import { CREATE_CLICK, UPDATE_CLICK } from "@/graphql/business"
import {
  CreateClick,
  CreateClickVariables
} from "@/graphql/business/types/CreateClick"
import {
  UpdateClickById,
  UpdateClickByIdVariables
} from "@/graphql/business/types/UpdateClickById"
import { ClickCollectInput, UpdateClickInput } from "@/types"
import { useMutation } from "@apollo/client"

export const UseClickNCollect = () => {
  const [createClk, { loading: loadCreat }] = useMutation<
    CreateClick,
    CreateClickVariables
  >(CREATE_CLICK)
  const [updateClk, { loading: loadUpdate }] = useMutation<
    UpdateClickById,
    UpdateClickByIdVariables
  >(UPDATE_CLICK)
  const createClick = async (data: ClickCollectInput) => {
    try {
      await createClk({
        variables: {
          data
        }
      })
      return { success: "OK" }
    } catch (error) {
      return { error }
    }
  }
  const updateClick = async (id: number, data: UpdateClickInput) => {
    try {
      await updateClk({
        variables: {
          id,
          data
        }
      })
      return { success: "OK" }
    } catch (error) {
      return { error }
    }
  }

  return {
    createClick,
    updateClick,
    loadCreat,
    loadUpdate
  }
}
