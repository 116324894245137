import { useMutation, useQuery } from "@apollo/client"
import {
  BUSINESS_GET_TEMPLATE_SPINNER_BY_ID,
  CREATE_TEMPLATE_SPINNER,
  DELETE_TEMPLATE_SPINNER,
  BUSINESS_GET_TEMPLATE_SPINNER,
  UPDATE_TEMPLATE_SPINNER,
  ADD_CLONE_TEMPLATE_SPINNER,
  GLOBAL_TEMPLATE_SPINNER
} from "@/graphql/business"
import {
  TemplateSpinnerById,
  TemplateSpinnerByIdVariables
} from "@/graphql/business/types/TemplateSpinnerById"
import {
  CreateTemplateSpinner,
  CreateTemplateSpinnerVariables
} from "@/graphql/business/types/CreateTemplateSpinner"
import { AdminType, TemplateInput, UserAuthStateEnum } from "@/types"
import {
  UpdateTemplateSpinner,
  UpdateTemplateSpinnerVariables
} from "@/graphql/business/types/UpdateTemplateSpinner"
import {
  DeleteTemplateSpinner,
  DeleteTemplateSpinnerVariables
} from "@/graphql/business/types/DeleteTemplateSpinner"
import { TemplateSpinner } from "@/graphql/business/types/TemplateSpinner"
import {
  CloneTemplate,
  CloneTemplateVariables
} from "@/graphql/business/types/CloneTemplate"
import { GetTemplateSpinnerGlobalQuery } from "@/gql-types/graphql"
import { useApplicationContext } from "../application"

export const useTemplateSpinner = (businessId?: number) => {
  const { data, loading, error, refetch } = useQuery<TemplateSpinner>(
    BUSINESS_GET_TEMPLATE_SPINNER,
    {
      skip: !businessId,
      variables: {
        businessId
      }
    }
  )

  return {
    data: data?.getTemplateSpinner,
    loading,
    error,
    refetch
  }
}

export const useTemplateGlobal = () => {
  const { user } = useApplicationContext()

  const { data, loading, error, refetch } =
    useQuery<GetTemplateSpinnerGlobalQuery>(GLOBAL_TEMPLATE_SPINNER, {
      skip: user?.type !== AdminType.ADMIN
    })

  return {
    data: data?.getTemplateSpinnerGlobal,
    loading,
    error,
    refetch
  }
}

export const useTemplateSpinnerById = (id?: number) => {
  const { data, loading, error, refetch } = useQuery<
    TemplateSpinnerById,
    TemplateSpinnerByIdVariables
  >(BUSINESS_GET_TEMPLATE_SPINNER_BY_ID, {
    variables: {
      templateId: id as number
    },
    skip: !id
  })
  return {
    data: data?.getTemplateSpinnerById,
    loading,
    error,
    refetch
  }
}

export const useCreateTemplateSpinner = () => {
  const [executeCreate, { loading }] = useMutation<
    CreateTemplateSpinner,
    CreateTemplateSpinnerVariables
  >(CREATE_TEMPLATE_SPINNER)
  const createTemplateSpinner = async (data: TemplateInput) => {
    try {
      await executeCreate({
        variables: {
          data
        }
      })
      return { success: "OK" }
    } catch (error) {
      return {
        error: "ERROR"
      }
    }
  }
  return {
    loading,
    createTemplateSpinner
  }
}

export const useUpdateTemplateSpinner = () => {
  const [updateTemplateSpinner] = useMutation<
    UpdateTemplateSpinner,
    UpdateTemplateSpinnerVariables
  >(UPDATE_TEMPLATE_SPINNER)
  const updateSpinner = async (templateId: number, data: TemplateInput) => {
    try {
      const spinnerUpdate = await updateTemplateSpinner({
        variables: {
          templateId,
          data
        }
      })
      return spinnerUpdate.data?.updateTemplateSpinner
    } catch (error) {
      return null
    }
  }
  return { updateSpinner }
}

export const usedeleteTemplate = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteTemplateSpinner, { loading }] = useMutation<
    DeleteTemplateSpinner,
    DeleteTemplateSpinnerVariables
  >(DELETE_TEMPLATE_SPINNER)
  const deletetemplates = async (templateId: number) => {
    try {
      const removeTemplate = await deleteTemplateSpinner({
        variables: {
          templateId
        }
      })
      return removeTemplate
    } catch (error) {
      return undefined
    }
  }
  return { deletetemplates, loading }
}

export const useCloneTemplateSpinner = () => {
  const [executeCloneTemplate, { loading }] = useMutation<
    CloneTemplate,
    CloneTemplateVariables
  >(ADD_CLONE_TEMPLATE_SPINNER)
  const cloneTemplate = async (
    businessId: number,
    templateId: number,
    templatesInfo: TemplateInput
  ) => {
    try {
      await executeCloneTemplate({
        variables: {
          templateId,
          businessId,
          templatesInfo
        }
      })
    } catch (error) {
      return {
        error: "ERROR"
      }
    }

    return {
      error: "ERROR"
    }
  }
  return {
    loading,
    cloneTemplate
  }
}
