import { TableColumnProps } from "@/components"
import { Source } from "@/components/source/Source"
import { BusinessClients_businessClients as IClient } from "@/graphql/business"
import { Box } from "@mui/material"

export const ContactTable = () => {
  const columns: TableColumnProps[] = [
    {
      id: "name",
      label: "Nom",
      component: (data: IClient) => <Box>{data.lastname}</Box>
    },
    {
      id: "first",
      label: "Prenom",
      component: (data: IClient) => <Box>{data.firstname}</Box>
    },
    {
      id: "source",
      label: "Source",
      component: (data: IClient) => (
        <Box>{Source(data.source).icon}</Box>
      )
    },
    {
      id: "email",
      label: "E-mail",
      component: (data: IClient) => <Box>{data.email}</Box>
    },
    {
      id: "phone",
      label: "Téléphone",
      component: (data: IClient) => <Box>{data.phone}</Box>
    },
  ]

  return { columns }
}
