import { Box } from "@mui/material"
import { useCurrentLisstProduct } from "@/hooks"
import { BaseTable } from "@/components"
import { useEffect, useState } from "react"
import { useTableProductList } from "./component/useTableProductList"
import { BusinessAllProduct_businessAllProduct } from "@/graphql/business"

export const ProductList = () => {
  const [page, setPage] = useState<number>(1)
  const [dataProced, setDataProced] = useState<BusinessAllProduct_businessAllProduct[]>([])
  const { data } = useCurrentLisstProduct()
  const { columns } = useTableProductList()

  useEffect(() => {
    if (data) {
      setDataProced(data)
    }
  }, [data])

  return (
    <Box sx={{ p: 2 }}>
      <BaseTable
        title="Listes des Produits test"
        columns={columns}
        data={[...dataProced].reverse() || []}
        page={page - 1}
      />
    </Box>
  )
}
