import { Typography } from "@mui/material"

interface FormatProps {
  position: string
  currency: string
  separator: string
  decimal: string
  decNum: number
  value: string | number
  style?: object
}

export const FormatPrice = ({
  position,
  currency,
  separator,
  decimal,
  decNum,
  value,
  style
}: FormatProps) => {
  const currencyList = [
    {
      name: "euro",
      currency: "€"
    },
    {
      name: "dollars",
      currency: "$"
    },
    {
      name: "franc",
      currency: "CHF"
    },
    {
      name: "roupie",
      currency: "RS"
    }
  ]
  const sepThouList = [
    {
      name: "dot",
      value: "."
    },
    {
      name: "space",
      value: " "
    },
    {
      name: "nospace",
      value: ""
    }
  ]
  const decimalList = [
    {
      name: "dot",
      value: "."
    },
    {
      name: "coma",
      value: ","
    }
  ]
  const currencyDec = decimalList.find((item) => item.name === decimal)
  const currencyType = currencyList.find((item) => item.name === currency)
  const currencySep = sepThouList.find((item) => item.name === separator)
  const numStr = (a: string | number, b: string) => {
    a = "" + a
    b = b || " "
    let c = "",
      d = 0
    while (a.match(/^0[0-9]/)) {
      a = a.substr(1)
    }
    let indexDec = a.indexOf(".")
    let dec = a.slice(indexDec + 1)
    dec = dec.slice(0, decNum)
    if (indexDec >= 0) {
      a = a.slice(0, indexDec)
    }
    for (let i = a.length - 1; i >= 0; i--) {
      c = d !== 0 && d % 3 === 0 ? a[i] + b + c : a[i] + c
      d++
    }
    if (indexDec >= 0 && Number(dec) > 0) {
      return `${c}${currencyDec?.value}${dec}`
    }

    return c
  }

  return (
    <>
      {position === "left" ? (
        <Typography sx={style}>
          {currencyType?.currency} {numStr(value, currencySep?.value || "")}
        </Typography>
      ) : (
        <Typography sx={style}>
          {numStr(value, currencySep?.value || "")} {currencyType?.currency}
        </Typography>
      )}
    </>
  )
}
