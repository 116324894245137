/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "@apollo/client"
import { Box, styled, TextField, Typography } from "@mui/material"
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PhoneInput, { CountryData } from "react-phone-input-2"
import "react-phone-input-2/lib/material.css"
import {
  CreateBusinessStep1,
  CreateBusinessStep1Variables,
  CREATE_BUSINESS_1
} from "../../../../graphql/business"
import { inputsValidation, storage } from "../../../../utils"
import { FormContainer } from "../components/FormContainer"
import Swal from "sweetalert2"
import { useForm } from "react-hook-form"
import { error } from "console"

const PhoneContainer = styled(Box)(() => ({
  "& .react-tel-input .form-control": {
    width: "100%",
    paddingBlock: 10
  }
}))
interface SignupStepProps {
  afterSave?: (id?: number) => void
}
export const FicheUser: FC<SignupStepProps> = ({ afterSave }): JSX.Element => {
  const [createBusinessStep1, { loading }] = useMutation<
    CreateBusinessStep1,
    CreateBusinessStep1Variables
  >(CREATE_BUSINESS_1)
  const [info, setInfo] = useState({ name: "", url: "", activity: "" })
  const [confirmPsw, setConfirmPsw] = useState("")
  const [errorState, setErrorState] = useState(false)
  const [values, setValues] = useState({
    password: "",
    email: "",
    lastname: "",
    firstname: "",
    phone: "",
    countryCode: ""
  })
  const { register, handleSubmit, formState: { errors } } = useForm()
  const navigate = useNavigate()

  const handleCreate = async (): Promise<void> => {
    try {
      const { name, activity, url } = info
      const create = await createBusinessStep1({
        variables: {
          business: {
            ...values,
            businessName: name as string,
            activity: activity as string,
            url: `${url}.tag.discount`
          }
        }
      })
      if (create.data?.createBusinessStep1) {
        localStorage.setItem(
          "new_Id",
          create.data.createBusinessStep1.id.toString()
        )
        if (create.data?.createBusinessStep1.clientSecret) localStorage.setItem("client_secret", create.data?.createBusinessStep1?.clientSecret.toString())
        if (values.password) {
          localStorage.setItem("secret", values.password)
          if (afterSave) {
            afterSave(create.data.createBusinessStep1.id)
          } else {
            navigate(`/signup/address/${create.data.createBusinessStep1.id}`)
          }
        }
      }
      return undefined
    } catch (errors) {
      setErrorState(true)
      return undefined
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    setErrorState(false)
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleChangePhone = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ): void => {
    event.preventDefault()
    setValues((prev) => ({
      ...prev,
      phone: formattedValue,
      countryCode: data.countryCode
    }))
  }

  const loadLocal = useCallback(() => {
    const infos = storage.get("step_2_tag")
    if (infos) {
      setInfo({ ...infos })
    } else {
      navigate("/signup")
    }
  }, [navigate])
  useEffect(() => {
    loadLocal()
  }, [loadLocal])

  return (
    <FormContainer
      step={2}
      loading={loading}
      title="Vos informations"
      onNext={handleSubmit(handleCreate)}
      disableBtn={
        (values.password && confirmPsw && values.password !== confirmPsw) ||
        !inputsValidation([...Object.values(values), confirmPsw])
      }
    >
      <Box sx={{ py: 2 }}>
        <Box sx={{ py: 1, display: "flex", justifyContent: "space-between" }}>
          <TextField
            label="Nom"
            variant="outlined"
            value={values.firstname}
            {
            ...register("firstname", {
              required: {
                value: true,
                message: "Ce champs est requis"
              }
            })
            }
            error={errors.firstname && true}
            helperText={`${errors.firstname?.message}`}
            onChange={handleChange}
            sx={{ width: "48%" }}
          />
          <TextField
            required
            label="Prénom"
            variant="outlined"
            {
            ...register("lastname", {
              required: {
                value: true,
                message: "Ce champs est requis"
              }
            })
            }
            error={errors.lastname && true}
            helperText={`${errors.lastname?.message}`}
            value={values.lastname}
            onChange={handleChange}
            sx={{ width: "48%" }}
          />
        </Box>
        <PhoneContainer sx={{ py: 1 }}>
          <PhoneInput
            enableSearch
            inputProps={{
              name: "Téléphone",
              required: true,
              autoFocus: true
            }}
            country="fr"
            value={values.phone}
            onChange={handleChangePhone}
            preferredCountries={["fr", "us"]}
          />
        </PhoneContainer>

        <Box sx={{ py: 1 }}>
          <TextField
            fullWidth
            required
            label="Email"
            variant="outlined"
            {
            ...register("email", {
              required: {
                value: true,
                message: "Ce champs est requis"
              },
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "Entrer un email valide"
              }
            })
            }
            error={errors.email && true || errorState}
            helperText={`${errors.email?.message}`}
            value={values.email}
            onChange={handleChange}
          />
          {
            errorState && (
              <Typography variant="caption" sx={{ color: "red" }}>Cette adresse éxiste déjà</Typography>
            )
          }
        </Box>
        <Box sx={{ py: 1 }}>
          <TextField
            fullWidth
            required
            label="Mot de passe"
            variant="outlined"
            type="password"
            {
            ...register("password", {
              required: {
                value: true,
                message: "Ce champs est requis"
              }
            })
            }
            error={errors.password && true}
            helperText={`${errors.password?.message}`}
            value={values.password}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ py: 1 }}>
          <TextField
            fullWidth
            required
            label="Confirmer mot de passe"
            variant="outlined"
            name="confirm"
            type="password"
            value={confirmPsw}
            error={
              (values.password &&
                confirmPsw &&
                values.password !== confirmPsw) ||
              false
            }
            onChange={(e) => setConfirmPsw(e.target.value)}
          />
        </Box>
      </Box>
    </FormContainer>
  )
}
