/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
import { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from "react"
import {
  Box,
  styled,
  BoxProps,
  Divider,
  IconButton
} from "@mui/material"
import PhoneInput, { CountryData } from "react-phone-input-2"
import { Loading } from "../loading"

const PhoneContainer = styled(Box)(() => ({
  "& .special-label": {
    display: "none"
  }
}))

interface CustomEditPhoneInputProps {
  label?: string
  editable?: boolean
  containerProps?: BoxProps
  loading?: boolean
  defaultValue?: string
  nodivider?: boolean
  name?: string
  onSave?: (value: string, name: string, countryCode: string) => void
}

export const CustomEditPhoneInput: FC<CustomEditPhoneInputProps> = ({
  containerProps,
  name,
  editable = true,
  loading,
  nodivider,
  label,
  defaultValue,
  onSave
}): JSX.Element => {
  const [values, setValues] = useState<string>("")
  const [countryState, setCountryState] = useState<string>("")
  const handleSave = (e: SyntheticEvent): void => {
    e.preventDefault()
    onSave && onSave(values, name || "", countryState)
  }
  const handleChangePhone = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ): void => {
    event.preventDefault()
	setCountryState(data.countryCode)
    setValues(formattedValue)
  }
  useEffect(() => {
    if (defaultValue) {
      setValues(defaultValue)
    }
    return
  }, [defaultValue])

  return (
    <PhoneContainer {...containerProps}>
      <form>
        <Box>
          <PhoneInput
            enableSearch
            inputProps={{
              name: "Téléphone",
              required: true,
              autoFocus: true
            }}
            country="fr"
            value={values}
            onChange={handleChangePhone}
            onBlur={handleSave}
            preferredCountries={["fr", "us"]}
            inputStyle={{
              width: "100%",
              padding: "11px 14px 12px 58px"
            }}
          />
          <Box
            sx={{
              position: "absolute"
            }}
          >
            {loading ? (
              <IconButton>
                <Loading />
              </IconButton>
            ) : null}
          </Box>
        </Box>
        {nodivider ? null : <Divider />}
      </form>
    </PhoneContainer>
  )
}
