/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "@apollo/client"
import Edit from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import {
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography
} from "@mui/material"
import { ChangeEvent, FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Cancel } from "@mui/icons-material"
import { ResearchBox } from "../../../../components"
import {
  CheckDomain,
  CheckDomainVariables,
  CHECK_SUBDOMAIN
} from "../../../../graphql/business"
import { useApplicationContext } from "../../../../hooks"
import { SubdomainStatusEnum } from "../../../../types"
import { categories, storage } from "../../../../utils"
import { FormContainer } from "../components/FormContainer"

const convertToUrl = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")
}
interface SignupStepProps {
  afterSave?: () => void
}
export const FicheBusiness: FC<SignupStepProps> = ({
  afterSave
}): JSX.Element => {
  const { dispatchSnack } = useApplicationContext()
  const [check, { loading }] = useMutation<CheckDomain, CheckDomainVariables>(
    CHECK_SUBDOMAIN
  )
  const [search, setSearch] = useState<string>("")
  const [categoriesFound, setCategoriesFound] = useState<
    { name: string; type: string }[]
  >([])
  const [url, setUrl] = useState<string>()
  const [edit, setEdit] = useState<boolean>(false)
  const [taken, setTaken] = useState<boolean>(false)
  const [values, setValues] = useState({
    name: "",
    activity: "",
    activityType: ""
  })
  const navigate = useNavigate()
  const handleNext = async (): Promise<void> => {
    try {
      const res = await check({
        variables: {
          url: `${url}.tag.discount`
        }
      })
      if (res.data?.checkDomain.status === SubdomainStatusEnum.TAKEN) {
        setTaken(true)
        return
      }
      if (res.data?.checkDomain.status === SubdomainStatusEnum.AVAILABLE) {
        const params = {
          ...values,
          url
        }
        storage.set("step_2_tag", params)
        if (afterSave) {
          afterSave()
        } else {
          navigate(`informations`)
        }
      }
    } catch (errors) {
      dispatchSnack({
        open: true,
        message: "Une erreur se produite !",
        severity: "error"
      })
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
    if (e.target.name === "name") {
      const normalizedUrl = convertToUrl(e.target.value)
      setUrl(normalizedUrl)
    }
  }
  const handleClickActivity = (name: string, type: string): void => {
    setValues((prev) => ({
      ...prev,
      activity: name,
      activityType: type
    }))
    setSearch("")
    setCategoriesFound([])
  }

  const filter = (): void => {
    const found =
      search.length > 3
        ? categories.filter((c) =>
            c.name.toLowerCase().includes(search.toLowerCase())
          )
        : []

    setCategoriesFound(found)
  }

  const handleChangeActivity = (val: string): void => {
    setSearch(val.toLowerCase())
    filter()
  }
  return (
    <FormContainer
      loading={loading}
      disableBtn={!values.name || !values.activity}
      title="Fiche de votre établissement"
      onNext={handleNext}
    >
      <Box sx={{ py: 2 }}>
        <Box sx={{ py: 1 }}>
          <TextField
            fullWidth
            required
            label="Nom de l'établissement"
            variant="outlined"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
        </Box>
        {values.activity && (
          <Box>
            <Typography>Catégorie d&#39;activité :</Typography>
            <Chip
              label={values.activity}
              deleteIcon={<Cancel />}
              onDelete={() =>
                setValues((prev) => ({
                  ...prev,
                  activity: "",
                  activityType: ""
                }))
              }
            />
          </Box>
        )}
        {!values.activity ? (
          <ResearchBox
            placeholder="Rechercher une activité"
            onChange={handleChangeActivity}
            startLabel="Catégorie d'activité"
          />
        ) : null}
        <Box sx={{ py: 1, maxHeight: 300, overflow: "auto" }}>
          {categoriesFound.map(({ name, type }) => (
            <div key={name}>
              <Button
                sx={{ textTransform: "none" }}
                endIcon={<AddIcon />}
                onClick={() => handleClickActivity(name, type)}
              >
                {name}
              </Button>
            </div>
          ))}
        </Box>

        <Box sx={{ py: 1 }}>
          {edit ? (
            <TextField
              fullWidth
              required
              label="Votre page tag discount"
              variant="outlined"
              name="url"
              value={url}
              onChange={({
                target: { value }
              }: ChangeEvent<HTMLInputElement>) => {
                setTaken(false)
                setUrl(convertToUrl(value))
              }}
              InputProps={{
                endAdornment: (
                  <Typography variant="subtitle2">.tag.discount</Typography>
                )
              }}
            />
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {url ? (
                <Typography variant="subtitle2">
                  https://{url}.tag.discount
                </Typography>
              ) : null}
              {url && (
                <IconButton onClick={() => setEdit(true)}>
                  <Edit />
                </IconButton>
              )}
            </Box>
          )}
        </Box>

        {taken ? (
          <Typography color="error.main">
            {`https://${url}.tag.discount`} est déjà pris, Veuillez changer
            votre url.
          </Typography>
        ) : null}
      </Box>
    </FormContainer>
  )
}
