import { TableColumnProps } from "@/components"
import { Typography } from "@mui/material"
import moment from "moment"
export const TableSignIn = (): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "name",
      label: "Etablissement",
      component: (data) => <Typography>{data.businessName}</Typography>
    },
    {
      id: "email",
      label: "email",
      component: (data) => <Typography>{data.email}</Typography>
    },
    {
      id: "createdAt",
      label: "Date d'inscription",
      component: (data) => <Typography>{moment(data.createdAt).format("DD MMMM YYYY")}</Typography>
    }
  ]

  return { columns }
}
