export const font: {
  label: string
  fieldType: string
  value: string
  id: string
}[] = [
  {
    label: "Montserrat",
    id: "montserrat",
    fieldType: "text",
    value: "Montserrat"
  },
  {
    label: "Lato",
    id: "lato",
    fieldType: "text",
    value: "Lato"
  },
  {
    label: "Comforter",
    id: "comforter",
    fieldType: "text",
    value: "Comforter"
  },
  {
    label: "Open Sans",
    id: "open-sans",
    fieldType: "text",
    value: "Open Sans"
  },
  {
    label: "Bungee",
    id: "bungee",
    fieldType: "text",
    value: "Bungee"
  },
  {
    label: "Poppins",
    id: "poppins",
    fieldType: "text",
    value: "Poppins"
  },
  {
    label: "Titillium Web",
    id: "titillium-web",
    fieldType: "text",
    value: "Titillium Web"
  },
  {
    label: "Quicksand",
    id: "quicksand",
    fieldType: "text",
    value: "Quicksand"
  },
  {
    label: "Josefin",
    id: "Josefin",
    fieldType: "text",
    value: "Josefin"
  },
  {
    label: "Play",
    id: "Play",
    fieldType: "text",
    value: "Play"
  },
  {
    label: "Spartan",
    id: "Spartan",
    fieldType: "text",
    value: "Spartan"
  },
  {
    label: "Economica",
    id: "Economica",
    fieldType: "text",
    value: "Economica"
  },
  {
    label: "Encode Sans",
    id: "Encode Sans",
    fieldType: "text",
    value: "Encode Sans"
  },
  {
    label: "Noto Sans",
    id: "Noto Sans",
    fieldType: "text",
    value: "Noto Sans"
  },
  {
    label: "EB Garamond",
    id: "EB Garamond",
    fieldType: "text",
    value: "EB Garamond"
  },
  {
    label: "Bitter",
    id: "Bitter",
    fieldType: "text",
    value: "Bitter"
  },
  {
    label: "Playfair Display",
    id: "Playfair Display",
    fieldType: "text",
    value: "Playfair Display"
  },
  {
    label: "Abril Fatface",
    id: "Abril Fatface",
    fieldType: "text",
    value: "Abril Fatface"
  },
  {
    label: "Josefin Slab",
    id: "Josefin Slab",
    fieldType: "text",
    value: "Josefin Slab"
  },
  {
    label: "Secular One",
    id: "Secular One",
    fieldType: "text",
    value: "Secular One"
  },
  {
    label: "Prata",
    id: "Prata",
    fieldType: "text",
    value: "Prata"
  },
  {
    label: "Philosopher",
    id: "Philosopher",
    fieldType: "text",
    value: "Philosopher"
  },
  {
    label: "Lusitana",
    id: "Lusitana",
    fieldType: "text",
    value: "Lusitana"
  },
  {
    label: "Chathura",
    id: "Chathura",
    fieldType: "text",
    value: "Chathura"
  },
  {
    label: "Bebas Neue",
    id: "Bebas Neue",
    fieldType: "text",
    value: "Bebas Neue"
  },
  {
    label: "Libre Baskerville",
    id: "Libre Baskerville",
    fieldType: "text",
    value: "Libre Baskerville"
  },
  {
    label: "Abel",
    id: "Abel",
    fieldType: "text",
    value: "Abel"
  },
  {
    label: "Staatliches",
    id: "Staatliches",
    fieldType: "text",
    value: "Staatliches"
  },
  {
    label: "Archivo",
    id: "Archivo",
    fieldType: "text",
    value: "Archivo"
  },
  {
    label: "Monoton",
    id: "Monoton",
    fieldType: "text",
    value: "Monoton"
  },
  {
    label: "Fugaz One",
    id: "Fugaz One",
    fieldType: "text",
    value: "Fugaz One"
  },
  {
    label: "Antonio",
    id: "Antonio",
    fieldType: "text",
    value: "Antonio"
  },
  {
    label: "Graduate",
    id: "Graduate",
    fieldType: "text",
    value: "Graduate"
  },
  {
    label: "Limelight",
    id: "Limelight",
    fieldType: "text",
    value: "Limelight"
  },
  {
    label: "Lobster",
    id: "Lobster",
    fieldType: "text",
    value: "Lobster"
  },
  {
    label: "Pacifico",
    id: "Pacifico",
    fieldType: "text",
    value: "Pacifico"
  },
  {
    label: "Shadows Into Light",
    id: "Shadows Into Light",
    fieldType: "text",
    value: "Shadows Into Light"
  },
  {
    label: "Amatic SC",
    id: "Amatic SC",
    fieldType: "text",
    value: "Amatic SC"
  },
  {
    label: "Righteous",
    id: "Righteous",
    fieldType: "text",
    value: "Righteous"
  },
  {
    label: "Gloria Hallelujah",
    id: "Gloria Hallelujah",
    fieldType: "text",
    value: "Gloria Hallelujah"
  },
  {
    label: "Luckiest Guy",
    id: "Luckiest Guy",
    fieldType: "text",
    value: "Luckiest Guy"
  },
  {
    label: "Almarai",
    id: "Almarai",
    fieldType: "text",
    value: "Almarai"
  },
  {
    label: "Noto Serif KR",
    id: "Noto Serif KR",
    fieldType: "text",
    value: "Noto Serif KR"
  },
  {
    label: "Noto Serif SC",
    id: "Noto Serif SC",
    fieldType: "text",
    value: "Noto Serif SC"
  }
]
