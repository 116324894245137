import { LooserGamesQuery, LooserGamesQueryVariables } from "@/gql-types/graphql"
import { useLazyQuery } from "@apollo/client"
import {
    LOOSER_GAMES,
} from "../../graphql/qrcode"

export const useLoosers = () => {
  const [getLooserExec, { loading, refetch }] = useLazyQuery<
    LooserGamesQuery,
    LooserGamesQueryVariables
  >(LOOSER_GAMES)
  const getLooser = async (qrcodeId: number, take: number, skip: number) => {
    try {
      const winners = await getLooserExec({
        variables: {
          limit: take,
          qrcodeId,
          skip
        }
      })
      return winners.data?.looserGames || []
    } catch (error) {
      return []
    }
  }
  return {
    refetch,
    loading,
    getLooser
  }
}
