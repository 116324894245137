import { Button, Typography } from "@mui/material"
import { ListBilling_listBilling as IBilling } from "@/graphql/abonnement"
import moment from "moment"
import { FormatPrice } from "@/components/FormatPrice"
import { useQuery } from "@apollo/client"
import { BUSINESS_GET_CLICK_BY_ID } from "@/graphql/business"
import { useContext } from "react"
import { useApplicationContext } from "@/hooks"

export const AbonnementTable = () => {
  const { business } = useApplicationContext()
  const { data: dataCurrency } = useQuery(BUSINESS_GET_CLICK_BY_ID, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })
  const columns = [
    {
      id: "num",
      label: "Numéro de facture ",
      component: (data: IBilling) => <Typography>{data.id}</Typography>
    },
    {
      id: "date",
      label: "Date",
      component: (data: IBilling) => (
        <Typography>{moment(data.createdAt).format("DD/MM/YYYY")}</Typography>
      )
    },
    {
      id: "amount",
      label: "Montant du paiement",
      component: (data: IBilling) => (
        <FormatPrice
          position={dataCurrency?.clickCollectById?.position || ""}
          currency={"euro"}
          separator={dataCurrency?.clickCollectById?.thousandsSeparator || ""}
          decimal={dataCurrency?.clickCollectById?.decimalSeparator || ""}
          decNum={dataCurrency?.clickCollectById?.decimalNumber}
          value={data?.amountPaid ? data?.amountPaid * 0.01 : 0}
        />
      )
    },
    {
      id: "current",
      label: "Mois en cours",
      component: (data: IBilling) => (
        <Typography>{moment().format("MMMM")}</Typography>
      )
    },
    {
      id: "download",
      label: "Télécharger la facture",
      component: (data: IBilling) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const link = document.createElement("a")
            link.href = data?.billing || ""
            link.setAttribute("target", "_blank")
            document.body.appendChild(link)
            link.click()
            link.remove()
          }}
        >
          Télécharger
        </Button>
      )
    }
  ]
  return { columns }
}
