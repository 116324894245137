import { BaseTable, ConfirmModal, QueryResult } from "@/components"
import {
  DeleteEventParty,
  DeleteEventPartyVariables,
  DELETE_EVENT,
  ListEventParty_listEventParty,
  UpdateEventParty,
  UpdateEventPartyVariables,
  UPDATE_EVENT
} from "@/graphql/eventParty"
import { GET_LIST_EVENT } from "@/graphql/eventParty/query"
import { useApplicationContext } from "@/hooks"
import { useMutation, useQuery } from "@apollo/client"
import { Add } from "@mui/icons-material"
import { Box, Button, styled, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { TableEvent, ModalEvent } from "./component"

export const EventList = () => {
  const { dispatchSnack, business } = useApplicationContext()
  const [page] = useState<number>(1)
  const [editId, setEditId] = useState<number | undefined>()
  const [open, setOpen] = useState(false)
  const [toDeleteId, setToDeleteId] = useState<number>()
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [dataProced, setDataProced] = useState<ListEventParty_listEventParty[]>([])
  const { data, loading, refetch } = useQuery(GET_LIST_EVENT, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })
  const [editEvent] = useMutation<UpdateEventParty, UpdateEventPartyVariables>(
    UPDATE_EVENT
  )
  const upStatus = async (item: boolean, id: number): Promise<void> => {
    editEvent({
      variables: {
        updateEventPartyId: id,
        data: {
          status: item,
          businessId: business?.id
        }
      }
    }).then(() => {
      refetch()
    })
  }
  const [deleteEvent] = useMutation<
    DeleteEventParty,
    DeleteEventPartyVariables
  >(DELETE_EVENT)
  const ButtonTop = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: 15
  }))
  const handleUpdate = (idEditProduct: number) => {
    setEditId(idEditProduct)
    setOpen(true)
  }
  const handleDelete = (eventId: number) => {
    setOpenDelete(true)
    setToDeleteId(eventId)
  }
  const confirmDelete = async () => {
    if (!toDeleteId) {
      setOpenDelete(false)
      return
    }
    await deleteEvent({
      variables: {
        deleteEventPartyId: toDeleteId
      }
    }).then(() => {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "suppression avec succès"
      })
      refetch()
      setOpenDelete(false)
    })
    setToDeleteId(undefined)
  }
  const { columns } = TableEvent({
    onRemove: handleDelete,
    onEdit: handleUpdate,
    onChangeStatus: upStatus
  })
  useEffect(() => {
    if (data) {
      setDataProced(data?.listEventParty)
    }
  }, [data])

  return (
    <>
      <Box>
        <QueryResult data={data} loading={loading}>
          <BaseTable
            indexed="id"
            title="Liste des événements :"
            columns={columns}
            data={[...dataProced].reverse() || []}
            page={page - 1}
            topComponent={
              <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
                <ButtonTop
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => {
                    setEditId(undefined)
                    setOpen(true)
                  }}
                >
                  Créer un événement
                </ButtonTop>
              </Box>
            }
          />
        </QueryResult>
      </Box>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onConfirm={confirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression d'évènement"
      >
        <Typography>Vous êtes sur le point de supprimer cet évènement</Typography>
      </ConfirmModal>
      <ModalEvent
        open={open}
        setOpen={setOpen}
        businessId={business?.id || 1}
        editedId={editId}
        onClose={() => refetch()}
      />
    </>
  )
}
