import { CustomModal } from "@/components/modals"
import { Box, Typography, styled, Button } from "@mui/material"
import { useParams, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Add from "@mui/icons-material/Add"
import {
  useApplicationContext,
  useCreateClient,
  useUpdateClient
} from "@/hooks"
import {
  useDeleteCategory,
  useDeleteSousCategory,
  useUpdateCategory,
  useUpdateSousCategory
} from "@/hooks/category"
import { BaseTable, ConfirmModal } from "@/components"
import { TableCategory } from "./TableCategory/TableCategory"
import ModalCategory from "./ModalCategory/ModalCategory"

import { useCategoryList } from "@/hooks/category/useCategoryList"
import { DeleteCategory_removeCategory } from "@/graphql/business"

type TypeDel = {
  id: number
  name: string
}

export const Category = () => {
  const { business, dispatchSnack } = useApplicationContext()
  const [open, setOpen] = useState(false)
  const [page] = useState<number>(1)
  const [openStatus, setOpenStatus] = useState(false)
  const [editStatus, setEditStatus] = useState({
    type: "",
    item: true,
    id: 0
  })
  const { id } = useParams()

  const businessId = business?.id || parseInt(id || "0")

  const { categories, refetch } = useCategoryList(businessId)
  const navigate = useNavigate()
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const { deleteLoading } = useUpdateClient()
  const { categoryDelete } = useDeleteCategory()
  const { categorySousDelete } = useDeleteSousCategory()
  const { loading: createLoading } = useCreateClient()
  const [categoryIds, setCategoryIds] = useState<number>()
  const [subCategoryIds, setSubCategoryIds] = useState<number>()

  const [listProduct, setListProduct] =
    useState<DeleteCategory_removeCategory[]>()
  const [openAdd, setOpenAdd] = useState<boolean>(false)

  const { categoryStatusUpdate } = useUpdateCategory()
  const { sousCategoryStatusUpdate } = useUpdateSousCategory()

  const ButtonTop = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: 15,
    color: "#050005" 
  }))
  const handleDelete = (delId: number, typeObj: TypeDel) => {
    setOpenDelete(true)
    if (typeObj || typeObj === null) {
      setSubCategoryIds(delId)
    } else {
      setCategoryIds(delId)
    }
  }

  const hadleSousCategory = (idCategory: number) => {
    navigate(`${idCategory}/sousCategory`)
  }

  const handleEditSousCategory = (idSousCategory: number) => {
    navigate(`sousCategory/${idSousCategory}`)
  }

  const handleEdit = (idEdit: number) => {
    navigate(`${idEdit}`)
  }

  const handleStatus = (item: boolean, idCat: number, type: string): void => {
    setOpenStatus(true)
    setEditStatus({
      type,
      item,
      id: idCat
    })
  }

  const confirmEditStat = async (): Promise<void> => {
    try {
      if (editStatus.type === "cat") {
        await categoryStatusUpdate(
          parseFloat(editStatus.id as unknown as string),
          editStatus.item
        )
      } else {
        await sousCategoryStatusUpdate(
          parseFloat(editStatus.id as unknown as string),
          editStatus.item
        )
      }

      refetch()
    } catch (errors) {
      throw new Error("ERROR")
    }
  }

  const { columns } = TableCategory({
    onRemove: handleDelete,
    onEdit: handleEdit,
    onViewSous: hadleSousCategory,
    onEditSousCategory: handleEditSousCategory,
    onEditStatus: (itemCat, idCat) => handleStatus(itemCat, idCat, "cat"),
    onEditSubStatus: (itemCat, idCat) => handleStatus(itemCat, idCat, "sub-cat")
  })

  const handleAdd = () => {
    if (business?.id || parseFloat(id as string)) {
      setOpen(true)
    }
  }
  const handleCancelDelete = () => {
    setCategoryIds(undefined)
    setOpenDelete(false)
  }

  const handleConfirmDelete = async () => {
    if (subCategoryIds) {
      const deleteSubCategory = await categorySousDelete(subCategoryIds)
      const listCategoryAfter = deleteSubCategory?.data?.deleteSousCategory
      if (listCategoryAfter && listCategoryAfter?.length > 0) {
        setListProduct([...listCategoryAfter])
        setOpenAdd(true)
      } else {
        dispatchSnack({
          open: true,
          severity: "success",
          message: "suppression avec succès"
        })
      }
      refetch()
      setSubCategoryIds(undefined)
    } else {
      if (!categoryIds) {
        setOpenDelete(false)
        return
      }
      const deleteCategory = await categoryDelete(categoryIds)
      const listCategoryAfter = deleteCategory?.data?.removeCategory
      if (listCategoryAfter && listCategoryAfter?.length > 0) {
        setListProduct([...listCategoryAfter])
        setOpenAdd(true)
      } else {
        dispatchSnack({
          open: true,
          severity: "success",
          message: "suppression avec succès"
        })
      }
      refetch()
      setCategoryIds(undefined)
    }
    setOpenDelete(false)
  }

  const handleCancel = () => {
    setOpenStatus(false)
  }

  useEffect(() => {
    refetch()
  }, [refetch])

        /****responsive styles */
        const alignStartMobile = {
      
          "@media(max-width:500px)" : {
            justifyContent: "flex-start"
          }
        }
      
        /*****end */

  return (
    <>
      {businessId ? (
        <ModalCategory
          open={open}
          setOpen={setOpen}
          loading={createLoading}
          refresh={() => refetch()}
          businessId={businessId}
        />
      ) : null}
      <Box sx={{ p: 2 ,  }}>
        <BaseTable
          title="Liste des catégories"
          columns={columns}
          data={categories}
          page={page - 1}
          topComponent={
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <ButtonTop
                variant="outlined"
                startIcon={<Add />}
                onClick={() => handleAdd()}
              >
                Ajouter catégorie
              </ButtonTop>
            </Box>
          }
        />
      </Box>
      <ConfirmModal
        open={openDelete}
        loading={deleteLoading}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de catégorie"
      >
        <Typography>
          Vous voulez vraiment supprimer cette categorie ?
        </Typography>
      </ConfirmModal>
      <ConfirmModal
        open={openStatus}
        setOpen={setOpenStatus}
        onCancel={handleCancel}
        onConfirm={confirmEditStat}
        confirmLabel="Modifier"
        isDeleting
        title="Changement de status"
      >
        <Typography>
          Cette action va changer tout les statuts des sous-catégories et des
          produits de la même catégorie. Etes-vous sûr de vouloir changer ?
        </Typography>
      </ConfirmModal>
      <CustomModal
        noAction
        btnClose
        open={openAdd}
        setOpen={setOpenAdd}
        title="Suppression catégorie"
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h4" color="primary">
            Supprimer ces produits avant la catégorie:
          </Typography>
          <ul>
            {listProduct &&
              listProduct.map((product, index) => {
                return (
                  <li key={index}>
                    <Typography component="p">{product.name}</Typography>
                  </li>
                )
              })}
          </ul>
          <Button variant="contained" onClick={(e) => setOpenAdd(false)}>
            ok
          </Button>
        </Box>
      </CustomModal>
    </>
  )
}
