import { gql } from "@apollo/client"

export const CRAETE_EMAILING = gql`
  mutation CreateEmailing($data: EmailMappingType!) {
    createEmailing(data: $data)
  }
`

export const UPDATE_EMAILING = gql`
  mutation UpdateEmailing($data: EmailMappingType!, $updateEmailingId: Float!) {
    updateEmailing(data: $data, id: $updateEmailingId)
  }
`

export const DELETE_EMAILING = gql`
  mutation DeleteEmailing($deleteEmailingId: Float!) {
    deleteEmailing(id: $deleteEmailingId)
  }
`

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($data: TemplateMailType!) {
    CreateTemplate(data: $data)
  }
`

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($templateId: Float!) {
    DeleteTemplate(templateId: $templateId)
  }
`

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($data: TemplateMailType!, $updateTemplateId: Float!) {
    UpdateTemplate(data: $data, id: $updateTemplateId)
  }
`

export const CREATE_SENDER = gql`
  mutation CreateSender($email: String!, $name: String!) {
    createSender(email: $email, name: $name)
  }
`

export const CREATE_SENDER_BUSINESS = gql`
  mutation CreateSenderBusiness(
    $businessId: Float!
    $email: String!
    $name: String!
  ) {
    createSenderBusiness(businessId: $businessId, email: $email, name: $name)
  }
`

export const VALIDATE_SENDER = gql`
  mutation ValidateSender($otp: Float!, $sendinblueId: Float!) {
    validateSender(otp: $otp, sendinblueId: $sendinblueId)
  }
`

export const DELETE_SENDER = gql`
  mutation DeleteSender($deleteSenderId: Float!) {
    deleteSender(id: $deleteSenderId)
  }
`

export const CREATE_LIST = gql`
mutation CreateList($listName: String!, $businessId: Float, $type: String) {
  createList(listName: $listName, businessId: $businessId, type: $type) {
    listBrevoId
    listContactId
  }
}
`

export const UPDATE_LIST = gql`
  mutation UpdateList($listId: Float!, $name: String!) {
    UpdateList(listId: $listId, name: $name)
  }
`

export const DELETE_LIST = gql`
  mutation DeleteList($listId: Float!) {
    DeleteList(listId: $listId)
  }
`

export const ADD_CONTACT = gql`
  mutation AddContact($data: AddContactType!) {
    AddContact(data: $data)
  }
`

export const CREATE_CAMPAGNE = gql`
  mutation CreateCampaign($data: CampaignsInfoType!) {
    createCampaign(data: $data)
  }
`

export const SEND_CAMPAIGN = gql`
  mutation SendCampaign($sendCampaignId: Float!) {
    sendCampaign(id: $sendCampaignId)
  }
`

export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($deleteCampaignId: Float!) {
    deleteCampaign(id: $deleteCampaignId)
  }
`

export const CREATE_LIST_NUM = gql`
  mutation CreateListNumber($data: ListNumType!) {
    CreateListNumber(data: $data)
  }
`

export const CREATE_SMS_CAMPAIGN = gql`
  mutation CreateSmsCampaign($data: CampaignSmsType!) {
    CreateSmsCampaign(data: $data)
  }
`

export const CREATE_PACK = gql`
   mutation CreatePack($data: SmsPackType!) {
    CreatePack(data: $data)
  } 
`

export const UPDATE_PACK = gql`
  mutation UpdatePack($updatePackId: Float!, $data: SmsPackType!) {
    UpdatePack(id: $updatePackId, data: $data)
  }
`

export const DELETE_PACK = gql`
  mutation DeletePack($deletePackId: Float!) {
    DeletePack(id: $deletePackId)
  }
`
