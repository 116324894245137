import { BaseTable, ConfirmModal, QueryResult } from "@/components"
import {
  Box,
  Typography,
  styled,
  Button
} from "@mui/material"
import { TableCampaign } from "./component/TableCampaign"
import { Add } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { DELETE_CAMPAIGN, GET_CAMPAGNE, SEND_CAMPAIGN } from "@/graphql/emailing"
import { useMutation, useQuery } from "@apollo/client"
import { useState, useEffect } from "react"
import Swal from "sweetalert2"
import { useApplicationContext } from "@/hooks"

export const CampaignDashboard = () => {
  const { business } = useApplicationContext()
  const { data, loading, refetch } = useQuery(GET_CAMPAGNE, {
    variables: {
      businessId: business?.id
    }
  })
  const [sendCampaignFn] = useMutation(SEND_CAMPAIGN)
  const [deleteCampaignFn] = useMutation(DELETE_CAMPAIGN)
  const [openSend, setOpenSend] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState<number | null>()
  const navigate = useNavigate()
  const ButtonTop = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: 15
  }))
  const handleSend = (id: number | null) => {
    setOpenSend(true)
    setSelectedCampaign(id)
  }
  const handleDelete = (id: number | null) => {
    setOpenDelete(true)
    setSelectedCampaign(id)
  }
  const handleCancel = () => {
    setSelectedCampaign(null)
  }
  const confirmSending = () => {
    sendCampaignFn({
      variables: {
        sendCampaignId: selectedCampaign
      }
    }).then(() => {
      setSelectedCampaign(null)
      refetch()
      Swal.fire({
        icon: "success",
        title: "Campagne envoyé",
        text: "",
        showConfirmButton: false,
        showCancelButton: false
      })

    }).catch(() => {
      Swal.fire({
        icon: "error",
        title: "Envoie échoué",
        text: "",
        showConfirmButton: false,
        showCancelButton: false
      })
    })
  }
  const confirmDelete = () => {
    deleteCampaignFn({
      variables: {
        deleteCampaignId: selectedCampaign
      }
    }).then(() => {
      setSelectedCampaign(null)
      refetch()
      Swal.fire({
        icon: "success",
        title: "Campagne supprimé",
        text: "",
        showConfirmButton: false,
        showCancelButton: false
      })
    }).catch(() => {
      Swal.fire({
        icon: "error",
        title: "Suppression échoué",
        text: "",
        showConfirmButton: false,
        showCancelButton: false
      })
    })
  }
  const { columns } = TableCampaign({
    onSend: handleSend,
    onDelete: handleDelete
  })

  useEffect(() => {

    refetch()
  }, [data])

  return (
    <Box sx={{ m: 2 }}>
      <QueryResult data={data} loading={loading}>
        <BaseTable
          title="Liste des campagnes"
          columns={columns}
          data={data?.campagnList}
          page={1}
          topComponent={
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <ButtonTop
                onClick={() => navigate("./create_campaign")}
                variant="outlined"
                startIcon={<Add />}
                sx={{ color: "#050005" }}
              >
                Créer une campagne
              </ButtonTop>
            </Box>
          }
        />
      </QueryResult>
      <ConfirmModal
        open={openSend}
        setOpen={setOpenSend}
        confirmLabel="Envoyer"
        title="Envoyer la campagne"
        onConfirm={confirmSending}
        onCancel={handleCancel}
      >
        <Typography>Vous êtes sur le point d'envoyer une campagne</Typography>
      </ConfirmModal>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onConfirm={confirmDelete}
        onCancel={handleCancel}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de campagne"
      >
        <Typography>Vous êtes sur le point de supprimer la campagne</Typography>
      </ConfirmModal>
    </Box>
  )
}
