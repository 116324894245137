import { useApplicationContext } from "."
import { admminRoutes as adminRoutes } from "../pages"
import { useBusinessRoute } from "../pages/business"
import { AdminType, IFeatured, IWebRoutes } from "../types"

export const useCustomRoutes = () => {
  const { businessRoutes } = useBusinessRoute()
  const routes = [...adminRoutes, ...businessRoutes]
  return { routes, adminRoutes, businessRoutes }
}
interface IRoutes {
  navbarMenu: IWebRoutes[]
  routes: IWebRoutes[]
  profilMenu: IWebRoutes[]
}
export const useRouteValidation = (): IRoutes => {
  const { user, business, isAdminModeBusiness } = useApplicationContext()
  const { routes: webRoots } = useCustomRoutes()
  const profilMenu = webRoots.filter((route) => {
    if (route.featured !== IFeatured.PROFIL) return false
    return false
  })
  const navbarMenu = webRoots.filter((route) => {
    if (route.featured !== IFeatured.SIDENAV) return false
    if (user?.type === AdminType.ADMIN) {

      if (business?.id && isAdminModeBusiness && user?.type === AdminType.ADMIN) {
        return route.role?.includes(AdminType.BUSINESS_ADMIN)
      }
      return route.role?.includes(AdminType.ADMIN)
    }
    return route.role?.includes(user?.type as AdminType)
  })
  const routes = webRoots.filter((route) => {
    if (user?.type === AdminType.ADMIN) {

      if (business?.id && isAdminModeBusiness && user?.type === AdminType.ADMIN) {
        return route.role?.includes(AdminType.BUSINESS_ADMIN)
      }
      return route.role?.includes(AdminType.ADMIN)
    }
    return route.role?.includes(user?.type as AdminType)
  })
  return {
    navbarMenu,
    profilMenu,
    routes
  }
}
