import { QueryResult } from "@/components"
import {
  BOOKING_PENDING,
  BOOKING_REJECTED,
  BOOKING_VALIDATE,
  PendingReservation_pendingReservation as IPending,
  ValidReservation_validReservation as IValid,
  RejectNewReservation_rejectNewReservation as IReject,
  VALIDATE_BOOKING,
  REJECT_BOOKING,
  ValidateReservation,
  ValidateReservationVariables,
  RejectNewReservation,
  RejectNewReservationVariables
} from "@/graphql/booking"
import { BOOKING_CREATE_NOTIFICATION } from "@/graphql/business/subscription"
import { useApplicationContext } from "@/hooks"
import { useMutation, useQuery, useSubscription } from "@apollo/client"
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { CardBooking } from "./component"

export const BookingList = () => {
  const [filterTab, setFilterTab] = useState("all")
  const { business } = useApplicationContext()
  const { data: bookingNotif, loading: loadingNotif } = useSubscription(
    BOOKING_CREATE_NOTIFICATION,
    {
      variables: {
        args: {
          businessId: business?.id
        }
      }
    }
  )
  const {
    data: dataPending,
    refetch: refetchPending,
    loading: loadingPending
  } = useQuery(BOOKING_PENDING, {
    variables: {
      businessId: business?.id
    },
    skip: !business
  })
  const {
    data: dataValid,
    refetch: refetchValid,
    loading: loadingValid
  } = useQuery(BOOKING_VALIDATE, {
    variables: {
      businessId: business?.id
    },
    skip: !business
  })

  const {
    data: dataReject,
    refetch: refetchReject,
    loading: loadingReject
  } = useQuery(BOOKING_REJECTED, {
    variables: {
      businessId: business?.id
    },
    skip: !business
  })

  const [validateFn] = useMutation<
    ValidateReservation,
    ValidateReservationVariables
  >(VALIDATE_BOOKING)
  const [rejectFn] = useMutation<
    RejectNewReservation,
    RejectNewReservationVariables
  >(REJECT_BOOKING)

  const validate = (id: number) => {
    validateFn({
      variables: {
        reservationId: id
      }
    }).then(() => {
      refetchPending()
      refetchValid()
    })
  }

  const reject = (id: number) => {
    rejectFn({
      variables: {
        reservationId: id
      }
    }).then(() => {
      refetchPending()
      refetchReject()
    })
  }

  const filterTabFn = (event: SelectChangeEvent) => {
    setFilterTab(event.target.value as string)
  }

  const dateNowFilter = (item: any[]) => {
    const nowDate = moment().format("DD/MM/YYYY")
    const dataDay = item.filter((item: { createdAt: moment.MomentInput }) => {
      const createdDate = moment(item.createdAt).format("DD/MM/YYYY")

      return createdDate === nowDate
    })

    return dataDay
  }

  const dateWeekFilter = (item: any[]) => {
    const currentDate = moment()
    const weekStart = currentDate.clone().startOf("isoWeek")

    let days: string[] = []

    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, "days").format("DD/MM/YYYY"))
    }

    const dataWeek = item.filter((item: { createdAt: moment.MomentInput }) => {
      const createdDate = moment(item.createdAt).format("DD/MM/YYYY")

      return days.includes(createdDate)
    })

    return dataWeek
  }

  const eventFilter = (item: any[]) => {
    if (item.length > 0) return item.filter((item) => item.eventParty !== null)
  }

  const allFilter = (item: any[]) => {
    if (item && item.length > 0)
      return item.filter((item) => item.archive !== true)
  }

  const filterTabObj: { [index: string]: any } = {
    now: (data: any[]) => dateNowFilter(data) || [],
    week: (data: any[]) => dateWeekFilter(data) || [],
    event: (data: any[]) => eventFilter(data) || [],
    all: (data: any[]) => allFilter(data) || []
  }

  useMemo(() => {
    if (bookingNotif && !loadingNotif) {
      refetchPending()
    }
  }, [bookingNotif, loadingNotif])

  useEffect(() => {
    setInterval(() => {
      refetchPending()
    }, 30000)
  }, [])
  return (
    <Box sx={{ p: 2 }}>
      <Box
        marginBottom={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box>
          <Typography variant="h5">Tableau des réservations :</Typography>
        </Box>
        <Box width={250}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filtre</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Filtre"
              value={filterTab}
              onChange={filterTabFn}
            >
              <MenuItem value={"now"}>Aujourd'hui</MenuItem>
              <MenuItem value={"week"}>Cette semaine</MenuItem>
              <MenuItem value={"event"}>Evénement</MenuItem>
              <MenuItem value={"all"}>Tous</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Box
            sx={{
              borderRadius: "12px",
              backgroundColor: "#EFEFEF",
              padding: 2
            }}
          >
            <Box mb={2}>
              <Typography variant="h5">En attente</Typography>
            </Box>
            <QueryResult data={dataPending} loading={loadingPending}>
              <>
                {dataPending?.pendingReservation.length > 0 &&
                  filterTabObj[filterTab](dataPending?.pendingReservation)
                    .reverse()
                    .map((item: IPending) => (
                      <CardBooking
                        key={item.id}
                        data={item}
                        onConfirm={validate}
                        onReject={reject}
                      />
                    ))}
              </>
            </QueryResult>
          </Box>
        </Grid>

        <Grid item md={4}>
          <Box
            sx={{
              borderRadius: "12px",
              backgroundColor: "#CDF0EA",
              padding: 2
            }}
          >
            <Box mb={2}>
              <Typography variant="h5">Validé</Typography>
            </Box>
            <QueryResult data={dataValid} loading={loadingValid}>
              <>
                {dataValid?.validReservation.length > 0
                  && filterTabObj[filterTab](dataValid?.validReservation)
                    .reverse()
                    .map((item: IValid) => (
                      <CardBooking
                        key={item.id}
                        data={item}
                        onConfirm={validate}
                        onReject={reject}
                        status
                      />
                    ))
                }
              </>
            </QueryResult>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box
            sx={{
              borderRadius: "12px",
              backgroundColor: "#ffebe5",
              padding: 2
            }}
          >
            <Box mb={2}>
              <Typography variant="h5">Refusé</Typography>
            </Box>
            <QueryResult data={dataReject} loading={loadingReject}>
              <>
                {dataReject?.rejectedReservation.length > 0
                  && filterTabObj[filterTab](dataReject?.rejectedReservation)
                    .reverse()
                    .map((item: IReject) => (
                      <CardBooking
                        key={item.id}
                        data={item}
                        onConfirm={validate}
                        onReject={reject}
                        status
                      />
                    ))
                }
              </>
            </QueryResult>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
