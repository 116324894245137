import { Route, Routes } from "react-router-dom"
import { Category } from "./components/Category/Category"
import { Produits } from "./Produits"
import EditCategory from "./components/Category/EditiCategory/EditCategory"
import { GererCategory } from "./components/Category/GererCategory"
import EditSousCategory from "./components/SousCategory/EditSousCategory/EditSousCategory"
import UpdateProduct from "./components/Produits/UpdateProduct/UpdateProduct"
import { ModalProduits } from "./components/Produits/ModalProduits/ModalProduits"

export const ProductRoutes = () => {
  return (
    <Routes>
      <Route index element={<Category />} />
      <Route path=":idEdit" element={<EditCategory />} />
      <Route path=":idCategory/sousCategory" element={<GererCategory />} />
      <Route
        path="sousCategory/:idEditSousCategory"
        element={<EditSousCategory />}
      />
      <Route
        path=":idCategory/product"
        element={<Produits categoryProps="category" />}
      />
      <Route
        path=":idCategory/sub-category/:idSubCategory/product"
        element={<Produits categoryProps="subCategory" />}
      />
      <Route path="/product-list" element={<Produits />} />
      <Route path="/product-list/add-product" element={<ModalProduits />} />
      <Route
        path=":idCategory/product/add-product"
        element={<ModalProduits />}
      />
      <Route
        path=":idCategory/sub-category/:idSubCategory/product/add-product"
        element={<ModalProduits />}
      />
      <Route path="/product-list/:idEditProduct" element={<UpdateProduct />} />
      <Route
        path=":idCategory/product/:idEditProduct"
        element={<UpdateProduct />}
      />
    </Routes>
  )
}
