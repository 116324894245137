import { Box } from "@mui/material"
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react"
import { CustomModal } from "../../components"
import { Address } from "../authentication/Signup/page/Address"
import { BusinessDescription } from "../authentication/Signup/page/BusinessDescription"
import { FicheDiner } from "../authentication/Signup/page/DinerServices"
import { FicheBusiness } from "../authentication/Signup/page/FicheBusiness"
import { LogoAndCover } from "../authentication/Signup/page/LogoAndCover"
import { MenusPhotos } from "../authentication/Signup/page/MenusPhotos"
import { PlatPhotos } from "../authentication/Signup/page/PlatPhotos"
import { FicheUser } from "../authentication/Signup/page/UserInfo"

interface AddNewBusinessProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onFinish?: () => void
}

enum StepEnum {
  FICHE = "FICHE",
  ADDRESS = "ADDRESS",
  INFOS = "INFOS",
  DESCR = "DESCR",
  DINER = "DINER",
  MENUS = "MENUS",
  PLATS = "PLATS",
  LOGO = "LOGO"
}

export const AddNewBusiness: FC<AddNewBusinessProps> = ({
  open,
  setOpen,
  onFinish
}) => {
  const [step, setStep] = useState<StepEnum>(StepEnum.FICHE)
  const Step = useMemo(() => {
    switch (step) {
      case StepEnum.FICHE:
        return <FicheBusiness afterSave={() => setStep(StepEnum.INFOS)} />
      case StepEnum.INFOS:
        return <FicheUser afterSave={() => setStep(StepEnum.ADDRESS)} />
      case StepEnum.ADDRESS:
        return (
          <Address
            afterSave={(isFood: boolean) => {
              if (isFood) {
                setStep(StepEnum.DINER)
              } else {
                setStep(StepEnum.DESCR)
              }
            }}
          />
        )
      case StepEnum.DINER:
        return <FicheDiner afterSave={() => setStep(StepEnum.DESCR)} />
      case StepEnum.DESCR:
        return (
          <BusinessDescription
            afterSave={(isFood: boolean) => {
              if (isFood) {
                setStep(StepEnum.MENUS)
              } else {
                setStep(StepEnum.LOGO)
              }
            }}
          />
        )
      case StepEnum.MENUS:
        return <MenusPhotos afterSave={() => setStep(StepEnum.PLATS)} />
      case StepEnum.PLATS:
        return <PlatPhotos afterSave={() => setStep(StepEnum.LOGO)} />
      case StepEnum.LOGO:
        return (
          <LogoAndCover
            admin
            afterSave={() => {
              if (onFinish) {
                onFinish()
              }
              setOpen(false)
            }}
          />
        )
      default:
        return <p>.</p>
    }
  }, [onFinish, setOpen, step])

    /****responsive styles */
    const mobilWidth = {
      
      "@media(max-width:663px)" : {
        minWidth: "inherit"
      }
    }
  
    /*****end */
  return (
    <CustomModal
      noAction
      title="Nouveau établissement"
      open={open}
      setOpen={setOpen}
    >
      <Box sx={{ minWidth: 550 , ...mobilWidth }}>{Step}</Box>
    </CustomModal>
  )
}
