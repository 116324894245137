import "../../../../../style.css"
import { BSType, PaletteInput, ThemeInput } from "@/types"
import { FC } from "react"
import { Box, Card, Grid, Typography, Button } from "@mui/material"
import { useApplicationContext } from "@/hooks"
import { convertEnumBs, Profil } from "../CustomPage"
import { QueryResult } from "@/components"
import { BaseBtn } from "./BaseBtn"
import { useQuery } from "@apollo/client"
import {
  PRODUCT_BY_BUSINESS,
  BUSINESS_GET_CLICK_BY_ID
} from "@/graphql/business"
import { Product_getproductById } from "@/graphql/business/types/Product"
import { Add, Remove, ShoppingCartOutlined } from "@mui/icons-material"
import { ContainerWithFooter } from "./layouts/ContainerWithFooter"
import { FormatPrice } from "@/components/FormatPrice"

interface BusinessViewProps {
  theme: ThemeInput
  palette: PaletteInput
  profil: Profil
}

export const BusinessView: FC<BusinessViewProps> = ({
  theme,
  palette,
  profil
}) => {
  const { business } = useApplicationContext()
  const { data, loading } = useQuery(PRODUCT_BY_BUSINESS, {
    variables: {
      businessId: business?.id
    },
    skip: !business
  })
  const { data: dataCurrency } = useQuery(BUSINESS_GET_CLICK_BY_ID, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })

  return (
    <ContainerWithFooter theme={theme} palette={palette} profil={profil}>
      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <BaseBtn
            theme={theme}
            className="buttonClass"
            variant="outlined"
            sx={{
              borderRadius: "5px",
              borderWidth: "0px",
              borderColor: "rgb(0, 0, 0)",
              width: "100%",
              maxWidth: 300,
              height: "60px",
              "&:hover": {
                borderColor: "rgb(0, 0, 0)"
              }
            }}
          >
            <span
              style={{ width: "50px" }}
              className="icon-Illustration-7"
            ></span>
            Reserver
          </BaseBtn>
        </Box>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <BaseBtn
            theme={theme}
            variant="outlined"
            sx={{
              borderRadius: "5px",
              borderWidth: "0px",
              borderColor: "rgb(0, 0, 0)",
              width: "100%",
              maxWidth: 300,
              height: "60px",
              "&:hover": {
                borderColor: "rgb(0, 0, 0)"
              }
            }}
          >
            <span
              style={{ width: "50px" }}
              className="icon-Illustration-12"
            ></span>
            commander
          </BaseBtn>
        </Box>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <BaseBtn
            theme={theme}
            variant="outlined"
            sx={{
              borderRadius: "5px",
              borderWidth: "0px",
              borderColor: "rgb(0, 0, 0)",
              width: "100%",
              maxWidth: 300,
              height: "60px",
              "&:hover": {
                borderColor: "rgb(0, 0, 0)"
              }
            }}
          >
            <span
              style={{ width: "50px" }}
              className="icon-Illustration-9"
            ></span>
            Horaires
          </BaseBtn>
        </Box>
      </Box>

      <QueryResult data={data} loading={loading}>
        <>
          <Grid
            sx={{ mt: 4 }}
            container
            rowSpacing={4}
            columnSpacing={2}
            justifyContent="center"
          >
            {data?.productByBusiness
              .slice(0, 4)
              .map((item: Product_getproductById) => (
                <Grid item xs={6} sm={4} key={item.id}>
                  <Card
                    sx={{
                      borderRadius: 5,
                      border: "1px solid #9c9a97",
                      background: theme?.colorCardProduct,
                      boxShadow: "none",
                      width: "100%",
                      height: "330px",
                      position: "relative",
                      overflow: "visible"
                    }}
                  >
                    <Box
                      sx={{
                        p: 1,
                        borderRadius: "30px",
                        objectFit: "cover",
                        backgroundSize: "auto",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "bottom",
                        width: "100%",
                        height: "150px"
                      }}
                    >
                      <img
                        src={item.image || ""}
                        alt="produit"
                        style={{
                          width: "100%",
                          height: "150px",
                          borderRadius: "30px"
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        p: 1,
                        maxHeight: 110,
                        height: "70px",
                        overflow: "hidden"
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: "center",
                          textOverflow: "ellipsis",
                          color: theme?.colorTextCardProduct,
                          width: "100%",
                          overflow: "hidden",
                          textShadow: "1px 1px rgba(0,0,0, 0.4)"
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          width: "100%",
                          color: theme?.colorTextCardProduct,
                          // textOverflow: "ellipsis",
                          textAlign: "center",
                          overflow: "hidden",
                          textShadow: "1px 1px rgba(0,0,0, 0.4)"
                          // whiteSpace: "nowrap"
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                    <Card
                      sx={{
                        mt: 0,
                        justifyContent: "center",
                        background: theme?.colorCardProduct
                      }}
                      elevation={0}
                    >
                      <Grid>
                        <Box
                          sx={{
                            minHeight: "45px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "center"
                          }}
                        >
                          <Typography
                            sx={{
                              display: "inline-block",
                              fontWeight: "bold",
                              width: "max-content",
                              color: theme?.colorTextCardProduct,
                              textShadow: "1px 1px rgba(0,0,0, 0.4)"
                            }}
                            variant="body2"
                          >
                            <FormatPrice
                              position={
                                dataCurrency?.clickCollectById?.position ||
                                "left"
                              }
                              currency={
                                dataCurrency?.clickCollectById?.currency ||
                                "euro"
                              }
                              separator={
                                dataCurrency?.clickCollectById
                                  ?.thousandsSeparator || "space"
                              }
                              decimal={
                                dataCurrency?.clickCollectById
                                  ?.decimalSeparator || "coma"
                              }
                              decNum={
                                dataCurrency?.clickCollectById?.decimalNumber ||
                                2
                              }
                              value={item.prixTTC || 0}
                            />
                          </Typography>
                          <Box sx={{ display: "flex" }}>
                            {theme?.bsType === convertEnumBs(BSType.SQUARE) ? (
                              <Button
                                sx={{
                                  minWidth: 20,
                                  p: 1,
                                  fontSize: "1.5em",
                                  "& span": {
                                    "::before": {
                                      width: 20,
                                      height: 20,
                                      color: `${theme?.colorBtn} !important`
                                    }
                                  }
                                }}
                                size="small"
                              >
                                <span className="icon-Illustration-10"></span>
                              </Button>
                            ) : (
                              <BaseBtn
                                theme={theme}
                                sx={{ minWidth: 20, p: 1, fontSize: "1.5em" }}
                                size="small"
                              >
                                <Remove />
                              </BaseBtn>
                            )}
                            <Typography
                              variant="h5"
                              sx={{
                                display: "flex",
                                color: theme?.colorTextCardProduct,
                                alignItems: "center",
                                mx: 1,
                                textShadow: "1px 1px rgba(0,0,0, 0.4)"
                              }}
                            >
                              0
                            </Typography>
                            {theme?.bsType === convertEnumBs(BSType.SQUARE) ? (
                              <Button
                                sx={{
                                  minWidth: 20,
                                  p: 1,
                                  fontSize: "1.5em",
                                  "& span": {
                                    "::before": {
                                      width: 20,
                                      height: 20,
                                      color: `${theme?.colorBtn} !important`
                                    }
                                  }
                                }}
                                size="small"
                              >
                                <span className="icon-Illustration-11"></span>
                              </Button>
                            ) : (
                              <BaseBtn
                                theme={theme}
                                sx={{ minWidth: 20, p: 1, fontSize: "1.5em" }}
                                size="small"
                              >
                                <Add />
                              </BaseBtn>
                            )}
                          </Box>
                        </Box>
                        <Grid
                          sx={{
                            width: "60px",
                            height: "60px",
                            display: "flex",
                            backgroundColor: theme.colorCardProduct,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            border: `1px solid ${theme.colorBtn}`,
                            position: "absolute",
                            bottom: "-25px",
                            left: 0,
                            right: 0,
                            marginLeft: "auto",
                            marginRight: "auto"
                          }}
                        >
                          <BaseBtn
                            theme={theme}
                            sx={{
                              minWidth: "0",
                              width: "80%",
                              height: "80%",
                              borderRadius: "50% !important",
                              border: `1px solid ${theme.colorBtnText}`
                            }}
                          >
                            <span
                              style={{
                                textTransform: "none",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "13px"
                              }}
                            >
                              <ShoppingCartOutlined />
                            </span>
                          </BaseBtn>
                        </Grid>
                      </Grid>
                    </Card>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </>
      </QueryResult>
    </ContainerWithFooter>
  )
}
