import { AdminType } from "@/types"
import { CircularProgress } from "@mui/material"
import { Navigate } from "react-router-dom"
import { useApplicationContext } from "../hooks"
import { UserAuthStateEnum } from "../types/UserType"

export const PrivateRoute = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  const { userAuthStatus, business, user } = useApplicationContext()
  const isAdmin = user?.type === AdminType.ADMIN

  if (userAuthStatus === UserAuthStateEnum.WAITING) {
    return <CircularProgress />
  }

  if (userAuthStatus === UserAuthStateEnum.AUTHENTICATED) {
    localStorage.removeItem("newBusiness")

    if (business && !business?.status && !isAdmin) {
      return <Navigate to="/payment" />
    }

    return children
  }

  return <Navigate to="/login" />
}

export const PublicRoute = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  const { userAuthStatus } = useApplicationContext()

  if (userAuthStatus === UserAuthStateEnum.WAITING) {
    return <CircularProgress />
  }
  if (userAuthStatus === UserAuthStateEnum.AUTHENTICATED) {

    return <Navigate to="/discount" />
  }
  return children
}
