import { Button, ButtonProps, styled } from "@mui/material"
import { FC, ReactNode, useEffect, useState } from "react"
import { useApplicationContext } from "@/hooks"
import { ThemeInput } from "@/types"

interface StyledBtnProps {
  colorBtn: string | undefined
  colorText: string | undefined
  police: string | undefined
  fontSize: string | undefined
}

const StyledBtn = styled(Button, {
  shouldForwardProp: (props) =>
    props !== "colorBtn" &&
    props !== "colorText" &&
    props !== "police" &&
    props !== "fontSize"
})<StyledBtnProps>(({ colorBtn, colorText, police, fontSize }) => ({
  textTransform: "none",
  backgroundColor: `${colorBtn} !important`,
  color: `${colorText} !important`,
  fontFamily: `${police} !important`,
  fontSize: `${fontSize} !important`,
  "span::before": {
    color: `${colorText}`
  }
}))

interface BaseBtnProps {
  theme: ThemeInput
  children?: ReactNode
  className?: string
  size?: "small" | "large" | "medium"
  variant?: "text" | "outlined" | "contained"
  fullWidth?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  sx?: any
}

export const BaseBtn: FC<BaseBtnProps> = ({
  children,
  onClick,
  theme,
  size,
  sx,
  className,
  fullWidth,
  variant
}) => {

  return (
    <StyledBtn
      variant={variant}
      colorBtn={theme?.colorBtn || "#FE724C"}
      colorText={theme?.colorBtnText || "#FFFFFF"}
      police={theme?.police || "Poppins"}
      fontSize={theme?.fontSize || "14px"}
      sx={sx}
      size={size}
      onClick={onClick}
      className={className}
      fullWidth={fullWidth || false}
    >
      {children}
    </StyledBtn>
  )
}
