import { gql } from "@apollo/client"

export const BUSINESS_LIST = gql`
  query BusinessList($skip: Float!, $take: Float!) {
    businessList(skip: $skip, take: $take) {
      id
      logo
      businessName
      password
      email
      lastname
      firstname
      activity
      phone
      siret
      url
      description
      siteweb
      address
      facebook
      linkedin
      twitter
      mentions
      conditions
      instagram
      seoDescription
      seoTitle
      googleTag
      fbTag
      metas
      confidentiality
      googleBusiness
      coverPhoto
      createdAt
      updatedAt
      dinerId
      dinerServicesId
      abonnementId
      clients {
        createdAt
      }
      Reservation {
        createdAt
      }
      Commands {
        createdAt
      }
      QRCode {
        createdAt
      }
      clientCount
      Utilisateur {
        id
        email
        firstname
        lastname
        type
        photo
        createdAt
        businessId
      }
      diner {
        id
        surplace
        emporter
        drive
        serviceDrive
        delevery
        deliveryNoContact
        createdAt
        updatedAt
      }
      platPhotos {
        id
        url
      }
      menusPhotos {
        id
        url
      }
      businessHours {
        id
        day
        start
        end
        name
        createdAt
      }
      files {
        id
        url
        fileType
        type
        name
        createdAt
      }
      Abonnements {
        abonnementId
        bookingCount
        businessId
        clickCount
        subscribeCount
        stripeSubscriptionId
        status
        payementUrl
  Abonnement {
        id
        name
        numberBooking
        numberClick
        numberSubscription
        prixTtc
        prixAbonnement
      }
      }
    }
  }
`
export const INFOS_NUMBER_ADMIN = gql`
  query AdminInfoNumber {
    adminInfoNumber {
      business
      users
      clients
      menuPhoto
      platPhoto
      qrcode
      lots
      qrcodeActvie
    }
  }
`

export const CLIENT_LIST = gql`
  query ClientsList($skip: Float!, $take: Float!) {
    clientsList(skip: $skip, take: $take) {
      id
      email
      phone
      auth
      firstname
      lastname
      photo
      createdAt
      sendinblueId
    }
  }
`
