/* eslint-disable react/no-unescaped-entities */
import { useParams } from "react-router-dom"
import { useState } from "react"
import { Typography, Box, Grid, Card, Button } from "@mui/material"
import { useMutation } from "@apollo/client"
import { useApplicationContext, useCurrentBusiness } from "@/hooks"
import { CustomTextInput } from "@/components/forms/CustomTextInput"

import {
  UpdateBusinessInfo,
  UpdateBusinessInfoVariables,
  UPDATE_BUSINESS_INFO
} from "@/graphql/business"
import { BusinessUpdateInput } from "@/types"
import Swal from "sweetalert2"
import { CSSProperties } from "react"

export const EtablissementSeo = (): JSX.Element => {
  const { id } = useParams()
  const { business } = useApplicationContext()
  const { data, refetch } = useCurrentBusiness(
    business?.id || parseFloat(id as string)
  )

  const [updateInfo] = useMutation<
    UpdateBusinessInfo,
    UpdateBusinessInfoVariables
  >(UPDATE_BUSINESS_INFO)

  const [formValue, setFormValue] = useState<BusinessUpdateInput>({
    seoTitle: data?.seoTitle,
    seoDescription: data?.seoDescription,
    googleTag: data?.googleTag,
    fbTag: data?.fbTag,
    metas: data?.metas
  })

  const containerStyle: CSSProperties = {
    backgroundColor: "white",
    borderRadius: "4px"
  }

  const handleSaveInfo = async (): Promise<void> => {
    try {
      await updateInfo({
        variables: {
          businessId: business?.id || parseFloat(id as string),
          business: formValue
        }
      })
      Swal.fire({
        icon: "success",
        title: "modification terminée",
        text: ""
      })
      refetch()
      return undefined
    } catch (errors) {
      Swal.fire({
        icon: "error",
        title: "modification échouée",
        text: ""
      })
      return undefined
    }
  }
  const noSeotitle = `${data?.businessName} | ${data?.activity}`

  const handleSetState = (value: string, name: string) => {
    setFormValue({
      ...formValue,
      [name]: value
    })
  }

        /****responsive styles */
        const tabletteWidth = {
      
          "@media(max-width:1999px)" : {
            width: "100%"
          }
        }
      
        /*****end */

  return (
    <>
      <Box sx={{ py: 2, ...containerStyle }}>
        <Grid container columnSpacing={1} sx={{ px: 2 }} > 
          <Grid item lg={6} md={12} sx={{ paddingBottom: 2 }} >
            <Card sx={{ p: 2 }} elevation={0}>
              <Box
                sx={{
                  height: "10vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <Typography variant="subtitle1">Titre du site</Typography>
                <CustomTextInput
                  fullWidth
                  editable
                  nodivider
                  defaultValue={data?.seoTitle || noSeotitle}
                  name="seoTitle"
                  onSave={handleSetState}
                />
              </Box>
            </Card>
            <Card sx={{ p: 2 }} elevation={0}>
              <Box>
                <Typography variant="subtitle1">Description du site</Typography>
                <CustomTextInput
                  nodivider
                  name="seoDescription"
                  onSave={handleSetState}
                  defaultValue={data?.seoDescription || data?.description || ""}
                  multiline
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "19.5vh",
                      alignItems: "flex-start"
                    }
                  }}
                />
              </Box>
            </Card>
          </Grid>
          <Grid item lg={6} md={12} sx={{ paddingBottom: 2 }} >
            <Grid item lg={12}>
              <Card sx={{ p: 2 }} elevation={0}>
                <Box
                  sx={{
                    height: "10vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      p: 1.5,
                      backgroundColor: "#ffebe5",
                      fontWeight: "Bold"
                    }}
                  >
                    TAG RESEAUX SOCIAUX
                  </Typography>
                </Box>
                <Grid container rowSpacing={2}>
                  <Grid item lg={12} md={3} xs={12} sx={{ marginInline: 1 }}>
                    <Box>
                      <Typography variant="subtitle1">
                        Google Tag/Pixel
                      </Typography>

                      <CustomTextInput
                        fullWidth
                        nodivider
                        name="googleTag"
                        onSave={handleSetState}
                        defaultValue={data?.googleTag || ""}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={3} xs={12} sx={{ marginInline: 1 }}>
                    <Box>
                      <Typography variant="subtitle1">
                        Facebook Tag/Pixel
                      </Typography>
                      <CustomTextInput
                        fullWidth
                        nodivider
                        name="fbTag"
                        onSave={handleSetState}
                        defaultValue={data?.fbTag || ""}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={3} xs={12} sx={{ marginInline: 1 }}>
                    <Box>
                      <Typography variant="subtitle1">
                        Ajouter autres tag...
                      </Typography>
                      <CustomTextInput
                        fullWidth
                        nodivider
                        name="metas"
                        onSave={handleSetState}
                        defaultValue={data?.metas || ""}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
        <Button variant="contained" onClick={handleSaveInfo} sx={{ color: "#050005" }}>
          Enregistrer les modifications
        </Button>
      </Box>
    </>
  )
}
