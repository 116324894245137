import { FC } from "react"
import { Box } from "@mui/material"
import { useParams } from "react-router-dom"
import { TabsType, TabsWithRoutes } from "@/components"
import { EtablissementInformations } from "@/components/Etablissement/Informations/Informations"
import { EtablissementSiteAndSocial } from "@/components/Etablissement/SiteAndSocial/SiteAndSocial"
import { EtablissementUsers } from "@/components/Etablissement/Users/Users"
import { EtablissementSeo } from "@/components/Etablissement/Seo/Seo"
import { useApplicationContext } from "@/hooks"
import { AdminType } from "@/types"
import { ClickNCollect } from "@/components/ClickNCollect"
import { TagsComponent } from "@/components/Tags/TagsComponent"

interface TabsEtablismentProps extends TabsType {
  client?: boolean
}

const tabs: TabsEtablismentProps[] = [
  {
    component: <EtablissementInformations />,
    id: "1",
    label: "Informations de la société",
    index: true,
    client: true
  },
  {
    component: <TagsComponent />,
    id: "2",
    label: "Tags",
    path: "tags",
    client: true
  },
  {
    component: <EtablissementSiteAndSocial />,
    id: "3",
    label: "Site web et réseaux sociaux d'établissements",
    path: "site-social",
    client: true
  },
  {
    component: <EtablissementUsers />,
    id: "4",
    label: "Utilisateur",
    path: "users",
    client: true
  },
  {
    component: <EtablissementSeo />,
    id: "5",
    label: "SEO",
    path: "seo",
    client: true
  },
  {
    component: <ClickNCollect />,
    id: "9",
    label: "Click'n collect",
    path: "clickncollect",
    client: true
  }
]
interface GererEtablissementProps {
  isBusiness?: boolean
}
export const GererEtablissement: FC<GererEtablissementProps> = ({
  isBusiness
}): JSX.Element => {
  const { id } = useParams()
  const { user } = useApplicationContext()

  const tab = tabs.filter((tb) => {
    if (user?.type === AdminType.ADMIN) return true
    return tb.client
  })
  return (
    <Box sx={{ p: 2 }}>
      <TabsWithRoutes
        tabs={tab}
        pathBase={
          isBusiness
            ? `/discount/etablissements`
            : `/discount/etablissements/${id}`
        }
      />
    </Box>
  )
}
