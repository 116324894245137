import { Avatar, Box, Typography, useTheme, Grid, Card, CardContent, Chip, Select, MenuItem, CircularProgress } from "@mui/material"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu"
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant"
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner"
import CasinoIcon from "@mui/icons-material/Casino"
import CropFreeIcon from "@mui/icons-material/CropFree"
import DomainAddIcon from "@mui/icons-material/DomainAdd"
import { useInfoNumber } from "../../../hooks/infosNumber/useInfoNumber"
import { South, North } from "@mui/icons-material"
import { CartesianGrid, ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Legend, Line } from "recharts"
import { useApplicationContext } from "../../../hooks"
import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_COUNT } from "@/graphql/business"
import { GetCountPeriod, GetCountPeriodVariables } from "@/graphql/business/types/GetCountPeriod"

export const Dashboard = (): JSX.Element => {
  const theme = useTheme()
  const { data } = useInfoNumber()
  const [periode, setPeriode] = useState("WEEK")
  const { business } = useApplicationContext()
  const [active, setActive] = useState(0)
  const { data: dataCount, loading, refetch } = useQuery<GetCountPeriod, GetCountPeriodVariables>(GET_COUNT, {
    variables: {
      businessId: business?.id || 0,
      periode: periode
    }
  })

  useEffect(() => {
    refetch()
  }, [periode])

  return (
    <Box p={2}>
      <Box>
        <Typography variant="h3" sx={{ mb: 3, fontWeight: "bold" }}>Resumé des activités</Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={0}>
              <Box sx={{
                [theme.breakpoints.up("md")]: {
                  display: "flex",
                  justifyContent: "space-between"
                }
              }}>
                <Box sx={{
                  mb: 2,
                  display: "block",
                  [theme.breakpoints.up("md")]: {
                    display: "flex"
                  }
                }}>
                  <Box sx={{
                    m: 3,
                    [theme.breakpoints.up("md")]: {
                      display: "none"
                    }
                  }}>
                    <Select
                      value={periode}
                      onChange={(e: any) => setPeriode(e.target.value)}
                    >
                      <MenuItem value="WEEK"> semaine</MenuItem>
                      <MenuItem value="MONTH">1 mois</MenuItem>
                      <MenuItem value="THREE">3 mois</MenuItem>
                      <MenuItem value="SIX">6 mois</MenuItem>
                    </Select>
                  </Box>
                  <Box sx={{
                    p: 3,
                    borderTop: active === 1 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                    background: active === 1 ? "#f5f5f5" : "transparent"
                  }}
                    onClick={() => setActive(1)}
                  >
                    <Typography variant="body1" sx={{ mb: 1 }}>Visiteurs</Typography>
                    <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.visitorCount}</Typography>
                    <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.visitorPourcentage + "%"} />
                  </Box>
                  <Box sx={{
                    p: 3,
                    borderTop: active === 2 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                    background: active === 2 ? "#f5f5f5" : "transparent"
                  }}
                    onClick={() => setActive(2)}
                  >
                    <Typography variant="body1" sx={{ mb: 1 }}>Nouveaux inscripts</Typography>
                    <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.customerCount}</Typography>
                    <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.customerPourcentage + "%"} />
                  </Box>
                  <Box sx={{
                    p: 3,
                    borderTop: active === 3 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                    background: active === 3 ? "#f5f5f5" : "transparent"
                  }}
                    onClick={() => setActive(3)}
                  >
                    <Typography variant="body1" sx={{ mb: 1 }}>Gagnants</Typography>
                    <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.winnerCount}</Typography>
                    <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.winnerPourcentage + "%"} />
                  </Box>
                  <Box sx={{
                    p: 3,
                    borderTop: active === 4 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                    background: active === 4 ? "#f5f5f5" : "transparent"
                  }}
                    onClick={() => setActive(4)}
                  >
                    <Typography variant="body1" sx={{ mb: 1 }}>Commandes</Typography>
                    <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.commandCount}</Typography>
                    <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.commandPourcentage + "%"} />
                  </Box>
                  <Box sx={{
                    p: 3,
                    borderTop: active === 5 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                    background: active === 5 ? "#f5f5f5" : "transparent"
                  }}
                    onClick={() => setActive(5)}
                  >
                    <Typography variant="body1" sx={{ mb: 1 }}>Reservations</Typography>
                    <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.bookingCount}</Typography>
                    <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.bookingPourcentage + "%"} />
                  </Box>
                </Box>
                <Box sx={{
                  m: 3,
                  [theme.breakpoints.down("md")]: {
                    display: "none"
                  }
                }}>
                  <Select
                    value={periode}
                    onChange={(e: any) => setPeriode(e.target.value)}
                  >
                    <MenuItem value="WEEK"> semaine</MenuItem>
                    <MenuItem value="MONTH">1 mois</MenuItem>
                    <MenuItem value="THREE">3 mois</MenuItem>
                    <MenuItem value="SIX">6 mois</MenuItem>
                  </Select>
                </Box>
              </Box>
              <CardContent sx={{ width: "100%", minHeight: 400 }}>
                {
                  loading ? <CircularProgress /> :
                    <ResponsiveContainer height={400} width="100%">
                      <LineChart
                        data={dataCount?.GetCountPeriod.customerChart}
                        width={500}
                        height={300}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line name="Semaine precedente" type="monotone" dataKey="pv" stroke="#000000" strokeDasharray="5 5" />
                        <Line name="Semaine en cours" type="monotone" dataKey="uv" stroke="#feca00" activeDot={{ r: 8 }} />
                      </LineChart>
                    </ResponsiveContainer>
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <GroupAddIcon />
              </Avatar>

              <Typography variant="h2">{data?.users || 0}</Typography>
              <Typography>Nombre d'utilisateurs</Typography>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <DomainAddIcon />
              </Avatar>
              <Typography variant="h2">{data?.business || 0}</Typography>
              <Typography>Nombre d'établissements</Typography>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <QrCodeScannerIcon />
              </Avatar>
              <Typography variant="h2">{data?.qrcode || 0}</Typography>
              <Typography>Nombre de QR Code créés</Typography>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <CropFreeIcon />
              </Avatar>
              <Typography variant="h2">{data?.qrcodeActvie || 0}</Typography>
              <Typography>Nombre de QR Code Active</Typography>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <CasinoIcon />
              </Avatar>
              <Typography variant="h2">{data?.lots || 0}</Typography>
              <Typography>Nombre de Lots crées</Typography>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <RestaurantMenuIcon />
              </Avatar>
              <Typography variant="h2">{data?.menuPhoto || 0}</Typography>
              <Typography>Nombre de Menus Photo</Typography>
            </Card>
          </Grid>
          <Grid item md={3} xs={12}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <TableRestaurantIcon />
              </Avatar>
              <Typography variant="h2">{data?.platPhoto || 0}</Typography>
              <Typography>Nombre de Plat Photo</Typography>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box >
  )
}
