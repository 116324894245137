type priceObj = {
  priceProcessed: number
  totalPrice: number
}

export const usePriceCalculation = (
  priceHt: number,
  tax: number,
  promotion: number
): priceObj => {
  const taxProcessed = (priceHt * tax) / 100
  const promoProcessed = (priceHt * promotion) / 100
  const pricePromoProcessed = priceHt - promoProcessed
  const priceProcessed = pricePromoProcessed + taxProcessed
  const totalPrice = priceHt + taxProcessed
  return { priceProcessed, totalPrice }
}
