import {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useMemo,
  FC
} from "react"

export enum EnumQrcodeRoute {
  LIST = "LIST",
  HOME = "HOME",
  CREATE = "CREATE",
  LOTS = "LOTS",
  EDIT_QRCODE = "EDIT_QRCODE",
  FONT_QRCODE = "FONT_QRCODE",
  BTN_QRCODE = "BTN_QRCODE"
}

interface QrcodeRouteContextType {
  currentQrcodeId?: number
  routeQrcode: EnumQrcodeRoute
  setRouteQrcode: Dispatch<SetStateAction<EnumQrcodeRoute>>
  setCurrentQrcodeId: Dispatch<SetStateAction<number | undefined>>
}

export const QrcodeRouteContext = createContext<QrcodeRouteContextType>(
  {} as QrcodeRouteContextType
)

export const QrcodeContextProvider: FC = ({ children }) => {
  const [routeQrcode, setRouteQrcode] = useState<EnumQrcodeRoute>(
    EnumQrcodeRoute.LIST
  )
  const [currentQrcodeId, setCurrentQrcodeId] = useState<number>()
  const memoizedContect = useMemo(
    () => ({
      routeQrcode,
      currentQrcodeId,
      setRouteQrcode,
      setCurrentQrcodeId
    }),
    [currentQrcodeId, routeQrcode]
  )
  return (
    <QrcodeRouteContext.Provider value={memoizedContect}>
      {children}
    </QrcodeRouteContext.Provider>
  )
}
