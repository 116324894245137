/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete, Edit } from "@mui/icons-material"
import { Typography, Box, Button } from "@mui/material"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { ButtonLittleIcon, ButtonStatus, TableColumnProps } from "@/components"
import {
  BusinessCategoryBusinessCategory as ICategory,
  BusinessSousCategory_businessSousCategory as ISousCategory
} from "@/graphql/business"
import defaultPictures from "@/assets/logoSimple.png"

type TypeDel = {
  id: number
  name: string
}
interface TableCategoryProps {
  onView?: (b: ICategory) => void
  onRemove?: (id: number, type: TypeDel) => void
  onEdit?: (idEdit: number) => void
  onEditStatus?: (item: boolean, id: number) => void
  onEditSubStatus?: (item: boolean, id: number) => void
  onViewSous?: (idCategory: number) => void
  onEditSousCategory?: (idSousCategory: number) => void
}

export const TableCategory = ({
  onRemove,
  onEditSousCategory,
  onEdit,
  onEditStatus,
  onEditSubStatus
}: TableCategoryProps): { columns: TableColumnProps[] } => {
  const navigate = useNavigate()
  const columns: TableColumnProps[] = [
    {
      id: "image",
      label: "Image",
      component: (data: ICategory) => (
        <Box
          sx={{
            width: 100,
            height: 100,
            m: 2,
            borderRadius: "5px",
            position: "relative"
          }}
        >
          <img
            src={data.image || defaultPictures}
            alt=""
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
      )
    },
    {
      id: "name",
      label: "Nom ",
      component: (data: ICategory) => <Typography>{data.name}</Typography>
    },
    {
      id: "categoryPrincipale",
      label: "Catégorie principale ",
      component: (data: ISousCategory) => (
        <Typography>{data.Category?.name}</Typography>
      )
    },
    {
      id: "date",
      label: "Date",
      component: (data: ICategory) => (
        <Typography>{moment(data.createdAt).format("DD/MM/YYYY")}</Typography>
      )
    },
    {
      id: "status",
      label: "Statut",
      component: (data: ICategory) => {
        if (data)
          if (data.Category?.name) {
            return (
              <ButtonStatus
                item={data.status}
                id={data.id}
                onChange={onEditSubStatus}
              />
            )
          }
        return (
          <ButtonStatus
            item={data.status}
            id={data.id}
            onChange={onEditStatus}
          />
        )
      }
    },
    {
      id: "action",
      label: "Action",
      component: (data: ICategory) => (
        <Box sx={{ display: "flex", justifyContent: "space-around" , alignItems: "center" ,  marginBottom: {md: 0 , xs: 5}  }}>
          {data.Category?.name ? (
            <ButtonLittleIcon
              icon={<Edit />}
              color="success"
              onClick={() => {
                if (onEditSousCategory) {
                  onEditSousCategory(data.id)
                }
              }}
              sx={{ marginInline: "5px" }}
            />
          ) : (
            <ButtonLittleIcon
              icon={<Edit />}
              color="success"
              onClick={() => {
                if (onEdit) {
                  onEdit(data.id)
                }
              }}
              sx={{ marginInline: "5px" }}
            />
          )}
          <ButtonLittleIcon
            icon={<Delete />}
            color="error"
            onClick={() => {
              if (onRemove) {
                onRemove(data.id, data.Category)
              }
            }}
            sx={{ marginInline: "5px" }}
          />
          {data.Category?.name ? (
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`${data.Category.id}/sub-category/${data.id}/product`)
              }
              sx={{ color: "#050005"}}
            >
              Voir Produit
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => navigate(`${data.id}/product`)}
              sx={{ color: "#050005" , marginInline: "5px"  }}
            >
              Voir Produit
            </Button>
          )}
        </Box>
      )
    }
  ]
  return { columns }
}
