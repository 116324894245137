import { useState, FC } from "react"
import { Box } from "@mui/material"
import HomeWorkIcon from "@mui/icons-material/HomeWork"
import { useParams } from "react-router-dom"
import { TabsWithRoutes, TabsWithRoutesProps } from "../../../components"
import { ActiviteInfos } from "../../Clients/ActiviteInfos/ActiviteInfos"
import { ClientInfos } from "../../Clients/ClientInfos/ClientInfos"

const tabs: TabsWithRoutesProps["tabs"] = [
  {
    component: <ClientInfos />,
    id: "1",
    label: "INFORMATIONS",
    index: true
  },
  {
    component: <ActiviteInfos />,
    id: "2",
    label: "ACTIVITES",
    path: "activitie"
  }
]
interface GererContactProps {
  isContact?: boolean
}

export const GererContact: FC<GererContactProps> = ({
  isContact
}): JSX.Element => {
  const { id } = useParams()
  return (
    <Box sx={{ p: 2 }}>
      <TabsWithRoutes
        tabs={tabs}
        pathBase={isContact ? `/discount/contact/` : `/discount/contact/${id}`}
      />
    </Box>
  )
}
