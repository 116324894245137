import { ThemeInput } from "@/types"
import { Typography, styled, TypographyProps } from "@mui/material"
import { FC } from "react"

interface TypographyUpdatedProps {
  theme: ThemeInput
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "inherit"
    | "button"
    | "overline"
  sx?: any
  children: React.ReactNode
}

interface StyledTypographyProps extends TypographyProps {
  fontSize: string
  fontFamily: string
  color: string
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (props) =>
    props !== "fontSize" && props !== "fontFamily" && props !== "color"
})<StyledTypographyProps>(({ fontSize, fontFamily, color }) => ({
  fontSize: fontSize,
  fontFamily: fontFamily,
  color: color
}))

export const TypographyUpdated: FC<TypographyUpdatedProps> = ({
  theme,
  variant,
  sx,
  children
}) => {
  return (
    <StyledTypography
      variant={variant}
      fontFamily={theme.police || "Poppins"}
      fontSize={theme.fontSize || "16px"}
      color={theme.colorText || "white"}
      sx={sx}
    >
      {children}
    </StyledTypography>
  )
}
