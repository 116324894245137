import { ButtonLittleIcon, TableColumnProps } from "@/components"
import { GetAllListTemplate_GetAllListTemplate as GetTemplateType } from "@/graphql/emailing/types/GetAllListTemplate"
import { Edit } from "@mui/icons-material"
import Delete from "@mui/icons-material/Delete"
import { Box } from "@mui/material"

interface TableProps {
  onDelete: (item: number) => void
  onEdite: (item: number) => void
}

export const TableTemplate = ({ onEdite, onDelete }: TableProps): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "id",
      label: "ID",
      component: (data: GetTemplateType) => <Box>{data.id}</Box>
    },
    {
      id: "name",
      label: "Nom de l'expéditeur",
      component: (data: GetTemplateType) => <Box>{data.name}</Box>
    },
    {
      id: "email",
      label: "Mail de l'expéditeur",
      component: (data: GetTemplateType) => <Box>{data.email}</Box>
    },
    {
      id: "subject",
      label: "Sujet",
      component: (data: GetTemplateType) => <Box>{data.subject}</Box>
    },
    {
      id: "templateName",
      label: "Nom de template",
      component: (data: GetTemplateType) => <Box>{data.nameTemplate}</Box>
    },
    {
      id: "templateId",
      label: "ID template Sendinblue",
      component: (data: GetTemplateType) => <Box>{data.idSendinblue}</Box>
    },
    {
      id: "action",
      label: "Action",
      component: (data: GetTemplateType) => (
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mr: 1 }}>
            <ButtonLittleIcon color="success" icon={<Edit />} onClick={() => onEdite(data.id)} />
          </Box>
          <Box>
            <ButtonLittleIcon color="error" icon={<Delete />} onClick={() => onDelete(data.id)} />
          </Box>
        </Box>
      )
    }
  ]
  return {
    columns
  }
}
