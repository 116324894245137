import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import AppBar from "@mui/material/AppBar"
import { Box, CircularProgress } from "@mui/material"

interface CustomModalProps {
  isDeleting?: boolean
  noConfirm?: boolean
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  title?: string
  onConfirm?: () => void
  onCancel?: () => void
  confirmLabel?: string
  cancelLabel?: string
  loading?: boolean
  children?: React.ReactNode
}
export const ConfirmModal: React.FC<CustomModalProps> = ({
  title,
  isDeleting,
  confirmLabel,
  noConfirm,
  cancelLabel,
  loading,
  onCancel,
  open,
  setOpen,
  onConfirm,
  children
}): JSX.Element => {
  const handleClose = (): void => {
    setOpen(false)
  }

  const handleConfirm = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    setOpen(false)
    onConfirm && onConfirm()
  }
  const handleCancel = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    setOpen(false)
    onCancel && onCancel()
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar position="relative" color="default">
        {title && (
          <DialogTitle
            id="alert-dialog-title"
            color={isDeleting ? "error" : undefined}
          >
            {title}
          </DialogTitle>
        )}
      </AppBar>
      <DialogContent>
        {children}
        <Box sx={{ minWidth: 350 }} />
      </DialogContent>
      <AppBar position="relative" color="default">
        <DialogActions>
          {!loading ? (
            <Button variant="outlined" onClick={handleCancel}>
              {cancelLabel || "Annuler"}
            </Button>
          ) : null}

          {!noConfirm && (
            <Button
              disabled={loading}
              startIcon={
                loading ? (
                  <CircularProgress style={{ width: 25, height: 25 }} />
                ) : null
              }
              variant="contained"
              onClick={handleConfirm}
              autoFocus
            >
              {confirmLabel || "Confirmer"}
            </Button>
          )}
        </DialogActions>
      </AppBar>
    </Dialog>
  )
}
