import { Box, styled } from "@mui/material"
import FacebookLogin from "react-facebook-login"
import { FC } from "react"
import { FacebookIcon } from "../../icons/icons"

interface FacebookButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSigninFb: (data: any) => void
}

const StyledBox = styled(Box)(() => ({
  "& button": {
    backgroundColor: "white",
    color: "inherit",
    fontFamily: "inherit",
    fontWeight: "inherit",
    alignItems: "center",
    display: "flex",
    border: "none",
    borderRadius: "50px !important",
    height: 40,
    width: "100%",
    justifyContent: "center"
  }
}))

export const FacebookButton: FC<FacebookButtonProps> = ({
  onSigninFb
}): JSX.Element => {
  return (
    <StyledBox>
      <FacebookLogin
        appId="644701063537096"
        fields="name,email,picture"
        callback={onSigninFb}
        icon={<FacebookIcon />}
        textButton="FACEBOOK"
      />
    </StyledBox>
  )
}
