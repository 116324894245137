import { FC } from "react"
import { ApplicationContextProvider } from "./application"
import { QrcodeContextProvider } from "./QrcodeContext"
import { SnackbarCustomProvider } from "./snackbar"

export const ContextProvider: FC = ({ children }) => {
  return (
    <ApplicationContextProvider>
      <QrcodeContextProvider>
        <SnackbarCustomProvider>{children}</SnackbarCustomProvider>
      </QrcodeContextProvider>
    </ApplicationContextProvider>
  )
}
