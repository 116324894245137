import {
  BaseTable,
  QueryResult,
  CustomModal,
  ConfirmModal,
  ResearchBox
} from "@/components"
import { useNavigate } from "react-router-dom"
import {
  CREATE_LIST,
  DELETE_LIST,
  UPDATE_LIST,
  ADD_CONTACT,
  GET_BUSINESS_CONTACT,
  GET_CONTACT_LIST
} from "@/graphql/emailing"
import { useAllClient, useApplicationContext } from "@/hooks"
import { useQuery, useMutation } from "@apollo/client"
import { Box, Typography, Button, TextField, Autocomplete } from "@mui/material"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { TableListContact } from "./TableListContact"
import AddIcon from "@mui/icons-material/Add"
import useSimpleDebounce from "@/hooks/useSimpleDebounce"
import { TableContact } from "./TableContact"

interface ContactInfoType {
  id: number | null
  label: string | null
  email: string | null
  sendinblueId: number | null
}

interface ListInfoType {
  id: number
  listSendinblueId: number
}

export const ListContact = () => {
  const { business } = useApplicationContext()
  const navigate = useNavigate()
  const [viewId, setViewId] = useState(0)
  const { data, loading, refetch } = useQuery(GET_BUSINESS_CONTACT, {
    variables: {
      businessId: business?.id
    }
  })
  const {
    data: viewData,
    loading: viewLoading,
    refetch: viewRefetch
  } = useQuery(GET_CONTACT_LIST, {
    variables: {
      listId: viewId
    },
    skip: !viewId
  })
  const [createList] = useMutation(CREATE_LIST)
  const [updateList] = useMutation(UPDATE_LIST)
  const [deleteList] = useMutation(DELETE_LIST)
  const [addContactFn] = useMutation(ADD_CONTACT)
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteId, setDeleteId] = useState<number | null>(null)
  const [listInfo, setListInfo] = useState<ListInfoType | null>(null)
  const [listName, setListName] = useState("")
  const [editOpen, setEditOpen] = useState(false)
  const [addOpen, setAddOpen] = useState(false)
  const [viewOpen, setViewOpen] = useState(false)
  const [addInfo, setAddInfo] = useState<ContactInfoType | null>()
  const [options] = useState({
    take: 50,
    skip: 0
  })
  const { data: allClient, loading: loadClient } = useAllClient(
    options.take,
    options.skip
  )
  const handleCancelDelete = () => {
    setOpenDelete(false)
    setDeleteId(null)
  }
  const { columns } = TableListContact({
    onAdd: (id) => {
      navigate("../add-contact/" + id)
    },
    onEdit: (item, name) => {
      setListName(name)
      setDeleteId(item)
      setEditOpen(true)
    },
    onDelete: (item) => {
      setDeleteId(item)
      setOpenDelete(true)
    },
    onView: (item) => {
      setViewId(item)
      setViewOpen(true)
    }
  })
  const { columnsView } = TableContact()
  const submitList = () => {
    createList({
      variables: {
        listName,
        businessId: business?.id
      }
    })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Création de liste avec succès",
          text: "",
          showConfirmButton: false,
          showCloseButton: false
        })
        refetch()
        setListName("")
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Erreur lors de la création",
          text: "",
          showConfirmButton: false,
          showCloseButton: false
        })
      })
  }
  const confirmEdition = () => {
    updateList({
      variables: {
        listId: deleteId,
        name: listName
      }
    })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Modification réussie",
          text: "",
          showConfirmButton: false,
          showCloseButton: false
        })
        refetch()
        setListName("")
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Erreur de modification",
          text: "",
          showConfirmButton: false,
          showCloseButton: false
        })
      })
  }
  const confirmDeletionList = () => {
    deleteList({
      variables: {
        listId: deleteId
      }
    })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Suppression réussi",
          text: "",
          showConfirmButton: false,
          showCancelButton: false
        })
        refetch()
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Suppression échoué",
          text: "",
          showCancelButton: false,
          showConfirmButton: false
        })
      })
  }
  const confirmAddContact = () => {
    Swal.fire("Sauvegarde en cours")
    Swal.showLoading()
    addContactFn({
      variables: {
        data: {
          clientId: addInfo?.id,
          email: addInfo?.email,
          sendinblueId: addInfo?.sendinblueId,
          listContact: [
            {
              list: listInfo?.id,
              listSendinblueId: listInfo?.listSendinblueId
            }
          ]
        }
      }
    })
      .then(() => {
        setAddInfo(null)
        setListInfo(null)
        Swal.fire({
          icon: "success",
          title: "Ajout réussi",
          text: "",
          showConfirmButton: false,
          showCancelButton: false
        })
        refetch()
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Ajout échoué",
          text: "",
          showConfirmButton: false,
          showCancelButton: false
        })
      })
  }
  const onClose = () => {
    setEditOpen(false)
    setOpen(false)
    setListName("")
  }
  const [searchValue, setSearchValue] = useState<string>()
  const handleSearch = (value: string) => {
    setSearchValue(value.toLowerCase())
  }
  const clearSearch = () => setSearchValue("")
  const searchDebounced = useSimpleDebounce(searchValue, 500)
  const filterList = (b: any) => {
    if (searchDebounced) {
      return b.name?.toLowerCase().includes(searchDebounced)
    }
    return true
  }

  /*****responsive styles */
  const mobileWrap = {
    "@media(max-width:767px)": {
      flexWrap: "wrap",
      justifyContent: "center"
    }
  }
  const xsMb2 = {
    "@media(max-width:767px)": {
      marginBottom: "16px"
    }
  }

  /****end styles */

  useEffect(() => {
    refetch()
  }, [data])

  useEffect(() => {
    viewRefetch()
  }, [viewData])

  return (
    <Box sx={{ m: 2 }}>
      <QueryResult data={data} loading={loading}>
        <BaseTable
          data={data?.getListBusinessContact?.filter(filterList) || []}
          page={1}
          title="Toutes les listes"
          columns={columns}
          topComponent={
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "end",
                ...mobileWrap
              }}
            >
              <Box sx={{ mr: 2, ...xsMb2 }}>
                <ResearchBox
                  placeholder="Rechercher rapide..."
                  onChange={handleSearch}
                  clear={clearSearch}
                />
              </Box>
              <Button
                color="primary"
                variant="outlined"
                sx={{ textTransform: "none", color: "#050005" }}
                onClick={() => setOpen(true)}
              >
                <AddIcon /> Ajouter une liste
              </Button>
            </Box>
          }
        />
      </QueryResult>
      <CustomModal
        title="Créer une liste"
        open={open}
        setOpen={onClose}
        onConfirm={submitList}
      >
        <Box textAlign="center">
          <TextField
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            variant="outlined"
            fullWidth
            label="Nom de la liste"
          />
        </Box>
      </CustomModal>
      <CustomModal
        title="Modification de la liste"
        open={editOpen}
        setOpen={onClose}
        onConfirm={confirmEdition}
      >
        <Box>
          <TextField
            fullWidth
            value={listName}
            variant="outlined"
            onChange={(e) => setListName(e.target.value)}
          />
        </Box>
      </CustomModal>
      <CustomModal
        open={addOpen}
        setOpen={setAddOpen}
        title="Ajouter un contact"
        onConfirm={confirmAddContact}
      >
        <Box mb={2}>Veuillez choisir un contact parmis la liste</Box>
        <Box>
          <QueryResult data={allClient} loading={loadClient}>
            <Autocomplete
              value={addInfo}
              disablePortal
              options={
                allClient?.map((opt) => ({
                  label: opt.email,
                  id: opt.id,
                  email: opt.email,
                  sendinblueId: opt.sendinblueId || null
                })) || []
              }
              onChange={(e, newValue: ContactInfoType | null) => {
                setAddInfo(newValue)
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </QueryResult>
        </Box>
      </CustomModal>
      <CustomModal
        open={viewOpen}
        setOpen={setViewOpen}
        title="Liste contact"
        noAction
        btnClose
      >
        <Box>
          <QueryResult data={viewData} loading={viewLoading}>
            <BaseTable
              data={viewData?.getContactList || []}
              page={1}
              columns={columnsView}
            />
          </QueryResult>
        </Box>
      </CustomModal>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={confirmDeletionList}
        confirmLabel="Supprimer"
        isDeleting
        title="Supprimer une liste"
      >
        <Typography>
          Vous êtes sur le point de supprimé une liste, voulez-vous continué ?
        </Typography>
      </ConfirmModal>
    </Box>
  )
}
