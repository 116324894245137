import { FC } from "react"
import { Alert, AlertTitle, Box, Card, CircularProgress } from "@mui/material"
import { useStyles } from "./styles"
import { LoginForm } from "../../../components"
import logo from "../../../assets/logo.png"
import { SignInProps } from "./interfaces"

export const Login: FC<SignInProps> = ({
  onLogin,
  loading,
  error,
  newBusiness
}): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.bg}>
      <Box className={classes.container}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%"
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {
              newBusiness ? (
                <Box sx={{ mb: 4 }}>
                  <Alert severity="success" variant="filled">
                    <AlertTitle>Félicitation!!!</AlertTitle>
                    Vous avez créé votre établissement. Veuillez vous connecté avec vos identifiant.
                  </Alert>
                </Box>) : ("")
            }
            <Card sx={{ maxWidth: 450, margin: "auto", borderRadius: 3, p: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <img src={logo} alt="chef" style={{ height: '90px' }} />
              </Box>
              <Box py={3}>
                <LoginForm onLogin={onLogin} error={error} />
              </Box>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  )
}
