export const categories: { name: string; type: string }[] = [
  { name: "Abattoir", type: "" },
  { name: "Abbaye", type: "" },
  { name: "Abri", type: "" },
  { name: "Académie de la piscine", type: "" },
  { name: "Académie de police", type: "" },
  { name: "Académie des armes à feu", type: "" },
  { name: "Académie des examens civils", type: "" },
  { name: "Académie des pompiers", type: "" },
  { name: "Académie des sciences", type: "" },
  { name: "Accessoires de meubles", type: "" },
  { name: "Accueil Scout", type: "" },
  { name: "Acheteur de bijoux", type: "" },
  { name: "Acheteur de diamants", type: "" },
  { name: "Acupuncteur", type: "" },
  { name: "Administrateur de la Cour", type: "" },
  { name: "Administrateur de propriété", type: "" },
  { name: "Administrateur scolaire", type: "" },
  { name: "Administration municipale", type: "" },
  { name: "Aéroclub", type: "" },
  { name: "Aéroport", type: "" },
  { name: "Aeroport domestique", type: "" },
  { name: "Aéroport international", type: "" },
  { name: "Aéroport régional", type: "" },
  { name: "Afficher le centre d’accueil", type: "" },
  { name: "Agence d’adoption", type: "" },
  { name: "Agence d’approvisionnement en services agricoles", type: "" },
  { name: "Agence d’assurance", type: "" },
  { name: "Agence d’assurance automobile", type: "" },
  { name: "Agence d’assurance dentaire", type: "" },
  { name: "Agence d’assurance habitation", type: "" },
  { name: "Agence d’assurance locataire", type: "" },
  { name: "Agence d’assurance maladie", type: "" },
  { name: "Agence d’assurance moto", type: "" },
  { name: "Agence d’assurance-vie", type: "" },
  { name: "Agence d’emploi", type: "" },
  { name: "Agence d’évaluation du crédit", type: "" },
  { name: "Agence d’excursions en bateau", type: "" },
  { name: "Agence d’excursions en canoë et kayak", type: "" },
  { name: "Agence de billets d’avion", type: "" },
  { name: "Agence de billets de bus", type: "" },
  { name: "Agence de billets de train", type: "" },
  { name: "Agence de certification", type: "" },
  { name: "Agence de circuits touristiques", type: "" },
  { name: "Agence de commerce électronique", type: "" },
  { name: "Agence de croisière", type: "" },
  { name: "Agence de design", type: "" },
  { name: "Agence de développement économique", type: "" },
  { name: "Agence de divertissement", type: "" },
  { name: "Agence de garde d’enfants", type: "" },
  { name: "Agence de location d’appartements", type: "" },
  { name: "Agence de location d’appartements à court terme", type: "" },
  { name: "Agence de location d’équipement", type: "" },
  { name: "Agence de location d’espaces commerciaux", type: "" },
  { name: "Agence de location d’espaces de bureaux", type: "" },
  { name: "Agence de location de cabines", type: "" },
  { name: "Agence de location de camions", type: "" },
  { name: "Agence de location de condominiums", type: "" },
  { name: "Agence de location de fourgonnettes", type: "" },
  { name: "Agence de location de grues", type: "" },
  { name: "Agence de location de maisons de vacances", type: "" },
  { name: "Agence de location de maisons mobiles", type: "" },
  { name: "Agence de location de motos", type: "" },
  { name: "Agence de location de suites exécutives", type: "" },
  { name: "Agence de location de véhicules récréatifs", type: "" },
  { name: "Agence de location de voitures", type: "" },
  { name: "Agence de location immobilière", type: "" },
  { name: "Agence de mannequins", type: "" },
  { name: "Agence de marketing", type: "" },
  { name: "Agence de marquage automatique", type: "" },
  { name: "Agence de multipropriété", type: "" },
  { name: "Agence de paris", type: "" },
  { name: "Agence de placement BPO", type: "" },
  { name: "Agence de prêt", type: "" },
  { name: "Agence de publicité", type: "" },
  { name: "Agence de recouvrement de créances", type: "" },
  { name: "Agence de service d’aide à domicile", type: "" },
  { name: "Agence de soins infirmiers", type: "" },
  { name: "Agence de tag", type: "" },
  { name: "Agence de talent", type: "" },
  { name: "Agence de tourisme sportif", type: "" },
  { name: "Agence de voyage", type: "" },
  { name: "Agence de voyages", type: "" },
  { name: "Agence de voyages d’observation des baleines", type: "" },
  { name: "Agence de voyages en bus", type: "" },
  { name: "Agence de voyages en hélicoptère", type: "" },
  { name: "Agence de voyages en montgolfière", type: "" },
  { name: "Agence de voyages SCUBA", type: "" },
  { name: "Agence des permis de conduire et de véhicules", type: "" },
  { name: "Agence fédérale de secours technique", type: "" },
  { name: "Agence House Sitter", type: "" },
  { name: "Agence immobilière", type: "" },
  { name: "Agence immobilière commerciale", type: "" },
  { name: "Agence immobilière industrielle", type: "" },
  { name: "Agence photo", type: "" },
  { name: "Agence temporaire", type: "" },
  { name: "Agenceur", type: "" },
  { name: "Agent commercial", type: "" },
  { name: "Agent d’argent mobile", type: "" },
  { name: "Agent d’expédition de véhicules", type: "" },
  { name: "Agent de la circulation", type: "" },
  { name: "Agent de passeport", type: "" },
  { name: "Agents immobiliers", type: "" },
  { name: "Agriculteur de camions", type: "" },
  { name: "Aide à domicile", type: "" },
  { name: "Aire de jeux intérieure", type: "" },
  { name: "Aire de jeux pour voitures miniatures", type: "" },
  { name: "Aire de restauration", type: "FOOD_SERVICES" },
  { name: "Ajusteur de perte", type: "" },
  { name: "Allée de bowling", type: "" },
  { name: "Allergologue", type: "" },
  { name: "Ambassade", type: "" },
  { name: "Amphithéâtre", type: "" },
  { name: "Anesthésiste", type: "" },
  { name: "Animalerie", type: "" },
  { name: "Anodiseur", type: "" },
  { name: "Appartement de vacances", type: "" },
  { name: "Appeler la boutique", type: "" },
  { name: "Approvisionnement de la fabrication alimentaire", type: "" },
  { name: "Aquarium", type: "" },
  { name: "Arboretum", type: "" },
  { name: "Arboriste et chirurgien arboricole", type: "" },
  { name: "Architecte", type: "" },
  { name: "Architecte paysagiste", type: "" },
  { name: "Archiver", type: "" },
  { name: "Archives d’État", type: "" },
  { name: "Archives militaires", type: "" },
  { name: "Arène", type: "" },
  { name: "Arène", type: "" },
  { name: "Armurerie", type: "" },
  { name: "Arpenteur", type: "" },
  { name: "Arpenteur agréé", type: "" },
  { name: "Arpenteur-géomètre", type: "" },
  { name: "Arrêt de camion", type: "" },
  { name: "Arrêt de repos", type: "" },
  { name: "Arrêt routier à péage", type: "" },
  { name: "Art Café", type: "" },
  { name: "Artisan du chocolat", type: "" },
  { name: "Artisanat", type: "" },
  { name: "Artisanat d’art", type: "" },
  { name: "Artiste", type: "" },
  { name: "Artiste", type: "" },
  { name: "Artiste ballon", type: "" },
  { name: "Ashram", type: "" },
  { name: "Asile des orphelins", type: "" },
  { name: "Assemblées de l’Église de Dieu", type: "" },
  { name: "Assistance et services informatiques", type: "" },
  { name: "Assistance téléphonique", type: "" },
  { name: "Assistante Maternelle", type: "" },
  { name: "Association agricole", type: "" },
  { name: "Association culturelle", type: "" },
  { name: "Association d’aide à l’impôt sur le revenu", type: "" },
  { name: "Association de logement", type: "" },
  { name: "Association de soutien aux étudiants", type: "" },
  { name: "Association de soutien aux patients", type: "" },
  { name: "Association des architectes", type: "" },
  { name: "Association des avocats", type: "" },
  { name: "Association des constructeurs de contremaîtres", type: "" },
  { name: "Association des écologistes", type: "" },
  { name: "Association des forces armées", type: "" },
  { name: "Association des infirmières", type: "" },
  { name: "Association des ingénieurs des routes, ports et canaux", type: "" },
  { name: "Association des ingénieurs industriels", type: "" },
  { name: "Association des ingénieurs techniques industriels", type: "" },
  { name: "Association des notaires", type: "" },
  { name: "Association des parents d’élèves", type: "" },
  { name: "Association des propriétaires", type: "" },
  { name: "Association des résidents", type: "" },
  { name: "Association ou organisation", type: "" },
  { name: "Associations professionnelles et de loisirs", type: "" },
  { name: "Assurance protection du revenu", type: "" },
  { name: "Astrologue", type: "" },
  { name: "Atelier", type: "" },
  { name: "Atelier d’impression d’écran", type: "" },
  { name: "Atelier d’outils et de matrices", type: "" },
  { name: "Atelier d’usinage automobile", type: "" },
  { name: "Atelier de carrosserie automobile", type: "" },
  { name: "Atelier de couture", type: "" },
  { name: "Atelier de couture", type: "" },
  { name: "Atelier de freinage", type: "" },
  { name: "Atelier de machines", type: "" },
  { name: "Atelier de menuiserie", type: "" },
  { name: "Atelier de métal", type: "" },
  { name: "Atelier de peinture", type: "" },
  { name: "Atelier de réparation automobile", type: "" },
  { name: "Atelier de réparation d’appareils photo", type: "" },
  { name: "Atelier de réparation d’aspirateurs", type: "" },
  { name: "Atelier de réparation d’instruments de musique", type: "" },
  { name: "Atelier de réparation d’outils", type: "" },
  { name: "Atelier de réparation de bateaux", type: "" },
  { name: "Atelier de réparation de bottes", type: "" },
  { name: "Atelier de réparation de camions", type: "" },
  { name: "Atelier de réparation de chaussures", type: "" },
  { name: "Atelier de réparation de meubles", type: "" },
  { name: "Atelier de réparation de moteurs électriques", type: "" },
  { name: "Atelier de réparation de motos", type: "" },
  { name: "Atelier de réparation de remorques", type: "" },
  { name: "Atelier de réparation de scooter", type: "" },
  { name: "Atelier de réparation de scooter", type: "" },
  { name: "Atelier de réparation de téléphones portables", type: "" },
  { name: "Atelier de réparation de tracteurs", type: "" },
  { name: "Atelier de réparation de vélos", type: "" },
  { name: "Atelier de réparation de VR", type: "" },
  { name: "Atelier de réparation de VTT", type: "" },
  { name: "Atelier de réparation électrique", type: "" },
  { name: "Atelier de réparation électronique", type: "" },
  { name: "Atelier de transmission", type: "" },
  { name: "Atelier de travail du métal", type: "" },
  { name: "Attraction touristique", type: "" },
  { name: "Attribution de terrain", type: "" },
  { name: "AU M", type: "" },
  { name: "Auberge", type: "" },
  { name: "auberge", type: "" },
  { name: "Auberge de chat", type: "" },
  { name: "Auberge de jeunesse", type: "" },
  { name: "Auberge de jeunesse scolaire", type: "" },
  { name: "Auberge de travail pour femmes", type: "" },
  { name: "Auberge japonaise avec source chaude", type: "" },
  { name: "Auberge japonaise économique", type: "" },
  { name: "Auberge pour chiens", type: "" },
  { name: "Auberges japonaises", type: "" },
  { name: "Audiologiste", type: "" },
  { name: "Audit financier", type: "" },
  { name: "Auditeur", type: "" },
  { name: "Auto-école", type: "" },
  { name: "Autorité centrale", type: "" },
  { name: "Autorité portuaire", type: "" },
  { name: "Avion", type: "" },
  { name: "Avis de presse", type: "" },
  { name: "Avocat", type: "" },
  { name: "Avocat", type: "" },
  { name: "Avocat à la sécurité sociale", type: "" },
  { name: "Avocat administratif", type: "" },
  { name: "Avocat aîné", type: "" },
  { name: "Avocat d’immigration", type: "" },
  { name: "Avocat de la justice pénale", type: "" },
  { name: "Avocat de première instance", type: "" },
  { name: "Avocat du travail", type: "" },
  { name: "Avocat en assurance", type: "" },
  { name: "Avocat en divorce", type: "" },
  { name: "Avocat en droit civil", type: "" },
  { name: "Avocat en droit de la famille", type: "" },
  { name: "Avocat en faillite", type: "" },
  { name: "Avocat en planification successorale", type: "" },
  { name: "Avocat en relations de travail", type: "" },
  { name: "Avocat fiscaliste", type: "" },
  { name: "Avocat généraliste", type: "" },
  { name: "Avocat immobilier", type: "" },
  { name: "Avocat spécialisé en dommages corporels", type: "" },
  { name: "Bain de pieds", type: "" },
  { name: "Bain extérieur", type: "" },
  { name: "Bain public", type: "" },
  { name: "Bande", type: "" },
  { name: "Banque", type: "" },
  { name: "Banque alimentaire", type: "" },
  { name: "Banque centrale", type: "" },
  { name: "Banque d’investissement", type: "" },
  { name: "Banque de réserve fédérale", type: "" },
  { name: "Banque de sperme", type: "" },
  { name: "Banque du sang", type: "" },
  { name: "Banque du secteur privé", type: "" },
  { name: "Banque du secteur public", type: "" },
  { name: "Banque Shinkin", type: "" },
  { name: "Bar", type: "" },
  { name: "Bar & Grill", type: "" },
  { name: "Bar à chicha", type: "" },
  { name: "Bar à cocktails", type: "" },
  { name: "Bar à expresso", type: "" },
  { name: "Bar à salsa", type: "" },
  { name: "Bar à tapas", type: "" },
  { name: "Bar à vin", type: "" },
  { name: "Bar de musique live", type: "" },
  { name: "Bar fille", type: "" },
  { name: "Bar gay", type: "" },
  { name: "Bar PMU", type: "" },
  { name: "Bar sportif", type: "" },
  { name: "Bar Tabac", type: "" },
  { name: "Barre à cidre", type: "" },
  { name: "Barre de fléchettes", type: "" },
  { name: "Barre de jambon cru", type: "" },
  { name: "Barre de support", type: "" },
  { name: "Base d’hydravion", type: "" },
  { name: "Base de l’armée de l’air", type: "" },
  { name: "Base des forces armées des États-Unis", type: "" },
  { name: "Base militaire", type: "" },
  { name: "Base navale", type: "" },
  { name: "Base-ball", type: "" },
  { name: "Basilique", type: "" },
  { name: "Bassin de baignade", type: "" },
  { name: "Bâtiment de la Bourse", type: "" },
  { name: "Bazar", type: "" },
  { name: "Belle école", type: "" },
  { name: "Bibliothèque", type: "" },
  { name: "Bibliothèque de droit", type: "" },
  { name: "Bibliothèque de films et de photographies", type: "" },
  { name: "Bibliothèque de jouets", type: "" },
  { name: "Bibliothèque de l’Université", type: "" },
  { name: "bibliothèque nationale", type: "" },
  { name: "Bibliothèque pour enfants", type: "" },
  { name: "Bibliotheque publique", type: "" },
  { name: "Bijouterie", type: "" },
  { name: "Bijoutier", type: "" },
  { name: "Bijoutier en gros", type: "" },
  { name: "Billard Club", type: "" },
  { name: "Bistro", type: "" },
  { name: "Blanchisserie", type: "" },
  { name: "Boatel", type: "" },
  { name: "Boîte de nuit", type: "" },
  { name: "Boîte de nuit gay", type: "" },
  { name: "Bookmaker", type: "" },
  { name: "Boucherie", type: "" },
  { name: "Boucherie Deli", type: "" },
  { name: "Boulangerie", type: "" },
  { name: "Boulangerie de mariage", type: "" },
  { name: "Boulangerie en gros", type: "" },
  { name: "Boutique", type: "" },
  { name: "Boutique à faire soi-même", type: "" },
  { name: "Boutique Aeromodel", type: "" },
  { name: "Boutique bio", type: "" },
  { name: "Boutique d’aquarium", type: "" },
  { name: "Boutique d’enseignes", type: "" },
  { name: "Boutique d’hélices", type: "" },
  { name: "Boutique d’herbes", type: "" },
  { name: "Boutique d’objets d’occasion", type: "" },
  { name: "Boutique d’oiseaux", type: "" },
  { name: "Boutique de bagels", type: "" },
  { name: "Boutique de bijoux fantaisie", type: "" },
  { name: "Boutique de biscuits", type: "" },
  { name: "Boutique de boissons gazeuses", type: "" },
  { name: "Boutique de brioches à la vapeur", type: "" },
  { name: "Boutique de broderie", type: "" },
  { name: "Boutique de cadres photo", type: "" },
  { name: "Boutique de cartes de vœux", type: "" },
  { name: "Boutique de ceinture", type: "" },
  { name: "Boutique de confiserie japonaise", type: "" },
  { name: "Boutique de consignation", type: "" },
  { name: "Boutique de courtepointe", type: "" },
  { name: "Boutique de figurines", type: "" },
  { name: "Boutique de fleurs séchées", type: "" },
  { name: "Boutique de jeans", type: "" },
  { name: "Boutique de jus", type: "" },
  { name: "Boutique de kite", type: "" },
  { name: "Boutique de lit", type: "" },
  { name: "Boutique de loterie", type: "" },
  { name: "Boutique de manteau de fourrure", type: "" },
  { name: "Boutique de mariage", type: "" },
  { name: "Boutique de miroirs", type: "" },
  { name: "Boutique de modèles", type: "" },
  { name: "Boutique de modiste", type: "" },
  { name: "Boutique de nouilles", type: "" },
  { name: "Boutique de nouilles Soba", type: "" },
  { name: "Boutique de paniers-cadeaux", type: "" },
  { name: "Boutique de paris hors piste", type: "" },
  { name: "Boutique de perruques", type: "" },
  { name: "Boutique de petits gâteaux", type: "" },
  { name: "Boutique de piercing", type: "" },
  { name: "Boutique de plongée", type: "" },
  { name: "Boutique de printemps auto", type: "" },
  { name: "Boutique de reprographie", type: "" },
  { name: "Boutique de sacs", type: "" },
  { name: "Boutique de sacs à main", type: "" },
  { name: "Boutique de silencieux", type: "" },
  { name: "Boutique de smoking", type: "" },
  { name: "Boutique de souvenirs", type: "" },
  { name: "Boutique de souvenirs de mariage", type: "" },
  { name: "Boutique de stores", type: "" },
  { name: "Boutique de surplus de l’armée et de la marine", type: "" },
  { name: "Boutique de tabac", type: "" },
  { name: "Boutique de tatouage", type: "" },
  { name: "Boutique de tatouage et piercing", type: "" },
  { name: "Boutique de timbres", type: "" },
  { name: "Boutique de tofu", type: "" },
  { name: "Boutique de trophées", type: "" },
  { name: "Boutique de violon", type: "" },
  { name: "Boutique de yaourt glacé", type: "" },
  { name: "Boutique enseigne au néon", type: "" },
  { name: "Boutique et location de kilt", type: "" },
  { name: "Boutique Feng Shui", type: "" },
  { name: "Boutique Hôtel", type: "" },
  { name: "Boutique Hua Gong", type: "" },
  { name: "Boutique intelligente", type: "" },
  { name: "Boutique Notions", type: "" },
  { name: "Boutique photo", type: "" },
  { name: "Boutique Safe & Vault", type: "" },
  { name: "Boutique Tamale", type: "" },
  { name: "Bowling", type: "" },
  { name: "Brasserie", type: "" },
  { name: "Brasserie", type: "" },
  { name: "Brasserie", type: "" },
  { name: "Brasserie de saké", type: "" },
  { name: "Brasserie Shochu", type: "" },
  { name: "Bricoleur", type: "" },
  { name: "Bubble Tea", type: "" },
  { name: "Buffet de bonbons et de desserts", type: "" },
  { name: "Buffet de fête pour enfants", type: "" },
  { name: "Buffet de mariage", type: "" },
  { name: "Bureau d’aide juridique", type: "" },
  { name: "Bureau d’architecte d’intérieur", type: "" },
  { name: "Bureau d’arpentage", type: "" },
  { name: "Bureau d’enregistrement", type: "" },
  { name: "Bureau d’enregistrement", type: "" },
  { name: "Bureau d’enregistrement des résidents", type: "" },
  { name: "Bureau d’enregistrement foncier", type: "" },
  { name: "Bureau d’enregistrement général", type: "" },
  { name: "Bureau d’examen des véhicules", type: "" },
  { name: "Bureau d’information", type: "" },
  { name: "Bureau d’information des citoyens", type: "" },
  { name: "Bureau d’information sur la convention", type: "" },
  { name: "Bureau d’inscription des électeurs", type: "" },
  { name: "Bureau d’orientation professionnelle des étudiants", type: "" },
  { name: "Bureau de conseil aux citoyens", type: "" },
  { name: "Bureau de construction et d’entretien", type: "" },
  { name: "Bureau de district", type: "" },
  { name: "Bureau de district de la ville", type: "" },
  { name: "Bureau de douane", type: "" },
  { name: "Bureau de douane principal", type: "" },
  { name: "Bureau de l’administration municipale", type: "" },
  { name: "Bureau de l’environnement", type: "" },
  { name: "Bureau de l’état civil", type: "" },
  { name: "Bureau de la ferme", type: "" },
  { name: "Bureau de la sécurité publique", type: "" },
  { name: "Bureau de poste", type: "" },
  { name: "Bureau de probation", type: "" },
  { name: "Bureau de recrutement militaire", type: "" },
  { name: "Bureau de sécurité sociale", type: "" },
  { name: "Bureau des affaires juridiques", type: "" },
  { name: "Bureau des licences", type: "" },
  { name: "Bureau des licences de mariage", type: "" },
  { name: "Bureau des objets trouvés", type: "" },
  { name: "Bureau des passeports", type: "" },
  { name: "Bureau des pensions", type: "" },
  { name: "Bureau des permis de conduire", type: "" },
  { name: "Bureau des procureurs publics", type: "" },
  { name: "Bureau des taxes municipales", type: "" },
  { name: "Bureau des visas et des passeports", type: "" },
  { name: "Bureau du chômage", type: "" },
  { name: "Bureau du défenseur public", type: "" },
  { name: "Bureau du district scolaire", type: "" },
  { name: "Bureau du gouvernement", type: "" },
  { name: "Bureau du gouvernement de l’État", type: "" },
  { name: "Bureau du gouvernement de la préfecture du Japon", type: "" },
  { name: "Bureau du gouvernement du comté", type: "" },
  { name: "Bureau du gouvernement du district", type: "" },
  { name: "Bureau du gouvernement fédéral", type: "" },
  { name: "Bureau du gouvernement local", type: "" },
  { name: "Bureau du gouvernement municipal", type: "" },
  { name: "Bureau du gouvernement régional", type: "" },
  { name: "Bureau du greffier municipal", type: "" },
  { name: "Bureau du percepteur d’impôts", type: "" },
  { name: "Bureau national de l’éducation", type: "" },
  { name: "Cabane à sucre", type: "" },
  { name: "Cabane de montagne", type: "" },
  { name: "Cabanes en rondins", type: "" },
  { name: "Cabine d’appel d’urgence", type: "" },
  { name: "Cabine de bureau d’appel public", type: "" },
  { name: "Cabine de péage", type: "" },
  { name: "Cabinet d’architecture", type: "" },
  { name: "Cabinet d’avocats", type: "" },
  { name: "Cabinet d’expertise comptable", type: "" },
  { name: "Cabinet de capital-investissement", type: "" },
  { name: "Cabinet de recherche de cadres", type: "" },
  { name: "Cabinet de relations publiques", type: "" },
  { name: "Cabinet dentaire", type: "" },
  { name: "Cabinet médical", type: "" },
  { name: "Café", type: "" },
  { name: "Café", type: "" },
  { name: "Café au chocolat", type: "" },
  { name: "Café Cosplay", type: "" },
  { name: "Café de la bande dessinée", type: "" },
  { name: "Café pour chiens", type: "" },
  { name: "Café pour enfants", type: "" },
  { name: "Café végétarien et épicerie fine", type: "" },
  { name: "Cafétéria", type: "" },
  { name: "Caisse", type: "" },
  { name: "Caisse d’épargne", type: "" },
  { name: "Caisse fédérale de crédit", type: "" },
  { name: "Camp", type: "" },
  { name: "Camp d’anglais", type: "" },
  { name: "Camp d’entraînement", type: "" },
  { name: "Camp d’été", type: "" },
  { name: "Camp de pêche", type: "" },
  { name: "Camp de réfugiés", type: "" },
  { name: "Camping", type: "" },
  { name: "Camping à la ferme", type: "" },
  { name: "Campus d’entreprise", type: "" },
  { name: "Capitale", type: "" },
  { name: "Cardiologue", type: "" },
  { name: "Cardiologue pédiatrique", type: "" },
  { name: "Carnaval Club", type: "" },
  { name: "Carrière", type: "" },
  { name: "Carvery", type: "" },
  { name: "Caserne de l’armée", type: "" },
  { name: "Caserne de pompiers", type: "" },
  { name: "Casernes militaires", type: "" },
  { name: "Casier à jetons", type: "" },
  { name: "Casino", type: "" },
  { name: "cathédrale", type: "" },
  { name: "Cathédrale catholique", type: "" },
  { name: "Cave à vin", type: "" },
  { name: "Célébrant de mariage", type: "" },
  { name: "Centrale des communications", type: "" },
  { name: "Centrale électrique", type: "" },
  { name: "Centrale hydroélectrique", type: "" },
  { name: "Centrale nucléaire", type: "" },
  { name: "Centrale solaire photovoltaïque", type: "" },
  { name: "Centre aquatique", type: "" },
  { name: "Centre chirurgical", type: "" },
  { name: "Centre civique", type: "" },
  { name: "Centre commercial", type: "" },
  { name: "Centre communautaire", type: "" },
  { name: "Centre culturel", type: "" },
  { name: "Centre d’affaires", type: "" },
  { name: "Centre d’amusement", type: "" },
  { name: "Centre d’amusement pour enfants", type: "" },
  { name: "Centre d’appel", type: "" },
  { name: "Centre d’apprentissage", type: "" },
  { name: "Centre d’apprentissage", type: "" },
  { name: "Centre d’apprentissage à distance", type: "" },
  { name: "Centre d’art", type: "" },
  { name: "Centre d’artisanat", type: "" },
  { name: "Centre d’échange de bouteilles et de canettes", type: "" },
  { name: "Centre d’éducation", type: "" },
  { name: "Centre d’emploi", type: "" },
  { name: "Centre d’entrainement", type: "" },
  { name: "Centre d’essai de conduite", type: "" },
  { name: "Centre d’étude", type: "" },
  { name: "Centre d’examen physique", type: "" },
  { name: "Centre d’exposition et de commerce", type: "" },
  { name: "Centre d’imagerie diagnostique médicale", type: "" },
  { name: "Centre d’information touristique", type: "" },
  { name: "Centre de bien-être", type: "" },
  { name: "Centre de coaching", type: "" },
  { name: "Centre de conférence", type: "" },
  { name: "Centre de conseils aux consommateurs", type: "" },
  { name: "Centre de contrôle des naissances", type: "" },
  { name: "Centre de deltaplane", type: "" },
  { name: "Centre de dépistage du VIH", type: "" },
  { name: "Centre de détention", type: "" },
  { name: "Centre de détention des immigrants", type: "" },
  { name: "Centre de détention pour mineurs", type: "" },
  { name: "Centre de diagnostic", type: "" },
  { name: "Centre de dialyse", type: "" },
  { name: "Centre de don de sang", type: "" },
  { name: "Centre de dons", type: "" },
  { name: "Centre de formation", type: "" },
  { name: "Centre de gymnastique", type: "" },
  { name: "Centre de jardinage", type: "" },
  { name: "Centre de jeunesse", type: "" },
  { name: "Centre de la cage de frappeur", type: "" },
  { name: "Centre de logement étudiant", type: "" },
  { name: "Centre de loisirs", type: "" },
  { name: "Centre de loisirs", type: "" },
  { name: "Centre de marquage laser", type: "" },
  { name: "Centre de méditation", type: "" },
  { name: "Centre de montage GNC", type: "" },
  { name: "Centre de naissance", type: "" },
  { name: "Centre de neige intérieur", type: "" },
  { name: "Centre de paintball", type: "" },
  { name: "Centre de parachutisme", type: "" },
  { name: "Centre de planification familiale", type: "" },
  { name: "Centre de plongée", type: "" },
  { name: "Centre de protection sociale", type: "" },
  { name: "Centre de recyclage", type: "" },
  { name: "Centre de réhabilitation", type: "" },
  { name: "Centre de remise en état", type: "" },
  { name: "Centre de réparation de trains", type: "" },
  { name: "Centre de réseautage informatique", type: "" },
  { name: "Centre de restauration scolaire", type: "FOOD_SERVICES" },
  { name: "Centre de retraite", type: "" },
  { name: "Centre de retraite de yoga", type: "" },
  { name: "Centre de santé communautaire", type: "" },
  { name: "Centre de saut à l’élastique", type: "" },
  { name: "Centre de services aux familles", type: "" },
  { name: "Centre de soins d’urgence", type: "" },
  { name: "Centre de soins de grossesse", type: "" },
  { name: "Centre de soins de jour pour adultes", type: "" },
  { name: "Centre de soins de santé pour enfants", type: "" },
  { name: "Centre de soins dentaires", type: "" },
  { name: "Centre de soins oculaires", type: "" },
  { name: "Centre de sports aériens", type: "" },
  { name: "Centre de sports d’aventure", type: "" },
  { name: "Centre de traitement de la toxicomanie", type: "" },
  { name: "Centre de traitement de la toxicomanie", type: "" },
  { name: "Centre de traitement de la violence domestique", type: "" },
  { name: "Centre de traitement des troubles de l’alimentation", type: "" },
  { name: "Centre de traitement du cancer", type: "" },
  { name: "Centre des anciens combattants", type: "" },
  { name: "Centre des personnes âgées", type: "" },
  { name: "Centre du diabète", type: "" },
  { name: "Centre de contrôle technique", type: "" },
  { name: "Centre équestre", type: "" },
  { name: "Centre équestre extérieur", type: "" },
  { name: "Centre Hawker", type: "" },
  { name: "Centre IRM", type: "" },
  { name: "Centre maçonnique", type: "" },
  { name: "Centre médical", type: "" },
  { name: "Centre médical public", type: "" },
  { name: "Centre périnatal", type: "" },
  { name: "Centre scolaire", type: "" },
  { name: "Centre spirite", type: "" },
  { name: "Centre sportif pour handicapés", type: "" },
  { name: "Chalet", type: "" },
  { name: "Chambre d’agriculture", type: "" },
  { name: "Chambre d’enregistrement", type: "" },
  { name: "Chambre de commerce", type: "" },
  { name: "Chambre des métiers d’art", type: "" },
  { name: "Chambre du Commerce", type: "" },
  { name: "Champ de tir", type: "" },
  { name: "Champ de tir Skeet", type: "" },
  { name: "Chantier naval", type: "" },
  { name: "Chapelle", type: "" },
  { name: "Chapelle de mariage", type: "" },
  { name: "Charcuterie", type: "" },
  { name: "Charité", type: "" },
  { name: "Charpentier", type: "" },
  { name: "Charte d’hélicoptère", type: "" },
  { name: "Charte de bus", type: "" },
  { name: "Charte de pêche", type: "" },
  { name: "château", type: "" },
  { name: "Château Hôtel", type: "" },
  { name: "Chatterie", type: "" },
  { name: "Chaudronnier", type: "" },
  { name: "Chenil", type: "" },
  { name: "Chercheur de marché", type: "" },
  { name: "Chinois à emporter", type: "" },
  { name: "Chiropracteur", type: "" },
  { name: "Chirurgien", type: "" },
  { name: "Chirurgien de la main", type: "" },
  { name: "Chirurgien dentiste", type: "" },
  { name: "Chirurgien gastro-intestinal", type: "" },
  { name: "Chirurgien LASIK", type: "" },
  { name: "Chirurgien orthopédique", type: "" },
  { name: "Chirurgien plastique", type: "" },
  { name: "Chirurgien vasculaire", type: "" },
  { name: "Chocolaterie", type: "" },
  { name: "Chocolaterie", type: "" },
  { name: "Choisissez vos propres produits de la ferme", type: "" },
  { name: "Chorale", type: "" },
  { name: "Churreria", type: "" },
  { name: "Cimetière", type: "" },
  { name: "Cimetière animalier", type: "" },
  { name: "Cimetière militaire", type: "" },
  { name: "Cinéma", type: "" },
  { name: "Cinéma Drive-in", type: "" },
  { name: "Cinéma en plein air", type: "" },
  { name: "Cirque", type: "" },
  { name: "Classe d’accouchement", type: "" },
  { name: "Classe de couture", type: "" },
  { name: "Classe de mise en forme du corps", type: "" },
  { name: "Classe de travail du bois", type: "" },
  { name: "Classe Mehandi", type: "" },
  { name: "Classer", type: "" },
  { name: "Clinique d’acupuncture", type: "" },
  { name: "Clinique d’avortement", type: "" },
  { name: "Clinique d’ophtalmologie", type: "" },
  { name: "Clinique d’oto-rhino-laryngologie", type: "" },
  { name: "Clinique d’urologie", type: "" },
  { name: "Clinique de chirurgie plastique", type: "" },
  { name: "Clinique de contrôle de la douleur", type: "" },
  { name: "Clinique de fertilité", type: "" },
  { name: "Clinique de greffe de cheveux", type: "" },
  { name: "Clinique de maquillage permanent", type: "" },
  { name: "Clinique de médecine chinoise", type: "" },
  { name: "Clinique de médecine du sport", type: "" },
  { name: "Clinique de médecine orientale", type: "" },
  { name: "Clinique de physiothérapie", type: "" },
  { name: "Clinique de psychopédagogie", type: "" },
  { name: "Clinique de santé des femmes", type: "" },
  { name: "Clinique de santé mentale", type: "" },
  { name: "Clinique de santé reproductive", type: "" },
  { name: "Clinique de soins de la peau", type: "" },
  { name: "Clinique de voyage", type: "" },
  { name: "Clinique du sommeil", type: "" },
  { name: "Clinique gratuite", type: "" },
  { name: "Clinique médicale", type: "" },
  { name: "Clinique orthopédique", type: "" },
  { name: "Clinique sans rendez-vous", type: "" },
  { name: "Clinique spécialisée", type: "" },
  { name: "Clinique spécialisée en psychoneurologie", type: "" },
  { name: "Clinique Std", type: "" },
  { name: "Clinique Tb", type: "" },
  { name: "Clinique vétérinaire", type: "" },
  { name: "club", type: "" },
  { name: "Club athlétique", type: "" },
  { name: "Club automobile", type: "" },
  { name: "Club conservateur", type: "" },
  { name: "Club d’Aikido", type: "" },
  { name: "Club d’anime", type: "" },
  { name: "Club d’arts martiaux", type: "" },
  { name: "Club d’aviron", type: "" },
  { name: "Club d’échecs", type: "" },
  { name: "Club d’échecs et de cartes", type: "" },
  { name: "Club d’entrepôt", type: "" },
  { name: "Club de badminton", type: "" },
  { name: "Club de balle molle", type: "" },
  { name: "Club de baseball", type: "" },
  { name: "Club de basketball", type: "" },
  { name: "Club de blues", type: "" },
  { name: "Club de BMX", type: "" },
  { name: "Club de boxe", type: "" },
  { name: "Club de bridge", type: "" },
  { name: "Club de cabaret", type: "" },
  { name: "Club de canoë et de kayak", type: "" },
  { name: "Club de chasse", type: "" },
  { name: "Club de comédie", type: "" },
  { name: "Club de costumes traditionnels", type: "" },
  { name: "Club de cricket", type: "" },
  { name: "Club de croquet", type: "" },
  { name: "Club de curling", type: "" },
  { name: "Club de danse", type: "" },
  { name: "Club de divertissement pour adultes", type: "" },
  { name: "Club de football", type: "" },
  { name: "Club de football", type: "" },
  { name: "Club de golf", type: "" },
  { name: "Club de gymnastique", type: "" },
  { name: "Club de handball", type: "" },
  { name: "Club de hockey", type: "" },
  { name: "Club de hockey sur glace", type: "" },
  { name: "Club de jazz", type: "" },
  { name: "Club de jeunesse", type: "" },
  { name: "Club de jeux de société", type: "" },
  { name: "Club de judo", type: "" },
  { name: "Club de karaté", type: "" },
  { name: "Club de la ligue de rugby", type: "" },
  { name: "Club de la petite ligue", type: "" },
  { name: "Club de modèles réduits", type: "" },
  { name: "Club de musique rock", type: "" },
  { name: "Club de natation", type: "" },
  { name: "Club de netball", type: "" },
  { name: "Club de padel", type: "" },
  { name: "Club de patinage à roulettes", type: "" },
  { name: "Club de patinage sur glace", type: "" },
  { name: "Club de pêche", type: "" },
  { name: "Club de plongée", type: "" },
  { name: "Club de polo", type: "" },
  { name: "Club de racquetball", type: "" },
  { name: "Club de rugby", type: "" },
  { name: "Club de santé", type: "" },
  { name: "Club de sauna", type: "" },
  { name: "Club de sauvetage de surf", type: "" },
  { name: "Club de ski", type: "" },
  { name: "Club de ski nautique", type: "" },
  { name: "Club de sport", type: "" },
  { name: "Club de squash", type: "" },
  { name: "Club de tennis", type: "" },
  { name: "Club de tennis de table", type: "" },
  { name: "Club de tir à l’arc", type: "" },
  { name: "Club de vélo", type: "" },
  { name: "Club de volleyball", type: "" },
  { name: "Club de volleyball de plage", type: "" },
  { name: "Club des collectionneurs de timbres", type: "" },
  { name: "Club des ligues", type: "" },
  { name: "Club des travailleurs", type: "" },
  { name: "Club des vins", type: "" },
  { name: "Club Disco", type: "" },
  { name: "Club du travail", type: "" },
  { name: "Club enfants", type: "" },
  { name: "Club équestre", type: "" },
  { name: "Club hôte", type: "" },
  { name: "Club informatique", type: "" },
  { name: "Club Kabaddi", type: "" },
  { name: "Club musical", type: "" },
  { name: "Club nautique", type: "" },
  { name: "Club nautique", type: "" },
  { name: "Club nudiste", type: "" },
  { name: "Club RSL", type: "" },
  { name: "Club social", type: "" },
  { name: "Club Yacht", type: "" },
  { name: "Coach de vie", type: "" },
  { name: "Coalfield", type: "" },
  { name: "Cocktail à l’oxygène", type: "" },
  { name: "Coiffeur", type: "" },
  { name: "Coiffeur à domicile", type: "" },
  { name: "Coiffeur mobile", type: "" },
  { name: "Collège", type: "" },
  { name: "Collège chrétien", type: "" },
  { name: "Collège communautaire", type: "" },
  { name: "Collège d’agriculture", type: "" },
  { name: "Collège de musique", type: "" },
  { name: "Collège de sixième forme", type: "" },
  { name: "Collège des enseignants", type: "" },
  { name: "Collège des femmes", type: "" },
  { name: "Collège gouvernemental", type: "" },
  { name: "Collège privé", type: "" },
  { name: "Collège résidentiel", type: "" },
  { name: "Collégien", type: "" },
  { name: "Commissaire à l’assermentation", type: "" },
  { name: "Commissaire-priseur immobilier", type: "" },
  { name: "Communauté de retraite", type: "" },
  { name: "Communication du Département d’État", type: "" },
  { name: "Communication du département municipal", type: "" },
  { name: "Compagnie aérienne", type: "" },
  { name: "Compagnie d’assurance", type: "" },
  { name: "Compagnie d’autobus et d’autocars", type: "" },
  { name: "Compagnie d’énergie nucléaire", type: "" },
  { name: "Compagnie d’opéra", type: "" },
  { name: "Compagnie de bus", type: "" },
  { name: "Compagnie de câble", type: "" },
  { name: "Compagnie de chemin de fer", type: "" },
  { name: "Compagnie de combustibles solides", type: "" },
  { name: "Compagnie de construction", type: "" },
  { name: "Compagnie de croisières", type: "" },
  { name: "Compagnie de danse", type: "" },
  { name: "Compagnie de gaz", type: "" },
  { name: "Compagnie de livraison", type: "" },
  { name: "Compagnie de téléphone", type: "" },
  { name: "Compagnie de théâtre", type: "" },
  { name: "Compagnie des eaux minérales", type: "" },
  { name: "Compagnie des services d’eau", type: "" },
  { name: "Compagnie métropolitaine de train", type: "" },
  { name: "Compagnie pétrolière et gazière", type: "" },
  { name: "Compagnie pharmaceutique", type: "" },
  { name: "Compétition de natation", type: "" },
  { name: "Complexe d’appartements", type: "" },
  { name: "Complexe de badminton", type: "" },
  { name: "Complexe de copropriété", type: "" },
  { name: "Complexe de logement", type: "" },
  { name: "Complexe de maisons de ville", type: "" },
  { name: "Complexe sportif", type: "" },
  { name: "Comptable", type: "" },
  { name: "Comptable agréé", type: "" },
  { name: "Comptoir de billets de train", type: "" },
  { name: "Concepteur d’architecture", type: "" },
  { name: "Concepteur d’éclairage paysager", type: "" },
  { name: "Concepteur de site Web", type: "" },
  { name: "Concessionnaire", type: "" },
  { name: "Concessionnaire Acura", type: "" },
  { name: "Concessionnaire Audi", type: "" },
  { name: "Concessionnaire BMW", type: "" },
  { name: "Concessionnaire Buick", type: "" },
  { name: "Concessionnaire Cadillac", type: "" },
  { name: "Concessionnaire Chevrolet", type: "" },
  { name: "Concessionnaire Chrysler", type: "" },
  { name: "Concessionnaire de bateaux", type: "" },
  { name: "Concessionnaire de camions", type: "" },
  { name: "Concessionnaire de camions à benne basculante", type: "" },
  { name: "Concessionnaire de camions d’occasion", type: "" },
  { name: "Concessionnaire de chariots élévateurs", type: "" },
  { name: "Concessionnaire de cyclomoteurs", type: "" },
  { name: "Concessionnaire de grues", type: "" },
  { name: "Concessionnaire de machines de construction", type: "" },
  { name: "Concessionnaire de maisons mobiles", type: "" },
  { name: "Concessionnaire de maisons modulaires", type: "" },
  { name: "Concessionnaire de moteurs diesel", type: "" },
  { name: "Concessionnaire de motoneiges", type: "" },
  { name: "Concessionnaire de motos d’occasion", type: "" },
  { name: "Concessionnaire de récupération", type: "" },
  { name: "Concessionnaire de remorques", type: "" },
  { name: "Concessionnaire de remorques de bateaux", type: "" },
  { name: "Concessionnaire de remorques pour chevaux", type: "" },
  { name: "Concessionnaire de remorques utilitaires", type: "" },
  { name: "Concessionnaire de scooter", type: "" },
  { name: "Concessionnaire de tracteurs", type: "" },
  { name: "Concessionnaire de véhicules automobiles", type: "" },
  { name: "Concessionnaire de voitures d’occasion", type: "" },
  { name: "Concessionnaire de voitures de course", type: "" },
  { name: "Concessionnaire de voiturettes de golf", type: "" },
  { name: "Concessionnaire Dodge", type: "" },
  { name: "Concessionnaire Ducati", type: "" },
  { name: "Concessionnaire Fiat", type: "" },
  { name: "Concessionnaire Ford", type: "" },
  { name: "Concessionnaire GMC", type: "" },
  { name: "Concessionnaire Harley-Davidson", type: "" },
  { name: "Concessionnaire Honda", type: "" },
  { name: "Concessionnaire Hyundai", type: "" },
  { name: "Concessionnaire indésirable", type: "" },
  { name: "Concessionnaire Infiniti", type: "" },
  { name: "Concessionnaire Isuzu", type: "" },
  { name: "Concessionnaire Jaguar", type: "" },
  { name: "Concessionnaire Jeep", type: "" },
  { name: "Concessionnaire Kia", type: "" },
  { name: "Concessionnaire Land Rover", type: "" },
  { name: "Concessionnaire Lexus", type: "" },
  { name: "Concessionnaire Lincoln Mercury", type: "" },
  { name: "Concessionnaire Mazda", type: "" },
  { name: "Concessionnaire Mercedes Benz", type: "" },
  { name: "Concessionnaire Mitsubishi", type: "" },
  { name: "Concessionnaire moto", type: "" },
  { name: "Concessionnaire moto BMW", type: "" },
  { name: "Concessionnaire moto Kawasaki", type: "" },
  { name: "Concessionnaire moto Suzuki", type: "" },
  { name: "Concessionnaire moto Triumph", type: "" },
  { name: "Concessionnaire moto Yamaha", type: "" },
  { name: "Concessionnaire Nissan", type: "" },
  { name: "Concessionnaire Oldsmobile", type: "" },
  { name: "Concessionnaire Opel", type: "" },
  { name: "Concessionnaire Pontiac", type: "" },
  { name: "Concessionnaire Porsche", type: "" },
  { name: "Concessionnaire Renault", type: "" },
  { name: "Concessionnaire Saab", type: "" },
  { name: "Concessionnaire Saturn", type: "" },
  { name: "Concessionnaire Subaru", type: "" },
  { name: "Concessionnaire Suzuki", type: "" },
  { name: "Concessionnaire Toyota", type: "" },
  { name: "Concessionnaire Volkswagen", type: "" },
  { name: "Concessionnaire Volvo", type: "" },
  { name: "Concessionnaire VR", type: "" },
  { name: "Concessionnaire VTT", type: "" },
  { name: "Confiserie", type: "" },
  { name: "Congrégation", type: "" },
  { name: "Conseil", type: "" },
  { name: "Conseil de district", type: "" },
  { name: "Conseil de l’éducation", type: "" },
  { name: "Conseil en brevets", type: "" },
  { name: "Conseil en ressources humaines", type: "" },
  { name: "Conseil militaire", type: "" },
  { name: "Conseil provincial", type: "" },
  { name: "Conseil régional", type: "" },
  { name: "Conseiller", type: "" },
  { name: "Conseiller de mariage", type: "" },
  { name: "Conseiller en planification familiale", type: "" },
  { name: "Conseiller familial", type: "" },
  { name: "Conseiller financier", type: "" },
  { name: "Conseillère pédagogique", type: "" },
  { name: "Conservatoire de danse", type: "" },
  { name: "Conservatoire de musique", type: "" },
  { name: "Conservatoire de musique", type: "" },
  { name: "Conserverie", type: "" },
  { name: "Constructeur automobile", type: "" },
  { name: "Constructeur d’abris d’auto et de pergola", type: "" },
  { name: "Constructeur d’aéronefs", type: "" },
  { name: "Constructeur d’éoliennes", type: "" },
  { name: "Constructeur de cabanon", type: "" },
  { name: "Constructeur de garage", type: "" },
  { name: "Constructeur de Gazebo", type: "" },
  { name: "Constructeur de maison personnalisé", type: "" },
  { name: "Constructeur de maisons en bois rond", type: "" },
  { name: "Constructeur de maisons modulaires", type: "" },
  { name: "Constructeur de mausolée", type: "" },
  { name: "Constructeur de parcours de golf", type: "" },
  { name: "Constructeur de poêle", type: "" },
  { name: "Constructeur de quais", type: "" },
  { name: "Constructeur de terrasse", type: "" },
  { name: "Constructeurs de bateaux", type: "" },
  { name: "Construction de machines", type: "" },
  { name: "Construction navale", type: "" },
  { name: "Consulat étranger", type: "" },
  { name: "Consultant", type: "" },
  { name: "Consultant acoustique", type: "" },
  { name: "Consultant aéronautique", type: "" },
  { name: "Consultant audiovisuel", type: "" },
  { name: "Consultant éclairage", type: "" },
  { name: "Consultant économique", type: "" },
  { name: "Consultant en aliments et boissons", type: "" },
  { name: "Consultant en assurance-chômage", type: "" },
  { name: "Consultant en bâtiment", type: "" },
  { name: "Consultant en centrale électrique", type: "" },
  { name: "Consultant en commerce extérieur", type: "" },
  { name: "Consultant en commerce international", type: "" },
  { name: "Consultant en douane", type: "" },
  { name: "Consultant en emploi", type: "" },
  { name: "Consultant en environnement", type: "" },
  { name: "Consultant en gestion d’entreprise", type: "" },
  { name: "Consultant en image", type: "" },
  { name: "Consultant en marketing", type: "" },
  { name: "Consultant en pension de retraite", type: "" },
  { name: "Consultant en protection incendie", type: "" },
  { name: "Consultant en santé", type: "" },
  { name: "Consultant fiscal", type: "" },
  { name: "Consultant immobilier", type: "" },
  { name: "Consultant industriel", type: "" },
  { name: "Consultant informatique", type: "" },
  { name: "Consultant média", type: "" },
  { name: "Consultant médico-légal", type: "" },
  { name: "Consultant minier", type: "" },
  { name: "Consultant Vastu", type: "" },
  { name: "Consultant Visa", type: "" },
  { name: "Consultante Feng Shui", type: "" },
  { name: "Conversion de GPL", type: "" },
  { name: "Convoyeur", type: "" },
  { name: "Coopérative agricole", type: "" },
  { name: "Coopérative agricole", type: "" },
  { name: "Coopérative d’huile d’olive", type: "" },
  { name: "Coopérative de logement", type: "" },
  { name: "Copie du magasin de fournitures", type: "" },
  { name: "Corporation municipale", type: "" },
  { name: "Cottage", type: "" },
  { name: "Country Club", type: "" },
  { name: "Country Park", type: "" },
  { name: "Cour de futsal", type: "" },
  { name: "Cour de Padel", type: "" },
  { name: "Cour de récupération", type: "" },
  { name: "Cour de squash", type: "" },
  { name: "Cour de train", type: "" },
  { name: "Cours d’alpinisme", type: "" },
  { name: "Cours d’aromathérapie", type: "" },
  { name: "Cours de Capoeira", type: "" },
  { name: "Cours de cuisine", type: "" },
  { name: "Cours de danse aérodynamique", type: "" },
  { name: "Cours de danse hip hop", type: "" },
  { name: "Cours de dessin", type: "" },
  { name: "Cours de peinture", type: "" },
  { name: "Cours de photographie", type: "" },
  { name: "Cours de poterie", type: "" },
  { name: "Cours de salsa", type: "" },
  { name: "Cours de Surf", type: "" },
  { name: "Court de tennis", type: "" },
  { name: "Courtier alimentaire", type: "" },
  { name: "Courtier automobile", type: "" },
  { name: "Courtier d’assurance", type: "" },
  { name: "Courtier en bourse", type: "" },
  { name: "Courtier en douane", type: "" },
  { name: "Courtier en entreprise", type: "" },
  { name: "Courtier en yacht", type: "" },
  { name: "Courtier financier", type: "" },
  { name: "Courtier hypothécaire", type: "" },
  { name: "Couturière", type: "" },
  { name: "Couvent", type: "" },
  { name: "Covoiturage", type: "" },
  { name: "Créateur de fleurs", type: "" },
  { name: "Créateur de modèles d’architecture et d’ingénierie", type: "" },
  { name: "Créateur de monuments", type: "" },
  { name: "Créateur Mehndi", type: "" },
  { name: "Créatrice de bijoux", type: "" },
  { name: "Crèche", type: "" },
  { name: "Crêperie", type: "" },
  { name: "Cultiver", type: "" },
  { name: "Cyber ​​café", type: "" },
  { name: "Cyber ​​café", type: "" },
  { name: "Cyclisme en salle", type: "" },
  { name: "Cyclisme sur route", type: "" },
  { name: "Décorateur d’intérieur", type: "" },
  { name: "Défense civile", type: "" },
  { name: "Dentiste", type: "" },
  { name: "Dentiste cosmétique", type: "" },
  { name: "Dentiste pédiatrique", type: "" },
  { name: "département d’éducation", type: "" },
  { name: "Département d’État Agriculture Food Supply", type: "" },
  { name: "Département d’État de l’emploi", type: "" },
  { name: "Département d’État de l’environnement", type: "" },
  { name: "Département d’État de la culture", type: "" },
  { name: "Département d’État de la défense civile", type: "" },
  { name: "Département d’État de la défense sociale", type: "" },
  { name: "Département d’État des finances", type: "" },
  { name: "Département d’État des sports", type: "" },
  { name: "Département d’État des transports", type: "" },
  {
    name: "Département d’État du logement et du développement urbain",
    type: ""
  },
  { name: "Département d’État du tourisme", type: "" },
  { name: "Département d’État pour le développement social", type: "" },
  { name: "Département d’État Science Technology", type: "" },
  { name: "Département d’urbanisme", type: "" },
  { name: "Département de la conservation", type: "" },
  { name: "Département de la justice", type: "" },
  { name: "Département de la publicité dans les journaux", type: "" },
  { name: "Département de la santé publique", type: "" },
  { name: "Département de la sécurité publique", type: "" },
  { name: "Département de la sécurité publique de la ville", type: "" },
  { name: "Département des affaires des anciens combattants", type: "" },
  { name: "Département des douanes", type: "" },
  { name: "Département des services correctionnels", type: "" },
  { name: "Département des services sociaux", type: "" },
  { name: "Département des transports", type: "" },
  { name: "Département des transports de la ville", type: "" },
  { name: "Département des travaux publics", type: "" },
  { name: "département des véhicules à moteur", type: "" },
  { name: "Département du développement régional", type: "" },
  { name: "Département du logement", type: "" },
  { name: "Département du shérif", type: "" },
  { name: "Département du trésor de la ville", type: "" },
  { name: "Département financier de la sécurité sociale", type: "" },
  { name: "Département fiscal", type: "" },
  { name: "Département hospitalier", type: "" },
  { name: "Département municipal Agriculture Alimentation", type: "" },
  { name: "Département municipal de l’environnement", type: "" },
  { name: "Département municipal de la culture", type: "" },
  { name: "Département municipal de la défense civile", type: "" },
  { name: "Département municipal de la défense sociale", type: "" },
  { name: "Département municipal de la santé", type: "" },
  { name: "Département municipal des finances", type: "" },
  { name: "Département municipal des sports", type: "" },
  { name: "Département municipal du développement agricole", type: "" },
  {
    name: "Département municipal du logement et du développement urbain",
    type: ""
  },
  { name: "Département municipal du tourisme", type: "" },
  { name: "Département municipal Science Technologie", type: "" },
  { name: "Département universitaire", type: "" },
  { name: "Dépot", type: "" },
  { name: "Dermatologue", type: "" },
  { name: "Designer graphique", type: "" },
  { name: "Destination religieuse", type: "" },
  { name: "Détaillant de loterie", type: "" },
  { name: "Détective", type: "" },
  { name: "Détective privé", type: "" },
  { name: "Développement agricole du département d’État", type: "" },
  { name: "Développement du logement", type: "" },
  { name: "Développement Mercantile", type: "" },
  { name: "Développement social de l’État", type: "" },
  { name: "Développement social municipal", type: "" },
  { name: "Diabétologue", type: "" },
  { name: "Diffuseur radio", type: "" },
  { name: "Dîner-théâtre", type: "" },
  { name: "Directeur des pompes funèbres", type: "" },
  { name: "Disc Golf Course", type: "" },
  { name: "Disciples of Christ Church", type: "" },
  { name: "Discothèque mobile", type: "" },
  { name: "Distillerie", type: "" },
  { name: "Distributeur automatique d’électronique", type: "" },
  { name: "Distributeur automatique de café", type: "" },
  { name: "Distributeur automatique de produits de beauté", type: "" },
  {
    name: "Distributeur automatique de produits de soins de la peau",
    type: ""
  },
  { name: "Distributeur d’acier", type: "" },
  { name: "Distributeur de bière", type: "" },
  { name: "Distributeur de boissons", type: "" },
  { name: "Distributeur de papier", type: "" },
  { name: "Distribution de journaux", type: "" },
  { name: "Domaine commémoratif", type: "" },
  { name: "Don d’organes et banque de tissus", type: "" },
  { name: "Dresseur d’animaux", type: "" },
  { name: "Dresseur de chevaux", type: "" },
  { name: "Earth Works Company", type: "" },
  { name: "Ébéniste", type: "" },
  { name: "Échafaudeur", type: "" },
  { name: "Échange de téléphone", type: "" },
  { name: "Écloserie de poulet", type: "" },
  { name: "École", type: "" },
  { name: "École à charte", type: "" },
  { name: "École aveugle", type: "" },
  { name: "École bilingue", type: "" },
  { name: "École catholique", type: "" },
  { name: "École CBSE", type: "" },
  { name: "École communautaire", type: "" },
  { name: "École culinaire", type: "" },
  { name: "École d’accueil et de tourisme", type: "" },
  { name: "École d’acupuncture", type: "" },
  { name: "Ecole d’Aikido", type: "" },
  { name: "École d’architecture", type: "" },
  { name: "École d’art", type: "" },
  { name: "Ecole d’artisanat", type: "" },
  { name: "École d’arts martiaux", type: "" },
  { name: "École d’assurance", type: "" },
  { name: "École d’autodéfense", type: "" },
  { name: "École d’éducation des adultes", type: "" },
  { name: "École d’éducation spéciale", type: "" },
  { name: "École d’équitation", type: "" },
  { name: "École d’escrime", type: "" },
  { name: "École d’infirmière", type: "" },
  { name: "École d’ingénieur", type: "" },
  { name: "Ecole de ballet", type: "" },
  { name: "École de barman", type: "" },
  { name: "École de camionnage", type: "" },
  { name: "École de capoeira", type: "" },
  { name: "École de coiffeur", type: "" },
  { name: "École de commerce", type: "" },
  { name: "École de comptabilité", type: "" },
  { name: "École de conduite moto", type: "" },
  { name: "École de Cramming", type: "" },
  { name: "École de cuisine", type: "" },
  { name: "école de danse", type: "" },
  { name: "École de design de mode", type: "" },
  { name: "École de ferme", type: "" },
  { name: "École de flamenco", type: "" },
  { name: "École de formation d’urgence", type: "" },
  { name: "École de formation des permis de conduire", type: "" },
  { name: "École de formation en informatique", type: "" },
  { name: "École de gestion", type: "" },
  { name: "École de gestion hôtelière", type: "" },
  { name: "École de gymnase", type: "" },
  { name: "École de judo", type: "" },
  { name: "École de Jujitsu", type: "" },
  { name: "École de karaté", type: "" },
  { name: "École de kickboxing", type: "" },
  { name: "École de Kung Fu", type: "" },
  { name: "École de langue", type: "" },
  { name: "École de langue allemande", type: "" },
  { name: "École de langue anglaise", type: "" },
  { name: "École de langue chinoise", type: "" },
  { name: "École de langue espagnole", type: "" },
  { name: "Ecole de langue française", type: "" },
  { name: "École de lutte", type: "" },
  { name: "Ecole de massage", type: "" },
  { name: "École de mathématiques", type: "" },
  { name: "École de médecine", type: "" },
  { name: "École de modélisation", type: "" },
  { name: "École de musique", type: "" },
  { name: "École de natation", type: "" },
  { name: "École de natation pour bébé", type: "" },
  { name: "École de peinture artistique", type: "" },
  { name: "École de photographie", type: "" },
  { name: "École de pilotage", type: "" },
  { name: "École de samba", type: "" },
  { name: "Ecole de ski", type: "" },
  { name: "École de sport", type: "" },
  { name: "École de surf", type: "" },
  { name: "École de taekwondo", type: "" },
  { name: "École de Tai Chi", type: "" },
  { name: "École de tambour", type: "" },
  { name: "École de théatre", type: "" },
  { name: "École de voile", type: "" },
  { name: "École dentaire", type: "" },
  { name: "École des télécommunications", type: "" },
  { name: "École du programme de langues étrangères", type: "" },
  { name: "École du soir", type: "" },
  { name: "École étudiante", type: "" },
  { name: "École gouvernementale", type: "" },
  { name: "École Icse", type: "" },
  { name: "Ecole immobilière", type: "" },
  { name: "École intermédiaire", type: "" },
  { name: "École internationale", type: "" },
  { name: "École maternelle", type: "" },
  { name: "École militaire", type: "" },
  { name: "École Montessori", type: "" },
  { name: "École paroissiale", type: "" },
  { name: "École pour les sourds", type: "" },
  { name: "École pré-gymnase", type: "" },
  { name: "Ecole préparatoire", type: "" },
  { name: "École primaire", type: "" },
  { name: "École primaire", type: "" },
  { name: "École privée", type: "" },
  { name: "École professionnelle", type: "" },
  { name: "école professionnelle", type: "" },
  { name: "École professionnelle One", type: "" },
  { name: "École publique", type: "" },
  { name: "École religieuse", type: "" },
  { name: "École Sambo", type: "" },
  { name: "École secondaire agricole", type: "" },
  { name: "École secondaire hôtelière", type: "" },
  { name: "École secondaire unisexe", type: "" },
  { name: "École sourde", type: "" },
  { name: "Ecole technique", type: "" },
  { name: "École Waldorf", type: "" },
  { name: "École Wing Chun", type: "" },
  { name: "Édifice patrimonial", type: "" },
  { name: "Éditeur", type: "" },
  { name: "Éditeur de journaux", type: "" },
  { name: "Éditeur de journaux de petites annonces", type: "" },
  { name: "Éditeur de livres", type: "" },
  { name: "Éditeur de livres multimédias et électroniques", type: "" },
  { name: "Éditeur de magazine", type: "" },
  { name: "Éditeur de musique", type: "" },
  { name: "Éditeur de musique imprimée", type: "" },
  { name: "Éducation du bureau municipal", type: "" },
  { name: "Église", type: "" },
  { name: "Église adventiste du septième jour", type: "" },
  { name: "Église anglicane", type: "" },
  { name: "Église apostolique", type: "" },
  { name: "Église arménienne", type: "" },
  { name: "Église baptiste", type: "" },
  { name: "Église biblique", type: "" },
  { name: "église catholique", type: "" },
  { name: "église chrétienne", type: "" },
  { name: "Église coréenne", type: "" },
  { name: "Église de Jésus-Christ des Saints des Derniers Jours", type: "" },
  { name: "Église de l’Alliance", type: "" },
  { name: "Église de l’Évangile", type: "" },
  { name: "Église de l’unité", type: "" },
  { name: "Église de la chapelle du calvaire", type: "" },
  { name: "Église de la Trinité", type: "" },
  { name: "Église de la vie abondante", type: "" },
  { name: "Église des amis", type: "" },
  { name: "Église du Nazaréen", type: "" },
  { name: "Église du Plein Evangile", type: "" },
  { name: "Église du vignoble", type: "" },
  { name: "Église épiscopale", type: "" },
  { name: "Église évangélique", type: "" },
  { name: "Église Foursquare", type: "" },
  { name: "Église hispanique", type: "" },
  { name: "Église luthérienne", type: "" },
  { name: "Église mennonite", type: "" },
  { name: "Eglise Méthodiste", type: "" },
  { name: "Église méthodiste unie", type: "" },
  { name: "Église morave", type: "" },
  { name: "Église New Age", type: "" },
  { name: "Église non confessionnelle", type: "" },
  { name: "église orthodoxe", type: "" },
  { name: "Église orthodoxe grecque", type: "" },
  { name: "Église orthodoxe orientale", type: "" },
  { name: "Église orthodoxe russe", type: "" },
  { name: "Église pentecôtiste", type: "" },
  { name: "Église Presbytérienne", type: "" },
  { name: "Église protestante", type: "" },
  { name: "Église Quaker", type: "" },
  { name: "Église réformée", type: "" },
  { name: "Église sourde", type: "" },
  { name: "Église Unie du Canada", type: "" },
  { name: "Église unie du Christ", type: "" },
  { name: "Église universaliste unitaire", type: "" },
  { name: "Église wesleyenne", type: "" },
  { name: "Électricien", type: "" },
  { name: "Élevage de volailles", type: "" },
  { name: "Éleveur de bétail", type: "" },
  { name: "Éleveur de chevaux", type: "" },
  { name: "Éleveur de chiens", type: "" },
  { name: "Emballeur de viande", type: "" },
  { name: "Emplacement de partage de voiture", type: "" },
  { name: "Enchère automatique", type: "" },
  { name: "Enchère judiciaire", type: "" },
  { name: "Endocrinologue", type: "" },
  { name: "Endodontiste", type: "" },
  { name: "Endoscopiste", type: "" },
  { name: "Entraineur de chien", type: "" },
  { name: "Entraîneur personnel", type: "" },
  { name: "Entraîneur personnel pour femmes", type: "" },
  { name: "Entrée de l’Agenzia", type: "" },
  { name: "Entrepôt de jambon cru", type: "" },
  { name: "Entrepôt de porte", type: "" },
  { name: "Entrepôt douanier", type: "" },
  { name: "Entrepreneur d’éclairage", type: "" },
  { name: "Entrepreneur d’étang", type: "" },
  { name: "Entrepreneur de plongée", type: "" },
  { name: "Entrepreneur de services publics", type: "" },
  { name: "Entrepreneur de terrain de basket", type: "" },
  { name: "Entrepreneur en acier de fer", type: "" },
  { name: "Entrepreneur en aménagement intérieur", type: "" },
  { name: "Entrepreneur en aménagement paysager de roche", type: "" },
  { name: "Entrepreneur en asphalte", type: "" },
  { name: "Entrepreneur en béton", type: "" },
  { name: "Entrepreneur en carrelage", type: "" },
  { name: "Entrepreneur en charpente d’acier", type: "" },
  { name: "Entrepreneur en chauffage", type: "" },
  { name: "Entrepreneur en climatisation", type: "" },
  { name: "Entrepreneur en clôture", type: "" },
  { name: "Entrepreneur en construction de véranda", type: "" },
  { name: "Entrepreneur en construction intérieure", type: "" },
  { name: "Entrepreneur en démolition", type: "" },
  { name: "Entrepreneur en énergie solaire", type: "" },
  { name: "Entrepreneur en escalier", type: "" },
  { name: "Entrepreneur en excavation", type: "" },
  { name: "Entrepreneur en fontaine", type: "" },
  { name: "Entrepreneur en forage", type: "" },
  { name: "Entrepreneur en forage de puits", type: "" },
  { name: "Entrepreneur en garde-corps", type: "" },
  { name: "Entrepreneur en isolation", type: "" },
  { name: "Entrepreneur en maçonnerie", type: "" },
  { name: "Entrepreneur en marbre", type: "" },
  { name: "Entrepreneur en murs secs", type: "" },
  { name: "Entrepreneur en pavage", type: "" },
  { name: "Entrepreneur en piscine", type: "" },
  { name: "Entrepreneur en puits de lumière", type: "" },
  { name: "Entrepreneur en revêtement", type: "" },
  { name: "Entrepreneur en revêtements de sol", type: "" },
  { name: "Entrepreneur en stuc", type: "" },
  { name: "Entrepreneur en systèmes d’arrosage de pelouse", type: "" },
  { name: "Entrepreneur en télécommunications", type: "" },
  { name: "Entrepreneur en toiture", type: "" },
  { name: "Entrepreneur en tôlerie", type: "" },
  { name: "Entrepreneur en véranda", type: "" },
  { name: "Entrepreneur ferroviaire", type: "" },
  { name: "Entrepreneur forestier", type: "" },
  { name: "Entrepreneur général", type: "" },
  { name: "Entrepreneur HVAC", type: "" },
  { name: "Entrepreneur mécanique", type: "" },
  { name: "Entreprise d’automatisation", type: "" },
  { name: "Entreprise d’emballage", type: "" },
  { name: "Entreprise d’embouteillage d’huile d’olive", type: "" },
  { name: "Entreprise d’hébergement Web", type: "" },
  { name: "Entreprise de conception de bâtiments", type: "" },
  { name: "Entreprise de conception de charpentes métalliques", type: "" },
  { name: "Entreprise de conception de modèles", type: "" },
  { name: "Entreprise de construction", type: "" },
  { name: "Entreprise de construction de courts de tennis", type: "" },
  { name: "Entreprise de construction de foire", type: "" },
  { name: "Entreprise de construction de routes", type: "" },
  { name: "Entreprise de construction en acier", type: "" },
  { name: "Entreprise de construction et de réparation navale", type: "" },
  { name: "Entreprise de construction métallique", type: "" },
  { name: "Entreprise de couture", type: "" },
  { name: "Entreprise de décapage de peinture", type: "" },
  { name: "Entreprise de déménagement", type: "" },
  { name: "Entreprise de design industriel", type: "" },
  { name: "Entreprise de domotique", type: "" },
  { name: "Entreprise de fabrication de plastique", type: "" },
  { name: "Entreprise de médias", type: "" },
  { name: "Entreprise de produits pétroliers", type: "" },
  { name: "Entreprise de purification de l’eau", type: "" },
  { name: "Entreprise de réseautage d’affaires", type: "" },
  { name: "Entreprise de services publics d’électricité", type: "" },
  { name: "Entreprise de services publics de logement", type: "" },
  { name: "Entreprise de T-shirt", type: "" },
  { name: "Entreprise de traitement des métaux", type: "" },
  { name: "Entreprise de transformation des aliments", type: "" },
  { name: "Entreprise de transport routier", type: "" },
  { name: "Entreprises de maisons préfabriquées", type: "" },
  { name: "Environnement Ressources naturelles renouvelables", type: "" },
  { name: "Épicerie", type: "" },
  { name: "Épicerie", type: "" },
  { name: "Épicerie", type: "" },
  { name: "Épicerie asiatique", type: "" },
  { name: "Épicerie casher", type: "" },
  { name: "Épicerie coréenne", type: "" },
  { name: "Épicerie fine japonaise", type: "" },
  { name: "Épicerie gastronomique", type: "" },
  { name: "Épicerie indienne", type: "" },
  { name: "Épicerie italienne", type: "" },
  { name: "Épicerie japonaise", type: "" },
  { name: "Épicerie mexicaine", type: "" },
  { name: "Épicier en gros", type: "" },
  { name: "Équipement de minage", type: "" },
  { name: "Équipement de transformation des aliments", type: "" },
  { name: "Équipement et fournitures hospitalières", type: "" },
  { name: "Équipement et service de pompage", type: "" },
  { name: "Équipements et solutions énergétiques", type: "" },
  { name: "Ergothérapeute", type: "" },
  { name: "Escalade", type: "" },
  { name: "Espace barbecue", type: "" },
  { name: "Espace barbecue", type: "" },
  { name: "Espace de stationnement public", type: "" },
  { name: "Établissement d’enseignement", type: "" },
  { name: "Étal de Hawker", type: "" },
  { name: "Étang de pêche", type: "" },
  { name: "État", type: "" },
  { name: "Étudier à l’école à domicile", type: "" },
  { name: "Évaluateur de bijoux", type: "" },
  { name: "Évaluateur fiscal", type: "" },
  { name: "Évaluateur immobilier", type: "" },
  { name: "Évaluateur immobilier", type: "" },
  { name: "Événement de tir à l’arc", type: "" },
  { name: "Exécuteur", type: "" },
  { name: "Expert", type: "" },
  { name: "Expert en quantité", type: "" },
  { name: "Expert immobilier", type: "" },
  { name: "Expert maritime", type: "" },
  { name: "Expert-comptable", type: "" },
  { name: "Exploiter", type: "" },
  { name: "Exportateur", type: "" },
  { name: "Exportateur d’aliments et de boissons", type: "" },
  { name: "Exportateur d’artisanat", type: "" },
  { name: "Exportateur d’électronique", type: "" },
  { name: "Exportateur d’épices", type: "" },
  { name: "Exportateur d’équipement", type: "" },
  { name: "Exportateur de bijoux", type: "" },
  { name: "Exportateur de charbon", type: "" },
  { name: "Exportateur de coton", type: "" },
  { name: "Exportateur de cuir", type: "" },
  { name: "Exportateur de jute", type: "" },
  { name: "Exportateur de médicaments", type: "" },
  { name: "Exportateur de papier", type: "" },
  { name: "Exportateur de pierre naturelle", type: "" },
  { name: "Exportateur de produits chimiques", type: "" },
  { name: "Exportateur de tabac", type: "" },
  { name: "Exportateur de textile", type: "" },
  { name: "Exportateur de thé", type: "" },
  { name: "Exportateur de véhicules", type: "" },
  { name: "Exportateur de vêtements", type: "" },
  { name: "Exposition", type: "" },
  { name: "Fabricant", type: "" },
  { name: "Fabricant", type: "" },
  { name: "Fabricant alimentaire", type: "" },
  { name: "Fabricant chimique", type: "" },
  { name: "Fabricant d’acier", type: "" },
  { name: "Fabricant d’alcool", type: "" },
  { name: "Fabricant d’aliments", type: "" },
  { name: "Fabricant d’aliments surgelés", type: "" },
  { name: "Fabricant d’articles en cuir", type: "" },
  { name: "Fabricant d’ascenseurs", type: "" },
  { name: "Fabricant d’assaisonnements alimentaires", type: "" },
  { name: "Fabricant d’autocollants", type: "" },
  { name: "Fabricant d’éclairage", type: "" },
  { name: "Fabricant d’électronique", type: "" },
  { name: "Fabricant d’équipement de ventilation", type: "" },
  { name: "Fabricant d’équipement médical", type: "" },
  { name: "Fabricant d’huile d’olive", type: "" },
  { name: "Fabricant d’idoles", type: "" },
  { name: "Fabricant d’instruments à cordes", type: "" },
  { name: "Fabricant d’instruments de musique", type: "" },
  { name: "Fabricant d’outils", type: "" },
  { name: "Fabricant d’usinage", type: "" },
  { name: "Fabricant de bâtiments portables", type: "" },
  { name: "Fabricant de batterie", type: "" },
  { name: "Fabricant de bijoux", type: "" },
  { name: "Fabricant de boîte d’allumettes", type: "" },
  { name: "Fabricant de briques", type: "" },
  { name: "Fabricant de carreaux", type: "" },
  { name: "Fabricant de céramique", type: "" },
  { name: "Fabricant de charpente métallique", type: "" },
  { name: "Fabricant de chaudières", type: "" },
  { name: "Fabricant de ciment", type: "" },
  { name: "Fabricant de fermes", type: "" },
  { name: "Fabricant de fourrure", type: "" },
  { name: "Fabricant de foyer", type: "" },
  { name: "Fabricant de fromage", type: "" },
  { name: "Fabricant de joints", type: "" },
  { name: "Fabricant de jouets", type: "" },
  { name: "Fabricant de jouets et de jeux", type: "" },
  { name: "Fabricant de lingerie", type: "" },
  { name: "Fabricant de machines agricoles", type: "" },
  { name: "Fabricant de matériel informatique", type: "" },
  { name: "Fabricant de métal", type: "" },
  { name: "Fabricant de meubles", type: "" },
  { name: "Fabricant de montres", type: "" },
  { name: "Fabricant de moule", type: "" },
  { name: "Fabricant de panier de saule", type: "" },
  { name: "Fabricant de papeterie", type: "" },
  { name: "Fabricant de peinture", type: "" },
  { name: "Fabricant de piano", type: "" },
  { name: "Fabricant de pièces automobiles", type: "" },
  { name: "Fabricant de pièces de machines", type: "" },
  { name: "Fabricant de pneus", type: "" },
  { name: "Fabricant de porte", type: "" },
  { name: "Fabricant de poterie", type: "" },
  { name: "Fabricant de présentoir", type: "" },
  { name: "Fabricant de produits cosmétiques", type: "" },
  { name: "Fabricant de produits en tissu", type: "" },
  { name: "Fabricant de produits optiques", type: "" },
  { name: "Fabricant de remorque", type: "" },
  { name: "Fabricant de résine plastique", type: "" },
  { name: "Fabricant de sauce de soja", type: "" },
  { name: "Fabricant de services publics d’électricité", type: "" },
  { name: "Fabricant de souvenirs", type: "" },
  { name: "Fabricant de tapis", type: "" },
  { name: "Fabricant de technologie médicale", type: "" },
  { name: "Fabricant de thé", type: "" },
  { name: "Fabricant de tricots", type: "" },
  { name: "Fabricant de verre", type: "" },
  { name: "Fabricant de verrerie", type: "" },
  { name: "Fabricant de vêtements de sport", type: "" },
  { name: "Fabricant de vêtements et de tissus", type: "" },
  { name: "Fabricant FMCG", type: "" },
  { name: "Fabrication de couteaux", type: "" },
  { name: "Fabriquant de meubles", type: "" },
  { name: "Facilité de stockage", type: "" },
  { name: "Facilité de vie assistée", type: "" },
  { name: "Facilité de vote", type: "" },
  { name: "Faculté d’arts", type: "" },
  { name: "Faculté de chimie", type: "" },
  { name: "Faculté de droit", type: "" },
  { name: "Faculté de droit", type: "" },
  { name: "Faculté de géographie et d’histoire", type: "" },
  { name: "Faculté de pharmacie", type: "" },
  { name: "Faculté de psychologie", type: "" },
  { name: "Faculté des médias et des sciences de l’information", type: "" },
  { name: "Faculté des sciences", type: "" },
  { name: "Faculté des sports", type: "" },
  { name: "Famille d’accueil", type: "" },
  { name: "Favela", type: "" },
  { name: "Fenêtre en aluminium", type: "" },
  { name: "Ferme", type: "" },
  { name: "Ferme appartenant à l’État", type: "" },
  { name: "Ferme aquacole", type: "" },
  { name: "Ferme biologique", type: "" },
  { name: "Ferme d’aquaculture", type: "" },
  { name: "Ferme d’arbres", type: "" },
  { name: "Ferme d’arbres de Noël", type: "" },
  { name: "Ferme d’élevage", type: "" },
  { name: "Ferme d’orchidées", type: "" },
  { name: "Ferme de crevettes", type: "" },
  { name: "Ferme de fruits de mer", type: "" },
  { name: "Ferme laitière", type: "" },
  { name: "Ferme pour enfants", type: "" },
  { name: "Festival", type: "" },
  { name: "Finisseur en métal", type: "" },
  { name: "Fish and Chips à emporter", type: "" },
  { name: "Flavors Fragrances and Aroma Fournisseur", type: "" },
  { name: "Fleuriste", type: "" },
  { name: "Foire", type: "" },
  { name: "Fondation", type: "" },
  { name: "Fondation de recherche", type: "" },
  { name: "Fondation nationale de la santé", type: "" },
  { name: "Fonderie", type: "" },
  { name: "Fontaine d’eau potable", type: "" },
  { name: "Force d’autodéfense au sol", type: "" },
  { name: "Force d’autodéfense marine", type: "" },
  { name: "Forêt nationale", type: "" },
  { name: "Forgeron", type: "" },
  { name: "Formation d’urgence", type: "" },
  { name: "Forteresse", type: "" },
  { name: "Fournisseur biochimique", type: "" },
  { name: "Fournisseur d’abat-jour", type: "" },
  { name: "Fournisseur d’abrasifs", type: "" },
  { name: "Fournisseur d’accessoires de magasin d’occasion", type: "" },
  { name: "Fournisseur d’accessoires pour bateaux", type: "" },
  { name: "Fournisseur d’accessoires pour meubles", type: "" },
  { name: "Fournisseur d’agrégats", type: "" },
  { name: "Fournisseur d’alarme de voiture", type: "" },
  { name: "Fournisseur d’alarme incendie", type: "" },
  {
    name: "Fournisseur d’aliments et de boissons de restauration",
    type: "FOOD_SERVICES"
  },
  { name: "Fournisseur d’alternateur", type: "" },
  { name: "Fournisseur d’aluminium", type: "" },
  { name: "Fournisseur d’ampoules", type: "" },
  { name: "Fournisseur d’articles en cuir", type: "" },
  { name: "Fournisseur d’attaches", type: "" },
  { name: "Fournisseur d’attelage de remorque", type: "" },
  { name: "Fournisseur d’auvent", type: "" },
  { name: "Fournisseur d’eau en bouteille", type: "" },
  { name: "Fournisseur d’échelle", type: "" },
  { name: "Fournisseur d’échelle", type: "" },
  { name: "Fournisseur d’écorce", type: "" },
  { name: "Fournisseur d’encens", type: "" },
  { name: "Fournisseur d’énergie", type: "" },
  { name: "Fournisseur d’énergie verte", type: "" },
  { name: "Fournisseur d’engrais", type: "" },
  { name: "Fournisseur d’enveloppes", type: "" },
  { name: "Fournisseur d’équipement", type: "" },
  { name: "Fournisseur d’équipement à rayons X", type: "" },
  { name: "Fournisseur d’équipement agricole", type: "" },
  { name: "Fournisseur d’équipement audiovisuel", type: "" },
  { name: "Fournisseur d’équipement d’adoucissement de l’eau", type: "" },
  { name: "Fournisseur d’équipement d’éclairage de scène", type: "" },
  { name: "Fournisseur d’équipement d’énergie solaire", type: "" },
  { name: "Fournisseur d’équipement d’impression", type: "" },
  { name: "Fournisseur d’équipement d’irrigation", type: "" },
  { name: "Fournisseur d’équipement d’irrigation de pelouse", type: "" },
  { name: "Fournisseur d’équipement d’oxygène", type: "" },
  { name: "Fournisseur d’équipement d’usine", type: "" },
  { name: "Fournisseur d’équipement de bijouterie", type: "" },
  { name: "Fournisseur d’équipement de blanchisserie à jetons", type: "" },
  { name: "Fournisseur d’équipement de bureau", type: "" },
  { name: "Fournisseur d’équipement de centrale électrique", type: "" },
  { name: "Fournisseur d’équipement de chauffage", type: "" },
  { name: "Fournisseur d’équipement de cinéma", type: "" },
  { name: "Fournisseur d’équipement de conciergerie", type: "" },
  { name: "Fournisseur d’équipement de construction", type: "" },
  { name: "Fournisseur d’équipement de crème glacée", type: "" },
  { name: "Fournisseur d’équipement de dessin", type: "" },
  { name: "Fournisseur d’équipement de détection de métaux", type: "" },
  { name: "Fournisseur d’équipement de ferme laitière", type: "" },
  { name: "Fournisseur d’équipement de forage", type: "" },
  { name: "Fournisseur d’équipement de laboratoire", type: "" },
  { name: "Fournisseur d’équipement de laminage", type: "" },
  { name: "Fournisseur d’équipement de magasin", type: "" },
  { name: "Fournisseur d’équipement de manutention", type: "" },
  { name: "Fournisseur d’équipement de mobilité", type: "" },
  { name: "Fournisseur d’équipement de physiothérapie", type: "" },
  { name: "Fournisseur d’équipement de protection incendie", type: "" },
  { name: "Fournisseur d’équipement de remorquage", type: "" },
  { name: "Fournisseur d’équipement de sécurité", type: "" },
  { name: "Fournisseur d’équipement de service d’incendie", type: "" },
  { name: "Fournisseur d’équipement de télécommunications", type: "" },
  { name: "Fournisseur d’équipement de tracteur", type: "" },
  { name: "Fournisseur d’équipement de travaux d’eau", type: "" },
  { name: "Fournisseur d’équipement de vide industriel", type: "" },
  { name: "Fournisseur d’équipement de vidéoconférence", type: "" },
  { name: "Fournisseur d’équipement Eftpos", type: "" },
  { name: "Fournisseur d’équipement électrique", type: "" },
  { name: "Fournisseur d’équipement ferroviaire", type: "" },
  { name: "Fournisseur d’équipement hydraulique", type: "" },
  { name: "Fournisseur d’équipement hydroponique", type: "" },
  { name: "Fournisseur d’équipement industriel", type: "" },
  { name: "Fournisseur d’équipement laser", type: "" },
  { name: "Fournisseur d’équipement médical", type: "" },
  { name: "Fournisseur d’équipement pour champs pétrolifères", type: "" },
  { name: "Fournisseur d’équipement pour le diabète", type: "" },
  { name: "Fournisseur d’équipement pour personnes handicapées", type: "" },
  { name: "Fournisseur d’équipement pour terrains de jeux", type: "" },
  { name: "Fournisseur d’équipement scientifique", type: "" },
  { name: "Fournisseur d’extensions de cheveux", type: "" },
  { name: "Fournisseur d’huile de chauffage", type: "" },
  { name: "Fournisseur d’huîtres", type: "" },
  { name: "Fournisseur d’instruments de mesure", type: "" },
  { name: "Fournisseur d’isolateurs", type: "" },
  { name: "Fournisseur d’oeufs", type: "" },
  { name: "Fournisseur d’outils pneumatiques", type: "" },
  { name: "Fournisseur d’usine de bonsaï", type: "" },
  { name: "Fournisseur de barils", type: "" },
  { name: "Fournisseur de bâtiment de jardin", type: "" },
  { name: "Fournisseur de béton prêt à l’emploi", type: "" },
  { name: "Fournisseur de blocs de verre", type: "" },
  { name: "Fournisseur de bois", type: "" },
  { name: "Fournisseur de bois de chauffage", type: "" },
  { name: "Fournisseur de boissons gazeuses", type: "" },
  { name: "Fournisseur de boîtes aux lettres", type: "" },
  { name: "Fournisseur de bouteilles de gaz", type: "" },
  { name: "Fournisseur de bûches à gaz", type: "" },
  { name: "Fournisseur de cadeaux d’entreprise", type: "" },
  { name: "Fournisseur de cadre industriel", type: "" },
  { name: "Fournisseur de cadres de plaque d’immatriculation", type: "" },
  { name: "Fournisseur de cadres en aluminium", type: "" },
  { name: "Fournisseur de cadres en bois", type: "" },
  { name: "Fournisseur de camion Topper", type: "" },
  { name: "Fournisseur de caoutchouc mousse", type: "" },
  { name: "Fournisseur de capuchons de moyeu", type: "" },
  { name: "Fournisseur de carburant", type: "" },
  { name: "Fournisseur de carburant diesel", type: "" },
  { name: "Fournisseur de cartouches de toner", type: "" },
  { name: "Fournisseur de cercueil", type: "" },
  { name: "Fournisseur de charbon", type: "" },
  { name: "Fournisseur de charpente métallique en béton", type: "" },
  { name: "Fournisseur de chaudières", type: "" },
  { name: "Fournisseur de ciment", type: "" },
  { name: "Fournisseur de cire", type: "" },
  { name: "Fournisseur de clôtures de patio", type: "" },
  { name: "Fournisseur de compresseurs d’air", type: "" },
  { name: "Fournisseur de condiments", type: "" },
  { name: "Fournisseur de conteneurs", type: "" },
  { name: "Fournisseur de conteneurs", type: "" },
  { name: "Fournisseur de contreplaqué", type: "" },
  { name: "Fournisseur de coquilles de campeur", type: "" },
  { name: "Fournisseur de cosmétiques et parfums", type: "" },
  { name: "Fournisseur de costumes de théâtre", type: "" },
  { name: "Fournisseur de coton", type: "" },
  { name: "Fournisseur de couteaux de machine", type: "" },
  { name: "Fournisseur de couverture de bateau", type: "" },
  { name: "Fournisseur de cuivre", type: "" },
  { name: "Fournisseur de décalcomanie", type: "" },
  { name: "Fournisseur de desserts glacés", type: "" },
  { name: "Fournisseur de distributeurs automatiques", type: "" },
  { name: "Fournisseur de dynamomètres", type: "" },
  { name: "Fournisseur de fenêtres", type: "" },
  { name: "Fournisseur de fenêtres en PVC", type: "" },
  { name: "Fournisseur de feuilles de polyéthylène et de plastique", type: "" },
  { name: "Fournisseur de feux d’artifice", type: "" },
  { name: "Fournisseur de fibre de verre", type: "" },
  { name: "Fournisseur de fil", type: "" },
  { name: "Fournisseur de filtres à air", type: "" },
  { name: "Fournisseur de filtres à eau", type: "" },
  { name: "Fournisseur de flipper", type: "" },
  { name: "Fournisseur de foin", type: "" },
  { name: "Fournisseur de gaz butane", type: "" },
  { name: "Fournisseur de gaz d’hélium", type: "" },
  { name: "Fournisseur de gaz de soudage", type: "" },
  { name: "Fournisseur de gaz industriel", type: "" },
  { name: "Fournisseur de gaz naturel", type: "" },
  { name: "Fournisseur de gazon", type: "" },
  { name: "Fournisseur de gazon", type: "" },
  { name: "Fournisseur de glace", type: "" },
  { name: "Fournisseur de glace sèche", type: "" },
  { name: "Fournisseur de granit", type: "" },
  { name: "Fournisseur de kérosène", type: "" },
  { name: "Fournisseur de lentilles de contact", type: "" },
  { name: "Fournisseur de lunch box", type: "" },
  { name: "Fournisseur de machine à affranchir", type: "" },
  { name: "Fournisseur de machine à café", type: "" },
  { name: "Fournisseur de machine à écrire", type: "" },
  { name: "Fournisseur de machine de déchiquetage de papier", type: "" },
  { name: "Fournisseur de machines alimentaires", type: "" },
  { name: "Fournisseur de machines d’amusement", type: "" },
  { name: "Fournisseur de machines de jardin", type: "" },
  { name: "Fournisseur de machines métalliques", type: "" },
  { name: "Fournisseur de manèges", type: "" },
  { name: "Fournisseur de marbre", type: "" },
  { name: "Fournisseur de matériaux de construction", type: "" },
  { name: "Fournisseur de matériaux de finition", type: "" },
  { name: "Fournisseur de matériaux de pavage", type: "" },
  { name: "Fournisseur de métal", type: "" },
  { name: "Fournisseur de meubles ajustés", type: "" },
  { name: "Fournisseur de moulage", type: "" },
  { name: "Fournisseur de munitions", type: "" },
  { name: "Fournisseur de murs de soutènement", type: "" },
  { name: "Fournisseur de paillis", type: "" },
  { name: "Fournisseur de palettes", type: "" },
  { name: "Fournisseur de paniers", type: "" },
  { name: "Fournisseur de photocopieurs", type: "" },
  { name: "Fournisseur de pièces d’appareils électroménagers", type: "" },
  { name: "Fournisseur de pièces de camions", type: "" },
  { name: "Fournisseur de pièces de carrosserie automobile", type: "" },
  { name: "Fournisseur de pièces de four", type: "" },
  { name: "Fournisseur de pièces électroniques", type: "" },
  { name: "Fournisseur de pierre", type: "" },
  { name: "Fournisseur de pierre concassée", type: "" },
  { name: "Fournisseur de pierre naturelle", type: "" },
  { name: "Fournisseur de Pinatas", type: "" },
  { name: "Fournisseur de plafond", type: "" },
  { name: "Fournisseur de planchers de bois et stratifiés", type: "" },
  { name: "Fournisseur de plantes artificielles", type: "" },
  { name: "Fournisseur de poissons d’étang", type: "" },
  { name: "Fournisseur de polymère", type: "" },
  { name: "Fournisseur de pompe à eau", type: "" },
  { name: "Fournisseur de pompes", type: "" },
  { name: "Fournisseur de porte", type: "" },
  { name: "Fournisseur de porte de douche", type: "" },
  { name: "Fournisseur de porte de garage", type: "" },
  { name: "Fournisseur de portes industrielles", type: "" },
  { name: "Fournisseur de printemps", type: "" },
  { name: "Fournisseur de produits agrochimiques", type: "" },
  { name: "Fournisseur de produits alimentaires", type: "" },
  { name: "Fournisseur de produits chimiques ménagers", type: "" },
  { name: "Fournisseur de produits de beauté", type: "" },
  { name: "Fournisseur de produits de gypse", type: "" },
  { name: "Fournisseur de produits de nettoyage", type: "" },
  { name: "Fournisseur de produits en béton", type: "" },
  { name: "Fournisseur de produits en caoutchouc", type: "" },
  { name: "Fournisseur de produits en fibre optique", type: "" },
  { name: "Fournisseur de produits en peau de mouton et en laine", type: "" },
  { name: "Fournisseur de produits en plastique", type: "" },
  { name: "Fournisseur de produits laitiers", type: "" },
  { name: "Fournisseur de produits métalliques laminés", type: "" },
  { name: "Fournisseur de produits promotionnels", type: "" },
  { name: "Fournisseur de propane", type: "" },
  { name: "Fournisseur de réfrigérateurs commerciaux", type: "" },
  { name: "Fournisseur de refroidisseur d’eau", type: "" },
  { name: "Fournisseur de réservoirs d’eau de pluie", type: "" },
  { name: "Fournisseur de roulements", type: "" },
  { name: "Fournisseur de sable et de gravier", type: "" },
  { name: "Fournisseur de sacs en papier", type: "" },
  { name: "Fournisseur de sacs en plastique", type: "" },
  { name: "Fournisseur de saleté", type: "" },
  { name: "Fournisseur de semences", type: "" },
  { name: "Fournisseur de semi-conducteurs", type: "" },
  { name: "Fournisseur de serrures", type: "" },
  { name: "Fournisseur de services de télécommunications", type: "" },
  { name: "Fournisseur de services Internet", type: "" },
  { name: "Fournisseur de services parajuridiques", type: "" },
  { name: "Fournisseur de système d’eau chaude", type: "" },
  { name: "Fournisseur de système d’eau chaude solaire", type: "" },
  { name: "Fournisseur de système de climatisation", type: "" },
  { name: "Fournisseur de système de nettoyage sous vide", type: "" },
  { name: "Fournisseur de système de sécurité", type: "" },
  { name: "Fournisseur de systèmes de protection incendie", type: "" },
  { name: "Fournisseur de tabac", type: "" },
  { name: "Fournisseur de tabourets de bar", type: "" },
  { name: "Fournisseur de tambour en acier", type: "" },
  { name: "Fournisseur de terre végétale", type: "" },
  { name: "Fournisseur de toilettes portables", type: "" },
  { name: "Fournisseur de traitement de l’eau", type: "" },
  { name: "Fournisseur de transport de chevaux", type: "" },
  { name: "Fournisseur de traverses de chemin de fer", type: "" },
  { name: "Fournisseur de tuyaux", type: "" },
  { name: "Fournisseur de tuyaux", type: "" },
  { name: "Fournisseur de vaisselle jetable", type: "" },
  { name: "Fournisseur de vêtements", type: "" },
  { name: "Fournisseur de vêtements de protection", type: "" },
  { name: "Fournisseur de vis", type: "" },
  { name: "Fournisseur et fabricant de rideaux", type: "" },
  { name: "Fournisseur GPS", type: "" },
  { name: "Fournisseurs de l’industrie du métal", type: "" },
  { name: "Fourniture et installation de véranda", type: "" },
  { name: "Foyer de groupe", type: "" },
  { name: "Foyer pour enfants", type: "" },
  { name: "Friperie", type: "" },
  { name: "Frituur", type: "" },
  { name: "Fromagerie", type: "" },
  { name: "Galerie d’art", type: "" },
  { name: "Galerie d’art aborigène", type: "" },
  { name: "Garde municipale", type: "" },
  { name: "Garde-frontière", type: "" },
  { name: "Garderie", type: "" },
  { name: "Garderie à temps partiel", type: "" },
  { name: "Garderie de jour pour chiens", type: "" },
  { name: "Gardien de chien", type: "" },
  { name: "Gardienne d’animaux", type: "" },
  { name: "Gardienne de maison", type: "" },
  { name: "Gastroentérologue", type: "" },
  { name: "Gastropub", type: "" },
  { name: "Gemmologue", type: "" },
  { name: "Généalogiste", type: "" },
  { name: "Géologue", type: "" },
  { name: "Gestion et promotion de la musique", type: "" },
  { name: "Gisement de pétrole", type: "" },
  { name: "Glacier", type: "" },
  { name: "Golf Driving Range", type: "" },
  { name: "Golf Resort", type: "" },
  { name: "Grand Café", type: "" },
  { name: "Grand magasin", type: "" },
  { name: "Grande Ecole", type: "" },
  { name: "grande roue", type: "" },
  { name: "grande surface", type: "" },
  { name: "Graveur", type: "" },
  { name: "Graveur de bijoux", type: "" },
  { name: "Graveur sur verre", type: "" },
  { name: "Gravier", type: "" },
  { name: "Gril", type: "" },
  { name: "Grossiste", type: "" },
  { name: "Grossiste Cash and Carry", type: "" },
  { name: "Grossiste d’accessoires automobiles", type: "" },
  { name: "Grossiste d’accessoires de bureau", type: "" },
  { name: "Grossiste d’accessoires électroniques", type: "" },
  { name: "Grossiste d’appareils électriques", type: "" },
  { name: "Grossiste d’articles d’hygiène", type: "" },
  { name: "Grossiste d’éclairage", type: "" },
  { name: "Grossiste d’équipement de fitness", type: "" },
  { name: "Grossiste d’outils", type: "" },
  { name: "Grossiste de bagages", type: "" },
  { name: "Grossiste de batteries", type: "" },
  { name: "Grossiste de chaussures", type: "" },
  { name: "Grossiste de légumes", type: "" },
  { name: "Grossiste de meubles", type: "" },
  { name: "Grossiste de nouveautés", type: "" },
  { name: "Grossiste de sacs en plastique", type: "" },
  { name: "Grossiste en accessoires de sport", type: "" },
  { name: "Grossiste en alcool", type: "" },
  { name: "Grossiste en articles ménagers", type: "" },
  { name: "Grossiste en artisanat", type: "" },
  { name: "Grossiste en boissons alcoolisées", type: "" },
  { name: "Grossiste en café", type: "" },
  { name: "Grossiste en céramique", type: "" },
  { name: "Grossiste en confiserie", type: "" },
  { name: "Grossiste en cosmétiques", type: "" },
  { name: "Grossiste en cuir", type: "" },
  { name: "Grossiste en électricité", type: "" },
  { name: "Grossiste en électroménagers", type: "" },
  { name: "Grossiste en électronique", type: "" },
  { name: "Grossiste en fils et fils", type: "" },
  { name: "Grossiste en fournitures de bureau", type: "" },
  { name: "Grossiste en fruits", type: "" },
  { name: "Grossiste en fruits", type: "" },
  { name: "Grossiste en fruits de mer", type: "" },
  { name: "Grossiste en fruits et légumes", type: "" },
  { name: "Grossiste en huile", type: "" },
  { name: "Grossiste en lingerie", type: "" },
  { name: "Grossiste en livres", type: "" },
  { name: "Grossiste en maroquinerie", type: "" },
  { name: "Grossiste en matériaux de construction", type: "" },
  { name: "Grossiste en papeterie", type: "" },
  { name: "Grossiste en perles", type: "" },
  { name: "Grossiste en pierre naturelle", type: "" },
  { name: "Grossiste en plastique", type: "" },
  { name: "Grossiste en produits agricoles", type: "" },
  { name: "Grossiste en produits chimiques", type: "" },
  { name: "Grossiste en produits chimiques industriels", type: "" },
  { name: "Grossiste en produits chirurgicaux", type: "" },
  { name: "Grossiste en produits de beauté", type: "" },
  { name: "Grossiste en produits de grande consommation", type: "" },
  { name: "Grossiste en produits pharmaceutiques", type: "" },
  { name: "Grossiste en riz", type: "" },
  { name: "Grossiste en tapis", type: "" },
  { name: "Grossiste en thé", type: "" },
  { name: "Grossiste en tissu", type: "" },
  { name: "Grossiste en verrerie", type: "" },
  { name: "Grossiste en vêtements", type: "" },
  { name: "Grossiste en vêtements et tissus", type: "" },
  { name: "Grossiste en viande", type: "" },
  { name: "Grossiste et importateur de vin", type: "" },
  { name: "Grossiste informatique", type: "" },
  { name: "Grossiste manteau", type: "" },
  { name: "Grossiste optique", type: "" },
  { name: "Grossistes d’épices", type: "" },
  { name: "Groupe de jeunesse", type: "" },
  { name: "Groupe de lecture", type: "" },
  { name: "Groupe des arts du spectacle", type: "" },
  { name: "Groupe médical", type: "" },
  { name: "Guardia Civil", type: "" },
  { name: "Gun Club", type: "" },
  { name: "Gurudwara", type: "" },
  { name: "Gym", type: "" },
  { name: "Gymnase Cz", type: "" },
  { name: "Gymnase de boxe", type: "" },
  { name: "Gynécologue obstétricien", type: "" },
  { name: "Hammam", type: "" },
  { name: "Haras équestre", type: "" },
  { name: "Head Start Center", type: "" },
  { name: "Hébergement", type: "" },
  { name: "Hébergement avec services", type: "" },
  { name: "Hébergement de groupe", type: "" },
  { name: "Hébergement indépendant", type: "" },
  { name: "Hébergement intérieur", type: "" },
  { name: "Hébergement pour animaux de compagnie", type: "" },
  { name: "Héliport", type: "" },
  { name: "Hématologue", type: "" },
  { name: "Herboriste", type: "" },
  { name: "Hippodrome", type: "" },
  { name: "Homéopathe", type: "" },
  { name: "Honey Farm", type: "" },
  { name: "Hôpital", type: "" },
  { name: "Hôpital cardiaque", type: "" },
  { name: "Hôpital d’oto-rhino-laryngologie", type: "" },
  { name: "Hôpital de la ville", type: "" },
  { name: "Hôpital des anciens combattants", type: "" },
  { name: "Hôpital du gouvernement", type: "" },
  { name: "Hôpital général", type: "" },
  { name: "Hôpital masculin", type: "" },
  { name: "Hôpital médical chinois", type: "" },
  { name: "Hôpital militaire", type: "" },
  { name: "Hôpital pour enfants", type: "" },
  { name: "Hopital PRIVE", type: "" },
  { name: "Hopital psychiatrique", type: "" },
  { name: "Hôpital spécialisé", type: "" },
  { name: "Hôpital spécialisé", type: "" },
  { name: "Hôpital universitaire", type: "" },
  { name: "Horloge Watch Maker", type: "" },
  { name: "Horseshoe Smith", type: "" },
  { name: "Hospice", type: "" },
  { name: "Hôtel", type: "" },
  { name: "Hôtel bien-être", type: "" },
  { name: "Hôtel capsule", type: "" },
  { name: "Hôtel d’aéroport", type: "" },
  { name: "Hôtel d’affaires", type: "" },
  { name: "Hôtel de conférence", type: "" },
  { name: "Hôtel de luxe", type: "" },
  { name: "Hôtel de ville ou de ville", type: "" },
  { name: "Hôtel économique", type: "" },
  { name: "Hôtel Extended Stay", type: "" },
  { name: "Hôtel familial", type: "" },
  { name: "Hôtel Hot Spring", type: "" },
  { name: "Huissier", type: "" },
  { name: "Huissier", type: "" },
  { name: "Hygiéniste dentaire", type: "" },
  { name: "Immeuble", type: "" },
  { name: "Immeuble meublé", type: "" },
  { name: "Immunologue", type: "" },
  { name: "Import Export Company", type: "" },
  { name: "Importateur", type: "" },
  { name: "Importateur d’équipement", type: "" },
  { name: "Imprimante commerciale", type: "" },
  { name: "Imprimante d’écran", type: "" },
  { name: "Imprimante d’étiquettes personnalisées", type: "" },
  { name: "Imprimante numérique", type: "" },
  { name: "Imprimerie", type: "" },
  { name: "Industrie cosmétique", type: "" },
  { name: "Industrie du matériel d’expédition", type: "" },
  { name: "Industrie du PVC", type: "" },
  { name: "Industrie du verre", type: "" },
  { name: "Infirmière générale autorisée", type: "" },
  { name: "Ingénieur", type: "" },
  { name: "Ingénieur aéronautique", type: "" },
  { name: "Ingénieur agronome", type: "" },
  { name: "Ingénieur chimiste", type: "" },
  { name: "Ingénieur civil", type: "" },
  { name: "Ingénieur conception", type: "" },
  { name: "Ingénieur consultant", type: "" },
  { name: "Ingénieur dans la marine", type: "" },
  { name: "Ingénieur de fabrication", type: "" },
  { name: "Ingénieur de recherche", type: "" },
  { name: "Ingénieur déchets solides", type: "" },
  { name: "Ingénieur éléctricien", type: "" },
  { name: "Ingénieur électronique", type: "" },
  { name: "Ingénieur électronique", type: "" },
  { name: "Ingénieur en biotechnologie", type: "" },
  { name: "Ingénieur en instrumentation", type: "" },
  { name: "Ingénieur en nanotechnologie", type: "" },
  { name: "Ingénieur en structure", type: "" },
  { name: "Ingénieur en télécommunications", type: "" },
  { name: "Ingénieur environnemental", type: "" },
  { name: "Ingénieur gaz", type: "" },
  { name: "Ingénieur géotechnique", type: "" },
  { name: "Ingénieur hydraulique", type: "" },
  { name: "Ingénieur industriel", type: "" },
  { name: "Ingénieur mécanique", type: "" },
  { name: "Ingénieur minier", type: "" },
  { name: "Ingénieur nucléaire", type: "" },
  { name: "Ingénieur pétrochimique", type: "" },
  { name: "Ingénieur précision", type: "" },
  { name: "Ingénieur textile", type: "" },
  { name: "Inspecteur de maison", type: "" },
  { name: "Inspecteur en bâtiment", type: "" },
  { name: "Inspecteur immobilier commercial", type: "" },
  { name: "Inspection de véhicule", type: "" },
  { name: "Inspection sanitaire", type: "" },
  { name: "Installateur de double vitrage", type: "" },
  { name: "Installateur de gaz", type: "" },
  { name: "Installateur de système de sécurité automobile", type: "" },
  { name: "Installateur de tapis", type: "" },
  { name: "Installateur du système de sécurité", type: "" },
  { name: "Installation d’entreposage frigorifique", type: "" },
  { name: "Installation d’entreposage pour VR", type: "" },
  { name: "Installation de cinéma à domicile", type: "" },
  { name: "Installation de l’armée", type: "" },
  { name: "Installation de libre-stockage", type: "" },
  { name: "Installation de stockage automobile", type: "" },
  { name: "Installation de stockage de bateaux", type: "" },
  { name: "Installation de stockage de documents", type: "" },
  { name: "Installation de stockage de vin", type: "" },
  { name: "Institut de design", type: "" },
  { name: "Institut de formation aéronautique", type: "" },
  { name: "Institut de formation aux logiciels", type: "" },
  { name: "Institut de formation du matériel", type: "" },
  { name: "Institut de géographie et de statistique", type: "" },
  { name: "Institut de recherche", type: "" },
  { name: "Institut de réforme agraire", type: "" },
  { name: "Institut de Technologie", type: "" },
  { name: "Institution européenne", type: "" },
  { name: "Institution financière", type: "" },
  { name: "Institution religieuse", type: "" },
  { name: "Instructeur d’aérobic", type: "" },
  { name: "Instructeur d’échecs", type: "" },
  { name: "Instructeur d’escalade", type: "" },
  { name: "Instructeur de danse de salon", type: "" },
  { name: "Instructeur de golf", type: "" },
  { name: "Instructeur de guitare", type: "" },
  { name: "Instructeur de jeu", type: "" },
  { name: "Instructeur de langue anglaise", type: "" },
  { name: "Instructeur de langue chinoise", type: "" },
  { name: "Instructeur de langue japonaise", type: "" },
  { name: "Instructeur de méditation", type: "" },
  { name: "Instructeur de musique", type: "" },
  { name: "Instructeur de natation", type: "" },
  { name: "Instructeur de patinage", type: "" },
  { name: "Instructeur de patinage sur glace", type: "" },
  { name: "Instructeur de piano", type: "" },
  { name: "Instructeur de plongée sous-marine", type: "" },
  { name: "Instructeur de tennis", type: "" },
  { name: "Instructeur de tricot", type: "" },
  { name: "Instructeur de volleyball", type: "" },
  { name: "Instructeur de yoga", type: "" },
  { name: "Instructeur nautique", type: "" },
  { name: "Instructeur vocal", type: "" },
  { name: "Internat", type: "" },
  { name: "Interniste", type: "" },
  { name: "Investissement foncier", type: "" },
  { name: "IUP", type: "" },
  { name: "Jardin", type: "" },
  { name: "jardin botanique", type: "" },
  { name: "Jardin communautaire", type: "" },
  { name: "Jardin d’enfants", type: "" },
  { name: "Jardin d’enfants Waldorf", type: "" },
  { name: "Jardin de la bière", type: "" },
  { name: "Jardin du Spa", type: "" },
  { name: "Jardinier", type: "" },
  { name: "Jeux vidéo", type: "" },
  { name: "Jouer à l’école", type: "" },
  { name: "Junkyard", type: "" },
  { name: "Justice de district", type: "" },
  { name: "Karaoké", type: "" },
  { name: "Karaoké vidéo", type: "" },
  { name: "Kinésiologue", type: "" },
  { name: "Kiosque", type: "" },
  { name: "Kiosque à journaux", type: "" },
  { name: "Kiosque de location de films", type: "" },
  { name: "Kiosque de location de jeux vidéo", type: "" },
  { name: "L’école", type: "" },
  { name: "L’Église du Christ", type: "" },
  { name: "L’entretien des machines", type: "" },
  { name: "La Département de la Finance", type: "" },
  { name: "La pratique du football", type: "" },
  { name: "La production agricole", type: "" },
  { name: "Laboratoire", type: "" },
  { name: "Laboratoire de biochimie", type: "" },
  { name: "Laboratoire de chimie", type: "" },
  { name: "Laboratoire de radiographie", type: "" },
  { name: "Laboratoire dentaire", type: "" },
  { name: "Laboratoire médical", type: "" },
  { name: "Laboratoire pharmaceutique", type: "" },
  { name: "Laboratoire photo", type: "" },
  { name: "Lac de baignade", type: "" },
  { name: "Laitier", type: "" },
  { name: "Lapidaire", type: "" },
  { name: "Lavage de camions", type: "" },
  { name: "Lave-Auto", type: "" },
  { name: "Lave-auto en libre-service", type: "" },
  { name: "Laverie", type: "" },
  { name: "Lawn Bowls Club", type: "" },
  { name: "Le dîner", type: "" },
  { name: "Restaurant", type: "FOOD_SERVICES" },
  { name: "Rugby", type: "" },
  { name: "Leçon de calligraphie", type: "" },
  { name: "Leçon de shogi", type: "" },
  { name: "Librairie", type: "" },
  { name: "Librairie chrétienne", type: "" },
  { name: "Librairie d’occasion", type: "" },
  { name: "Librairie juridique", type: "" },
  { name: "Librairie médicale", type: "" },
  { name: "Librairie pour enfants", type: "" },
  { name: "Librairie rare", type: "" },
  { name: "Librairie religieuse", type: "" },
  { name: "Lieu de culte", type: "" },
  { name: "Lieu de l’événement", type: "" },
  { name: "Lieu de musique en direct", type: "" },
  { name: "Lieu de pèlerinage", type: "" },
  { name: "Ligne de train de banlieue", type: "" },
  { name: "Liquidateur", type: "" },
  { name: "Liquidateur immobilier", type: "" },
  { name: "lit et petit-déjeuner", type: "" },
  { name: "Little League Field", type: "" },
  { name: "Livraison de fleurs", type: "" },
  { name: "Livraison de pizzas", type: "FOOD_SERVICES" },
  { name: "Livraison de repas", type: "" },
  { name: "Livraison Restaurant chinois", type: "FOOD_SERVICES" },
  { name: "Location d’appartements de vacances", type: "" },
  { name: "Location d’installations et de machines", type: "" },
  { name: "Location de bureaux virtuels", type: "" },
  { name: "Location de chalet", type: "" },
  { name: "Location de château gonflable", type: "" },
  { name: "Lodge des voyageurs", type: "" },
  { name: "Loge", type: "" },
  { name: "Logement social", type: "" },
  { name: "Love Hotel", type: "" },
  { name: "Lutte gréco-romaine", type: "" },
  { name: "Lutte libre", type: "" },
  { name: "Lycée", type: "" },
  { name: "Lycée", type: "" },
  { name: "Lycée", type: "" },
  { name: "Lycée", type: "" },
  { name: "Lycée", type: "" },
  { name: "Lycée", type: "" },
  { name: "Lycée Folk", type: "" },
  { name: "Lycée pour filles", type: "" },
  { name: "Lycée pour garçons", type: "" },
  { name: "Machines d’emballage", type: "" },
  { name: "Maçon", type: "" },
  { name: "Maçon", type: "" },
  { name: "Magasin", type: "" },
  { name: "Magasin à un dollar", type: "" },
  { name: "Magasin audio domestique", type: "" },
  { name: "Magasin Butsudan", type: "" },
  { name: "Magasin d’accessoires de mode", type: "" },
  { name: "Magasin d’accessoires de téléphone portable", type: "" },
  { name: "Magasin d’accessoires de voiture", type: "" },
  { name: "Magasin d’accessoires informatiques", type: "" },
  { name: "Magasin d’accessoires pour camions", type: "" },
  { name: "Magasin d’acrylique", type: "" },
  { name: "Magasin d’affiches", type: "" },
  { name: "Magasin d’alarme antivol", type: "" },
  { name: "Magasin d’alcool", type: "" },
  { name: "Magasin d’alcools d’État", type: "" },
  { name: "Magasin d’alimentation animale", type: "" },
  { name: "Magasin d’alimentation en gros", type: "" },
  { name: "Magasin d’aliments biologiques", type: "" },
  { name: "Magasin d’aliments naturels", type: "" },
  { name: "Magasin d’aliments naturels", type: "" },
  { name: "Magasin d’aliments surgelés", type: "" },
  { name: "Magasin d’ameublement", type: "" },
  { name: "Magasin d’antiquité", type: "" },
  { name: "Magasin d’appareils", type: "" },
  { name: "Magasin d’appareils auditifs", type: "" },
  { name: "Magasin d’appareils photo", type: "" },
  { name: "Magasin d’appâts", type: "" },
  { name: "Magasin d’arbres de transmission", type: "" },
  { name: "Magasin d’armoires", type: "" },
  { name: "Magasin d’articles de baseball", type: "" },
  { name: "Magasin d’articles de hockey", type: "" },
  { name: "Magasin d’articles de sport", type: "" },
  { name: "Magasin d’articles de toilette", type: "" },
  { name: "Magasin d’articles en cuir", type: "" },
  { name: "Magasin d’articles ménagers asiatiques", type: "" },
  { name: "Magasin d’articles pour la maison", type: "" },
  { name: "Magasin d’articles religieux", type: "" },
  { name: "Magasin d’artisanat", type: "" },
  { name: "Magasin d’aspirateurs", type: "" },
  { name: "Magasin d’éclairage", type: "" },
  { name: "Magasin d’écran", type: "" },
  { name: "Magasin d’électroménagers d’occasion", type: "" },
  { name: "Magasin d’électronique", type: "" },
  { name: "Magasin d’emballage cadeau", type: "" },
  { name: "Magasin d’entrepôt", type: "" },
  { name: "Magasin d’épices", type: "" },
  { name: "Magasin d’équipement d’exercice", type: "" },
  { name: "Magasin d’équipement de décoration de gâteaux", type: "" },
  { name: "Magasin d’essence", type: "" },
  { name: "Magasin d’huile", type: "" },
  { name: "Magasin d’informatique", type: "" },
  { name: "Magasin d’informatique d’occasion", type: "" },
  { name: "Magasin d’instruments de musique", type: "" },
  { name: "Magasin d’instruments de musique d’occasion", type: "" },
  { name: "Magasin d’objets de collection", type: "" },
  { name: "Magasin d’osier", type: "" },
  { name: "Magasin d’outils", type: "" },
  { name: "Magasin d’uniforme", type: "" },
  { name: "Magasin d’usine", type: "" },
  { name: "Magasin d’usine de soie", type: "" },
  { name: "Magasin de bagages", type: "" },
  { name: "Magasin de ballons", type: "" },
  { name: "Magasin de bandes dessinées", type: "" },
  { name: "Magasin de bannières", type: "" },
  { name: "Magasin de batterie", type: "" },
  { name: "Magasin de batterie de voiture", type: "" },
  { name: "Magasin de batteries", type: "" },
  { name: "Magasin de bébé", type: "" },
  { name: "Magasin de bière", type: "" },
  { name: "Magasin de biscuits au riz", type: "" },
  { name: "Magasin de bois", type: "" },
  { name: "Magasin de boîtes à musique", type: "" },
  { name: "Magasin de bonbons bon marché japonais", type: "" },
  { name: "Magasin de bottes en feutre", type: "" },
  { name: "Magasin de bougies", type: "" },
  { name: "Magasin de bretzels", type: "" },
  { name: "Magasin de bureau informatique", type: "" },
  { name: "Magasin de café", type: "" },
  { name: "Magasin de camping", type: "" },
  { name: "Magasin de canapés", type: "" },
  { name: "Magasin de cannabis", type: "" },
  { name: "Magasin de canoë et kayak", type: "" },
  { name: "Magasin de cartes", type: "" },
  { name: "Magasin de cartes à collectionner", type: "" },
  { name: "Magasin de cartes de sport", type: "" },
  { name: "Magasin de CD", type: "" },
  { name: "Magasin de CD d’occasion", type: "" },
  { name: "Magasin de charcuterie", type: "" },
  { name: "Magasin de chasse", type: "" },
  { name: "Magasin de chasse et pêche", type: "" },
  { name: "Magasin de chaussures", type: "" },
  { name: "Magasin de chaussures orthopédiques", type: "" },
  { name: "Magasin de cheminée", type: "" },
  { name: "Magasin de chimie automobile", type: "" },
  { name: "Magasin de cigares", type: "" },
  { name: "Magasin de cinéma maison", type: "" },
  { name: "Magasin de climatisation", type: "" },
  { name: "Magasin de comptoir", type: "" },
  { name: "Magasin de cosmétiques", type: "" },
  { name: "Magasin de costumes", type: "" },
  { name: "Magasin de couteaux", type: "" },
  { name: "Magasin de coutellerie", type: "" },
  { name: "Magasin de couture", type: "" },
  { name: "Magasin de cricket", type: "" },
  { name: "Magasin de danse", type: "" },
  { name: "Magasin de danse flamenco", type: "" },
  { name: "Magasin de démarrage", type: "" },
  { name: "Magasin de desserts", type: "" },
  { name: "Magasin de disques", type: "" },
  { name: "Magasin de divertissement de plage", type: "" },
  { name: "Magasin de divertissement pour adultes", type: "" },
  { name: "Magasin de Donut", type: "" },
  { name: "Magasin de drapeau", type: "" },
  { name: "Magasin de DVD", type: "" },
  { name: "Magasin de DVD pour adultes", type: "" },
  { name: "Magasin de fauteuils roulants", type: "" },
  { name: "Magasin de fenêtre Plast", type: "" },
  { name: "Magasin de fête", type: "" },
  { name: "Magasin de feux d’artifice", type: "" },
  { name: "Magasin de football", type: "" },
  { name: "Magasin de fournaise", type: "" },
  { name: "Magasin de fournitures aéronautiques", type: "" },
  { name: "Magasin de fournitures Airsoft", type: "" },
  { name: "Magasin de fournitures bouddhistes", type: "" },
  { name: "Magasin de fournitures chirurgicales", type: "" },
  { name: "Magasin de fournitures d’aménagement paysager", type: "" },
  { name: "Magasin de fournitures d’aromathérapie", type: "" },
  { name: "Magasin de fournitures d’art", type: "" },
  { name: "Magasin de fournitures d’arts martiaux", type: "" },
  { name: "Magasin de fournitures d’église", type: "" },
  { name: "Magasin de fournitures d’emballage", type: "" },
  { name: "Magasin de fournitures d’étang", type: "" },
  { name: "Magasin de fournitures d’hôtel", type: "" },
  { name: "Magasin de fournitures de billard", type: "" },
  { name: "Magasin de fournitures de bureau", type: "" },
  { name: "Magasin de fournitures de clôture", type: "" },
  { name: "Magasin de fournitures de coiffeur", type: "" },
  { name: "Magasin de fournitures de cuisine", type: "" },
  { name: "Magasin de fournitures de déménagement", type: "" },
  { name: "Magasin de fournitures de fléchettes", type: "" },
  { name: "Magasin de fournitures de maçonnerie", type: "" },
  { name: "Magasin de fournitures de massage", type: "" },
  { name: "Magasin de fournitures de piscine", type: "" },
  { name: "Magasin de fournitures de plomberie", type: "" },
  { name: "Magasin de fournitures de police", type: "" },
  { name: "Magasin de fournitures de restaurant", type: "FOOD_SERVICES" },
  { name: "Magasin de fournitures de salle de bain", type: "" },
  { name: "Magasin de fournitures de sérigraphie", type: "" },
  { name: "Magasin de fournitures de tennis de table", type: "" },
  { name: "Magasin de fournitures de théâtre", type: "" },
  { name: "Magasin de fournitures de toiture", type: "" },
  { name: "Magasin de fournitures dentaires", type: "" },
  { name: "Magasin de fournitures DJ", type: "" },
  { name: "Magasin de fournitures éducatives", type: "" },
  { name: "Magasin de fournitures électriques", type: "" },
  { name: "Magasin de fournitures marines", type: "" },
  { name: "Magasin de fournitures médicales", type: "" },
  { name: "Magasin de fournitures métaphysiques", type: "" },
  { name: "Magasin de fournitures pour aérographe", type: "" },
  { name: "Magasin de fournitures pour animaux de compagnie", type: "" },
  { name: "Magasin de fournitures pour le travail du bois", type: "" },
  { name: "Magasin de fournitures pour maisons mobiles", type: "" },
  { name: "Magasin de fournitures pour murs secs", type: "" },
  { name: "Magasin de fournitures pour remorques", type: "" },
  { name: "Magasin de fournitures pour VR", type: "" },
  { name: "Magasin de fournitures scolaires", type: "" },
  { name: "Magasin de friandises", type: "" },
  { name: "Magasin de fruits de mer séchés", type: "" },
  { name: "Magasin de fruits et légumes", type: "" },
  { name: "Magasin de fruits secs", type: "" },
  { name: "Magasin de futon", type: "" },
  { name: "Magasin de générateurs", type: "" },
  { name: "Magasin de grillades", type: "" },
  { name: "Magasin de guitare", type: "" },
  { name: "Magasin de jambon", type: "" },
  { name: "Magasin de jambon cru", type: "" },
  { name: "Magasin de jeux", type: "" },
  { name: "Magasin de jeux d’occasion", type: "" },
  { name: "Magasin de jeux vidéo", type: "" },
  { name: "Magasin de jouets", type: "" },
  { name: "Magasin de kebab", type: "" },
  { name: "Magasin de kimono", type: "" },
  { name: "Magasin de la ferme", type: "" },
  { name: "Magasin de laine", type: "" },
  { name: "Magasin de laine", type: "" },
  { name: "Magasin de laveuses et sécheuses", type: "" },
  { name: "Magasin de linge", type: "" },
  { name: "Magasin de lingerie", type: "" },
  { name: "Magasin de linoléum", type: "" },
  { name: "Magasin de literie", type: "" },
  { name: "Magasin de location d’électronique", type: "" },
  { name: "Magasin de location de films", type: "" },
  { name: "Magasin de location de jeux vidéo", type: "" },
  { name: "Magasin de location stéréo", type: "" },
  { name: "Magasin de logiciels informatiques", type: "" },
  { name: "Magasin de loisirs", type: "" },
  { name: "Magasin de lunettes de soleil", type: "" },
  { name: "Magasin de machines à coudre", type: "" },
  { name: "Magasin de magazines", type: "" },
  { name: "Magasin de magie", type: "" },
  { name: "Magasin de maillots de bain", type: "" },
  { name: "Magasin de manteaux en cuir", type: "" },
  { name: "Magasin de manteaux en peau de mouton", type: "" },
  { name: "Magasin de marchandises africaines", type: "" },
  { name: "Magasin de marchandises australien", type: "" },
  { name: "Magasin de marchandises confisquées personnalisées", type: "" },
  { name: "Magasin de marchandises hawaïennes", type: "" },
  { name: "Magasin de marchandises irlandaises", type: "" },
  { name: "Magasin de mariage", type: "" },
  { name: "Magasin de matelas", type: "" },
  { name: "Magasin de matelas à eau", type: "" },
  { name: "Magasin de matériaux de construction", type: "" },
  { name: "Magasin de matériaux isolants", type: "" },
  { name: "Magasin de matériel de bowling", type: "" },
  { name: "Magasin de matériel de brassage", type: "" },
  { name: "Magasin de matériel de soudage", type: "" },
  { name: "Magasin de matériel de vinification", type: "" },
  { name: "Magasin de maternité", type: "" },
  { name: "Magasin de médecine chinoise", type: "" },
  { name: "Magasin de médecine orientale", type: "" },
  { name: "Magasin de meubles", type: "" },
  { name: "Magasin de meubles Amish", type: "" },
  { name: "Magasin de meubles anciens", type: "" },
  { name: "Magasin de meubles d’extérieur", type: "" },
  { name: "Magasin de meubles d’occasion", type: "" },
  { name: "Magasin de meubles de bureau", type: "" },
  { name: "Magasin de meubles de bureau d’occasion", type: "" },
  { name: "Magasin de meubles de chambre à coucher", type: "" },
  { name: "Magasin de meubles de cuisine", type: "" },
  { name: "Magasin de meubles de jardin", type: "" },
  { name: "Magasin de meubles de restaurant de bar", type: "FOOD_SERVICES" },
  { name: "Magasin de meubles de supermarché", type: "" },
  { name: "Magasin de meubles en canne", type: "" },
  { name: "Magasin de meubles en pin", type: "" },
  { name: "Magasin de meubles inachevés", type: "" },
  { name: "Magasin de meubles pour enfants", type: "" },
  { name: "Magasin de meubles rustiques", type: "" },
  { name: "Magasin de miniatures", type: "" },
  { name: "Magasin de montres", type: "" },
  { name: "Magasin de moteurs électriques", type: "" },
  { name: "Magasin de moteurs hors-bord", type: "" },
  { name: "Magasin de motos", type: "" },
  { name: "Magasin de musique", type: "" },
  { name: "Magasin de narguilé", type: "" },
  { name: "Magasin de Noël", type: "" },
  { name: "Magasin de noix", type: "" },
  { name: "Magasin de nouveauté", type: "" },
  { name: "Magasin de nutrition sportive", type: "" },
  { name: "Magasin de paintball", type: "" },
  { name: "Magasin de papier", type: "" },
  { name: "Magasin de papier peint", type: "" },
  { name: "Magasin de partitions", type: "" },
  { name: "Magasin de pâtes", type: "" },
  { name: "Magasin de pêche", type: "" },
  { name: "Magasin de pêche", type: "" },
  { name: "Magasin de peinture", type: "" },
  { name: "Magasin de peintures", type: "" },
  { name: "Magasin de perles", type: "" },
  { name: "Magasin de phoques", type: "" },
  { name: "Magasin de phytothérapie", type: "" },
  { name: "Magasin de pianos", type: "" },
  { name: "Magasin de pièces automobiles", type: "" },
  { name: "Magasin de pièces automobiles d’occasion", type: "" },
  { name: "Magasin de pièces de moto", type: "" },
  { name: "Magasin de pièces de voitures de course", type: "" },
  { name: "Magasin de planche à voile", type: "" },
  { name: "Magasin de pneus", type: "" },
  { name: "Magasin de pneus d’occasion", type: "" },
  { name: "Magasin de poêles à bois", type: "" },
  { name: "Magasin de poisson rouge", type: "" },
  { name: "Magasin de poissons tropicaux", type: "" },
  { name: "Magasin de pop-corn", type: "" },
  { name: "Magasin de porcelaine", type: "" },
  { name: "Magasin de porte", type: "" },
  { name: "Magasin de portes de douche", type: "" },
  { name: "Magasin de poterie", type: "" },
  { name: "Magasin de poupées", type: "" },
  { name: "Magasin de produits amérindiens", type: "" },
  { name: "Magasin de produits de beauté", type: "" },
  { name: "Magasin de produits de boulangerie", type: "" },
  { name: "Magasin de produits laitiers", type: "" },
  { name: "Magasin de produits mexicains", type: "" },
  { name: "Magasin de produits orientaux", type: "" },
  { name: "Magasin de produits saisonniers", type: "" },
  { name: "Magasin de radiateurs", type: "" },
  { name: "Magasin de rayonnage", type: "" },
  { name: "Magasin de recharge d’encre d’imprimante", type: "" },
  { name: "Magasin de récupération architecturale", type: "" },
  { name: "Magasin de réfrigérateurs", type: "" },
  { name: "Magasin de rénovation domiciliaire", type: "" },
  { name: "Magasin de reptiles", type: "" },
  { name: "Magasin de revêtements de sol", type: "" },
  { name: "Magasin de rideaux", type: "" },
  { name: "Magasin de riz", type: "" },
  { name: "Magasin de rock", type: "" },
  { name: "Magasin de roues", type: "" },
  { name: "Magasin de rugby", type: "" },
  { name: "Magasin de santé et de beauté", type: "" },
  { name: "Magasin de sauna", type: "" },
  { name: "Magasin de scrapbooking", type: "" },
  { name: "Magasin de skate", type: "" },
  { name: "Magasin de skateboard", type: "" },
  { name: "Magasin de ski", type: "" },
  { name: "Magasin de ski nautique", type: "" },
  { name: "Magasin de snowboard", type: "" },
  { name: "Magasin de soie", type: "" },
  { name: "Magasin de soupe", type: "FOOD_SERVICES" },
  { name: "Magasin de sous-vêtements", type: "" },
  { name: "Magasin de souvenirs", type: "" },
  { name: "Magasin de souvenirs de sport", type: "" },
  { name: "Magasin de spas", type: "" },
  { name: "Magasin de sport automobile", type: "" },
  { name: "Magasin de sports de plein air", type: "" },
  { name: "Magasin de stylos", type: "" },
  { name: "Magasin de surf", type: "" },
  { name: "Magasin de surplus", type: "" },
  { name: "Magasin de t-shirts", type: "" },
  { name: "Magasin de t-shirts personnalisés", type: "" },
  { name: "Magasin de tapis", type: "" },
  { name: "Magasin de tapis", type: "" },
  { name: "Magasin de tapis oriental", type: "" },
  { name: "Magasin de tatami", type: "" },
  { name: "Magasin de teinture", type: "" },
  { name: "Magasin de téléphone portable", type: "" },
  { name: "Magasin de télescopes", type: "" },
  { name: "Magasin de tennis", type: "" },
  { name: "Magasin de thé", type: "" },
  { name: "Magasin de timbres en caoutchouc", type: "" },
  { name: "Magasin de tir à l’arc", type: "" },
  { name: "Magasin de tissu", type: "" },
  { name: "Magasin de toit ouvrant automatique", type: "" },
  { name: "Magasin de tondeuses à gazon", type: "" },
  { name: "Magasin de trains miniatures", type: "" },
  { name: "Magasin de traitement de fenêtre", type: "" },
  { name: "Magasin de tricot", type: "" },
  { name: "Magasin de tuiles", type: "" },
  { name: "Magasin de vaporisateurs", type: "" },
  { name: "Magasin de variétés", type: "" },
  { name: "Magasin de vélos", type: "" },
  { name: "Magasin de vélos d’occasion", type: "" },
  { name: "Magasin de verre", type: "" },
  { name: "Magasin de verre automobile", type: "" },
  { name: "Magasin de verre et de miroirs", type: "" },
  { name: "Magasin de verrerie", type: "" },
  { name: "Magasin de vêtements", type: "" },
  { name: "Magasin de vêtements", type: "" },
  { name: "Magasin de vêtements d’extérieur", type: "" },
  { name: "Magasin de vêtements d’occasion", type: "" },
  { name: "Magasin de vêtements de cérémonie", type: "" },
  { name: "Magasin de vêtements de plage", type: "" },
  { name: "Magasin de vêtements de sport", type: "" },
  { name: "Magasin de vêtements de travail", type: "" },
  { name: "Magasin de vêtements et d’équipement de plein air", type: "" },
  { name: "Magasin de vêtements grande taille", type: "" },
  { name: "Magasin de vêtements pour bébés", type: "" },
  { name: "Magasin de vêtements pour enfants", type: "" },
  { name: "Magasin de vêtements pour femmes", type: "" },
  { name: "Magasin de vêtements pour hommes", type: "" },
  { name: "Magasin de vêtements pour jeunes", type: "" },
  { name: "Magasin de vêtements vintage", type: "" },
  { name: "Magasin de vêtements Western", type: "" },
  { name: "Magasin de vin", type: "" },
  { name: "Magasin de vitamines et suppléments", type: "" },
  { name: "Magasin de volaille", type: "" },
  { name: "Magasin discount", type: "" },
  { name: "Magasin en cours d’exécution", type: "" },
  { name: "Magasin équestre", type: "" },
  { name: "Magasin général", type: "" },
  { name: "Magasin hors taxes", type: "" },
  { name: "Magasin indésirable", type: "" },
  { name: "Magasin pour enfants", type: "" },
  { name: "Magasin stéréo de voiture", type: "" },
  { name: "Magasin traditionnel Kostume", type: "" },
  { name: "Magicien", type: "" },
  { name: "Maintenance de la propriété", type: "" },
  { name: "Maison à mi-chemin", type: "" },
  { name: "Maison abritée", type: "" },
  { name: "Maison d’adoration bahá’íe", type: "" },
  { name: "Maison d’agrément public", type: "" },
  { name: "Maison d’hôtes", type: "" },
  { name: "Maison de campagne", type: "" },
  { name: "Maison de couture haute couture", type: "" },
  { name: "Maison de désossage", type: "" },
  { name: "Maison de disques", type: "" },
  { name: "Maison de jeux de hasard", type: "" },
  { name: "Maison de repos", type: "" },
  { name: "Maison de retraite", type: "" },
  { name: "Maison de thé", type: "" },
  { name: "Maison de vacances", type: "" },
  { name: "Maison de vacances non-fumeur", type: "" },
  { name: "Maison de vente aux enchères", type: "" },
  { name: "Maison des médias", type: "" },
  { name: "Maison des ventes aux enchères de bétail", type: "" },
  { name: "Maison du crabe", type: "" },
  { name: "Maison funéraire", type: "" },
  { name: "Maison hantée", type: "" },
  { name: "Maison Mah Jong", type: "" },
  { name: "Maison People S", type: "" },
  { name: "Maison Sokol", type: "" },
  { name: "Manoir", type: "" },
  { name: "Manufacture de chaussure", type: "" },
  { name: "Maquilleur", type: "" },
  { name: "Marae", type: "" },
  { name: "Marchand d’art", type: "" },
  { name: "Marchand d’articles en fer", type: "" },
  { name: "Marchand d’or", type: "" },
  { name: "Marchand de bétail", type: "" },
  { name: "Marchand de diamants", type: "" },
  { name: "Marchand de ferraille", type: "" },
  { name: "Marchand de ferronnerie", type: "" },
  { name: "Marchand de légumes", type: "" },
  { name: "Marchand de pièces", type: "" },
  { name: "Marchand de verre", type: "" },
  { name: "Marché", type: "" },
  { name: "Marché automobile", type: "" },
  { name: "Marché aux arbres du Nouvel An", type: "" },
  { name: "Marché aux bestiaux", type: "" },
  { name: "Marché aux fleurs", type: "" },
  { name: "Marché aux puces", type: "" },
  { name: "Marché de fruits de mer", type: "" },
  { name: "Marché de gros", type: "" },
  { name: "Marché de gros de légumes", type: "" },
  { name: "Marché de gros de vêtements", type: "" },
  { name: "Marché de Noël", type: "" },
  { name: "Marché de nuit", type: "" },
  { name: "Marché de produits frais", type: "" },
  { name: "Marché de vêtements", type: "" },
  { name: "Marché des fermiers", type: "" },
  { name: "Marché des matériaux de construction", type: "" },
  { name: "Marché des pièces automobiles", type: "" },
  { name: "Marché flottant", type: "" },
  { name: "Marché traditionnel", type: "" },
  { name: "Marina", type: "" },
  { name: "Massage érotique", type: "" },
  { name: "Massage Spa", type: "" },
  { name: "Massothérapeute", type: "" },
  { name: "Massothérapeute asiatique", type: "" },
  { name: "Massothérapeute sportif", type: "" },
  { name: "Massothérapeute thaï", type: "" },
  { name: "Matériel de boulangerie", type: "" },
  { name: "Matériel et fournitures d’impression", type: "" },
  { name: "Maternité", type: "" },
  { name: "Mec Ranch", type: "" },
  { name: "Mécanicien", type: "" },
  { name: "Médecin", type: "" },
  { name: "Médecin d’urgence", type: "" },
  { name: "Médecin de famille", type: "" },
  { name: "Médecin du sport", type: "" },
  { name: "Médecin du travail", type: "" },
  { name: "Médecin généraliste", type: "" },
  { name: "Médecin légiste", type: "" },
  { name: "Médecin néonatal", type: "" },
  { name: "Médecin psychosomatique", type: "" },
  { name: "Médecin spécialiste de la fertilité", type: "" },
  { name: "Médecin spécialiste de la gestion de la douleur", type: "" },
  { name: "Médecin spécialiste des maladies infectieuses", type: "" },
  { name: "Memorial Park", type: "" },
  { name: "Menuisier", type: "" },
  { name: "Menuisier", type: "" },
  { name: "Mercerie", type: "" },
  { name: "Messagerie", type: "" },
  { name: "Microbiologiste", type: "" },
  { name: "Ministère de l’Éducation", type: "" },
  { name: "Ministère de la gestion des urgences", type: "" },
  { name: "Mission", type: "" },
  { name: "Modèle Portfolio Studio", type: "" },
  { name: "Monastère", type: "" },
  { name: "Moniteur de ski nautique", type: "" },
  { name: "Monopole de vente au détail d’alcool", type: "" },
  { name: "Montagnes russes", type: "" },
  { name: "Monteur d’acier", type: "" },
  { name: "Monument historique", type: "" },
  { name: "Mortuaire", type: "" },
  { name: "Mosquée", type: "" },
  { name: "Motel", type: "" },
  { name: "Moulin", type: "" },
  { name: "Moulin à cidre", type: "" },
  { name: "Moulin à coton", type: "" },
  { name: "Moulin à eau", type: "" },
  { name: "Moulin à farine", type: "" },
  { name: "Moulin à jute", type: "" },
  { name: "Moulin à riz", type: "" },
  { name: "Musée", type: "" },
  { name: "Musée archéologique", type: "" },
  { name: "Musée d’art", type: "" },
  { name: "Musée d’art moderne", type: "" },
  { name: "Musée d’art oriental", type: "" },
  { name: "Musée d’histoire", type: "" },
  { name: "Musée d’histoire locale", type: "" },
  { name: "Musée d’histoire naturelle", type: "" },
  { name: "Musée d’histoire spatiale", type: "" },
  { name: "Musée de cire", type: "" },
  { name: "Musée de l’armée", type: "" },
  { name: "Musée de l’artisanat", type: "" },
  { name: "Musée de la guerre", type: "" },
  { name: "Musée de la sculpture", type: "" },
  { name: "Musée de la technologie", type: "" },
  { name: "Musée de zoologie", type: "" },
  { name: "Musée des enfants", type: "" },
  { name: "Musée des lieux historiques", type: "" },
  { name: "Musée des sciences", type: "" },
  { name: "Musée du jouet", type: "" },
  { name: "Musée du patrimoine", type: "" },
  { name: "Musée en plein air", type: "" },
  { name: "Musée ethnographique", type: "" },
  { name: "Musée ferroviaire", type: "" },
  { name: "Musée maritime", type: "" },
  { name: "musée national", type: "" },
  { name: "Musicien", type: "" },
  { name: "Musicien et compositeur", type: "" },
  { name: "Nage synchronisée", type: "" },
  { name: "Naturopathie", type: "" },
  { name: "Néphrologue", type: "" },
  { name: "Nettoyeurs", type: "" },
  { name: "Neurochirurgien", type: "" },
  { name: "Neurologue", type: "" },
  { name: "Notaire", type: "" },
  { name: "Nounou", type: "" },
  { name: "Nounou", type: "" },
  { name: "Nouveau restaurant américain", type: "FOOD_SERVICES" },
  { name: "Numérologue", type: "" },
  { name: "Nutritionniste", type: "" },
  { name: "Observatoire", type: "" },
  { name: "Office de l’aménagement du territoire", type: "" },
  { name: "Office de tourisme", type: "" },
  { name: "Office des brevets", type: "" },
  { name: "Office du logement", type: "" },
  { name: "Oncologiste", type: "" },
  { name: "Opéra", type: "" },
  { name: "Opérateur de marché", type: "" },
  { name: "Opérateur de réseau mobile", type: "" },
  { name: "Ophtalmologiste", type: "" },
  { name: "Ophtalmologiste pédiatrique", type: "" },
  { name: "Opticien", type: "" },
  { name: "Optométriste", type: "" },
  { name: "Orchestre", type: "" },
  { name: "Orfèvre", type: "" },
  { name: "Orfèvre", type: "" },
  { name: "Organisateur d’activités de plein air", type: "" },
  { name: "Organisateur professionnel", type: "" },
  { name: "Organisation à but non lucratif", type: "" },
  { name: "Organisation agricole", type: "" },
  { name: "Organisation artistique", type: "" },
  { name: "Organisation de bénévoles", type: "" },
  { name: "Organisation de jeunesse", type: "" },
  { name: "Organisation de protection de l’environnement", type: "" },
  { name: "Organisation de protection des animaux", type: "" },
  {
    name: "Organisation de services et de soutien aux personnes handicapées",
    type: ""
  },
  {
    name: "Organisation des aborigènes et des insulaires du détroit de Torres",
    type: ""
  },
  { name: "Organisation des anciens combattants", type: "" },
  { name: "Organisation des célibataires", type: "" },
  { name: "Organisation des dépanneurs", type: "" },
  { name: "Organisation des étudiants étrangers", type: "" },
  { name: "Organisation des femmes", type: "" },
  { name: "Organisation des personnes disparues", type: "" },
  { name: "Organisation des services sociaux", type: "" },
  { name: "Organisation des services sociaux pour les jeunes", type: "" },
  { name: "Organisation du commerce équitable", type: "" },
  { name: "Organisation environnementale", type: "" },
  { name: "Organisation fraternelle", type: "" },
  { name: "Organisation gay et lesbienne", type: "" },
  { name: "Organisation maorie", type: "" },
  { name: "Organisation non gouvernementale", type: "" },
  { name: "Organisation religieuse", type: "" },
  { name: "Organiser", type: "" },
  { name: "Orphelinat", type: "" },
  { name: "Orthodontiste", type: "" },
  { name: "Orthophoniste", type: "" },
  { name: "Orthoptiste", type: "" },
  { name: "Ostéopathe", type: "" },
  { name: "Oto-rhino-laryngologiste", type: "" },
  { name: "Outlet Mall", type: "" },
  {
    name: "Pacific Northwest Restaurant (États-Unis)",
    type: "FOOD_SERVICES"
  },
  { name: "Pagode", type: "" },
  { name: "Palais de justice de la ville", type: "" },
  { name: "Palais des Congrès", type: "" },
  { name: "Papeterie", type: "" },
  { name: "Papeterie", type: "" },
  { name: "Paquet d’aliments de base", type: "" },
  { name: "Parc à chien", type: "" },
  { name: "Parc à vélo", type: "" },
  { name: "Parc animalier", type: "" },
  { name: "Parc animalier et safari", type: "" },
  { name: "Parc aquatique", type: "" },
  { name: "Parc athlétique", type: "" },
  { name: "Parc BMX", type: "" },
  { name: "Parc d’affaires", type: "" },
  { name: "Parc d’attractions", type: "" },
  { name: "Parc d’attractions", type: "" },
  { name: "Parc d’attractions", type: "" },
  { name: "Parc d’état", type: "" },
  { name: "Parc de maisons mobiles", type: "" },
  { name: "Parc de planche à roulettes", type: "" },
  { name: "Parc de vacances", type: "" },
  { name: "Parc de ville", type: "" },
  { name: "Parc écologique", type: "" },
  { name: "Parc éolien", type: "" },
  { name: "parc national", type: "" },
  { name: "Parc nudiste", type: "" },
  { name: "Parc RV", type: "" },
  { name: "Parc technologique", type: "" },
  { name: "Parcours de cordes hautes", type: "" },
  { name: "Parcours de golf miniature", type: "" },
  { name: "Parfumerie", type: "" },
  { name: "Park & ​​Ride", type: "" },
  { name: "Parking", type: "" },
  { name: "Parking de Tsukigime", type: "" },
  { name: "Parking gratuit", type: "" },
  { name: "Parking pour motos", type: "" },
  { name: "Parking pour vélos", type: "" },
  { name: "Parodontiste", type: "" },
  { name: "Parodontiste d’implants dentaires", type: "" },
  { name: "Paroisse", type: "" },
  { name: "Parti politique", type: "" },
  { name: "Pasteur", type: "" },
  { name: "Patch de citrouille", type: "" },
  { name: "Pathologiste", type: "" },
  { name: "Patinoire", type: "" },
  { name: "Patinoire à roulettes", type: "" },
  { name: "Patinoire de hockey", type: "" },
  { name: "Pâtisserie", type: "" },
  { name: "Pâtisserie", type: "" },
  { name: "Pâtisserie", type: "" },
  { name: "Pâtisserie chinoise", type: "" },
  { name: "Pavillon de danse", type: "" },
  { name: "Pavillon de la plage", type: "" },
  { name: "Pavillon Markmens", type: "" },
  { name: "Paysagiste", type: "" },
  { name: "Paysagiste", type: "" },
  { name: "Pêche aux crevettes", type: "" },
  { name: "Pédiatre", type: "" },
  { name: "Peintre", type: "" },
  { name: "Peinture", type: "" },
  { name: "Pension de famille", type: "" },
  { name: "Pension pour chevaux", type: "" },
  { name: "Pépinière", type: "" },
  { name: "Pharmacie", type: "" },
  { name: "Pharmacie", type: "" },
  { name: "Pharmacie biologique", type: "" },
  { name: "Pharmacie homéopathique", type: "" },
  { name: "Pharmacie vétérinaire", type: "" },
  { name: "Photographe", type: "" },
  { name: "Photographe aérien", type: "" },
  { name: "Photographe commercial", type: "" },
  { name: "Photographe de mariage", type: "" },
  { name: "Physiatre", type: "" },
  { name: "Physiothérapeute", type: "" },
  { name: "Piano Bar", type: "" },
  { name: "Pie Shop", type: "" },
  { name: "Pièce à outils", type: "" },
  { name: "Pilote de pile", type: "" },
  { name: "Piscine", type: "" },
  { name: "Piscine", type: "" },
  { name: "Piscine", type: "" },
  { name: "Piscine de plongée acrobatique", type: "" },
  { name: "Piscine de water-polo", type: "" },
  { name: "Piscine extérieure", type: "" },
  { name: "Piscine intérieure", type: "" },
  { name: "Piscine publique", type: "" },
  { name: "Piste athlétique", type: "" },
  { name: "Piste BMX", type: "" },
  { name: "Piste d’atterrissage", type: "" },
  { name: "Piste de course automobile", type: "" },
  { name: "Piste de course hors route", type: "" },
  { name: "Piste de karting", type: "" },
  { name: "Piste de luge d’été", type: "" },
  { name: "Piste Vtt", type: "" },
  { name: "Pizza à emporter", type: "FOOD_SERVICES" },
  { name: "Place du marché de Hua Hui", type: "" },
  { name: "Place du marché de Hua Niao", type: "" },
  { name: "Place du marché du thé", type: "" },
  { name: "Place du marché Qing Fang", type: "" },
  { name: "Planétarium", type: "" },
  { name: "Planificateur d’événements", type: "" },
  { name: "Planificateur d’exposition", type: "" },
  { name: "Planificateur de mariage", type: "" },
  { name: "Planificateur financier", type: "" },
  { name: "Plâtrier", type: "" },
  { name: "Plombier", type: "" },
  { name: "Pneumologue", type: "" },
  { name: "Podologue", type: "" },
  { name: "Poissonerie", type: "" },
  { name: "Police civile", type: "" },
  { name: "Police d’État", type: "" },
  { name: "Police de la Guardia Di Finanza", type: "" },
  { name: "Police des autoroutes", type: "" },
  { name: "Police des carabiniers", type: "" },
  { name: "Police fédérale", type: "" },
  { name: "Policlinique pour enfants", type: "" },
  { name: "Polytechnique", type: "" },
  { name: "Poney Club", type: "" },
  { name: "Pont", type: "" },
  { name: "Pont d’observation", type: "" },
  { name: "Port fluvial", type: "" },
  { name: "Porte intérieure", type: "" },
  { name: "Porte-vélos", type: "" },
  { name: "Poste de passage frontalier", type: "" },
  { name: "Poste de police de la circulation", type: "" },
  { name: "Poste de secours", type: "" },
  { name: "Poste de télévision", type: "" },
  { name: "Poste électrique", type: "" },
  { name: "Poulet", type: "" },
  { name: "Poulet Frit à emporter", type: "" },
  { name: "Pourvoirie de voyages en radeau", type: "" },
  { name: "Praticien en médecine alternative", type: "" },
  { name: "Praticien en médecine holistique", type: "" },
  { name: "Préfecture", type: "" },
  { name: "Préparation d’impôt", type: "" },
  { name: "Presbytère", type: "" },
  { name: "Préscolaire", type: "" },
  { name: "Préservation du patrimoine", type: "" },
  { name: "Prestataire", type: "" },
  { name: "Prêteur hypothécaire", type: "" },
  { name: "Prêteur sur gage", type: "" },
  { name: "Prêtre", type: "" },
  { name: "Prêtre hindou", type: "" },
  { name: "Prison", type: "" },
  { name: "Processeur de photo de passeport", type: "" },
  { name: "Processeur de viande", type: "" },
  { name: "Proctologue", type: "" },
  { name: "Procureur de district", type: "" },
  { name: "Producteur alimentaire", type: "" },
  { name: "Producteur d’orchidées", type: "" },
  { name: "Producteur de bétail", type: "" },
  { name: "Producteur de caoutchouc mousse", type: "" },
  { name: "Producteur de Champagne", type: "" },
  { name: "Producteur de cultures", type: "" },
  { name: "Producteur de ferronnerie", type: "" },
  { name: "Producteur de Foie Gras", type: "" },
  { name: "Producteur de musique", type: "" },
  { name: "Producteur Eau Minérale", type: "" },
  { name: "Production théâtrale", type: "" },
  { name: "Produire grossiste", type: "" },
  { name: "Produire le marché", type: "" },
  { name: "Produits carnés", type: "" },
  { name: "Programme après l’école", type: "" },
  { name: "Programme d’alphabétisation", type: "" },
  { name: "Programme de bien-être", type: "" },
  { name: "Programme de conditionnement physique", type: "" },
  { name: "Programme de logement à faible revenu", type: "" },
  { name: "Programme de traitement de l’alcoolisme", type: "" },
  { name: "Programme économique du gouvernement", type: "" },
  { name: "Promenade", type: "" },
  { name: "Promeneur de chien", type: "" },
  { name: "Promoteur immobilier", type: "" },
  { name: "Propriété des locataires", type: "" },
  { name: "Prothèses", type: "" },
  { name: "Psychanalyste", type: "" },
  { name: "Psychiatre", type: "" },
  { name: "Psychique", type: "" },
  { name: "Psychologue", type: "" },
  { name: "Psychologue enfant", type: "" },
  { name: "Psychothérapeute", type: "" },
  { name: "Pub", type: "" },
  { name: "pub irlandais", type: "" },
  { name: "Publicité par publipostage", type: "" },
  { name: "Quai de pêche", type: "" },
  { name: "Quartier général tribal", type: "" },
  { name: "Quincaillerie", type: "" },
  { name: "Radiologie dentaire", type: "" },
  { name: "Radiologue", type: "" },
  { name: "Raffinerie de pétrole", type: "" },
  { name: "Rafting", type: "" },
  { name: "Ramoneur", type: "" },
  { name: "Rampe de bateau", type: "" },
  { name: "Ranch", type: "" },
  { name: "Recherche et développement de produits", type: "" },
  { name: "Recours", type: "" },
  { name: "Recouvrement de créances", type: "" },
  { name: "Recruteur", type: "" },
  { name: "Réflexologue", type: "" },
  { name: "Réfrigération commerciale", type: "" },
  { name: "Refuge animalier", type: "" },
  { name: "Refuge faunique", type: "" },
  { name: "Refuge pour femmes", type: "" },
  { name: "Refuge pour sans-abris", type: "" },
  { name: "Registre civil", type: "" },
  { name: "Registre de la propriété intellectuelle", type: "" },
  { name: "Registre des entreprises", type: "" },
  { name: "Relieur", type: "" },
  { name: "Rembourreuse automatique", type: "" },
  { name: "Remodeler", type: "" },
  { name: "Rénovateur de cuisine", type: "" },
  { name: "Rénovateur de salle de bain", type: "" },
  { name: "Réparation et entretien de voiture", type: "" },
  { name: "Repas à emporter", type: "" },
  { name: "Repérage", type: "" },
  { name: "Réserve de chasse", type: "" },
  { name: "Réserve nationale", type: "" },
  { name: "Réserve naturelle", type: "" },
  { name: "Réserve naturelle", type: "" },
  { name: "Résidence Militar", type: "" },
  { name: "Restaurant à fondue", type: "FOOD_SERVICES" },
  { name: "Restaurant à raclette", type: "FOOD_SERVICES" },
  { name: "Restaurant afghan", type: "FOOD_SERVICES" },
  { name: "Restaurant africain", type: "FOOD_SERVICES" },
  { name: "Restaurant allemand", type: "FOOD_SERVICES" },
  { name: "Restaurant Alsace", type: "FOOD_SERVICES" },
  { name: "Restaurant américain", type: "FOOD_SERVICES" },
  { name: "Restaurant américain traditionnel", type: "FOOD_SERVICES" },
  { name: "Restaurant amérindien", type: "FOOD_SERVICES" },
  { name: "Restaurant An Hui", type: "FOOD_SERVICES" },
  { name: "Restaurant Anago", type: "FOOD_SERVICES" },
  { name: "Restaurant andalou", type: "FOOD_SERVICES" },
  { name: "Restaurant anglais", type: "FOOD_SERVICES" },
  { name: "Restaurant argentin", type: "FOOD_SERVICES" },
  { name: "Restaurant arménien", type: "FOOD_SERVICES" },
  { name: "Restaurant asiatique", type: "FOOD_SERVICES" },
  { name: "Restaurant asiatique du sud-est", type: "FOOD_SERVICES" },
  { name: "Restaurant asturien", type: "FOOD_SERVICES" },
  { name: "Restaurant australien", type: "FOOD_SERVICES" },
  { name: "Restaurant autrichien", type: "FOOD_SERVICES" },
  { name: "Restaurant bangladais", type: "FOOD_SERVICES" },
  { name: "Restaurant Bar à Huîtres", type: "FOOD_SERVICES" },
  { name: "Restaurant barbecue", type: "FOOD_SERVICES" },
  { name: "Restaurant barbecue au mouton", type: "FOOD_SERVICES" },
  { name: "Restaurant barbecue coréen", type: "FOOD_SERVICES" },
  { name: "Restaurant barbecue d’abats", type: "FOOD_SERVICES" },
  { name: "Restaurant barbecue mongol", type: "FOOD_SERVICES" },
  { name: "Restaurant basque", type: "FOOD_SERVICES" },
  { name: "Restaurant bateau à vapeur", type: "FOOD_SERVICES" },
  { name: "Restaurant belge", type: "FOOD_SERVICES" },
  { name: "Restaurant Berry", type: "FOOD_SERVICES" },
  { name: "Restaurant bio", type: "FOOD_SERVICES" },
  { name: "Restaurant birman", type: "FOOD_SERVICES" },
  { name: "Restaurant brésilien", type: "FOOD_SERVICES" },
  { name: "Restaurant britannique", type: "FOOD_SERVICES" },
  { name: "Restaurant britannique moderne", type: "FOOD_SERVICES" },
  { name: "Restaurant brunch", type: "FOOD_SERVICES" },
  { name: "Restaurant buffet", type: "FOOD_SERVICES" },
  { name: "Restaurant bulgare", type: "FOOD_SERVICES" },
  { name: "Restaurant Burrito", type: "FOOD_SERVICES" },
  { name: "Restaurant cachemirien", type: "FOOD_SERVICES" },
  { name: "Restaurant cajun", type: "FOOD_SERVICES" },
  { name: "Restaurant californien", type: "FOOD_SERVICES" },
  { name: "Restaurant cambodgien", type: "FOOD_SERVICES" },
  { name: "Restaurant canadien", type: "FOOD_SERVICES" },
  { name: "Restaurant cantabrique", type: "FOOD_SERVICES" },
  { name: "Restaurant cantonais", type: "FOOD_SERVICES" },
  { name: "Restaurant cap-verdien", type: "FOOD_SERVICES" },
  { name: "Restaurant casher", type: "FOOD_SERVICES" },
  { name: "Restaurant castillan", type: "FOOD_SERVICES" },
  { name: "Restaurant catalan", type: "FOOD_SERVICES" },
  { name: "Restaurant Chanko", type: "FOOD_SERVICES" },
  { name: "Restaurant Cheesesteak", type: "FOOD_SERVICES" },
  { name: "Restaurant Chesapeake", type: "FOOD_SERVICES" },
  { name: "Restaurant chilien", type: "FOOD_SERVICES" },
  { name: "Restaurant chinois", type: "FOOD_SERVICES" },
  { name: "Restaurant Chophouse", type: "FOOD_SERVICES" },
  { name: "Restaurant colombien", type: "FOOD_SERVICES" },
  { name: "Restaurant continental", type: "FOOD_SERVICES" },
  { name: "Restaurant coréen", type: "FOOD_SERVICES" },
  { name: "Restaurant coréen de boeuf", type: "FOOD_SERVICES" },
  { name: "Restaurant costaricain", type: "FOOD_SERVICES" },
  { name: "Restaurant Couscous", type: "FOOD_SERVICES" },
  { name: "Restaurant créole", type: "FOOD_SERVICES" },
  { name: "Restaurant croate", type: "FOOD_SERVICES" },
  { name: "Restaurant cubain", type: "FOOD_SERVICES" },
  { name: "Restaurant d’Afrique de l’Est", type: "FOOD_SERVICES" },
  { name: "Restaurant d’ailes de poulet", type: "FOOD_SERVICES" },
  { name: "Restaurant d’aliments crus", type: "FOOD_SERVICES" },
  { name: "Restaurant d’Amérique centrale", type: "FOOD_SERVICES" },
  { name: "Restaurant d’Europe de l’Est", type: "FOOD_SERVICES" },
  { name: "Restaurant danois", type: "FOOD_SERVICES" },
  { name: "Restaurant de bonbons japonais", type: "FOOD_SERVICES" },
  { name: "Restaurant de bouillie", type: "FOOD_SERVICES" },
  { name: "Restaurant de boulettes", type: "FOOD_SERVICES" },
  { name: "Restaurant de côtes coréennes", type: "FOOD_SERVICES" },
  { name: "Restaurant de crêpes", type: "FOOD_SERVICES" },
  { name: "Restaurant de cuisine à domicile", type: "FOOD_SERVICES" },
  { name: "Restaurant de cuisine champêtre", type: "FOOD_SERVICES" },
  { name: "Restaurant de cuisine d’abats", type: "FOOD_SERVICES" },
  { name: "Restaurant de cuisine Xiang", type: "FOOD_SERVICES" },
  { name: "Restaurant de danse", type: "FOOD_SERVICES" },
  { name: "Restaurant de desserts", type: "FOOD_SERVICES" },
  { name: "Restaurant de fruits de mer", type: "FOOD_SERVICES" },
  { name: "Restaurant de grillades françaises", type: "FOOD_SERVICES" },
  { name: "Restaurant de hamburgers", type: "FOOD_SERVICES" },
  { name: "Restaurant de homard", type: "FOOD_SERVICES" },
  { name: "Restaurant de hot-dogs", type: "FOOD_SERVICES" },
  { name: "Restaurant de la Nouvelle-Angleterre", type: "FOOD_SERVICES" },
  { name: "Restaurant de langue", type: "FOOD_SERVICES" },
  { name: "Restaurant de nouilles Champon", type: "FOOD_SERVICES" },
  { name: "Restaurant de nouilles chinoises", type: "FOOD_SERVICES" },
  { name: "Restaurant de nouilles Dan Dan", type: "FOOD_SERVICES" },
  { name: "Restaurant de nouilles froides", type: "FOOD_SERVICES" },
  { name: "Restaurant de nouilles Udon", type: "FOOD_SERVICES" },
  { name: "Restaurant de petites assiettes", type: "FOOD_SERVICES" },
  { name: "Restaurant de pizza", type: "FOOD_SERVICES" },
  { name: "Restaurant de plats de viande", type: "FOOD_SERVICES" },
  { name: "Restaurant de poisson pêcheur", type: "FOOD_SERVICES" },
  { name: "Restaurant de poulet", type: "FOOD_SERVICES" },
  {
    name: "Restaurant de restauration rapide de style hongkongais",
    type: "FOOD_SERVICES"
  },
  { name: "Restaurant de riz", type: "FOOD_SERVICES" },
  { name: "Restaurant de soupe", type: "FOOD_SERVICES" },
  { name: "Restaurant de sushi", type: "FOOD_SERVICES" },
  { name: "Restaurant de sushis à tapis roulant", type: "FOOD_SERVICES" },
  { name: "Restaurant de tacos", type: "FOOD_SERVICES" },
  { name: "Restaurant de tapas", type: "FOOD_SERVICES" },
  { name: "Restaurant de tofu", type: "FOOD_SERVICES" },
  { name: "Restaurant déjeuner", type: "FOOD_SERVICES" },
  { name: "Restaurant des Caraïbes", type: "FOOD_SERVICES" },
  { name: "Restaurant des Seychelles", type: "FOOD_SERVICES" },
  { name: "Restaurant diététique", type: "FOOD_SERVICES" },
  { name: "Restaurant Dim Sum", type: "FOOD_SERVICES" },
  { name: "Restaurant Dojo", type: "FOOD_SERVICES" },
  { name: "Restaurant dominicain", type: "FOOD_SERVICES" },
  { name: "Restaurant Donburi aux fruits de mer", type: "FOOD_SERVICES" },
  { name: "Restaurant du Hunan", type: "FOOD_SERVICES" },
  { name: "Restaurant du Moyen-Orient", type: "FOOD_SERVICES" },
  {
    name: "Restaurant du nord-ouest du Pacifique (Canada)",
    type: "FOOD_SERVICES"
  },
  { name: "Restaurant du Sichuan", type: "FOOD_SERVICES" },
  { name: "Restaurant éclectique", type: "FOOD_SERVICES" },
  { name: "Restaurant égyptien", type: "FOOD_SERVICES" },
  { name: "Restaurant en libre-service", type: "FOOD_SERVICES" },
  { name: "Restaurant équatorien", type: "FOOD_SERVICES" },
  { name: "Restaurant érythréen", type: "FOOD_SERVICES" },
  { name: "Restaurant espagnol", type: "FOOD_SERVICES" },
  { name: "Restaurant éthiopien", type: "FOOD_SERVICES" },
  { name: "Restaurant ethnique", type: "FOOD_SERVICES" },
  { name: "Restaurant européen", type: "FOOD_SERVICES" },
  { name: "Restaurant européen moderne", type: "FOOD_SERVICES" },
  { name: "Restaurant Falafel", type: "FOOD_SERVICES" },
  { name: "Restaurant familial", type: "FOOD_SERVICES" },
  { name: "Restaurant finlandais", type: "FOOD_SERVICES" },
  { name: "Restaurant Fish & Chips", type: "FOOD_SERVICES" },
  { name: "Restaurant floridien", type: "FOOD_SERVICES" },
  { name: "Restaurant français", type: "FOOD_SERVICES" },
  { name: "Restaurant français moderne", type: "FOOD_SERVICES" },
  { name: "Restaurant Fu Jian", type: "FOOD_SERVICES" },
  { name: "Restaurant Fugu", type: "FOOD_SERVICES" },
  { name: "Restaurant fusion", type: "FOOD_SERVICES" },
  { name: "Restaurant fusion asiatique", type: "FOOD_SERVICES" },
  { name: "Restaurant galicien", type: "FOOD_SERVICES" },
  { name: "Restaurant gallois", type: "FOOD_SERVICES" },
  { name: "Restaurant gastronomique", type: "FOOD_SERVICES" },
  { name: "Restaurant géorgien", type: "FOOD_SERVICES" },
  { name: "Restaurant grec", type: "FOOD_SERVICES" },
  { name: "Restaurant guatémaltèque", type: "FOOD_SERVICES" },
  { name: "Restaurant Gui Zhou", type: "FOOD_SERVICES" },
  { name: "Restaurant Gyro", type: "FOOD_SERVICES" },
  { name: "Restaurant Gyudon", type: "FOOD_SERVICES" },
  { name: "Restaurant haïtien", type: "FOOD_SERVICES" },
  { name: "Restaurant Hakka", type: "FOOD_SERVICES" },
  { name: "Restaurant halal", type: "FOOD_SERVICES" },
  { name: "Restaurant Haute Française", type: "FOOD_SERVICES" },
  { name: "Restaurant hawaïen", type: "FOOD_SERVICES" },
  { name: "Restaurant Hoagie", type: "FOOD_SERVICES" },
  { name: "Restaurant hondurien", type: "FOOD_SERVICES" },
  { name: "Restaurant hongrois", type: "FOOD_SERVICES" },
  { name: "Restaurant Hot Pot", type: "FOOD_SERVICES" },
  { name: "Restaurant indien", type: "FOOD_SERVICES" },
  { name: "Restaurant indonésien", type: "FOOD_SERVICES" },
  { name: "Restaurant irlandais", type: "FOOD_SERVICES" },
  { name: "Restaurant islandais", type: "FOOD_SERVICES" },
  { name: "Restaurant israélien", type: "FOOD_SERVICES" },
  { name: "Restaurant italien", type: "FOOD_SERVICES" },
  { name: "Restaurant italien du nord", type: "FOOD_SERVICES" },
  { name: "Restaurant italien du sud", type: "FOOD_SERVICES" },
  { name: "Restaurant Izakaya", type: "FOOD_SERVICES" },
  { name: "Restaurant jamaïcain", type: "FOOD_SERVICES" },
  { name: "Restaurant japonais", type: "FOOD_SERVICES" },
  { name: "Restaurant japonais au curry", type: "FOOD_SERVICES" },
  { name: "Restaurant japonais authentique", type: "FOOD_SERVICES" },
  { name: "Restaurant japonais de style Kyoto", type: "FOOD_SERVICES" },
  { name: "Restaurant japonais Hot Pot", type: "FOOD_SERVICES" },
  { name: "Restaurant Jiang Su", type: "FOOD_SERVICES" },
  { name: "Restaurant juif", type: "FOOD_SERVICES" },
  { name: "Restaurant Kaiseki", type: "FOOD_SERVICES" },
  { name: "Restaurant Katsudon", type: "FOOD_SERVICES" },
  { name: "Restaurant kazakh", type: "FOOD_SERVICES" },
  { name: "Restaurant Kushiage et Kushikatsu", type: "FOOD_SERVICES" },
  { name: "Restaurant Kushiyaki", type: "FOOD_SERVICES" },
  { name: "Restaurant laotien", type: "FOOD_SERVICES" },
  { name: "Restaurant latino-américain", type: "FOOD_SERVICES" },
  { name: "Restaurant libanais", type: "FOOD_SERVICES" },
  { name: "Restaurant lituanien", type: "FOOD_SERVICES" },
  { name: "Restaurant Louisiane contemporain", type: "FOOD_SERVICES" },
  { name: "Restaurant macrobiotique", type: "FOOD_SERVICES" },
  { name: "Restaurant madrilène", type: "FOOD_SERVICES" },
  { name: "Restaurant malais", type: "FOOD_SERVICES" },
  { name: "Restaurant maltais", type: "FOOD_SERVICES" },
  { name: "Restaurant Mandarin", type: "FOOD_SERVICES" },
  { name: "Restaurant Marche", type: "FOOD_SERVICES" },
  { name: "Restaurant marocain", type: "FOOD_SERVICES" },
  { name: "Restaurant méditerranéen", type: "FOOD_SERVICES" },
  { name: "Restaurant mexicain", type: "FOOD_SERVICES" },
  { name: "Restaurant Mid-Atlantic (États-Unis)", type: "FOOD_SERVICES" },
  { name: "Restaurant Miso Escalope", type: "FOOD_SERVICES" },
  { name: "Restaurant Monjayaki", type: "FOOD_SERVICES" },
  { name: "Restaurant musulman indien", type: "FOOD_SERVICES" },
  { name: "Restaurant napolitain", type: "FOOD_SERVICES" },
  { name: "Restaurant Navarraise", type: "FOOD_SERVICES" },
  { name: "Restaurant néerlandais", type: "FOOD_SERVICES" },
  { name: "Restaurant néerlandais de Pennsylvanie", type: "FOOD_SERVICES" },
  { name: "Restaurant néo-zélandais", type: "FOOD_SERVICES" },
  { name: "Restaurant népalais", type: "FOOD_SERVICES" },
  { name: "Restaurant nicaraguayen", type: "FOOD_SERVICES" },
  { name: "Restaurant nord-africain", type: "FOOD_SERVICES" },
  { name: "Restaurant norvégien", type: "FOOD_SERVICES" },
  { name: "Restaurant Nuevo Latino", type: "FOOD_SERVICES" },
  { name: "Restaurant Nyonya", type: "FOOD_SERVICES" },
  { name: "Restaurant Obanzai", type: "FOOD_SERVICES" },
  { name: "Restaurant occidental", type: "FOOD_SERVICES" },
  { name: "Restaurant occidental japonais", type: "FOOD_SERVICES" },
  { name: "Restaurant Oden", type: "FOOD_SERVICES" },
  { name: "Restaurant Okonomiyaki", type: "FOOD_SERVICES" },
  { name: "Restaurant ouest-africain", type: "FOOD_SERVICES" },
  { name: "Restaurant ouzbek", type: "FOOD_SERVICES" },
  { name: "Restaurant Pacific Rim", type: "FOOD_SERVICES" },
  { name: "Restaurant pakistanais", type: "FOOD_SERVICES" },
  { name: "Restaurant pan-asiatique", type: "FOOD_SERVICES" },
  { name: "Restaurant pan-latin", type: "FOOD_SERVICES" },
  { name: "Restaurant paraguayen", type: "FOOD_SERVICES" },
  { name: "Restaurant persan", type: "FOOD_SERVICES" },
  { name: "Restaurant péruvien", type: "FOOD_SERVICES" },
  { name: "Restaurant petit-déjeuner", type: "FOOD_SERVICES" },
  { name: "Restaurant philippin", type: "FOOD_SERVICES" },
  { name: "Restaurant Pho", type: "FOOD_SERVICES" },
  { name: "Restaurant Po ‘Boys", type: "FOOD_SERVICES" },
  { name: "Restaurant polonais", type: "FOOD_SERVICES" },
  { name: "Restaurant polynésien", type: "FOOD_SERVICES" },
  { name: "Restaurant portoricain", type: "FOOD_SERVICES" },
  { name: "Restaurant portugais", type: "FOOD_SERVICES" },
  { name: "Restaurant Provence", type: "FOOD_SERVICES" },
  { name: "Restaurant Punjabi", type: "FOOD_SERVICES" },
  { name: "Restaurant québécois", type: "FOOD_SERVICES" },
  { name: "Restaurant Ramen", type: "FOOD_SERVICES" },
  { name: "Restaurant régional japonais", type: "FOOD_SERVICES" },
  { name: "Restaurant romain", type: "FOOD_SERVICES" },
  { name: "Restaurant roumain", type: "FOOD_SERVICES" },
  { name: "Restaurant russe", type: "FOOD_SERVICES" },
  { name: "Restaurant Ryotei", type: "FOOD_SERVICES" },
  { name: "Restaurant salvadorien", type: "FOOD_SERVICES" },
  { name: "Restaurant sans gluten", type: "FOOD_SERVICES" },
  { name: "Restaurant scandinave", type: "FOOD_SERVICES" },
  { name: "Restaurant serbe", type: "FOOD_SERVICES" },
  { name: "Restaurant Shabu-shabu", type: "FOOD_SERVICES" },
  { name: "Restaurant Shan Dong", type: "FOOD_SERVICES" },
  { name: "Restaurant shanghaien", type: "FOOD_SERVICES" },
  { name: "Restaurant sicilien", type: "FOOD_SERVICES" },
  { name: "Restaurant singapourien", type: "FOOD_SERVICES" },
  { name: "Restaurant Soul Food", type: "FOOD_SERVICES" },
  { name: "Restaurant sri-lankais", type: "FOOD_SERVICES" },
  { name: "Restaurant sud-africain", type: "FOOD_SERVICES" },
  { name: "Restaurant sud-américain", type: "FOOD_SERVICES" },
  { name: "Restaurant sud-asiatique", type: "FOOD_SERVICES" },
  { name: "Restaurant Sud-Ouest de la France", type: "FOOD_SERVICES" },
  { name: "Restaurant suédois", type: "FOOD_SERVICES" },
  { name: "Restaurant suisse", type: "FOOD_SERVICES" },
  { name: "Restaurant Sukiyaki", type: "FOOD_SERVICES" },
  { name: "Restaurant Sukiyaki et Shabu Shabu", type: "FOOD_SERVICES" },
  { name: "Restaurant Sundae", type: "FOOD_SERVICES" },
  { name: "Restaurant Suppon", type: "FOOD_SERVICES" },
  { name: "Restaurant surinamais", type: "FOOD_SERVICES" },
  { name: "Restaurant Syokudo et Teishoku", type: "FOOD_SERVICES" },
  { name: "Restaurant syrien", type: "FOOD_SERVICES" },
  { name: "Restaurant taïwanais", type: "FOOD_SERVICES" },
  { name: "Restaurant Takoyaki", type: "FOOD_SERVICES" },
  { name: "Restaurant tchèque", type: "FOOD_SERVICES" },
  { name: "Restaurant Tempura", type: "FOOD_SERVICES" },
  { name: "Restaurant Tempura Donburi", type: "FOOD_SERVICES" },
  { name: "Restaurant Teppanyaki", type: "FOOD_SERVICES" },
  { name: "Restaurant Tex-Mex", type: "FOOD_SERVICES" },
  { name: "Restaurant thaï", type: "FOOD_SERVICES" },
  { name: "Restaurant Tonkatsu", type: "FOOD_SERVICES" },
  { name: "Restaurant toscan", type: "FOOD_SERVICES" },
  { name: "Restaurant traditionnel", type: "FOOD_SERVICES" },
  { name: "Restaurant tunisien", type: "FOOD_SERVICES" },
  { name: "Restaurant turc", type: "FOOD_SERVICES" },
  { name: "Restaurant turkmène", type: "FOOD_SERVICES" },
  { name: "Restaurant ukrainien", type: "FOOD_SERVICES" },
  { name: "Restaurant Unagi", type: "FOOD_SERVICES" },
  { name: "Restaurant uruguayen", type: "FOOD_SERVICES" },
  { name: "Restaurant valencien", type: "FOOD_SERVICES" },
  { name: "Restaurant végétalien", type: "FOOD_SERVICES" },
  { name: "Restaurant végétarien", type: "FOOD_SERVICES" },
  { name: "Restaurant vénézuélien", type: "FOOD_SERVICES" },
  { name: "Restaurant vénitien", type: "FOOD_SERVICES" },
  { name: "Restaurant vietnamien", type: "FOOD_SERVICES" },
  { name: "Restaurant Wok", type: "FOOD_SERVICES" },
  { name: "Restaurant Yakiniku", type: "FOOD_SERVICES" },
  { name: "Restaurant Yakitori", type: "FOOD_SERVICES" },
  { name: "Restaurant yéménite", type: "FOOD_SERVICES" },
  { name: "Restaurant Zhe Jiang", type: "FOOD_SERVICES" },
  { name: "Restaurants Izakaya modernes", type: "FOOD_SERVICES" },
  { name: "Restauration rapide", type: "FOOD_SERVICES" },
  { name: "Rhumatologue", type: "" },
  { name: "Ring de boxe", type: "" },
  { name: "Rodeo", type: "" },
  { name: "Sacem", type: "" },
  { name: "Sage-femme", type: "" },
  { name: "Salade", type: "" },
  { name: "Salle", type: "" },
  { name: "Salle d’escalade", type: "" },
  { name: "Salle de bain publique", type: "" },
  { name: "Salle de bain publique accessible en fauteuil roulant", type: "" },
  { name: "Salle de bain publique féminine", type: "" },
  { name: "Salle de bain publique pour hommes", type: "" },
  { name: "Salle de bal", type: "" },
  { name: "Salle de banquet", type: "" },
  { name: "Salle de billard", type: "" },
  { name: "Salle de bingo", type: "" },
  { name: "Salle de boxe Muay Thai", type: "" },
  { name: "Salle de concert", type: "" },
  { name: "Salle de curling", type: "" },
  { name: "Salle de danse", type: "" },
  { name: "Salle de la bière", type: "" },
  { name: "Salle de mariage", type: "" },
  { name: "Salle de réception", type: "" },
  { name: "Salle de réunion", type: "" },
  { name: "Salle de tir à l’arc", type: "" },
  { name: "Salle des enfants", type: "" },
  { name: "Salle des fêtes", type: "" },
  { name: "Salle des scouts", type: "" },
  { name: "Salle du Royaume des Témoins de Jéhovah", type: "" },
  { name: "Salle du village", type: "" },
  { name: "Salle philharmonique", type: "" },
  { name: "Salon", type: "" },
  { name: "Salon d’escrime", type: "" },
  { name: "Salon de beauté", type: "" },
  { name: "Salon de bronzage", type: "" },
  { name: "Salon de coiffure", type: "" },
  { name: "Salon de coiffure", type: "" },
  { name: "Salon de fruits", type: "" },
  { name: "Salon de l’artisanat", type: "" },
  { name: "Salon de l’immobilier", type: "" },
  { name: "Salon de manucure", type: "" },
  { name: "Salon de massage des pieds", type: "" },
  { name: "Salon de thé chinois", type: "" },
  { name: "Salon de thé traditionnel", type: "" },
  { name: "Salon Pachinko", type: "" },
  { name: "Sambodrome", type: "" },
  { name: "Sanctuaire du pilier de la ville", type: "" },
  { name: "Sandwicherie", type: "" },
  { name: "Sauna", type: "" },
  { name: "Sauna gay", type: "" },
  { name: "Sauna public", type: "" },
  { name: "Scierie", type: "" },
  { name: "Scrivener judiciaire", type: "" },
  { name: "Sculpteur", type: "" },
  { name: "Sculpture", type: "" },
  { name: "Sculpture sur pierre", type: "" },
  { name: "se garer", type: "" },
  { name: "Sécurité et santé au travail", type: "" },
  { name: "Seitai", type: "" },
  { name: "Sellerie", type: "" },
  { name: "Séminaire", type: "" },
  { name: "Séminaire religieux", type: "" },
  { name: "Serre", type: "" },
  { name: "Serrurier", type: "" },
  { name: "Serveur de processus", type: "" },
  { name: "Service Agistment", type: "" },
  { name: "Service agricole", type: "" },
  { name: "Service automobile", type: "" },
  { name: "Service aux sans-abri", type: "" },
  { name: "Service aux victimes d’actes criminels", type: "" },
  { name: "Service client électroménagers", type: "" },
  { name: "Service d’accordage de piano", type: "" },
  { name: "Service d’administration des affaires", type: "" },
  { name: "Service d’adoption d’animaux de compagnie", type: "" },
  { name: "Service d’aérographe", type: "" },
  { name: "Service d’affûtage", type: "" },
  { name: "Service d’affûtage de patins", type: "" },
  { name: "Service d’affûtage de scie", type: "" },
  { name: "Service d’agent de sécurité", type: "" },
  { name: "Service d’alignement des roues", type: "" },
  { name: "Service d’ambulance", type: "" },
  { name: "Service d’analyse de l’amiante", type: "" },
  { name: "Service d’analyse de l’eau", type: "" },
  { name: "Service d’analyse des sols", type: "" },
  { name: "Service d’analyse sanguine", type: "" },
  { name: "Service d’annonce de l’heure et de la température", type: "" },
  { name: "Service d’antenne", type: "" },
  { name: "Service d’arbre", type: "" },
  { name: "Service d’aromathérapie", type: "" },
  { name: "Service d’ascenseur", type: "" },
  { name: "Service d’assainissement", type: "" },
  { name: "Service d’assistance aux petites créances", type: "" },
  { name: "Service d’autobus scolaire", type: "" },
  { name: "Service d’élimination des eaux usées", type: "" },
  { name: "Service d’emboutissage de métal", type: "" },
  { name: "Service d’empreintes digitales", type: "" },
  { name: "Service d’encaissement de chèques", type: "" },
  { name: "Service d’enlèvement automatique de dent", type: "" },
  { name: "Service d’enlèvement des débris", type: "" },
  { name: "Service d’entiercement", type: "" },
  { name: "Service d’entretien de pelouse", type: "" },
  { name: "Service d’épilation", type: "" },
  { name: "Service d’épilation à la cire", type: "" },
  { name: "Service d’épilation au laser", type: "" },
  { name: "Service d’épilation par électrolyse", type: "" },
  { name: "Service d’équitation", type: "" },
  { name: "Service d’escorte de transport", type: "" },
  { name: "Service d’expédition de fret", type: "" },
  { name: "Service d’expédition et d’expédition", type: "" },
  { name: "Service d’exploration pétrolière et gazière", type: "" },
  { name: "Service d’hébergement de vacances", type: "" },
  { name: "Service d’hygiène du milieu", type: "" },
  { name: "Service d’hypnothérapie", type: "" },
  { name: "Service d’immigration et de naturalisation", type: "" },
  { name: "Service d’imperméabilisation", type: "" },
  { name: "Service d’impression d’invitations", type: "" },
  { name: "Service d’impression numérique", type: "" },
  { name: "Service d’insolvabilité", type: "" },
  { name: "Service d’installation de fenêtres", type: "" },
  { name: "Service d’installation de gaz", type: "" },
  { name: "Service d’installation de parquet", type: "" },
  { name: "Service d’installation de stalle", type: "" },
  { name: "Service d’installation électrique", type: "" },
  { name: "Service d’investissement", type: "" },
  { name: "Service d’orientation professionnelle", type: "" },
  { name: "Service d’orthèses et de prothèses", type: "" },
  { name: "Service d’usine d’intérieur", type: "" },
  { name: "Service de balade à poney", type: "" },
  { name: "Service de blanchiment des dents", type: "" },
  { name: "Service de blanchisserie", type: "" },
  { name: "Service de broderie", type: "" },
  { name: "Service de camion à benne basculante", type: "" },
  { name: "Service de cartographie", type: "" },
  { name: "Service de cercueil", type: "" },
  { name: "Service de certificat médical", type: "" },
  { name: "Service de change", type: "" },
  { name: "Service de cirage de chaussures", type: "" },
  { name: "Service de climatisation automatique", type: "" },
  { name: "Service de collecte des ordures", type: "" },
  { name: "Service de commerce électronique", type: "" },
  { name: "Service de communication de la tour", type: "" },
  { name: "Service de communication par satellite", type: "" },
  { name: "Service de comptabilité", type: "" },
  { name: "Service de conciergerie", type: "" },
  { name: "Service de conseil en crédit", type: "" },
  { name: "Service de conteneurs", type: "" },
  { name: "Service de contrôle des animaux", type: "" },
  { name: "Service de contrôle des oiseaux", type: "" },
  { name: "Service de couches", type: "" },
  { name: "Service de coupe de verre", type: "" },
  { name: "Service de courrier", type: "" },
  { name: "Service de crémation", type: "" },
  { name: "Service de déchiquetage", type: "" },
  { name: "Service de découpe au jet d’eau", type: "" },
  { name: "Service de découpe laser", type: "" },
  { name: "Service de déménagement d’animaux de compagnie", type: "" },
  { name: "Service de déménagement de piano", type: "" },
  { name: "Service de déménagement et de stockage", type: "" },
  { name: "Service de déneigement", type: "" },
  { name: "Service de dépistage de drogues", type: "" },
  { name: "Service de dépotoir", type: "" },
  { name: "Service de détail de voiture", type: "" },
  { name: "Service de détatouage", type: "" },
  { name: "Service de développement des affaires", type: "" },
  { name: "Service de distribution", type: "" },
  { name: "Service de distribution circulaire", type: "" },
  { name: "Service de divertissement d’entreprise", type: "" },
  { name: "Service de divorce", type: "" },
  { name: "Service de drainage", type: "" },
  { name: "Service de duplication de clé", type: "" },
  { name: "Service de duplication vidéo", type: "" },
  { name: "Service de facturation médicale", type: "" },
  { name: "Service de faillite", type: "" },
  { name: "Service de fête pour enfants", type: "" },
  { name: "Service de finition de parquet", type: "" },
  { name: "Service de finition de plancher", type: "" },
  { name: "Service de forclusion", type: "" },
  { name: "Service de fourrure", type: "" },
  { name: "Service de frappe", type: "" },
  { name: "Service de garde en famille", type: "" },
  { name: "Service de gaufrage", type: "" },
  { name: "Service de gestion des déchets", type: "" },
  { name: "Service de gravure sur verre", type: "" },
  { name: "Service de grue", type: "" },
  { name: "Service de l’emploi de la ville", type: "" },
  { name: "Service de lavage sous pression", type: "" },
  { name: "Service de limousine", type: "" },
  { name: "Service de liquidation de maison", type: "" },
  { name: "Service de livraison", type: "" },
  { name: "Service de livraison", type: "" },
  { name: "Service de livraison d’épicerie", type: "" },
  { name: "Service de livraison de lait", type: "" },
  { name: "Service de location", type: "" },
  { name: "Service de location d’aéronefs", type: "" },
  { name: "Service de location d’appareils", type: "" },
  { name: "Service de location d’échafaudages", type: "" },
  { name: "Service de location d’équipement audiovisuel", type: "" },
  { name: "Service de location d’équipement de bureau", type: "" },
  { name: "Service de location d’équipement de construction", type: "" },
  { name: "Service de location d’équipement de fête", type: "" },
  { name: "Service de location d’équipement de karaoké", type: "" },
  { name: "Service de location d’équipement de pelouse", type: "" },
  { name: "Service de location d’équipement de sport", type: "" },
  { name: "Service de location d’équipement de sports nautiques", type: "" },
  { name: "Service de location d’instruments de musique", type: "" },
  { name: "Service de location d’outils", type: "" },
  { name: "Service de location de bateaux", type: "" },
  { name: "Service de location de boîtes aux lettres", type: "" },
  { name: "Service de location de canoë et kayak", type: "" },
  { name: "Service de location de chapiteaux", type: "" },
  { name: "Service de location de chariots élévateurs", type: "" },
  { name: "Service de location de chevaux", type: "" },
  { name: "Service de location de costumes", type: "" },
  { name: "Service de location de fauteuils roulants", type: "" },
  { name: "Service de location de jeux vidéo", type: "" },
  { name: "Service de location de machines de construction", type: "" },
  { name: "Service de location de meubles", type: "" },
  { name: "Service de location de motoneige", type: "" },
  { name: "Service de location de péniches", type: "" },
  { name: "Service de location de quad", type: "" },
  { name: "Service de location de remorques", type: "" },
  { name: "Service de location de robes complètes", type: "" },
  { name: "Service de location de robes de mariée", type: "" },
  { name: "Service de location de robes de soirée", type: "" },
  { name: "Service de location de robes et de smoking", type: "" },
  { name: "Service de location de scooter", type: "" },
  { name: "Service de location de ski", type: "" },
  { name: "Service de location de snowboard", type: "" },
  { name: "Service de location de tables et chaises", type: "" },
  { name: "Service de location de tente", type: "" },
  { name: "Service de location de vélos", type: "" },
  { name: "Service de location de voitures", type: "" },
  { name: "Service de location de VTT", type: "" },
  { name: "Service de lutte antiparasitaire", type: "" },
  { name: "Service de mammographie", type: "" },
  { name: "Service de mandats", type: "" },
  { name: "Service de mariage", type: "" },
  { name: "Service de marketing Internet", type: "" },
  { name: "Service de marquage de ligne", type: "" },
  { name: "Service de médecine interne", type: "" },
  { name: "Service de médiation", type: "" },
  { name: "Service de ménage", type: "" },
  { name: "Service de meulage d’outils", type: "" },
  { name: "Service de mise au point automatique", type: "" },
  { name: "Service de modification de vêtements", type: "" },
  { name: "Service de monogrammation", type: "" },
  { name: "Service de montage vidéo", type: "" },
  { name: "Service de moulage par injection plastique", type: "" },
  { name: "Service de navette aéroport", type: "" },
  { name: "Service de nettoyage", type: "" },
  { name: "Service de nettoyage commercial", type: "" },
  { name: "Service de nettoyage de gouttières", type: "" },
  { name: "Service de nettoyage de piscine", type: "" },
  { name: "Service de nettoyage de plage", type: "" },
  { name: "Service de nettoyage de réservoir d’eau", type: "" },
  {
    name: "Service de nettoyage de rideaux et de tissus d’ameublement",
    type: ""
  },
  { name: "Service de nettoyage de tapis", type: "" },
  { name: "Service de nettoyage de tissus d’ameublement", type: "" },
  { name: "Service de nettoyage de vitres", type: "" },
  { name: "Service de nettoyage des conduits d’air", type: "" },
  { name: "Service de nettoyage du cuir", type: "" },
  { name: "Service de nettoyage par explosion", type: "" },
  { name: "Service de nouvelles", type: "" },
  { name: "Service de paie", type: "" },
  { name: "Service de parachute ascensionnel", type: "" },
  { name: "Service de pension pour animaux", type: "" },
  { name: "Service de perçage des oreilles", type: "" },
  { name: "Service de perte de poids", type: "" },
  { name: "Service de photographie", type: "" },
  { name: "Service de placage", type: "" },
  { name: "Service de placement familial", type: "" },
  { name: "Service de placement familial pour adultes", type: "" },
  { name: "Service de planification de réunions", type: "" },
  { name: "Service de plans directeurs", type: "" },
  { name: "Service de plastification", type: "" },
  { name: "Service de polissage des métaux", type: "" },
  { name: "Service de ponçage et de polissage des planchers", type: "" },
  { name: "Service de préparation d’impôts", type: "" },
  { name: "Service de prévisions météorologiques", type: "" },
  { name: "Service de production vidéo", type: "" },
  { name: "Service de promenade en traîneau à chiens", type: "" },
  { name: "Service de protection des femmes", type: "" },
  { name: "Service de protection incendie", type: "" },
  { name: "Service de publication assistée par ordinateur", type: "" },
  { name: "Service de publicité", type: "" },
  { name: "Service de recherche d’emploi", type: "" },
  { name: "Service de reconstruction de moteur", type: "" },
  { name: "Service de récupération de données", type: "" },
  { name: "Service de rédaction", type: "" },
  { name: "Service de référence aux avocats", type: "" },
  { name: "Service de référence aux médecins", type: "" },
  { name: "Service de référence de colocation", type: "" },
  { name: "Service de remorquage", type: "" },
  { name: "Service de remplacement capillaire", type: "" },
  { name: "Service de rencontres", type: "" },
  { name: "Service de rénovation de bureaux", type: "" },
  {
    name: "Service de rénovation et de restauration de cadres photo",
    type: "FOOD_SERVICES"
  },
  { name: "Service de réparation", type: "" },
  { name: "Service de réparation d’appareils", type: "" },
  { name: "Service de réparation d’appareils auditifs", type: "" },
  { name: "Service de réparation d’écran", type: "" },
  { name: "Service de réparation d’équipement agricole", type: "" },
  { name: "Service de réparation d’équipement audiovisuel", type: "" },
  { name: "Service de réparation d’équipement vidéo", type: "" },
  { name: "Service de réparation d’horloge", type: "" },
  { name: "Service de réparation d’imprimantes", type: "" },
  { name: "Service de réparation de bagages", type: "" },
  { name: "Service de réparation de balance", type: "" },
  { name: "Service de réparation de bijoux", type: "" },
  { name: "Service de réparation de caméra vidéo", type: "" },
  { name: "Service de réparation de climatisation", type: "" },
  { name: "Service de réparation de compresseur d’air", type: "" },
  { name: "Service de réparation de copieur", type: "" },
  { name: "Service de réparation de cuir", type: "" },
  { name: "Service de réparation de fauteuils roulants", type: "" },
  { name: "Service de réparation de fibre de verre", type: "" },
  { name: "Service de réparation de four à micro-ondes", type: "" },
  { name: "Service de réparation de fournaise", type: "" },
  { name: "Service de réparation de lampe", type: "" },
  { name: "Service de réparation de laveuse et sécheuse", type: "" },
  { name: "Service de réparation de machine à écrire", type: "" },
  { name: "Service de réparation de machines", type: "" },
  { name: "Service de réparation de machines à coudre", type: "" },
  {
    name: "Service de réparation de machines de construction routière",
    type: ""
  },
  { name: "Service de réparation de magnétoscope", type: "" },
  { name: "Service de réparation de matelas à eau", type: "" },
  { name: "Service de réparation de matériel de bureau", type: "" },
  { name: "Service de réparation de montres", type: "" },
  { name: "Service de réparation de moteurs diesel", type: "" },
  {
    name: "Service de réparation de petits appareils électroménagers",
    type: ""
  },
  { name: "Service de réparation de petits moteurs", type: "" },
  { name: "Service de réparation de pianos", type: "" },
  { name: "Service de réparation de piscine", type: "" },
  { name: "Service de réparation de radiateur", type: "" },
  { name: "Service de réparation de radiateur automatique", type: "" },
  { name: "Service de réparation de réfrigérateur", type: "" },
  { name: "Service de réparation de skis", type: "" },
  { name: "Service de réparation de spas", type: "" },
  { name: "Service de réparation de téléphone", type: "" },
  { name: "Service de réparation de téléviseurs", type: "" },
  { name: "Service de réparation de tondeuse à gazon", type: "" },
  { name: "Service de réparation de verre", type: "" },
  { name: "Service de réparation hydraulique", type: "" },
  { name: "Service de réparation informatique", type: "" },
  { name: "Service de réparation stéréo", type: "" },
  { name: "Service de réponse téléphonique", type: "" },
  { name: "Service de reprise", type: "" },
  { name: "Service de restauration automatique", type: "FOOD_SERVICES" },
  { name: "Service de restauration d’art", type: "FOOD_SERVICES" },
  { name: "Service de restauration de bâtiments", type: "FOOD_SERVICES" },
  {
    name: "Service de restauration de meubles anciens",
    type: "FOOD_SERVICES"
  },
  { name: "Service de restauration de photos", type: "FOOD_SERVICES" },
  { name: "Service de restauration de poupées", type: "FOOD_SERVICES" },
  {
    name: "Service de restauration des dégâts d’eau",
    type: "FOOD_SERVICES"
  },
  {
    name: "Service de restauration en cas d’incendie",
    type: "FOOD_SERVICES"
  },
  { name: "Service de revêtement en poudre", type: "" },
  { name: "Service de sablage", type: "" },
  { name: "Service de saisie de données", type: "" },
  { name: "Service de santé au travail", type: "" },
  { name: "Service de santé mentale", type: "" },
  { name: "Service de sauvetage de la faune", type: "" },
  { name: "Service de sauvetage des animaux", type: "" },
  { name: "Service de sécurité", type: "" },
  { name: "Service de sécurité informatique", type: "" },
  { name: "Service de serrurerie d’urgence", type: "" },
  { name: "Service de ski nautique", type: "" },
  { name: "Service de soins à domicile", type: "" },
  { name: "Service de soins d’urgence", type: "" },
  { name: "Service de suppression des graffitis", type: "" },
  { name: "Service de système septique", type: "" },
  { name: "Service de taxi", type: "" },
  { name: "Service de taxi minibus", type: "" },
  { name: "Service de technologie événementielle", type: "" },
  { name: "Service de teinture de fenêtres", type: "" },
  { name: "Service de télécopie", type: "" },
  { name: "Service de télégramme chantant", type: "" },
  { name: "Service de télémarketing", type: "" },
  { name: "Service de test de paternité", type: "" },
  { name: "Service de test STD", type: "" },
  { name: "Service de tests pédagogiques", type: "" },
  { name: "Service de traitement thermique des métaux", type: "" },
  { name: "Service de transcription", type: "" },
  { name: "Service de transcription médicale", type: "" },
  { name: "Service de transfert d’argent", type: "" },
  { name: "Service de transport", type: "" },
  { name: "Service de transport en calèche", type: "" },
  { name: "Service de transport frigorifique", type: "" },
  { name: "Service de transport pour personnes handicapées", type: "" },
  { name: "Service de tutorat", type: "" },
  { name: "Service de vidange d’huile", type: "" },
  { name: "Service de visioconférence", type: "" },
  { name: "Service de voiturier", type: "" },
  { name: "Service dentaire d’urgence", type: "" },
  { name: "Service des cautions", type: "" },
  { name: "Service des certificats de naissance", type: "" },
  { name: "Service DJ", type: "" },
  { name: "Service électrique automobile", type: "" },
  { name: "Service entreprise à entreprise", type: "" },
  { name: "Service forestier", type: "" },
  { name: "Service funéraire pour animaux de compagnie", type: "" },
  { name: "Service géologique", type: "" },
  { name: "Service Homekill", type: "" },
  { name: "Service informatique", type: "" },
  { name: "Service logistique", type: "" },
  { name: "Service polygraphique", type: "" },
  { name: "Service sourd", type: "" },
  { name: "Service technique", type: "" },
  { name: "Service vétérinaire d’urgence", type: "" },
  { name: "Services d’information", type: "" },
  { name: "Services de cheminée", type: "" },
  { name: "Services de Fortune Telling", type: "" },
  { name: "Services ferroviaires", type: "" },
  { name: "Services juridiques", type: "" },
  { name: "Services médicaux locaux", type: "" },
  { name: "seuil", type: "" },
  { name: "Sexologue", type: "" },
  { name: "Shintoïste", type: "" },
  { name: "Siège social", type: "" },
  { name: "Silo à céréales", type: "" },
  { name: "Site de reconstitution", type: "" },
  { name: "Site Superfund", type: "" },
  { name: "Skittle Alley", type: "" },
  { name: "Skittle Club", type: "" },
  { name: "Snack Bar", type: "" },
  { name: "Soapland", type: "" },
  { name: "Société aérospatiale", type: "" },
  { name: "Société BPO", type: "" },
  { name: "Société d’électronique", type: "" },
  { name: "Société d’énergie solaire", type: "" },
  { name: "Société d’énergie thermique", type: "" },
  { name: "Société d’exploitation portuaire", type: "" },
  { name: "Société d’imperméabilisation", type: "" },
  { name: "Société d’investissement", type: "" },
  { name: "Société d’investissements", type: "" },
  { name: "Société de biotechnologie", type: "" },
  { name: "Société de capital-risque", type: "" },
  { name: "Société de construction", type: "" },
  { name: "Société de financement et de prêt automobile", type: "" },
  { name: "Société de Flocage", type: "" },
  { name: "Société de génie civil", type: "" },
  { name: "Société de gestion d’événements", type: "" },
  { name: "Société de gestion de base de données", type: "" },
  { name: "Société de gestion de fonds", type: "" },
  { name: "Société de gestion immobilière", type: "" },
  { name: "Société de logement", type: "" },
  { name: "Société de logiciels", type: "" },
  { name: "Société de logiciels de comptabilité", type: "" },
  { name: "Société de maintenance d’aéronefs", type: "" },
  { name: "Société de métallurgie", type: "" },
  { name: "Société de production cinématographique", type: "" },
  { name: "Société de recherche géologique", type: "" },
  { name: "Société de topographie", type: "" },
  { name: "Société historique", type: "" },
  { name: "Société minière", type: "" },
  { name: "Société minière d’or", type: "" },
  { name: "Soin des pieds", type: "" },
  { name: "Soins aux personnes âgées", type: "" },
  { name: "Soins jeunesse", type: "" },
  { name: "Soudeur", type: "" },
  { name: "Soudeur en aluminium", type: "" },
  { name: "Souffleur de verre", type: "" },
  { name: "Soupe populaire", type: "FOOD_SERVICES" },
  { name: "Southern Restaurant (États-Unis)", type: "FOOD_SERVICES" },
  { name: "Southwestern Restaurant (États-Unis)", type: "FOOD_SERVICES" },
  { name: "Spa", type: "" },
  { name: "Spa aux poissons", type: "" },
  { name: "Spa et club de santé", type: "" },
  { name: "Spa facial", type: "" },
  { name: "Spa Hot Bedstone", type: "" },
  { name: "Spa médical", type: "" },
  { name: "Spa santé", type: "" },
  { name: "Sports d’aventure", type: "" },
  { name: "Spot de parkour", type: "" },
  { name: "Spot Wi-Fi", type: "" },
  { name: "Stable", type: "" },
  { name: "Stade", type: "" },
  { name: "Stade Greyhound", type: "" },
  { name: "Stand de hot-dog", type: "" },
  { name: "station balnéaire", type: "" },
  { name: "Station d’épuration", type: "" },
  { name: "Station d’épuration", type: "" },
  { name: "Station d’hygiène", type: "" },
  { name: "Station d’inspection de voiture", type: "" },
  { name: "Station d’inspection du smog", type: "" },
  { name: "Station de carburant alternative", type: "" },
  { name: "Station de la Garde côtière", type: "" },
  { name: "Station de partage de vélos", type: "" },
  { name: "station de pesée", type: "" },
  { name: "Station de recharge pour véhicules électriques", type: "" },
  { name: "Station de santé", type: "" },
  { name: "Station de ski", type: "" },
  { name: "Station de taxi", type: "" },
  { name: "Station équestre", type: "" },
  { name: "station thermale", type: "" },
  { name: "Station-essence", type: "" },
  { name: "Statuaire", type: "" },
  { name: "Steak House", type: "" },
  { name: "Steakhouse japonais", type: "" },
  { name: "Sténographe judiciaire", type: "" },
  { name: "Studio d’animation", type: "" },
  { name: "Studio d’art", type: "" },
  { name: "Studio d’enregistrement", type: "" },
  { name: "Studio de cinéma", type: "" },
  { name: "Studio de photographie", type: "" },
  { name: "Studio de Pilates", type: "" },
  { name: "Studio de portrait", type: "" },
  { name: "Studio de vitrail", type: "" },
  { name: "Studio de Yoga", type: "" },
  { name: "Studio de yoga Bikram", type: "" },
  { name: "Styliste", type: "" },
  { name: "Styliste modéliste", type: "" },
  { name: "Sucrerie", type: "" },
  { name: "Super bain public", type: "" },
  { name: "Supermarché", type: "" },
  { name: "Supermarché chinois", type: "" },
  { name: "Supermarché Discount", type: "" },
  { name: "Supermarché industriel", type: "" },
  { name: "Synagogue", type: "" },
  { name: "Synagogue conservatrice", type: "" },
  { name: "Synagogue messianique", type: "" },
  { name: "Synagogue orthodoxe", type: "" },
  { name: "Synagogue réformée", type: "" },
  { name: "Syndicat de travail", type: "" },
  { name: "Syndicat étudiant", type: "" },
  { name: "Tache scénique", type: "" },
  { name: "Tailleur", type: "" },
  { name: "Tailleur de pierre", type: "" },
  { name: "Tailleur homme", type: "" },
  { name: "Tailleur personnalisé", type: "" },
  { name: "Tannerie", type: "" },
  { name: "Taxi aérien", type: "" },
  { name: "Taxidermiste", type: "" },
  { name: "Technicien en extension de cheveux", type: "" },
  { name: "Teintures", type: "" },
  { name: "Teinturier", type: "" },
  { name: "Téléphérique de montagne", type: "" },
  { name: "Téléphone public", type: "" },
  { name: "Télévision en circuit fermé", type: "" },
  { name: "Temple bouddhiste", type: "" },
  { name: "Temple hindou", type: "" },
  { name: "Temple jaïn", type: "" },
  { name: "Temple Parsi", type: "" },
  { name: "Temple taoïste", type: "" },
  { name: "Tennis de table", type: "" },
  { name: "Terminal à conteneurs", type: "" },
  { name: "Terminal de croisière", type: "" },
  { name: "Terrain d’athlétisme", type: "" },
  { name: "Terrain d’équitation", type: "" },
  { name: "Terrain de badminton", type: "" },
  { name: "Terrain de baseball", type: "" },
  { name: "Terrain de basketball", type: "" },
  { name: "Terrain de beach-volley", type: "" },
  { name: "Terrain de cricket", type: "" },
  { name: "Terrain de football", type: "" },
  { name: "Terrain de football américain", type: "" },
  { name: "Terrain de golf", type: "" },
  { name: "Terrain de golf intérieur", type: "" },
  { name: "Terrain de golf privé", type: "" },
  { name: "Terrain de golf public", type: "" },
  { name: "Terrain de handball", type: "" },
  { name: "Terrain de hockey", type: "" },
  { name: "Terrain de jeux", type: "" },
  { name: "Terrain de pétanque", type: "" },
  { name: "Terrain de pique-nique", type: "" },
  { name: "Terrain de rugby", type: "" },
  { name: "Terrain de softball", type: "" },
  { name: "Terrain de stationnement", type: "" },
  { name: "Terrain de volleyball", type: "" },
  { name: "Théâtre amateur", type: "" },
  { name: "Théâtre de ballet", type: "" },
  { name: "Théâtre de marionnettes", type: "" },
  { name: "Théâtre des arts du spectacle", type: "" },
  { name: "Théâtre dramatique", type: "" },
  { name: "Théâtre flamenco", type: "" },
  { name: "Théâtre Imax", type: "" },
  { name: "Théâtre pour enfants", type: "" },
  { name: "Thérapeute en biofeedback", type: "" },
  { name: "Thérapeute en drainage lymphatique", type: "" },
  { name: "Thérapeute Reiki", type: "" },
  { name: "Thérapie cranio-sacrée", type: "" },
  { name: "Thermes", type: "" },
  { name: "Tir à l’arc", type: "" },
  { name: "Titre de la société", type: "" },
  { name: "Toiletteur pour animaux", type: "" },
  { name: "Tombeau", type: "" },
  { name: "Tondeuse à moutons", type: "" },
  { name: "Torréfacteurs de café", type: "" },
  { name: "Tournage", type: "" },
  { name: "Traducteur", type: "" },
  { name: "Traitement du poisson", type: "" },
  { name: "Traiteur", type: "" },
  { name: "Traiteur mobile", type: "" },
  { name: "Transformation des fruits et légumes", type: "" },
  { name: "Transporteur", type: "" },
  { name: "Transporteur à domicile fabriqué", type: "" },
  { name: "Travailleur social", type: "" },
  { name: "Travaux d’eau", type: "" },
  { name: "Travaux de fer", type: "" },
  { name: "Travaux de hauteur", type: "" },
  { name: "Trésor du Département d’État", type: "" },
  { name: "Troisième école secondaire", type: "" },
  { name: "Troupe de danse du lion", type: "" },
  { name: "Trust Bank", type: "" },
  { name: "Tune Up Fournisseur", type: "" },
  { name: "Tuning automobile", type: "" },
  { name: "Tuteur privé", type: "" },
  { name: "Un bar karaoké", type: "" },
  { name: "Un dortoir étudiant", type: "" },
  { name: "Un espace de travail commun", type: "" },
  { name: "Un jour de spa", type: "" },
  { name: "Un organisateur de soirée", type: "" },
  { name: "Un service de traversier", type: "" },
  { name: "Une école de commerce", type: "" },
  { name: "Union des locataires", type: "" },
  { name: "Université", type: "" },
  { name: "Université ouverte", type: "" },
  { name: "Université privée", type: "" },
  { name: "Université publique", type: "" },
  { name: "Université technique", type: "" },
  { name: "Urgences", type: "" },
  { name: "Urologue", type: "" },
  { name: "Usine automobile", type: "" },
  { name: "Usine chimique", type: "" },
  { name: "Usine d’acier inoxydable", type: "" },
  { name: "Usine d’incinération", type: "" },
  { name: "Usine de béton", type: "" },
  { name: "Usine de dessalement", type: "" },
  { name: "Usine de filtration", type: "" },
  { name: "Usine de gravier", type: "" },
  { name: "Usine de mélange d’asphalte", type: "" },
  { name: "Usine de refroidissement", type: "" },
  { name: "Usine de sable", type: "" },
  { name: "Usine de textile", type: "" },
  { name: "Usine de tissage", type: "" },
  { name: "Usine mécanique", type: "" },
  { name: "Vélodrome", type: "" },
  { name: "Vendeur de billets d’événement", type: "" },
  { name: "Vénéréologue", type: "" },
  { name: "Vente en gros d’eau minérale", type: "" },
  { name: "Vente en gros de vélos", type: "" },
  { name: "Vente en gros Fleuriste", type: "" },
  { name: "Vente en gros Pépinière", type: "" },
  { name: "Vente en gros Pharmacie", type: "" },
  { name: "Verger", type: "" },
  { name: "Vétérinaire", type: "" },
  { name: "Vidéothèque", type: "" },
  { name: "Vignoble", type: "" },
  { name: "Vignoble", type: "" },
  { name: "Villa", type: "" },
  { name: "Village de chalets", type: "" },
  { name: "Ville de la sécurité routière", type: "" },
  { name: "Ville militaire", type: "" },
  { name: "Ville morte", type: "" },
  { name: "Ville thermale", type: "" },
  { name: "Visite des ménages agricoles", type: "" },
  { name: "Vitrier", type: "" },
  { name: "Voilier", type: "" },
  { name: "Voirie", type: "" },
  { name: "Voyagiste", type: "" },
  { name: "Webcam publique", type: "" },
  { name: "Wrecker automatique", type: "" },
  { name: "Yakatabune", type: "" },
  { name: "Yeshiva", type: "" },
  { name: "Zac", type: "" },
  { name: "Zone à faibles émissions", type: "" },
  { name: "Zone d’aviron", type: "" },
  { name: "Zone d’événement de tir", type: "" },
  { name: "Zone d’événement de voile", type: "" },
  { name: "Zone d’haltérophilie", type: "" },
  { name: "Zone d’observation des oiseaux", type: "" },
  { name: "Zone de canoë", type: "" },
  { name: "Zone de chasse", type: "" },
  { name: "Zone de formation des apprenants conducteurs", type: "" },
  { name: "Zone de randonnée", type: "" },
  { name: "Zone de Tae Kwon Do Comp", type: "" },
  { name: "Zone hors route", type: "" },
  { name: "Zone industrielle", type: "" },
  { name: "Zone piétonne", type: "" },
  { name: "Zoo", type: "" }
]
