/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Chip, styled } from "@mui/material"
import React, { FC } from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

interface ItemStatusProps {
  item: boolean | null
  id: number
  onChange?: (item: boolean, id: number) => void
}

const StatusTextColor = (status: boolean) => {
  switch (status) {
    case true:
      return { text: "Récupérer", color: "success" }
    case false:
      return { text: "Non-récupérer", color: "warning" }

    default:
      return { text: "Non-récupérer", color: "warning" }
  }
}

const ChipChip = styled(Chip)(() => ({
  padding: 0,
  height: 25
}))

export const WinnerStatus: FC<ItemStatusProps> = ({ item, onChange, id }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.SyntheticEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChange = (status: boolean) => {
    if (onChange) {
      onChange(status, id)
    }
    setAnchorEl(null)
  }
  const statusList = [
    {
      text: "récupérer",
      color: "success",
      value: 1,
      bool: true
    },
    {
      text: "non-récupérer",
      color: "warning",
      value: 2,
      bool: false
    }
  ]

  return (
    <Box>
      <ChipChip
        label={StatusTextColor(item === null ? true : item).text}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color={StatusTextColor(item === null ? true : item).color as any}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {statusList.map((status) => {
          return (
            <MenuItem
              key={status.value}
              onClick={() => handleChange(status.bool)}
            >
              <Chip
                label={StatusTextColor(status.bool).text}
                color={StatusTextColor(status.bool).color as any}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}
