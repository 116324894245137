import * as React from "react"
import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/Button"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import WebIcon from "@mui/icons-material/Web"
import LogoutIcon from "@mui/icons-material/Logout"
import { useNavigate } from "react-router-dom"
import {
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider
} from "@mui/material"
import { useApplicationContext, useRouteValidation } from "../../hooks"
import { AdminType } from "../../types"

interface UserMenusProps {
  logout: () => void
}
export const UserMenus: React.FC<UserMenusProps> = ({
  logout
}): JSX.Element => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleNavigate = (path?: string): void => {
    const pathEdited = path && path.endsWith("*") ? path.replace("*", "") : path
    setAnchorEl(null)
    navigate(`/discount/${pathEdited ?? ""}`)
  }

  const { user, business } = useApplicationContext()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const idRef = open ? "simple-popover" : undefined
  const handleLogout = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    logout()
  }
  const isNotAdmin = user?.type !== AdminType.ADMIN

  return (
    <div>
      <IconButton onClick={handleClick} sx={{ p: 0, mx: 2 }}>
        <Typography
          sx={{ mr: 1, textTransform: "none" }}
          color="#999"
          variant="subtitle1"
        >
          {business?.businessName}
        </Typography>
        <Avatar sx={{ width: 40, height: 40 }} src={business?.logo || ""}>
          {business?.businessName?.charAt(0)}
        </Avatar>
      </IconButton>
      <Popover
        id={idRef}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Box>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader id="nested-list-subheader">
                <Box sx={{ textAlign: "center", p: 2 }}>
                  <Typography variant="subtitle1">
                    {isNotAdmin ? user?.firstname : "Wizitag"}{" "}
                    {isNotAdmin ? "" : "(administrateur)"}
                  </Typography>
                </Box>
              </ListSubheader>
            }
          >
            <Divider />
            {
              isNotAdmin && (
                <ListItemButton onClick={() => handleNavigate("./abonnement")}>
                  <ListItemIcon>
                    <WebIcon />
                  </ListItemIcon>
                  <ListItemText primary="Gestion d'abonnement" />
                </ListItemButton>
              )
            }
          </List>
          <Box sx={{ p: 2 }}>
            <IconButton onClick={handleLogout}>
              <LogoutIcon />
              <Typography ml={1}>Se déconnecter</Typography>
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </div>
  )
}
