import {
  Avatar,
  Box,
  Typography,
  useTheme,
  Grid,
  Card,
  Alert
} from "@mui/material"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle"
import { useApplicationContext } from "@/hooks"
import { AbonnementTable } from "./AbonnementTable"
import { useEffect, useState } from "react"
import { BaseTable, QueryResult } from "@/components"
import { useQuery } from "@apollo/client"
import {
  ABONNEMENT,
  BUSINESS_SUBSCRIPTION,
  LIST_BILLING
} from "@/graphql/abonnement"

export const AbonnementInfo = () => {
  const theme = useTheme()
  const { business } = useApplicationContext()
  const { columns } = AbonnementTable()
  const [page] = useState<number>(1)
  const [alertMsg, setAlertMsg] = useState(false)
  const { data, loading } = useQuery(BUSINESS_SUBSCRIPTION, {
    variables: {
      businessId: business?.id
    },
    skip: !business
  })
  const { data: dataBilling, loading: loadingBilling } = useQuery(
    LIST_BILLING,
    {
      variables: {
        businessId: business?.id
      },
      skip: !business
    }
  )
  const { data: dataBusinessSub } = useQuery(BUSINESS_SUBSCRIPTION, {
    variables: {
      businessId: business?.id
    },
    skip: !business
  })
  const { data: dataSubscription, loading: loadingSubscripotion } = useQuery(
    ABONNEMENT,
    {
      variables: {
        abonnementId: dataBusinessSub?.getSubscriptionBusiness.abonnementId
      },
      skip: !dataBusinessSub?.getSubscriptionBusiness.abonnementId
    }
  )
  const calcCount = (current: number, total: number): boolean => {
    const alertLimit = (total * 80) / 100

    if (current >= alertLimit) return true

    return false
  }

  useEffect(() => {
    setAlertMsg(
      calcCount(
        data?.getSubscriptionBusiness.bookingCount,
        dataSubscription?.oneAbonnement.numberBooking
      )
    )
    setAlertMsg(
      calcCount(
        data?.getSubscriptionBusiness.subscribeCount,
        dataSubscription?.oneAbonnement.numberSubscription
      )
    )
    setAlertMsg(
      calcCount(
        data?.getSubscriptionBusiness.clickCount,
        dataSubscription?.oneAbonnement.numberClick
      )
    )
  }, [
    data?.getSubscriptionBusiness.bookingCount,
    data?.getSubscriptionBusiness.clickCount,
    data?.getSubscriptionBusiness.subscribeCount,
    dataSubscription?.oneAbonnement.numberBooking,
    dataSubscription?.oneAbonnement.numberClick,
    dataSubscription?.oneAbonnement.numberSubscription
  ])

  return (
    <Box p={2}>
      <Box sx={{ textAlign: "center" }}>
        <QueryResult data={data} loading={loading}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2 }} elevation={3}>
                <Avatar
                  sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
                >
                  <GroupAddIcon />
                </Avatar>
                <Typography variant="h2">
                  {loadingSubscripotion
                    ? "- / -"
                    : `${data?.getSubscriptionBusiness.bookingCount} / ${dataSubscription?.oneAbonnement.numberBooking}`}
                </Typography>
                <Typography>Nombre de réservation</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2 }} elevation={3}>
                <Avatar
                  sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
                >
                  <SupervisedUserCircleIcon />
                </Avatar>
                <Typography variant="h2">
                  {loadingSubscripotion
                    ? "- / -"
                    : `${data?.getSubscriptionBusiness.subscribeCount} / ${dataSubscription?.oneAbonnement.numberSubscription}`}
                </Typography>
                <Typography>Nombre de clients incrits</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2 }} elevation={3}>
                <Avatar
                  sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
                >
                  <SupervisedUserCircleIcon />
                </Avatar>
                <Typography variant="h2">
                  {loadingSubscripotion
                    ? "- / -"
                    : `${data?.getSubscriptionBusiness.clickCount} / ${dataSubscription?.oneAbonnement.numberClick}`}
                </Typography>
                <Typography>Nombre de click'n collect</Typography>
              </Card>
            </Grid>
          </Grid>
        </QueryResult>
      </Box>
      <Box
        sx={{
          my: 3
        }}
      >
        {alertMsg ? (
          <Alert severity="warning">
            Vous avez presque consommer votre quota du mois, pensez à upgrader
            votre abonnement!
          </Alert>
        ) : (
          ""
        )}
      </Box>
      <Box>
        <Typography
          variant="h5"
          component="div"
          sx={{
            my: 4
          }}
        >
          Résumé de vos facturations :
        </Typography>
      </Box>
      <Box>
        <QueryResult data={dataBilling} loading={loadingBilling}>
          <BaseTable
            indexed="id"
            title="Liste des factures"
            columns={columns}
            data={dataBilling?.listBilling || []}
            page={page - 1}
          />
        </QueryResult>
      </Box>
    </Box>
  )
}
