/* eslint-disable react/no-unescaped-entities */
import { useParams } from "react-router-dom"
import { Typography, Box, Grid, Card, Button } from "@mui/material"
import { useMutation } from "@apollo/client"
import { useState } from "react"
import { BusinessUpdateInput } from "@/types"
import Swal from "sweetalert2"
import { useApplicationContext, useCurrentBusiness } from "../../../hooks"
import { CustomTextInput } from "../../forms/CustomTextInput"
import {
  UpdateBusinessInfo,
  UpdateBusinessInfoVariables,
  UPDATE_BUSINESS_INFO
} from "../../../graphql/business"

export const EtablissementSiteAndSocial = (): JSX.Element => {
  const { id } = useParams()
  const { business } = useApplicationContext()
  const { data, refetch } = useCurrentBusiness(
    business?.id || parseFloat(id as string)
  )

  const [formValue, setFormValue] = useState<BusinessUpdateInput>({
    facebook: data?.facebook,
    siteweb: data?.siteweb,
    instagram: data?.instagram,
    twitter: data?.twitter,
    linkedin: data?.linkedin,
    confidentiality: data?.confidentiality,
    googleBusiness: data?.googleBusiness,
    mentions: data?.mentions,
    conditions: data?.conditions
  })

  const [updateInfo] = useMutation<
    UpdateBusinessInfo,
    UpdateBusinessInfoVariables
  >(UPDATE_BUSINESS_INFO)

  const handleSaveInfo = async (): Promise<void> => {
    if (!isValide()) {
      Swal.fire({
        title: "Les champs ne doivent contenir que des URLs",
        icon: "error"
      })
      return
    }
    try {
      await updateInfo({
        variables: {
          businessId: business?.id || parseFloat(id as string),
          business: formValue
        }
      })
      Swal.fire({
        icon: "success",
        title: "Modification terminée",
        text: ""
      })
      refetch()
      return undefined
    } catch (errors) {
      Swal.fire({
        icon: "error",
        title: "La modification a échouée",
        text: ""
      })
      return undefined
    }
  }

  const handleSetState = (value: string, name: string) => {
    setFormValue({
      ...formValue,
      [name]: value
    })
  }

  const testUrl = (value: string | null | undefined) => {
    if (!value) return true
    const regex =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    const r = new RegExp(regex)
    return r.test(value)
  }

  const isValide = (): boolean => {
    const {
      siteweb,
      mentions,
      facebook,
      twitter,
      confidentiality,
      conditions,
      linkedin,
      instagram,
      googleBusiness
    } = formValue
    return (
      testUrl(siteweb) &&
      testUrl(mentions) &&
      testUrl(facebook) &&
      testUrl(twitter) &&
      testUrl(confidentiality) &&
      testUrl(conditions) &&
      testUrl(linkedin) &&
      testUrl(instagram) &&
      testUrl(googleBusiness)
    )
  }
  /****custom responsive styles */
  const smJustifyCenter = {
    "@media(max-width:767px)" : {
      justifyContent : "center"
    }
  }




  /*****end style */
  return (
    <Box sx={{ py: 2 }}>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", paddingBottom: 2 , ...smJustifyCenter }}
      >
        <Button
          disabled={!isValide()}
          variant="contained"
          onClick={handleSaveInfo}
          sx={{ color: "#050005" }}
        >
          Enregistrer les modifications
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <Card sx={{ p: 1, px: 2 }} elevation={1}>
            <Typography variant="subtitle1">Site web</Typography>
            <CustomTextInput
              editable
              nodivider
              isUrl
              defaultValue={data?.siteweb || ""}
              name="siteweb"
              onSave={handleSetState}
              error={!testUrl(formValue.siteweb)}
              helperText={!testUrl(formValue.siteweb) && "entrer une URL valide"}
            />
          </Card>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Card sx={{ p: 1, px: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1">Lien mentions légales</Typography>
              <CustomTextInput
                nodivider
                isUrl
                name="mentions"
                onSave={handleSetState}
                defaultValue={data?.mentions || ""}
                error={!testUrl(formValue.mentions)}
                helperText={
                  !testUrl(formValue.mentions) && "entrer une URL valide"
                }
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Card sx={{ p: 1, px: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1">
                Lien Conditions générale
              </Typography>
              <CustomTextInput
                nodivider
                isUrl
                name="conditions"
                onSave={handleSetState}
                defaultValue={data?.conditions || ""}
                error={!testUrl(formValue.conditions)}
                helperText={
                  !testUrl(formValue.conditions) && "entrer une URL valide"
                }
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Card sx={{ p: 1, px: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1">
                Lien Politique de confidentialité
              </Typography>
              <CustomTextInput
                nodivider
                isUrl
                name="confidentiality"
                onSave={handleSetState}
                defaultValue={data?.confidentiality || ""}
                error={!testUrl(formValue.confidentiality)}
                helperText={
                  !testUrl(formValue.confidentiality) && "entrer une URL valide"
                }
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Card sx={{ p: 1, px: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1">LinkedIn</Typography>
              <CustomTextInput
                nodivider
                isUrl
                name="linkedin"
                onSave={handleSetState}
                defaultValue={data?.linkedin || ""}
                error={!testUrl(formValue.linkedin)}
                helperText={
                  !testUrl(formValue.linkedin) && "entrer une URL valide"
                }
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Card sx={{ p: 1, px: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1">Facebook</Typography>
              <CustomTextInput
                nodivider
                isUrl
                name="facebook"
                onSave={handleSetState}
                defaultValue={data?.facebook || ""}
                error={!testUrl(formValue.facebook)}
                helperText={
                  !testUrl(formValue.facebook) && "entrer une URL valide"
                }
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Card sx={{ p: 1, px: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1">Google My Business</Typography>
              <CustomTextInput
                nodivider
                isUrl
                name="googleBusiness"
                onSave={handleSetState}
                defaultValue={data?.googleBusiness || ""}
                error={!testUrl(formValue.googleBusiness)}
                helperText={
                  !testUrl(formValue.googleBusiness) && "entrer une URL valide"
                }
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Card sx={{ p: 1, px: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1">Instagram</Typography>
              <CustomTextInput
                nodivider
                isUrl
                name="instagram"
                onSave={handleSetState}
                defaultValue={data?.instagram || ""}
                error={!testUrl(formValue.instagram)}
                helperText={
                  !testUrl(formValue.instagram) && "entrer une URL valide"
                }
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Card sx={{ p: 1, px: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1">Twitter</Typography>
              <CustomTextInput
                nodivider
                isUrl
                name="twitter"
                onSave={handleSetState}
                defaultValue={data?.twitter || ""}
                error={!testUrl(formValue.twitter)}
                helperText={
                  !testUrl(formValue.twitter) && "entrer une URL valide"
                }
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
