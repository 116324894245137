import { BaseTable, ResearchBox } from "@/components"
import { TableAbonnement } from "./TableAbonnement"
import { useState } from "react"
import { Box } from "@mui/material"
import useSimpleDebounce from "@/hooks/useSimpleDebounce"
import { BUSINESS_LIST, BusinessList, BusinessListVariables, BusinessList_businessList } from "@/graphql"
import { useQuery } from "@apollo/client"

export const ListeFacturation = () => {
  const [searchValue, setSearchValue] = useState("")
  const handleSearch = (value: string) => {
    setSearchValue(value)
  }
  const { columns } = TableAbonnement()
  const searchDebounced = useSimpleDebounce(searchValue, 500)
  const { data, loading } = useQuery<
    BusinessList,
    BusinessListVariables
  >(BUSINESS_LIST, {
    variables: {
      take: 100,
      skip: 0
    }
  })
  const filterBusiness = (b: BusinessList_businessList) => {
    if (searchDebounced) {
      return b.businessName.includes(searchDebounced)
    }
    return true
  }

  return (
    <BaseTable
      loading={loading}
      columns={columns}
      data={data?.businessList.filter(filterBusiness) || []}
      topComponent={
        <Box sx={{ p: 2, display: "flex", justifyContent: "end" }}>
          <Box sx={{ mr: 2 }}>
            <ResearchBox
              placeholder="Rechercher rapide..."
              onChange={handleSearch}
              clear={() => setSearchValue("")}
            />
          </Box>
        </Box>
      }
      page={0}
    />
  )
}
