import { gql } from "@apollo/client"

export const UPLOAD = gql`
  mutation UploadFile($data: UploadInput!) {
    uploadFile(data: $data)
  }
`
export const ADD_FILE = gql`
  mutation AddFile($businessId: Float!, $files: FileInput!) {
    addFile(businessId: $businessId, files: $files) {
      id
      url
    }
  }
`

export const DL_IMG = gql`
  query DownloadFile($url: String!) {
    downloadFile(url: $url)
  }
`

export * from "./types/UploadFile"
export * from "./types/AddFile"
