import { gql } from "@apollo/client"

export const CREATE_EVENT = gql`
  mutation CreateEventParty($data: EventPartyType!) {
    createEventParty(data: $data)
  }
`

export const UPDATE_EVENT = gql`
  mutation UpdateEventParty(
    $data: EventPartyType!
    $updateEventPartyId: Float!
  ) {
    updateEventParty(data: $data, id: $updateEventPartyId)
  }
`

export const DELETE_EVENT = gql`
  mutation DeleteEventParty($deleteEventPartyId: Float!) {
    deleteEventParty(id: $deleteEventPartyId)
  }
`
