import { Navigate } from "react-router-dom"
import { useApplicationContext } from "../../hooks"

export const NotFound = (): JSX.Element => {
  const { user } = useApplicationContext()

  if (user) {
    return <Navigate to="/discount" />
  }
  return <Navigate to="/login" />
}
