import { ButtonLittleIcon } from "@/components/Button"
import { Cancel, Edit } from "@mui/icons-material"
import { Button, Card, CardProps, styled, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { FC, useEffect, useState } from "react"

interface BoxCardProps extends CardProps {
  color: string
}

const BoxCard = styled(Card, {
  shouldForwardProp: (props) => props !== "color"
})<BoxCardProps>(({ color }) => ({
  background: `${color}`,
  borderRadius: 15
}))

const ButtonIcon = styled(ButtonLittleIcon)(() => ({
  position: "absolute",
  top: 10,
  right: 10
}))

interface BackgroundColorProps {
  color?: string
  onSave: (color: string) => void
}

export const ColorBackground: FC<BackgroundColorProps> = ({
  color,
  onSave
}) => {
  const [bgColor, setBgColor] = useState<string>("transparent")
  const [edit, setEdit] = useState<boolean>(false)

  useEffect(() => {
    setBgColor(color || "transparent")
  }, [color])

  const disabled = bgColor === color
  const mobilFullWidth = {
      
    "@media(max-width:663px)" : {
      width: "100%",
      minWidth: "100%"
    }
  }
  return (
    <Box sx={{ mx: 1, position: "relative" }}>
      <BoxCard
        sx={{
          width: "100%",
          maxWidth: 200,
          height: 350,
          py: 1,
          position: "relative",
          ...mobilFullWidth
        }}
        color={bgColor}
      >
        <ButtonIcon icon={<Edit />} onClick={() => setEdit(!edit)} />
      </BoxCard>
      {edit ? (
        <Card sx={{ p: 2, mt: 2, background: "#ccc", position: "relative" }}>
          <Box>
            <ButtonIcon
              color="error"
              icon={<Cancel />}
              onClick={() => setEdit(!edit)}
            />
          </Box>
          <Typography variant="subtitle1">Paramètre de couleur :</Typography>
          <Typography variant="subtitle2">Couleur de fond :</Typography>
          <input
            type="color"
            value={bgColor}
            onChange={(e) => setBgColor(e.target.value)}
          />
          <Box sx={{ py: 1 }}>
            <Button
              disabled={disabled}
              variant="contained"
              sx={{ textTransform: "none" , color: "#050005" }}
              onClick={() => {
                onSave(bgColor)
                setEdit(false)
              }}
            >
              Enregistrer les modifications
            </Button>
          </Box>
        </Card>
      ) : null}
    </Box>
  )
}
