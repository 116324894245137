import { useApplicationContext } from "@/hooks"
import { BackgroundType, PaletteInput, ThemeInput } from "@/types"
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  styled
} from "@mui/material"
import { FC, ReactNode } from "react"
import { Profil } from "../../CustomPage"
import { FooterCommercant } from "../FooterCommercant"
import { TypographyUpdated } from "../TypographyUpdated"
import ReactPlayer from "react-player"
import "./styles.css"

interface LogoCommercantProps {
  logo: string
  name?: string
}
interface ContainerWithFooterProps {
  profil: Profil
  theme: ThemeInput
  palette: PaletteInput
  children: ReactNode
  sx?: any
}
export const LogoCommercant: FC<LogoCommercantProps> = ({
  logo,
  name
}): JSX.Element => {
  const StyledBox = styled(Box)(() => ({
    backgroundColor: "transparent",
    height: 100,
    width: 100,
    borderRadius: 50,
    overflow: "hidden",
    margin: "auto",
    position: "relative",
    top: -55,
    borderWidth: 7,
    borderColor: "transparent",
    borderStyle: "solid"
  }))
  return (
    <StyledBox>
      <Avatar src={logo} sx={{ width: 85, height: 85 }}>
        {name?.charAt(0)}
      </Avatar>
    </StyledBox>
  )
}

export const ContainerWithFooter: FC<ContainerWithFooterProps> = ({
  profil,
  theme,
  palette,
  children,
  sx
}) => {
  const { business } = useApplicationContext()

  const updateBackground = () => {
    if (!palette) return
    if (palette.bgType === BackgroundType.COLOR_LINEAR) {
      return `linear-gradient(${palette.bgColor1}, ${palette.bgColor2})`
    } else if (palette.bgType === BackgroundType.COLOR_SINGLE) {
      return `${palette.bgColor}`
    } else if (palette.bgType === BackgroundType.IMAGE) {
      return `url(${palette.bgImage})`
    }
  }
  return (
    <Box
      sx={{
        ...sx,
        background: updateBackground(),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%"
      }}
    >
      <Card
        elevation={0}
        sx={{
          borderRadius: 0,
          backgroundColor: "transparent"
        }}
      >
        {!profil.animated ? (
          <CardMedia
            component="img"
            height="140"
            image={profil?.coverPhoto as string}
            alt="green iguana"
            sx={{ borderRadius: "0px 0px 40px 40px" }}
          />
        ) : (
          <Box
            sx={{
              height: "200px",
              width: "auto",
              borderRadius: "0 0 40px 40px",
              overflow: "hidden",
              position: "relative"
            }}
          >
            <ReactPlayer
              url={profil?.coverVidz}
              playing
			  loop
              muted
              height="100%"
			  width="100%"
			  className="react-player"
            />
          </Box>
        )}
        <Box sx={{ height: 48 }}>
          <LogoCommercant
            logo={profil?.logo || ""}
            name={business?.businessName}
          />
        </Box>
        <CardContent sx={{ p: 0 }}>
          <TypographyUpdated
            sx={{
              textAlign: "center",
              mb: 2
            }}
            variant="h2"
            theme={theme}
          >
            {business?.businessName}
          </TypographyUpdated>
          <TypographyUpdated
            theme={theme}
            variant="body2"
            sx={{ textAlign: "center" }}
          >
            {business?.activity}
          </TypographyUpdated>
          <TypographyUpdated
            theme={theme}
            variant="body2"
            sx={{ textAlign: "center" }}
          >
            {business?.address}
          </TypographyUpdated>
        </CardContent>
      </Card>
      <Container>
        {children}
        {business && <FooterCommercant theme={theme} business={business} />}
      </Container>
    </Box>
  )
}
