import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@mui/material"
import { SpinnerTemplate } from "@/components/common/SpinnerTemplate"
import { TemplateInput } from "@/types"
import PhoneInput from "react-phone-input-2"
import { withStyles } from "@mui/styles"

type cardSpinnerProps = TemplateInput & {
  logoUrl?: string
  children?: JSX.Element
  countryCode?: string
}

export const CardSpinner = ({
  bgImage,
  bgColor,
  textColor,
  fontFamily,
  name,
  description,
  spinItemMainColor,
  subtitle,
  fieldEmailPlaceholder,
  fieldCondition,
  fieldPrivacy,
  conditionText,
  privacyText,
  fieldPhonePlaceholder,
  fieldPhone,
  fieldNamePlaceholder,
  fieldName,
  btnBg,
  btnText,
  btnTextColor,
  thankText,
  logoUrl,
  children,
  countryCode
}: cardSpinnerProps) => {
  const CustomColorCheckbox = withStyles({
    root: {
      color: `${textColor}`,
      "&$checked": {
        color: `${textColor}`
      }
    },
    checked: {}
  })((props) => (
    <Checkbox color="default" sx={{ color: `${textColor}` }} {...props} />
  ))

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "10px",
        background: `${bgImage}` ? `url(${bgImage})` : `${bgColor}`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        maxWidth: 376,
        margin: "auto"
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ px: 3, textAlign: "center", pt: 2 }}>
          <Avatar
            src={logoUrl}
            style={{ width: 80, height: 80, margin: "auto" }}
          />
        </Box>
        <Box sx={{ my: 4 }}>
          <Typography
            variant="h4"
            sx={{
              color: `${textColor}`,
              fontFamily: `${fontFamily}`
            }}
          >
            {name}
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography
            sx={{
              color: `${textColor}`,
              fontFamily: `${fontFamily}`
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {!children ? (
            <SpinnerTemplate mainColor={spinItemMainColor as string} />
          ) : (
            children
          )}
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography
            sx={{
              color: `${textColor}`,
              fontFamily: `${fontFamily}`
            }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            variant="outlined"
            placeholder={fieldEmailPlaceholder || ""}
            sx={{ backgroundColor: "#ffffff", borderRadius: 1, width: "250px" }}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          {fieldPhone ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <PhoneInput
                enableSearch
                containerStyle={{ width: "auto", display: "flex" }}
                specialLabel=""
                country={countryCode || "fr"}
                value={fieldPhonePlaceholder}
                preferredCountries={["fr", "us"]}
                inputStyle={{
                  backgroundColor: "#ffffff",
                  borderRadius: 1,
                  width: "250px",
                  height: "40px"
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Box sx={{ my: 2 }}>
          {fieldName ? (
            <TextField
              variant="outlined"
              placeholder={fieldNamePlaceholder || ""}
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: 1,
                width: "250px"
              }}
            />
          ) : (
            ""
          )}
        </Box>
        <Box>
          {fieldPrivacy ? (
            <Grid container item alignItems="center" justifyContent="center">
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start"
                }}
              >
                <Grid>
                  <FormControlLabel
                    control={<CustomColorCheckbox />}
                    label=""
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{
                      color: `${textColor}`,
                      fontFamily: `${fontFamily}`,
                      textAlign: "left"
                    }}
                  >
                    {privacyText}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          ) : (
            ""
          )}

          {fieldCondition ? (
            <Grid container alignItems="center" justifyContent="center">
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start"
                }}
              >
                <Grid>
                  <FormControlLabel
                    control={<CustomColorCheckbox />}
                    label=""
                  />
                </Grid>
                <Grid>
                  <Typography
                    sx={{
                      color: `${textColor}`,
                      fontFamily: `${fontFamily}`,
                      textAlign: "left"
                    }}
                  >
                    {conditionText}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          ) : (
            ""
          )}

          <Button
            variant="contained"
            sx={{
              background: `${btnBg}`,
              color: `${btnTextColor}`,
              fontFamily: `${fontFamily}`
            }}
          >
            {btnText}
          </Button>
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography
            sx={{
              color: `${textColor}`,
              fontFamily: `${fontFamily}`
            }}
          >
            {thankText}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
