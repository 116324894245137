import { Cancel } from "@mui/icons-material"
import Edit from "@mui/icons-material/Edit"
import { Box, Grid, IconButton, styled, Typography } from "@mui/material"
import { useState } from "react"
import { TextInputBase } from ".."
import { CSVReader } from "../CSVReader"

const Textarea = styled(TextInputBase)(() => ({
  "& textarea": {
    minHeight: 150
  }
}))
export const EmailForm = (): JSX.Element => {
  return (
    <Box>
      <Typography>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad nesciunt
        nostrum veniam ipsum, deleniti tempora? Reiciendis obcaecati ratione
        libero tempora eius ullam commodi. Dignissimos, inventore beatae dolor
        quidem porro corporis.
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <TextInputBase label="Titre de l'email" />
          <TextInputBase label="Titre de l'email" />
          <TextInputBase label="Titre de l'email" />
          <TextInputBase label="Titre de l'email" />
          <Box sx={{ textAlign: "center" }}>
            <Typography>
              Importer un fichier (.csv) contenant une liste d&#39;email de vos
              cibles
            </Typography>
            <CSVReader
              onChange={(d) => {
                // console.log(d)
              }}
            />
            <Typography>ou</Typography>
            <Typography>
              Ajouter des emails manuellement (séparer par un virgule (,))
            </Typography>
            <Box>
              <TextInputBase
                multiline
                label="Emails (séparer par un virgule (,))"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <TextInputBase label="Titre de l'email" />
          <TextInputBase label="Titre de l'email" />
          <Textarea multiline label="Titre de l'email" />
        </Grid>
      </Grid>
    </Box>
  )
}
