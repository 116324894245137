/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import { Outlet, Route, Routes, useNavigate } from "react-router-dom"
import { MenuItem, Select, SelectChangeEvent } from "@mui/material"

function a11yProps(index: number): { id: string; "aria-controls": string } {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window

  return { innerWidth, innerHeight }
}

export interface TabsType {
  id: string
  label: string
  component: JSX.Element
  index?: boolean
  path?: string
}
export interface TabsWithRoutesProps {
  tabs: TabsType[]
  pathBase?: string
}
export const TabsWithRoutes: React.FC<TabsWithRoutesProps> = ({
  tabs,
  pathBase
}): JSX.Element => {
  const [windowSize, setWindowSize] = React.useState(getWindowSize())
  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setValue(newValue)
  }
  const handleNavigate = (path?: string): void => {
    const pathUri = pathBase ? `${pathBase}/${path || ""}` : `${path || ""}`
    navigate(pathUri)
  }
  const handleSelect = (event: SelectChangeEvent) => {
    const currentPath = tabs.find((elem) => elem.id === event.target.value)
    const pathUri = pathBase
      ? `${pathBase}/${currentPath?.path || ""}`
      : `${currentPath?.path || ""}`
    navigate(pathUri)
  }
  const handleWindowResize = () => {
    setWindowSize(getWindowSize())
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  })

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Box
          sx={{
            display: {
              md: "none"
            }
          }}
        >
          <Select
            sx={{ width: "100%" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={tabs[0].id}
            onChange={handleSelect}
          >
            {tabs.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {windowSize.innerWidth >= 900 ? (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabs.map(({ id, label, path }, index) => (
              <Tab
                key={id}
                label={label}
                {...a11yProps(index)}
                onClick={() => handleNavigate(path)}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold"
                }}
              />
            ))}
          </Tabs>
        ) : (
          ""
        )}
      </Box>
      <Box>
        <Routes>
          {tabs.map(({ id, index, path, component }) => {
            return (
              <Route key={id} index={index} path={path} element={component} />
            )
          })}
        </Routes>
        <Outlet />
      </Box>
    </Box>
  )
}
