/* eslint-disable no-nested-ternary */
import { useMutation } from "@apollo/client"
import { Box, TextField, Typography } from "@mui/material"
import { FC, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
  UpdateBusinessInfo,
  UpdateBusinessInfoVariables,
  UPDATE_BUSINESS_INFO
} from "../../../../graphql/business"
import { storage } from "../../../../utils"
import { FormContainer } from "../components/FormContainer"

interface SignupStepProps {
  afterSave?: (isFood: boolean) => void
}

export const BusinessDescription: FC<SignupStepProps> = ({
  afterSave
}): JSX.Element => {
  const [updateInfo, { loading }] = useMutation<
    UpdateBusinessInfo,
    UpdateBusinessInfoVariables
  >(UPDATE_BUSINESS_INFO)
  const { id } = useParams()
  const [description, setDescription] = useState("")
  const [isFoodServices, setIsFoodServices] = useState(false)
  const [newId, setNewId] = useState<number>()

  const navigate = useNavigate()

  const handleCreate = async (): Promise<void> => {
    try {
      const create = await updateInfo({
        variables: {
          businessId: parseFloat(id as string) || (newId as number),
          business: {
            description
          }
        }
      })
      if (create.data?.updateBusinessInfo.id) {
        if (afterSave) {
          afterSave(false)
        } else {
          navigate(`/signup/logo/${create.data?.updateBusinessInfo.id}`)
        }
      }
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const handleIgnore = (): void => {
    navigate(`/signup/logo/${id}`)
  }
  const loadLocal = useCallback(() => {
    const nid = storage.get("new_Id")
    setNewId(parseFloat(nid))
    const infos = storage.get("step_2_tag")
    if (infos?.activityType === "FOOD_SERVICES") {
      setIsFoodServices(true)
    }
  }, [])
  useEffect(() => {
    loadLocal()
  }, [loadLocal])
  return (
    <FormContainer
      step={5}
      loading={loading}
      title="Ajouter la description de l'établissement"
      onNext={handleCreate}
      onIgnore={handleIgnore}
    >
      <Box sx={{ py: 2 }}>
        <Typography>Ajouter la description de l&#39;établissement</Typography>
        <Box sx={{ py: 1 }}>
          <TextField
            fullWidth
            multiline
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
      </Box>
    </FormContainer>
  )
}
