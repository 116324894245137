import { useApplicationContext } from "@/hooks"
import { Beenhere } from "@mui/icons-material"
import {
  Box,
  CardContent,
  Typography,
  Card,
  Grid,
  Avatar,
  Button,
  CircularProgress
} from "@mui/material"
import { Payement } from "../authentication/Signup/page/payement"
import { QueryResult } from "@/components"
import {
  ListAbonnement_listAbonnement,
  LIST_ABONNEMENT
} from "@/graphql/abonnement"
import { useMutation, useQuery } from "@apollo/client"
import Logo from "@/assets/logo_bg.png"
import { CREATE_CLIENT_SECRET } from "@/graphql/business"

export const Payment = () => {
  const { business, logout } = useApplicationContext()
  const { data, loading } = useQuery(LIST_ABONNEMENT)
  const [createClientSecret, { data: dataSecret, loading: loadingSecret }] = useMutation(CREATE_CLIENT_SECRET)

  return (
    <Box sx={{ pt: 5, height: "100vh", background: "rgb(248, 248, 248)" }}>
      <Box
        sx={{
          p: 3,
          textAlign: "center",
          pt: 2,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Avatar
          src={Logo}
          style={{
            width: 200,
            height: 125,
            margin: "auto",
            padding: 10
          }}
          variant="rounded"
        />
      </Box>
      {
        loadingSecret && (
          <Box sx={{
            display: "flex", justifyContent: "center", alignItems: "center",
            height: "60vh"
          }}>
            <CircularProgress />
          </Box>
        )
      }

      {!dataSecret && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box sx={{ width: "80vw" }}>
              <QueryResult loading={loading} data={data}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ mb: 3 }}
                >
                  {data?.listAbonnement.map(
                    (item: ListAbonnement_listAbonnement) => {
                      if (item.status)
                        return (
                          <Grid key={item.id} item md={4}>
                            <Card sx={{ borderRadius: 8 }}>
                              <CardContent>
                                <Box textAlign="center" sx={{ mb: 2 }}>
                                  <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Box>
                                <Typography variant="body2">
                                  {item.description}
                                </Typography>
                                <Box my={3}>
                                  {item.numberBooking ? (
                                    <Typography
                                      component="div"
                                      variant="body2"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 1
                                      }}
                                    >
                                      <Beenhere sx={{ mr: 1 }} />{" "}
                                      {item.numberBooking} réservations
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  {item.numberClick ? (
                                    <Typography
                                      component="div"
                                      variant="body2"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 1
                                      }}
                                    >
                                      <Beenhere sx={{ mr: 1 }} />{" "}
                                      {item.numberClick} click'n collect
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  {item.numberSubscription ? (
                                    <Typography
                                      component="div"
                                      variant="body2"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 1
                                      }}
                                    >
                                      <Beenhere sx={{ mr: 1 }} />{" "}
                                      {item.numberSubscription} inscriptions
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  {item.prixEmail ? (
                                    <Typography
                                      component="div"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 1
                                      }}
                                    >
                                      <Beenhere sx={{ mr: 1 }} /> le prix des
                                      emails {item.prixEmail} €
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  {item.prixSms ? (
                                    <Typography
                                      component="div"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 1
                                      }}
                                    >
                                      <Beenhere sx={{ mr: 1 }} /> le prix des
                                      SMS {item.prixSms} €
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  {item.freeTrial ? (
                                    <Typography
                                      component="div"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 1
                                      }}
                                    >
                                      <Beenhere sx={{ mr: 1 }} />{" "}
                                      {item.percentOff}% de réduction pendant{" "}
                                      {item.freeTrial} mois
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                                <Typography
                                  textAlign="center"
                                  variant="h1"
                                  component="div"
                                  sx={{ fontWeight: "bold", mb: 4 }}
                                >
                                  {item.prixAbonnement} €
                                  <Typography component="sup">
                                    {" "}
                                    HT/mois
                                  </Typography>
                                </Typography>
                                <Box textAlign="center">
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      createClientSecret({
                                        variables: {
                                          businessId: business?.id,
                                          amount: item.priceStripeId
                                        }
                                      })
                                    }}
                                  > Je m'abonne</Button>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        )
                      return ""
                    }
                  )}
                </Grid>
              </QueryResult>
            </Box>
          </Box>
        </>
      )}

      {dataSecret && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Payement client_secret={dataSecret?.createClintSecret} />
        </Box>
      )}

      < Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" onClick={() => logout()}>Retour</Button>
      </Box>
    </Box >
  )
}
