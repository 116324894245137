/* eslint-disable no-underscore-dangle */
import { useMutation } from "@apollo/client"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { TokenContext } from "../../App"
import {
  ApplicationContext,
  ISnackContext,
  SnackbarContext
} from "../../contexts"
import { IApplicationContext } from "../../contexts/application/Interfaces"
import {
  Login,
  LoginVariables,
  SIGNIN,
  UPLOAD,
  UploadFile,
  UploadFileVariables
} from "../../graphql"
import { IRequest, UploadInput } from "../../types"
import { UserAuthStateEnum } from "../../types/UserType"
import { AuthStorage } from "../../utils"
import { LocalStorage } from "../../utils/AuthStorage"
import { UseApplicationContextType } from "./interfaces"

export const useApplicationContext = (): UseApplicationContextType &
  IApplicationContext &
  ISnackContext => {
  const { setToken } = useContext(TokenContext)
  const contexts = useContext(ApplicationContext)
  const snackbarContexts = useContext(SnackbarContext)
  const navigate = useNavigate()

  const [userAuthStatus, setUserAuthStatus] = useState<UserAuthStateEnum>(
    UserAuthStateEnum.WAITING
  )
  const { user, setUser } = contexts
  const loadUser = useCallback((): void => {
    if (user) {
      setUserAuthStatus(UserAuthStateEnum.AUTHENTICATED)
    }
    if (!user) {
      const checkuser = LocalStorage.isAuth()
      if (checkuser) {
        setUserAuthStatus(UserAuthStateEnum.AUTHENTICATED)
        setUser(checkuser)
      } else {
        setUserAuthStatus(UserAuthStateEnum.UNAUTHENTICATED)
      }
    }
  }, [setUser, user])

  useEffect(() => {
    loadUser()
  }, [loadUser])

  const logout = (): void => {
    AuthStorage.clearToken(() => {
      setUser(undefined)
      setToken(undefined)
      navigate("/login")
      window.location.reload()
    })
  }

  const [uploadExec, { loading: uploadLoading, error: uploadError }] =
    useMutation<UploadFile, UploadFileVariables>(UPLOAD)

  /**
   *
   * @param file
   * @returns
   * @deprecated Import from useFileUploader instead : ``` import { useFileUploader } from "../application" ```
   * @deprecated use like ``` const { uploadFile } = useFileUploader()```
   */
  const uploadFile = async (file: UploadInput): Promise<string | undefined> => {
    try {
      const fileData = await uploadExec({
        variables: {
          data: {
            ...file
          }
        }
      })
      if (fileData.data?.uploadFile) {
        return fileData.data.uploadFile
      }
      return undefined
    } catch (error) {
      snackbarContexts.dispatchSnack({
        open: true,
        message: "Erreur d'enregistrement de fichier !",
        severity: "error"
      })
      return undefined
    }
  }

  const [sign, { loading: signinLoading, error: signinError }] = useMutation<
    Login,
    LoginVariables
  >(SIGNIN)
  const signin = async (email: string, password: string): Promise<IRequest> => {
    try {
      const res = await sign({
        variables: {
          email,
          password
        }
      })
      const users = res.data?.login
      if (users?.id) {
        LocalStorage.authenticate(users)
        setUser(users)

        return { success: "ok" }
      }
      return { error: "error" }
    } catch (error) {
      return { error: "error" }
    }
  }
  return {
    user,
    userAuthStatus,
    logout,
    uploadError,
    uploadLoading,
    uploadFile,
    signinLoading,
    signinError: signinError?.message,
    signin,
    ...snackbarContexts,
    ...contexts
  }
}
