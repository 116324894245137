/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
import { Box, Typography, styled, Button } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import Add from "@mui/icons-material/Add"
import { ApolloQueryResult, useQuery } from "@apollo/client"
import { useApplicationContext } from "@/hooks"
import {
  BusinessProductById_businessProductById as IProduct,
} from "@/graphql/business"
import {
  PRODUCT_BY_BUSINESS,
  PRODUCT_BUSINESS_CATEGORY,
  PRODUCT_BUSINESS_SUBCATEGORY
} from "@/graphql/business"
import { BaseTable, ConfirmModal, ResearchBox } from "@/components"
import { useDeleteProduct } from "@/hooks/category"
import { QueryResult } from "@/components/common"
import { TableProduits } from "./components/Produits/TableProduits/TableProduits"
import useSimpleDebounce from "@/hooks/useSimpleDebounce"

type ProductProps = {
  categoryProps?: string
}

export const Produits = ({ categoryProps }: ProductProps) => {
  const { dispatchSnack, business } = useApplicationContext()
  const navigate = useNavigate()
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const { deleteProduct } = useDeleteProduct()
  const [toDeleteId, setToDeleteId] = useState<number>()
  const [page] = useState<number>(1)
  const { idCategory, idSubCategory } = useParams()
  const parseIdCat = idCategory ? parseFloat(idCategory) : null
  const parseIdSubCat = idSubCategory ? parseFloat(idSubCategory) : null
  const {
    data: dataCategory,
    refetch: refetchCategory,
    loading: loadingCategory
  } = useQuery(PRODUCT_BUSINESS_CATEGORY, {
    variables: {
      categoryId: parseIdCat,
      businessId: business?.id
    },
    skip: !parseIdCat
  })
  const {
    data: dataSubCat,
    refetch: refetchSubCat,
    loading: loadingSubCat
  } = useQuery(PRODUCT_BUSINESS_SUBCATEGORY, {
    variables: {
      sousCategoryId: parseIdSubCat,
      businessId: business?.id
    },
    skip: !parseIdSubCat
  })
  const { data, refetch, loading } = useQuery(PRODUCT_BY_BUSINESS, {
    variables: {
      businessId: business?.id
    },
    skip: !business
  })
  let dataObj = {}
  let dataList = []
  let refetchList: () => Promise<ApolloQueryResult<unknown>>
  let loadingList = false

  switch (categoryProps) {
    case "category":
      dataObj = dataCategory
      dataList = dataCategory?.getProductBusinessCategory
      refetchList = () => refetchCategory()
      loadingList = loadingCategory
      break

    case "subCategory":
      dataObj = dataSubCat
      dataList = dataSubCat?.getProductBusinessSubCategory
      refetchList = () => refetchSubCat()
      loadingList = loadingSubCat
      break

    default:
      dataObj = data
      dataList = data?.productByBusiness
      refetchList = () => refetch()
      loadingList = loading
      break
  }

  const ButtonTop = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: 15,
    '@media(max-width:767px)' : {
      marginTop: 10,
      marginLeft: 0
    }
  }))

  const handleUpdateProduct = (idEditProduct: number) => {
    navigate(`${idEditProduct}`)
  }

  const handleDelete = (productId: number) => {
    setOpenDelete(true)
    setToDeleteId(productId)
  }

  const { columns } = TableProduits({
    onRemove: handleDelete,
    onEdit: handleUpdateProduct
  })

  const handleAdd = async () => {
    navigate("add-product")
  }

  const handleCancelDelete = () => {
    setToDeleteId(undefined)
    setOpenDelete(false)
  }

  const handleConfirmDelete = async () => {
    if (!toDeleteId) {
      setOpenDelete(false)
      return
    }
    const deleteProducts = await deleteProduct(toDeleteId)
    if (deleteProducts) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "suppression avec succès"
      })
    }
    refetchList()
    setToDeleteId(undefined)
    setOpenDelete(false)
  }

  const [searchValue, setSearchValue] = useState<string>()
  const handleSearch = (value: string) => {
    setSearchValue(value.toLowerCase())
  }
  const clearSearch = () => setSearchValue("")
  const searchDebounced = useSimpleDebounce(searchValue, 500)
  const filterProduct = (b: IProduct) => {
    if (searchDebounced) {
      return b.name?.toLowerCase().includes(searchDebounced)
    }
    return true
  }

  useEffect(() => {
    refetchList()
  }, [data, refetchList])

  /****responsive styles   */
  const mobileFlexWrap = {
    '@media(max-width:767px)' : {
      flexWrap : "wrap",
      justifyContent: "flex-end"
    }
  }

  /******* end styles  */

  return (
    <>
      <Box sx={{ p: 2 }}>
        <QueryResult data={dataObj} loading={loadingList}>
          <BaseTable
            indexed="id"
            title="Liste des produits"
            columns={columns}
            data={dataList?.filter(filterProduct).reverse() || []}
            page={page - 1}
            topComponent={
              <Box sx={{ p: 2, display: "flex", alignItems: "center" , ...mobileFlexWrap }}>
                <Box sx={{ mr:{ lg:2 , xs:0} , width:{lg:"auto" , xs: "100%"} }}>
                  <ResearchBox
                    placeholder="Recherche rapide..."
                    onChange={handleSearch}
                    clear={clearSearch}
                  />
                </Box>
                <ButtonTop
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => handleAdd()}
                  sx={{ color: "#050005" }}
                >
                  Ajouter produits
                </ButtonTop>
              </Box>
            }
          />
        </QueryResult>
      </Box>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de produit"
      >
        <Typography>Vous êtes sur le point de supprimer un produit</Typography>
      </ConfirmModal>
    </>
  )
}
