import { useQuery } from "@apollo/client"
import { useEffect } from "react"
import { Qrcodes, QRCODES, QrcodesVariables } from "../../graphql/qrcode"

export const useQRCode = (id?: number) => {
  const { data, loading, error, refetch } = useQuery<Qrcodes, QrcodesVariables>(
    QRCODES,
    {
      variables: {
        businessId: id as number
      },
      skip: !id
    }
  )
  useEffect(() => {
    if (id && !data) {
      refetch({ businessId: id })
    }
  }, [id])

  return {
    data: data?.qrcodes,
    loading,
    error,
    refetch
  }
}
