import { Button, styled } from "@mui/material"
import { FC } from "react"
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from "react-google-login"
import { GoogleIcon } from "../../icons/icons"

const StyledButton = styled(Button)(() => ({
  backgroundColor: "white",
  color: "inherit",
  borderRadius: "50px !important",
  height: 40
}))
interface GoogleButtonProps {
  onSigninSuccess: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void
  onSigninError: () => void
}
export const GoogleButton: FC<GoogleButtonProps> = ({
  onSigninSuccess,
  onSigninError
}): JSX.Element => {
  return (
    <GoogleLogin
      clientId="32127140915-c4jo9opb0onl3c25heieothb27l24rn8.apps.googleusercontent.com"
      buttonText="Login"
      onSuccess={onSigninSuccess}
      onFailure={onSigninError}
      cookiePolicy="single_host_origin"
      render={({ onClick, disabled }) => (
        <StyledButton
          fullWidth
          variant="contained"
          color="inherit"
          startIcon={<GoogleIcon />}
          disabled={disabled}
          onClick={onClick}
        >
          GOOGLE
        </StyledButton>
      )}
    />
  )
}
