import { GET_LIST_SENDER } from "@/graphql/emailing"
import { useApplicationContext } from "@/hooks"
import { useQuery } from "@apollo/client"
import {
  Box,
  MenuItem,
  Select,
  Typography
} from "@mui/material"

export const SenderMailForm = (): JSX.Element => {
  const { campagneState, setCampagneState } = useApplicationContext()
  const { data: listSender } = useQuery(GET_LIST_SENDER, {
    variables: {
      status: true
    }
  })

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>Infos expéditeur</Typography>
        <Select
          value={campagneState?.sender}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          fullWidth
          onChange={(e) => {
            setCampagneState((prev) => ({
              ...prev,
              sender: e.target.value
            }))
          }}
        >
          {listSender?.getListSender.map((item: any) => (
            <MenuItem
              key={item.name}
              value={item.email}

            >{item.name} ({item.email})</MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}
