import { Dispatch, FC, SetStateAction } from "react"
import { useParams } from "react-router-dom"
import { CustomModal, LotForm } from "../../../../components"
import { Lots_lots as Ilot } from "../../../../graphql/lots"
import {
  useApplicationContext,
  useCreateLots,
  useLots,
  useUpdateLots
} from "../../../../hooks"
import { LotInput } from "../../../../types"

interface CreateLotProps {
  open: boolean
  editLot?: Ilot
  setOpen: Dispatch<SetStateAction<boolean>>
  afterCreate?: () => void
}

export const CreateLot: FC<CreateLotProps> = ({
  open,
  editLot,
  setOpen,
  afterCreate
}) => {
  const { refetch } = useLots()
  const { id } = useParams()
  const { createLot, loading: createLoading } = useCreateLots()
  const { updateLot, loading: updateLoading } = useUpdateLots()
  const { dispatchSnack } = useApplicationContext()

  const handleCreate = async (values: LotInput) => {
    const lot = await createLot(
      {
        ...values
      },
      id ? parseFloat(id) : undefined
    )
    if (lot?.data) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Ajouter avec succès"
      })
      refetch()
      if (afterCreate) {
        afterCreate()
      }
    }
    setOpen(false)
  }
  const handleUpdate = async (values: LotInput) => {
    if (!editLot) return
    const lot = await updateLot(editLot?.id, {
      ...values
    })
    if (lot?.data) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Modification avec succées"
      })
      refetch()
    }
    setOpen(false)
  }

  return (
    <CustomModal
      noAction
      open={open}
      setOpen={setOpen}
      title={editLot ? "Mettre à jour un lot" : "Créer un lot"}
    >
      <LotForm
        lot={editLot}
        loading={createLoading || updateLoading}
        onCancel={() => {
          setOpen(false)
        }}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
      />
    </CustomModal>
  )
}
