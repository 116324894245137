import { useState } from "react"
import { useMutation } from "@apollo/client"
import { Box, Button, styled, Typography } from "@mui/material"
import { BaseTable, QueryResult } from "@/components"
import { Add } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { TableTemplate } from "./tableTemplate"
import { useQuery } from "@apollo/client"
import { GET_ALL_LIST_TEMPLATE, DELETE_TEMPLATE } from "@/graphql/emailing"
import { ConfirmModal } from "@/components"

export const ListTemplate = () => {
  const ButtonTop = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: 15
  }))
  const [deleteId, setDeleteId] = useState<number>(0)
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE)
  const { data, loading, refetch } = useQuery(GET_ALL_LIST_TEMPLATE)
  const navigate = useNavigate()
  const handleDialog = (id: number) => {
    setDeleteId(id)
    setOpenDelete(true)
  }
  const handleCancelDelete = () => {
    setDeleteId(0)
    setOpenDelete(false)
  }

  const handleConfirmDelete = async () => {
    deleteTemplate({
      variables: {
        templateId: deleteId
      }
    }).then(() => refetch())
    setDeleteId(0)
    setOpenDelete(false)
  }
  const goesToEdit = (id: number) => navigate(`../edit/${id}`)
  const { columns } = TableTemplate({
    onEdite: goesToEdit,
    onDelete: handleDialog
  })
  const [openDelete, setOpenDelete] = useState<boolean>(false)

  return (
    <Box sx={{ m: 2 }}>
      <QueryResult data={data} loading={loading}>
        <BaseTable
          indexed="id"
          title="Liste des templates"
          columns={columns}
          data={data?.GetAllListTemplate}
          page={1}
          topComponent={
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <ButtonTop
                startIcon={<Add />}
                variant="outlined"
                onClick={() => navigate('../create')}
              >Ajouter nouveau template</ButtonTop>
            </Box>
          }
        />
      </QueryResult>

      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de template"
      >
        <Typography>Vous êtes sur le point de supprimer un template, voulez-vous poursuivre?</Typography>
      </ConfirmModal>
    </Box>
  )
}
