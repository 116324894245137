import { useLazyQuery } from "@apollo/client"
import {
  WinnerGames,
  WinnerGamesVariables,
  WINNERS
} from "../../graphql/qrcode"

export const useWinners = () => {
  const [getWinnerExec, { loading,refetch }] = useLazyQuery<
    WinnerGames,
    WinnerGamesVariables
  >(WINNERS)
  const getWinners = async (qrcodeId: number, take: number, skip: number) => {
    try {
      const winners = await getWinnerExec({
        variables: {
          limit: take,
          qrcodeId,
          skip
        }
      })
      return winners.data?.winnerGames || []
    } catch (error) {
      return []
    }
  }
  return {
    refetch,
    loading,
    getWinners
  }
}
