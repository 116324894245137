import { Box } from "@mui/material"
import { FC } from "react"
import { Route, Routes, Outlet } from "react-router-dom"
import { Etablissement } from "./Etablissement"
import { GererEtablissement } from "./GererEtablissement"
import { EmailEtablissement } from "./EmailEtablissement"
import { GererClients } from "../Clients/GererClients"
import { ActiviteInfos } from "../Clients/ActiviteInfos/ActiviteInfos"
import { GererCategory } from "../../components/Etablissement/Produits/components/Category/GererCategory"
import { Produits } from "../../components/Etablissement/Produits/Produits"
import { ProductList } from "../../components/Etablissement/Produits/components/Category/ProductList/ProductList"
import EditCategory from "../../components/Etablissement/Produits/components/Category/EditiCategory/EditCategory"
import EditSousCategory from "../../components/Etablissement/Produits/components/SousCategory/EditSousCategory/EditSousCategory"
import UpdateProduct from "../../components/Etablissement/Produits/components/Produits/UpdateProduct/UpdateProduct"
import { ModalProduits } from "../../components/Etablissement/Produits/components/Produits/ModalProduits/ModalProduits"


interface EtablissementRoutesProps {
  isBusiness?: boolean
}

export const EtablissementRoutes: FC<EtablissementRoutesProps> = ({
  isBusiness
}): JSX.Element => {
  return (
    <Box>
      {isBusiness ? (
        <GererEtablissement isBusiness />
      ) : (
        <Routes>
          <Route index element={<Etablissement />} />
          <Route path=":id/*" element={<GererEtablissement />} />
          <Route path=":id/email" element={<EmailEtablissement />} />
          <Route path=":id/clients/id" element={<GererClients />} />
          <Route path=":id/clients/id/activitie" element={<ActiviteInfos />} />
          <Route path=":id/category/:idEdit" element={<EditCategory />} />
          <Route path=":id/product/ajoutCategory" element={<ModalProduits />} />
          <Route
            path=":id/category/:idCategory/sousCategory"
            element={<GererCategory />}
          />
          <Route
            path=":id/category/sousCategory/:idEditSousCategory"
            element={<EditSousCategory />}
          />
          <Route
            path=":id/category/:idCategory/sousCategory/productList"
            element={<ProductList />}
          />
          <Route path=":id/category/product" element={<Produits />} />
          <Route
            path=":id/category/product/ajoutProduit"
            element={<ModalProduits />}
          />
          <Route
            path=":id/category/product/:idEditProduct"
            element={<UpdateProduct />}
          />
        </Routes>
      )}
      <Outlet />
    </Box>
  )
}
