/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  styled,
  Typography,
  Button,
  CircularProgress,
  CardMedia,
  Card
} from "@mui/material"
import moment from "moment"
import SendIcon from "@mui/icons-material/Send"
import ListItemText from "@mui/material/ListItemText"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import {
  ChangeEvent,
  FC,
  useEffect,
  FormEvent,
  useState
} from "react"
import { COLOR } from "../../utils"
import { DatePickerBase, TextInputBase } from "../forms"
import { ConfirmModal } from "../modals"
import { QRCodeInput, ReglementInput } from "../../types"
import { BusinessInfo_businessInfo as IBusiness } from "../../graphql/business"
import { Lots_lots } from "@/graphql/lots"
import { LogoCommercant } from "@/pages/business/Personnalization/CustomPage/Components/layouts/ContainerWithFooter"

interface ReglementProps {
  loading?: boolean
  defaultValue?: QRCodeInput
  valueReglement?: ReglementInput
  lots?: Lots_lots[]
  business?: IBusiness
  onCancel?: () => void
  onCreateReglement?: (values: ReglementInput) => void
}
const TextVariable = styled("span")(() => ({
  fontWeight: "bold",
  color: COLOR.PRIMARY,
  fontSize: 14,
  cursor: "pointer",
  borderBottom: "1px solid " + COLOR.PRIMARY
}))

const TitleReglement = styled(Typography)(() => ({
  padding: 5,
  fontWeight: "bold",
  fontSize: 18,
  margin: "10px 0px"
}))

interface VariableToTextProps {
  isDate?: boolean
  label?: string
  variableName: string
  value?: any
  onChange?: (val: string, variableName: string) => void
  sx?: any
}
const VariableToText: FC<VariableToTextProps> = ({
  variableName,
  label,
  isDate,
  value,
  onChange,
  sx
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [textValue, setTextValue] = useState<string>("")
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { value: inputValue } = e.target

    setTextValue(inputValue)
  }
  const handleChangeDate = (date: Date | null) => {
    setTextValue(date as any)
  }
  const handleConfirm = () => {
    if (onChange) {
      onChange(textValue, variableName)
    }
  }
  useEffect(() => {
    if (value) {
      setTextValue(value)
    }
  }, [value])

  const valueT = value && isDate ? moment(value).format("DD MMMM YYYY") : value
  return (
    <>
      <TextVariable onClick={() => setOpen(true)} sx={sx}>
        {valueT || `{{ ${label} }}`}{" "}
      </TextVariable>
      <ConfirmModal
        title={label || "Editer l'info"}
        open={open}
        setOpen={setOpen}
        onConfirm={handleConfirm}
      >
        <Box>
          {!isDate ? (
            <TextInputBase
              onChange={handleChange}
              value={textValue}
              label={label}
            />
          ) : (
            <DatePickerBase
              label={label}
              format="DD MMMM YYYY"
              onChange={handleChangeDate}
              value={textValue as any}
            />
          )}
        </Box>
      </ConfirmModal>
    </>
  )
}

export const Reglement: FC<ReglementProps> = ({
  onCancel,
  onCreateReglement,
  defaultValue,
  lots,
  valueReglement,
  business,
  loading
}): JSX.Element => {
  const now = new Date
  const [values, setValues] = useState<ReglementInput>({
    nom_du_jeu: "",
    activite: "",
    adresse_compelete: "",
    capital: "",
    date_end: "",
    date_mise_en_ligne: "",
    date_start: "",
    nombre_de_lot: 0,
    numero_siret: "",
    sites: "",
    societe: "",
    tagdiscount: "",
    ville_rcs: ""
  })

  const handleChange = (value: string, name: string) => {
    if (name === "nombre_de_lot") {
      const nombre = parseFloat(value)
      setValues((prev) => ({
        ...prev,
        [name]: nombre
      }))
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: value
      }))
    }
  }
  const handleCreateReglement = (e: FormEvent) => {
    e.preventDefault()
    onCreateReglement && onCreateReglement(values)
  }

  useEffect(() => {
    setValues({
      nom_du_jeu: valueReglement?.nom_du_jeu || defaultValue?.title || "",
      date_end: valueReglement?.date_end || defaultValue?.dateEnd || "",
      sites: valueReglement?.sites || defaultValue?.urlRegle || "",
      adresse_compelete:
        valueReglement?.adresse_compelete || business?.address || "",
      activite: valueReglement?.activite || business?.activity || "",
      date_start: valueReglement?.date_start || business?.createdAt || "",
      numero_siret: valueReglement?.numero_siret || business?.siret || "",
      societe: valueReglement?.societe || defaultValue?.etablissement || "",
      tagdiscount: valueReglement?.tagdiscount || business?.businessName || "",
      ville_rcs: valueReglement?.ville_rcs || business?.address || "",
      date_mise_en_ligne: "",
      capital: valueReglement?.capital || business?.address || "",
      nombre_de_lot: valueReglement?.nombre_de_lot || 0
    })
  }, [defaultValue, business, valueReglement])

  return (
    <Box>
      <Card
        elevation={0}
        sx={{
          borderRadius: 0,
          backgroundColor: "transparent",
          mb: 2
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={business?.coverPhoto as string}
          alt="green iguana"
          sx={{ borderRadius: "0px 0px 40px 40px" }}
        />
        <Box sx={{ height: 48 }}>
          <LogoCommercant
            logo={business?.logo || ""}
            name={business?.businessName}
          />
        </Box>
      </Card>
      <form onSubmit={handleCreateReglement}>
        <Typography variant="h2" color="primary" textAlign="center" >
          Règlement du jeu{" "}
          <VariableToText
            label="Nom du jeu"
            variableName="nom_du_jeu"
            value={values.nom_du_jeu}
            onChange={handleChange}
            sx={{ fontSize: "1em" }}
          />{" "}
          à partir du{" "}
          <VariableToText
            isDate
            variableName="date_mise_en_ligne"
            label="Date de mise en ligne"
            value={now}
            onChange={handleChange}
            sx={{ fontSize: "1em" }}
          />
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 1 - Société Organisatrice
        </TitleReglement>
        <Typography>
          La société{" "}
          <VariableToText
            variableName="societe"
            label="Nom de la société"
            value={values.societe}
            onChange={handleChange}
          />
          , société en nom collectif au capital de{" "}
          <VariableToText
            variableName="capital"
            label="Capital en Euro"
            value={values.capital}
            onChange={handleChange}
          />{" "}
          euros, dont le siège social se trouve{" "}
          <VariableToText
            variableName="adresse_compelete"
            label="Adresse complete"
            value={values.adresse_compelete}
            onChange={handleChange}
          />{" "}
          immatriculée au RCS de{" "}
          <VariableToText
            variableName="ville_rcs"
            label="Ville"
            value={values.ville_rcs}
            onChange={handleChange}
          />{" "}
          sous le numéro{" "}
          <VariableToText
            variableName="numero_siret"
            label="Numero siret"
            value={values.numero_siret}
            onChange={handleChange}
          />{" "}
          (ci-après « la Société Organisatrice ») organise du
          <VariableToText
            isDate
            variableName="date_start"
            label="Date de départ"
            value={values.date_start}
            onChange={handleChange}
          />{" "}
          à 00h01 jusqu’au{" "}
          <VariableToText
            isDate
            label="Date fin du Jeu"
            variableName="date_end"
            value={values.date_end}
            onChange={handleChange}
          />{" "}
          à 23h59 un jeu gratuit avec obligation d’achat intitulé, «{" "}
          <VariableToText
            variableName="nom_du_jeu"
            label="Nom du jeu"
            value={values.nom_du_jeu}
            onChange={handleChange}
          />{" "}
          » (ci-après le « Jeu »).
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 2 - Public autorisé à participer au Jeu
        </TitleReglement>
        <Typography>
          Ce Jeu est ouvert à toute personne physique, à l’exclusion du
          personnel de la Société Organisatrice, du personnel des sociétés
          prestataires de services et de leurs éventuels sous-traitants sur
          cette opération.
        </Typography>{" "}
        <Typography>
          {" "}
          La participation au Jeu d’un mineur ou d’un majeur incapable est
          soumise à l’autorisation de ses parents/représentants légaux. La
          Société Organisatrice se réserve le droit de vérifier la réalité de
          cette autorisation. A défaut pour le participant de pouvoir en
          justifier, sa participation au Jeu sera annulée.
        </Typography>
        <Typography>
          La Société Organisatrice se réservant le droit de procéder à toutes
          les vérifications nécessaires concernant l’identité, les adresses mail
          et postale, ainsi que l’âge des participants.{" "}
        </Typography>
        <Typography>
          Les personnes n’ayant pas justifié de leurs coordonnées et identités
          complètes ou qui les auraient fournies de façon inexacte ou mensongère
          ne pourront se voir remettre le lot, tout comme les personnes refusant
          les collectes, enregistrements et utilisations des informations à
          caractère nominatif les concernant et strictement nécessaires pour les
          besoins de la gestion du jeu.
        </Typography>{" "}
        <Typography>
          {" "}
          La participation au jeu implique pour tout participant l'acceptation
          entière et sans réserve du présent règlement (ci-après « le Règlement
          »). Le non-respect du Règlement entraîne l’annulation automatique de
          la participation et de l'attribution éventuelle des lots.
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 3 - Supports de communication sur le Jeu
        </TitleReglement>
        <Typography>
          Ce Jeu est diffusé via le QR code présent sur Flyer ou une bannière
          publicitaire sous forme d’image de toutes tailles (ci-après
          communément dénommé « le Jeu »).
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 4 - Modalités de participation de jeux
        </TitleReglement>
        <Typography>
          Pour participer au Jeu, les participants doivent : • Se rendre dans
          les locaux du commerce ou identifier sur internet une image avec la
          bannière avec le code QR • Flasher le QR code, avec un smartphone
          compatible, présent sur le présentoire ou sur le flyer (flash possible
          à partir de l’application photo du téléphone mobile, d’une application
          de scan de QR code ou de l’appareil photo d’un smartphone compatible)
          • Cliquer sur « Jouer » • Faire tourner la roue de jeu • En cas ou le
          Jeu est gagnant, renseigner le formulaire de participation. Il ne sera
          accepté qu’une seule participation par personne dans un intervalle de
          24h et dans la limite de 4 gains par mois.
        </Typography>
        <TitleReglement variant="h3">ARTICLE 5 - Dotation</TitleReglement>
        <Typography>
          Est mis en jeu pour l’ensemble du Jeu (limité à{" "}
          <VariableToText
            variableName="nombre_de_lot"
            label="Nombre de lot"
            value={values.nombre_de_lot}
            onChange={handleChange}
          />
          lots/mois) :
          {lots?.length && lots?.length >= 1 ? (
            lots?.map((value, index) => (
              <ListItem key={index} alignItems="center">
                <ListItemAvatar>
                  <SendIcon />
                </ListItemAvatar>
                <ListItemText>
                  <Typography>{value.title}</Typography>
                </ListItemText>
              </ListItem>
            ))
          ) : (
            <ListItem alignItems="center">
              <ListItemAvatar>
                <SendIcon />
              </ListItemAvatar>
              <ListItemText>
                <Typography>INTITULER DU LOT</Typography>
              </ListItemText>
            </ListItem>
          )}
        </Typography>
        <Typography>
          Cette liste est susceptible d’évoluer en cours de Jeu à la seule
          discrétion de la Société Organisatrice. Dans tous les cas, la dotation
          ne pourra pas être échangée contre sa valeur en valeur numéraire ou
          contre toute autre dotation. Le gagnant ne pourra demander à obtenir
          une autre dotation ou toute autre contrepartie en numéraire (totale ou
          partielle) ou équivalent à la place de la dotation proposée. La
          dotation est nominative et ne pourra être attribuée à une autre
          personne que le gagnant. Toute revente de ce lot est considérée comme
          illicite et entraîne l’annulation automatique de la
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 6 - Désignation des gagnants
        </TitleReglement>
        <Typography>
          participation et du gain attribué. Un algorithme aléatoire permettra
          de désigner les gagnants de ce Jeu (cf article 4 du présent
          règlement).
        </Typography>
        <TitleReglement variant="h3">ARTICLE 7 - Remise de Lots</TitleReglement>
        <Typography>
          L’annonce du ou des gagnants sera faite instantanément à la fin du
          jeu. Un email de confirmation sera envoyé au(x) gagnant(s) dans les 24
          heures suivant leur(s) participation(s) conformément aux informations
          et coordonnées fournies par lui (eux) lors de leur(s) inscription(s)
          au Jeu.
        </Typography>
        <Typography>
          Cet email contient également le bon gagnant (code à usage unique) qui
          sera à utiliser dans la limite de 1 mois à compter du mois en cours de
          la réception du bon : • Sur le site internet de{" "}
          <VariableToText
            variableName="sites"
            label="url"
            value={values.sites}
            onChange={handleChange}
          />{" "}
          pour les reservation • Uniquement en caisse autres lots
        </Typography>
        <Typography>
          Si les informations ou coordonnées communiquées par le gagnant ne
          permettent pas de l’informer de son gain, il perdra la qualité de
          gagnant et ne pourra effectuer aucune réclamation, le(s) lot(s)
          demeurant alors la propriété de la Société Organisatrice qui pourra le
          réattribuer.
        </Typography>
        <Typography>
          Dans le cas où le gagnant serait dans l’impossibilité de bénéficier,
          en tout ou partie, de son lot, pour quelque raison que ce soit, il en
          perdra le bénéfice, sans possibilité d’obtenir une
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 8 - Frais de participation
        </TitleReglement>
        <Typography>quelconque contrepartie.</Typography>{" "}
        <Typography> Le Jeu est sans obligation d’achat.</Typography>{" "}
        <Typography>
          {" "}
          La Société Organisatrice ne procédera à aucun remboursement des frais
          de connexion. En l'état actuel des offres de service et de la
          technique, la plupart des fournisseurs d'accès à Internet offrent une
          connexion forfaitaire aux internautes. Dans la mesure où l'abonnement
          aux services du fournisseur d'accès est contracté par l'internaute
          pour son usage de l'Internet en général, il est expressément convenu
          que l’accès au Jeu ne pourra donner lieu à aucun remboursement puisque
          le fait pour le participant de se connecter et de participer au
          Jeu-Concours ne lui occasionne aucun frais ou débours supplémentaire.
        </Typography>{" "}
        <Typography>
          {" "}
          La Société Organisatrice ne sera tenue à aucun remboursement si la
          participation n’a pas été conforme au présent règlement ou si la
          demande n’a pas été faite dans les formes et
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 9 - Respect des règles du Jeu
        </TitleReglement>
        <Typography>délais indiqués ci-dessus.</Typography>{" "}
        <Typography>
          {" "}
          La participation à ce Jeu implique l’acceptation pleine, entière et
          sans réserve du présent règlement y compris ses avenants éventuels, la
          renonciation à tout recours concernant les conditions d'organisation
          et le déroulement du Jeu, ses résultats et l'attribution des prix.
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 10 - Données Personnelles
        </TitleReglement>
        <Typography>
          La Société Organisatrice ainsi que le site{" "}
          <VariableToText
            label="Tagdicount"
            variableName="tagdiscount"
            value={values.tagdiscount}
            onChange={handleChange}
          />
          sont responsable du traitement des données collectées. A ce titre elle
          s'autorise à conserver les informations transmises par les
          participants dans le cadre du présent Jeu. Les données sont uniquement
          recueillies à l’usage de la Société Organisatrice permettant à
          celle-ci de remplir ses obligations relatives à la détermination du
          gagnant et à la remise de la dotation. Les informations nominatives
          recueillies dans le cadre du présent Jeu sont traitées conformément au
          Règlement Européen de Protection des Données, aucun traitement
          informatique des données n’aura lieu hormis pour les données inscrites
          sur le bon de participation du gagnant. Le gagnant est informé que les
          données nominatives le concernant enregistrées dans le cadre de ce Jeu
          sont nécessaires à la prise en compte de sa participation. Tous les
          participants au Jeu disposent en application de cette loi, d’un droit
          d’accès, d’opposition, de rectification ou de suppression des données
          les concernant. Toute demande d'accès, d’opposition, de rectification
          ou de suppression doit être adressée auprès de la Société
          Organisatrice ({" "}
          <VariableToText
            variableName="societe"
            label="Nom de la société"
            value={values.societe}
            onChange={handleChange}
          />{" "}
          <VariableToText
            variableName="activite"
            label="Activité de la société"
            value={values.activite}
            onChange={handleChange}
          />
          <VariableToText
            variableName="adresse_compelete"
            label="Adresse complète"
            value={values.adresse_compelete}
            onChange={handleChange}
          />
          . ). Par conséquent, les personnes qui exerceront le droit de
          suppression des données les concernant avant la fin du Jeu seront
          réputées renoncer à leur participation.
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 11 - Responsabilité
        </TitleReglement>
        <Typography>
          La Société Organisatrice se réserve le droit de modifier, reporter,
          compléter ou annuler sans préavis tout ou partie de ce Jeu. Elle ne
          saura être tenue responsable et aucune indemnisation ne pourra être
          réclamée à ce titre.
        </Typography>
        <Typography>
          La Société Organisatrice se réserve également le droit de proroger le
          présent Jeu, pour quelque raison que ce soit, sans que sa
          responsabilité ne puisse être engagée de ce fait et sans que le
          participant ne puisse prétendre à une indemnisation à quelque titre
          que ce soit.
        </Typography>
        <Typography>
          La Société Organisatrice ne saurait être tenue pour responsable
          d’éventuels dysfonctionnements liés au réseau Internet lui-même ou
          liés à toute intrusion, tentative d’intrusion ou fraude ayant entraîné
          des défaillances dans l’administration, la sécurité, l’équité,
          l’intégrité ou la gestion du Jeu. La Société Organisatrice ne saurait
          notamment être déclarée responsable pour toutes erreurs, omissions,
          interruptions, effacement, perte de tout courrier électronique et plus
          généralement, de la perte de toute donnée de ce fait.
        </Typography>
        <Typography>
          La Société Organisatrice ne saurait être tenue pour responsable de
          l'encombrement du réseau Internet, de la qualité de l'équipement des
          internautes, ni de la qualité de leur mode d'accès qui pourraient
          avoir des répercussions sur le délai d'acheminement des réponses ou
          sur le temps de connexion nécessaire à la participation. Ainsi, la
          responsabilité de La Société Organisatrice ne pourra être engagée si
          les formulaires électroniques d’inscription ne sont pas enregistrés,
          sont incomplets, ou impossibles à vérifier.
        </Typography>
        <Typography>
          La Société Organisatrice ne saurait non plus être tenue pour
          responsable de l'encombrement des lignes téléphoniques, des
          dysfonctionnements des réseaux de télécommunication ou des services
          postaux entravant le bon déroulement du Jeu. La responsabilité de la
          Société Organisatrice ne pourra être engagée si les participations au
          Jeu n’ont pas été enregistrées ou sont impossibles à vérifier ou à
          décrypter.
        </Typography>
        <Typography>
          La Société Organisatrice se réserve le droit, à l'encontre de toute
          personne qui altérerait le déroulement du Jeu et affecterait
          l'administration, la sécurité, l'équité, l'intégrité, ou le bon
          déroulement du Jeu, de bloquer temporairement ou définitivement,
          totalement ou partiellement, la possibilité qui lui est donnée de
          participer au Jeu, de ne pas lui attribuer les éventuelles dotations
          qu’il aurait gagné et le cas échéant, se réserve le droit d'engager à
          son
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 12 - Conversion de preuve
        </TitleReglement>
        <Typography>
          encontre des poursuites judiciaires. Il est convenu que les données
          contenues dans les systèmes d'information de la Société Organisatrice
          ont force probante quant aux éléments de connexion et aux informations
          résultant d'un traitement informatique relatif au Jeu organisé par la
          Société Organisatrice.
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 13 - Demande de reglement
        </TitleReglement>
        <Typography>
          Règlement et Charte de confidentialité sont disponibles sur le site
          internet{" "}
          <VariableToText
            variableName="sites"
            label="Site internet"
            value={values.sites}
            onChange={handleChange}
          />{" "}
          directement sur le Jeu ou sur simple demande à l’adresse :
          <VariableToText
            variableName="societe"
            label="Société"
            value={values.societe}
            onChange={handleChange}
          />{" "}
          -{" "}
          <VariableToText
            variableName="adresse_compelete"
            label="Adresse complète"
            value={values.adresse_compelete}
            onChange={handleChange}
          />
          .
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 14 - Droits de priorité et intellectuelle et industrielle
        </TitleReglement>
        <Typography>
          Conformément à la législation relative aux droits de propriété
          littéraire et artistique aux droits voisins et aux droits de propriété
          industrielle, la reproduction et la représentation de tout ou partie
          des éléments composant le présent Jeu sont strictement interdites.
          Toutes les marques ou noms de produits cités dans le Jeu sont des
          marques déposées appartenant à leur propriétaire respectif.
        </Typography>
        <TitleReglement variant="h3">
          ARTICLE 15 - Litige - Loi applicaple
        </TitleReglement>
        <Typography>
          Toute contestation ou réclamation relative aux modalités de
          participation et de tirage au sort devra être formulée par écrit et
          adressée à la Société Organisatrice. La demande devra impérativement
          comporter le nom du Jeu, la date précise de participation du Jeu, les
          coordonnées complètes du participant et le motif exact de la
          contestation. Aucun autre mode de contestation ou réclamation ne sera
          pris en compte. Les contestations et réclamations écrites ne seront
          plus prises en compte par la Société Organisatrice passé un délai d’un
          mois après la clôture du Jeu. Le présent règlement est régi par la loi
          française.
        </Typography>
        <Box sx={{ textAlign: "center", m: 2 }}>
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={() => onCancel && onCancel()}
          >
            Anuller
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            startIcon={
              loading ? (
                <CircularProgress style={{ width: 25, height: 25 }} />
              ) : null
            }
          >
            Valider
          </Button>
        </Box>
      </form>
    </Box>
  )
}
