import InputBase from "@mui/material/InputBase"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import { ChangeEvent, FC, ReactNode, SyntheticEvent, useState, useEffect } from "react"
import { Cancel } from "@mui/icons-material"
import { Box } from "@mui/material"

interface ResearchBoxProps {
  placeholder?: string
  activity?: string
  address?: string
  onChange?: (value: string) => void
  startLabel?: ReactNode
  clear?: () => void
  mx?: number
}
export const ResearchBox: FC<ResearchBoxProps> = ({
  placeholder,
  startLabel,
  activity,
  address,
  mx,
  clear,
  onChange
}): JSX.Element => {

  const [value, setValue] = useState<string>("")


  useEffect(()=>{
    if(activity){
      setValue(activity)
    }else if(address){
      setValue(address)
    }
    return 
  }, [activity, address])

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value)
    onChange && onChange(event.target.value)
  }
  const handleClear = (e: SyntheticEvent): void => {
    e.preventDefault()
    setValue("")
    clear && clear()
    onChange && onChange("")
  }

  return (
    <Box
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        height: "43px",
        border: "1px solid #CACACA",
        borderRadius: "5px"
      }}
    >
      <InputBase
        onChange={handleChange}
        value={value}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder || "Rechercher..."}
        inputProps={{ "aria-label": "search google maps" }}
      />
      {value ? (
        <IconButton
          type="submit"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={handleClear}
        >
          <Cancel />
        </IconButton>
      ) : null}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Box>
  )
}
