import { TableColumnProps } from "@/components"
import { Clientresevation_clientResevation as IClientBooking } from "@/graphql/clients"
import { Typography, Chip } from "@mui/material"
import moment from "moment"

export const TableBooking = (): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "name",
      label: "Nom",
      component: (data: IClientBooking) => <Typography>{data.name}</Typography>
    },
  {
      id: "email",
      label: "email",
      component: (data: IClientBooking) => <Typography>{data.email}</Typography>
    },
    {
      id: "phone",
      label: "Téléphone",
      component: (data: IClientBooking) => <Typography>{data.phone}</Typography>
    },
	{
	id: "createdAt",
	label: "date de création",
	component: (data: IClientBooking) => <Typography>{moment(data.createdAt).format("DD MMMM YYYY")}</Typography>
	},
	{
	id: "date",
	label: "Réservation pour",
	component: (data: IClientBooking) => <Typography>{moment(data.date).format("DD MMMM YYYY")} à {moment(data.date).format("HH:mm")}</Typography>
	},
    {
      id: "status",
      label: "état de la commande",
      component: (data: IClientBooking) => {
        switch (data.status) {
          case "PENDING":
            return <Chip color="warning" label="En attente" />
          case "VALID":
            return <Chip color="success" label="Validé" />
          case "REJECT":
            return <Chip color="error" label="Rejeté" />
          case "DONE":
            return <Chip label="Terminé" />
        }
      }
    }
  ]

  return { columns }
}
