import { BUSINESS_GET_CLICK_BY_ID } from "@/graphql/business"
import { useQuery } from "@apollo/client"
import { Archive, Cancel, CheckCircle, Print } from "@mui/icons-material"
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material"
import moment from "moment"
import { Key, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import { FormatPrice } from "../FormatPrice"
import { useApplicationContext } from "@/hooks"
import { ProductByBusiness } from "@/graphql/business/types/ProductByBusiness"

export type CurrentListType = {
  businessId: number
  commandsId: number
  description: string
  id: number
  image: string
  name: string
  preparationTime: number | null
  prix: number
  prixPromotionnels: number
  prixTTC: number
  productId: number
  status: boolean
  totalCount: number
  totalPrice: number
}

type ModalDndProps = {
  businessProducts?: ProductByBusiness
  business: any
  currentList: any[]
  currencyType: any
  clientCommand: any
  open: boolean
  handleClose: () => void
  archived: (id: number, status: string) => void
}

export const ModalDnd = ({
  businessProducts,
  business,
  currentList,
  clientCommand,
  open,
  handleClose,
  archived
}: ModalDndProps) => {
  const { dispatchSnack } = useApplicationContext()
  const { data: dataCurrency } = useQuery(BUSINESS_GET_CLICK_BY_ID, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })
  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  const archiveMessage = () => {
    dispatchSnack({
      open: true,
      severity: "success",
      message: "Archivé avec succès"
    })
  }
  const clientCommandList = currentList && currentList.length > 0 ? currentList.map((item: any) => {
    return item.productId
  }) : null
  const productByCard = businessProducts?.productByBusiness.filter((item) => {
    if (clientCommandList && clientCommandList?.length > 0) {
      return clientCommandList.includes(item.id)
    }
  })
  const processedProduct = productByCard
    ? productByCard.map((i: any, index: number) => {
      return {
        ...i,
        ...currentList[index],
        totalPrice: currentList[index].productPrice * currentList[index].totalCount
      }
    })
    : null
  const sum = processedProduct
    ? processedProduct.reduce(
      (acc: number, obj: CurrentListType) => acc + obj.totalPrice,
      0
    )
    : 0
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      scroll="body"
      fullWidth
    >
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2
            }}
          >
            <Typography id="modal-modal-title" variant="h5" component="div">
              {`N°: ${clientCommand?.id}, ${moment(
                clientCommand?.createdAt
              ).format("DD MMMM YYYY HH:mm")}`}
            </Typography>

            <Box>
              <Tooltip title="Archivé">
                <IconButton
                  sx={{ border: "1px solid", mr: 1 }}
                  color="warning"
                  onClick={() => {
                    archived(clientCommand?.id, "archive")
                    archiveMessage()
                    handleClose()
                  }}
                >
                  <Archive />
                </IconButton>
              </Tooltip>

              <Tooltip title="Imprimé">
                <IconButton sx={{ border: "1px solid" }} onClick={handlePrint}>
                  <Print />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          {clientCommand?.archive && (
            <Alert sx={{ mb: 2 }} severity="warning">
              Cette commande a été archivée
            </Alert>
          )}
          <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
            Information de l'établissement :
          </Typography>
          <Typography >
            {business?.businessName}
          </Typography>
          <Typography >
            {business?.address}
          </Typography>
          <Typography >{business?.url}</Typography>
          <Divider sx={{ my: 2 }} />
          <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
            Information client :
          </Typography>
          <Typography >
            {clientCommand?.name}
          </Typography>
          <Typography >
            {clientCommand?.address}
          </Typography>
          <Typography >
            {clientCommand?.email}
          </Typography>
          <Typography >
            {clientCommand?.phone}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
            Description d'achat :
          </Typography>
          <Typography>
            {clientCommand?.description}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
            Information d'achat :
          </Typography>
          {processedProduct
            && processedProduct.map(
              (
                item: {
                  id: Key | null | undefined
                  productPrice: number
                  totalCount: string | number
                  name: string | number
                  totalPrice: string | number
                },
                index: number
              ) => (
                <Box key={item.id} sx={{ mb: 2, pl: 2 }}>
                  <Typography variant="h5">{item.name} :</Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography component="span">
                      {item.totalCount} x &nbsp;
                    </Typography>
                    <FormatPrice
                      position={
                        dataCurrency?.clickCollectById?.position || ""
                      }
                      currency={
                        dataCurrency?.clickCollectById?.currency || ""
                      }
                      separator={
                        dataCurrency?.clickCollectById?.thousandsSeparator ||
                        ""
                      }
                      decimal={
                        dataCurrency?.clickCollectById?.decimalSeparator || ""
                      }
                      decNum={dataCurrency?.clickCollectById?.decimalNumber}
                      value={item.productPrice}
                    />
                    <Typography component="span">
                      &nbsp; = &nbsp;
                    </Typography>
                    <FormatPrice
                      position={
                        dataCurrency?.clickCollectById?.position || ""
                      }
                      currency={
                        dataCurrency?.clickCollectById?.currency || ""
                      }
                      separator={
                        dataCurrency?.clickCollectById?.thousandsSeparator ||
                        ""
                      }
                      decimal={
                        dataCurrency?.clickCollectById?.decimalSeparator || ""
                      }
                      decNum={dataCurrency?.clickCollectById?.decimalNumber}
                      value={item.totalPrice}
                    />
                  </Box>
                </Box>
              )
            )}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: "flex" }}>
            <Typography id="modal-modal-title" variant="h5" sx={{ mr: 2 }}>
              TOTAL:
            </Typography>
            <FormatPrice
              position={dataCurrency?.clickCollectById?.position || ""}
              currency={dataCurrency?.clickCollectById?.currency || ""}
              separator={
                dataCurrency?.clickCollectById?.thousandsSeparator || ""
              }
              decimal={dataCurrency?.clickCollectById?.decimalSeparator || ""}
              decNum={dataCurrency?.clickCollectById?.decimalNumber}
              value={sum}
            />
          </Box>
          {clientCommand?.status === "PENDING" && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
                Action :
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around"
                }}
              >
                <Button
                  sx={{ my: 2 }}
                  variant="contained"
                  color="error"
                >
                  <Cancel sx={{ mr: 1 }} />
                  Refusé
                </Button>
                <Button
                  sx={{ my: 2 }}
                  variant="contained"
                  color="success"
                >
                  <CheckCircle sx={{ mr: 1 }} />
                  Validé
                </Button>
              </Box>
            </>
          )}
          {clientCommand?.status === "VALID" && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
                Action :
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around"
                }}
              >
                <Button
                  sx={{ my: 2 }}
                  variant="contained"
                  color="success"
                >
                  <CheckCircle sx={{ mr: 1 }} />
                  Terminé
                </Button>
              </Box>
            </>
          )}
        </CardContent>
      </Card>

      {/* Template for printing */}

      <Box sx={{ display: "none" }}>
        <Card ref={componentRef} sx={{ border: "none", boxShadow: "none" }}>
          <CardContent>
            <Box sx={{ display: "flex" }}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="div"
                  >
                    {`N°: ${clientCommand?.id}, ${moment(
                      clientCommand?.createdAt
                    ).format("DD MMMM YYYY HH:mm")}`}
                  </Typography>
                </Box>
                <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
                  Information établissement :
                </Typography>
                <Typography >
                  {business?.businessName}
                </Typography>
                <Typography >
                  {business?.address}
                </Typography>
                <Typography >
                  {business?.url}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
              Information client :
            </Typography>
            <Typography >
              {clientCommand?.name}
            </Typography>
            <Typography >
              {clientCommand?.address}
            </Typography>
            <Typography >
              {clientCommand?.email}
            </Typography>
            <Typography >
              {clientCommand?.phone}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
              Description d'achat :
            </Typography>
            <Typography>
              {clientCommand?.description}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
              Information achat :
            </Typography>
            {processedProduct 
              && processedProduct.map(
                (
                  item: {
                    id: Key | null | undefined
                    totalCount: string | number
                    name: string | number
                    totalPrice: string | number
                  },
                  index: number
                ) => (
                  <Box key={item.id} sx={{ mb: 2, pl: 2 }}>
                    <Typography variant="h5">
                      Article {index + 1} :
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Typography component="span">
                        {item.totalCount} x &nbsp;
                      </Typography>
                      <Typography component="span">
                        {item.name} = &nbsp;
                      </Typography>
                      <FormatPrice
                        position={
                          dataCurrency?.clickCollectById?.position || ""
                        }
                        currency={
                          dataCurrency?.clickCollectById?.currency || ""
                        }
                        separator={
                          dataCurrency?.clickCollectById
                            ?.thousandsSeparator || ""
                        }
                        decimal={
                          dataCurrency?.clickCollectById?.decimalSeparator ||
                          ""
                        }
                        decNum={dataCurrency?.clickCollectById?.decimalNumber}
                        value={item.totalPrice}
                      />
                    </Box>
                  </Box>
                )
              )}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: "flex" }}>
              <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
                TOTAL :
              </Typography>
              {/* rectification */}
              <FormatPrice
                position={dataCurrency?.clickCollectById?.position || ""}
                currency={dataCurrency?.clickCollectById?.currency || ""}
                separator={
                  dataCurrency?.clickCollectById?.thousandsSeparator || ""
                }
                decimal={dataCurrency?.clickCollectById?.decimalSeparator || ""}
                decNum={dataCurrency?.clickCollectById?.decimalNumber}
                value={sum}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  )
}
