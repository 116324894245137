import { styled } from "@mui/material"
import Box from "@mui/material/Box"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { FC } from "react"

const ControlLabel = styled(FormControlLabel)(() => ({
  justifyContent: "space-between",
  width: "100%"
}))
interface ICustomSwitch {
  label: string
  checked: boolean
  onChange: (value: any, name: string, checked: boolean) => void
  name: string
}
export const CustomSwitch: FC<ICustomSwitch> = ({
  checked,
  label,
  name,
  onChange
}): JSX.Element => {
  const handleChange = (event: React.SyntheticEvent, value: any): void => {
    onChange(value, name, checked)
  }

  return (
    <Box>
      <ControlLabel
        control={
          <Switch checked={checked} onChange={handleChange} name={name} />
        }
        label={label}
        labelPlacement="start"
      />
    </Box>
  )
}
