import { gql } from "@apollo/client";

export const TAGLIST = gql`
  query TagList($businessId: Float) {
    tagList(businessId: $businessId) {
      id
      name
      expired
      businessId
      listBrevoId
      listContactId
    }
  }
`
