/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography, Box } from "@mui/material"
import moment from "moment"
import { TableColumnProps } from "@/components"
import { BusinessAllProduct_businessAllProduct as IListAllProduct } from "@/graphql/business"

export const useTableProductList = (): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "image",
      label: "Image",
      component: (data: IListAllProduct) => (
        <Box
          sx={{
            width: 100,
            height: 100,
            m: 2,
            borderRadius: "5px",
            position: "relative"
          }}
        >
          <img
            src={data.image || ""}
            alt=""
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
      )
    },
    {
      id: "name",
      label: "Name ",
      component: (data: IListAllProduct) => <Typography>{data.name}</Typography>
    },
    {
      id: "prix",
      label: "Prix",
      component: (data: IListAllProduct) => (
        <Typography>{data.prix} $</Typography>
      )
    },
    {
      id: "date",
      label: "Date",
      component: (data: IListAllProduct) => (
        <Typography>{moment(data.createdAt).format("DD/MM/YYYY")}</Typography>
      )
    }
  ]
  return { columns }
}
