import { TableColumnProps, ButtonLittleIcon } from "@/components"
import { Box } from "@mui/material"
import Delete from "@mui/icons-material/Delete"
import Visibility from "@mui/icons-material/Visibility"
import Add from "@mui/icons-material/Add"
import { GetListContact_getListContact as ListContatType } from "@/graphql/emailing/types/GetListContact"
import Edit from "@mui/icons-material/Edit"

interface TableProps {
  onAdd: (item: number, listId: number) => void
  onEdit: (item: number, name: string) => void
  onDelete: (item: number) => void
  onView: (item: number) => void
}
export const TableListContact = ({ onAdd, onEdit, onDelete, onView }: TableProps): { columns: TableColumnProps[] } => {
  const columns = [
    {
      id: "2",
      label: "Nom",
      component: (data: ListContatType) => <p>{data.name}</p>
    },
    {
      id: "3",
      label: "Nombre de contacts",
      component: (data: ListContatType) => ( data?.clients ? <p>{data?.clients.length} contact(s)</p> : <p>0 contact</p> )
    },
    {
      id: "4",
      label: "Actions",
      component: (data: ListContatType) => (
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mr: 1 }}>
            <ButtonLittleIcon color="info" onClick={() => onView(Number(data.id))} icon={<Visibility />} />
          </Box>
          <Box sx={{ mr: 1 }}>
            <ButtonLittleIcon color="success" onClick={() => onAdd(Number(data.id), Number(data.listSendinblueId))} icon={<Add />} />
          </Box>
          <Box sx={{ mr: 1 }}>
            <ButtonLittleIcon color="success" onClick={() => onEdit(Number(data.id), data.name || "")} icon={<Edit />} />
          </Box>
          <Box sx={{ mr: 1 }}>
            <ButtonLittleIcon color="error" onClick={() => onDelete(Number(data.id))} icon={<Delete />} />
          </Box>
        </Box>
      )
    }
  ]

  return { columns }
}
