import { Box, Typography, styled, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import Add from "@mui/icons-material/Add"
import { useApplicationContext, useUpdateClient } from "@/hooks"
import { BaseTable, ConfirmModal } from "@/components"
import Swal from "sweetalert2"
import {
  usedeleteTemplate,
  useTemplateGlobal,
  useTemplateSpinner
} from "@/hooks/templateSpinner"
import { tableListTemplate } from "./tableListTemplate/tableListTemplate"

export const ListTemplate = () => {
  const { deletetemplates } = usedeleteTemplate()
  const navigate = useNavigate()
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const { deleteLoading } = useUpdateClient()
  const [templateSpinnerId, setTemplateSpinnerId] = useState<number>()
  const [page] = useState<number>(1)
  const { loading: loadingBusiness, business } = useApplicationContext()
  const { data, refetch, loading } = useTemplateSpinner(business?.id)
  const ButtonTop = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: 15
  }))
  const { data: globalTemplate, refetch: refetchGlobal } = useTemplateGlobal()
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleDelete = (templateId: number) => {
    setOpenDelete(true)
    setTemplateSpinnerId(templateId)
  }

  const handleEdit = (idTemplate: number) => {
    navigate(`${idTemplate}`)
  }

  const { columns } = tableListTemplate({
    onRemove: handleDelete,
    onEdit: handleEdit
  })

  const handleAdd = () => {
    navigate("add-template")
  }
  const handleCancelDelete = () => {
    setTemplateSpinnerId(undefined)
    setOpenDelete(false)
  }

  const handleConfirmDelete = async () => {
    if (!templateSpinnerId) {
      setOpenDelete(false)
      return
    }
    const removeTemplates = await deletetemplates(templateSpinnerId)
    if (removeTemplates) {
      Swal.fire({
        icon: "success",
        title: "Suppression réussie",
        showConfirmButton: false,
        showCloseButton: true
      })
    }
    if (business?.id) {
      refetch()
    } else {
      refetchGlobal()
    }
    setTemplateSpinnerId(undefined)
    setOpenDelete(false)
  }

  return (
    <>
      <Box sx={{ p: 2 }}>
        {data || globalTemplate ? (
          <BaseTable
            title="Listes des templates"
            columns={columns}
            loading={loading}
            data={data || globalTemplate || []}
            page={page - 1}
            topComponent={
              <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
                <ButtonTop
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => handleAdd()}
                  sx={{ color: "#050005" }}
                >
                  Ajouter template
                </ButtonTop>
              </Box>
            }
          />
        ) : (
          ""
        )}
      </Box>
      <ConfirmModal
        open={openDelete}
        loading={deleteLoading}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de template"
      >
        <Typography>Vous voulez vraiment supprimer ce template ?</Typography>
      </ConfirmModal>
    </>
  )
}
