import { Box, Button, CardContent, Typography, Card, Alert, AlertColor, Grid, ToggleButton } from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react"
import "../style.css"
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js"

export const PayementForm = () => {
  const [message, setMessage] = useState<string>("")
  const [typeMessage, setTypeMessage] = useState<AlertColor>("success")
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (!stripe) return

    const clientSecret = localStorage.getItem("client_secret")

    if (!clientSecret) return

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payement réussie.");
          setTypeMessage("success")
          break;
        case "processing":
          setMessage("Votre payement est en cours.");
          setTypeMessage("info")
          break;
        case "requires_payment_method":
          setMessage("Merci d'effectué votre payement.");
          setTypeMessage("warning")
          break;
        default:

          break;
      }
    })
  }, [stripe])

  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!elements || !stripe) {
      return
    }

    localStorage.setItem("newBusiness", "1")
    await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_REDIRECT || "https://dashboard.tag.discount/login"
      }
    }).then((result: any) => {
      if (result.error.type === "card_error" || result.error.type === "validation_error") {
        setMessage(result.error.message || "");
      } else {
        setMessage("An unexpected error occurred.");
      }
    });
  }

  return (
    <Box>
      <form onSubmit={handleSubmit} className="card-content">
        <Card>
          <CardContent>
            <PaymentElement />
          </CardContent>
        </Card>
        <Box sx={{ mt: 3, textAlign: "center" }}>
          <Button
            variant="contained"
            type="submit"
            disabled={!stripe || !elements}
          >
            Enregistrer
          </Button>
        </Box>
        {message && <Alert sx={{ mt: 3 }} severity={typeMessage}>{message}</Alert>}
      </form>
    </Box>
  )
}
