import { Box, Button, Grid, Typography } from "@mui/material"
import { useState } from "react"
import Swal from "sweetalert2"
import { Lots_lots as Ilot } from "../../../../graphql/lots"
import { useDeleteLots, useLots } from "../../../../hooks"
import { CreateLot } from "./CreateLot"
import { LotCard } from "./LotCard"
import { ConfirmModal } from "@/components"

export const ManageLots = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteId, setDeleteId] = useState(0)
  const [editLot, setEditLot] = useState<Ilot>()
  const { data, refetch } = useLots()
  const { deleteLot } = useDeleteLots()

  const handleEditLot = (lot: Ilot) => {
    setEditLot(lot)
    setOpen(true)
  }
  const handleDeleteLot = (lot: Ilot) => {
    setOpenDelete(true)
    setDeleteId(lot.id)
  }
  const handleConfirmDelete = async () => {
    setOpenDelete(false)
    try {
      await deleteLot(deleteId)
      Swal.fire({
        icon: "success",
        title: "le lot a été supprimé",
        text: ""
      })
      refetch()
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "une erreur s'est produite",
        text: ""
      })
    }
  }
  const handleCancelDelete = () => {
    setOpenDelete(false)
    setDeleteId(0)
  }
  const handleAdd = () => {
    setEditLot(undefined)
    setOpen(true)
  }
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="subtitle1">Liste des lots</Typography>
        <Button variant="outlined" onClick={handleAdd} sx={{ color: "#050005" }}>
          Créer un lot
        </Button>
      </Box>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          {data?.lots.map((lot) => {
            return (
              <LotCard
                key={lot.id}
                lot={lot}
                onClickEdit={handleEditLot}
                onClickDelete={handleDeleteLot}
              />
            )
          })}
        </Grid>
      </Box>
      <CreateLot editLot={editLot} open={open} setOpen={setOpen} />
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de lot"
      >
        <Typography>Vous voulez vraiment supprimer ce lot ?</Typography>
      </ConfirmModal>
    </Box>
  )
}
