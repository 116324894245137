/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography, Button, FormControl, MenuItem } from "@mui/material"
import { useQuery } from "@apollo/client"
import { FC, useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import { Dropzone } from "@/components/dropzone"
import { SousCategoryInput } from "@/types"
import { BUSINESS_CATEGORY } from "@/graphql/business"
import { useApplicationContext } from "@/hooks"
import { useSousCategory } from "@/hooks/category"
import { useParams } from "react-router-dom"
import {
  CategoryAll,
  CategoryAllVariables
} from "@/graphql/business/types/CategoryAll"

interface ModalSubProps {
  setOpen: (b: boolean) => void
}

const ModalSubCategory: FC<ModalSubProps> = ({ setOpen }) => {
  const { id } = useParams()
  const { dispatchSnack, business } = useApplicationContext()
  const businessId = business?.id || parseInt(id || "0")
  const { createSousCategory } = useSousCategory()
  const { data, refetch } = useQuery<CategoryAll, CategoryAllVariables>(
    BUSINESS_CATEGORY,
    {
      variables: {
        businessId: businessId
      },
      skip: !businessId
    }
  )
  const [values, setValues] = useState<SousCategoryInput>({
    categoryIds: 0,
    name: "",
    image: "",
    status: true,
    businessId: business?.id || parseInt(id || "0")
  })
  const handleUpload = async (file?: string): Promise<void> => {
    if (file) {
      setValues((prev) => ({
        ...prev,
        image: file
      }))
    }
  }

  const confirmAdd = async () => {
    if (!values.businessId) {
      return
    }
    else if(!values.name || !values.image ){
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez completer les champs obligatoires"
      })
    }
    else if(!values.categoryIds){
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez selectionner une catégorie"
      })
    }
    else{
      const create = await createSousCategory(values)
      if (create.success) {
        dispatchSnack({
          open: true,
          severity: "success",
          message: "Ajouter avec succès"
        })
        refetch()
        setOpen(false)
      }
    }
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    
    setValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <Box>
      <Box>
        <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
          Séléctionner la catégorie
        </Typography>
        <FormControl fullWidth>
          <Select
            id="categoryIds"
            name="categoryIds"
            value={values.categoryIds}
            onChange={handleChange}
            onSelect={handleChange}
          >
            {data?.category
              ? data.category.map((category: any) => {
                  return (
                    <MenuItem value={category.id} key={category.id}>
                      {category.name}
                    </MenuItem>
                  )
                })
              : ""}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>Nom</Typography>
        <Box>
          <TextField
            name="name"
            variant="outlined"
            fullWidth
            value={values.name || ""}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <Typography sx={{ fontWeight: "bold", mt: 1, mb: 2 }}>
        Sous Category
      </Typography>
      <Box sx={{ pt: 2, pb: 4 }}>
        <Box
          sx={{
            width: "100%",
            height: 100,
            mr: 1,
            mb: 1,
            position: "relative"
          }}
        >
          <Dropzone
            onFinished={handleUpload}
            message="Ajouter PDF ou png ou JPEG"
            photo={values.image || ""}
            btnSx={{ height: "100%" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 2,
          gap: "10px"
        }}
      >
        <Button variant="outlined" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button type="submit" onClick={confirmAdd} variant="contained">
          Ajouter
        </Button>
      </Box>
    </Box>
  )
}

export default ModalSubCategory
