import { QueryResult } from "@/components"
import { Beenhere } from "@mui/icons-material"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Radio
} from "@mui/material"
import {
  ListAbonnement_listAbonnement,
  LIST_ABONNEMENT
} from "@/graphql/abonnement"
import { useQuery, useMutation } from "@apollo/client"
import { FormContainer } from "../components/FormContainer"
import { CREATE_CLIENT_SECRET } from "@/graphql/business"
import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react"

export const Abonnement = () => {
  const { id } = useParams()
  const [checked, setChecked] = useState("")
  const [aboId, setAboId] = useState(0)
  const { data, loading } = useQuery(LIST_ABONNEMENT)
  const [createClientSecret, { loading: loadSecret }] =
    useMutation(CREATE_CLIENT_SECRET)
  const navigate = useNavigate()
  const handleCreate = async (item: any) => {
    const create = await createClientSecret({
      variables: {
        aboId,
        businessId: parseInt(id || ""),
        amount: String(item)
      }
    })
    if (create) {
      localStorage.setItem(
        "client_secret",
        create.data?.createClintSecret.toString()
      )

      setTimeout(() => {
        navigate("/signup/payement")
      }, 1000)
    }
  }
  const handleNext = () => {
    handleCreate(checked)
  }
  return (
    <FormContainer
      step={9}
      title="Choisissez votre abonnement"
      disableBtn={checked === ""}
      loading={loadSecret}
      onNext={handleNext}
    >
      <QueryResult loading={loading} data={data}>
        <Grid container spacing={2} justifyContent="center" sx={{ mb: 3 }}>
          {data?.listAbonnement.map((item: ListAbonnement_listAbonnement) => {
            if (item.status)
              return (
                <Grid key={item.id} item md={4}>
                  <Card sx={{ borderRadius: 8 }}>
                    <CardContent>
                      <Box textAlign="center">
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ fontWeight: "bold" }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <p>{item.description}</p>
                      <Box pl={4} mb={3}>
                        {item.numberBooking ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> {item.numberBooking}{" "}
                            réservations
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.numberClick ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> {item.numberClick}{" "}
                            click'n collect
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.numberSubscription ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} />{" "}
                            {item.numberSubscription} inscriptions
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.prixEmail ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> le prix des emails{" "}
                            {item.prixEmail} €
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.prixSms ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> le prix des SMS{" "}
                            {item.prixSms} €
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.freeTrial ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> {item.percentOff}% de
                            réduction pendant {item.freeTrial} mois
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Typography
                        textAlign="center"
                        variant="h1"
                        component="div"
                        sx={{ fontWeight: "bold", mb: 4 }}
                      >
                        {item.prixAbonnement} €
                        <Typography component="sup"> HT/mois</Typography>
                      </Typography>
                      <Box textAlign="center">
                        <Button
                          variant="outlined"
                          onClick={() => {
                            localStorage.setItem("aboId", item.id.toString())
                            setAboId(item.id)
                            setChecked(item.priceStripeId || "")
                          }}
                          sx={{ color: "#050005" }}
                        >
                          <Radio
                            checked={checked === item.priceStripeId}
                            value={item.priceStripeId}
                            name="abonnement"
                          />
                          Choisir cet abonnement
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )
            return ""
          })}
        </Grid>
      </QueryResult>
    </FormContainer>
  )
}
