import { gql } from "@apollo/client"

export const QRCODES = gql`
  query Qrcodes($businessId: Float!) {
    qrcodes(businessId: $businessId) {
      id
      qrcodeImage
      logo
      textColor
      etablissement
      title
      subtitle1
      subtitle2
      background
      active
      scanTotal
      winnerTotal
      colorEmpty
      borderLine
      createdAt
      updatedAt
      businessId
      dateEnd
      urlRegle
      urlPreview
      qrcodeTitle
      templateSpinnerId
      reglementId
      lots {
        id
        image
        title
        color
        winFrequence
        description
        createdAt
      }
    }
  }
`

export const WINNERS = gql`
  query WinnerGames($skip: Float!, $limit: Float!, $qrcodeId: Float!) {
    winnerGames(skip: $skip, limit: $limit, qrcodeId: $qrcodeId) {
      id
      code
      active
      createdAt
      qRCodeId
      retrieve
      lots {
        id
        image
        title
      }
      clients {
        id
        email
        phone
        firstname
        lastname
        photo
      }
    }
  }
`

export const LOOSER_GAMES = gql`
  query LooserGames($skip: Float!, $limit: Float!, $qrcodeId: Float!) {
    looserGames(skip: $skip, limit: $limit, qrcodeId: $qrcodeId) {
      clientId
      createdAt
      clients {
        id
        lastname
        email
        firstname
        city
        address
        createdAt
        source
        status
        photo
        phone
      }
    }
  }
`

export const REGLEMENT = gql`
  query reglement($reglementId: Float!) {
    reglement(reglementId: $reglementId) {
      nom_du_jeu
      activite
      adresse_compelete
      capital
      date_end
      date_mise_en_ligne
      date_start
      nombre_de_lot
      numero_siret
      sites
      societe
      tagdiscount
      ville_rcs
    }
  }
`

export const REGLEMENT_UPDATE = gql`
  mutation updateReglement($reglement: ReglementInput!, $reglementId: Float!) {
    updateReglement(reglement: $reglement, reglementId: $reglementId) {
      nom_du_jeu
      activite
      adresse_compelete
      capital
      date_end
      date_mise_en_ligne
      date_start
      nombre_de_lot
      numero_siret
      sites
      societe
      tagdiscount
      ville_rcs
    }
  }
`

export const STATUS_GAME_USER = gql`
  query StatusGameUser($clientId: Float!, $limit: Float!) {
    statusGamesByUser(clientId: $clientId, limit: $limit) {
      createdAt
      id
      qRCodeId
      lots {
        title
        scan
      }
      clients {
        phone
        email
      }
    }
  }
`
