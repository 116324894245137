/* eslint-disable no-nested-ternary */
import { Box, Chip, Typography } from "@mui/material"
import { ChangeEvent, FC } from "react"
import Checkbox from "@mui/material/Checkbox"

interface CustomCheckboxDinerProps {
  title?: string
  subtitle?: string
  name: string
  current: boolean | null
  onChange: (name: string, checked: boolean | null) => void
}
export const CustomCheckboxDiner: FC<CustomCheckboxDinerProps> = ({
  title,
  subtitle,
  current,
  name,
  onChange
}): JSX.Element => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked, value } = e.target
    const val =
      checked && value === "OUI"
        ? true
        : checked && value === "NON"
        ? false
        : null
    onChange(name, val)
  }
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Box>
      <div>
        <Checkbox
          checked={current === true}
          name="oui"
          value="OUI"
          icon={<Chip label="Oui" variant="outlined" />}
          checkedIcon={<Chip label="Oui" color="primary" />}
          onChange={handleChange}
        />
        <Checkbox
          name="non"
          value="NON"
          checked={current === false}
          icon={<Chip label="Non" variant="outlined" />}
          checkedIcon={<Chip label="Non" color="primary" />}
          onChange={handleChange}
        />
      </div>
    </Box>
  )
}
