/* eslint-disable react/no-unescaped-entities */
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useParams } from "react-router-dom"
import { ChangeEvent, useState } from "react"
import {
  Box,
  Grid,
  Card,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  Avatar,
  RadioGroup
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { Dropzone } from "@/components/dropzone"
import { inputsValidation } from "@/utils"
import {
  useApplicationContext,
  useCurrentBusiness,
  useUserAction
} from "@/hooks"
import { CustomTextInput } from "@/components/forms/CustomTextInput"
import { TextInputBase, UserForm } from "@/components/forms"
import { AdminType, CreateUtilisateurInput } from "@/types"
import { CustomModal } from "@/components/modals"

export const EtablissementUsers = (): JSX.Element => {
  const { id } = useParams()
  const { business, dispatchSnack } = useApplicationContext()
  const { updateUser, loading, createUser, createLoading } = useUserAction()
  const [open, setOpen] = useState<boolean>(false)
  const { data, refetch } = useCurrentBusiness(
    business?.id || parseFloat(id as string)
  )
  const [passwordValues, setPasswordValues] = useState({
    password: "",
    oldPassword: "Iamadmin",
    confirm: ""
  })

  const handleSaveInfo = async (
    value: string,
    name: string,
    userId: number
  ): Promise<void> => {
    try {
      await updateUser({
        variables: {
          userId,
          data: {
            [name]: value
          }
        }
      })
      refetch()
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const addClick = () => {
    setOpen(!open)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleUploadPhoto = async (
    userId: number,
    file?: string
  ): Promise<void> => {
    try {
      await updateUser({
        variables: {
          userId,
          data: {
            photo: file
          }
        }
      })
      refetch()
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const handleReset = async (userId: number): Promise<void> => {
    try {
      const updatePassword = await updateUser({
        variables: {
          userId,
          data: {
            password: passwordValues.password
          }
        }
      })
      setPasswordValues({
        confirm: "",
        oldPassword: "",
        password: ""
      })
      if (updatePassword) {
        dispatchSnack({
          open: true,
          severity: "success",
          message: "Modification avec succes"
        })
      }
      refetch()
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const handleCreateUser = async (values: CreateUtilisateurInput) => {
    const create = await createUser({
      variables: {
        data: {
          businessId: business?.id || parseFloat(id as string),
          ...values
        }
      }
    }).catch(() => {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Cet adresse existe deja"
      })
    })
    if (create) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Ajouter avec succès"
      })
    }
    setOpen(false)
    refetch()
  }

  return (
    <>
      <CustomModal
        noAction
        title="Ajouter un utilisateur"
        open={open}
        setOpen={setOpen}
      >
        <UserForm
          loading={createLoading}
          onCreate={handleCreateUser}
          onCancel={() => setOpen(false)}
        />
      </CustomModal>

      <Box sx={{ p: 2, display: "flex", justifyContent: "end" }}>
        <Button
          variant="text"
          onClick={addClick}
          sx={{ textTransform: "none" }}
        >
          <AddIcon />
          Ajouter un utilisateur
        </Button>
      </Box>

      <Box sx={{ py: 2 }}>
        {data?.Utilisateur.map((user) => {
          return (
            <Accordion key={user.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel1a-content-${user.id}`}
                id={`panel1a-header-${user.id}`}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar alt="Remy Sharp" src={`${user.photo}`} />
                  <Typography sx={{ ml: 2 }}>
                    {user.firstname} {user.lastname} -{" "}
                    <Typography
                      component="span"
                      variant="subtitle2"
                      style={{ fontStyle: "italic" }}
                    >
                      {user.type === AdminType.BUSINESS_ADMIN
                        ? "Administrateur"
                        : "Utilisateur"}
                    </Typography>
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} key={user.id}>
                  <Grid item xs={12}>
                    <Card sx={{ p: 2 }} elevation={1}>
                      <Typography variant="subtitle1">
                        Photo de profil
                      </Typography>
                      <Box
                        sx={{
                          width: 200,
                          height: 200,
                          py: 1,
                          position: "relative"
                        }}
                      >
                        <Dropzone
                          onFinished={(file) =>
                            handleUploadPhoto(user.id, file)
                          }
                          btnSx={{ height: "100%" }}
                          message="Modifier Photo"
                          photo={user.photo || undefined}
                        />
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Card sx={{ p: 2 }} elevation={1}>
                      <Typography variant="subtitle1">Prénom</Typography>
                      <CustomTextInput
                        editable
                        nodivider
                        defaultValue={user.firstname || ""}
                        name="firstname"
                        onSave={(v, n) => handleSaveInfo(v, n, user.id)}
                      />
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Card sx={{ p: 2 }} elevation={1}>
                      <Box>
                        <Typography variant="subtitle1">Nom</Typography>
                        <CustomTextInput
                          nodivider
                          name="lastname"
                          onSave={(v, n) => handleSaveInfo(v, n, user.id)}
                          defaultValue={user?.lastname || ""}
                        />
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Card sx={{ p: 2 }} elevation={1}>
                      <Box>
                        <Typography variant="subtitle1">Email</Typography>
                        <CustomTextInput
                          nodivider
                          editable={false}
                          name="email"
                          onSave={(v, n) => handleSaveInfo(v, n, user.id)}
                          defaultValue={user.email || ""}
                        />
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Card sx={{ p: 2 }} elevation={3}>
                      <Box sx={{ my: 2 }}>
                        <Typography variant="subtitle1">Rôle</Typography>

                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label" />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={user.type}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              handleSaveInfo(e.target.value, "type", user.id)
                            }
                          >
                            <FormControlLabel
                              value="BUSINESS_ADMIN"
                              control={<Radio />}
                              label="Administrateur"
                            />
                            <FormControlLabel
                              value="BUSINESS_USER"
                              control={<Radio />}
                              label="Utilisateur"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant="subtitle1">
                        Réinitialiser mot de passe
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center"
                      }}
                    >
                      <Box sx={{ mr: 2, pb: 1 }}>
                        <Typography variant="subtitle1">
                          Nouveau mot de passe
                        </Typography>
                        <TextInputBase
                          name="password"
                          type="password"
                          value={passwordValues.password}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box sx={{ mr: 5, pb: 1 }}>
                        <Typography variant="subtitle1">
                          Confirmer nouveau mot de passe
                        </Typography>
                        <TextInputBase
                          name="confirm"
                          type="password"
                          value={passwordValues.confirm}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box>
                        <Button
                          disabled={
                            !inputsValidation([
                              passwordValues.confirm,
                              passwordValues.password
                            ]) ||
                            passwordValues.password !==
                              passwordValues.confirm ||
                            loading
                          }
                          variant="contained"
                          onClick={() => handleReset(user.id)}
                          startIcon={
                            loading ? (
                              <CircularProgress
                                style={{ width: 25, height: 25 }}
                              />
                            ) : null
                          }
                        >
                          Enregistrer
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Box>
    </>
  )
}
