import { BusinessInfo_businessInfo as IBusiness } from "@/graphql/business"
import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { businessMedia } from "@/utils/getSocialMedia"
import { businessMenu } from "@/utils/menuBottom"
import { ThemeInput } from "@/types"
import { TypographyUpdated } from "./TypographyUpdated"

interface FooterCommercantProps {
  theme: ThemeInput
  business: IBusiness
}
export const FooterCommercant: FC<FooterCommercantProps> = ({
  business,
  theme
}) => {
  const [medias, setMedias] = useState<any[]>([])
  const [menus, setMenus] = useState<any[]>([])

  useEffect(() => {
    const medis = businessMedia(business)
    const menis = businessMenu(business)

    setMedias(medis)
    setMenus(menis)
  }, [business])
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        mt: 5
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          mx: "auto",
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: 260
        }}
      >
        {medias.map(({ url, media, icon }) => {
          return (
            <Tooltip title={media} key={media}>
              <a href={url} target="_blank" rel="noreferrer">
                <IconButton
                  sx={{
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "white"
                    },
                    "& svg": {
                      fill: theme.mediaColor
                    }
                  }}
                >
                  {icon}
                </IconButton>
              </a>
            </Tooltip>
          )
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          mb: 2
        }}
      >
        {menus.map(({ url, menu }, index) => {
          return (
            <Box key={menu} sx={{ m: 1 }}>
              <Tooltip title={menu} key={menu}>
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline", color: "white" }}
                >
                  <TypographyUpdated theme={theme}>{menu}</TypographyUpdated>
                </a>
              </Tooltip>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
