import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  InputAdornment
} from "@mui/material"
import { ChangeEvent, FC, useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import { Dropzone } from "@/components/dropzone"
import { CustomModal, DatePickerBase } from "@/components"
import { EventPartyType } from "@/types"
import { useMutation, useQuery } from "@apollo/client"
import {
  CreateEventParty,
  CreateEventPartyVariables,
  CREATE_EVENT,
  GET_DETAILS_EVENT,
  UpdateEventParty,
  UpdateEventPartyVariables,
  UPDATE_EVENT
} from "@/graphql/eventParty"
import { useApplicationContext } from "@/hooks"
import { BUSINESS_GET_CLICK_BY_ID } from "@/graphql/business"
import TimePicker from "rc-time-picker"
import moment from "moment"
import "./style.css"
import Swal from "sweetalert2"

interface ModalEventProps {
  open: boolean
  setOpen: (b: boolean) => void
  businessId: number
  editedId?: number
  onClose: () => void
}

export const ModalEvent: FC<ModalEventProps> = ({
  open,
  businessId,
  setOpen,
  editedId,
  onClose
}): JSX.Element => {
  const { business ,dispatchSnack } = useApplicationContext()
  const now = new Date()
  const { data } = useQuery(GET_DETAILS_EVENT, {
    variables: {
      businessId,
      detailEventPartyId: editedId
    },
    skip: !editedId
  })
  const defaultValue = moment().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  })
  const [disabled, setDisabled] = useState(true)
  const [values, setValues] = useState<EventPartyType>({
    title: "",
    img: "",
    description: "",
    dateStart: now,
    dateEnd: now,
    hourStart: defaultValue,
    hourEnd: defaultValue,
    status: true,
    businessId: businessId,
    guest: 0,
    price: 0
  })
  const [loading, setLoading] = useState(false)
  const [currency, setCurrency] = useState({ name: "", currency: "" })
  const [saveEvent] = useMutation<CreateEventParty, CreateEventPartyVariables>(
    CREATE_EVENT
  )
  const [editEvent] = useMutation<UpdateEventParty, UpdateEventPartyVariables>(
    UPDATE_EVENT
  )
  const handleUpload = async (file?: string): Promise<void> => {
    if (file) {
      setValues((prev) => ({
        ...prev,
        img: file
      }))
    }
  }

  const { data: dataCurrency } = useQuery(BUSINESS_GET_CLICK_BY_ID, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })

  const handleSubmit = () => {    
    if(
    !values.title ||
    !values.img ||
    !values.description ||
    !values.dateStart ||
    !values.dateEnd ||
    !values.hourStart ||
    !values.hourEnd
    ){
      dispatchSnack({
        open: true,
        severity: "error",
        message:
          "Veuillez compléter les champs obligatoires"
      })
    }
    else if(!values.guest || !values.price){
      dispatchSnack({
        open: true,
        severity: "error",
        message:
          "Veuillez entrer un nombre valide sur le champ nombre d'invité et ou prix d'entré"
      })
    }
    else{
      setLoading(true)
      if (editedId) {
        editEvent({
          variables: {
            updateEventPartyId: editedId,
            data: {
              ...values,
              businessId
            }
          }
        }).then(() => {
          dispatchSnack({
            open: true,
            severity: "success",
            message:
              "Édition d'évènement avec succès"
          })
          setLoading(false)
          setOpen(false)
          onClose()
        })
        return
      }
      saveEvent({
        variables: {
          data: {
            ...values,
            businessId
          }
        }
      }).then(() => {
        Swal.fire({
          icon: "success",
          title: "Création d'évènement avec succès"
        })
        setLoading(false)
        setOpen(false)
        onClose()
      })
    }
  }

  const handleCancel = () => {
    setValues({
      title: "",
      img: "",
      description: "",
      dateStart: now,
      dateEnd: now,
      hourStart: "",
      hourEnd: "",
      status: true,
      businessId: businessId,
      guest: 0
    })
    setLoading(false)
    setOpen(false)
    onClose()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === "guest" || name === "price") {
      setValues((prev) => ({
        ...prev,
        [name]: Number(value)
      }))

      return
    }

    setValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const currencyList = [
    {
      name: "euro",
      currency: "€"
    },
    {
      name: "dollars",
      currency: "$"
    },
    {
      name: "franc",
      currency: "CHF"
    },
    {
      name: "roupie",
      currency: "RS"
    }
  ]

  useEffect(() => {
    if (data) {
      const dataEvent = data.detailEventParty
      setValues({
        title: dataEvent.title,
        img: dataEvent.img,
        description: dataEvent.description,
        dateStart: dataEvent.dateStart,
        dateEnd: dataEvent.dateEnd,
        hourStart: moment(dataEvent.hourStart),
        hourEnd: moment(dataEvent.hourEnd),
        status: dataEvent.status,
        businessId: businessId,
        guest: dataEvent.guest,
        price: dataEvent.price
      })
    } else {
      setValues({
        title: "",
        img: "",
        description: "",
        dateStart: now,
        dateEnd: now,
        hourStart: defaultValue,
        hourEnd: defaultValue,
        status: true,
        businessId: businessId,
        guest: 0
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, data, editedId])
  useEffect(() => {
    const currencyType = currencyList.find(
      (item) => item.name === dataCurrency?.clickCollectById?.currency
    )
    setCurrency(currencyType || { name: "", currency: "" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurrency?.clickCollectById?.currency])
  return (
    <CustomModal
      noAction
      title={editedId ? "Modifier l'événement" : "Créer un événement"}
      open={open}
      setOpen={setOpen as any}
      fullWidth
    >
      <Box>
        <Box>
          <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
            Titre de l'événement
          </Typography>
          <Box>
            <TextField
              name="title"
              variant="outlined"
              fullWidth
              value={values.title || ""}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
            Image
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: 100,
              mr: 1,
              mb: 1,
              position: "relative"
            }}
          >
            <Dropzone
              onFinished={handleUpload}
              message="Ajouter PDF ou png ou JPEG"
              photo={values.img || ""}
              btnSx={{ height: "100%" }}
            />
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Début de l'événement
            </Typography>
            <DatePickerBase
              format="DD MMMM YYYY"
              value={values.dateStart || now}
              onChange={(e) => {
                setDisabled(false)
                setValues((item) => ({
                  ...item,
                  dateStart: e
                }))
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Fin de l'événement
            </Typography>
            <DatePickerBase
              format="DD MMMM YYYY"
              value={values.dateEnd || now}
              onChange={(e) => {
                setValues((item) => ({
                  ...item,
                  dateEnd: e
                }))
              }}
              minDate={values.dateStart}
              disabled={disabled}
            />
          </Grid>
          <Grid item md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Heure de début
            </Typography>
            <TimePicker
              showSecond={false}
              value={values.hourStart}
              name="start"
              allowEmpty={false}
              onChange={(e) => {
                setValues((item) => ({
                  ...item,
                  hourStart: e
                }))
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Heure de fin
            </Typography>
            <TimePicker
              showSecond={false}
              value={values.hourEnd}
              name="start"
              allowEmpty={false}
              onChange={(e) => {
                setValues((item) => ({
                  ...item,
                  hourEnd: e
                }))
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Nombre d'invités possibles
            </Typography>
            <TextField
              name="guest"
              variant="outlined"
              type="number"
              fullWidth
              value={values.guest || null}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Prix de l'entrée
            </Typography>
            <TextField
              name="price"
              variant="outlined"
              type="number"
              fullWidth
              value={values.price || null}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currency.currency}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Box>
          <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
            Description
          </Typography>
          <TextField
            name="description"
            variant="outlined"
            fullWidth
            multiline
            value={values.description || ""}
            onChange={handleChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
            mb: 2,
            gap: "10px"
          }}
        >
          <Button variant="outlined" onClick={handleCancel}>
            Annuler
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleSubmit}
            startIcon={
              loading ? (
                <CircularProgress style={{ width: 25, height: 25 }} />
              ) : null
            }
          >
            Enregistrer
          </Button>
        </Box>
      </Box>
    </CustomModal>
  )
}
export default ModalEvent
