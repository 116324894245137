/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useMutation } from "@apollo/client"
import {
  Box,
  TextField,
  styled,
  Divider,
  Typography,
  IconButton
} from "@mui/material"
import { FC, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import usePlacesAutocomplete from "use-places-autocomplete"
import Edit from "@mui/icons-material/Edit"
import {
  UpdateBusinessInfo,
  UpdateBusinessInfoVariables,
  UPDATE_BUSINESS_INFO
} from "../../../../graphql/business"
import { FormContainer } from "../components/FormContainer"
import { ResearchBox } from "../../../../components"
import { storage } from "../../../../utils"

const SuggestionItem = styled(Box)(() => ({
  "&:hover": {
    background: "#0001"
  }
}))

interface SignupStepProps {
  afterSave?: (isFood: boolean) => void
}
export const Address: FC<SignupStepProps> = ({ afterSave }): JSX.Element => {
  const [updateInfo, { loading }] = useMutation<
    UpdateBusinessInfo,
    UpdateBusinessInfoVariables
  >(UPDATE_BUSINESS_INFO)
  const { id } = useParams()
  const [address, setAddress] = useState("")
  const [newId, setNewId] = useState<number>()
  const [isFoodServices, setIsFoodServices] = useState(false)
  const [siret, setSiret] = useState<string>("")
  const navigate = useNavigate()

  const handleCreate = async (): Promise<void> => {
    try {
      const create = await updateInfo({
        variables: {
          businessId: parseFloat(id as string) || (newId as number),
          business: {
            address,
            siret
          }
        }
      })
      if (create.data?.updateBusinessInfo) {
        if (isFoodServices) {
          if (afterSave) {
            afterSave(true)
          } else {
            navigate(`/signup/diner/${create.data.updateBusinessInfo.id}`)
          }
        } else if (afterSave && !isFoodServices) {
          afterSave(false)
        } else {
          navigate(`/signup/description/${create.data.updateBusinessInfo.id}`)
        }
      }
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300
  })

  const loadLocal = useCallback(() => {
    const nid = storage.get("new_Id")
    setNewId(parseFloat(nid))
    const infos = storage.get("step_2_tag")
    if (infos?.activityType === "FOOD_SERVICES") {
      setIsFoodServices(true)
    }
  }, [])
  useEffect(() => {
    loadLocal()
  }, [loadLocal])

  const handleInput = (valueText: string): void => {
    // Update the keyword of the input element
    setValue(valueText)
  }

  const SelectPlaces = (places: any): void => {
    setAddress(places.description)
    clearSuggestions()
  }
  return (
    <FormContainer
      step={3}
      loading={loading}
      title="Informations de votre établissement"
      onNext={handleCreate}
    >
      <Box sx={{ py: 2 }}>
        <Box>
          <div>
            {address ? (
              <Box sx={{ display: "flex" }}>
                <Typography variant="subtitle2">Adresse : {address}</Typography>
                <IconButton onClick={() => setAddress("")}>
                  <Edit />
                </IconButton>
              </Box>
            ) : (
              <ResearchBox
                placeholder="Ex : 55 rue de belle..."
                onChange={handleInput}
                startLabel="Adresse"
              />
            )}
            {status === "OK" ? (
              <Box sx={{ p: 1 }}>
                {data.map((suggestion) => {
                  const {
                    place_id,
                    structured_formatting: { main_text, secondary_text }
                  } = suggestion

                  return (
                    <SuggestionItem
                      key={place_id}
                      onClick={() => {
                        SelectPlaces(suggestion)
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography variant="subtitle2">
                        {main_text}, {secondary_text}
                      </Typography>
                      <Divider />
                    </SuggestionItem>
                  )
                })}
              </Box>
            ) : null}
          </div>
        </Box>
        <Box sx={{ py: 1 }}>
          <TextField
            fullWidth
            label="Siret"
            variant="outlined"
            name="siret"
            value={siret}
            onChange={(e) => {
              setSiret(e.target.value)
            }}
          />
        </Box>
      </Box>
    </FormContainer>
  )
}
