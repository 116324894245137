/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton } from "@mui/material"
import { FC, SyntheticEvent, useEffect, useState } from "react"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import { BaseTable } from "../../../../components"
import { useClientComponent } from "./components/useClientComponent"
import { useLoosers } from "@/hooks/winners/useLooser"
import { useLooserColumn } from "./components/useLooserColumn"

// const ButtonTop = styled(Button)(() => ({
//   textTransform: "none",
//   marginLeft: 15
// }))
interface ListLooserProps {
  qrcodeId: number
}

export const ListLooser: FC<ListLooserProps> = ({ qrcodeId }) => {
  // const navigate = useNavigate()
  const { loading, getLooser, refetch } = useLoosers()
  const [winners, setWinners] = useState<any[]>([])

  const [page, setPage] = useState<number>(1)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)

  const { columns } = useLooserColumn()

  const load = async () => {
    const wins = await getLooser(qrcodeId, 100, 0)
    setWinners(wins)
  }
  useEffect(() => {
    if (qrcodeId) {
      load()
    }
  }, [qrcodeId])

  const handleRefetch = async (e: SyntheticEvent) => {
    e.preventDefault()
    setIsRefresh(true)
    setWinners([])
    setTimeout(async () => {
      const data = await refetch({
        limit: 101,
        skip: 0,
        qrcodeId: qrcodeId
      })
      if (data.data.looserGames) {
        setIsRefresh(false)
        setWinners(data.data.looserGames)
      }
    }, 1000)
  }
  return (
    <Box>
      <Box sx={{ py: 2 }}>
        <BaseTable
          title="Liste des perdants"
          loading={loading || isRefresh}
          columns={columns}
          data={winners}
          page={page - 1}
          topComponent={
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              {/* <Typography variant="subtitle2">
                {checkeds.length} séléctionné(s)
              </Typography>
              <ButtonTop variant="text" startIcon={<MarkEmailReadIcon />}>
                {checkeds.length ? (
                  "Envoyer email marketing"
                ) : (
                  <Tooltip
                    arrow
                    title="Veuillez séléctionner un ou plusieurs client(s) pour envoyer un email marketing"
                  >
                    <span>Envoyer email marketing</span>
                  </Tooltip>
                )}
              </ButtonTop>
              <ButtonTop variant="text" startIcon={<ImportExportIcon />}>
                Importer *.csv
              </ButtonTop>
              <ButtonTop variant="text" startIcon={<Add />}>
                Ajouter un client
              </ButtonTop> */}
              <IconButton onClick={handleRefetch}>
                <RotateLeftIcon />
              </IconButton>
            </Box>
          }
        />
      </Box>
      {/* <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button style={{ textTransform: "none" }} variant="contained">
          Voir tous les gagnants
        </Button>
      </Box> */}
    </Box>
  )
}
