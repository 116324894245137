import { TabsWithRoutes, TabsWithRoutesProps } from "@/components"
import { Box } from "@mui/material"
import { ListAbonnement } from "../admin/Abonnement/ListAbonnement"
import { AbonnementInfo } from "./AbonnementInfo"

export const AbonnementBoard = () => {
  const tabs: TabsWithRoutesProps["tabs"] = [
    {
      component: <AbonnementInfo />,
      id: "1",
      label: "Résumé de votre abonnement",
      index: true
    },
    {
      component: <ListAbonnement />,
      id: "2",
      label: "Les offres d'abonnements",
      path: "list_abonnement"
    }
  ]
  return (
    <Box sx={{ p: 2 }}>
      <TabsWithRoutes tabs={tabs} pathBase="" />
    </Box>
  )
}
