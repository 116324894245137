/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete, Edit } from "@mui/icons-material"
import Visibility from "@mui/icons-material/Visibility"
import { Typography, Box } from "@mui/material"
import moment from "moment"
import { TableColumnProps, ButtonLittleIcon } from "@/components"
import { Qrcodes_qrcodes as IQrcode } from "@/graphql/qrcode"
import { useApplicationContext, useCurrentBusiness } from "@/hooks"

interface useCLientColumnsProps {
  onView?: (b: IQrcode) => void
  onEdit?: (b: IQrcode) => void
  onRemove?: (id: number) => void
}

export const useQrcodeList = ({
  onView,
  onEdit,
  onRemove
}: useCLientColumnsProps): { columns: TableColumnProps[] } => {
  const { business } = useApplicationContext()
  const { data: businessData } = useCurrentBusiness(business?.id)
  const columns: TableColumnProps[] = [
    {
      id: "image",
      label: "QR Code ",
      component: (data: IQrcode) => (
        <Box>
          <img
            src={data.qrcodeImage || ""}
            alt="qrcode"
            width={120}
            height={120}
          />
        </Box>
      )
    },
    {
      id: "title",
      label: "Titre du Jeu",
      component: (data: IQrcode) => (
        <Box>
          <Typography>{data.qrcodeTitle}</Typography>
        </Box>
      )
    },
    {
      id: "url_game",
      label: "Lien du jeu",
      component: (data: IQrcode) => (
        <Box>
          <a
            href={`https://${businessData?.url}/games/${data.id}`}
            onClick={() =>
              window.open(
                `https://${businessData?.url}/games/${data.id}`,
                "_blank"
              )
            }
          >
            {`https://${businessData?.url}/games/${data.id}`}
          </a>
        </Box>
      )
    },
    {
      id: "date_debut",
      label: "Date de création",
      component: (data: IQrcode) => (
        <Box>
          <Typography>
            {moment(data.createdAt).format("DD MMMM YYYY")}
          </Typography>
        </Box>
      )
    },
    {
      id: "date_fin",
      label: "Date de fin",
      component: (data: IQrcode) => (
        <Box>
          {data.dateEnd ? (
            <Typography>
              {moment(data.dateEnd).format("DD MMMM YYYY")}
            </Typography>
          ) : null}
        </Box>
      )
    },

    {
      id: "action",
      label: "",
      component: (data: IQrcode) => (
        <Box>
          <ButtonLittleIcon
            icon={<Visibility />}
            color="info"
            sx={{ mr: 1 }}
            onClick={() => {
              if (onView) {
                onView(data)
              }
            }}
          />
          <ButtonLittleIcon
            icon={<Delete />}
            color="error"
            onClick={() => {
              if (onRemove) {
                onRemove(data.id)
              }
            }}
          />
        </Box>
      )
    }
  ]
  return { columns }
}
