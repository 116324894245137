import { gql } from "@apollo/client";

export const VALIDATE_BOOKING = gql`
  mutation ValidateReservation($reservationId: Float!) {
    ValidateReservation(reservationId: $reservationId) {
      id
      email
      message
      name
      person
      phone
      status
      createdAt
      updatedAt
      date
      clientId
      businessId
    }
  }
`

export const REJECT_BOOKING = gql`
  mutation RejectNewReservation($reservationId: Float!) {
    rejectNewReservation(reservationId: $reservationId) {
      businessId
      clientId
      createdAt
      date
      email
      id
      message
      name
      person
      phone
      status
      updatedAt
    }
  }
`