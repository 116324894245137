/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
import { Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useState } from "react"
import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useApplicationContext } from "@/hooks"
import { useDeleteSousCategory } from "@/hooks/category"

import { ConfirmModal } from "@/components"
import { useCurrentCategoryById } from "@/hooks/useCurrentCategoryById"

export const SousCategory = () => {
  const { dispatchSnack } = useApplicationContext()
  const { idCategory } = useParams()
  const { categorySousDelete } = useDeleteSousCategory()
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [sousCategoryIds, setSousCategoryIds] = useState<number>()

  const { refetch } = useCurrentCategoryById(parseFloat(idCategory as string))

  const handleCancelDelete = () => {
    setSousCategoryIds(undefined)
    setOpenDelete(false)
  }

  const handleConfirmDelete = async () => {
    if (!sousCategoryIds) {
      setOpenDelete(false)
      return
    }
    const deleteSousCategory = await categorySousDelete(sousCategoryIds)
    if (deleteSousCategory) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "suppression avec succès"
      })
    }
    refetch()
    setSousCategoryIds(undefined)
    setOpenDelete(false)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right">image</TableCell>
              <TableCell align="right">Catégorie principale</TableCell>
              <TableCell align="right">nom sous-category</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Vous voulez vraiment supprimer ce sous category ?"
      >
        <Typography>Vos sous category sont précieux !</Typography>
      </ConfirmModal>
    </>
  )
}
