/* eslint-disable @typescript-eslint/no-explicit-any */
import ImportExportIcon from "@mui/icons-material/ImportExport"
import {
  Box,
  Typography,
  Button,
  Pagination,
  styled,
  IconButton,
  LinearProgress
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import Add from "@mui/icons-material/Add"
import { useNavigate, useParams } from "react-router-dom"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import { configPagination } from "@/components/tables/config"
import { BaseTable, ConfirmModal, CustomModal, ImportFiles } from "@/components"
import {
  useApplicationContext,
  useCreateClient,
  useCurrentBusinessClient,
  useImportFile,
  useUpdateClient
} from "@/hooks"
import { BusinessClients_businessClients as IClient } from "@/graphql/business"
import { CreateClientInput, StatusEnumType, StatusType } from "@/types"
import { NoClient } from "@/components/layouts/NoClient/NoClient"
import useSimpleDebounce from "@/hooks/useSimpleDebounce"
import { useClientComponent } from "./components/useClientComponent"
import { AddContact } from "./components/Contact"

const ButtonTop = styled(Button)(({ theme }) => ({
  textTransform: "none",
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    marginBottom: 5,
    border: "1px solid",
    width: "100%"
  },
  [theme.breakpoints.up("md")]: {
    marginLeft: 15
  }
}))
export const Clients = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [importFile, setImportFile] = useState<boolean>(false)
  const [importing, setImporting] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [toDeleteId, setToDeleteId] = useState<number>()
  const [page, setPage] = useState<number>(1)
  const [clientList, setClientList] = useState<IClient[]>([])
  const { business, dispatchSnack } = useApplicationContext()
  const [searchValue] = useState<string>()
  const [isRefresh, setIsRefresh] = useState<boolean>(false)

  const searchDebounced = useSimpleDebounce(searchValue, 500)

  const runSearchApi = useCallback(() => {
    if (searchDebounced) {
      // logic search
    }
  }, [searchDebounced])

  useEffect(() => {
    runSearchApi()
  }, [runSearchApi])

  const { importClientCSV } = useImportFile()
  const { deleteClient, deleteLoading, updateClient } = useUpdateClient()
  const { createClient, loading: createLoading } = useCreateClient()

  const { data, loading, refetch } = useCurrentBusinessClient(
    business?.id || parseFloat(id as string)
  )

  const handleView = (b: IClient) => {
    navigate(`${b.id}`)
  }

  const handleStatusUpdate = async (clientId: number, status: StatusType) => {
    await updateClient(clientId, {
      status: status as unknown as StatusEnumType
    })
    refetch()
  }

  const handleDelete = (idclient: number) => {
    setOpenDelete(true)
    setToDeleteId(idclient)
  }

  const handleCancelDelete = () => {
    setToDeleteId(undefined)
    setOpenDelete(false)
  }
  const handleConfirmDelete = async () => {
    if (!toDeleteId) {
      setOpenDelete(false)
      return
    }
    const deleteClients = await deleteClient(toDeleteId)
    if (deleteClients) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "suppression avec succès"
      })
    }
    refetch()
    setToDeleteId(undefined)
    setOpenDelete(false)
  }

  const { columns } = useClientComponent({
    onView: handleView,
    onRemove: handleDelete,
    onChangeStatus: handleStatusUpdate
  })

  const handleAdd = () => {
    if (business?.id || parseFloat(id as string)) {
      setOpen(true)
    }
  }
  const confirmAdd = async (values: CreateClientInput) => {
    const businessId = business?.id || parseFloat(id as string)
    if (!businessId) {
      return
    }
    try {
      const create = await createClient(businessId, values)
      if (create.success) {
        dispatchSnack({
          open: true,
          severity: "success",
          message: "Ajouter avec succès"
        })
        refetch()
      } else {
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Les identifiants que vous avez entrer existe déja"
        })
      }
    } finally {
      setOpen(false)
    }
  }
  const handleFinishedImport = async (datas: any) => {
    setImportFile(false)
    if (!business?.id && !parseFloat(id as string)) {
      return
    }
    setImporting(true)
    await importClientCSV(business?.id || parseFloat(id as string), datas)
    setImporting(false)
    refetch()
  }
  useEffect(() => {
    if (data) {
      setClientList(data)
    }
  }, [data])

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const perPage = configPagination.rowPerPage
  const count = configPagination.count(data, perPage)

  return (
    <Box>
      {importing ? (
        <Box sx={{ textAlign: "center", p: 4 }}>
          <Typography sx={{ p: 2 }} variant="subtitle1">
            Veuillez patienter quelques secondes, enregistrement de vos
            clients...
          </Typography>
          <LinearProgress variant="buffer" sx={{ height: 2 }} />
          <LinearProgress />
          <LinearProgress variant="buffer" sx={{ height: 2 }} />
        </Box>
      ) : null}

      <Box
        sx={{
          p: 2
        }}
      >
        <BaseTable
          indexed="id"
          title="Gérer les clients"
          loading={loading || isRefresh}
          columns={columns}
          data={clientList || []}
          page={page - 1}
          perPage={perPage}
          emptyComponent={
            <NoClient
              onAdd={handleAdd}
              onImport={() => setImportFile(!importFile)}
            />
          }
          topComponent={
            <Box
              sx={{
                p: { xs: 0, md: 2 },
                display: { xs: "block", md: "flex" },
                alignItems: "center"
              }}
            >
              <ButtonTop
                variant="text"
                startIcon={<Add />}
                onClick={() => handleAdd()}
              >
                Créer un contact
              </ButtonTop>
              <IconButton
                onClick={() => {
                  setClientList([])
                  setIsRefresh(true)
                  setTimeout(async () => {
                    const data = await refetch()
                    setIsRefresh(false)
                    setClientList(data.data.businessClients)
                  }, 1000)
                }}
              >
                <RotateLeftIcon />
              </IconButton>
            </Box>
          }
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
        <Pagination
          color="primary"
          page={page}
          count={count}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
      </Box>
      <AddContact
        open={open}
        loading={createLoading}
        setOpen={setOpen}
        onAdd={confirmAdd}
      />
      <ConfirmModal
        open={openDelete}
        loading={deleteLoading}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Confirmation de suppression de contact"
      >
        <Typography>Voulez-vous vraiment supprimer ce contact ?</Typography>
      </ConfirmModal>
      <CustomModal
        noAction
        fullscreen
        btnClose
        open={importFile}
        setOpen={setImportFile}
        title="Importer des contacts (Fichier *.csv)"
      >
        <ImportFiles onFinish={handleFinishedImport} />
      </CustomModal>
    </Box>
  )
}
