import {
  Bolt,
  Message,
  Group,
  Send,
  LockOpen,
  Mouse
} from "@mui/icons-material"
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  useTheme,
  Divider,
  TextField,
  Button,
  Select,
  MenuItem,
  Tabs,
  Tab
} from "@mui/material"
import {
  ChangeEvent,
  useEffect,
  useState,
  SyntheticEvent,
  ReactNode
} from "react"
import { useApplicationContext } from "@/hooks"
import { useForm } from "react-hook-form"
import { useParams, useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import { CAMPAIGN_AUTO } from "@/graphql/business"
import { GET_LIST_SENDER } from "@/graphql/emailing"
import {
  CampaignAutoResolver,
  CampaignAutoResolverVariables
} from "@/graphql/business/types/CampaignAutoResolver"
import { SwitchStyle, BaseTable } from "@/components"
import { SelectChangeEvent } from "@mui/material/Select"
import { TableResult } from "./component/TableResult"
import Swal from "sweetalert2"

interface TabPanelProps {
  children?: ReactNode
  i: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, i, value, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== i}
      id={`simple-tabpanel-${i}`}
      aria-labelledby={`simple-tab-${i}`}
      {...other}
    >
      {value === i && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(i: number) {
  return {
    id: `simple-tab-${i}`,
    "aria-control": `simple-tabpanel-${i}`
  }
}

export const EditCampaign = () => {
  const navigate = useNavigate()
  const { type } = useParams()
  const [typeCampaign, setTypeCampaign] = useState("")
  const [html, setHtml] = useState("")
  const [status, setStatus] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [idTemplate, setIdTemplate] = useState<number | null>()
  const [loading] = useState(false)
  const { templateState, setTemplateState, business } = useApplicationContext()
  const [templateLocal, setTemplateLocal] = useState<string | undefined>("")
  const [saveCampaign] = useMutation<
    CampaignAutoResolver,
    CampaignAutoResolverVariables
  >(CAMPAIGN_AUTO)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const templateHtml = () => {
    setHtml(localStorage.getItem("templateCampagne") || "")
  }
  const { data: listSender } = useQuery(GET_LIST_SENDER, {
    variables: {
      status: true
    }
  })
  const theme = useTheme()
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setTemplateState((prev: any) => ({
      ...prev,
      [name]: value
    }))
  }
  const handleChangeTab = (e: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }
  const handleSelect = (e: SelectChangeEvent<string>) => {
    setTemplateState((prev: any) => ({
      ...prev,
      email: e.target.value
    }))
  }
  const submitCampaign = async () => {
    await saveCampaign({
      variables: {
        businessId: business?.id || 0,
        data: {
          type: typeCampaign,
          name: templateState?.name,
          subject: templateState?.subject,
          nameTemplate: templateState?.nameTemplate,
          template: templateLocal,
          idSendinblue: idTemplate,
          templateId: idTemplate,
          email: templateState?.email,
          status
        }
      }
    })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Votre modification a été enregistré",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
        navigate(-1)
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Erreur d'enregistrement",
          text: "",
          showCloseButton: true,
          showConfirmButton: false
        })
      })
  }
  const handleStatus = (): void => {
    setStatus(!status)
  }
  const { column } = TableResult()

  useEffect(() => {
    setTemplateLocal(templateState?.template)
  }, [])

  useEffect(() => {
    switch (type) {
      case "new-user":
        console.log("business :", business?.campaignAuto)
        const infoNew = business?.campaignAuto?.find(
          (item) => item.type === "NEWUSER"
        )
        setTypeCampaign("NEWUSER")
        setStatus(infoNew?.status || false)
        setIdTemplate(infoNew?.idSendinblue || null)
        setTemplateState((prev) => ({
          ...prev,
          email: infoNew?.email || templateState?.email,
          name: infoNew?.name || templateState?.name,
          subject: infoNew?.subject || templateState?.subject,
          nameTemplate: infoNew?.nameTemplate || templateState?.nameTemplate,
          template: infoNew?.template || html
        }))
        setTemplateLocal(infoNew?.template || templateState?.template)
        break
      case "inactive-user":
        const infoInactive = business?.campaignAuto?.find(
          (item) => item.type === "INACTIVEUSER"
        )
        setTypeCampaign("INACTIVEUSER")
        setStatus(infoInactive?.status || false)
        setIdTemplate(infoInactive?.idSendinblue || null)
        setTemplateState((prev) => ({
          ...prev,
          email: infoInactive?.email || templateState?.email,
          name: infoInactive?.name || templateState?.name,
          subject: infoInactive?.subject || templateState?.subject,
          nameTemplate:
            infoInactive?.nameTemplate || templateState?.nameTemplate,
          template: infoInactive?.template || html
        }))
        setTemplateLocal(infoInactive?.template || templateState?.template)
        break
      case "undelivery":
        const infoUndelivery = business?.campaignAuto?.find(
          (item) => item.type === "UNDELIVERY"
        )
        setTypeCampaign("UNDELIVERY")
        setStatus(infoUndelivery?.status || false)
        setIdTemplate(infoUndelivery?.idSendinblue || null)
        setTemplateState((prev) => ({
          ...prev,
          email: infoUndelivery?.email || templateState?.email,
          name: infoUndelivery?.name || templateState?.name,
          subject: infoUndelivery?.subject || templateState?.subject,
          nameTemplate:
            infoUndelivery?.nameTemplate || templateState?.nameTemplate,
          template: infoUndelivery?.template || html
        }))
        setTemplateLocal(infoUndelivery?.template || templateState?.template)
        break
    }
  }, [type])

  useEffect(() => {
    templateHtml()
  }, [templateHtml])

  return (
    <Box sx={{ p: 3 }}>
      <Box>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant="h2">
                {type === "new-user" && "Accueillir les nouveaux"}
                {type === "inactive-user" && "Rattraper les clients inactif"}
                {type === "undelivery" && "Relancer les clients gagnants"}
              </Typography>
              <Typography>
                {type === "new-user" &&
                  "Message d'accueil des nouveaux arrivant"}
                {type === "inactive-user" &&
                  "Ne perdez plus aucun clients en envoyant un message a ceux qui ne sont pas venus depuis longtemps"}
                {type === "undelivery" &&
                  "Relancer les clients qui ont gagnes et qui n'ont pas encore recuperer leur prix"}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center"
                }}
              >
                <SwitchStyle textActive status={status} change={handleStatus} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Card>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Avatar
                      sx={{
                        margin: "auto",
                        bgcolor: theme.palette.primary.main
                      }}
                    >
                      <Bolt />
                    </Avatar>
                  </Box>
                  <Box sx={{ flexGrow: 3, ml: 2, minHeight: 70 }}>
                    <Typography variant="h5">Déclencheur</Typography>
                    <Typography>
                      {type === "new-user" &&
                        "Les clients qui viennent de s'inscrire dans la boutique ou dans les jeux"}
                      {type === "inactive-user" &&
                        "Les clients n'ayant aucune activite depuis 30jours"}
                      {type === "undelivery" &&
                        "Relancer les clients qui ont gagnes et qui n'ont pas encore recuperer leur prix"}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Avatar
                      sx={{
                        margin: "auto",
                        bgcolor: theme.palette.primary.main
                      }}
                    >
                      <Message />
                    </Avatar>
                  </Box>
                  <Box sx={{ flexGrow: 3, ml: 2, minHeight: 70 }}>
                    <Typography variant="h5">Titre template</Typography>
                    <Typography>{templateState?.nameTemplate}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Avatar
                      sx={{
                        margin: "auto",
                        bgcolor: theme.palette.primary.main
                      }}
                    >
                      <Group />
                    </Avatar>
                  </Box>
                  <Box sx={{ flexGrow: 3, ml: 2, minHeight: 70 }}>
                    <Typography variant="h5">Destinataire</Typography>
                    <Typography>Clients de la boutique</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2 }}>
          <Tabs value={currentTab} onChange={handleChangeTab}>
            <Tab label="Message" {...a11yProps} />
            <Tab label="Resultats" {...a11yProps} />
          </Tabs>
        </Box>

        <CustomTabPanel value={currentTab} i={0}>
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item md={4}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      display: "inline-block"
                    }}
                  >
                    Information générales
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", mt: 1 }}
                    >
                      Infos expéditeur
                    </Typography>
                    <Select
                      value={templateState?.email}
                      fullWidth
                      name="sender"
                      onChange={handleSelect}
                    >
                      {listSender?.getListSender.map(
                        (item: any, index: number) => (
                          <MenuItem key={index} value={item.email}>
                            {item.name} ({item.email})
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", mt: 1 }}
                    >
                      Nom de l'éxpéditeur
                    </Typography>
                    <TextField
                      fullWidth
                      value={templateState?.name}
                      variant="outlined"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Ce champ est requis"
                        }
                      })}
                      error={errors.name && true}
                      helperText={errors.name?.message as String}
                      name="name"
                      onChange={handleChange}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", mt: 1 }}
                    >
                      Sujet du mail
                    </Typography>
                    <TextField
                      fullWidth
                      value={templateState?.subject}
                      variant="outlined"
                      {...register("subject", {
                        required: {
                          value: true,
                          message: "Ce champ est requis"
                        }
                      })}
                      error={errors.subject && true}
                      helperText={errors.subject?.message as String}
                      name="subject"
                      onChange={handleChange}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", mt: 1 }}
                    >
                      Nom du template
                    </Typography>
                    <TextField
                      fullWidth
                      value={templateState?.nameTemplate}
                      variant="outlined"
                      {...register("nameTemplate", {
                        required: {
                          value: true,
                          message: "Ce champ est requis"
                        }
                      })}
                      error={errors.nameTemplate && true}
                      helperText={errors.nameTemplate?.message as String}
                      name="nameTemplate"
                      onChange={handleChange}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "bold", mt: 1 }}
                    >
                      Template du mail
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => navigate(`../template/${type}`)}
                    >
                      Créer le template
                    </Button>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 3 }}
                  >
                    <Button variant="outlined" sx={{ mr: 2 }}>
                      Annuler
                    </Button>
                    <Button
                      disabled={loading}
                      variant="contained"
                      onClick={handleSubmit(submitCampaign)}
                    >
                      {loading ? "Sauvegarde en cours" : "Enregistrer"}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={8}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      display: "inline-block"
                    }}
                  >
                    Prévisualisation
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <iframe
                    srcDoc={templateLocal}
                    frameBorder={0}
                    style={{ minHeight: 450, width: "100%" }}
                  ></iframe>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} i={1}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Card>
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Avatar
                        sx={{
                          margin: "auto",
                          bgcolor: theme.palette.primary.main
                        }}
                      >
                        <Send />
                      </Avatar>
                    </Box>
                    <Box sx={{ flexGrow: 3, ml: 2, minHeight: 70 }}>
                      <Typography
                        variant="h5"
                        sx={{ textTransform: "uppercase" }}
                      >
                        Envoyé
                      </Typography>
                      <Typography variant="h2" display="div">
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card>
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Avatar
                        sx={{
                          margin: "auto",
                          bgcolor: theme.palette.primary.main
                        }}
                      >
                        <Mouse />
                      </Avatar>
                    </Box>
                    <Box sx={{ flexGrow: 3, ml: 2, minHeight: 70 }}>
                      <Typography
                        variant="h5"
                        sx={{ textTransform: "uppercase" }}
                      >
                        Clicks
                      </Typography>
                      <Typography variant="h2" display="div">
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4}>
              <Card>
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Avatar
                        sx={{
                          margin: "auto",
                          bgcolor: theme.palette.primary.main
                        }}
                      >
                        <LockOpen />
                      </Avatar>
                    </Box>
                    <Box sx={{ flexGrow: 3, ml: 2, minHeight: 70 }}>
                      <Typography
                        variant="h5"
                        sx={{ textTransform: "uppercase" }}
                      >
                        Ouvert
                      </Typography>
                      <Typography variant="h2" display="div">
                        0
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ my: 2 }}>
            <BaseTable columns={column} page={1} data={[]} />
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  )
}
