import { Theme } from "@mui/material"
import { createStyles, makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headPrimary: {
      background: theme.palette.primary.main,
      "& th, & th *": {
        color: "#fff"
      }
    },
    striped: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)
