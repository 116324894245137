import { styled } from "@mui/material"
import loader from "../../assets/loader.png"

const LoaderDiv = styled("div")(() => ({
  "& img ": {
    animation: "spin 1s linear infinite"
  }
}))
export const Loading = (): JSX.Element => {
  return (
    <LoaderDiv>
      <img src={loader} alt="" />
    </LoaderDiv>
  )
}
