import ApartmentIcon from "@mui/icons-material/Apartment"
import FormatListBulleted from "@mui/icons-material/FormatListBulleted"
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService"
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
import PeopleIcon from "@mui/icons-material/People"
import QrCodeIcon from "@mui/icons-material/QrCode"
import HomeIcon from "@mui/icons-material/Home"
import WebIcon from "@mui/icons-material/Web"
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import { IWebRoutes, IFeatured, AdminType } from "@/types"
import { ProductRoutes } from "@/components/Etablissement/Produits/ProductRoutes"
import { ClientRoutes } from "@/pages/Clients"
import { EtablissementRoutes } from "@/pages/etablissement"
import CasinoIcon from "@mui/icons-material/Casino"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import {
  AccessTime,
  AssignmentTurnedIn,
  AutoStories,
  Bolt,
  DeliveryDining,
  Settings
} from "@mui/icons-material"
import { Home } from "./Home/Home"
import { GameRoute } from "./QRCode/GameRoute"
import { BookingRoutes } from "../Booking/BookingRoutes"
import { PersonnalizationRoutes } from "./Personnalization/PersonnalizationRoutes"
import { AbonnementBoard } from "../abonnement"
import { CommandRoutes } from "@/components/command/CommandRoutes"
import { CampaignRoutes } from "../campaign/CampaignRoutes"
import { PaymentRoute } from "../payement/paymentRoute"
import { Payment } from "../payement/payment"

export const useBusinessRoute = () => {
  const businessRoutes: IWebRoutes[] = [
    {
      id: "DASHBOARD_BS",
      index: true,
      element: <Home />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Tableau de bord",
      icon: <HomeIcon />,
      featured: IFeatured.SIDENAV
    },
    {
      id: "ETABLISSEMENT",
      element: <EtablissementRoutes isBusiness />,
      path: "etablissements/*",
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Gérer mon établissement",
      icon: <ApartmentIcon />,
      featured: IFeatured.SIDENAV
    },
    {
      id: "PERSONALISATION",
      path: "personnalisation/*",
      element: <PersonnalizationRoutes />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Personnaliser ma Page client",
      icon: <WebIcon />,
      featured: IFeatured.SIDENAV
    },
    {
      id: "CLIENTS_BS",
      path: "client/*",
      element: <ClientRoutes />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Gérer les clients",
      icon: <PeopleIcon />,
      featured: IFeatured.SIDENAV
    },
    {
      id: "COMMAND_BOOKING",
      path: "command-booking/*",
      element: <BookingRoutes />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Commandes",
      icon: <DeliveryDining />,
      featured: IFeatured.SIDENAV,
      subMenus: [
        {
          id: "COMMAND_BOARD",
          label: "Commandes",
          path: "command-booking/*",
          icon: <AssignmentTurnedIn />
        }
      ]
    },
    {
      id: "BOOKING",
      path: "booking/*",
      element: <BookingRoutes />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Réservations",
      icon: <AccessTime />,
      featured: IFeatured.SIDENAV,
      subMenus: [
        {
          id: "BOOKING_BOARD",
          label: "Liste des réservations",
          path: "booking/booking-board/*",
          icon: <AutoStories />
        },
        {
          id: "BOOKING_SETTING",
          label: "Paramètre des réservations",
          path: "booking/booking-param",
          icon: <Settings />
        },
        {
          id: "EVENT",
          label: "Gestion des événements",
          path: "command-booking/list_event",
          icon: <Settings />
        }

      ]
    },
    {
      id: "PRODUCT_BS",
      path: "category/*",
      element: <ProductRoutes />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Gérer mes produits",
      icon: <ProductionQuantityLimitsIcon />,
      featured: IFeatured.SIDENAV,
      subMenus: [
        {
          id: "LIST_CAT",
          label: "Liste catégorie",
          path: "category/*",
          icon: <FormatListBulletedIcon />
        },
        {
          id: "LIST_PRODUCT",
          label: "Liste produits",
          path: "category/product-list/*",
          icon: <ProductionQuantityLimitsIcon />
        }
      ]
    },
    {
      id: "QRCODE_BS",
      path: "games/*",
      element: <GameRoute />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Gérer jeux et code QR",
      icon: <QrCodeIcon />,
      featured: IFeatured.SIDENAV,
      subMenus: [
        {
          id: "GAME_HOME",
          label: "Gérer Jeux",
          icon: <HomeRepairServiceIcon />,
          index: true,
          path: "games/list-qrcode/*"
        },
        {
          id: "LOTS_HOME",
          label: "Gérer lots",
          icon: <EmojiEventsIcon />,
          index: true,
          path: "games/list-lots/*"
        },
        {
          id: "LIST_TEMPLATE",
          label: "Listes des templates",
          path: "games/list-template/*",
          icon: <CasinoIcon />
        }
      ]
    },
    {
      id: "ABONNEMENT",
      path: "abonnement/*",
      element: <AbonnementBoard />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Gestion d'abonnement",
      icon: <WebIcon />
    },
    {
      id: "COMMAND_EMAIL",
      path: "command/*",
      element: <CommandRoutes />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER]
    },
    {
      id: "BOOKING_EMAIL",
      path: "reservation/*",
      element: <BookingRoutes />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER]
    },
    {
      id: "PAYEMENT",
      path: "payment/*",
      element: <Payment />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Payement",
      icon: <WebIcon/>
    },
    {
      id: "CAMPAIGN",
      path: "Campagne/*",
      element: <CampaignRoutes />,
      role: [AdminType.BUSINESS_ADMIN, AdminType.BUSINESS_USER],
      label: "Campagne marketing",
      icon: <MailOutlineIcon />,
      featured: IFeatured.SIDENAV,
      subMenus: [
        {
          id: "CAMPAGNE",
          label: "Campagne",
          icon: <MailOutlineIcon />,
          path: "Campagne/*"
        },
        {
          id: "AUTOMATE",
          label: "Campagne automatisé",
          icon: <Bolt />,
          path: "Campagne/auto"
        },
        {
          id: "CONTACT",
          label: "Liste contact",
          icon: <FormatListBulleted />,
          path: "Campagne/contact"
        }
      ]
    }
  ]
  return { businessRoutes }
}
