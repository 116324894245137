import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { AppBar, IconButton, Toolbar } from "@mui/material"
import { CancelOutlined } from "@mui/icons-material"

interface CustomModalProps {
  btnClose?: boolean
  fullscreen?: boolean
  noConfirmBtn?: boolean
  fullWidth?: boolean
  open: boolean
  title?: string
  confirmLabel?: string
  cancelLabel?: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm?: () => void
  noAction?: boolean
  children?: React.ReactNode
}
export const CustomModal: React.FC<CustomModalProps> = ({
  title,
  noConfirmBtn,
  btnClose,
  open,
  confirmLabel,
  fullscreen,
  fullWidth,
  cancelLabel,
  noAction,
  setOpen,
  onConfirm,
  children
}): JSX.Element => {
  const handleClose = (): void => {
    setOpen(false)
  }

  const handleConfirm = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    setOpen(false)
    onConfirm && onConfirm()
  }
  return (
    <div>
      <Dialog
        fullScreen={fullscreen}
        fullWidth={fullWidth}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar position="relative" color="default">
          {title && (
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <DialogTitle id="alert-dialog-title" sx={{ px: 0 }}>
                {title}
              </DialogTitle>
              {btnClose ? (
                <IconButton onClick={handleClose}>
                  <CancelOutlined fontSize="large" />
                </IconButton>
              ) : null}
            </Toolbar>
          )}
        </AppBar>
        <DialogContent>{children}</DialogContent>
        {!noAction ? (
          <AppBar position="relative" color="default">
            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>
                {cancelLabel || "Annuler"}
              </Button>
              {!noConfirmBtn && (
                <Button variant="contained" onClick={handleConfirm} autoFocus>
                  {confirmLabel || "Confirmer"}
                </Button>
              )}
            </DialogActions>
          </AppBar>
        ) : null}
      </Dialog>
    </div>
  )
}
