/* eslint-disable react/jsx-props-no-spreading */
import useSimpleDebounce from "@/hooks/useSimpleDebounce"
import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useState
} from "react"

export const ColorPicker: FC<InputHTMLAttributes<any>> = memo(
  ({ ...props }) => {
    const [color, setColor] = useState<string>()
    const [value, setValue] = useState<ChangeEvent<HTMLInputElement>>()

    const batchedValue = useSimpleDebounce(value, 200)

    const applyValue = useCallback(() => {
      if (batchedValue) {
        if (props.onChange) {
          props.onChange(batchedValue)
        }
      }
    }, [batchedValue])

    useEffect(() => {
      applyValue()
    }, [applyValue])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setColor(e.target.value)
      setValue(e)
    }

    return (
      <input type="color" value={color} onChange={handleChange} {...props} />
    )
  }
)
