/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Chip, styled } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { ClientById_clientById as IClient } from "../../graphql/clients"
import { StatusType } from "../../types"

interface ClientStatusProps {
  client: IClient
  onChange?: (status: StatusType) => void
}

const StatusTextColor = (status: string) => {
  switch (status) {
    case StatusType.CLIENT:
      return { text: "Client", color: "success" }
    case StatusType.PROSPECT:
      return { text: "Prospect", color: "secondary" }
    case StatusType.VIP:
      return { text: "V.I.P", color: "warning" }
    case StatusType.INACTIF:
      return { text: "Inactif", color: "default" }

    default:
      return { text: "Aucun", color: "default" }
  }
}

const ChipChip = styled(Chip)(() => ({
  padding: 0,
  height: 25
}))

export const ClientStatus: FC<ClientStatusProps> = ({ client, onChange }) => {
  const [current, setCurrent] = useState(client.status || "")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.SyntheticEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleChange = (status: StatusType) => {
    if (onChange) {
      onChange(status)
    }
    setCurrent(status)
    setAnchorEl(null)
  }
  const statusList = Object.values(StatusType)
  useEffect(() => {
    if (client.status) {
      setCurrent(client.status)
    }
  }, [client])

  return (
    <Box>
      <ChipChip
        label={StatusTextColor(current).text}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color={StatusTextColor(current).color as any}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {statusList
          .filter((st) => st !== current)
          .map((status) => {
            return (
              <MenuItem key={status} onClick={() => handleChange(status)}>
                <Chip
                  label={StatusTextColor(status).text}
                  color={StatusTextColor(status).color as any}
                />
              </MenuItem>
            )
          })}
      </Menu>
    </Box>
  )
}
