import { Box, IconButton, Typography, styled } from "@mui/material"
import { FC } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useRouteValidation } from "../../hooks"

const MyBox = styled(Box)(() => ({}))
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderNavigationProps {}
// eslint-disable-next-line no-empty-pattern
export const NavigationBar: FC<HeaderNavigationProps> = ({}): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const { navbarMenu } = useRouteValidation()

  const handleNavigate = (path?: string): void => {
    const pathEdited = path && path.endsWith("*") ? path.replace("*", "") : path
    navigate(`/discount/${pathEdited ?? ""}`)
  }
  const isActive = (path?: string): boolean => {
    if (!path && location.pathname.split("/").join(" ").trim() === "discount")
      return true

    return location.pathname
      .split("/")
      .join(" ")
      .trim()
      .includes(path?.replace("*", "")?.replace("/", "")?.trim() || "none")
  }
  return (
    <Box pl={1}>
      <MyBox
        sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}
      >
        {navbarMenu.map(({ id, label, icon, path }) => (
          <IconButton
            color={isActive(path) ? "primary" : undefined}
            key={id}
            onClick={() => handleNavigate(path)}
          >
            {icon}
            <Typography sx={{ ml: 3 }} variant="subtitle2">
              {label}
            </Typography>
          </IconButton>
        ))}
      </MyBox>
    </Box>
  )
}
