/* eslint-disable @typescript-eslint/no-explicit-any */
export const storage = {
  get: (key: string) => {
    const items = localStorage.getItem(key)
    if (!items) return undefined
    return JSON.parse(items)
  },
  set: (key: string, values: any) => {
    const data = JSON.stringify(values)
    localStorage.setItem(key, data)
  }
}
