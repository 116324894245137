import {
  Avatar,
  Box,
  Card,
  Button,
  Typography,
  styled,
  Stack,
  TypographyProps,
  Modal,
  CircularProgress,
  CardContent
} from "@mui/material"
import { FC, useEffect, useRef, useState } from "react"
import { Qrcodes_qrcodes as IQRCode } from "@/graphql/qrcode"
import { toPng } from "html-to-image"
import { useQuery } from "@apollo/client"
import { DL_IMG } from "@/graphql"
import { QueryResult } from "../common"

interface TextProps extends TypographyProps {
  textColor?: string
}

const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "textColor"
})<TextProps>(({ textColor }) => ({
  color: textColor
}))

const BusinessNameText = styled(StyledText)(() => ({
  fontSize: 16,
  fontWeight: "bold"
}))

const TitleText = styled(StyledText)(() => ({
  fontSize: 18,
  fontWeight: "bold",
  lineHeight: "1rem",
  marginBottom: 10
}))

const SubTitle1Text = styled(StyledText)(() => ({
  fontSize: 14,
  fontWeight: 600
}))

const SubTitle2Text = styled(StyledText)(() => ({
  fontSize: 14,
  fontWeight: 600
}))

interface QRCodeCardProps {
  qrcode: IQRCode
}

export const QRCodeCard: FC<QRCodeCardProps> = ({ qrcode }) => {
  const { textColor } = qrcode
  const [prepare, setPrepare] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [waitQrcodeImage, setWaitQrcodeImage] = useState<boolean>(false)
  const { data: dataBg, loading: loadingBg } = useQuery(DL_IMG, {
    variables: {
      url: qrcode.background
    },
    skip: !qrcode.background
  })
  const { data: dataLogo, loading: loadingLogo } = useQuery(DL_IMG, {
    variables: {
      url: qrcode.logo
    },
    skip: !qrcode.logo
  })
  const { data: dataQr, loading: loadingQr } = useQuery(DL_IMG, {
    variables: {
      url: qrcode.qrcodeImage
    },
    skip: !qrcode.qrcodeImage
  })
  const qrRef = useRef(null)
  const downloadQrcode = async () => {
    setPrepare(true)
    setWaitQrcodeImage(true)
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve("")
      }, 500)
    })
    const el = qrRef.current
    if (!el) return
    toPng(el, {
      canvasWidth: 560,
      canvasHeight: 800
    })
      .then((canvas) => {
        const a = document.createElement("a")
        a.href = canvas
        a.download = "qrcode.jpg"
        a.click()
        setWaitQrcodeImage(false)
      })
      .catch(() => {
        setWaitQrcodeImage(false)
      })
      .finally(() => {
        setPrepare(false)
      })
  }

  useEffect(() => {
    setLoading(loadingBg || loadingLogo || loadingQr)
  }, [loadingBg, loadingLogo, loadingQr])

  return (
    <Box
      sx={{
        margin: "auto",
        maxWidth: 420
      }}
    >
      {waitQrcodeImage ? (
        <Box>
          <Modal open>
            <Box
              sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Card elevation={0}>
                <CardContent sx={{ p: 4 }}>
                  <Box
                    sx={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "Bold",
                      mb: 4
                    }}
                  >
                    Nous créons votre impression, merci de patientez jusqu'à la fin
                  </Box>
                  <Box textAlign="center">
                    <CircularProgress style={{ width: 75, height: 75 }} />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Modal>
        </Box>
      ) : null}
      <Box ref={qrRef}>
        <QueryResult data={dataBg && dataQr && dataLogo} loading={loading}>
          <Card
            sx={{
              borderRadius: 0,
              position: "relative",
              width: "100%",
              height: 600,
              backgroundImage: `url(data:image/jpeg;base64,${dataBg?.downloadFile})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}
          >
            <Box
              sx={{
                p: 2,
                position: "absolute",
                zIndex: 50,
                width: "100%",
                height: "100%"
              }}
            >
              <Box sx={{ textAlign: "start" }}>
                <Stack direction="row" spacing={0.5}>
                  <Avatar
                    sx={{ width: 22, height: 22 }}
                    alt="Remy Sharp"
                    src="/static/images/newlogo.png"
                  />
                  <SubTitle1Text textColor={textColor}>
                    Tag Discount
                  </SubTitle1Text>
                </Stack>
              </Box>
              <Box>
                <img
                  src={`data:image/jpeg;base64,${dataLogo?.downloadFile}`}
                  style={{
                    margin: "auto",
                    maxHeight: 50,
                    borderRadius: 0,
                    objectFit: "contain"
                  }}
                  alt="Logo"
                />
                <BusinessNameText textColor={textColor}>
                  {qrcode.etablissement}
                </BusinessNameText>
              </Box>
              <Box sx={{ py: 2 }}>
                <TitleText textColor={textColor}>{qrcode.title}</TitleText>
                <SubTitle1Text textColor={textColor}>
                  {qrcode.subtitle1}
                </SubTitle1Text>
              </Box>
              <Box sx={{ py: 2 }}>
                <img
                  src={`data:image/jpeg;base64,${dataQr?.downloadFile}`}
                  alt="QrCode"
                />
              </Box>
              {/* <Box sx={{ py: 2 }}>
                <SubTitle2Text textColor={textColor}>
                  {qrcode.subtitle1}
                </SubTitle2Text>
              </Box> */}
            </Box>
          </Card>
        </QueryResult>
      </Box>
      <Box sx={{ py: 2 }}>
        <Button
          disabled={prepare}
          fullWidth
          variant="contained"
          onClick={downloadQrcode}
          startIcon={
            prepare ? (
              <CircularProgress style={{ width: 25, height: 25 }} />
            ) : null
          }
        >
          Télécharger
        </Button>
      </Box>
    </Box>
  )
}
