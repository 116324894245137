import { useMutation } from "@apollo/client"
import {
  CreateSousCategory,
  CreateSousCategoryVariables,
  DeleteSousCategory,
  DeleteSousCategoryVariables,
  DELETE_SOUS_CATEGORY,
  UpdateSousCategoryVariables,
  UpdateSousCategory,
  CREATE_SOUS_CATEGORY,
  updateStatusSubCategory,
  updateStatusSubCategoryVariables,
  UPDATE_SOUS_CATEGORY,
  UPDATE_STATUS_SOUS_CATEGORY
} from "@/graphql/business"

import { SousCategoryInput, UpdateCategoryInput } from "@/types"

export const useSousCategory = () => {
  const [executeCreate, { loading }] = useMutation<
    CreateSousCategory,
    CreateSousCategoryVariables
  >(CREATE_SOUS_CATEGORY)
  const createSousCategory = async (data: SousCategoryInput) => {
    try {
      await executeCreate({
        variables: {
          data
        }
      })
      return { success: "OK" }
    } catch (error) {
      return {
        error: "ERROR"
      }
    }
  }
  return {
    loading,
    createSousCategory
  }
}

export const useUpdateSousCategory = () => {
  const [updateSousCategory] = useMutation<
    UpdateSousCategory,
    UpdateSousCategoryVariables
  >(UPDATE_SOUS_CATEGORY)
  const [updateStatusSousCategory] = useMutation<
    updateStatusSubCategory,
    updateStatusSubCategoryVariables
  >(UPDATE_STATUS_SOUS_CATEGORY)
  const sousCategoryUpdate = async (
    sousCategoryId: number,
    data: UpdateCategoryInput
  ) => {
    try {
      const category = await updateSousCategory({
        variables: {
          sousCategoryId,
          data
        }
      })
      return category.data?.updateSousCategory
    } catch (error) {
      return null
    }
  }
  const sousCategoryStatusUpdate = async (
    sousCategoryId: number,
    status: boolean
  ) => {
    try {
      const subCategory = await updateStatusSousCategory({
        variables: {
          sousCategoryId,
          status
        }
      })
      return subCategory.data?.updateStatusSubCategory
    } catch (error) {
      return null
    }
  }
  return { sousCategoryUpdate, sousCategoryStatusUpdate }
}

export const useDeleteSousCategory = () => {
  const [deleteSousCategory, { loading }] = useMutation<
    DeleteSousCategory,
    DeleteSousCategoryVariables
  >(DELETE_SOUS_CATEGORY)
  const categorySousDelete = async (id: number) => {
    try {
      const souscategory = await deleteSousCategory({
        variables: {
          sousCategoryId: id
        }
      })
      return souscategory
    } catch (error) {
      return undefined
    }
  }
  return { categorySousDelete, loading }
}
