/* eslint-disable @typescript-eslint/no-explicit-any */
import SortIcon from "@mui/icons-material/Sort"
import Delete from "@mui/icons-material/Delete"
import Edit from "@mui/icons-material/Edit"
import Visibility from "@mui/icons-material/Visibility"
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Checkbox,
  Box,
  Button,
  CircularProgress,
  useTheme
} from "@mui/material"
import Popover from "@mui/material/Popover"
import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useState,
  MouseEvent,
  useEffect
} from "react"
import { BaseTableProps } from "./interface"
import { useStyles } from "./styles"

export const BaseTable: FC<BaseTableProps> = ({
  columns,
  data,
  page,
  perPage,
  cellAlign,
  stickyHeader,
  primaryHead,
  striped,
  checkbox,
  topComponent,
  indexed,
  edit,
  view,
  remove,
  title,
  filterComponent,
  loading,
  emptyComponent,
  listChecked,
  onEdit,
  onRemove,
  onView,
  onCheckedChange
}) => {
  const [check, setCheck] = useState<any[]>([])
  const classes = useStyles()
  const handleCheck = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: any
  ): void => {
    if (checked) {
      setCheck((prev) => prev.concat(id))
    } else {
      setCheck((prev) => prev.filter((di) => di !== id))
    }
  }
  const handleView = (e: SyntheticEvent): void => {
    e.preventDefault()
    onView && onView(check)
  }
  const handleEdit = (e: SyntheticEvent): void => {
    e.preventDefault()
    onEdit && onEdit(check)
  }
  const handleRemove = (e: SyntheticEvent): void => {
    e.preventDefault()
    onRemove && onRemove(check)
  }
  const handleClickRow = (e: SyntheticEvent, dt: any): void => {
    e.preventDefault()
    onView && onView(dt)
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  /**
   * All responsive styles
   */
  const theme = useTheme()
  const tableHeaderMd = {
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: "-9999px",
      left: "-9999px"
    }
  }
  const trMd = {
    [theme.breakpoints.down("md")]: {
      borderTop: "1px solid #eee"
    }
  }
  const tdMd = {
    color: "black",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      padding: 0,
      paddingTop: 1,
      border: "none",
      position: "relative",
      whiteSpace: "normal",
      textAlign: "left",
      minHeight: 45,
      alignItems: "center",
      "&::before": {
        content: "attr(data-title)",
        display: "inline-block",
        width: "35%",
        paddingRight: "10px",
        textAlign: "left",
        fontWeight: "bold",
        fontSize: 12
      }
    }
  }

  const topMd = {
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "end"
    }
  }
  const tableRsp = {
    overflow: "inherit",
    "scrollbar-width": "none",
    '@media (min-width: 900px)': {
      overflow: "auto",
      "scrollbar-width": "auto"
    }
  }
  /**
   * End responsive
   */

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const newData = data.map((dt, index) => {
    const key = `rowkey-${index}`
    return (
      <TableRow
        key={key}
        className={striped ? classes.striped : ""}
        onClick={(e) => handleClickRow(e, dt)}
        sx={trMd}
      >
        {indexed && checkbox ? (
          <TableCell
            align="left"
            onClick={(e) => e.stopPropagation()}
            sx={tdMd}
          >
            <Checkbox
              checked={check.includes(dt[indexed])}
              onChange={(e, ck) => handleCheck(e, ck, dt[indexed])}
            />
          </TableCell>
        ) : null}
        {columns.map((column, i) => {
          const keye = `tablerow-${column.id}-${i}`
          return (
            <TableCell
              sx={tdMd}
              align={
                (column.align as
                  | "inherit"
                  | "left"
                  | "center"
                  | "right"
                  | "justify") || cellAlign
              }
              key={keye}
              data-title={column.label}
            >
              {column.component(dt)}
            </TableCell>
          )
        })}
      </TableRow>
    )
  })

  useEffect(() => {
    if (onCheckedChange) {
      onCheckedChange(check)
    }
  }, [check, onCheckedChange])

  useEffect(() => {
    if (listChecked) {
      setCheck((prev) => prev.concat(listChecked))
    }
  }, [listChecked])

  return (
    <TableContainer component={Paper} sx={{ p: 2, ...tableRsp }} elevation={0}>
      <Box sx={{ alignItems: "center", display: { xs: "block", md: "flex" }, justifyContent: "space-between" }}>
        {title ? (
          <Box sx={{ px: { xs: 0, md: 1 } }}>
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
        ) : null}
        <Box sx={topMd}>
          <Box>{topComponent}</Box>
          {view ? (
            <Button
              sx={{ mr: 1 }}
              color="info"
              startIcon={<Visibility />}
              disabled={!check}
              onClick={handleView}
            >
              Voir
            </Button>
          ) : null}
          {edit ? (
            <Button
              sx={{ mr: 1 }}
              color="inherit"
              startIcon={<Edit />}
              disabled={!check}
              onClick={handleEdit}
            >
              Modifier
            </Button>
          ) : null}
          {remove ? (
            <Button
              color="error"
              sx={{ ml: 1 }}
              startIcon={<Delete />}
              disabled={!check}
              onClick={handleRemove}
            >
              Supprimer
            </Button>
          ) : null}
          {filterComponent ? (
            <div>
              <Button
                color="inherit"
                sx={{ ml: 1 }}
                startIcon={<SortIcon />}
                onClick={handleClick}
              >
                Filtrer
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              >
                {filterComponent}
              </Popover>
            </div>
          ) : null}
        </Box>
      </Box>
      <Table
        size="small"
        aria-label="a dense table"
        stickyHeader={stickyHeader}
      >
        <TableHead
          className={primaryHead ? classes.headPrimary : ""}
          sx={tableHeaderMd}
        >
          <TableRow>
            {indexed && checkbox ? (
              <TableCell align="left">
                <Checkbox
                  checked={check.length === data.length}
                  indeterminate={!!check.length && check.length !== data.length}
                  onChange={(e, ck) => {
                    if (ck) {
                      const checks = data.map((dts) => dts[indexed])
                      setCheck(checks)
                    } else {
                      setCheck([])
                    }
                  }}
                />
              </TableCell>
            ) : null}
            {columns.map((column, index) => {
              const key = `column-${column.id}-${index}`
              return (
                <TableCell
                  align={
                    (column.align as
                      | "inherit"
                      | "left"
                      | "center"
                      | "right"
                      | "justify") || cellAlign
                  }
                  key={key}
                >
                  <Typography>{column.label}</Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>

        <TableBody sx={{ color: "black" }}>
          {!loading && perPage !== undefined
            ? newData.slice(page * perPage, page * perPage + perPage)
            : newData}
        </TableBody>
      </Table>
      {loading ? (
        <Box sx={{ textAlign: "center", p: 4 }}>
          <CircularProgress style={{ height: 30, width: 30 }} color="inherit" />
        </Box>
      ) : null}
      {!loading && data.length === 0 ? emptyComponent : null}
    </TableContainer>
  )
}
