import { Box, Button } from "@mui/material"
import { green, red, grey } from "@mui/material/colors"

type buttonProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status: any
  onChange: () => void
  loading: boolean
}

const openColor = green[200]
const closeColor = red[200]
const loadingColor = grey[200]
const bgGreenColor = green[500]
const bgRedColor = red[500]
const bgGreyColor = grey[500]

export const ButtonOpeningBusiness = ({
  status,
  onChange,
  loading
}: buttonProps) => {
  if (!status) {
    return (
      <Button
        sx={{
          fontWeight: "bold",
          borderRadius: "4px !important",
          backgroundColor: closeColor,
          color: "#ffffff",
          mx: 1
        }}
        variant="contained"
        onClick={onChange}
      >
        <Box
          sx={{
            width: 15,
            height: 15,
            borderRadius: "50%",
            backgroundColor: bgRedColor,
            display: "inline-block",
            mr: 1
          }}
        />
        Commande désactivée
      </Button>
    )
  }

  if (loading) {
    return (
      <Button
        sx={{
          fontWeight: "bold",
          borderRadius: "4px !important",
          backgroundColor: loadingColor,
          color: "#ffffff",
          mx: 1
        }}
        variant="contained"
        disabled
      >
        <Box
          sx={{
            width: 15,
            height: 15,
            borderRadius: "50%",
            backgroundColor: bgGreyColor,
            display: "inline-block",
            mr: 1
          }}
        />
        Modification des commandes
      </Button>
    )
  }

  return (
    <Button
      sx={{
        fontWeight: "bold",
        borderRadius: "4px !important",
        backgroundColor: openColor,
        color: "#ffffff",
        mx: 1
      }}
      variant="contained"
      onClick={onChange}
    >
      <Box
        sx={{
          width: 15,
          height: 15,
          borderRadius: "50%",
          backgroundColor: bgGreenColor,
          display: "inline-block",
          mr: 1
        }}
      />
      commande activée
    </Button>
  )
}
