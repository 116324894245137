import { Dropzone, ResearchBox } from "@/components"
import { BgColor } from "@/components/Etablissement/Media/BgColor"
import { ColorBackground } from "@/components/Etablissement/Media/ColorBackground"
import { ColorBtn } from "@/components/Etablissement/Media/ColorBtn"
import {
  UpdateBusinessInfo,
  UpdateBusinessInfoVariables,
  UPDATE_BUSINESS_INFO
} from "@/graphql/business"
import {
  useApplicationContext,
  useCurrentBusiness,
  useUpdateOrCreatePalette,
  useUpdateOrCreateTheme
} from "@/hooks"
import {
  BackgroundType,
  BGType,
  BoutonShopType,
  BSType,
  PaletteInput,
  ThemeInput
} from "@/types"
import { useMutation } from "@apollo/client"
import Delete from "@mui/icons-material/Delete"
import {
  Box,
  Grid,
  Card,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  IconButton
} from "@mui/material"
import { useState, useEffect } from "react"
import ReactPlayer from "react-player"
import { useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { BusinessView } from "./Components/BusinessView"
import { CustomTypography } from "./Components/CustomTypography"

export interface Profil {
  logo?: string
  coverPhoto?: string
  coverVidz?: string
  animated?: boolean
}

export const convertEnumBs = (bsType: BSType) => {
  return BoutonShopType[BSType[bsType] as keyof typeof BoutonShopType]
}
export const CustomPage = () => {
  const [updateInfo] = useMutation<
    UpdateBusinessInfo,
    UpdateBusinessInfoVariables
  >(UPDATE_BUSINESS_INFO)
  const { id } = useParams()
  const { business } = useApplicationContext()
  const { createOrUpdatePalette } = useUpdateOrCreatePalette()
  const { createOrUpdateTheme } = useUpdateOrCreateTheme()
  const { data, refetch } = useCurrentBusiness(
    business?.id || parseFloat(id as string)
  )

  const [theme, setTheme] = useState<ThemeInput>({
    colorBtn: "#FE724C",
    colorText: "#FFFFFF",
    colorBtnText: "#FFFFFF",
    mediaColor: "#e63946",
    colorCardProduct: "#FFFFFF",
    colorTextCardProduct: "#000000",
    bsType: BoutonShopType.CIRCULAR,
    fontSize: "14px",
    police: "Poppins"
  })

  const [palette, setPalette] = useState<PaletteInput>({
    bgColor: "#FFFFFF",
    bgType: BackgroundType.COLOR_LINEAR
  })

  const [profil, setProfil] = useState<Profil>()
  const [searchVideo, setSearchVideo] = useState<string>()
  const [disabled, setDisabled] = useState<boolean>(false)

  const convertEnum = (bgType: BGType) => {
    return BackgroundType[BGType[bgType] as keyof typeof BackgroundType]
  }

  useEffect(() => {
    if (data?.BusinessTheme && data?.BusinessTheme.bsType) {
      setTheme({
        colorBtn: data?.BusinessTheme.colorBtn,
        colorText: data?.BusinessTheme.colorText,
        colorBtnText: data?.BusinessTheme.colorBtnText,
        mediaColor: data?.BusinessTheme.mediaColor,
        colorCardProduct: data?.BusinessTheme.colorCardProduct,
        colorTextCardProduct: data?.BusinessTheme.colorTextCardProduct,
        bsType: convertEnumBs(data?.BusinessTheme.bsType),
        fontSize: data?.BusinessTheme.fontSize,
        police: data?.BusinessTheme.police
      })
    }
    if (data?.BusinessPalette && data?.BusinessPalette.bgType) {
      setPalette({
        bgType: convertEnum(data?.BusinessPalette.bgType),
        bgColor: data?.BusinessPalette.bgColor,
        bgColor1: data?.BusinessPalette.bgColor1,
        bgColor2: data?.BusinessPalette.bgColor2,
        bgImage: data?.BusinessPalette.bgImage,
        bgViedo: data?.BusinessPalette.bgViedo
      })
    }
    if (data?.logo && data?.coverPhoto) {
      setProfil({
        logo: data?.logo,
        coverPhoto: data?.coverPhoto,
        coverVidz: data?.coverVidz || "",
        animated: data?.animatedCover || false
      })
    }
  }, [data])

  const handleSaveProfil = async () => {
    try {
      const response = await updateInfo({
        variables: {
          businessId: business?.id || parseFloat(id as string),
          business: {
            logo: profil?.logo,
            coverPhoto: profil?.coverPhoto,
            coverVidz: profil?.coverVidz,
            animatedCover: profil?.animated
          }
        }
      })
      return response
    } catch (errors) {
      return undefined
    }
  }

  const handleSavePalette = async (): Promise<any> => {
    const lance = await createOrUpdatePalette(
      {
        ...palette
      },
      {
        businessId: business?.id || parseFloat(id as string),
        paletteId: data?.BusinessPalette?.id
      }
    )
    if (lance) {
      return lance
    }
    return undefined
  }

  const handleSaveTheme = async (): Promise<any> => {
    const lance = await createOrUpdateTheme(
      { ...theme },
      {
        businessId: business?.id || parseFloat(id as string),
        themeId: data?.BusinessTheme?.id
      }
    )
    if (lance) {
      return lance
    }
    return undefined
  }

  const handleChangeBgType = (type: BackgroundType) => {
    setPalette((prev) => ({
      ...prev,
      bgType: type
    }))
  }

  const handleChangeTypography = (e: any) => {
    const { name, value } = e.target
    setTheme((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeCardProduct = (e: any) => {
    const { name, value } = e.target
    setTheme((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeTextCardProduct = (e: any) => {
    const { name, value } = e.target
    setTheme((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeBtn = (e: any) => {
    const { name, value } = e.target
    setTheme((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeLogo = (file?: string) => {
    setProfil((prev) => ({
      ...prev,
      logo: file
    }))
  }

  const handleChangeCover = (file?: string) => {
    setPalette((prev) => ({
      ...prev,
      bgImage: file
    }))
  }

  const handleChangeCoverPhoto = (file?: string) => {
    setProfil((prev) => ({
      ...prev,
      coverPhoto: file
    }))
  }

  const handleChangeLinear = (color1: string, color2: string) => {
    setPalette((prev) => ({
      ...prev,
      bgColor1: color1,
      bgColor2: color2
    }))
  }

  const handleChangeColor = (color: string) => {
    setPalette((prev) => ({
      ...prev,
      bgColor: color
    }))
  }

  const handleSavePage = async (): Promise<void> => {
    setDisabled(true)
    try {
      const newProfil = await handleSaveProfil()
      const newPalette = await handleSavePalette()
      const newTheme = await handleSaveTheme()
      if (newPalette && newTheme && newProfil) {
        Swal.fire({
          icon: "success",
          title: "Personnalisation enregistrée"
        })
        setDisabled(false)
        refetch()
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "modification échouée"
      })
    }
  }

    /****responsive styles */
    const mobilFullWidth = {
      
      "@media(max-width:663px)" : {
        width: "100%",
        minWidth: "100%"
      }
    }
  
    /*****end */

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Card sx={{ p: 2 }} elevation={1}>
            <Typography sx={{ my: 1 }} variant="h3">
              Profil de l'établissement
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={profil?.animated}
                      value={profil?.animated}
                      onChange={(e) =>
                        setProfil((prev) => ({
                          ...prev,
                          animated: e.target.checked
                        }))
                      }
                    />
                  }
                  label="Utiliser le couverture animée (vidéo)"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">
                  Logo de l'établissement
                </Typography>
                <Box
                  sx={{
                    height: 200,
                    py: 1,
                    position: "relative"
                  }}
                >
                  <Dropzone
                    onFinished={handleChangeLogo}
                    message="Ajouter logo"
                    btnSx={{ height: "100%" }}
                    photo={profil?.logo || undefined}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">Couverture image</Typography>
                <Box
                  sx={{
                    height: 350,
                    py: 1,
                    position: "relative"
                  }}
                >
                  <Dropzone
                    onFinished={handleChangeCoverPhoto}
                    message="Ajouter couverture"
                    btnSx={{ height: "100%" }}
                    photo={profil?.coverPhoto || undefined}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1">Couverture animée</Typography>
                <Box
                  sx={{
                    height: 350,
                    py: 1,
                    position: "relative"
                  }}
                >
                  <Box>
                    <ResearchBox
                      placeholder="Ajouter le lien içi"
                      onChange={(i: string) =>
                        setProfil((prev) => ({
                          ...prev,
                          coverVidz: i
                        }))
                      }
                    />
                  </Box>
                  {profil?.coverVidz ? (
                    <Box
                      sx={{
                        mt: 2,
                        border: "1px dashed red",
                        borderRadius: 2,
                        overflow: "hidden"
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          setProfil((prev) => ({
                            ...prev,
                            coverVidz: ""
                          }))
                        }
                        sx={{
                          position: "absolute",
                          bottom: 5,
                          right: 5,
                          zIndex: 999
                        }}
                      >
                        <Delete color="error" />
                      </IconButton>
                      <ReactPlayer
                        controls
                        url={profil?.coverVidz}
                        height={200}
                        width={300}
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ p: 2, mt: 2 }} elevation={1}>
            <Typography sx={{ my: 1 }} variant="h3">
              Fond de l'établissement
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ mx: 1 , my: 1 , ...mobilFullWidth  }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={palette?.bgType === convertEnum(BGType.IMAGE)}
                      value={BackgroundType.IMAGE}
                      onChange={(e) =>
                        handleChangeBgType(e.target.value as any)
                      }
                    />
                  }
                  label="Utiliser une image comme fond"
                />

                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 200,
                    height: 350,
                    py: 1,
                    position: "relative",
                    ...mobilFullWidth
                  }}
                >
                  <Dropzone
                    onFinished={handleChangeCover}
                    message="Ajouter couverture"
                    btnSx={{ height: "100%" }}
                    photo={palette?.bgImage || undefined}
                  />
                </Box>
              </Box>
              <Box sx={{ mx: 1 , my: 1 , ...mobilFullWidth  }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        palette?.bgType === convertEnum(BGType.COLOR_LINEAR)
                      }
                      value={BackgroundType.COLOR_LINEAR}
                      onChange={(e) =>
                        handleChangeBgType(e.target.value as any)
                      }
                    />
                  }
                  label="Utiliser une couleur linéaire comme fond"
                />

                <BgColor
                  colorTop={palette?.bgColor1 || ""}
                  colorBottom={palette?.bgColor2 || ""}
                  onSave={handleChangeLinear}
                />
              </Box>
              <Box sx={{ mx: 1 , my: 1 , ...mobilFullWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        palette?.bgType === convertEnum(BGType.COLOR_SINGLE)
                      }
                      value={BackgroundType.COLOR_SINGLE}
                      onChange={(e) =>
                        handleChangeBgType(e.target.value as any)
                      }
                    />
                  }
                  label="Utiliser une couleur unique comme fond"
                />

                <ColorBackground
                  color={palette?.bgColor || ""}
                  onSave={handleChangeColor}
                />
              </Box>
            </Box>
          </Card>
          <Card sx={{ p: 2, mt: 2 }} elevation={1}>
            <Typography sx={{ my: 1 }} variant="h3">
              Police
            </Typography>
            <Box>
              <CustomTypography
                theme={theme}
                onChange={handleChangeTypography}
              />
            </Box>
          </Card>
          <Card sx={{ p: 2, mt: 2 }} elevation={1}>
            <Typography sx={{ my: 1 }} variant="h3">
              Boutons
            </Typography>
            <Box>
              <ColorBtn theme={theme} onChange={handleChangeBtn} />
            </Box>
          </Card>
          <Card sx={{ p: 2, mt: 2 }} elevation={1}>
            <Typography sx={{ my: 1 }} variant="h3">
              Cartes produits
            </Typography>
            <Box>
              <Typography variant="subtitle2">Couleur de fond :</Typography>
              <input
                name="colorCardProduct"
                type="color"
                value={theme?.colorCardProduct || "#FFFFFF"}
                onChange={(e) => handleChangeCardProduct(e)}
              />
            </Box>
            <Box>
              <Typography variant="subtitle2">Couleur du texte :</Typography>
              <input
                name="colorTextCardProduct"
                type="color"
                value={theme?.colorTextCardProduct || "#000000"}
                onChange={(e) => handleChangeTextCardProduct(e)}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item md={6}>
          {profil && palette && (
            <BusinessView theme={theme} palette={palette} profil={profil} />
          )}
        </Grid>
        <Button
          disabled={disabled}
          variant="contained"
          sx={{ mx: "auto", mt: 2, textTransform: "none", color: "#050005" }}
          onClick={handleSavePage}
        >
          Enregistrer les modifications {disabled && <CircularProgress />}
        </Button>
      </Grid>
    </Box>
  )
}
