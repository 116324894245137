import { useEffect, useState } from "react"
import { ButtonLittleIcon } from "@/components"
import { useApplicationContext } from "@/hooks"
import { Autorenew, Group } from "@mui/icons-material"
import Edit from "@mui/icons-material/Edit"
import NotificationsActive from "@mui/icons-material/NotificationsActive"
import { Box, Card, CardContent, Chip, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { CampaignAutoType } from "@/types"
import { useQuery } from "@apollo/client"
import { GET_LIST_ENTRY } from "@/graphql/emailing"

interface InfoType {
  status: boolean
  type: CampaignAutoType
}

export const CampagneAuto = () => {
  const { business, setTemplateState } = useApplicationContext()
  const { data, loading, refetch } = useQuery(GET_LIST_ENTRY, {
    variables: {
      businessId: business?.id
    }
  })
  const [newUser, setNewUser] = useState<InfoType | null>()
  const [inactiveUser, setInactiveUser] = useState<InfoType | null>()
  const [undelivery, setUndelivery] = useState<InfoType | null>()
  const navigate = useNavigate()

  useEffect(() => {
    const newState =
      business?.campaignAuto?.find((item) => item.type === "NEWUSER") || null
    const inactiveState =
      business?.campaignAuto?.find((item) => item.type === "INACTIVEUSER") ||
      null
    const undeliveryState =
      business?.campaignAuto?.find((item) => item.type === "UNDELIVERY") || null

    setNewUser(newState)
    setInactiveUser(inactiveState)
    setUndelivery(undeliveryState)
    setTemplateState({
      name: "",
      email: "",
      subject: "",
      nameTemplate: "",
      template: ""
    })
  }, [])

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h5">Options des campagnes automatique</Typography>
      </Box>
      <Card sx={{ mb: 3 }}>
        <CardContent sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box sx={{ maxWidth: 125, width: "25%", textAlign: "center" }}>
              <Group />
            </Box>
            <Box sx={{ flexGrow: 2, maxWidth: 650 }}>
              <Typography variant="h5">Accueillir les nouveaux</Typography>
              <Typography>Message d'accueil des nouveaux arrivant</Typography>
            </Box>
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="h5">Envoyé</Typography>
                <Typography>0</Typography>
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="h5">Clicks</Typography>
                <Typography>0</Typography>
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="h5">Ouvert</Typography>
                <Typography>0</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                maxWidth: 125,
                width: "25%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ButtonLittleIcon
                icon={<Edit />}
                color="primary"
                sx={{ mr: 2 }}
                onClick={() => navigate("../edit-campaign/new-user/1")}
              />
              {newUser?.status ? (
                <Chip variant="filled" color="success" label="Actif" />
              ) : (
                <Chip variant="filled" color="error" label="Inactif" />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ mb: 3 }}>
        <CardContent sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box sx={{ maxWidth: 125, width: "25%", textAlign: "center" }}>
              <Autorenew />
            </Box>
            <Box sx={{ flexGrow: 2, maxWidth: 650 }}>
              <Typography variant="h5">
                Rattraper les clients inactif
              </Typography>
              <Typography>
                Ne perdez plus aucun clients en envoyant un message a ceux qui
                ne sont pas venus depuis longtemps
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="h5">Envoyé</Typography>
                <Typography>0</Typography>
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="h5">Clicks</Typography>
                <Typography>0</Typography>
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="h5">Ouvert</Typography>
                <Typography>0</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                maxWidth: 125,
                width: "25%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ButtonLittleIcon
                icon={<Edit />}
                color="primary"
                sx={{ mr: 2 }}
                onClick={() => navigate("../edit-campaign/inactive-user/1")}
              />
              {inactiveUser?.status ? (
                <Chip variant="filled" color="success" label="Actif" />
              ) : (
                <Chip variant="filled" color="error" label="Inactif" />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Card sx={{ mb: 3 }}>
        <CardContent sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box sx={{ maxWidth: 125, width: "25%", textAlign: "center" }}>
              <NotificationsActive />
            </Box>
            <Box sx={{ flexGrow: 2, maxWidth: 650 }}>
              <Typography variant="h5">
                Relancer les clients gagnants
              </Typography>
              <Typography>
                Relancer les clients qui ont gagnes et qui n'ont pas encore
                recuperer leur prix
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="h5">Envoyé</Typography>
                <Typography>0</Typography>
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="h5">Clicks</Typography>
                <Typography>0</Typography>
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="h5">Ouvert</Typography>
                <Typography>0</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                maxWidth: 125,
                width: "25%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ButtonLittleIcon
                icon={<Edit />}
                color="primary"
                sx={{ mr: 2 }}
                onClick={() => navigate("../edit-campaign/undelivery/1")}
              />
              {undelivery?.status ? (
                <Chip variant="filled" color="success" label="Actif" />
              ) : (
                <Chip variant="filled" color="error" label="Inactif" />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
