/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdminType {
  ADMIN = "ADMIN",
  BUSINESS_ADMIN = "BUSINESS_ADMIN",
  BUSINESS_USER = "BUSINESS_USER",
}

/**
 * amdin type
 */
export enum AdminTypeInput {
  ADMIN = "ADMIN",
  BUSINESS_ADMIN = "BUSINESS_ADMIN",
  BUSINESS_USER = "BUSINESS_USER",
}

export enum BGType {
  COLOR_LINEAR = "COLOR_LINEAR",
  COLOR_SINGLE = "COLOR_SINGLE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum BSType {
  CIRCULAR = "CIRCULAR",
  SQUARE = "SQUARE",
}

/**
 * File type enum
 */
export enum BackgroundType {
  COLOR_LINEAR = "COLOR_LINEAR",
  COLOR_SINGLE = "COLOR_SINGLE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

/**
 * File type enum
 */
export enum BoutonShopType {
  CIRCULAR = "CIRCULAR",
  SQUARE = "SQUARE",
}

export enum CampaignAutoType {
  INACTIVEUSER = "INACTIVEUSER",
  NEWUSER = "NEWUSER",
  UNDELIVERY = "UNDELIVERY",
}

export enum CampaignType {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

export enum ClientEnumType {
  PARTICULIER = "PARTICULIER",
  PRO = "PRO",
}

export enum ClientStatusType {
  PARTICULIER = "PARTICULIER",
  PRO = "PRO",
}

export enum CloseType {
  AFTERNOON = "AFTERNOON",
  MORNING = "MORNING",
  NIGHT = "NIGHT",
}

export enum CommandStatusType {
  DONE = "DONE",
  PENDING = "PENDING",
  REJECT = "REJECT",
  VALID = "VALID",
}

export enum CommandTraitementType {
  DIFFEREECOMMAND = "DIFFEREECOMMAND",
  TODAYCOMMAND = "TODAYCOMMAND",
}

export enum EmailTrackType {
  CLICK = "CLICK",
  DELIVERED = "DELIVERED",
  PENDING = "PENDING",
  SENT = "SENT",
}

export enum EmailType {
  ADMIN_BILLING = "ADMIN_BILLING",
  ADMIN_CHANGE_PASSWORD = "ADMIN_CHANGE_PASSWORD",
  ADMIN_CHANGE_SUBSCRIPTION = "ADMIN_CHANGE_SUBSCRIPTION",
  ADMIN_CREATE_EVNET = "ADMIN_CREATE_EVNET",
  ADMIN_CREATE_SUBSCRIPTION = "ADMIN_CREATE_SUBSCRIPTION",
  ADMIN_CREATE_TRADING = "ADMIN_CREATE_TRADING",
  ADMIN_EDIT_CLICK = "ADMIN_EDIT_CLICK",
  ADMIN_STATUS_ORDER = "ADMIN_STATUS_ORDER",
  CUSTOMER_NEW_USER = "CUSTOMER_NEW_USER",
  CUSTOMER_REGISTER = "CUSTOMER_REGISTER",
  CUSTOMER_REGISTER_GAME = "CUSTOMER_REGISTER_GAME",
  CUSTOMER_RESET_PASSWORD = "CUSTOMER_RESET_PASSWORD",
  CUSTOMER_SIGNIN = "CUSTOMER_SIGNIN",
  CUSTOMER_STATUS_BOOKING = "CUSTOMER_STATUS_BOOKING",
  CUSTOMER_STATUS_GAME = "CUSTOMER_STATUS_GAME",
  CUSTOMER_STATUS_ORDER = "CUSTOMER_STATUS_ORDER",
  CUSTOMER_VALIDATE_BOOKING = "CUSTOMER_VALIDATE_BOOKING",
  CUSTOM_VALIDATE_ORDER = "CUSTOM_VALIDATE_ORDER",
  TRADER_ARCHIV_BOOKING = "TRADER_ARCHIV_BOOKING",
  TRADER_ARCHIV_ORDER = "TRADER_ARCHIV_ORDER",
  TRADER_BUSINESS_CREATE = "TRADER_BUSINESS_CREATE",
  TRADER_CHANGE_SUBSCRIPTION = "TRADER_CHANGE_SUBSCRIPTION",
  TRADER_EDIT_CLICK = "TRADER_EDIT_CLICK",
  TRADER_EDIT_PASSWORD = "TRADER_EDIT_PASSWORD",
  TRADER_EDIT_PROFILE = "TRADER_EDIT_PROFILE",
  TRADER_EDIT_USER = "TRADER_EDIT_USER",
  TRADER_END_GAME = "TRADER_END_GAME",
  TRADER_END_ORDER = "TRADER_END_ORDER",
  TRADER_NEW_BOOKING = "TRADER_NEW_BOOKING",
  TRADER_NEW_CUSTOMER = "TRADER_NEW_CUSTOMER",
  TRADER_NEW_GAME = "TRADER_NEW_GAME",
  TRADER_NEW_ORDER = "TRADER_NEW_ORDER",
  TRADER_NEW_TEMPLATE = "TRADER_NEW_TEMPLATE",
  TRADER_NEW_USER = "TRADER_NEW_USER",
  TRADER_QUOTA = "TRADER_QUOTA",
  TRADER_REJECT_BOOKING = "TRADER_REJECT_BOOKING",
  TRADER_REJECT_ORDER = "TRADER_REJECT_ORDER",
  TRADER_STATUS_CUSTOMER = "TRADER_STATUS_CUSTOMER",
  TRADER_STATUS_GAIN = "TRADER_STATUS_GAIN",
  TRADER_STATUS_ORDER = "TRADER_STATUS_ORDER",
  TRADER_VALIDATE_BOOKING = "TRADER_VALIDATE_BOOKING",
  TRADRE_VALIDATE_ORDER = "TRADRE_VALIDATE_ORDER",
}

/**
 * File type enum
 */
export enum EnumFileType {
  IMAGE = "IMAGE",
  PDF = "PDF",
  VIDEO = "VIDEO",
}

/**
 * File type enum
 */
export enum FileBusinessEnum {
  CATALOGUE_PRODUCT = "CATALOGUE_PRODUCT",
  GALLERIES = "GALLERIES",
  MENU = "MENU",
  PLAT = "PLAT",
  UNKNOWN = "UNKNOWN",
}

export enum FileBusinessType {
  CATALOGUE_PRODUCT = "CATALOGUE_PRODUCT",
  GALLERIES = "GALLERIES",
  MENU = "MENU",
  PLAT = "PLAT",
  UNKNOWN = "UNKNOWN",
}

export enum FileType {
  IMAGE = "IMAGE",
  PDF = "PDF",
  VIDEO = "VIDEO",
}

export enum OrdreStatusType {
  DONE = "DONE",
  PENDING = "PENDING",
  REJECT = "REJECT",
  VALID = "VALID",
}

export enum SourceEnumType {
  FACEBOOK = "FACEBOOK",
  FORM = "FORM",
  GOOGLE = "GOOGLE",
  IMPORT = "IMPORT",
  MANUAL = "MANUAL",
}

export enum SourceType {
  FACEBOOK = "FACEBOOK",
  FORM = "FORM",
  GOOGLE = "GOOGLE",
  IMPORT = "IMPORT",
  MANUAL = "MANUAL",
}

export enum StatusEnumType {
  CLIENT = "CLIENT",
  INACTIF = "INACTIF",
  PROSPECT = "PROSPECT",
  VIP = "VIP",
}

export enum StatusType {
  CLIENT = "CLIENT",
  INACTIF = "INACTIF",
  PROSPECT = "PROSPECT",
  VIP = "VIP",
}

/**
 * The basic directions
 */
export enum SubdomainStatusEnum {
  AVAILABLE = "AVAILABLE",
  TAKEN = "TAKEN",
}

export enum TagExpired {
  DAY = "DAY",
  MONTH = "MONTH",
  UNDEFINED = "UNDEFINED",
  WEEK = "WEEK",
}

/**
 * Abonnement
 */
export interface AbonnementInput {
  name?: string | null;
  short?: string | null;
  description?: string | null;
  prixAbonnement?: number | null;
  numberSubscription?: number | null;
  numberBranding?: number | null;
  numberClick?: number | null;
  numberBooking?: number | null;
  numberGame?: number | null;
  prixEmail?: number | null;
  prixSms?: number | null;
  taxe?: number | null;
  prixTtc?: number | null;
  percentOff?: number | null;
  freeTrial?: number | null;
  productStripeId?: string | null;
  priceStripeId?: string | null;
  couponStripeId?: string | null;
  defaultSub?: boolean | null;
}

/**
 * add contact to list
 */
export interface AddContactType {
  email?: string | null;
  clientId?: number | null;
  sendinblueId?: number | null;
  listContact: ListType[];
}

/**
 * add multiple contact
 */
export interface AddMultipleType {
  listContact?: number[] | null;
  listId: number;
}

export interface ArgBookingType {
  businessId: number;
}

export interface ArgType {
  businessId: number;
}

export interface BusinessDinerServicesInput {
  surplace?: boolean | null;
  emporter?: boolean | null;
  drive?: boolean | null;
  serviceDrive?: boolean | null;
  delevery?: boolean | null;
  deliveryNoContact?: boolean | null;
}

/**
 * New create dashboard user data
 */
export interface BusinessInputStep1 {
  businessName: string;
  password: string;
  email: string;
  lastname: string;
  firstname: string;
  activity: string;
  phone: string;
  url: string;
  countryCode?: string | null;
}

/**
 * New create dashboard user data
 */
export interface BusinessUpdateInput {
  businessName?: string | null;
  email?: string | null;
  lastname?: string | null;
  firstname?: string | null;
  activity?: string | null;
  phone?: string | null;
  description?: string | null;
  coverPhoto?: string | null;
  coverVidz?: string | null;
  animatedCover?: boolean | null;
  logo?: string | null;
  siret?: string | null;
  address?: string | null;
  siteweb?: string | null;
  linkedin?: string | null;
  facebook?: string | null;
  twitter?: string | null;
  googleBusiness?: string | null;
  conditions?: string | null;
  mentions?: string | null;
  instagram?: string | null;
  seoDescription?: string | null;
  seoTitle?: string | null;
  confidentiality?: string | null;
  metas?: string | null;
  googleTag?: string | null;
  fbTag?: string | null;
  opening?: boolean | null;
  btnColor?: string | null;
  abonnementId?: number | null;
  customerId?: string | null;
  closeType?: string | null;
  paypalImg?: string | null;
  urlPaypalCount?: string | null;
  sendinblueKey?: string | null;
  countryCode?: string | null;
}

export interface CampaignAutoInpute {
  businessId?: number | null;
  type?: string | null;
  nameTemplate?: string | null;
  idSendinblue?: number | null;
  templateId?: number | null;
  template?: string | null;
  subject?: string | null;
  status?: boolean | null;
  email?: string | null;
  name?: string | null;
  id?: number | null;
}

export interface CampaignSmsType {
  name?: string | null;
  businessId?: number | null;
  templateId?: number | null;
  smsTemplateId?: number | null;
  message?: string | null;
  listContact?: number[] | null;
}

/**
 * Get campaign info
 */
export interface CampaignsInfoType {
  name: string;
  sender: string;
  subject: string;
  recipients: number[];
  htmlContent?: string | null;
  businessId?: number | null;
  templateId?: number | null;
  smsTemplateId?: number | null;
  message?: string | null;
}

/**
 * category inputs
 */
export interface CategoryInput {
  name: string;
  image?: string | null;
  status?: boolean | null;
  businessId: number;
}

/**
 * create click
 */
export interface ClickCollectInput {
  businessId: number;
  currency?: string | null;
  position?: string | null;
  thousandsSeparator?: string | null;
  decimalSeparator?: string | null;
  decimalNumber?: number | null;
  exchangeRate?: number | null;
  deferredCommand: boolean;
}

export interface ClientTagListType {
  clientTag?: ClientTagType[] | null;
}

export interface ClientTagType {
  tagId?: number | null;
  listId?: number | null;
  listContactId?: number | null;
}

/**
 * New create user data
 */
export interface CreateClientInput {
  email?: string | null;
  phone?: string | null;
  lastname?: string | null;
  firstname?: string | null;
  address?: string | null;
  city?: string | null;
  codePostal?: string | null;
  civilite?: string | null;
  source?: SourceEnumType | null;
  status?: StatusEnumType | null;
  password?: string | null;
  photo?: string | null;
  keywords?: string | null;
  type?: ClientStatusType | null;
  acceptEmail?: boolean | null;
  acceptSms?: boolean | null;
  acceptBusinessSms?: boolean | null;
  acceptBusinessEmail?: boolean | null;
  raisonSocial?: string | null;
  function?: string | null;
  affiliateBusinessId?: number | null;
}

/**
 * New create dashboard user data
 */
export interface CreateUtilisateurInput {
  email: string;
  password: string;
  firstname: string;
  lastname?: string | null;
  photo?: string | null;
  type?: AdminTypeInput | null;
  businessId?: number | null;
}

/**
 * hours inputs
 */
export interface DeleteHoursInput {
  hoursIds: number[];
}

/**
 * emailing
 */
export interface EmailMappingType {
  type: EmailType;
  templateId: number;
  emailParams: string;
}

/**
 * EventPartyType
 */
export interface EventPartyType {
  title?: string | null;
  description?: string | null;
  dateStart?: any | null;
  dateEnd?: any | null;
  hourStart?: any | null;
  hourEnd?: any | null;
  img?: string | null;
  guest?: number | null;
  price?: number | null;
  businessId?: number | null;
  status?: boolean | null;
}

/**
 * files inputs
 */
export interface FileInput {
  fileType: EnumFileType;
  type: FileBusinessEnum;
  url: string;
  name?: string | null;
}

/**
 * New create user data
 */
export interface ImportClientInput {
  email?: string | null;
  phone?: string | null;
  lastname?: string | null;
  firstname?: string | null;
  address?: string | null;
  city?: string | null;
  codePostal?: string | null;
  acceptEmail?: boolean | null;
  acceptSms?: boolean | null;
  civilite?: string | null;
  password?: string | null;
  photo?: string | null;
}

export interface ImportType {
  clients: ImportClientInput[];
}

export interface ListNumType {
  businessId: number;
  clientIds: number[];
}

export interface ListType {
  list: number;
  listSendinblueId: number;
}

/**
 * files inputs
 */
export interface LotInput {
  title: string;
  winFrequence: number;
  image?: string | null;
  color?: string | null;
  description?: string | null;
}

/**
 * New create dashboard user data
 */
export interface MenuPhotoUpdateInput {
  menus?: MenuPhotoUpdateInputType[] | null;
}

/**
 * New create dashboard user data
 */
export interface MenuPhotoUpdateInputType {
  url?: string | null;
  id?: number | null;
}

/**
 * List update hour
 */
export interface OpeningHourListType {
  list?: OpeningHourUpdateInputType[] | null;
}

/**
 * Update hour type
 */
export interface OpeningHourUpdateInputType {
  name?: string | null;
  end?: any | null;
  start?: any | null;
  day?: string | null;
  id?: number | null;
}

/**
 * files inputs
 */
export interface PaletteInput {
  bgColor1?: string | null;
  bgColor2?: string | null;
  bgColor?: string | null;
  bgImage?: string | null;
  bgViedo?: string | null;
  bgType: BackgroundType;
}

/**
 * New create dashboard user data
 */
export interface PlatPhotoUpdateInput {
  plats?: PlatPhotoUpdateInputType[] | null;
}

/**
 * New create dashboard user data
 */
export interface PlatPhotoUpdateInputType {
  url?: string | null;
  id?: number | null;
}

/**
 * Product inputs
 */
export interface ProductInput {
  name: string;
  image?: string | null;
  prix: number;
  description?: string | null;
  descriptionLong?: string | null;
  prixHorsTaxe: number;
  taxe: number;
  prixTTC: number;
  prixPromotionnels?: number | null;
  sousCategoryIds?: number | null;
  categoryIds?: number | null;
  preparationTime?: number | null;
  status?: boolean | null;
}

/**
 * QRCodeInput
 */
export interface QRCodeInput {
  lotsIds: number[];
  title?: string | null;
  logo?: string | null;
  textColor?: string | null;
  etablissement?: string | null;
  subtitle1?: string | null;
  subtitle2?: string | null;
  background?: string | null;
  colorEmpty?: string | null;
  borderLine?: string | null;
  qrcodeTitle?: string | null;
  dateEnd?: any | null;
  urlRegle?: string | null;
  urlPreview?: string | null;
  templateId?: number | null;
}

/**
 * reglement inputs
 */
export interface ReglementInput {
  nom_du_jeu: string;
  date_end: string;
  date_start: string;
  date_mise_en_ligne: string;
  capital: string;
  societe: string;
  ville_rcs: string;
  numero_siret: string;
  adresse_compelete: string;
  nombre_de_lot: number;
  sites: string;
  tagdiscount: string;
  activite: string;
}

/**
 * input for the reservation configuration
 */
export interface ReservationConfigurationInput {
  morning?: boolean | null;
  minutesIntervalle?: number | null;
  afternoon?: boolean | null;
  night?: boolean | null;
  startHourMorning?: any | null;
  endHourMorning?: any | null;
  startHourAfternoon?: any | null;
  endHourAfternoon?: any | null;
  startHourNight?: any | null;
  endHourNight?: any | null;
}

export interface SmsPackType {
  name?: string | null;
  price?: number | null;
  sms?: number | null;
  description?: string | null;
  taxe?: number | null;
  priceTtc?: number | null;
}

/**
 * sub-category inputs
 */
export interface SousCategoryInput {
  name: string;
  image?: string | null;
  description?: string | null;
  categoryIds: number;
  status?: boolean | null;
  businessId: number;
}

/**
 * Subscription Input
 */
export interface SubscriptionInput {
  abonnementId?: number | null;
  businessId?: number | null;
  payementUrl?: string | null;
  status?: string | null;
  stripeSubscriptionId?: string | null;
  couponStripeId?: string | null;
}

/**
 * files inputs
 */
export interface TableInput {
  name?: string | null;
  description?: string | null;
  place?: number | null;
  photo?: string | null;
  disponible?: boolean | null;
}

/**
 * tag input
 */
export interface TagsInput {
  name?: string | null;
  expired?: string | null;
  businessId?: number | null;
}

/**
 * files inputs
 */
export interface TemplateInput {
  name?: string | null;
  description?: string | null;
  bgColor?: string | null;
  bgImage?: string | null;
  title?: string | null;
  subtitle?: string | null;
  instructionText?: string | null;
  privacyLink?: string | null;
  privacyText?: string | null;
  conditionLink?: string | null;
  conditionText?: string | null;
  acceptNewsletter?: boolean | null;
  textColor?: string | null;
  fieldEmail?: boolean | null;
  fieldName?: boolean | null;
  fieldPhone?: boolean | null;
  fieldPrivacy?: boolean | null;
  fieldCondition?: boolean | null;
  btnText?: string | null;
  btnBg?: string | null;
  thankText?: string | null;
  btnTextColor?: string | null;
  winMessageTitle?: string | null;
  winMessageCode?: string | null;
  copyCodeText?: string | null;
  winCloseBtnText?: string | null;
  spinItemMainColor?: string | null;
  isSavedByOwner?: boolean | null;
  isSavedAsGlobal?: boolean | null;
  businessId?: number | null;
  fontFamily?: string | null;
  fieldEmailPlaceholder?: string | null;
  fieldNamePlaceholder?: string | null;
  fieldPhonePlaceholder?: string | null;
  fontSizeTitle?: string | null;
  fontSizeSubtitle?: string | null;
  fontSizeText?: string | null;
  winPictures?: string | null;
  loseMessageTitle?: string | null;
  loseMessage?: string | null;
  losePictures?: string | null;
}

/**
 * create template mail
 */
export interface TemplateMailType {
  name?: string | null;
  email?: string | null;
  subject?: string | null;
  templateName?: string | null;
  htmlContent?: string | null;
  template?: string | null;
}

/**
 * files input
 */
export interface ThemeInput {
  colorBtn?: string | null;
  colorBtnText?: string | null;
  police?: string | null;
  fontSize?: string | null;
  colorText?: string | null;
  mediaColor?: string | null;
  colorCardProduct?: string | null;
  colorTextCardProduct?: string | null;
  bsType: BoutonShopType;
}

/**
 * update category
 */
export interface UpdateCategoryInput {
  name?: string | null;
  image?: string | null;
  status?: boolean | null;
}

/**
 * update click
 */
export interface UpdateClickInput {
  businessId: number;
  currency?: string | null;
  position?: string | null;
  thousandsSeparator?: string | null;
  decimalSeparator?: string | null;
  decimalNumber?: number | null;
  exchangeRate?: number | null;
  deferredCommand: boolean;
}

/**
 * files inputs
 */
export interface UpdateLotInput {
  title?: string | null;
  winFrequence?: number | null;
  image?: string | null;
  description?: string | null;
  color?: string | null;
}

/**
 * Update product
 */
export interface UpdateProductInput {
  name?: string | null;
  prix?: number | null;
  description?: string | null;
  descriptionLong?: string | null;
  prixHorsTaxe?: number | null;
  taxe?: number | null;
  prixTTC?: number | null;
  prixPromotionnels?: number | null;
  image?: string | null;
  sousCategoryId?: number | null;
  categoryId?: number | null;
  preparationTime?: number | null;
  status?: boolean | null;
}

/**
 * update sub-category
 */
export interface UpdateSousCategoryInput {
  name?: string | null;
  image?: string | null;
  description?: string | null;
  categoryIds?: number | null;
  status?: boolean | null;
}

/**
 * Update user infos
 */
export interface UpdateUtilisateurInput {
  oldPassword?: string | null;
  password?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  photo?: string | null;
  type?: AdminTypeInput | null;
  email?: string | null;
  sendinblueKey?: string | null;
}

export interface UploadInput {
  name: string;
  type: string;
  data: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
