import { gql } from "@apollo/client";

export const GET_LIST_EVENT = gql`
  query ListEventParty($businessId: Float!) {
    listEventParty(businessId: $businessId) {
      businessId
      id
      title
      img
      description
      dateStart
      dateEnd
      hourStart
      hourEnd
      createdAt
      updatedAt
      status
      guest
      price
    }
  }
`

export const GET_DETAILS_EVENT = gql`
  query DetailEventParty($businessId: Float!, $detailEventPartyId: Float!) {
    detailEventParty(businessId: $businessId, id: $detailEventPartyId) {
      businessId
      id
      title
      img
      description
      dateStart
      dateEnd
      hourStart
      hourEnd
      createdAt
      updatedAt
      status
      guest
      price
    }
  }
`