import { Abonnement } from "./page/Abonnement"
import { Box, Container, Grid, styled } from "@mui/material"
import { Route, Routes } from "react-router-dom"
import logo from "../../../assets/logoWithoutBg.png"
import { Address } from "./page/Address"
import { BusinessDescription } from "./page/BusinessDescription"
import { Confirm } from "./page/Confirm"
import { FicheDiner } from "./page/DinerServices"
import { FicheBusiness } from "./page/FicheBusiness"
import { LogoAndCover } from "./page/LogoAndCover"
import { Payement } from "./page/payement"
import { FicheUser } from "./page/UserInfo"
import { useStyles } from "./styles"

const LeftBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}))
const RightBox = styled(Box)(() => ({
  background: "#fff",
  height: "100%",
  overflowY: "auto"
}))
export const Signup = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.bg}>
      <Container>
        <Box className={classes.container}>
          <Grid
            container
            spacing={0}
            sx={{ height: "100%", m: 0, boxShadow: "0 0 18px 0 #ccc" }}
          >
            <Grid item md={5}>
              <LeftBox>
                <Box sx={{ textAlign: "center" }}>
                  <img src={logo} alt="chef" style={{ height: '120px' }} />
                </Box>
              </LeftBox>
            </Grid>
            <Grid item md={7}>
              <RightBox sx={{ p: 2 }}>
                <Routes>
                  <Route index element={<FicheBusiness />} />
                  <Route path="informations" element={<FicheUser />} />
                  <Route path="address/:id" element={<Address />} />
                  <Route path="diner/:id" element={<FicheDiner />} />
                  <Route
                    path="description/:id"
                    element={<BusinessDescription />}
                  />
                  <Route path="logo/:id" element={<LogoAndCover />} />
                  <Route path="abonnement/:id" element={<Abonnement />} />
                  <Route path="payement" element={<Payement />} />
                  <Route path="confirm/:email" element={<Confirm />} />
                </Routes>
              </RightBox>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}
