import { Box } from "@mui/material"
import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Loading } from "../../components"
import { useApplicationContext } from "../../hooks"
import { UserAuthStateEnum } from "../../types/UserType"

export const Landing = (): JSX.Element => {
  const navigate = useNavigate()
  const { userAuthStatus } = useApplicationContext()

  const checkUser = useCallback((): void => {
    if (userAuthStatus === UserAuthStateEnum.UNAUTHENTICATED) {
      navigate("/login")
    }
    if (userAuthStatus === UserAuthStateEnum.AUTHENTICATED) {
      navigate("/discount")
    }
  }, [navigate, userAuthStatus])
  useEffect(() => {
    checkUser()
  }, [checkUser])

  return (
    <Box>
      <Loading />
    </Box>
  )
}
