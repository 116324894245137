import { TableColumnProps } from "@/components";
import { Box } from "@mui/system";
import { GetContactList_getContactList as ContactListType } from "@/graphql/emailing/types/GetContactList"

export const TableContact = (): { columnsView: TableColumnProps[] } => {
  const columnsView = [
    {
      id: 1,
      label: "Email",
      component: (data: ContactListType) => <Box>{data.client?.email}</Box>
    },
    {
      id: 2,
      label: "Nom",
      component: (data: ContactListType) => <Box>{data.client?.firstname} {data.client?.lastname}</Box>
    },
  ]
  return {
    columnsView
  }
}
