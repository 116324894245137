/* eslint-disable react-hooks/exhaustive-deps */
import { Cancel } from "@mui/icons-material"
import Edit from "@mui/icons-material/Edit"
import { Box, Typography, Card, Button, styled, CardProps } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { ButtonLittleIcon } from "../../Button"

interface BoxCardProps extends CardProps {
  color1: string
  color2: string
}

const BoxCard = styled(Card, {
  shouldForwardProp: (props) => props !== "color1" && props !== "color2"
})<BoxCardProps>(({ color1, color2 }) => ({
  background: `linear-gradient(0deg, ${color2}, ${color1})`,
  borderRadius: 15
}))
const ButtonIcon = styled(ButtonLittleIcon)(() => ({
  position: "absolute",
  top: 10,
  right: 10
}))
interface BgColorProps {
  colorTop?: string
  colorBottom?: string
  onSave: (color1: string, color2: string) => void
}
export const BgColor: FC<BgColorProps> = ({
  colorTop,
  colorBottom,
  onSave
}) => {
  const [color1, setColor1] = useState<string>("transparent")
  const [color2, setColor2] = useState<string>("black")
  const [edit, setEdit] = useState<boolean>(false)

  useEffect(() => {
    setColor1(colorTop || "transparent")
    setColor2(colorBottom || "black")
  }, [colorTop, colorBottom])

  const disabled = color1 === colorTop && color2 === colorBottom
  const mobilFullWidth = {
      
    "@media(max-width:663px)" : {
      width: "100%",
      minWidth: "100%"
    }
  }

  return (
    <Box sx={{ mx: 1, position: "relative" }}>
      <BoxCard
        sx={{
          width: "100%",
          maxWidth: 200,
          height: 350,
          py: 1,
          position: "relative",
          ...mobilFullWidth
        }}
        color1={color1}
        color2={color2}
      >
        <ButtonIcon icon={<Edit />} onClick={() => setEdit(!edit)} />
      </BoxCard>
      {edit ? (
        <Card sx={{ p: 2, mt: 2, background: "#ccc", position: "relative" }}>
          <Box>
            <ButtonIcon
              color="error"
              icon={<Cancel />}
              onClick={() => setEdit(!edit)}
            />
          </Box>
          <Typography variant="subtitle1">Paramètre de couleur :</Typography>
          <Typography variant="subtitle2">Couleur de fond 1 :</Typography>
          <input
            type="color"
            value={color1}
            onChange={(e) => setColor1(e.target.value)}
          />

          <Typography variant="subtitle2">Couleur de fond 2 :</Typography>
          <input
            type="color"
            value={color2}
            onChange={(e) => setColor2(e.target.value)}
          />
          <Box sx={{ py: 1 }}>
            <Button
              disabled={disabled}
              variant="contained"
              sx={{ textTransform: "none" , color: "#050005" }}
              onClick={() => {
                onSave(color1, color2)
                setEdit(false)
              }}
            >
              Enregistrer les modifications
            </Button>
          </Box>
        </Card>
      ) : null}
    </Box>
  )
}
