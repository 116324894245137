/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography, Box } from "@mui/material"
import moment from "moment"
import { TableColumnProps } from "../../../../../components"
import { LooserType } from "@/gql-types/graphql"

export const useLooserColumn = (): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "nom",
      label: "Nom ",
      component: (data: LooserType) => (
        <Typography>{data.clients.firstname} {data.clients.lastname}</Typography>
      )
    },
    {
      id: "email",
      label: "Email",
      component: (data: LooserType) => (
        <Typography>{data.clients.email}</Typography>
      )
    },
    {
      id: "date",
      label: "Date du scan",
      component: (data: LooserType) => (
        <Box>
          <Typography variant="subtitle2">
            {moment(data.createdAt).format("DD/MM/YYYY à HH:mm")}
          </Typography>
        </Box>
      )
    }
  ]
  return { columns }
}
