import { useState } from "react"
import { CustomModal } from "@/components/modals"
import {
  Box,
  Button,
  Grid,
  ListItemButton,
  ListItemText,
  Typography
} from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { TemplateInput } from "@/types"
import { useTemplateGlobal, useTemplateSpinner } from "@/hooks/templateSpinner"
import { CardSpinner } from "./CardSpinner"
import { useApplicationContext } from "@/hooks"

type ModalTemplateProps = TemplateInput & {
  onChoose: (item: TemplateInput) => void
}

export const ModalTemplateChoice = ({ onChoose }: ModalTemplateProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const { business } = useApplicationContext()
  const { data: templateSpinnerData } = useTemplateSpinner(business?.id)

  const [values, setValues] = useState<TemplateInput>({
    spinItemMainColor: ""
  })

  const handleClick = (item: TemplateInput) => {
    setValues(item)
  }

  const addClick = () => {
    setOpen(!open)
  }

  const chooseTemplate = () => {
    setOpen(false)
    onChoose(values)
  }

  return (
    <>
      <CustomModal noAction open={open} setOpen={setOpen} fullWidth>
        <Box position="relative">
          <Grid container>
            <Grid item xs={6}>
              <Grid sx={{ p: 3 }}>
                <Typography variant="subtitle1">Templates</Typography>
                {templateSpinnerData
                  ? templateSpinnerData.map((spinnerData) => {
                      return (
                        <ListItemButton
                          key={spinnerData.id}
                          divider
                          onClick={() => handleClick(spinnerData)}
                        >
                          <ListItemText
                            primary={spinnerData.name}
                            secondary={spinnerData.description}
                          />
                        </ListItemButton>
                      )
                    })
                  : null}
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ my: 2, maxHeight: "70vh", overflow: "auto" }}
            >
              <CardSpinner
                bgImage={values.bgImage}
                bgColor={values.bgColor}
                textColor={values.textColor}
                fontFamily={values.fontFamily}
                name={values.name}
                description={values.description}
                spinItemMainColor={values.spinItemMainColor}
                subtitle={values.subtitle}
                fieldEmailPlaceholder={values.fieldEmailPlaceholder}
                fieldCondition={values.fieldCondition}
                fieldPrivacy={values.fieldPrivacy}
                conditionText={values.conditionText}
                privacyText={values.privacyText}
                fieldPhonePlaceholder={values.fieldPhonePlaceholder}
                fieldPhone={values.fieldPhone}
                fieldNamePlaceholder={values.fieldNamePlaceholder}
                fieldName={values.fieldName}
                btnBg={values.btnBg}
                btnText={values.btnText}
                btnTextColor={values.btnTextColor}
                thankText={values.thankText}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              bottom: 0,
              left: 0,
              background: "white"
            }}
            position="sticky"
          >
            <Button variant="outlined" onClick={addClick}>
              Annuler
            </Button>
            <Button variant="contained" onClick={chooseTemplate}>
              <CheckIcon /> Choisr
            </Button>
          </Box>
        </Box>
      </CustomModal>
      <Box sx={{ p: 2, display: "flex", justifyContent: "end" }}>
        <Button
          variant="outlined"
          onClick={addClick}
          sx={{ textTransform: "none" }}
        >
          Choisir un template
        </Button>
      </Box>
    </>
  )
}
