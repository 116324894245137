import { BUSINESS_CATEGORY } from "@/graphql/business"
import {
  CategoryAll,
  CategoryAllVariables,
  CategoryAll_category,
  CategoryAll_sousCategory
} from "@/graphql/business/types/CategoryAll"
import { useQuery } from "@apollo/client"
import { useCallback, useEffect, useState } from "react"

export const useCategoryList = (businessId?: number) => {
  const [categories, setCategories] = useState<CategoryAll_category[]>([])
  const [categoriesPrincipales, setcategoriesPrincipales] = useState<
    CategoryAll_category[]
  >([])
  const [sousCategories, setSousCategories] = useState<
    CategoryAll_sousCategory[]
  >([])
  const { data, refetch, loading } = useQuery<
    CategoryAll,
    CategoryAllVariables
  >(BUSINESS_CATEGORY, {
    variables: {
      businessId: businessId as number
    },
    skip: !businessId
  })
  useEffect(() => {
    if (!data && businessId) {
      refetch()
    }
  }, [businessId])

  const mergeCategories = useCallback(() => {
    if (data?.category && data?.sousCategory) {
      setCategories((prev) =>
        data.category
          .concat(data.sousCategory as any)
          .sort((a, b) => (a.position > b.position ? 1 : -1))
      )
      setSousCategories(data.sousCategory)
      setcategoriesPrincipales(data.category)
    }
  }, [data?.category, data?.sousCategory])

  useEffect(() => {
    mergeCategories()
  }, [mergeCategories])


  return { categories, refetch, loading, sousCategories, categoriesPrincipales }
}
