import { ReglementInput } from "@/gql-types/graphql"
import { REGLEMENT, REGLEMENT_UPDATE } from "@/graphql/qrcode"
import { reglement, reglementVariables } from "@/graphql/qrcode/types/reglement"
import {
  updateReglement,
  updateReglementVariables
} from "@/graphql/qrcode/types/updateReglement"
import { useMutation, useQuery } from "@apollo/client"
import { useEffect } from "react"

export const useCurrentReglement = (idReglement?: number) => {
  const { data, refetch } = useQuery<reglement, reglementVariables>(REGLEMENT, {
    variables: {
      reglementId: idReglement as number
    },
    skip: !idReglement
  })

  const [updateReglement, { loading }] = useMutation<
    updateReglement,
    updateReglementVariables
  >(REGLEMENT_UPDATE)

  const modifyReglement = async (
    reglement: ReglementInput,
    reglementId: number
  ) => {
    const response = await updateReglement({
      variables: {
        reglement,
        reglementId
      }
    })
    return response.data
  }

  useEffect(() => {
    if (!data && idReglement) {
      refetch()
    }
  }, [data, idReglement, refetch])

  return {
    data: data?.reglement,
    refetch,
    modifyReglement,
    loading
  }
}
