import { EmailEditor } from "@/components/Email/EmailEditor/EmailEditor"
import { Box } from "@mui/material"

export const CreateTemplate = () => {
  return (
    <Box>
      <EmailEditor />
    </Box>
  )
}
