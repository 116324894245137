/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete, Edit } from "@mui/icons-material"
import { Typography, Box } from "@mui/material"
import moment from "moment"
import { ButtonLittleIcon, TableColumnProps } from "@/components"
import { TemplateSpinner_getTemplateSpinner as ISpinner } from "@/graphql/business/types/TemplateSpinner"
import { SpinnerTemplate } from "@/components/common/SpinnerTemplate"

interface TableSpinnerProps {
  onView?: (b: ISpinner) => void
  onRemove?: (id: number) => void
  onEdit?: (idTemplate: number) => void
}

export const tableListTemplate = ({
  onEdit,
  onRemove
}: TableSpinnerProps): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "name",
      label: "Nom du template ",
      component: (data?: ISpinner) => (
        <Typography
          sx={{
            color: "black",
            fontFamily: `${data?.fontFamily}`
          }}
        >
          {data?.name}
        </Typography>
      )
    },
    {
      id: "preview",
      label: "Visuel du Jeu",
      component: (data?: ISpinner) => (
        <Box>
          <SpinnerTemplate mainColor={data?.spinItemMainColor} />
        </Box>
      )
    },
    {
      id: "description",
      label: "description",
      component: (data?: ISpinner) => (
        <Typography
          sx={{
            color: "black",
            fontFamily: `${data?.fontFamily}`
          }}
        >
          {data?.description}{" "}
        </Typography>
      )
    },
    {
      id: "date",
      label: "date de création",
      component: (data?: ISpinner) => (
        <Typography
          sx={{
            color: "black",
            fontFamily: `${data?.fontFamily}`
          }}
        >
          {moment(data?.createdAt).format("DD/MM/YYYY")}{" "}
        </Typography>
      )
    },
    {
      id: "action",
      label: "Action",
      component: (data: ISpinner) => (
        <Box>
          <ButtonLittleIcon
            icon={<Edit />}
            sx={{ mr: 1 }}
            color="success"
            onClick={() => {
              if (onEdit) {
                onEdit(data.id)
              }
            }}
          />
          <ButtonLittleIcon
            icon={<Delete />}
            color="error"
            onClick={() => {
              if (onRemove) {
                onRemove(data.id)
              }
            }}
          />
        </Box>
      )
    }
  ]
  return { columns }
}
