import { useMutation } from "@apollo/client"
import {
  CreateClient,
  CreateClientVariables,
  CREATE_CLIENT
} from "../../graphql/clients"
import { CreateClientInput } from "../../types"

export const useCreateClient = () => {
  const [executeCreate, { loading }] = useMutation<
    CreateClient,
    CreateClientVariables
  >(CREATE_CLIENT)
  const createClient = async (businessId: number | null, data: CreateClientInput) => {
    try {
      await executeCreate({
        variables: {
          businessId,
          data
        }
      })
      return { success: "OK" }
    } catch (error) {
      return {
        error
      }
    }
  }
  return {
    loading,
    createClient
  }
}
