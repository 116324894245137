import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  styled,
  Avatar,
  Stack,
  TypographyProps,
  CardContent,
  Divider,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl
} from "@mui/material"

import { ChangeEvent, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { DatePickerBase, Dropzone, QueryResult } from "@/components"
import { Reglement } from "@/components/qrcode/Reglement"
import { CustomModal } from "@/components/modals"
import { EnumQrcodeRoute, QrcodeRouteContext } from "@/contexts/QrcodeContext"
import {
  useApplicationContext,
  useLots,
  useQRCode,
  useQRCodeAction
} from "@/hooks"
import { QRCodeInput, ReglementInput, TemplateInput } from "@/types"
import { useTemplateSpinner } from "@/hooks/templateSpinner"
import { CardSpinner } from "@/components/TemplateGames/CardSpinner"
import { CreateLot } from "../../ManageLots/CreateLot"
import Swal from "sweetalert2"
import { SpinnerTemplate } from "@/components/common/SpinnerTemplate"

interface TextProps extends TypographyProps {
  textColor?: string
}

const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "textColor"
})<TextProps>(({ textColor }) => ({
  color: textColor
}))

const BusinessNameText = styled(StyledText)(() => ({
  fontSize: 16,
  fontWeight: "bold"
}))

const TitleText = styled(StyledText)(() => ({
  fontSize: 18,
  fontWeight: "bold",
  lineHeight: "1rem",
  marginBottom: 10
}))

const SubTitle1Text = styled(StyledText)(() => ({
  fontSize: 14,
  fontWeight: 600
}))

const SubTitle2Text = styled(StyledText)(() => ({
  fontSize: 14,
  fontWeight: 600
}))

const defaultQRCode =
  "https://tagsfiles.s3.amazonaws.com/22423fc0-7a4f-42bf-b4e5-8cbe5f1af0fc-Wed%20May%2004%202022%2021%3A34%3A46%20GMT%2B0300%20%28East%20Africa%20Time%29"

export const CreateGame = () => {
  const { id: gameId } = useParams()
  const navigate = useNavigate()
  const { currentQrcodeId, setCurrentQrcodeId, setRouteQrcode } = useContext(QrcodeRouteContext)
  const [addLot, setAddLot] = useState<boolean>(false)
  const { business, dispatchSnack } = useApplicationContext()
  const { data: qrcodeData, refetch } = useQRCode(
    business?.id || parseInt(gameId as string)
  )

  const { data: templateSpinnerData, loading: spinnerLoading } =
    useTemplateSpinner(business?.id)

  const [values, setValues] = useState<QRCodeInput>({
    lotsIds: [],
    title: "",
    background: "",
    subtitle1: "",
    subtitle2: "",
    etablissement: "",
    logo: "",
    textColor: "#121212",
    colorEmpty: "",
    borderLine: "",
    dateEnd: null,
    qrcodeTitle: "",
    urlRegle: "",
    templateId: null
  })

  const [openReglement, setOpenReglement] = useState<boolean>(false)

  const { createQRCode, updateQRCode, loading } = useQRCodeAction()
  const { data, refetch: refetchLot } = useLots(parseInt(gameId as string))

  const [lots, setLots] = useState<number[]>([])

  const [image, setImage] = useState<string>(defaultQRCode)

  const [themeSpinner, setThemeSpinner] = useState<TemplateInput>({
    name: "",
    description: "",
    bgColor: "",
    bgImage: "",
    title: "",
    subtitle: "",
    instructionText: "",
    privacyLink: "",
    privacyText: "",
    conditionLink: "",
    conditionText: "",
    acceptNewsletter: false,
    textColor: "",
    fieldEmail: true,
    fieldName: true,
    fieldPhone: true,
    fieldPrivacy: false,
    fieldCondition: false,
    btnText: "",
    btnBg: "",
    thankText: "",
    btnTextColor: "",
    winMessageTitle: "",
    winMessageCode: "",
    copyCodeText: "",
    winCloseBtnText: "",
    spinItemMainColor: "",
    isSavedByOwner: false,
    isSavedAsGlobal: false,
    fontFamily: "",
    fieldEmailPlaceholder: "",
    fieldNamePlaceholder: "",
    fieldPhonePlaceholder: "",
    fontSizeTitle: "",
    fontSizeSubtitle: "",
    fontSizeText: ""
  })
  const [nameSelected, setNameSelected] = useState("")

  const handleChangeLots = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    if (checked) {
      setLots((prev) => prev.concat(parseFloat(name)))
    } else {
      setLots((prev) => prev.filter((id) => id !== parseFloat(name)))
    }
  }

  const isActive = () => {
    if (lots.length <= 0) return false
    const requiredField = [
      values.qrcodeTitle,
      values.urlRegle,
      values.dateEnd,
      values.subtitle1,
      values.title
    ]
    return requiredField.every((val) => Boolean(val))
  }

  const handleConfirm = async (valuesReglement: ReglementInput) => {
    const createReglement = await createQRCode(
      {
        ...values,
        lotsIds: lots
      },
      { ...valuesReglement },
      parseInt(gameId as string)
    )
    setOpenReglement(false)
    if (createReglement) {
      refetch()
      Swal.fire({
        icon: "success",
        title: "Création de jeu réussie",
        text: "",
        showConfirmButton: false,
        showCloseButton: true
      })
      navigate(-1)
      setRouteQrcode(EnumQrcodeRoute.LIST)
    }
  }

  const handleCreateQRCode = async () => {
    setOpenReglement(true)
  }
  useEffect(() => {
    if (business && !currentQrcodeId) {
      setValues((prev) => ({
        ...prev,
        title: `${business.businessName || ""} - ${business.activity || ""}`,
        background: business.coverPhoto || "",
        subtitle1: business.description || "",
        subtitle2: " ",
        etablissement: business.businessName || "",
        logo: business.logo || "",
        colorEmpty: ""
      }))
    }
  }, [business, currentQrcodeId])
  useEffect(() => {
    if (currentQrcodeId) {
      const active = qrcodeData?.find((qr) => {
        return qr.id === currentQrcodeId
      })

      if (!active) {
        // nothing to do
      } else {
        if (active.templateSpinnerId && templateSpinnerData) {
          const getIndexTemplate = templateSpinnerData
            ?.map((item) => item.id)
            .indexOf(active.templateSpinnerId)
          setNameSelected(`${active.templateSpinnerId}`)
          setThemeSpinner(templateSpinnerData[getIndexTemplate])
        }

        setValues((prev) => ({
          ...prev,
          title: active.title || "",
          background: active.background || "",
          subtitle1: active.subtitle1 || "",
          subtitle2: active.subtitle2 || " ",
          etablissement: active.etablissement || "",
          logo: active.logo || "",
          colorEmpty: active.colorEmpty || "",
          textColor: active.textColor,
          borderLine: active.borderLine || "",
          qrcodeTitle: active.qrcodeTitle || "",
          urlRegle: active.urlRegle || "",
          dateEnd: active.dateEnd || "",
          templateId: active.templateSpinnerId
        }))

        setLots(active.lots.map((l) => l.id))
        active.qrcodeImage && setImage(active.qrcodeImage)
      }
    }
  }, [gameId, currentQrcodeId, qrcodeData, templateSpinnerData])

  const handleSave = (name: string, value: string | number | null | Date) => {
    if (currentQrcodeId) {
      updateQRCode(
        currentQrcodeId,
        {
          [name]: value,
          lotsIds: lots
        },
        refetch
      )
    }
    setValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSaveForm = async () => {
    if (!currentQrcodeId) return
    try {
      await updateQRCode(currentQrcodeId, { ...values, lotsIds: lots }, refetch)
      navigate(-1)
      setRouteQrcode(EnumQrcodeRoute.LIST)
      setCurrentQrcodeId(undefined)
      Swal.fire({
        icon: "success",
        title: "Jeu modifié",
        text: ""
      })
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "modification échouée",
        text: "la modification du jeu a échouée"
      })
    }
  }

  const handleChangeBg = (url?: string) => {
    if (url) {
      setValues((prev) => ({
        ...prev,
        background: url
      }))
      if (currentQrcodeId) {
        updateQRCode(
          currentQrcodeId,
          {
            background: url,
            lotsIds: lots
          },
          refetch
        )
      }
    }
  }
  const handleChangeLogo = (url?: string) => {
    if (url) {
      setValues((prev) => ({
        ...prev,
        logo: url
      }))
      if (currentQrcodeId) {
        updateQRCode(
          currentQrcodeId,
          {
            logo: url,
            lotsIds: lots
          },
          refetch
        )
      }
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues((item) => {
      return {
        ...item,
        [name]: value
      }
    })
  }

  const handleChangeSelect = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target

    setNameSelected(value)

    const theme = templateSpinnerData?.find(
      (template) => template.id === parseFloat(value)
    )
    if (theme) {
      setThemeSpinner(theme)
      setValues((prev) => ({
        ...prev,
        [name]: theme.id
      }))
    }
  }

  const testQrcodeId = () => {

    if (values.urlRegle && typeof values.urlRegle === 'string') {
      const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w.-]*)*\/?$/
      const urlTrue = urlRegex.test(values.urlRegle);

      if (!urlTrue) {

        dispatchSnack({
          open: true,
          severity: "error",
          message: "Veuillez vérifier l'URL du Jeu"
        });
      }
      else if (currentQrcodeId) {
        handleSaveForm()
      } else {
        handleCreateQRCode()
      }
    }
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 2, textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={testQrcodeId}
          disabled={!isActive()}
          sx={{ textTransform: "none", color: "#050005" }}
        >
          {gameId
            ? "Enregistrer les modifications"
            : "Enregistrer le jeu et accepter le reglement"}
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "inline-block"
                }}
              >
                Information générales
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
                Titre de QR Code
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="qrcodeTitle"
                value={values.qrcodeTitle}
                onChange={handleChange}
              />
              <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
                Url de règlement du jeu
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="urlRegle"
                value={values.urlRegle}
                onChange={handleChange}
              />
              <Box sx={{ display: "flex" }}>
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Date de fin du Jeux *:{" "}
                  </Typography>
                  <DatePickerBase
                    format="DD MMMM YYYY"
                    value={values.dateEnd || Date.now()}
                    onChange={(date: Date | null) => {
                      handleSave("dateEnd", date)
                    }}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "inline-block"
                }}
              >
                Option du sticker QR code
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ margin: "auto", py: 2 }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", mt: 1 }}
                  component="span"
                >
                  Couleur du texte
                </Typography>
                <Box sx={{ display: "inline-block", ml: 2 }}>
                  <input
                    type="color"
                    value={values.textColor || ""}
                    name="textColor"
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        textColor: e.target.value
                      }))
                    }
                    onBlur={(e) => {
                      if (!currentQrcodeId) return
                      updateQRCode(
                        currentQrcodeId,
                        {
                          textColor: e.target.value,
                          lotsIds: lots
                        },
                        refetch
                      )
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
                  Etablissement
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="etablissement"
                  value={values.etablissement || business?.businessName}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
                  Titre du sticker:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
                  Sous-titre 1:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="subtitle1"
                  value={values.subtitle1}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>
                  Sous-titre 2:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="subtitle2"
                  value={values.subtitle2}
                  onChange={handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex"
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}
                  >
                    Ajouter logo
                  </Typography>
                  <Dropzone
                    photo={values.logo || business?.logo || ""}
                    message="Logo"
                    btnSx={{
                      width: { xs: 100, md: 150 },
                      height: 150,
                      margin: "auto"
                    }}
                    onFinished={handleChangeLogo}
                  />
                </Box>

                <Box sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: { xs: "0.75rem", sm: "1rem" } }}
                  >
                    Ajouter image de fond
                  </Typography>
                  <Dropzone
                    photo={values.background || business?.coverPhoto || ""}
                    message="Image de fond"
                    btnSx={{
                      height: 150,
                      width: { xs: 100, md: 300 },
                      margin: "auto"
                    }}
                    onFinished={handleChangeBg}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              margin: "auto",
              maxWidth: 420,
              textAlign: "center"
            }}
          >
            <Card
              sx={{
                p: 2,
                borderRadius: 5,
                background: `url(${values.background || business?.coverPhoto || ""
                  }) no-repeat center`,
                backgroundSize: "cover"
              }}
            >
              <Box sx={{ textAlign: "start" }}>
                <Stack direction="row" spacing={0.5}>
                  <Avatar
                    sx={{ width: 22, height: 22 }}
                    alt="Remy Sharp"
                    src="/static/images/logoSimple.png"
                  />
                  <SubTitle2Text textColor={values.textColor || ""}>
                    Wizitag.
                  </SubTitle2Text>
                </Stack>
              </Box>
              <Box sx={{ py: 1 }}>
                <Dropzone
                  photo={values.logo || business?.logo || ""}
                  message="Logo"
                  btnSx={{ width: 80, height: 80, margin: "auto" }}
                  onFinished={handleChangeLogo}
                />
                <Box sx={{ py: 2 }}>
                  <BusinessNameText textColor={values.textColor || ""}>
                    {values.etablissement}
                  </BusinessNameText>
                </Box>
              </Box>
              <Box sx={{ py: 2 }}>
                <TitleText textColor={values.textColor || ""}>
                  {values.title}
                </TitleText>
                <SubTitle1Text textColor={values.textColor || ""}>
                  {values.subtitle1}
                </SubTitle1Text>
              </Box>
              <Box sx={{ py: 2 }}>
                <img src={image} alt="" />
              </Box>
              <Box sx={{ py: 2 }}>
                <SubTitle2Text textColor={values.textColor || ""}>
                  {values.subtitle2}
                </SubTitle2Text>
              </Box>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: { xs: 0, md: "-16px" } }}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "inline-block"
                }}
              >
                Option du jeu
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1">
                Choisissez le template de jeu
              </Typography>
              <Box marginBottom={2}>
                <QueryResult
                  data={templateSpinnerData}
                  loading={spinnerLoading}
                >
                  <FormControl fullWidth>
                    <Select
                      id="demo-simple-select"
                      value={nameSelected}
                      name="templateId"
                      onChange={handleChangeSelect}
                    >
                      {templateSpinnerData
                        ? templateSpinnerData.map((item, index) => (
                          <MenuItem
                            key={`${item.name}-${item.id}`}
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        ))
                        : ""}
                    </Select>
                  </FormControl>
                </QueryResult>
              </Box>
              <Typography variant="subtitle1">
                Choisissez les lots pour votre jeu
                <small> (Vous devez choisir ou créer un lot pour pouvoir créer le jeu)</small>
              </Typography>
              <Box>
                <FormGroup>
                  {data?.lots.map((lot) => {
                    return (
                      <Box key={lot.id} sx={{}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={`${lot.id}`}
                              checked={lots.includes(lot.id)}
                              onChange={handleChangeLots}
                            />
                          }
                          label={
                            <Box>
                              <Typography variant="subtitle2">
                                {lot.title}
                                {" - "}
                                <Typography variant="caption">
                                  Fréquence de gain par scan : 1/
                                  {lot.winFrequence}
                                </Typography>
                              </Typography>
                            </Box>
                          }
                        />
                      </Box>
                    )
                  })}
                </FormGroup>
                <Box>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={() => setAddLot(true)}
                  >
                    Ajouter un lot
                  </Button>
                  <CreateLot
                    open={addLot}
                    setOpen={setAddLot}
                    afterCreate={() => refetchLot()}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: 0,
              borderRadius: 3,
              textAlign: "center",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {nameSelected && themeSpinner ? (
              <CardSpinner
                bgImage={themeSpinner?.bgImage}
                bgColor={themeSpinner.bgColor}
                logoUrl={values.logo || business?.logo || ""}
                textColor={themeSpinner.textColor}
                fontFamily={themeSpinner.fontFamily}
                name={values.qrcodeTitle ? values.qrcodeTitle : "Titre du jeu"}
                description={themeSpinner.description}
                spinItemMainColor={themeSpinner.spinItemMainColor}
                subtitle={themeSpinner.subtitle}
                fieldEmailPlaceholder={themeSpinner.fieldEmailPlaceholder}
                fieldCondition={themeSpinner.fieldCondition}
                fieldPrivacy={themeSpinner.fieldPrivacy}
                conditionText={themeSpinner.conditionText}
                privacyText={themeSpinner.privacyText}
                fieldPhonePlaceholder={themeSpinner.fieldPhonePlaceholder}
                fieldPhone={themeSpinner.fieldPhone}
                fieldNamePlaceholder={themeSpinner.fieldNamePlaceholder}
                fieldName={themeSpinner.fieldName}
                btnBg={themeSpinner.btnBg}
                btnText={themeSpinner.btnText}
                btnTextColor={themeSpinner.btnTextColor}
                thankText={themeSpinner.thankText}
                countryCode={business?.countryCode || "fr"}
              >
                <SpinnerTemplate
                  dataProps={
                    data?.lots.filter((lot) => lots.includes(lot.id)) || []
                  }
                  emptyColor={values.colorEmpty || ""}
                  mainColor={themeSpinner.spinItemMainColor as string}
                />
              </CardSpinner>
            ) : (
              <Box>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ textAlign: "center", maxWidth: "80%", margin: "auto" }}
                >
                  Vous n'avez pas encore de visuel de jeu
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        noAction
        btnClose
        title="Règlement du jeux"
        open={openReglement}
        setOpen={setOpenReglement}
      >
        <Reglement
          loading={loading}
          onCreateReglement={handleConfirm}
          onCancel={() => setOpenReglement(false)}
          defaultValue={values}
          business={business}
        />
      </CustomModal>
    </Box>
  )
}
