/* eslint-disable no-underscore-dangle */
import { useMutation } from "@apollo/client"
import { useContext } from "react"
import { SnackbarContext } from "../../contexts"
import { UPLOAD, UploadFile, UploadFileVariables } from "../../graphql"
import { UploadInput } from "../../types"

export const useFileUploader = () => {
  const snackbarContexts = useContext(SnackbarContext)

  const [uploadExec, { loading: uploadLoading, error: uploadError }] =
    useMutation<UploadFile, UploadFileVariables>(UPLOAD)
  const uploadFile = async (file: UploadInput): Promise<string | undefined> => {
    try {
      const fileData = await uploadExec({
        variables: {
          data: {
            ...file
          }
        }
      })
      if (fileData.data?.uploadFile) {
        return fileData.data.uploadFile
      }
      return undefined
    } catch (error) {
      snackbarContexts.dispatchSnack({
        open: true,
        message: "Erreur d'enregistrement de fichier !",
        severity: "error"
      })
      return undefined
    }
  }

  return {
    uploadError,
    uploadLoading,
    uploadFile
  }
}
