import { gql } from "@apollo/client"

export const BUSINESS_PROFIL = gql`
  query BusinessInfo($businessId: Float!) {
    businessInfo(businessId: $businessId) {
      id
      logo
      businessName
      activeBooking
      password
      email
      lastname
      firstname
      status
      activity
      phone
      siret
      url
      description
      siteweb
      address
      facebook
      linkedin
      twitter
      mentions
      conditions
      seoDescription
      seoTitle
      metas
      googleTag
      fbTag
      confidentiality
      instagram
      googleBusiness
      coverPhoto
      abonnementId
      coverVidz
	  animatedCover
      createdAt
      updatedAt
      dinerId
      clientCount
      clientSecret
      dinerServicesId
	  countryCode
      opening
      _count {
        files
        menusPhotos
        platPhotos
        businessHours
        Utilisateur
        QRCode
        Lots
      }
      Utilisateur {
        id
        email
        firstname
        lastname
        type
        photo
        createdAt
        businessId
      }
      diner {
        id
        surplace
        emporter
        drive
        serviceDrive
        delevery
        deliveryNoContact
        createdAt
        updatedAt
      }
      platPhotos {
        id
        url
      }
      menusPhotos {
        id
        url
      }
      businessHours {
        id
        day
        start
        end
        name
        createdAt
      }
      files {
        id
        url
        fileType
        type
        name
        createdAt
      }
      BusinessPalette {
        id
        bgImage
        bgColor1
        bgColor2
        bgColor
        bgViedo
        bgType
        createdAt
        businessId
      }
      BusinessTheme {
        id
        colorBtn
        colorBtnText
        colorCardProduct
        colorTextCardProduct
        mediaColor
        bsType
        police
        colorText
        fontSize
        businessId
      }
      Abonnements {
        abonnementId
        bookingCount
        businessId
        clickCount
        subscribeCount
        stripeSubscriptionId
        status
        payementUrl
      }
      ReservationConfiguration {
        id
        morning
        afternoon
        night
        startHourMorning
        endHourMorning
        startHourAfternoon
        endHourAfternoon
        startHourNight
        endHourNight
        minutesIntervalle
      }
      closeType
      paypalImg
      urlPaypalCount
      campaignAuto {
        id
        status
        type
        name
        nameTemplate
        subject
        template
        templateId
        idSendinblue
        email
      }
    }
  }
`
export const BUSINESS_CLIENT = gql`
  query BusinessClients($skip: Float!, $take: Float!, $businessId: Float!) {
    businessClients(skip: $skip, take: $take, businessId: $businessId) {
      id
      email
      phone
      auth
      firstname
      lastname
      status
      source
      photo
      createdAt
    }
  }
`

export const BUSINESS_INFO_NUM = gql`
  query BusinessInfoNumber($businessId: Float!) {
    businessInfoNumber(businessId: $businessId) {
      winners
      users
      clients
      menuPhoto
      platPhoto
      qrcode
      qrcodeActvie
      lots
      catalogues
      images
      videos
    }
  }
`

export const BUSINESS_CATEGORY = gql`
  query CategoryAll($businessId: Float!) {
    category(businessId: $businessId) {
      id
      name
      image
      status
      createdAt
      position
    }
    sousCategory(businessId: $businessId) {
      id
      name
      image
      status
      createdAt
      Category {
        id
        name
      }
      position
    }
  }
`

export const BUSINESS_CATEGORY_BY_ID = gql`
  query CategoryId($categoryId: Float!) {
    categoryById(categoryId: $categoryId) {
      id
      name
      image
      status
    }
  }
`

export const BUSINESS_SOUS_CATEGORY_BY_ID = gql`
  query SousCategoryId($sousCategoryId: Float!) {
    sousCategoryById(sousCategoryId: $sousCategoryId) {
      id
      name
      image
      status
      categoryId
    }
  }
`

export const BUSINESS_SOUS_CATEGORY = gql`
  query SousCategory($businessId: Float!) {
    sousCategory(businessId: $businessId) {
      id
      name
      image
      Category {
        id
        name
        image
      }
    }
  }
`

export const BUSINESS_PRODUCT = gql`
  query ProductId($id: Float!) {
    product(id: $id) {
      id
      name
      image
      businessId
      prix
      description
    }
  }
`

export const GET_PRODUCT_BY_SUB_CAT = gql`
  query ProductSubId($sousCategoryId: Float!) {
    getProductBySousCategory(sousCategoryId: $sousCategoryId) {
      id
      name
      description
      image
      taxe
      prixTTC
      categoryId
      prixPromotionnels
      descriptionLong
      preparationTime
      createdAt
    }
  }
`
export const BUSINESS_PRODUCT_ID = gql`
  query Product($productId: Float!) {
    getproductById(productId: $productId) {
      id
      name
      prix
      image
      sousCategoryId
      taxe
      prixPromotionnels
      descriptionLong
      description
      prixTTC
      prixHorsTaxe
      categoryId
      preparationTime
      status
    }
  }
`

export const PRODUCT_BY_BUSINESS = gql`
  query ProductByBusiness($businessId: Float!) {
    productByBusiness(businessId: $businessId) {
      id
      name
      image
      businessId
      prix
      prixTTC
      prixPromotionnels
      description
      preparationTime
      createdAt
      status
    }
  }
`

export const BUSINESS_GET_ALL_PRODUCT = gql`
  query ProductAllBusiness {
    getAllProduct {
      id
      name
      image
      prix
      prixTTC
      prixPromotionnels
      description
      preparationTime
      createdAt
      status
    }
  }
`

export const BUSINESS_GET_CLICK = gql`
  query businessGetClick {
    clickCollect {
      id
      businessId
      currency
      position
      thousandsSeparator
      decimalSeparator
      decimalNumber
      exchangeRate
    }
  }
`

export const BUSINESS_GET_CLICK_BY_ID = gql`
  query businessGetClickId($businessId: Float!) {
    clickCollectById(businessId: $businessId) {
      id
      businessId
      currency
      position
      thousandsSeparator
      decimalSeparator
      decimalNumber
      exchangeRate
	  deferredCommand
    }
  }
`
export const GET_PRODUCT_BY_CATEGORY = gql`
  query getProductByCategory($categoryId: Float!) {
    getProductByCategoryId(categoryId: $categoryId) {
      id
      name
      description
      image
      taxe
      prixTTC
      prix
      sousCategoryId
      prixPromotionnels
      descriptionLong
      preparationTime
      createdAt
      status
    }
  }
`

export const BUSINESS_GET_TEMPLATE_SPINNER = gql`
  query TemplateSpinner($businessId: Float!) {
    getTemplateSpinner(businessId: $businessId) {
      id
      name
      description
      bgColor
      bgImage
      instructionText
      title
      privacyLink
      subtitle
      privacyText
      conditionLink
      conditionText
      acceptNewsletter
      textColor
      fieldEmail
      fieldEmailPlaceholder
      fieldName
      fieldNamePlaceholder
      fieldPhone
      fieldPhonePlaceholder
      fieldPrivacy
      fieldCondition
      btnText
      btnBg
      fontFamily
      thankText
      btnTextColor
      winMessageTitle
      winMessageCode
      copyCodeText
      winCloseBtnText
      isSavedByOwner
      isSavedAsGlobal
      spinItemMainColor
      createdAt
    }
  }
`
export const BUSINESS_GET_TEMPLATE_SPINNER_BY_ID = gql`
  query TemplateSpinnerById($templateId: Float!) {
    getTemplateSpinnerById(templateId: $templateId) {
      id
      name
      description
      bgColor
      bgImage
      instructionText
      title
      privacyLink
      subtitle
      privacyText
      conditionLink
      conditionText
      acceptNewsletter
      textColor
      fieldEmail
      fieldEmailPlaceholder
      fieldName
      fieldNamePlaceholder
      fieldPhone
      fieldPhonePlaceholder
      fieldPrivacy
      fieldCondition
      btnText
      btnBg
      thankText
      fontFamily
      btnTextColor
      winMessageTitle
      winMessageCode
      copyCodeText
      winCloseBtnText
      isSavedByOwner
      isSavedAsGlobal
      spinItemMainColor
      createdAt
      winPictures
      loseMessageTitle
      losePictures
      loseMessage
    }
  }
`
export const GLOBAL_TEMPLATE_SPINNER = gql`
  query GetTemplateSpinnerGlobal {
    getTemplateSpinnerGlobal {
      id
      name
      description
      bgColor
      bgImage
      instructionText
      title
      privacyLink
      subtitle
      privacyText
      conditionLink
      conditionText
      acceptNewsletter
      textColor
      fieldEmail
      fieldEmailPlaceholder
      fieldName
      fieldNamePlaceholder
      fieldPhone
      fieldPhonePlaceholder
      fieldPrivacy
      fieldCondition
      btnText
      btnBg
      fontFamily
      thankText
      btnTextColor
      winMessageTitle
      winMessageCode
      copyCodeText
      winCloseBtnText
      isSavedByOwner
      isSavedAsGlobal
      spinItemMainColor
      createdAt
    }
  }
`

export const PRODUCT_BUSINESS_CATEGORY = gql`
  query getProductBusinessCategory($categoryId: Float!, $businessId: Float!) {
    getProductBusinessCategory(
      categoryId: $categoryId
      businessId: $businessId
    ) {
      id
      name
      description
      image
      taxe
      prixTTC
      prix
      sousCategoryId
      prixPromotionnels
      descriptionLong
      preparationTime
      createdAt
      status
    }
  }
`

export const PRODUCT_BUSINESS_SUBCATEGORY = gql`
  query getProductBusinessSubCategory(
    $sousCategoryId: Float!
    $businessId: Float!
  ) {
    getProductBusinessSubCategory(
      sousCategoryId: $sousCategoryId
      businessId: $businessId
    ) {
      id
      name
      description
      image
      taxe
      prixTTC
      prix
      sousCategoryId
      prixPromotionnels
      descriptionLong
      preparationTime
      createdAt
      status
    }
  }
`

export const GET_PENDING_COMMAND = gql`
  query Query($businessId: Float!) {
    pendingCommand(businessId: $businessId) {
      id
      name
      email
      phone
      description
      status
      clientId
      code
      createdAt
      archive
      commandTraitement
      products {
        commandsId
        id
        productId
        totalCount
        productPrice
        createdAt
      }
      client {
        firstname
        email
        phone
      }
    }
  }
`

export const GET_VALID_COMMAND = gql`
  query ValidCommand($businessId: Float!) {
    validCommand(businessId: $businessId) {
      id
      name
      email
      phone
      description
      status
      clientId
      code
      createdAt
      archive
      commandTraitement
      products {
        commandsId
        id
        productId
        productPrice
        totalCount
        createdAt
      }
      client {
        firstname
        email
        phone
      }
    }
  }
`

export const GET_REJECT_COMMAND = gql`
  query RejectCommand($businessId: Float!) {
    rejectCommand(businessId: $businessId) {
      id
      name
      email
      phone
      description
      status
      clientId
      code
      createdAt
      archive
      commandTraitement
      products {
        commandsId
        id
        productId
        productPrice
        totalCount
        createdAt
      }
      client {
        firstname
        email
        phone
      }
    }
  }
`

export const GET_DONE_COMMAND = gql`
  query DoneCommand($businessId: Float!) {
    doneCommand(businessId: $businessId) {
      id
      name
      email
      phone
      description
      status
      clientId
      code
      createdAt
      commandTraitement
      products {
        commandsId
        id
        productId
        productPrice
        totalCount
        createdAt
      }
      client {
        firstname
        email
        phone
      }
    }
  }
`

export const GET_CLIENT_COMMAND = gql`
  query ClientCommand($clientId: Float!) {
    clientCommand(clientId: $clientId) {
      businessId
      clientId
      code
      createdAt
      description
      email
      id
      name
      phone
      createdAt
      products {
        totalCount
        productId
        id
        createdAt
        commandsId
        createdAt
      }
      status
      business {
        businessName
        address
        url
      }
    }
  }
`

export const GET_CLIENT_COMMAND_ID = gql`
  query ClientCommandById($commandId: Float!, $clientId: Float!) {
    clientCommandById(commandId: $commandId, clientId: $clientId) {
      businessId
      clientId
      code
      createdAt
      description
      email
      id
      name
      phone
      address
      archive
      products {
        totalCount
        productId
        productPrice
        id
        createdAt
        commandsId
        createdAt
      }
      status
      business {
        businessName
        address
        url
      }
    }
  }
`

export const GET_ADMIN_INFO = gql`
  query GetUserAdminInfo {
    getUserAdminInfo {
      id
      firstname
      lastname
      email
      type
    }
  }
`

export const GET_CLIENT_BUSINESS = gql`
  query ClientBusiness($clientId: Float!) {
    ClientBusiness(clientId: $clientId) {
      id
      businessName
      createdAt
      email
    }
  }
`

export const GET_COUNT = gql`
  query GetCountPeriod($businessId: Float!, $periode: String!) {
    GetCountPeriod(businessId: $businessId, periode: $periode) {
      visitorCount
      commandCount
      winnerCount
      bookingCount
      customerChart {
        count
        name
        prev
      }
      visitorPourcentage
      commandPourcentage
      customerPourcentage
      winnerPourcentage
      bookingPourcentage
      commandChart {
        count
        name
        prev
      }
      customerCount
      winnerChart {
        count
        name
        prev
      }
      bookingChart {
        count
        name
        prev
      }
      visitorChart {
        count
        name
        prev
      }
    }
  }
`
