import { convertEnumBs } from "@/pages/business/Personnalization/CustomPage"
import { BoutonShopType, BSType, ThemeInput } from "@/types"
import {
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material"
import { Box } from "@mui/system"
import { FC } from "react"

interface ColorBtnProps {
  theme: ThemeInput | undefined
  onChange: (e: any) => void
}

export const ColorBtn: FC<ColorBtnProps> = ({ theme, onChange }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "relative" }}>
        <Box>
          <Typography variant="subtitle1">Paramètre de couleur :</Typography>
          <Box>
            <Typography variant="subtitle2">Couleur de fond :</Typography>
            <input
              name="colorBtn"
              type="color"
              value={theme?.colorBtn || "transparent"}
              onChange={(e) => onChange(e)}
            />
          </Box>
          <Box>
            <Typography variant="subtitle2">Couleur de texte :</Typography>
            <input
              name="colorBtnText"
              type="color"
              value={theme?.colorBtnText || "transparent"}
              onChange={(e) => onChange(e)}
            />
          </Box>
          <Typography variant="subtitle1">Paramètre des icons :</Typography>
          <Box>
            <Typography variant="subtitle2">
              Couleur des réseaux sociaux :
            </Typography>
            <input
              name="mediaColor"
              type="color"
              value={theme?.mediaColor || "#e63946"}
              onChange={(e) => {
                onChange(e)
              }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Choix des icônes addition et suppression :
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bsType"
                      checked={theme?.bsType === convertEnumBs(BSType.CIRCULAR)}
                      value={BoutonShopType.CIRCULAR}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(e) => onChange(e)}
                    />
                  }
                  label="Utiliser la bordure arrondie"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bsType"
                      checked={theme?.bsType === convertEnumBs(BSType.SQUARE)}
                      value={BoutonShopType.SQUARE}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(e) => onChange(e)}
                    />
                  }
                  label="Utiliser la bordure carrée"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
