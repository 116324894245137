/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  InputLabel,
  FormHelperText
} from "@mui/material"
import { FormEvent, useState, useRef, useEffect } from "react"
import TextField from "@mui/material/TextField"
import { useNavigate, useParams } from "react-router-dom"
import JoditEditor from "jodit-react"
import { useQuery } from "@apollo/client"
import { ProductInput } from "@/types"
import { useApplicationContext } from "@/hooks"
import { useProduct } from "@/hooks/category"
import { BUSINESS_GET_CLICK_BY_ID } from "@/graphql/business"
import { Dropzone } from "@/components/dropzone"
import { usePriceCalculation } from "@/hooks/usePriceCaclulation"
import { SwitchStyle } from "@/components/common"
import { useCategoryList } from "@/hooks/category/useCategoryList"
import { getCategory } from "@/utils/getCategories"
import { FormatPrice } from "@/components/FormatPrice"

export const ModalProduits = () => {
  const { dispatchSnack } = useApplicationContext()
  const { id } = useParams()
  const { createProduct } = useProduct()
  const [values, setValues] = useState<ProductInput>({
    name: "",
    prix: 0,
    image: "",
    prixHorsTaxe: 0,
    taxe: 20,
    prixPromotionnels: 0,
    prixTTC: 0,
    description: "",
    descriptionLong: "",
    sousCategoryIds: null,
    categoryIds: null,
    preparationTime: 0,
    status: true
  })
  const [isLong, setIsLong] = useState<boolean>(false)
  const [isLongLong, setIsLongLong] = useState<boolean>(false)
  const { business } = useApplicationContext()
  const businessId = business?.id || parseInt(`${id}`)
  const [currentSelectedCat, setCurrentSelectedCat] = useState<number>()
  const { categories, refetch, categoriesPrincipales, sousCategories } =
    useCategoryList(businessId)
  const editor = useRef(null)
  const navigate = useNavigate()
  const timeOption = [
    {
      label: "5 min",
      value: 5
    },
    {
      label: "10 min",
      value: 10
    },
    {
      label: "15 min",
      value: 15
    },
    {
      label: "20 min",
      value: 20
    },
    {
      label: "25 min",
      value: 25
    },
    {
      label: "30 min",
      value: 30
    },
    {
      label: "35 min",
      value: 35
    },
    {
      label: "40 min",
      value: 40
    },
    {
      label: "45 min",
      value: 45
    },
    {
      label: "50 min",
      value: 50
    },
    {
      label: "55 min",
      value: 55
    },
    {
      label: "1h",
      value: 60
    },
    {
      label: "> 1h",
      value: 70
    }
  ]
  const [required, setRequired] = useState({
    name: false,
    categoryIds: false,
    prixHorsTaxe: false,
    description: false
  })
  const { data: dataCurrency } = useQuery(BUSINESS_GET_CLICK_BY_ID, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })

  const { priceProcessed, totalPrice } = usePriceCalculation(
    Number(values.prixHorsTaxe) || 0,
    Number(values.taxe) || 0,
    Number(values.prixPromotionnels) || 0
  )
  const handleUpload = async (file?: string): Promise<void> => {
    if (file) {
      setValues((prev) => ({
        ...prev,
        image: file
      }))
    }
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    if (
      name === "prixHorsTaxe" ||
      name === "taxe" ||
      name === "prixPromotionnels" ||
      name === "preparationTime"
    ) {
      let prix = value
      if (prix < 0) {
        prix = 0
      }
      if ((name === "taxe" || name === "prixPromotionnels") && prix > 100) {
        prix = 100
      }
      setValues((prev) => ({
        ...prev,
        [name]: prix
      }))
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: value
      }))
      if (
        values.description?.length !== undefined &&
        values.description.length > 150
      ) {
        setIsLong(true)
      } else {
        setIsLong(false)
      }
    }
  }
  const handleBlur = (e: any) => {
    const { name, value } = e.target
    if (value) {
      setRequired((prev) => ({
        ...prev,
        [name]: false
      }))
      return
    }
    setRequired((prev) => ({
      ...prev,
      [name]: true
    }))
  }
  const handleDescription = (item: string) => {
    setValues({
      ...values,
      descriptionLong: item
    })
    if (item.length > 500) {
      setIsLongLong(true)
    } else {
      setIsLongLong(false)
    }
  }
  const handleStatus = (): void => {
    setValues({
      ...values,
      status: !values.status
    })
  }
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const businessId = business?.id || parseFloat(id as string)
    if (!businessId) {
      return
    }
    if (
      !values.name ||
      !values.description ||
      !values.prixHorsTaxe ||
      !values.categoryIds ||
      !values.descriptionLong
    ) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez completer tout les champs"
      })
      return
    } 

    const create = await createProduct(businessId, {
      ...values,
      prixHorsTaxe: Number(values.prixHorsTaxe),
      prixPromotionnels: Number(values.prixPromotionnels),
      taxe: Number(values.taxe)
    })
    if (create.success) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Ajouter avec succès"
      })
      navigate(-1)
      refetch()
    }
  }

  useEffect(() => {
    setValues((old) => ({
      ...old,
      prixTTC: totalPrice,
      prix: priceProcessed
    }))
  }, [priceProcessed, totalPrice])

  const handleSelectSubCat = (e: any) => {
    const { value } = e.target

    const getCatId = sousCategories.find(
      (sous) => sous.id === parseFloat(value)
    )?.Category?.id
    const findCAT = getCategory(categoriesPrincipales, getCatId as any)

    setValues((prev) => ({
      ...prev,
      categoryIds: findCAT?.id || null,
      sousCategoryIds: parseFloat(value)
    }))
  }
  const handleSelectCat = (e: any) => {
    const { value } = e.target
    const sous = sousCategories.filter(
      (s) => s.Category?.id === parseFloat(value)
    )
    if (sous.length) {
      setCurrentSelectedCat(parseFloat(value))
    } else {
      setCurrentSelectedCat(undefined)
      setValues((prev) => ({
        ...prev,
        sousCategoryIds: null
      }))
    }
    setValues((prev) => ({
      ...prev,
      categoryIds: parseFloat(value)
    }))
  }
  return (
    <Box sx={{ m: 3, py: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          mt: 2,
          mb: 2
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          Ajouter produit
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        <Grid item>
          <SwitchStyle change={handleStatus} status={values?.status} />
        </Grid>
      </Grid>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
          Nom du Produit
        </Typography>
        <Box>
          <TextField
            error={required.name}
            label="Nom du Produit"
            name="name"
            variant="outlined"
            fullWidth
            value={values.name || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            helperText={required.name && "le champ nom de produit est requis"}
          />
        </Box>
      </Box>
      <Grid container columnSpacing={1}>
        <Grid item xl={8} lg={12} md={12}>
          {categories ? (
            <Grid container columnSpacing={1}>
              <Grid item md={4} sm={3} xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                >
                  Catégorie
                </Typography>
                <FormControl fullWidth required error={required.categoryIds}>
                  <InputLabel id="demo-simple-select-label">
                    Catégorie
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="categoryIds"
                    label="Catégorie"
                    name="categoryIds"
                    value={values.categoryIds || ""}
                    onChange={handleSelectCat}
                    onBlur={handleBlur}
                  >
                    {categoriesPrincipales
                      ? categoriesPrincipales.map((category: any) => {
                          return (
                            <MenuItem value={category.id} key={category.id}>
                              {category.name}
                            </MenuItem>
                          )
                        })
                      : ""}
                  </Select>
                  {required.categoryIds && (
                    <FormHelperText>choisissez une catégorie</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                >
                  Sous catégorie
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="sousCategoryIds"
                    name="sousCategoryIds"
                    value={values.sousCategoryIds || ""}
                    onChange={handleSelectSubCat}
                  >
                    {sousCategories
                      ? sousCategories
                          .filter((ct) =>
                            currentSelectedCat
                              ? ct.Category?.id === currentSelectedCat
                              : true
                          )
                          .map((sousCategory: any) => {
                            return (
                              <MenuItem
                                value={sousCategory.id}
                                key={sousCategory.id}
                              >
                                {sousCategory.name}
                              </MenuItem>
                            )
                          })
                      : ""}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} sm={5} xs={12}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                >
                  Temps de préparation (en minute)
                </Typography>
                <Autocomplete
                  options={timeOption}
                  getOptionLabel={(option) => option.label}
                  sx={{
                    ".MuiOutlinedInput-root": { height: 43, padding: "3px" }
                  }}
                  onChange={(event, value) =>
                    setValues((prev) => ({
                      ...prev,
                      preparationTime: Number(value?.value)
                    }))
                  }
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      name="preparationTime"
                      sx={{ height: 43 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <Grid container columnSpacing={1}>
            <Grid item md={4} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
              >
                Prix hors taxe
              </Typography>
              <Box>
                <TextField
                  error={required.prixHorsTaxe}
                  label="Prix hors taxe"
                  name="prixHorsTaxe"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={values.prixHorsTaxe}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  helperText={required.prixHorsTaxe && "prix hors taxe requis"}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
              >
                Taxe (en %)
              </Typography>
              <Box>
                <TextField
                  name="taxe"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={values.taxe}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
              >
                Remise promotionnel (en %)
              </Typography>
              <Box>
                <TextField
                  name="prixPromotionnels"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={values.prixPromotionnels}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} justifyContent="flex-end">
            <Grid item md={4}>
              <Typography
                variant="body2"
                textAlign="right"
                sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
              >
                Prix TTC (hors promo)
              </Typography>
              <Box sx={{ fontWeight: "bold" }}>
                <FormatPrice
                  style={{
                    fontWeight: "bold !important",
                    textAlign: "right",
                    fontSize: 28
                  }}
                  position={dataCurrency?.clickCollectById?.position || "left"}
                  currency={
                    dataCurrency?.clickCollectById?.currency || "dollars"
                  }
                  separator={
                    dataCurrency?.clickCollectById?.thousandsSeparator ||
                    "space"
                  }
                  decimal={
                    dataCurrency?.clickCollectById?.decimalSeparator || "coma"
                  }
                  decNum={dataCurrency?.clickCollectById?.decimalNumber}
                  value={totalPrice}
                />
              </Box>
            </Grid>
            <Grid item md={4}>
              <Typography
                variant="body2"
                textAlign="right"
                sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
              >
                Prix TOTAL
              </Typography>
              <Box sx={{ fontWeight: "bold" }}>
                <FormatPrice
                  style={{
                    fontWeight: "bold !important",
                    textAlign: "right",
                    fontSize: 28
                  }}
                  position={dataCurrency?.clickCollectById?.position || "left"}
                  currency={
                    dataCurrency?.clickCollectById?.currency || "dollars"
                  }
                  separator={
                    dataCurrency?.clickCollectById?.thousandsSeparator ||
                    "space"
                  }
                  decimal={
                    dataCurrency?.clickCollectById?.decimalSeparator || "coma"
                  }
                  decNum={dataCurrency?.clickCollectById?.decimalNumber}
                  value={priceProcessed}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} lg={12} md={12} xs={12}>
          <Typography variant="body2" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
            Image produit
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "82%",
              mr: 1,
              mb: 1,
              position: "relative"
            }}
          >
            <Dropzone
              onFinished={handleUpload}
              message="Ajouter PDF ou png ou JPEG"
              photo={values.image || ""}
              btnSx={{ height: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Typography sx={{ fontWeight: "bold", mt: 4, mb: 2 }}>
          Description courte
        </Typography>
        <Box>
          <Box>
            <TextField
              label="Description courte"
              error={isLong || required.description}
              name="description"
              variant="outlined"
              fullWidth
              required
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                isLong ? "le nombre de charactère maximal est de 150" : null
              }
            />
            {required.description && (
              <FormHelperText error>description courte requise</FormHelperText>
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography sx={{ fontWeight: "bold", mt: 4, mb: 2 }}>
            Description longue
          </Typography>
          <Box>
            <JoditEditor
              ref={editor}
              value={values.descriptionLong || ""}
              onChange={handleDescription}
            />
            <Typography
              variant="caption"
              sx={{
                color: isLongLong ? "red" : "black"
              }}
            >
              Nombre de caractere saisie: {values.descriptionLong?.length}{" "}
              {isLongLong && "(le nombre de charactère maximal est atteint)"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", my: 2, gap: "10px" }}
      >
        <Button type="submit" onClick={onSubmit} variant="contained">
          Enregistrer
        </Button>
        <Button variant="outlined" onClick={() => navigate(-1)}>
          Annuler
        </Button>
      </Box>
    </Box>
  )
}
