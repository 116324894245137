import { ReactNode } from "react"
import { AdminType } from "./graphql-types"

export enum IFeatured {
  SIDENAV = "SIDENAV",
  PROFIL = "PROFIL"
}
export interface IWebRoutes {
  id: string
  path?: string
  index?: boolean
  element: JSX.Element
  role?: AdminType[]
  admin?: boolean
  type?: JSX.Element
  label?: string
  icon?: ReactNode
  featured?: IFeatured
  idInPack?: string
  children?: string[]
  routeFunction?: () => void
  subMenus?: {
    path?: string
    index?: boolean
    id: any
    label: string
    icon?: ReactNode
    routeFunction?: () => void
  }[]
}
