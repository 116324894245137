/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete, Edit } from "@mui/icons-material"
import { Typography, Box } from "@mui/material"
import moment from "moment"
import {
  ButtonLittleIcon,
  ClientStatus,
  TableColumnProps
} from "@/components"
import { Source } from "@/components/source/Source"
import { BusinessClients_businessClients as IClient } from "@/graphql/business"
import { StatusType } from "@/types"

interface useCLientColumnsProps {
  onView?: (b: IClient) => void
  onRemove?: (id: number) => void
  onChangeStatus?: (id: number, status: StatusType) => void
}

export const useClientComponent = ({
  onView,
  onRemove,
  onChangeStatus
}: useCLientColumnsProps): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "lastname",
      label: "Nom ",
      component: (data: IClient) => <Typography>{data.firstname}</Typography>
    },
    {
      id: "firstname",
      label: "Prénom",
      component: (data: IClient) => <Typography>{data.lastname}</Typography>
    },
    {
      id: "source",
      label: "Source",
      component: (data: IClient) => (
        <Typography>{Source(data.source).icon}</Typography>
      )
    },
    {
      id: "email",
      label: "E-mail",
      component: (data: IClient) => <Typography>{data.email}</Typography>
    },
    {
      id: "phone",
      label: "Téléphone",
      component: (data: IClient) => <Typography>{data.phone}</Typography>
    },
    {
      id: "status",
      label: "Statut",
      component: (data: IClient) => (
        <ClientStatus
          onChange={(status) =>
            onChangeStatus ? onChangeStatus(data.id, status) : {}
          }
          client={data as any}
        />
      )
    },
    {
      id: "date",
      label: "Date d'Inscription",
      component: (data: IClient) => (
        <Box>
          <Typography variant="subtitle2">
            {moment(data.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      )
    },
    {
      id: "action",
      label: "Editer",
      component: (data: IClient) => (
        <Box>
          <ButtonLittleIcon
            icon={<Edit />}
            color="info"
            sx={{ mr: 1 }}
            onClick={() => {
              if (onView) {
                onView(data)
              }
            }}
          />
          <ButtonLittleIcon
            icon={<Delete />}
            color="error"
            onClick={() => {
              if (onRemove) {
                onRemove(data.id)
              }
            }}
          />
        </Box>
      )
    }
  ]
  return { columns }
}
