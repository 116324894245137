import { gql } from "@apollo/client"

export const COMMAND_CREATE_NOTIFICATION = gql`
  subscription NotificationCommandSub($args: ArgType!) {
    notificationCommandSub(args: $args) {
      businessId
      message
    }
  }
`

export const BOOKING_CREATE_NOTIFICATION = gql`
  subscription NotificationBookingSub($args: ArgBookingType!) {
    notificationBookingSub(args: $args) {
      businessId
      message
    }
  }
`
