import { Route, Routes } from "react-router-dom"
import { ConfirmCommand } from "./ConfirmCommand"
import { RejectCommand } from "./RejectCommand"

export const CommandRoutes = () => {
  return (
    <Routes>
      <Route path=":idCommand/confirm" element={<ConfirmCommand />} />
      <Route path=":idCommand/reject" element={<RejectCommand />} />
    </Routes>
  )
}
