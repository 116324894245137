import {
  Box,
  Typography,
  Button,
  styled,
  Stack,
  Pagination,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Card,
  RadioGroup,
  Radio,
  Grid,
  FormControl,
  FormLabel
} from "@mui/material"
import React, { useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import TextField from "@mui/material/TextField"
import ImportExportIcon from "@mui/icons-material/ImportExport"
import { useNavigate } from "react-router-dom"
import PhoneInput from "react-phone-input-2"
import { configPagination } from "@/components/tables/config"
import { BaseTable, CustomModal, ConfirmModal } from "../../../components"
import { ClientsList_clientsList as IClient } from "../../../graphql"
import { useContactColumns } from "./components/useContactColumns"
import {
  useAllClient,
  useApplicationContext,
  useCreateClient,
  useUpdateClient
} from "../../../hooks"
import { Controller, useForm } from "react-hook-form"
import {
  ClientStatusType,
  CreateClientInput,
  SourceEnumType,
  StatusEnumType
} from "@/types"

const PhoneContainer = styled(Box)(() => ({
  "& .react-tel-input .form-control": {
    width: "100%",
    paddingBlock: 10
  }
}))

export const Contact = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const navigate = useNavigate()
  const { deleteClient, deleteLoading } = useUpdateClient()
  const { createClient } = useCreateClient()
  const [toDeleteId, setToDeleteId] = useState<number>()
  const [page, setPage] = useState<number>(1)
  const [options] = useState({
    take: 50,
    skip: 0
  })

  const { dispatchSnack } = useApplicationContext()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<CreateClientInput>({
    defaultValues: {
      address: "",
      email: "",
      password: "",
      city: "",
      civilite: "",
      codePostal: "",
      firstname: "",
      lastname: "",
      phone: "",
      photo: "",
      source: SourceEnumType.MANUAL,
      acceptEmail: true,
      acceptSms: true,
      status: StatusEnumType.CLIENT,
      type: ClientStatusType.PARTICULIER,
      keywords: "",
      raisonSocial: "",
      function: ""
    }
  })

  const addClick = () => {
    setOpen(!open)
  }

  const { data, loading, refetch } = useAllClient(options.take, options.skip)

  const handleView = (b: IClient) => {
    navigate(`${b.id}`)
  }
  const handleDelete = (idclient: number) => {
    setOpenDelete(true)
    setToDeleteId(idclient)
  }

  const { columns } = useContactColumns({
    onView: handleView,
    onRemove: handleDelete
  })

  const onSubmit = async (data: CreateClientInput) => {
    const create = await createClient(null, data)
    if (create.success) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Ajouter avec succès"
      })
      refetch()
    } else {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Les identifiants que vous avez entrer existe déja"
      })
    }
    setOpen(false)
  }

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleCancelDelete = () => {
    setToDeleteId(undefined)
    setOpenDelete(false)
  }
  const handleConfirmDelete = async () => {
    if (!toDeleteId) {
      setOpenDelete(false)
      return
    }
    const deleteClients = await deleteClient(toDeleteId)
    if (deleteClients) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "suppression avec succès"
      })
    }
    refetch()
    setToDeleteId(undefined)
    setOpenDelete(false)
  }

  const perPage = configPagination.rowPerPage
  const count = configPagination.count(data, perPage)
  return (
    <>
      <ConfirmModal
        open={openDelete}
        loading={deleteLoading}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de contact"
      >
        <Typography>Vous êtes sur le point de supprimer un contact, voulez-vous le supprimer?</Typography>
      </ConfirmModal>

      <CustomModal
        noAction
        title="Ajouter contact"
        open={open}
        setOpen={setOpen}
      >
        <Box>
          <form onSubmit={handleSubmit(onSubmit)} id="add_contact">
            <Typography sx={{ fontWeight: "bold", my: 2 }}>
              Information personnelles
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6} sx={{ marginBottom: "15px" }}>
                <TextField
                  error={errors.firstname ? true : false}
                  label="Prénom"
                  variant="outlined"
                  {...register("firstname", { required: true })}
                  helperText={errors.firstname && "Ce champ est requis"}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  label="Nom"
                  error={errors.lastname ? true : false}
                  fullWidth
                  variant="outlined"
                  {...register("lastname", { required: true })}
                  helperText={errors.lastname && "Ce champ est requis"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ marginBottom: "15px" }}>
                <TextField
                  label="Civilité"
                  error={errors.civilite ? true : false}
                  variant="outlined"
                  {...register("civilite", { required: true })}
                  helperText={errors.civilite && "Ce champ est requis"}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={errors.function ? true : false}
                  fullWidth
                  label="Fonction"
                  variant="outlined"
                  {...register("function", { required: true })}
                  helperText={errors.function && "Ce champ est requis"}
                />
              </Grid>
            </Grid>
            <Typography sx={{ fontWeight: "bold", mt: 2, mb: 3 }}>
              Coordonnées
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ marginBottom: "15px" }}>
                <TextField
                  error={errors.email ? true : false}
                  label="E-mail"
                  variant="outlined"
                  {...register("email", {
                    required: { value: true, message: "Champ requis" },
                    pattern: {
                      value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                      message: "Entrer un email valide"
                    }
                  })}
                  helperText={errors.email?.message}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneContainer>
                  <Controller
                    control={control}
                    name="phone"
                    rules={{ required: true }}
                    render={({ field: { ref, value, ...field } }) => (
                      <PhoneInput
                        {...field}
                        enableSearch
                        inputProps={{
                          ref,
                          required: true,
                          autoFocus: true
                        }}
                        country="fr"
                        value={value}
                        preferredCountries={["fr", "us"]}
                      />
                    )}
                  />
                </PhoneContainer>
                {errors.phone && (
                  <Box sx={{ color: "red" }}>Ce champ est requis</Box>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ marginBottom: "15px" }}>
                <TextField
                  label="Addresse"
                  variant="outlined"
                  {...register("address")}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Code Postal"
                  variant="outlined"
                  {...register("codePostal")}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginBottom: "15px" }}>
                <TextField
                  fullWidth
                  label="Ville"
                  variant="outlined"
                  {...register("city")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={errors.password ? true : false}
                  label="mot de passe"
                  fullWidth
                  variant="outlined"
                  type="password"
                  {...register("password", { required: true })}
                  helperText={errors.password && "Ce champ est requis"}
                />
              </Grid>
            </Grid>
            <Typography sx={{ fontWeight: "bold", mt: 2, mb: 3 }}>
              Information de gestion
            </Typography>
            <Box sx={{ my: 2 }}>
              <Card sx={{ p: 2 }} elevation={3}>
                <Typography variant="subtitle1">Type</Typography>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" />
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={ClientStatusType.PARTICULIER}
                    {...register("type", { required: true })}
                  >
                    <FormControlLabel
                      value={ClientStatusType.PARTICULIER}
                      control={<Radio />}
                      label="Particulier"
                    />
                    <FormControlLabel
                      value={ClientStatusType.PRO}
                      control={<Radio />}
                      label="Professionnel"
                    />
                  </RadioGroup>
                </FormControl>
              </Card>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                label="Raison sociale"
                variant="outlined"
                fullWidth
                {...register("raisonSocial")}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <Card sx={{ p: 2 }} elevation={3}>
                <Typography variant="subtitle1">Statut</Typography>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" />
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={StatusEnumType.CLIENT}
                    {...register("status", { required: true })}
                  >
                    <FormControlLabel
                      value={StatusEnumType.CLIENT}
                      control={<Radio />}
                      label="Client"
                    />
                    <FormControlLabel
                      value={StatusEnumType.VIP}
                      control={<Radio />}
                      label="V.I.P"
                    />
                    <FormControlLabel
                      value={StatusEnumType.PROSPECT}
                      control={<Radio />}
                      label="Prospect"
                    />
                  </RadioGroup>
                </FormControl>
              </Card>
            </Box>
            <Box>
              <FormGroup
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Controller
                  name="acceptEmail"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          ref={ref}
                          checked={value || false}
                        />
                      }
                      label="Inscription aux campagnes email"
                    />
                  )}
                />
                <Controller
                  name="acceptSms"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          ref={ref}
                          checked={value || false}
                        />
                      }
                      label="Inscription aux campagnes SMS"
                    />
                  )}
                />
              </FormGroup>
            </Box>
            <Typography variant="subtitle1">Mots-Clés</Typography>
            <Box sx={{ my: 2 }}>
              <TextField
                label="Mot Clés"
                variant="outlined"
                fullWidth
                {...register("keywords")}
              />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Button onClick={() => setOpen(false)}>Annuler</Button>
              <Button
                disabled={loading}
                variant="contained"
                type="submit"
                startIcon={
                  loading ? (
                    <CircularProgress style={{ width: 25, height: 25 }} />
                  ) : null
                }
                sx={{ color: "#050005" }}
              >
                Ajouter
              </Button>
            </Box>
          </form>
        </Box>
      </CustomModal>

      <Box sx={{ p: 2 }}>
        <Box sx={{ py: 3 }}>
          <BaseTable
            title="Gérer les contact"
            loading={loading}
            columns={columns}
            topComponent={
              <Box sx={{ py: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="text"
                    onClick={addClick}
                    sx={{ textTransform: "none" }}
                  >
                    <AddIcon />
                    Ajouter un utilisateur
                  </Button>
                </Box>
              </Box>
            }
            data={data || []}
            page={page - 1}
            perPage={perPage}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Stack>
            <Pagination
              color="primary"
              count={count}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          </Stack>
        </Box>
      </Box>
    </>
  )
}
