import { REJECT_COMMAND } from "@/graphql/business"
import { useMutation } from "@apollo/client"
import { Box, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

export const RejectCommand = () => {
  const { idCommand } = useParams()
  const [rejectFn] = useMutation(REJECT_COMMAND)
  const [loading, setLoading] = useState(true)
  const [commandState, setCommandState] = useState(true)
  useEffect(() => {
    rejectFn({
      variables: {
        commandId: idCommand
      }
    })
      .then(() => setCommandState(true))
      .catch(() => setCommandState(false))
      .finally(() => setLoading(false))
  })
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          {commandState ? (
            <Box>Commande rejeté</Box>
          ) : (
            <Box>Commande échoué</Box>
          )}
        </Box>
      )}
    </Box>
  )
}
