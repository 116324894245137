import { Box } from "@mui/material"
import { Route, Routes } from "react-router-dom"
import { Notification } from "./Notification"
import { InfoTemplate } from "./infoTemplate"
import { TemplateBuilder } from "./templateBuilder"
import { ListTemplate } from "./listTemplate"

export const NotificationRouter = () => {
  return (
    <Box>
      <Routes>
        <Route index element={< Notification />} />
        <Route path="create" element={< InfoTemplate />} />
        <Route path="edit/:id" element={< InfoTemplate />} />
        <Route path="template" element={< TemplateBuilder />} />
        <Route path="list_template" element={< ListTemplate />} />
      </Routes>
    </Box>
  )
}
