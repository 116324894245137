import { Routes, Route } from "react-router-dom"
import { ContainerWithMenu } from "../components"
import { PrivateRoute } from "./PrivateRoute"
import { useApplicationContext, useRouteValidation } from "../hooks"
import { Landing, SignIn, Signup } from "../pages"
import { NotFound } from "../pages/NotFound"
import { useSubscription } from "@apollo/client"
import {
  BOOKING_CREATE_NOTIFICATION,
  COMMAND_CREATE_NOTIFICATION
} from "@/graphql/business/subscription"
import { useSnackbar } from "notistack"
import { useMemo } from "react"
import { Payment } from "@/pages/payement/payment"

const PrivateRouters = (): JSX.Element => {
  const { routes } = useRouteValidation()

  return (
    <Routes>
      {routes.map(({ element, index, id, path }) => (
        <Route key={id} index={index} path={path} element={element} />
      ))}
    </Routes>
  )
}
export const MainRouter = (): JSX.Element => {
  const { business } = useApplicationContext()
  const { data: notifCommand, loading: loadingCommand } = useSubscription(
    COMMAND_CREATE_NOTIFICATION,
    {
      variables: {
        args: {
          businessId: business?.id
        }
      }
    }
  )
  const { data: notifBooking, loading: loadingBooking } = useSubscription(
    BOOKING_CREATE_NOTIFICATION,
    {
      variables: {
        args: {
          businessId: business?.id
        }
      }
    }
  )
  const { enqueueSnackbar } = useSnackbar()
  const enqueueNotif = (msg: string) => {
    enqueueSnackbar(msg, {
      anchorOrigin: {
        horizontal: "right",
        vertical: "top"
      },
      variant: "info"
    })
  }

  useMemo(() => {
    if (notifCommand && !loadingCommand) {
      enqueueNotif(notifCommand.notificationCommandSub.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCommand, notifCommand])

  useMemo(() => {
    if (notifBooking && !loadingBooking) {
      enqueueNotif(notifBooking.notificationBookingSub.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingBooking, notifBooking])

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/signup/*" element={<Signup />} />
      <Route path="/payment/*" element={< Payment />}/>
      <Route
        path="/discount/*"
        element={
          <PrivateRoute>
            <ContainerWithMenu>
              <PrivateRouters />
            </ContainerWithMenu>
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
