/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography, Box, Chip } from "@mui/material"
import moment from "moment"
import { BaseTableProps, TableColumnProps } from "@/components"
import {
  UPDATE_WINNER,
  WinnerGames_winnerGames as IWinner
} from "@/graphql/qrcode"
import { WinnerStatus } from "@/components/common"
import { useMutation } from "@apollo/client"

interface useClientProps {
  onEdit: () => void
}

export const useClientComponent = ({
  onEdit
}: useClientProps): { columns: TableColumnProps[] } => {
  const [updateWinner] = useMutation(UPDATE_WINNER)
  const upStatus = async (item: boolean, id: number): Promise<void> => {
    updateWinner({
      variables: {
        updateWinnerLotId: id,
        status: item
      }
    })

	onEdit()
  }
  const columns: TableColumnProps[] = [
    {
      id: "address",
      label: "Nom ",
      component: (data: IWinner) => (
        <Typography>
          {data?.clients?.firstname} {data?.clients?.lastname}
        </Typography>
      )
    },
    {
      id: "email",
      label: "Email ",
      component: (data: IWinner) => (
        <Typography>{data?.clients?.email}</Typography>
      )
    },
    {
      id: "phone",
      label: "Téléphone",
      component: (data: IWinner) => (
        <Typography>{data?.clients?.phone}</Typography>
      )
    },
    {
      id: "code",
      label: "Code de gain",
      component: (data: IWinner) => (
        <Chip label={data?.code} variant="outlined" color="info" size="small" />
      )
    },
    {
      id: "lot",
      label: "Lot gagné",
      component: (data: IWinner) => <Typography>{data?.lots?.title}</Typography>
    },
    {
      id: "status",
      label: "Statut",
      component: (data: IWinner) => {
        if (data)
          return (
            <WinnerStatus
              item={data.retrieve}
              id={data.id}
              onChange={upStatus}
            />
          )
        return null
      }
    },
    {
      id: "date",
      label: "Date du scan",
      component: (data: IWinner) => (
        <Box>
          <Typography variant="subtitle2">
            {moment(data.createdAt).format("DD/MM/YYYY à HH:mm")}
          </Typography>
        </Box>
      )
    }
  ]
  return { columns }
}
