import { Box, Button, Card, Grid, Typography } from "@mui/material"
import moment from "moment"
import { SyntheticEvent, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { QRCodeCard } from "@/components/qrcode"
import { QrcodeRouteContext, EnumQrcodeRoute } from "@/contexts/QrcodeContext"
import { useApplicationContext, useQRCode } from "@/hooks"
import { ListGagnant } from "./Clients"
import { ListLooser } from "./Loosers"

export const QRCodeHome = () => {
  const navigate = useNavigate()
  const { business } = useApplicationContext()
  const { currentQrcodeId, setRouteQrcode, setCurrentQrcodeId } =
    useContext(QrcodeRouteContext)
  const { id } = useParams()
  const { data } = useQRCode(business?.id)

  const handleToCreateGame = (e: SyntheticEvent) => {
    e.preventDefault()
    setCurrentQrcodeId(undefined)
    setRouteQrcode(EnumQrcodeRoute.CREATE)
  }
  const qurcodeIdParams = parseFloat(id as string)
  const activeQRCode = data?.find(
    (qrcode) => qrcode.id === (currentQrcodeId || qurcodeIdParams)
  )

  const handleToEditGame = (e: SyntheticEvent) => {
    e.preventDefault()
    setCurrentQrcodeId(activeQRCode?.id || currentQrcodeId)
    // setRouteQrcode(EnumQrcodeRoute.CREATE)
    navigate("update-game")
  }

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 2, maxWidth: 500, width: "100%", margin: "auto" }}>
            <Box sx={{ textAlign: "center" }}>
              {activeQRCode && (
                <Button variant="outlined" onClick={handleToEditGame}>
                  Modifier l'options du Jeu et du code QR
                </Button>
              )}
            </Box>
            <Box>
              {!activeQRCode ? (
                <Box sx={{ textAlign: "center" }}>
                  <Typography>Vous n'avez pas encore de Code QR</Typography>
                  <Button variant="outlined" onClick={handleToCreateGame}>
                    Créer un Jeux
                  </Button>
                </Box>
              ) : (
                <Box sx={{ textAlign: "center", py: 2 }}>
                  <QRCodeCard qrcode={activeQRCode} />
                </Box>
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 2 }} elevation={0}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <Typography variant="subtitle1">Total de scanner :</Typography>
                <Typography variant="subtitle2">
                  {activeQRCode?.scanTotal} scans
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography variant="subtitle1">Total de Gagnants :</Typography>
                <Typography variant="subtitle2">
                  {activeQRCode?.winnerTotal} scans
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography variant="subtitle1">Total de Perdants :</Typography>
                <Typography variant="subtitle2">
                  {(activeQRCode?.scanTotal || 0) -
                    (activeQRCode?.winnerTotal || 0)}{" "}
                  scans
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography variant="subtitle1">Dernier scan :</Typography>
                <Typography variant="subtitle2">
                  {activeQRCode?.updatedAt
                    ? moment(activeQRCode?.updatedAt).format(
                        "DD MMMM YYYY à HH:mm"
                      )
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          {activeQRCode ? <ListGagnant qrcodeId={activeQRCode.id} /> : null}
          {activeQRCode ? <ListLooser qrcodeId={activeQRCode.id} /> : null}
        </Grid>
      </Grid>
    </Box>
  )
}
