/* eslint-disable no-console */
import { useLazyQuery, useQuery } from "@apollo/client"
import { ClientsList, ClientsListVariables, CLIENT_LIST } from "../../graphql"
import {
  ClientById,
  ClientByIdVariables,
  CLIENT_BY_ID
} from "../../graphql/clients"

export const useAllClient = (take: number, skip: number) => {
  const { data, loading, error, refetch } = useQuery<
    ClientsList,
    ClientsListVariables
  >(CLIENT_LIST, {
    variables: {
      take,
      skip
    }
  })
  return {
    data: data?.clientsList,
    loading,
    error,
    refetch
  }
}

export const useOneClient = () => {
  const [getClientExecute, { data, loading, refetch }] = useLazyQuery<
    ClientById,
    ClientByIdVariables
  >(CLIENT_BY_ID)
  const getClient = async (id: number) => {
    try {
      const client = await getClientExecute({
        variables: {
          clientId: id
        }
      })
      return client.data?.clientById
    } catch (error) {
      console.log(error)
      return null
    }
  }
  return { getClient, loading, data: data?.clientById, refetch }
}
