import { FC } from "react"
import { Box } from "@mui/material"
import { ClientInfos } from "./ClientInfos/ClientInfos"
import { ActiviteInfos } from "./ActiviteInfos/ActiviteInfos"
import { TabsWithRoutes, TabsWithRoutesProps } from "@/components"

interface GererClientsProps {
  isClient?: boolean
}

export const GererClients: FC<GererClientsProps> = () => {
  const tabs: TabsWithRoutesProps["tabs"] = [
    {
      component: <ClientInfos />,
      id: "1",
      label: "Informations du client",
      index: true
    },
    {
      component: <ActiviteInfos />,
      id: "2",
      label: "Activités",
      path: "activitie"
    }
  ]

  /****responsive styles */
  const mobSpace = {
    "@media(max-width:767px)" : {
      mt: 2,
      mx: 2
    }
  }

  /***** end style *******/

  return (
    <Box sx={ mobSpace }>
      <TabsWithRoutes tabs={tabs} pathBase="" />
    </Box>
  )
}
