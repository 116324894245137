import { CommandTraitementType } from "@/types"
import { Visibility } from "@mui/icons-material"
import { Box, Card, CardContent, Chip, Typography, Divider, Button } from "@mui/material"
import moment from "moment"
import { Key } from "react"
import { Cancel, CheckCircle } from "@mui/icons-material"
import { FormatPrice } from "@/components/FormatPrice"
import { ButtonLittleIcon } from "@/components"
import { ProductByBusiness } from "@/graphql/business/types/ProductByBusiness"
import { ClientInfoType, ProductTaskType } from "@/types/DndInitTypes"

type CardProps = {
  businessProducts?: ProductByBusiness
  currencyType?: any
  commandType: any
  commandeId: number
  products: ProductTaskType[]
  createdAt: Date
  archive?: boolean
  id?: string
  name?: string
  email?: string
  adresse?: string
  phone?: number
  description?: string
  status?: string
  clientId: number
  code?: number
  commandTraitement?: CommandTraitementType
  index?: number
  client?: ClientInfoType
  onEmmit: (commandId: number, clientId: number, products: ProductTaskType[]) => void
  onChange: (id: number, status: string) => void
}

export const CardCommand = ({
  id,
  clientId,
  commandTraitement,
  products,
  commandType,
  status,
  createdAt,
  currencyType,
  archive,
  name,
  email,
  phone,
  businessProducts,
  onEmmit,
  onChange
}: CardProps) => {
  const commandeId = Number(id)
  const clientCommandList = products.map((item: any) => {
    return item.productId
  })
  const productByCard = businessProducts?.productByBusiness.filter((item) =>
    clientCommandList.includes(item.id)
  )
  const processedProduct = productByCard
    ? productByCard.map((i: any, index: number) => {
      return {
        ...i,
        ...products[index],
        totalPrice: products[index].productPrice * products[index].totalCount
      }
    })
    : null
  return (
    <Card sx={{ mb: 1 }}>
      <CardContent
        sx={{
          position: "relative",
          pb: "16px !important"
        }}
      >
        <ButtonLittleIcon
          sx={{
            backgroundColor: "#2196f3",
            position: "absolute",
            right: 10,
            top: 10
          }}
          icon={<Visibility />}
          onClick={() => onEmmit(commandeId, clientId, products)}
        />
        {commandTraitement === CommandTraitementType.DIFFEREECOMMAND && (
          <Box marginBottom={2}>
            <Chip
              size="small"
              color="warning"
              label="Commande différée"
            />
          </Box>
        )}
        {archive && (
          <Box marginBottom={2}>
            <Chip size="small" color="warning" label="Archivé" />
          </Box>
        )}
        <Box>
          <Typography variant="h5" fontWeight="bold" component="span">
            N° {id}
          </Typography>
          <Typography variant="body2" component="span"></Typography>
        </Box>
        <Box>
          <Typography variant="h5" fontWeight="bold" component="span">
            Client : &nbsp;
          </Typography>
          <Typography variant="body2" component="span">
            {name}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" fontWeight="bold" component="span">
            E-mail : &nbsp;
          </Typography>
          <Typography variant="body2" component="span">
            {email}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" fontWeight="bold" component="span">
            Téléphone : &nbsp;
          </Typography>
          <Typography variant="body2" component="span">
            {phone}
          </Typography>
        </Box>
        <Box>
          <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
            Information d'achat :
          </Typography>
          {processedProduct &&
            processedProduct.map(
              (item: {
                id: Key | null | undefined
                productPrice: number
                totalCount: string | number
                name: string | number
                totalPrice: string | number
              }) => (
                <Box key={item.id} sx={{ mb: 2, pl: 2 }}>
                  <Typography variant="h5">{item.name}</Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography component="span">
                      {item.totalCount} x &nbsp;
                    </Typography>
                    <FormatPrice
                      position={
                        currencyType?.clickCollectById?.position || ""
                      }
                      currency={
                        currencyType?.clickCollectById?.currency || ""
                      }
                      separator={
                        currencyType?.clickCollectById
                          ?.thousandsSeparator || ""
                      }
                      decimal={
                        currencyType?.clickCollectById
                          ?.decimalSeparator || ""
                      }
                      decNum={
                        currencyType?.clickCollectById?.decimalNumber
                      }
                      value={item.productPrice}
                    />
                    <Typography component="span">
                      &nbsp; = &nbsp;
                    </Typography>
                    <FormatPrice
                      position={
                        currencyType?.clickCollectById?.position || ""
                      }
                      currency={
                        currencyType?.clickCollectById?.currency || ""
                      }
                      separator={
                        currencyType?.clickCollectById
                          ?.thousandsSeparator || ""
                      }
                      decimal={
                        currencyType?.clickCollectById
                          ?.decimalSeparator || ""
                      }
                      decNum={
                        currencyType?.clickCollectById?.decimalNumber
                      }
                      value={item.totalPrice}
                    />
                  </Box>
                </Box>
              )
            )}
        </Box>
        {commandType === "PENDING" && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
              Action :
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around"
              }}
            >
              <Button
                sx={{ my: 2 }}
                variant="contained"
                color="error"
                onClick={() => {
                  onChange(Number(id) || 0, "reject")
                }}
              >
                <Cancel sx={{ mr: 1 }} />
                Refusé
              </Button>
              <Button
                sx={{ my: 2 }}
                variant="contained"
                color="success"
                onClick={() => {
                  onChange(Number(id) || 0, "valid")
                }}
              >
                <CheckCircle sx={{ mr: 1 }} />
                Validé
              </Button>
            </Box>
          </>
        )}
        {commandType === "VALID" && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography id="modal-modal-title" variant="h5" sx={{ mb: 2 }}>
              Action :
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around"
              }}
            >
              <Button
                sx={{ my: 2 }}
                variant="contained"
                color="success"
                onClick={() => {
                  onChange(Number(id) || 0, "done")
                }}
              >
                <CheckCircle sx={{ mr: 1 }} />
                Terminé
              </Button>
            </Box>
          </>
        )}
        <Box marginTop={1}>
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontSize: "12px"
            }}
          >
            {moment(createdAt).format("DD MMMM YYYY")}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
