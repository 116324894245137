/* eslint-disable camelcase */
import AddIcon from "@mui/icons-material/Add"
import { Box, Divider, IconButton, Button } from "@mui/material"
import { FC, SyntheticEvent, useEffect, useState } from "react"
import { ResearchBox } from "../forms"
import { Loading } from "../loading"
import { categories } from "../../utils"

interface CategorieActiviteInputProps {
  label?: string
  editable?: boolean
  loading?: boolean
  defaultValue?: string
  nodivider?: boolean
  name?: string
  onSave?: (value: string, name: string) => void
}
export const CategorieActiviteInput: FC<CategorieActiviteInputProps> = ({
  name,
  editable = true,
  loading,
  nodivider,
  label,
  defaultValue,
  onSave
}): JSX.Element => {
  const [activity, setActivity] = useState<string>("")
  const [categoriesFound, setCategoriesFound] = useState<{ name: string }[]>([])
  const [search, setSearch] = useState<string>("")

  const [edit, setEdit] = useState<boolean>(false)

  useEffect(() => {
    if (defaultValue) {
      setActivity(defaultValue)
    }
    return () => {
      // cleanup
    }
  }, [defaultValue])

  useEffect(()=>{
    if (!name) return
    onSave && onSave(activity, name)
  }, [activity])

  const handleClickActivity = (names: string): void => {
    setActivity(names)
    setSearch("")
    setCategoriesFound([])
    
    setEdit(false)
  }
  const handleSave = (e: SyntheticEvent): void => {
    e.preventDefault()
    if (!name) return
    onSave && onSave(activity, name)
    setEdit(false)
  }
  

  const filter = (): void => {
    const found =
      search.length > 3
        ? categories.filter((c) => c.name.toLowerCase().includes(search))
        : []
    
      setCategoriesFound(found)
  }
  const handleChangeActivity = (val: string): void => {
    setSearch(val.toLowerCase())
    filter()
  }
  return (
    <Box>
      <form>
        <Box>
          <ResearchBox
            placeholder="Rechercher une activité"
            activity={activity}
            onChange={handleChangeActivity}
            startLabel="Catégorie d'activité"
          />
          <Box sx={{ py: 1, maxHeight: 300, overflow: "auto" }}>
            {categoriesFound.map(({ name: nam }, index) => (
              <div key={index}>
                <Button
                  sx={{ textTransform: "none" }}
                  endIcon={<AddIcon />}
                  onClick={() => handleClickActivity(nam)}
                >
                  {nam}
                </Button>
              </div>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute"
          }}
        >
          {loading ? (
            <IconButton>
              <Loading />
            </IconButton>
          ) : null}
        </Box>
        {nodivider ? null : <Divider />}
      </form>
    </Box>
  )
}
