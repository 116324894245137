/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Typography,
  Grid,
  Button,
  Select,
  FormControl,
  TextField,
  MenuItem,
  Autocomplete,
  InputLabel,
  FormHelperText
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { Dropzone } from "@/components/dropzone"
import { useCurrentGetProductById } from "@/hooks/useCurrentGetProductById"
import { useUpdateProduct } from "@/hooks/category"
import { useQuery } from "@apollo/client"
import { BUSINESS_GET_CLICK_BY_ID } from "@/graphql/business"
import JoditEditor from "jodit-react"
import { useRef, useState, FormEvent, useMemo } from "react"
import { useApplicationContext } from "@/hooks"
import { QueryResult } from "@/components/common/QueryResult"
import { usePriceCalculation } from "@/hooks/usePriceCaclulation"
import { SwitchStyle } from "@/components/common"

import { useCategoryList } from "@/hooks/category/useCategoryList"
import { getCategory } from "@/utils/getCategories"
import { FormatPrice } from "@/components/FormatPrice"

export const UpdateProduct = () => {
  const { idEditProduct } = useParams()
  const { productUpdate } = useUpdateProduct()
  const { dispatchSnack, business } = useApplicationContext()
  const { data, refetch, loading } = useCurrentGetProductById(
    Number(idEditProduct)
  )
  const [currentSelectedCat, setCurrentSelectedCat] = useState<number>()
  const {
    categoriesPrincipales,
    sousCategories,
    loading: categorieLoading
  } = useCategoryList(business?.id)

  const navigate = useNavigate()
  const editor = useRef(null)
  const [nameState, setNameState] = useState<string | null>("")
  const [picturesState, setPicturesState] = useState<string | null | undefined>(
    ""
  )
  const [preTaxState, setPreTaxState] = useState<number | null | void>()
  const [taxState, setTaxState] = useState<number | null>(20)
  const [promoPriceState, setPromoPriceState] = useState<number | null>(0)
  const [descriptionState, setDescriptionState] = useState<string | null>("")
  const [longDescritionState, setLongDescritionState] = useState<string | null>(
    ""
  )
  const [categoryIdState, setCategoryIdState] = useState<number | null>(0)
  const [subCategoryIdState, setSubCategoryIdState] = useState<number | null>(0)
  const [preparationTime, setPreparationTime] = useState<number | null>(0)
  const [status, setStatus] = useState<boolean>(true)
  const timeOption = [
    {
      label: "5 min",
      value: 5
    },
    {
      label: "10 min",
      value: 10
    },
    {
      label: "15 min",
      value: 15
    },
    {
      label: "20 min",
      value: 20
    },
    {
      label: "25 min",
      value: 25
    },
    {
      label: "30 min",
      value: 30
    },
    {
      label: "35 min",
      value: 35
    },
    {
      label: "40 min",
      value: 40
    },
    {
      label: "45 min",
      value: 45
    },
    {
      label: "50 min",
      value: 50
    },
    {
      label: "55 min",
      value: 55
    },
    {
      label: "1h",
      value: 60
    },
    {
      label: "> 1h",
      value: 70
    }
  ]

  const [required, setRequired] = useState({
    name: false,
    categoryIds: false,
    prixHorsTaxe: false,
    description: false
  })

  const defaultTime = timeOption.find(
    (item) => item.value === data?.preparationTime
  )
  const { data: dataCurrency } = useQuery(BUSINESS_GET_CLICK_BY_ID, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })

  const { priceProcessed, totalPrice } = usePriceCalculation(
    Number(preTaxState) || 0,
    Number(taxState) || 0,
    Number(promoPriceState) || 0
  )

  const handleUpload = async (file?: string): Promise<void> => {
    setPicturesState(file)
  }

  const handleDescription = (item: string) => {
    setLongDescritionState(item)
  }

  const handleUpdate = (e: any) => {
    const { name, value } = e.target || {}
    switch (name) {
      case "name":
        setNameState(value)
        break
      case "prixHorsTaxe":
        if (value < 0) {
          setPreTaxState(0)
        } else {
          setPreTaxState(value)
        }
        break
      case "taxe":
        if (value > 100) {
          setTaxState(100)
        } else if (value < 0) {
          setTaxState(0)
        } else {
          setTaxState(value)
        }
        break
      case "prixPromotionnels":
        if (value > 100) {
          setPromoPriceState(100)
        } else if (value < 0) {
          setPromoPriceState(0)
        } else {
          setPromoPriceState(value)
        }
        break
      case "description":
        setDescriptionState(value)
        break
      case "categoryIds":
        setCategoryIdState(parseFloat(value))
        break
      case "sousCategoryIds":
        setSubCategoryIdState(parseFloat(value))
        break
      case "preparationTime":
        setPreparationTime(Number(value))
        break

      default:
        break
    }
  }

  const handleBlur = (e: any) => {
    const { name, value } = e.target
    if (value) {
      setRequired((prev) => ({
        ...prev,
        [name]: false
      }))
      return
    }
    setRequired((prev) => ({
      ...prev,
      [name]: true
    }))
  }

  const handleStatus = (): void => {
    setStatus(!status)
  }

  const submit = async (e: FormEvent) => {
    e.preventDefault()
    const update = await productUpdate(parseFloat(idEditProduct as string), {
      name: nameState || "",
      image: picturesState,
      prixHorsTaxe: Number(preTaxState) || 0,
      taxe: Number(taxState) || 0,
      prixPromotionnels: Number(promoPriceState),
      prixTTC: totalPrice || 0,
      prix: priceProcessed || 0,
      description: descriptionState,
      descriptionLong: longDescritionState,
      sousCategoryId: subCategoryIdState || null,
      categoryId: categoryIdState || null,
      preparationTime: preparationTime || null,
      status
    })
    if (update?.success) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Produit modifié avec succès"
      })
      navigate(-1)
      refetch()
    }
  }

  useMemo(() => {
    if (data) {
      setNameState(data?.name)
      setPreTaxState(data?.prixHorsTaxe)
      setTaxState(data?.taxe)
      setPromoPriceState(data?.prixPromotionnels)
      setDescriptionState(data?.description)
      setCategoryIdState(data?.categoryId)
      setSubCategoryIdState(data?.sousCategoryId)
      setLongDescritionState(data?.descriptionLong)
      setPicturesState(data?.image)
      setPreparationTime(data?.preparationTime)
      setStatus(data?.status === null ? true : data?.status)
    }
  }, [data])

  const handleSelectSubCat = (e: any) => {
    const { value } = e.target

    const getCatId = sousCategories.find(
      (sous) => sous.id === parseFloat(value)
    )?.Category?.id
    const findCAT = getCategory(categoriesPrincipales, getCatId as any)

    setCategoryIdState(findCAT?.id || null)
    setSubCategoryIdState(parseFloat(value))
  }
  const handleSelectCat = (e: any) => {
    const { value } = e.target
    const sous = sousCategories.filter(
      (s) => s.Category?.id === parseFloat(value)
    )
    if (sous.length) {
      setCurrentSelectedCat(parseFloat(value))
    } else {
      setCurrentSelectedCat(undefined)
      setSubCategoryIdState(null)
    }
    setCategoryIdState(parseFloat(value))
  }
  return (
    <Box
      sx={{
        p: 2,
        m: 3,
        mb: 1
      }}
    >
      <QueryResult loading={loading} data={data}>
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              mt: 2,
              mb: 2
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Modification produit
            </Typography>
          </Box>
          <Grid container justifyContent="center">
            <Grid item>
              <SwitchStyle change={handleStatus} status={status} />
            </Grid>
          </Grid>
          <Box>
            <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              Nom du Produit
            </Typography>
            <TextField
              error={required.name}
              label="Nom du Produit"
              name="name"
              variant="outlined"
              fullWidth
              value={nameState}
              onBlur={handleBlur}
              onChange={handleUpdate}
              required
              helperText={required.name && "le champ Nom du produit est requis"}
            />
          </Box>
          <Grid container columnSpacing={1}>
            <Grid item md={8}>
              <Grid container columnSpacing={1}>
                <Grid item xl={4} lg={6} md={6} xs={12}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                  >
                    Catégorie
                  </Typography>
                  <QueryResult
                    loading={categorieLoading}
                    data={categoriesPrincipales}
                  >
                    <FormControl
                      fullWidth
                      required
                      error={required.categoryIds}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Catégorie
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        label="Catégorie"
                        id="categoryIds"
                        name="categoryIds"
                        value={categoryIdState || ""}
                        onChange={handleSelectCat}
                        onBlur={handleBlur}
                      >
                        {categoriesPrincipales.map((item: any) => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                      {required.categoryIds && (
                        <FormHelperText>
                          choisissez une catégorie
                        </FormHelperText>
                      )}
                    </FormControl>
                  </QueryResult>
                </Grid>
                <Grid item xl={4} lg={6} md={6} xs={12}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                  >
                    Sous catégorie
                  </Typography>
                  <QueryResult loading={categorieLoading} data={sousCategories}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="sousCategoryIds"
                        name="sousCategoryIds"
                        value={subCategoryIdState || ""}
                        onChange={handleSelectSubCat}
                      >
                        {sousCategories
                          .filter((ct) =>
                            currentSelectedCat
                              ? ct.Category?.id === currentSelectedCat
                              : true
                          )
                          .map((item: any) => {
                            return (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    </FormControl>
                  </QueryResult>
                </Grid>
                <Grid item xl={4} lg={12} md={12} xs={12}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                  >
                    Temps de préparation (en minute)
                  </Typography>
                  <Autocomplete
                    defaultValue={defaultTime}
                    options={timeOption}
                    getOptionLabel={(option) => option.label}
                    sx={{
                      ".MuiOutlinedInput-root": { height: 43, padding: "3px" }
                    }}
                    onChange={(event, value) =>
                      setPreparationTime(Number(value?.value))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="preparationTime"
                        sx={{ height: 43 }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={1}>
                <Grid item xl={4} lg={6} md={6} xs={12}>
                  <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
                    Prix hors taxe
                  </Typography>
                  <Box>
                    <TextField
                      label="Prix hors taxe"
                      name="prixHorsTaxe"
                      error={required.prixHorsTaxe}
                      variant="outlined"
                      type="number"
                      required
                      fullWidth
                      value={preTaxState}
                      onChange={handleUpdate}
                      onBlur={handleBlur}
                      helperText={
                        required.prixHorsTaxe && "prix hors taxe requis"
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xl={4} lg={6} md={6} xs={12}>
                  <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
                    Taxe (en %)
                  </Typography>
                  <Box>
                    <TextField
                      name="taxe"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={taxState}
                      onChange={handleUpdate}
                    />
                  </Box>
                </Grid>
                <Grid item xl={4} lg={12} md={12} xs={12}>
                  <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
                    Remise promotionnel (en %)
                  </Typography>
                  <Box>
                    <TextField
                      name="prixPromotionnels"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={promoPriceState}
                      onChange={handleUpdate}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container columnSpacing={1} justifyContent="flex-end">
                <Grid item md={4}>
                  <Typography
                    textAlign="right"
                    sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                  >
                    Prix TTC (hors promo)
                  </Typography>
                  <Box sx={{ fontWeight: "bold" }}>
                    <FormatPrice
                      style={{
                        fontWeight: "bold !important",
                        textAlign: "right",
                        fontSize: 28
                      }}
                      position={
                        dataCurrency?.clickCollectById?.position || "left"
                      }
                      currency={
                        dataCurrency?.clickCollectById?.currency || "euro"
                      }
                      separator={
                        dataCurrency?.clickCollectById?.thousandsSeparator ||
                        "space"
                      }
                      decimal={
                        dataCurrency?.clickCollectById?.decimalSeparator ||
                        "coma"
                      }
                      decNum={dataCurrency?.clickCollectById?.decimalNumber}
                      value={totalPrice}
                    />
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Typography
                    textAlign="right"
                    sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                  >
                    Prix TOTAL
                  </Typography>
                  <Box sx={{ fontWeight: "bold" }}>
                    <FormatPrice
                      style={{
                        fontWeight: "bold !important",
                        textAlign: "right",
                        fontSize: 28
                      }}
                      position={
                        dataCurrency?.clickCollectById?.position || "left"
                      }
                      currency={
                        dataCurrency?.clickCollectById?.currency || "dollars"
                      }
                      separator={
                        dataCurrency?.clickCollectById?.thousandsSeparator ||
                        "space"
                      }
                      decimal={
                        dataCurrency?.clickCollectById?.decimalSeparator ||
                        "coma"
                      }
                      decNum={dataCurrency?.clickCollectById?.decimalNumber}
                      value={priceProcessed}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
                Image du Produit
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: {
                    md: "82%"
                  },
                  mr: 1,
                  mb: 1,
                  position: "relative"
                }}
              >
                <Dropzone
                  onFinished={handleUpload}
                  message="Ajouter PDF ou png ou JPEG"
                  photo={picturesState || ""}
                  btnSx={{ height: "100%" }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              Description courte
            </Typography>
            <Box>
              <TextField
                label="Description courte"
                error={required.description}
                name="description"
                variant="outlined"
                fullWidth
                required
                value={descriptionState}
                onChange={handleUpdate}
                onBlur={handleBlur}
                helperText={
                  required.description && "description courte requise"
                }
              />
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              Description longue
            </Typography>
            <Box>
              <JoditEditor
                ref={editor}
                value={longDescritionState || ""}
                onChange={handleDescription}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
              gap: "10px"
            }}
          >
            <Button type="submit" onClick={submit} variant="contained">
              Enregistrer
            </Button>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Annuler
            </Button>
          </Box>
        </>
      </QueryResult>
    </Box>
  )
}
export default UpdateProduct
