/* eslint-disable react/jsx-props-no-spreading */
import { Box, TextField, styled, TextFieldProps, BoxProps } from "@mui/material"
import { FC } from "react"

interface TextInputBaseProps {
  label?: string
  containerProps?: BoxProps
}
const CustomTextField = styled(TextField)(() => ({
  borderRadius: 10
}))
export const TextInputBase: FC<TextInputBaseProps & TextFieldProps> = ({
  containerProps,
  ...props
}): JSX.Element => {
  return (
    <Box {...containerProps}>
      <CustomTextField fullWidth {...props} variant="outlined" />
    </Box>
  )
}
