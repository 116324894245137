import { Route, Routes } from "react-router-dom"
import { CustomPage } from "./CustomPage"

export const PersonnalizationRoutes = () => {
  return (
    <Routes>
      <Route index element={<CustomPage />} />
    </Routes>
  )
}
