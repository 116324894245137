import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Login } from "./Login"
import { useApplicationContext } from "../../../hooks"

export const SignIn = (): JSX.Element => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const { signin, signinLoading, signinError } = useApplicationContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLogin = async ({ email, password }: any): Promise<void> => {
    setLoading(true)
    const login = await signin(email, password)
    setTimeout(() => {
      if (login.success) {
        navigate("/discount", { replace: true })
      }
      if (login.error) {
        setLoading(false)
      }
    }, 500)
    setLoading(false)
  }
  const [newBusiness, setNewBusiness] = useState(false)

  useEffect(() => {
    const localState = localStorage.getItem("newBusiness")
    if (localState && Number(localState) > 0) {
      setNewBusiness(true)
    }
  }, [newBusiness])

  return (
    <Login
      onLogin={handleLogin}
      loading={loading || signinLoading}
      error={signinError}
      newBusiness={newBusiness}
    />
  )
}
