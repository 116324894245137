import { CSSProperties, FC } from "react"
import { PieChart, Pie, Cell } from "recharts"

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent
}: any): any => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

interface IShapePieChart {
  data: any[]
  datakey: string
  colors?: string[]
  size?: number
  style?: CSSProperties
}

export const ShapePieChart: FC<IShapePieChart> = ({
  data = [],
  datakey,
  colors,
  size,
  style
}): JSX.Element => {
  return (
    <PieChart width={size || 200} height={size || 200} style={style}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        fill="#8884d8"
        dataKey={datakey}
      >
        {data.map((_entry, index) => {
          const key = index
          return (
            <Cell
              key={`cell-${key}`}
              fill={
                colors
                  ? colors[index % COLORS.length]
                  : COLORS[index % COLORS.length]
              }
            />
          )
        })}
      </Pie>
    </PieChart>
  )
}
