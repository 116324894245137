import { useMutation } from "@apollo/client"
import {
  CreateCategory,
  CreateCategoryVariables,
  CREATE_CATEGORY,
  DeleteCategory,
  DeleteCategoryVariables,
  DELETE_CATEGORY,
  UpdateCategoryVariables,
  UpdateCategory,
  UPDATE_CATEGORY,
  UPDATE_STATUS_CATEGORY,
  updateStatusCategory,
  updateStatusCategoryVariables,
  UPDATE_LIST_CATEGORY
} from "@/graphql/business"

import { CategoryInput, UpdateCategoryInput } from "@/types"
import { updateListCategory, updateListCategoryVariables } from "@/graphql/business/types/updateListCategory"

export const useCategory = () => {
  const [executeCreate, { loading }] = useMutation<
    CreateCategory,
    CreateCategoryVariables
  >(CREATE_CATEGORY)
  const createCategory = async (data: CategoryInput) => {
    try {
      await executeCreate({
        variables: {
          data
        }
      })
      return { success: "OK" }
    } catch (error) {
      return {
        error: "ERROR"
      }
    }
  }
  return {
    loading,
    createCategory
  }
}

export const useDeleteCategory = () => {
  const [deleteCategory, { loading }] = useMutation<
    DeleteCategory,
    DeleteCategoryVariables
  >(DELETE_CATEGORY)
  const categoryDelete = async (id: number) => {
    try {
      const category = deleteCategory({
        variables: {
          categoryId: id
        }
      })
      return category
    } catch (error) {
      return undefined
    }
  }
  return { categoryDelete, loading }
}

export const useUpdateCategory = () => {
  const [updateCategory] = useMutation<UpdateCategory, UpdateCategoryVariables>(
    UPDATE_CATEGORY
  )
 const [updateListCategory] = useMutation<updateListCategory, updateListCategoryVariables>(UPDATE_LIST_CATEGORY)
  const categoryUpdateList = async(categoryId: number, statusArrow: string) => {
    try {
      const message = await updateListCategory({ variables: { categoryId, statusArrow } })
    } catch (error) {
      return null
    }
  }
  const [updateStatusCategoryData] = useMutation<
    updateStatusCategory,
    updateStatusCategoryVariables
  >(UPDATE_STATUS_CATEGORY)
  const categoryUpdate = async (
    categoryId: number,
    data: UpdateCategoryInput
  ) => {
    try {
      const category = await updateCategory({
        variables: {
          categoryId,
          data
        }
      })
      return category.data?.updateCategory
    } catch (error) {
      return null
    }
  }
  const categoryStatusUpdate = async (categoryId: number, status: boolean) => {
    try {
      const category = await updateStatusCategoryData({
        variables: {
          categoryId,
          status
        }
      })
      return category.data?.updateStatusCategory
    } catch (error) {
      return null
    }
  }
  return { categoryUpdate, categoryStatusUpdate, categoryUpdateList }
}
