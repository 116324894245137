/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton } from "@mui/material"
import { FC, SyntheticEvent, useEffect, useState } from "react"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import { BaseTable } from "@/components"
import { useClientComponent } from "./components/useClientComponent"
import { useWinners } from "@/hooks"
import { WinnerGames_winnerGames as IWinner } from "@/graphql/qrcode"

interface ListGagnantProps {
  qrcodeId: number
}

export const ListGagnant: FC<ListGagnantProps> = ({ qrcodeId }) => {
  const { loading, getWinners, refetch } = useWinners()
  const [winners, setWinners] = useState<IWinner[]>([])

  const [page] = useState<number>(1)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const handleRefetch = () => {
    setWinners([])
    setIsRefresh(true)
    setTimeout(async () => {
      const data = await refetch({
        limit: 101,
        skip: 0,
        qrcodeId: qrcodeId
      })
      if (data.data.winnerGames) {
        setIsRefresh(false)
        setWinners(data.data.winnerGames)
      }
    }, 1000)
  }

  const { columns } = useClientComponent({
    onEdit: () => {
      handleRefetch()
    }
  })

  const load = async () => {
    const wins = await getWinners(qrcodeId, 100, 0)
    setWinners(wins)
  }
  useEffect(() => {
    if (qrcodeId) {
      load()
    }
  }, [qrcodeId])

  return (
    <Box>
      <Box sx={{ py: 2 }}>
        <BaseTable
          title="Liste des gagnants"
          loading={loading || isRefresh}
          columns={columns}
          data={winners}
          page={page - 1}
          topComponent={
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <IconButton onClick={handleRefetch}>
                <RotateLeftIcon />
              </IconButton>
            </Box>
          }
        />
      </Box>
    </Box>
  )
}
