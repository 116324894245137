import { useState, FC, useCallback, useEffect } from "react"
import { Wheel } from "react-custom-roulette"
import { Box } from "@mui/material"
import { COLOR } from "@/utils"
import LogoWithoutBg from "@/assets/logoSimple.png"
import "./spinner.css"
import { Lots_lots } from "@/graphql/lots"
import { Qrcodes_qrcodes_lots as ILot } from "@/graphql/qrcode"

interface CreateColorSpinnerProps {
  mainColor?: string
  dataProps?: Lots_lots[]
  emptyColor?: string
}
const data = [
  { option: "lot1" },
  { option: "lot2" },
  { option: "lot3" },
  { option: "lot4" },
  { option: "lot5" },
  { option: "lot6" },
  { option: "lot7" },
  { option: "lot8" },
  { option: "lot9" },
  { option: "lot10" }
]
const tintColorFinder = (colorSpinner: any) => {
  const colors = Array.from(Array(4)).map((_, i) => {
    if (i % 2 === 0) {
      return colorSpinner
    }
    return "#ffffff"
  })
  return colors
}

const tintColorText = (colorText: any) => {
  const colors = Array.from(Array(4)).map((_, i) => {
    if (i % 2 === 0) {
      return "#ffffff"
    }
    return colorText
  })
  return colors
}

export const gameCreator = (lots: ILot[], colorForEmpty?: string) => {
  const background = [
    "#ff8f43",
    "#e91e63",
    "#70bbe0",
    "#0b3351",
    "#673ab7",
    "#f9dd50",
    "#e24a2b",
    "#7fffd4",
    "#673ab7",
    "#00bcd4",
    "#4caf50",
    "#ffeb3b",
    "#ff9800",
    "#607d8b",
    "#9e9e9e",
    "#795548",
    "#f44336"
  ]

  const stripped = [...lots]
  const gameData = stripped.reduce((acc, next): any => {
    const indexColor = Math.floor(Math.random() * 15)
    const randomColor = background[indexColor]
    const nextD = [
      {
        option: next.title,
        ...next,
        color: next.color || randomColor
      },
      { option: "", color: colorForEmpty || "#fff" }
    ]
    return [...acc, ...nextD]
  }, [])

  return gameData
}

export const SpinnerTemplate: FC<CreateColorSpinnerProps> = ({
  mainColor,
  dataProps,
  emptyColor
}): JSX.Element => {
  const [backgrounds, setBackgrounds] = useState(tintColorFinder(COLOR.PRIMARY))
  const [textColor, setTextColor] = useState(tintColorText(COLOR.PRIMARY))
  const [mustSpin] = useState(false)
  const dataProced =
    !dataProps || dataProps.length === 0
      ? data
      : gameCreator(dataProps || [], emptyColor)

  const changeMainColor = useCallback(() => {
    const colors = tintColorFinder(mainColor || COLOR.PRIMARY)
    const textColors = tintColorText(mainColor || COLOR.PRIMARY)
    setBackgrounds(colors as any)
    setTextColor(textColors as any)
  }, [mainColor])

  useEffect(() => {
    changeMainColor()
  }, [changeMainColor])


        /****responsive styles */
        const mobileTemplateDimensions = {
      
          "@media(max-width:767px)" : {
            width: "250px",
            height: "250px"
          }
        }
      
        /*****end */
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          width: 350,
          height: 350,
          mx: "auto",
          div: {
            width: "100%",
            height: "100%"
          },
          ...mobileTemplateDimensions
        }}
      >
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={3}
          data={dataProced}
          backgroundColors={backgrounds}
          textColors={textColor}
          radiusLineWidth={2}
          fontSize={20}
          outerBorderColor="#eeeeee"
          innerBorderWidth={0}
          outerBorderWidth={5}
          innerBorderColor={mainColor || COLOR.PRIMARY}
          perpendicularText={true}
          textDistance={80}
        />
        <img
          src={LogoWithoutBg}
          alt="logo"
          width="35px"
          height="35px"
          style={{
            position: "absolute",
            
            zIndex: 100,
            top: "50%",
            transform: "translateY(-50%)"
          }}
        />
        <Box
          sx={{
            width: "65px !important",
            height: "65px !important",
            backgroundColor: mainColor || COLOR.PRIMARY,
            borderRadius: "50%",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 99
          }}
        ></Box>
        <Box
          sx={{
            width: "25px !important",
            height: "25px !important",
            backgroundColor: mainColor || COLOR.PRIMARY,
            borderRadius: "50%",
            borderBottomRightRadius: 0,
            position: "absolute",
            transform: "rotate(45deg)",
            top: "-5px",
            zIndex: 99
          }}
        ></Box>
        <Box
          sx={{
            width: "90px !important",
            height: "90px !important",
            backgroundColor: "white",
            borderRadius: "50%",
            border: "2px solid black",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 98
          }}
        ></Box>
      </Box>
    </>
  )
}
