import { FC } from "react"
import { Box } from "@mui/material"
import {
  TabsWithRoutes,
  TabsWithRoutesProps
} from "@/components/tabs/TabsWithRoutes"
import { SousCategory } from "../SousCategory/SousCategory"
import { ProductList } from "./ProductList/ProductList"

interface GererCategoryProps {
  isCategory?: boolean
}

export const GererCategory: FC<GererCategoryProps> = ({ isCategory }) => {
  const tabs: TabsWithRoutesProps["tabs"] = [
    {
      component: <SousCategory />,
      id: "idCategory",
      label: "sousCategory",
      index: true
    },
    {
      component: <ProductList />,
      id: "2",
      label: "product",
      path: "productList"
    }
  ]

  return (
    <Box>
      <TabsWithRoutes
        tabs={tabs}
        pathBase={isCategory ? `/discount/category/` : ""}
      />
    </Box>
  )
}
