import { Route, Routes } from "react-router-dom"
import { Clients } from "./Clients"
import { GererClients } from "./GererClients"

export const ClientRoutes = () => {
  return (
    <Routes>
      <Route index element={<Clients />} />
      <Route path=":id/*" element={<GererClients />} />
    </Routes>
  )
}
