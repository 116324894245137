import { QueryResult } from "@/components"
import { GET_ADMIN_INFO, UPDATE_USER } from "@/graphql/business"
import { UpdateUtilisateurInput } from "@/types"
import { useMutation, useQuery } from "@apollo/client"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react"
import Swal from "sweetalert2"

export const MyAcoount = () => {
  const [adminInfo, setAdminInfo] = useState<UpdateUtilisateurInput>({
    email: "",
    oldPassword: "",
    password: ""
  })
  const { data, loading } = useQuery(GET_ADMIN_INFO)
  const [updateAdmin, { error }] = useMutation(UPDATE_USER)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setAdminInfo((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const editMailData = {
    email: adminInfo.email
  }
  const editPswdData = {
    email: adminInfo.email,
    oldPassword: adminInfo.oldPassword,
    password: adminInfo.password
  }
  const handleSubmit = () => {
    updateAdmin({
      variables: {
        userId: 1,
        data: adminInfo.oldPassword ? editPswdData : editMailData
      }
    })
      .then(() =>
        Swal.fire({
          icon: "success",
          title: "Edition profile",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
      )
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Edition profile échoué",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
      })
  }

  useEffect(() => {
    if (data) {
      setAdminInfo({
        email: data.getUserAdminInfo.email,
        oldPassword: "",
        password: ""
      })
    }
    if (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
        text: "",
        showConfirmButton: false,
        showCloseButton: true
      })
    }
  }, [data, error])

  return (
    <Card elevation={0} sx={{ m: 3 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <QueryResult data={data} loading={loading}>
              <>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Adresse mail administrateur
                  </Typography>
                  <TextField
                    type="mail"
                    fullWidth
                    name="email"
                    value={adminInfo.email}
                    onChange={handleChange}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Ancien mot de passe
                  </Typography>
                  <TextField
                    type="password"
                    fullWidth
                    name="oldPassword"
                    value={adminInfo.oldPassword}
                    onChange={handleChange}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Nouveau mot de passe
                  </Typography>
                  <TextField
                    type="password"
                    fullWidth
                    name="password"
                    value={adminInfo.password}
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <Button variant="contained" onClick={handleSubmit} sx={{ color: "#050005" }}>
                    Enregistrer
                  </Button>
                </Box>
              </>
            </QueryResult>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
