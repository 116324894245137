import { Email, Lock } from "@mui/icons-material"
import { Box, Button, TextField, Typography } from "@mui/material"
import React, { FC, SyntheticEvent, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { LoginVariables } from "../../../graphql"

interface LoginFormProps {
  onLogin: (data: LoginVariables) => void
  error?: string
  isAdmin?: boolean
}
export const LoginForm: FC<LoginFormProps> = ({
  onLogin,
  error,
  isAdmin
}): JSX.Element => {
  const navigate = useNavigate()
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<LoginVariables>()
  const onSign = (data: LoginVariables): void => {
    onLogin(data)
  }
  const handleGoToSignup = (e: SyntheticEvent): void => {
    e.preventDefault()
    navigate("/signup")
  }
  return (
    <form onSubmit={handleSubmit(onSign)}>
      <Box mb={1} sx={{ textAlign: "center" }}>
        <TextField
          label="Votre email"
          error={errors.email && true}
          type="email"
          {...register("email", {
            required: { value: true, message: "email requis" },
            pattern: {
              value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              message: "Entrer un email valide"
            }
          })}
          InputProps={{
            endAdornment: <Email />
          }}
          sx={{ mb: 2 }}
          helperText={errors.email?.message}
        />
        <TextField
          error={errors.password && true}
          label="Mot de passe"
          // name="password"
          type="password"
          // value={values.password}
          // onChange={handleChange}
          {...register("password", {
            required: { value: true, message: "Champ requis" }
          })}
          InputProps={{
            endAdornment: <Lock />
          }}
          helperText={errors.password?.message}
        />
      </Box>
      <Box>
        {error && (
          <Typography sx={{ textAlign: "center" }} color="error">
            {error}
          </Typography>
        )}
      </Box>
      <Box sx={{ textAlign: "center", py: 1 }}>
        <Button
          variant="contained"
          type="submit"
          sx={{ textTransform: "none" , color: "#050005" }}
        >
          Se connecter
        </Button>
      </Box>
      {!isAdmin ? (
        <Box sx={{ textAlign: "center", py: 1 }}>
          <Button
            onClick={handleGoToSignup}
            sx={{ textTransform: "none", textDecoration: "underline" , color: "#050005" }}
          >
            Créer un compte
          </Button>
        </Box>
      ) : null}
    </form>
  )
}
