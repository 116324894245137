/* eslint-disable no-nested-ternary */
import { useMutation } from "@apollo/client"
import Delete from "@mui/icons-material/Delete"
import { Box, IconButton } from "@mui/material"
import { FC, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Dropzone } from "../../../../components"

import {
  UpdateBusinessInfo,
  UpdateBusinessInfoVariables,
  UPDATE_BUSINESS_INFO
} from "../../../../graphql/business"
import { storage } from "../../../../utils"
import { FormContainer } from "../components/FormContainer"

interface SignupStepProps {
  afterSave?: () => void
}
export const PlatPhotos: FC<SignupStepProps> = ({ afterSave }): JSX.Element => {
  const [updateInfo, { loading }] = useMutation<
    UpdateBusinessInfo,
    UpdateBusinessInfoVariables
  >(UPDATE_BUSINESS_INFO)
  const { id } = useParams()

  const [newId, setNewId] = useState<number>()
  const [files, setFiles] = useState<string[]>([])
  const navigate = useNavigate()

  const handleCreate = async (): Promise<void> => {
    try {
      const create = await updateInfo({
        variables: {
          businessId: parseFloat(id as string) || (newId as number),
          photoPlats: {
            plats: files.map((url) => ({ url }))
          }
        }
      })
      if (create.data?.updateBusinessInfo.id) {
        if (afterSave) {
          afterSave()
        } else {
          navigate(`/signup/logo/${create.data?.updateBusinessInfo.id}`)
        }
      }
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const handleIgnore = (): void => {
    navigate(`/signup/logo/${id}`)
  }
  const handleUpload = (file?: string): void => {
    if (file) {
      setFiles((prev) => prev.concat(file))
    }
  }
  const loadLocal = useCallback(() => {
    const nid = storage.get("new_Id")
    setNewId(parseFloat(nid))
  }, [])
  useEffect(() => {
    loadLocal()
  }, [loadLocal])
  return (
    <FormContainer
      step={7}
      loading={loading}
      title="Ajouter des photos de votre plat"
      onNext={handleCreate}
      onIgnore={handleIgnore}
    >
      {}
      <Box sx={{ pt: 2, pb: 4, display: "flex", flexWrap: "wrap" }}>
        {["input", ...files].map((url) => {
          return (
            <Box
              key={url}
              sx={{
                width: 150,
                height: 150,
                mr: 1,
                mb: 1,
                position: "relative"
              }}
            >
              {url !== "input" ? (
                <IconButton
                  onClick={() => {
                    setFiles((prev) => prev.filter((f) => f !== url))
                  }}
                  sx={{ position: "absolute", bottom: 5, right: 5 }}
                >
                  <Delete color="error" />
                </IconButton>
              ) : null}
              {url === "input" ? (
                <Dropzone
                  onFinished={handleUpload}
                  message="Ajouter photos"
                  btnSx={{ height: "100%" }}
                />
              ) : (
                <img
                  src={url}
                  alt=""
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                />
              )}
            </Box>
          )
        })}
      </Box>
    </FormContainer>
  )
}
