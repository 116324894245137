import { Box, Chip } from "@mui/material"
import { ButtonLittleIcon, TableColumnProps } from "@/components"
import Edit from "@mui/icons-material/Edit"
import Delete from "@mui/icons-material/Delete"
import { GetListSender_getListSender as SenderType } from "@/graphql/emailing/types/GetListSender"

interface TableProps {
  onValidate: (item: number) => void
  onDelete: (item: number) => void
}

interface SenderTypeExtend extends SenderType {
  id: number
}

export const TableSender = ({ onValidate, onDelete }: TableProps): { columns: TableColumnProps[] } => {
  const columns = [
    {
      id: "id",
      label: "ID",
      component: (data: SenderTypeExtend) => <Box>{data.id}</Box>
    },
    {
      id: "name",
      label: "Nom",
      component: (data: SenderType) => <Box>{data.name}</Box>
    },
    {
      id: "email",
      label: "Email",
      component: (data: SenderType) => <Box>{data.email}</Box>
    },
    {
      id: "sendinblueId",
      label: "ID Brevo",
      component: (data: SenderType) => <Box>{data.sendinblueId}</Box>
    },
    {
      id: "status",
      label: "Validation",
      component: (data: SenderType) => data.validateStatus ? <Chip label="Vérifié" color="success" /> : <Chip label="Non-vérifié" color="error" />
    },
    {
      id: "action",
      label: "Action",
      component: (data: SenderTypeExtend) => {
        return (
          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: 1 }}>
              <ButtonLittleIcon color="success" icon={<Edit />} onClick={() => onValidate(data.sendinblueId)} />
            </Box>
            <Box sx={{ mr: 1 }}>
              <ButtonLittleIcon color="error" icon={<Delete />} onClick={() => onDelete(data.id)} />
            </Box>
          </Box>
        )
      }
    }
  ]

  return { columns }
}
