import { useQuery } from "@apollo/client"
import { useEffect } from "react"

import {
  SousCategoryById,
  SousCategoryByIdVariables,
  BUSINESS_SOUS_CATEGORY_BY_ID
} from "../graphql/business"

export const useCurrentSousCategoryById = (id?: number) => {
  const { data, loading, refetch } = useQuery<
    SousCategoryById,
    SousCategoryByIdVariables
  >(BUSINESS_SOUS_CATEGORY_BY_ID, {
    variables: {
      sousCategoryId: id as number
    },
    skip: !id
  })

  useEffect(() => {
   if (id && !data) {
    refetch()
   }
  }, [id])
  

  return {
    data: data?.sousCategoryById,
    loading,
    refetch
  }
}
