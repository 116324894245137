/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import { FC, useCallback, useState, ReactNode } from "react"
import { useDropzone } from "react-dropzone"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import {
  Button,
  Card,
  SxProps,
  Theme,
  Typography,
  Box,
  useTheme
} from "@mui/material"
import { PictureAsPdf } from "@mui/icons-material"
import { useApplicationContext, useFileUploader } from "../../hooks"
import { UploadInput } from "../../types"
import { Loading } from ".."

interface DropzoneProps {
  message?: string
  btnSx?: SxProps<Theme>
  onUpload?: (data: UploadInput) => void
  loading?: boolean
  photo?: string
  /** DO NOT USE THIS PROPS
   * @deprecated
   */
  isPDF?: boolean
  onFinished?: (file?: string, name?: string) => void
  uploadIcon?: ReactNode
  acceptedType?: string
}

export const DropzoneMultipleFiles: FC<DropzoneProps> = ({
  message,
  btnSx
}): JSX.Element => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Button variant="outlined" sx={{ p: 3, borderRadius: 3, ...btnSx }}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUploadIcon />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>{message || "Télécharger photos"}</p>
        )}
      </div>
    </Button>
  )
}

export const DropzoneProfil: FC<DropzoneProps> = ({
  message,
  btnSx,
  loading,
  photo,
  onUpload
}): JSX.Element => {
  const theme = useTheme()
  const { dispatchSnack } = useApplicationContext()
  const [image, setImage] = useState<string>()
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = (): void => {
      setImage(reader.result?.toString())
      onUpload &&
        onUpload({
          data: reader.result?.toString() as string,
          type: acceptedFiles[0].type,
          name: acceptedFiles[0].name
        })
    }
    reader.onerror = (): void => {
      dispatchSnack({
        open: true,
        severity: "error",
        message:
          "Une erreur s'est produite lors du chargement du fichier. Veuillez réesayer !"
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <Box
        sx={{
          p: 3,
          borderRadius: "50%",
          width: 150,
          height: 150,
          textTransform: "none",
          ...btnSx,
          background: `url(${image || photo}) center no-repeat`,
          backgroundSize: "cover",
          cursor: "pointer",
          border: `1px solid ${theme.palette.primary.main}`
        }}
      >
        <input {...getInputProps()} />
        {loading ? (
          <Card sx={{ p: 1, borderRadius: 3 }} elevation={3}>
            <Loading />
          </Card>
        ) : (
          <Box sx={{ visibility: photo ? "hidden" : "visible" }}>
            <CloudUploadIcon />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>{photo ? null : message}</p>
            )}
          </Box>
        )}
      </Box>
    </div>
  )
}

export const DropzoneSingleFile: FC<DropzoneProps> = ({
  message,
  btnSx,
  loading,
  photo,
  onUpload
}): JSX.Element => {
  const theme = useTheme()
  const { dispatchSnack } = useApplicationContext()
  const [image, setImage] = useState<string>()
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = (): void => {
      setImage(reader.result?.toString())
      onUpload &&
        onUpload({
          data: reader.result?.toString() as string,
          type: acceptedFiles[0].type,
          name: acceptedFiles[0].name
        })
    }
    reader.onerror = (): void => {
      dispatchSnack({
        open: true,
        severity: "error",
        message:
          "Une erreur se produite lors du chargement du fichier. Veuillez réésayer !"
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 3,
        textTransform: "none",
        ...btnSx,
        background: `url(${image || photo}) center no-repeat`,
        backgroundSize: "cover",
        cursor: "pointer",
        border: `1px solid ${theme.palette.primary.main}`
      }}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {loading ? (
          <Card sx={{ p: 1, borderRadius: 3 }} elevation={3}>
            <Loading />
            <Typography>Chargement...</Typography>
          </Card>
        ) : (
          <Box>
            <CloudUploadIcon />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>{message || "Télécharger photo"}</p>
            )}
          </Box>
        )}
      </div>
    </Box>
  )
}

export const Dropzone: FC<DropzoneProps> = ({
  message,
  btnSx,
  photo,
  isPDF,
  acceptedType,
  uploadIcon,
  onFinished
}): JSX.Element => {
  const theme = useTheme()
  const { dispatchSnack } = useApplicationContext()
  const { uploadFile, uploadLoading } = useFileUploader()
  const [image, setImage] = useState<string>()
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader()
    reader.readAsDataURL(acceptedFiles[0])
    reader.onload = async (): Promise<void> => {
      setImage(reader.result?.toString())
      const file = await uploadFile({
        data: reader.result?.toString() as string,
        type: acceptedFiles[0].type,
        name: acceptedFiles[0].name
      })
      if (file) {
        onFinished && onFinished(file, acceptedFiles[0].name)
      }
      if (!file) {
        dispatchSnack({
          open: true,
          severity: "error",
          message:
            "Une erreur se produite lors du chargement du fichier. Veuillez réésayer !"
        })
        onFinished && onFinished(undefined, "Error")
      }
      setImage(undefined)
    }
    reader.onerror = (): void => {
      dispatchSnack({
        open: true,
        severity: "error",
        message:
          "Une erreur se produite lors du chargement du fichier. Veuillez réésayer !"
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedType
  })

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 3,
        textTransform: "none",
        ...btnSx,
        background: `url(${image || photo}) center no-repeat`,
        backgroundSize: "cover",
        cursor: "pointer",
        border: `1px dashed ${theme.palette.primary.main}`
      }}
    >
      <div {...getRootProps()} style={{ height: "100%" }}>
        <input {...getInputProps()} />
        {uploadLoading ? (
          <Box sx={{ p: 1, textAlign: "center" }}>
            <Loading />
            <Typography>Chargement...</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              alignItems: "center",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              visibility: photo ? "hidden" : "visible"
            }}
          >
            {!isPDF ? <>{uploadIcon || <AddAPhotoIcon />}</> : null}
            {isPDF ? <PictureAsPdf /> : null}
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <Typography variant="subtitle2">
                {message || "Télécharger photo"}
              </Typography>
            )}
          </Box>
        )}
      </div>
    </Box>
  )
}
