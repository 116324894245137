import {
  BaseTable,
  ButtonLittleIcon,
  ConfirmModal,
  CustomModal,
  QueryResult
} from "@/components"
import { useMutation, useQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { Add, Delete, List } from "@mui/icons-material"
import {
  Box,
  styled,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Grid,
  Typography,
} from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react"
import { tableEmail } from "./components/tableEmail"
import {
  CRAETE_EMAILING,
  DELETE_EMAILING,
  DETAIL_EMAILING,
  GET_ALL_LIST_TEMPLATE,
  LIST_EMAILING,
  UPDATE_EMAILING
} from "@/graphql/emailing"
import { EmailMappingType, EmailType } from "@/types"
import { Translate } from "./components/translate"
import Swal from "sweetalert2"

type ParamsType = {
  paramKey: string
  paramValue: string
}

export const Notification = (): JSX.Element => {
  const navigate = useNavigate()
  const [detailId, setDetailsId] = useState<number | null>()
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [emailing, setEmailing] = useState<EmailMappingType>({
    type: EmailType.ADMIN_BILLING,
    templateId: 0,
    emailParams: ""
  })
  const [params, setParams] = useState<ParamsType[]>([])
  const { data, loading, refetch } = useQuery(LIST_EMAILING)
  const {
    data: dataTemplate,
    loading: loadingTemplate,
    refetch: refetchTemplate
  } = useQuery(GET_ALL_LIST_TEMPLATE)
  const {
    data: dataDetails,
    loading: loadingDetails,
    refetch: refetchDetails
  } = useQuery(DETAIL_EMAILING, {
    variables: {
      emailingDetailsId: detailId
    },
    skip: !detailId
  })
  const [createEmailing] = useMutation(CRAETE_EMAILING)
  const [deleteEmailing] = useMutation(DELETE_EMAILING)
  const [upEmailing] = useMutation(UPDATE_EMAILING)
  const ButtonTop = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: 15,
    marginBottom: 15
  }))

  const delEmail = () => {
    deleteEmailing({
      variables: {
        deleteEmailingId: detailId
      }
    })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Notification supprimé",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Votre modification a échoué",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
      })
      .finally(() => {
        refetch()
      })
  }
  const handleSelect = (e: any) => {
    setEmailing((prev) => {
      return {
        ...prev,
        type: e.target.value as EmailType
      }
    })
  }
  const handleSubmit = () => {
    const procedParam = params.map((item) => ({
      [item.paramKey]: item.paramValue
    }))

    if (detailId) {
      upEmailing({
        variables: {
          updateEmailingId: detailId,
          data: {
            ...emailing,
            emailParams:
              procedParam.length !== 0 ? JSON.stringify(procedParam) : ""
          }
        }
      })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Notification enregistré",
            text: "",
            showConfirmButton: false,
            showCloseButton: true
          })
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Votre modification a échoué",
            text: "Ce service existe déjà.",
            showConfirmButton: false,
            showCloseButton: true
          })
        })
        .finally(() => {
          refetch()
          setOpen(false)
        })
      return
    }

    createEmailing({
      variables: {
        data: {
          ...emailing,
          emailParams: params.length !== 0 ? JSON.stringify(procedParam) : ""
        }
      }
    })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Notification enregistré",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Votre modification a échoué",
          text: "Ce service existe déjà.",
          showConfirmButton: false,
          showCloseButton: true
        })
      })
      .finally(() => {
        refetch()
        setOpen(false)
      })
  }
  const handleChange = (e: any) => {
    setEmailing((prev) => {
      return {
        ...prev,
        templateId: Number(e.target.value)
      }
    })
  }
  const handleChangeParam = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const upParam = params.map((item, i) => {
      if (i === index) {
        return { ...item, [event.target.name]: event.target.value }
      }
      return item
    })
    setParams(upParam)
  }
  const handleCancelDelete = () => {
    setDetailsId(null)
    setOpenDelete(false)
  }
  const delItem = (index: number) => {
    const arrProc = [...params.slice(0, index), ...params.slice(index + 1)]
    setParams(arrProc)
  }
  const deleteItemAction = (id: number) => {
    setDetailsId(id)
    setOpenDelete(true)
  }
  const editEmail = (id: number) => {
    setDetailsId(id)
    setOpen(true)
  }
  const { columns } = tableEmail({
    onEdit: editEmail,
    onRemove: deleteItemAction
  })

  useEffect(() => {
    refetchDetails()
    if (dataDetails && !loadingDetails) {
      setEmailing({
        type: dataDetails?.emailingDetails?.type,
        templateId: dataDetails?.emailingDetails?.templateId,
        emailParams: dataDetails?.emailingDetails?.emailParams
      })

      if (dataDetails?.emailingDetails?.emailParams) {
        const arrProced = dataDetails?.emailingDetails?.emailParams
          .replace(/[[\]']/g, "")
          .split(",")
        const editAgain = arrProced.map((item: string) => JSON.parse(item))

        setParams(
          editAgain.map(
            (item: { [s: string]: unknown } | ArrayLike<unknown>) => ({
              paramKey: Object.keys(item),
              paramValue: Object.values(item)
            })
          )
        )
      }
    }
  }, [dataDetails, detailId, loadingDetails, refetchDetails])
  

  return (
    <Box sx={{ p: 3 }}>
      <QueryResult data={data} loading={loading}>
        <BaseTable
          title="Liste des templates mails de notification"
          columns={columns}
          data={data?.listEmailing || []}
          page={10}
          topComponent={
            <Box sx={{ p: 2, display: "flex", alignItems: "center" , flexWrap: "wrap" }}>
              <ButtonTop
                variant="outlined"
                startIcon={<Add />}
                onClick={() => {
                  setEmailing({
                    type: dataDetails?.emailingDetails?.type,
                    templateId: 0,
                    emailParams: ""
                  })
                  setParams([])
                  setOpen(true)
                  setDetailsId(null)
                }}
                sx={{ color: "#050005" }}
              >
                Ajouter une notification
              </ButtonTop>
              <ButtonTop
                variant="outlined"
                onClick={() => navigate('list_template')}
                startIcon={<List />}
                sx={{ color: "#050005" }}
              >Liste des templates</ButtonTop>
            </Box>
          }
        />
      </QueryResult>

      <CustomModal
        noAction
        title={detailId ? "Editer un template" : "Ajouter un template"}
        open={open}
        setOpen={setOpen}
      >
        <Box>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Service</InputLabel>
              <Select
                label="Service"
                value={emailing?.type}
                onChange={handleSelect}
              >
                {Object.keys(EmailType).map((key) => (
                  <MenuItem key={key} value={EmailType[key as EmailType]}>
                    {Translate[key as EmailType]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Liste des templates</InputLabel>
              <Select
                label="Liste des templates"
                value={emailing?.templateId}
                onChange={handleChange}
              >
                {dataTemplate?.GetAllListTemplate.map((key: any) => (
                  <MenuItem key={key} value={key.idSendinblue}>
                    {key.nameTemplate}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Template ID"
              variant="outlined"
              value={emailing?.templateId}
              fullWidth
              disabled
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            {params.map((item, index) => (
              <Grid
                key={`${index}-item`}
                container
                spacing={2}
                marginBottom={2}
              >
                <Grid item xs={5}>
                  <TextField
                    name="paramKey"
                    value={item.paramKey}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChangeParam(index, e)
                    }
                    label="Paramètre"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    name="paramValue"
                    value={item.paramValue}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChangeParam(index, e)
                    }
                    label="Valeur"
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "Flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <ButtonLittleIcon
                    sx={{ mr: 1, mb: 1 }}
                    icon={<Delete />}
                    color="error"
                    onClick={() => delItem(index)}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
          <Box
            sx={{
              mt: 3,
              textAlign: "center"
            }}
          >
            <Button variant="contained" onClick={handleSubmit}>
              Enregistrer
            </Button>
          </Box>
        </Box>
      </CustomModal>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={delEmail}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de notification"
      >
        <Typography>
          Vous voulez vraiment supprimer cette notification ?
        </Typography>
      </ConfirmModal>
    </Box>
  )
}
