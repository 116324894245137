import {
  useApplicationContext,
  useCurrentBusiness,
  useUpdateOrCreateReservationConfiguration
} from "@/hooks"
import { ReservationConfigurationInput } from "@/types"
import {
  Box,
  Button,
  Checkbox,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import TimePicker from "rc-time-picker"
import { useEffect, useState } from "react"
import "rc-time-picker/assets/index.css"
import moment from "moment"
import { SwitchStyle } from "@/components"
import { useMutation } from "@apollo/client"
import { STATUS_BOOKING } from "@/graphql/business"

export const ConfigurationReservation = () => {
  const { business, dispatchSnack } = useApplicationContext()
  const { data, refetch } = useCurrentBusiness(business?.id)
  const [active, setActive] = useState(false)
  const {
    createOrUpdateConfiguration,
    configurationError,
    configurationLoading
  } = useUpdateOrCreateReservationConfiguration()

  const [configuration, setConfiguration] =
    useState<ReservationConfigurationInput>()

  const handleConfigureReservation = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!configuration) return
    const lite = await createOrUpdateConfiguration(configuration, {
      businessId: business?.id,
      configurationId: data?.ReservationConfiguration?.id
    })
    if (lite) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "configuration ajoutée"
      })
      await refetch()
    }
  }

  const [activeBookingFn] = useMutation(STATUS_BOOKING)

  useEffect(() => {
    if (data?.ReservationConfiguration) {
      setConfiguration({
        morning: data?.ReservationConfiguration?.morning,
        afternoon: data?.ReservationConfiguration?.afternoon,
        night: data?.ReservationConfiguration?.night,
        startHourMorning: data?.ReservationConfiguration?.startHourMorning
          ? moment(data?.ReservationConfiguration?.startHourMorning)
          : "",
        endHourMorning: data?.ReservationConfiguration?.endHourMorning
          ? moment(data?.ReservationConfiguration?.endHourMorning)
          : "",
        startHourAfternoon: data?.ReservationConfiguration?.startHourAfternoon
          ? moment(data?.ReservationConfiguration?.startHourAfternoon)
          : "",
        endHourAfternoon: data?.ReservationConfiguration?.endHourAfternoon
          ? moment(data?.ReservationConfiguration?.endHourAfternoon)
          : "",
        startHourNight: data?.ReservationConfiguration?.startHourNight
          ? moment(data?.ReservationConfiguration?.startHourNight)
          : "",
        endHourNight: data?.ReservationConfiguration?.endHourNight
          ? moment(data?.ReservationConfiguration?.endHourNight)
          : "",
        minutesIntervalle: data?.ReservationConfiguration?.minutesIntervalle
      })
    }
  }, [data])

  useEffect(() => {
    if (configurationError) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: configurationError
      })
    }
  }, [configurationError, dispatchSnack])

  const handleCheck = (name: string, checked: boolean) => {
    setConfiguration((prev) => ({
      ...prev,
      [name]: checked
    }))
  }

  const handleChangeIntervalle = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setConfiguration((prev) => ({
      ...prev,
      minutesIntervalle: parseFloat(value)
    }))
  }

  const handleStatus = () => {
    setActive(!active)
    activeBookingFn({
      variables: {
        businessId: business?.id,
        state: !active
      }
    })
  }

  useEffect(() => {
    setActive(business?.activeBooking || false)
  }, [business])

  return (
    <Card elevation={0} sx={{ m: 2 }}>
      <CardContent>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="div">Activer l'option de reservation sur votre magasin</Typography>
          <SwitchStyle textActive status={active} change={handleStatus} />
        </Box>
        <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
          Configurer l'horaire des réservations dans votre établissement
        </Typography>
        <form onSubmit={handleConfigureReservation}>
          <Typography>Fourchette de temps</Typography>
          <RadioGroup
            row
            name="minutesIntervalle"
            value={`${configuration?.minutesIntervalle}`}
            onChange={handleChangeIntervalle}
          >
            <FormControlLabel
              value={15}
              control={<Radio />}
              label="15 min"
              sx={{
                pr: 2,
                mb: 1
              }}
            />
            <FormControlLabel
              value={30}
              control={<Radio />}
              label="30 min"
              sx={{
                pr: 2,
                mb: 1
              }}
            />
            <FormControlLabel
              value={60}
              control={<Radio />}
              label="60 min"
              sx={{
                pr: 2,
                mb: 1
              }}
            />
          </RadioGroup>
          <FormGroup>
            <FormControlLabel
              name="morning"
              onChange={(e, checked) => handleCheck("morning", checked)}
              control={<Checkbox checked={configuration?.morning || false} />}
              label="Matin"
            />
            <Box
              sx={{
                flexGrow: 1,
                alignItems: "center",
                display: configuration?.morning ? "flex" : "none"
              }}
            >
              <Box
                sx={{
                  px: 2,
                  pb: 2,
                  display: "flex",
                  alignItems: "center",
                  "&:hover .actions": {
                    opacity: 1
                  }
                }}
              >
                <Box sx={{ px: 2 }}>
                  <Typography variant="body2">ouverture</Typography>
                  <TimePicker
                    showSecond={false}
                    minuteStep={15}
                    value={configuration?.startHourMorning}
                    name="startHourMorning"
                    allowEmpty={false}
                    onChange={(newValue) => {
                      setConfiguration((prev) => ({
                        ...prev,
                        startHourMorning: newValue
                      }))
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="body2">fermeture</Typography>
                  <TimePicker
                    showSecond={false}
                    minuteStep={15}
                    name="endHourMorning"
                    allowEmpty={false}
                    value={configuration?.endHourMorning}
                    onChange={(newValue) => {
                      setConfiguration((prev) => ({
                        ...prev,
                        endHourMorning: newValue
                      }))
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <FormControlLabel
              name="afternoon"
              onChange={(e, checked) => handleCheck("afternoon", checked)}
              control={<Checkbox checked={configuration?.afternoon || false} />}
              label="Après-midi"
            />
            <Box
              sx={{
                flexGrow: 1,
                alignItems: "center",
                display: configuration?.afternoon ? "flex" : "none"
              }}
            >
              <Box
                sx={{
                  px: 2,
                  pb: 2,
                  display: "flex",
                  alignItems: "center",
                  "&:hover .actions": {
                    opacity: 1
                  }
                }}
              >
                <Box sx={{ px: 2 }}>
                  <Typography variant="body2">ouverture</Typography>
                  <TimePicker
                    showSecond={false}
                    minuteStep={15}
                    name="startHourAfternoon"
                    value={configuration?.startHourAfternoon}
                    allowEmpty={false}
                    onChange={(newValue) => {
                      setConfiguration((prev) => ({
                        ...prev,
                        startHourAfternoon: newValue
                      }))
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="body2">fermeture</Typography>
                  <TimePicker
                    showSecond={false}
                    minuteStep={15}
                    name="endHourAfternoon"
                    value={configuration?.endHourAfternoon}
                    allowEmpty={false}
                    onChange={(newValue) => {
                      setConfiguration((prev) => ({
                        ...prev,
                        endHourAfternoon: newValue
                      }))
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <FormControlLabel
              name="night"
              onChange={(e, checked) => handleCheck("night", checked)}
              control={<Checkbox checked={configuration?.night || false} />}
              label="Soir"
            />
            <Box
              sx={{
                flexGrow: 1,
                alignItems: "center",
                display: configuration?.night ? "flex" : "none"
              }}
            >
              <Box
                sx={{
                  px: 2,
                  pb: 2,
                  display: "flex",
                  alignItems: "center",
                  "&:hover .actions": {
                    opacity: 1
                  }
                }}
              >
                <Box sx={{ px: 2 }}>
                  <Typography variant="body2">ouverture</Typography>
                  <TimePicker
                    showSecond={false}
                    minuteStep={15}
                    name="startHourNight"
                    value={configuration?.startHourNight}
                    allowEmpty={false}
                    onChange={(newValue) => {
                      setConfiguration((prev) => ({
                        ...prev,
                        startHourNight: newValue
                      }))
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="body2">fermeture</Typography>
                  <TimePicker
                    showSecond={false}
                    minuteStep={15}
                    name="endHourNight"
                    value={configuration?.endHourNight}
                    allowEmpty={false}
                    onChange={(newValue) => {
                      setConfiguration((prev) => ({
                        ...prev,
                        endHourNight: newValue
                      }))
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </FormGroup>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2
            }}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={configurationLoading}
              sx={{ color: "#050005" }}
            >
              Valider
            </Button>
          </Box>{" "}
        </form>
      </CardContent>
    </Card>
  )
}
