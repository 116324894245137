/* eslint-disable no-nested-ternary */
import { useMutation } from "@apollo/client"
import { Box } from "@mui/material"
import { FC, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
  CreateBusinessDinerServices,
  CreateBusinessDinerServicesVariables,
  CREATE_DINER_SERVICES
} from "../../../../graphql/business"
import { storage } from "../../../../utils"
import { CustomCheckboxDiner } from "../components/CustomCheckboxDiner"
import { FormContainer } from "../components/FormContainer"

interface SignupStepProps {
  afterSave?: () => void
}
export const FicheDiner: FC<SignupStepProps> = ({ afterSave }): JSX.Element => {
  const [createDiner, { loading }] = useMutation<
    CreateBusinessDinerServices,
    CreateBusinessDinerServicesVariables
  >(CREATE_DINER_SERVICES)
  const { id } = useParams()
  const [newId, setNewId] = useState<number>()

  const [values, setValues] = useState({
    surplace: null,
    emporter: null,
    drive: null,
    serviceDrive: null,
    delevery: null,
    deliveryNoContact: null
  })
  const navigate = useNavigate()

  const handleCreate = async (): Promise<void> => {
    try {
      const create = await createDiner({
        variables: {
          createBusinessDinerServicesId:
            parseFloat(id as string) || (newId as number),
          services: {
            ...values
          }
        }
      })
      if (create.data?.createBusinessDinerServices.id) {
        if (afterSave) {
          afterSave()
        } else {
          navigate(
            `/signup/description/${create.data?.createBusinessDinerServices.id}`
          )
        }
      }
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const handleChange = (name: string, checked: boolean | null): void => {
    setValues((prev) => ({
      ...prev,
      [name]: checked
    }))
  }

  const handleIgnore = (): void => {
    navigate(`/signup/description/${id}`)
  }
  const loadLocal = useCallback(() => {
    const nid = storage.get("new_Id")
    setNewId(parseFloat(nid))
  }, [])
  useEffect(() => {
    loadLocal()
  }, [loadLocal])
  return (
    <FormContainer
      step={4}
      loading={loading}
      title="Ajouter des types de services pour le diner"
      onNext={handleCreate}
      onIgnore={handleIgnore}
    >
      <Box sx={{ py: 2 }}>
        <Box sx={{ py: 1 }}>
          <CustomCheckboxDiner
            title="Repas sur places"
            subtitle="Les clients peuvent diner dans votre restaurant"
            current={values.surplace}
            name="surplace"
            onChange={handleChange}
          />
        </Box>

        <Box sx={{ py: 1 }}>
          <CustomCheckboxDiner
            title="À emporter"
            subtitle="Les clients récupèrent leur commande à l'intérieur de votre restaurant"
            current={values.emporter}
            name="emporter"
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ py: 1 }}>
          <CustomCheckboxDiner
            title="Drive disponible"
            subtitle="Les clients récupèrent leur commande à l'extérieur de votre restaurant"
            current={values.drive}
            name="drive"
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ py: 1 }}>
          <CustomCheckboxDiner
            title="Service de drive"
            subtitle="Les clients récupèrent leurs commande à un giuchet de drive de votre restaurant"
            current={values.serviceDrive}
            name="serviceDrive"
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ py: 1 }}>
          <CustomCheckboxDiner
            title="Livraison"
            subtitle="Votre restaurant propose un service de livraison (interne ou externe)"
            current={values.delevery}
            name="delevery"
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ py: 1 }}>
          <CustomCheckboxDiner
            title="Livraison sans contact"
            subtitle="Votre restaurant livre les commande de clients et les plces devant leur porte"
            current={values.deliveryNoContact}
            name="deliveryNoContact"
            onChange={handleChange}
          />
        </Box>
      </Box>
    </FormContainer>
  )
}
