import { TemplateGames } from "@/components"
import { ListTemplate } from "@/components/TemplateGames/listTemplate/ListTemplate"
import { Route, Routes } from "react-router-dom"
import { CreateGame } from "./CreateGame/components/CreateGame"
import { ManageLots } from "./ManageLots/ManageLots"
import { QRCodeHome } from "./QRCodeHome"
import { QrcodeList } from "./QRCodeHome/QrcodeList"
import { ReglementPage } from "./Reglement/ReglementPage"

export const GameRoute = () => {
  return (
    <Routes>
      <Route index element={<ListTemplate />} />
      <Route path="list-template" element={<ListTemplate />} />
      <Route path="list-template/add-template" element={<TemplateGames />} />
      <Route path="list-template/:idTemplate/*" element={<TemplateGames />} />
      <Route path="list-qrcode" element={<QrcodeList />} />
      <Route path="list-qrcode/:id" element={<QRCodeHome />} />
      <Route path="list-lots" element={<ManageLots />} />
      <Route path="list-qrcode/create-game" element={<CreateGame />} />
      <Route path="list-qrcode/:id/update-game" element={<CreateGame />} />
      <Route
        path="list-qrcode/reglement/:idReglement"
        element={<ReglementPage />}
      />
    </Routes>
  )
}
