/* eslint-disable camelcase */
import {
  Box,
  styled,
  Divider,
  Typography,
  IconButton,
  BoxProps
} from "@mui/material"
import { FC, SyntheticEvent, useEffect, useState } from "react"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete"
import { ResearchBox } from "../forms"
import { Loading } from "../loading"

const SuggestionItem = styled(Box)(() => ({
  "&:hover": {
    background: "#0001"
  }
}))

interface AddressInputProps {
  label?: string
  editable?: boolean
  containerProps?: BoxProps
  loading?: boolean
  defaultValue?: string
  nodivider?: boolean
  name?: string
  onSave?: (value: string, name: string) => void
}
export const AddressInputEdit: FC<AddressInputProps> = ({
  containerProps,
  name,
  editable = true,
  loading,
  nodivider,
  label,
  defaultValue,
  onSave
}): JSX.Element => {
  const [address, setAddress] = useState("")

  useEffect(()=> {
    if(defaultValue){
      setAddress(defaultValue)
    }
    return ()=>{
      // cleanup
    }
  }, [defaultValue])

  useEffect(()=>{
    if(!name) return 
    onSave && onSave(address, name)
  }, [address])

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300
  })

  const [edit, setEdit] = useState<boolean>(false)

  const handleInput = (valueText: string): void => {
    // Update the keyword of the input element
    setValue(valueText)
  }

  const handleSelect = (e: SyntheticEvent, datas: any) => () => {
    e.preventDefault()
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"

    const { description } = datas

    setValue(description, false)

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng })
      })
      .catch((error) => {
        console.log("😱 Error: ", error)
      })
  }

  const SelectPlaces = (places: any): void => {
    setAddress(places.description)
    clearSuggestions()
    setEdit(false)
    
  }
  const handleSave = (e: SyntheticEvent): void => {
    e.preventDefault()
    if (!name) return
    onSave && onSave(address, name)
    setEdit(false)
  }
  useEffect(() => {
    if (defaultValue) {
      setAddress(defaultValue)
    }
    return () => {
      // cleanup
    }
  }, [defaultValue])

  return (
    <Box>
      <form>
        <Box>
          <div>
            <ResearchBox
              placeholder="Ex : 55 rue de belle..."
              address={address}
              onChange={handleInput}
              startLabel="Adresse"
            />

            {status === "OK" ? (
              <Box sx={{ p: 1 }}>
                {data.map((suggestion) => {
                  const {
                    place_id,
                    structured_formatting: { main_text, secondary_text }
                  } = suggestion

                  return (
                    <SuggestionItem
                      key={place_id}
                      onClick={() => {
                        SelectPlaces(suggestion)
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography variant="subtitle2">
                        {main_text}, {secondary_text}
                      </Typography>
                      <Divider />
                    </SuggestionItem>
                  )
                })}
              </Box>
            ) : null}
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            minWidth: 150,
            justifyContent: "flex-end"
          }}
        >
          {loading ? (
            <IconButton>
              <Loading />
            </IconButton>
          ) : null}
        </Box>
        {nodivider ? null : <Divider />}
      </form>
    </Box>
  )
}
