import { Route, Routes } from "react-router-dom"
import { ListTemplate } from "./listTemplate/ListTemplate"
import { TemplateGames } from "./TemplateGames"

export const TemplateRoute = () => {
  return (
    <Routes>
      <Route index element={<ListTemplate />} />
      <Route path="add-template" element={<TemplateGames />} />
      <Route path=":idTemplate/*" element={<TemplateGames />} />
    </Routes>
  )
}
