/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@apollo/client"
import { AdminInfoNumber, INFOS_NUMBER_ADMIN } from "../../graphql"
import {
  BusinessInfoNumber,
  BusinessInfoNumberVariables,
  BUSINESS_INFO_NUM
} from "../../graphql/business"

export const useInfoNumber = () => {
  const { data, loading, error } = useQuery<AdminInfoNumber>(INFOS_NUMBER_ADMIN)
  return {
    data: data?.adminInfoNumber,
    loading,
    error
  }
}

export const useBusinessInfoNumber = (id?: number) => {
  const { data, loading, error } = useQuery<
    BusinessInfoNumber,
    BusinessInfoNumberVariables
  >(BUSINESS_INFO_NUM, {
    skip: !id,
    variables: {
      businessId: id as number
    }
  })

  return {
    data: data?.businessInfoNumber,
    loading,
    error
  }
}
