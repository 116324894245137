import Add from "@mui/icons-material/Add"
import { Box, Button, styled, Typography } from "@mui/material"
import { SyntheticEvent, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { BaseTable, ConfirmModal } from "@/components"
import { QrcodeRouteContext } from "@/contexts/QrcodeContext"
import { Qrcodes_qrcodes as IQrcode } from "@/graphql/qrcode"
import { useApplicationContext, useQRCode, useQRCodeAction } from "@/hooks"
import Swal from "sweetalert2"
import { useQrcodeList } from "./useQrcodeList"

const ButtonTop = styled(Button)(() => ({
  textTransform: "none"
}))

export const QrcodeList = () => {
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteId, setDeleteId] = useState<number>(0)
  const [page] = useState<number>(1)
  const { removeQRCode, loadingRemove } = useQRCodeAction()

  const { business } = useApplicationContext()
  const { setCurrentQrcodeId } = useContext(QrcodeRouteContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading, refetch } = useQRCode(
    business?.id || parseFloat(id as string)
  )
  const handleView = (qr: IQrcode) => {
    setCurrentQrcodeId(qr.id)
    navigate(`${qr.id}`)
  }
  const handleRemove = (qrId: number) => {
    setOpenDelete(true)
    setDeleteId(qrId)
  }
  const confirmDelete = () => {
    setOpenDelete(false)
    Swal.showLoading()
    if (loadingRemove) {
      Swal.showLoading()
    }
    removeQRCode(deleteId, () => {
      Swal.fire({
        icon: "success",
        title: "Suppression avec succès",
        text: "",
        showConfirmButton: false,
        showCloseButton: true
      })
      Swal.hideLoading()
      refetch()
    })
  }
  const handleCancelDelete = () => {
    setOpenDelete(false)
    setDeleteId(0)
  }
  const handleCreate = (e: SyntheticEvent) => {
    e.preventDefault()
    navigate("create-game")
  }
  const { columns } = useQrcodeList({
    onView: handleView,
    onRemove: handleRemove
  })

  useEffect(() => {
    setCurrentQrcodeId(undefined)
  }, [])

  return (
    <Box
      sx={{
        p: 2,
        width: { xs: "fit-content", md: "100%" }
      }}
    >
      <BaseTable
        title="Liste des QR Codes"
        loading={loading}
        columns={columns}
        data={data || []}
        page={page}
        topComponent={
          <ButtonTop
            variant="outlined"
            startIcon={<Add />}
            onClick={handleCreate}
            sx={{ color: "#050005", marginBlock: 2 }}
          >
            Ajouter nouveau QR Code
          </ButtonTop>
        }
      />
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={confirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de jeu"
      >
        <Typography>Vous êtes sur le point de supprimer un jeu !</Typography>
      </ConfirmModal>
    </Box>
  )
}
