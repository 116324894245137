/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment, {Moment} from "moment"

type ViewType = "year" | "day" | "month"
interface DateTimePickerBaseProps {
  onChange: (date: Moment | null) => void
  label?: string
  value?: Date
  format?: string
  views?: readonly ViewType[]
  minDate?: string
  disabled?: boolean
}
export const DatePickerBase: React.FC<DateTimePickerBaseProps> = ({
  onChange,
  label,
  value,
  views,
  disabled
}): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disabled={disabled}
        label={label}
        views={views}
        disablePast
        onChange={onChange}
        value={moment(value)}
      />
    </LocalizationProvider>
  )
}
