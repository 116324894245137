/* eslint-disable @typescript-eslint/no-unused-vars */
import Delete from "@mui/icons-material/Delete"
import LoginIcon from "@mui/icons-material/Login"
import FiberManualRecord from "@mui/icons-material/FiberManualRecord"
import { Typography, Box, Chip, Button } from "@mui/material"
import moment from "moment"
import { ButtonLittleIcon, TableColumnProps } from "../../../components"
import { BusinessList_businessList as IBusiness } from "../../../graphql"

interface UseEtablissementColumnsProps {
  onView?: (b: IBusiness) => void
  onRemove?: (b: IBusiness) => void
  onEmail?: (b: IBusiness) => void
}
export const useEtablissementColumns = ({
  onView,
  onRemove
}: UseEtablissementColumnsProps): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "nom",
      label: "Nom de l'établissement",
      component: (data: IBusiness) => {
        return <Typography variant="subtitle2">{data.businessName}</Typography>
      }
    },
    {
      id: "nbclient",
      label: "Nb d'inscription",
      component: (data: IBusiness) => {
        return (
          <Box>
            <Chip
              label={data?.clients?.length + " inscrit"}
              variant="outlined"
              color="success"
              size="small"
            />
          </Box>
        )
      }
    },

    {
      id: "email",
      label: "Email",
      component: (data: IBusiness) => {
        return (
          <Box>
            <Typography variant="caption">{data.email}</Typography>
          </Box>
        )
      }
    },
    {
      id: "cate",
      label: "Catégorie d'activité",
      component: (data: IBusiness) => {
        return (
          <Box sx={{ maxWidth: 200 }}>
            <Typography variant="caption">{data.activity}</Typography>
          </Box>
        )
      }
    },
    {
      id: "created",
      label: "Date d'inscription",
      component: (data: IBusiness) => {
        return (
          <Box>
            <Typography variant="caption">
              {moment(data.createdAt).format("DD/MM/YYYY")}{" "}
            </Typography>
          </Box>
        )
      }
    },
    {
      id: "localisation",
      label: "Localisation",
      component: (data: IBusiness) => {
        return (
          <Box sx={{ maxWidth: 200 }}>
            <Typography variant="caption">{data.address}</Typography>
          </Box>
        )
      }
    },
    {
      id: "status",
      label: "Abonnement",
      component: (data: IBusiness) => {
        const abonnement = data?.Abonnements?.[0]?.status ?? null
        return (
          <Box>
            {abonnement !== null ? <Chip
              label="Actif"
              variant="outlined"
              color="success"
              size="small"
              icon={<FiberManualRecord />}
            /> :
              <Chip
                label="En attente"
                variant="outlined"
                color="error"
                size="small"
                icon={<FiberManualRecord />}
              />}
          </Box>
        )
      }
    },

    {
      id: "action",
      label: "Editer",
      component: (data: IBusiness) => (
        <Box>
          <Button
            variant="outlined"
            endIcon={<LoginIcon />}
            sx={{ textTransform: "none", mr: 1, py: 0 }}
            onClick={() => onView && onView(data)}
            color="info"
          >
            Entrer
          </Button>

          <ButtonLittleIcon
            onClick={() => onRemove && onRemove(data)}
            icon={<Delete />}
            color="error"
            sx={{ mr: 1 }}
          />
        </Box>
      )
    }
  ]
  return { columns }
}
