import { ButtonLittleIcon, TableColumnProps } from "@/components"
import { CampagnList_campagnList as campagnelistType } from "@/graphql/emailing/types/CampagnList"
import Delete from "@mui/icons-material/Delete"
import Send from "@mui/icons-material/Send"
import { Box, Chip } from "@mui/material"
import moment from "moment"

interface TableCampaignProps {
  onSend: (id: number | null) => void
  onDelete: (id: number | null) => void
}
export const TableCampaign = ({onSend, onDelete}: TableCampaignProps): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "name",
      label: "Nom",
      component: (data: campagnelistType) => <Box>{data.name}</Box>
    },
    {
      id: "date",
      label: "Date de création",
      component: (data: campagnelistType) => <Box>{moment(data.createdAt).format("DD MMMM YYYY")}</Box>
    },
    {
      id: "type",
      label: "Type de campagne",
      component: (data: campagnelistType) => <Box>{data.type === "SMS" ? "Sms" : "E-mail"}</Box>
    },
    {
      id: "status",
      label: "Status",
      component: (data: campagnelistType) => data.status ? <Box><Chip label="Envoyé" color="success" /></Box> : <Box><Chip label="En attente" color="error" /></Box>
      },
    {
      id: "action",
      label: "Action",
      component: (data: campagnelistType) => (
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mr: 2 }}>
            <ButtonLittleIcon color="error" onClick={() => onDelete(data.id)} icon={<Delete />} />
          </Box>
          <Box>
            <ButtonLittleIcon color="success" onClick={() => onSend(data.id)} icon={<Send />} />
          </Box>
        </Box>
      )
    }
  ]
  return {
    columns
  }
}
