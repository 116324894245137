import { useQuery } from "@apollo/client"
import {
  BusinessInfo,
  BusinessInfoVariables,
  BUSINESS_PROFIL
} from "../graphql/business"

export const useCurrentBusiness = (id?: number) => {
  const { data, loading, error, refetch } = useQuery<
    BusinessInfo,
    BusinessInfoVariables
  >(BUSINESS_PROFIL, {
    variables: {
      businessId: id as number
    },
    skip: !id
  })
  return {
    data: data?.businessInfo,
    loading,
    error,
    refetch
  }
}
