import { gql } from "@apollo/client"

export const CREATE_BUSINESS_1 = gql`
  mutation CreateBusinessStep1($business: BusinessInputStep1!) {
    createBusinessStep1(business: $business) {
      id
      password
      email
      lastname
      firstname
      activity
      clientSecret
    }
  }
`
export const CHECK_SUBDOMAIN = gql`
  mutation CheckDomain($url: String!) {
    checkDomain(url: $url) {
      status
    }
  }
`

export const CREATE_DINER_SERVICES = gql`
  mutation CreateBusinessDinerServices(
    $services: BusinessDinerServicesInput!
    $createBusinessDinerServicesId: Float!
  ) {
    createBusinessDinerServices(
      services: $services
      id: $createBusinessDinerServicesId
    ) {
      id
      email
    }
  }
`

export const UPDATE_BUSINESS_INFO = gql`
  mutation UpdateBusinessInfo(
    $businessId: Float!
    $business: BusinessUpdateInput
    $photoPlats: PlatPhotoUpdateInput
    $photoMenus: MenuPhotoUpdateInput
  ) {
    updateBusinessInfo(
      businessId: $businessId
      business: $business
      photoPlats: $photoPlats
      photoMenus: $photoMenus
    ) {
      id
      email
    }
  }
`
export const LOGIN_BUSINESS = gql`
  mutation LoginBusiness($password: String!, $email: String!) {
    loginBusiness(password: $password, email: $email) {
      id
      token
      logo
      businessName
      password
      email
      lastname
      firstname
      activity
      phone
      siret
      url
      siteweb
      description
      coverPhoto
      createdAt
      updatedAt
      address
      dinerId
      dinerServicesId
    }
  }
`

export const RESET_PASSWORD_BUSINESS = gql`
  mutation ResetPasswordBusiness(
    $password: String!
    $oldPassword: String!
    $businessId: Float!
  ) {
    resetPasswordBusiness(
      password: $password
      oldPassword: $oldPassword
      businessId: $businessId
    ) {
      id
      password
      email
    }
  }
`

export const BUSINESS_HOURS = gql`
  mutation UpdateBusinessHours(
    $businessId: Float!
    $businessHours: OpeningHourListType
  ) {
    updateBusinessHours(businessId: $businessId, businessHours: $businessHours)
  }
`

export const DELETE_FILE = gql`
  mutation DeleteFile($fileId: Float!) {
    deleteFile(fileId: $fileId)
  }
`

export const DELETE_BUSINESS = gql`
  mutation DeleteBusiness($businessId: Float!) {
    deleteBusiness(businessId: $businessId)
  }
`

export const CREATE_USER = gql`
  mutation CreateUtilisateur($data: CreateUtilisateurInput!) {
    createUtilisateur(data: $data) {
      id
      email
      firstname
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUtilisateur($userId: Float!, $data: UpdateUtilisateurInput!) {
    updateUtilisateur(userId: $userId, data: $data) {
      id
      email
      firstname
      lastname
      password
      type
    }
  }
`
export const DELETE_HORAIRES = gql`
  mutation DeleteHoraires($data: DeleteHoursInput!) {
    deleteHoraires(data: $data)
  }
`
export const UPDATE_OR_CREATE_PALETTE = gql`
  mutation BusinessPalette(
    $data: PaletteInput!
    $businessId: Float
    $paletteId: Float
  ) {
    businessPalette(
      data: $data
      businessId: $businessId
      paletteId: $paletteId
    ) {
      id
      bgImage
      bgColor1
      bgColor2
      bgColor
      bgViedo
      bgType
      businessId
    }
  }
`

export const UPDATE_OR_CREATE_THEME = gql`
  mutation BusinessTheme(
    $themeId: Float
    $businessId: Float
    $data: ThemeInput!
  ) {
    businessTheme(themeId: $themeId, businessId: $businessId, data: $data) {
      id
      colorBtn
      colorBtnText
      bsType
      mediaColor
      colorText
      colorCardProduct
      colorTextCardProduct
      police
      fontSize
      businessId
    }
  }
`

export const UPDATE_OR_CREATE_CONFIGURATION_RESERVATION = gql`
  mutation ReservationConfiguration(
    $data: ReservationConfigurationInput!
    $businessId: Float
    $configurationId: Float
  ) {
    reservationConfiguration(
      data: $data
      businessId: $businessId
      configurationId: $configurationId
    ) {
      morning
      id
      afternoon
      night
      startHourMorning
      endHourMorning
      startHourAfternoon
      endHourAfternoon
      startHourNight
      endHourNight
    }
  }
`

export const UPDATE_LIST_CATEGORY = gql`
  mutation updateListCategory($categoryId: Float!, $statusArrow: String!) {
    updateListCategory(categoryId: $categoryId, statusArrow: $statusArrow)
  }
`

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($data: CategoryInput!) {
    createCategory(data: $data)
  }
`

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($data: UpdateCategoryInput!, $categoryId: Float!) {
    updateCategory(data: $data, categoryId: $categoryId)
  }
`

export const UPDATE_STATUS_CATEGORY = gql`
  mutation updateStatusCategory($status: Boolean!, $categoryId: Float!) {
    updateStatusCategory(status: $status, categoryId: $categoryId)
  }
`

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($categoryId: Float!) {
    removeCategory(categoryId: $categoryId) {
      name
      categoryId
      createdAt
      description
    }
  }
`

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($businessId: Float!, $data: ProductInput!) {
    createProduct(businessId: $businessId, data: $data)
  }
`

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($data: UpdateProductInput!, $productId: Float!) {
    updateProduct(data: $data, productId: $productId)
  }
`
export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: Float!) {
    removeProduct(productId: $productId)
  }
`

export const CREATE_SOUS_CATEGORY = gql`
  mutation CreateSousCategory($data: SousCategoryInput!) {
    createSousCategory(data: $data)
  }
`

export const UPDATE_SOUS_CATEGORY = gql`
  mutation UpdateSousCategory(
    $data: UpdateSousCategoryInput!
    $sousCategoryId: Float!
  ) {
    updateSousCategory(data: $data, sousCategoryId: $sousCategoryId)
  }
`

export const UPDATE_STATUS_SOUS_CATEGORY = gql`
  mutation updateStatusSubCategory($status: Boolean!, $sousCategoryId: Float!) {
    updateStatusSubCategory(status: $status, sousCategoryId: $sousCategoryId)
  }
`

export const DELETE_SOUS_CATEGORY = gql`
  mutation DeleteSousCategory($sousCategoryId: Float!) {
    deleteSousCategory(sousCategoryId: $sousCategoryId) {
      name
      categoryId
      createdAt
      description
    }
  }
`

export const CREATE_CLICK = gql`
  mutation CreateClick($data: ClickCollectInput!) {
    createClick(data: $data)
  }
`

export const UPDATE_CLICK = gql`
  mutation UpdateClickById($id: Float!, $data: UpdateClickInput!) {
    updateClickById(id: $id, data: $data)
  }
`

export const CREATE_TEMPLATE_SPINNER = gql`
  mutation CreateTemplateSpinner($data: TemplateInput!) {
    createTemplateSpinner(data: $data) {
      id
      name
      description
      bgColor
      bgImage
      instructionText
      title
      privacyLink
      subtitle
      privacyText
      conditionLink
      conditionText
      acceptNewsletter
      textColor
      fieldEmail
      fieldName
      fieldPhone
      fieldPrivacy
      fieldCondition
      btnText
      btnBg
      thankText
      btnTextColor
      winMessageTitle
      winMessageCode
      copyCodeText
      winCloseBtnText
      isSavedByOwner
      isSavedAsGlobal
      spinItemMainColor
      winPictures
      loseMessageTitle
      losePictures
      loseMessage
    }
  }
`

export const UPDATE_TEMPLATE_SPINNER = gql`
  mutation UpdateTemplateSpinner($templateId: Float!, $data: TemplateInput!) {
    updateTemplateSpinner(templateId: $templateId, data: $data) {
      id
      name
      description
      bgColor
      bgImage
      instructionText
      title
      privacyLink
      subtitle
      privacyText
      conditionLink
      conditionText
      acceptNewsletter
      textColor
      fieldEmail
      fieldName
      fieldPhone
      btnText
      btnBg
      thankText
      btnTextColor
      winMessageTitle
      winMessageCode
      copyCodeText
      winCloseBtnText
      isSavedByOwner
      isSavedAsGlobal
      spinItemMainColor
    }
  }
`

export const DELETE_TEMPLATE_SPINNER = gql`
  mutation DeleteTemplateSpinner($templateId: Float!) {
    deleteTemplateSpinner(templateId: $templateId) {
      id
      name
      description
      bgColor
      bgImage
      instructionText
      title
      privacyLink
      subtitle
      privacyText
      conditionLink
      conditionText
      acceptNewsletter
      textColor
      fieldEmail
      fieldName
      fieldPhone
      btnText
      btnBg
      thankText
      btnTextColor
      winMessageTitle
      winMessageCode
      copyCodeText
      winCloseBtnText
      isSavedByOwner
      isSavedAsGlobal
      spinItemMainColor
    }
  }
`

export const ADD_CLONE_TEMPLATE_SPINNER = gql`
  mutation CloneTemplate(
    $templateId: Float!
    $businessId: Float!
    $templatesInfo: TemplateInput
  ) {
    cloneTemplateSpinner(
      templateId: $templateId
      businessId: $businessId
      templatesInfo: $templatesInfo
    ) {
      id
      name
      description
      bgColor
      bgImage
      instructionText
      title
      privacyLink
      subtitle
      privacyText
      conditionLink
      conditionText
      acceptNewsletter
      textColor
      fieldEmail
      fieldName
      fieldPhone
      btnText
      btnBg
      thankText
      btnTextColor
      winMessageTitle
      winMessageCode
      copyCodeText
      winCloseBtnText
      isSavedByOwner
      isSavedAsGlobal
      spinItemMainColor
    }
  }
`

export const CLOSE_DONE_COMMAND = gql`
  mutation Mutation($commandId: Float!) {
    closeDoneCommand(commandId: $commandId) {
      id
      name
      description
      code
      clientId
      businessId
      status
    }
  }
`

export const VALIDATE_COMMAND = gql`
  mutation ValidateCommand($commandId: Float!) {
    ValidateCommand(commandId: $commandId) {
      id
      name
      description
      code
      clientId
      businessId
      status
    }
  }
`

export const REJECT_COMMAND = gql`
  mutation RejectNewCommand($commandId: Float!) {
    rejectNewCommand(commandId: $commandId) {
      id
      name
      description
      code
      clientId
      businessId
      status
    }
  }
`

export const ARCHIVE_COMMAND = gql`
  mutation UpdateCommand($archive: Boolean!, $commandId: Float!) {
    updateCommand(archive: $archive, commandId: $commandId) {
      id
      name
      status
      address
      archive
      code
      createdAt
      description
      email
      clientId
      phone
    }
  }
`

export const CAMPAIGN_AUTO = gql`
  mutation CampaignAutoResolver($businessId: Float!, $data: CampaignAutoInpute!) {
    CampaignAutoResolver(businessId: $businessId, data: $data)
  }
`

export const ADD_CONTACT_MULTI = gql`
  mutation AddMultipleContact($data: AddMultipleType!) {
    AddMultipleContact(data: $data)
  }
`

export const CREATE_CLIENT_SECRET = gql`
  mutation CreateClintSecret($aboId: Float!, $businessId: Float!, $amount: String!) {
    createClintSecret(aboId: $aboId, businessId: $businessId, amount: $amount)
  }
`

export const STATUS_BOOKING = gql`
  mutation UpdataBookingStatus($businessId: Float!, $state: Boolean!) {
    UpdataBookingStatus(businessId: $businessId, state: $state)
  }
`

export const DISABLE_BUSINESS = gql`
  mutation DisableBusiness($businessId: Float!) {
    disableBusiness(businessId: $businessId)
  }
`
