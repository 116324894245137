import { ThemeInput } from "@/types"
import { FC } from "react"
import { Box, Typography, FormControl, Select, MenuItem } from "@mui/material"
import { font } from "@/utils"
import { size } from "@/utils/fontSize"

interface CustomTypographyProps {
  theme: ThemeInput
  onChange: (e: any) => void
}

export const CustomTypography: FC<CustomTypographyProps> = ({
  theme,
  onChange
}) => {
  return (
    <Box>
      <Box>
        <Typography variant="subtitle2">Police des caractères :</Typography>
        <FormControl fullWidth>
          <Select
            name="police"
            value={theme?.police || "Roboto"}
            onChange={(e) => {
              onChange(e)
            }}
          >
            {font.map((fonte) => {
              return (
                <MenuItem
                  sx={{ fontFamily: fonte.value }}
                  key={fonte.id}
                  value={fonte.value}
                >
                  {fonte.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Typography variant="subtitle2">Taille des caractères :</Typography>
        <FormControl fullWidth>
          <Select
            name="fontSize"
            value={theme?.fontSize || "14px"}
            onChange={(e) => {
              onChange(e)
            }}
          >
            {size.map((taille, index) => {
              return (
                <MenuItem sx={{ fontSize: taille }} key={index} value={taille}>
                  {taille}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Typography variant="subtitle2" >Couleur de texte :</Typography>
        <input
            name="colorText"
            type="color"
            value={theme?.colorText || "transparent"}
            onChange={ (e)=> {
              onChange(e)
            } }
          />
      </Box>
    </Box>
  )
}
