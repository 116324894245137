/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonProps, styled, Box } from "@mui/material"
import { FC, ReactNode } from "react"

const StyledButton = styled(Button)(() => ({
  padding: 0,
  minHeight: 25,
  height: 25,
  width: 25,
  minWidth: 25,
  "& span": {
    margin: 0
  }
}))

const StyledIcon = styled(Box)(() => ({
  padding: 0,
  disaply: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0px !important",
  width: 15,
  height: 15,
  "& svg": {
    width: 15,
    height: 15
  }
}))
interface ButtonIconProps {
  icon?: ReactNode
}
export const ButtonLittleIcon: FC<ButtonProps & ButtonIconProps> = ({
  icon,
  children,
  ...props
}) => {
  return (
    <StyledButton
      variant="contained"
      {...props}
      startIcon={<StyledIcon>{icon}</StyledIcon>}
    >
      {children}
    </StyledButton>
  )
}
