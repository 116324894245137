/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography, Grid, Button, TextField } from "@mui/material"
import { useParams } from "react-router-dom"
import { useCurrentCategoryById } from "@/hooks/useCurrentCategoryById"
import { useUpdateCategory } from "@/hooks/category"
import { Dropzone } from "@/components/dropzone"
import { useEffect, useState } from "react"
import { CategoryInput } from "@/types"
import { ConfirmModal } from "@/components/modals"
import { SwitchStyle } from "@/components/common"
import { useApplicationContext } from "@/hooks"
import Swal from "sweetalert2"

export const EditCategory = () => {
  const { idEdit, id } = useParams()
  const { business } = useApplicationContext()
  const { categoryUpdate, categoryStatusUpdate } = useUpdateCategory()
  const { data, refetch } = useCurrentCategoryById(parseFloat(idEdit as string))
  const [categoryState, setCategoryState] = useState<CategoryInput>({
    name: "",
    image: "",
    status: true,
    businessId: business?.id || parseInt(id || "0")
  })
  const [open, setOpen] = useState(false)

  const handleUpload = async (file?: string): Promise<void> => {
    try {
      if (file) {
        await categoryUpdate(parseFloat(idEdit as string), {
          image: file
        })
      }
      refetch()
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const updateName = (e: any) => {
    setCategoryState((prev) => ({
      ...prev,
      name: e.target.value
    }))
  }

  const updateFile = async (file?: string): Promise<void> => {
    setCategoryState((prev) => ({
      ...prev,
      image: file || ""
    }))
  }

  const handleUpdate = async (): Promise<void> => {
    try {
      if (!idEdit) {
        return
      }
      await categoryUpdate(parseFloat(idEdit as string), {
        name: categoryState.name
      }).then(() => {
        Swal.fire({
          icon: "success",
          title: "Votre modification enregistré",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
      })
      refetch()
    } catch (errors) {
      throw new Error("ERROR")
    }
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleOpenModal = (): void => {
    setOpen(true)
  }

  const handleStatus = async (): Promise<void> => {
    try {
      if (!idEdit) {
        return
      }
      await categoryStatusUpdate(
        parseFloat(idEdit as string),
        !categoryState.status
      )
      refetch()
      setCategoryState((prev) => ({
        ...prev,
        status: !categoryState.status
      }))
    } catch (errors) {
      throw new Error("ERROR")
    }
  }

  const saveAll = () => {
    handleUpdate()
    handleUpload(categoryState.image || "")
  }

  useEffect(() => {
    setCategoryState((prev) => ({
      ...prev,
      name: data?.name || "",
      image: data?.image || "",
      status: data?.status === null ? true : data?.status,
      businessId: business?.id || parseInt(id || "0")
    }))
  }, [data, id, business?.id])

  return (
    <>
      <Box
        sx={{
          p: 2,
          m: 3,
          mb: 1,
          border: "0.3px solid grey",
          borderRadius: "10px"
        }}
      >
        <Grid container justifyContent="center">
          <Grid item md={4}>
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}
            >
              Modification catégorie
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <SwitchStyle
                change={handleOpenModal}
                status={categoryState?.status}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
                  Catégorie
                </Typography>
                <Box>
                  <TextField
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={categoryState.name || ""}
                    onChange={updateName}
                  />
                </Box>
                <Typography sx={{ fontWeight: "bold", mt: 1, mb: 2 }}>
                  Image catégorie
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: 250,
                    mr: 1,
                    mb: 1,
                    position: "relative"
                  }}
                >
                  <Dropzone
                    onFinished={updateFile}
                    message="Ajouter PDF ou png ou JPEG"
                    photo={categoryState.image || ""}
                    btnSx={{ height: "100%" }}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Button variant="contained" onClick={saveAll}>
                Enregister
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        onCancel={handleCancel}
        onConfirm={handleStatus}
        confirmLabel="Modifier"
        isDeleting
        title="Changement de status"
      >
        <Typography>
          Cette action va changer tout les statuts des sous-catégories et des
          produits de la même catégorie. Etes-vous sûr de vouloir changer ?
        </Typography>
      </ConfirmModal>
    </>
  )
}
export default EditCategory
