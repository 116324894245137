/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import Edit from "@mui/icons-material/Edit"
import Delete from "@mui/icons-material/Delete"
import { Lots_lots as ILot } from "../../../../graphql/lots"

interface LotCardProps {
  lot: ILot
  onClickEdit?: (lot: ILot) => void
  onClickDelete?: (lot: ILot) => void
}

export const LotCard: React.FC<LotCardProps> = ({
  lot,
  onClickEdit,
  onClickDelete
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Card
      sx={{
        maxWidth: 345,
        width: "100%",
        marginRight: 2,
        mb: 2,
        borderColor: lot.color,
        borderStyle: "solid",
        borderWidth: lot.color ? 3 : 0,
        borderRadius: 3
      }}
    >
      <CardHeader
        action={
          <div>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
            >
              <MenuItem
                onClick={() => {
                  if (onClickEdit) {
                    onClickEdit(lot)
                  }
                  setAnchorEl(null)
                }}
              >
                <Edit sx={{ mr: 2 }} />
                Modifier
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (onClickDelete) {
                    onClickDelete(lot)
                  }
                  setAnchorEl(null)
                }}
              >
                <Delete sx={{ mr: 2 }} />
                Supprimer
              </MenuItem>
            </Menu>
          </div>
        }
        title={lot.title}
        subheader={`Fréquence de gain : 1/${lot.winFrequence} scan`}
      />
      <CardMedia component="img" height="194" image={lot.image || ""} alt="" />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {lot.description}
        </Typography>
      </CardContent>
    </Card>
  )
}
