export const days: {
  name: string
  id: string
  index: number
}[] = [
  {
    name: "Lundi",
    id: "Lundi",
    index: 0
  },
  {
    name: "Mardi",
    id: "Mardi",
    index: 1
  },
  {
    name: "Mercredi",
    id: "Mercredi",
    index: 2
  },
  {
    name: "Jeudi",
    id: "Jeudi",
    index: 3
  },
  {
    name: "Vendredi",
    id: "Vendredi",
    index: 4
  },
  {
    name: "Samedi",
    id: "Samedi",
    index: 5
  },
  {
    name: "Dimanche",
    id: "Dimanche",
    index: 6
  }
]

export const daysPeriod: {
  name: string
  id: string
  index: number
}[] = [
  {
    name: "Matin",
    id: "Matin",
    index: 0
  },
  {
    name: "Après-midi",
    id: "Après-midi",
    index: 1
  },
  {
    name: "Soir",
    id: "Soir",
    index: 2
  },
  {
    name: "Journée",
    id: "Journée",
    index: 3
  }
]
