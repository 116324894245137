import { useQuery } from "@apollo/client"

import {
  BusinessGetProductById,
  BusinessGetProductByIdVariable,
  BUSINESS_PRODUCT_ID
} from "../graphql/business"

export const useCurrentGetProductById = (id?: number) => {
  const { data, loading, error, refetch } = useQuery<
    BusinessGetProductById,
    BusinessGetProductByIdVariable
  >(BUSINESS_PRODUCT_ID, {
    variables: {
      productId: id as number
    }
  })
  return {
    data: data?.getproductById,
    loading,
    error,
    refetch
  }
}
