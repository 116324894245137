import { Avatar, Box, Typography, useTheme, Grid, Card, CardContent, Chip, Select, MenuItem, CircularProgress } from "@mui/material"
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner"
import CropFreeIcon from "@mui/icons-material/CropFree"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import { South, North } from "@mui/icons-material"
import VideoSettingsIcon from "@mui/icons-material/VideoSettings"
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle"
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import CasinoIcon from "@mui/icons-material/Casino"
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits"
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
import { CartesianGrid, ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Legend, Line } from "recharts"
import { useApplicationContext } from "../../../hooks"
import { useBusinessInfoNumber } from "../../../hooks/infosNumber/useInfoNumber"
import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_COUNT } from "@/graphql/business"
import { GetCountPeriod, GetCountPeriodVariables } from "@/graphql/business/types/GetCountPeriod"

export const Home = () => {
  const theme = useTheme()
  const [periode, setPeriode] = useState("WEEK")
  const { business } = useApplicationContext()
  const [active, setActive] = useState(1)
  const [dataChart, setDataChart] = useState<any[]>([])
  const { data } = useBusinessInfoNumber(business?.id)
  const { data: dataCount, loading, refetch } = useQuery<GetCountPeriod, GetCountPeriodVariables>(GET_COUNT, {
    variables: {
      businessId: business?.id || 0,
      periode: periode
    }
  })

  useEffect(() => {
  switch (active) {
    case 1: 
      setDataChart(dataCount?.GetCountPeriod.visitorChart || [])
    break
    case 2: 
      setDataChart(dataCount?.GetCountPeriod.customerChart || [])
    break
    case 3: 
      setDataChart(dataCount?.GetCountPeriod.winnerChart || [])
    break
    case 4: 
      setDataChart(dataCount?.GetCountPeriod.commandChart || [])
    break
    case 5: 
      setDataChart(dataCount?.GetCountPeriod.bookingChart || [])
    break
  }
    }, [active])

  useEffect(() => {
    refetch()
  }, [periode])

  return (
    <Box p={2}>
      <Box>
        <Box>
          <Typography variant="h3" sx={{ mb: 3, fontWeight: "bold" }}>Resumé des activités</Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={0}>
                <Box sx={{
                  [theme.breakpoints.up("md")]: {
                    display: "flex",
                    justifyContent: "space-between"
                  }
                }}>
                  <Box sx={{
                    m: 3,
                    [theme.breakpoints.up("md")]: {
                      display: "none"
                    }
                  }}>
                    <Select
                      value={periode}
                      onChange={(e: any) => setPeriode(e.target.value)}
                    >
                      <MenuItem value="WEEK"> semaine</MenuItem>
                      <MenuItem value="MONTH">1 mois</MenuItem>
                      <MenuItem value="THREE">3 mois</MenuItem>
                      <MenuItem value="SIX">6 mois</MenuItem>
                    </Select>
                  </Box>
                  <Box sx={{
                    mb: 2,
                    [theme.breakpoints.up("md")]: {
                      display: "flex"
                    }
                  }}>
                    <Box sx={{
                      p: 3,
                      borderTop: active === 1 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                      background: active === 1 ? "#f5f5f5" : "transparent"
                    }}
                      onClick={() => setActive(1)}
                    >
                      <Typography variant="body1" sx={{ mb: 1 }}>Visiteurs</Typography>
                      <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.visitorCount}</Typography>
                      <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.visitorPourcentage + "%"} />
                    </Box>
                    <Box sx={{
                      p: 3,
                      borderTop: active === 2 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                      background: active === 2 ? "#f5f5f5" : "transparent"
                    }}
                      onClick={() => setActive(2)}
                    >
                      <Typography variant="body1" sx={{ mb: 1 }}>Nouveaux inscripts</Typography>
                      <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.customerCount}</Typography>
                      <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.customerPourcentage + "%"} />
                    </Box>
                    <Box sx={{
                      p: 3,
                      borderTop: active === 3 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                      background: active === 3 ? "#f5f5f5" : "transparent"
                    }}
                      onClick={() => setActive(3)}
                    >
                      <Typography variant="body1" sx={{ mb: 1 }}>Gagnants</Typography>
                      <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.winnerCount}</Typography>
                      <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.winnerPourcentage + "%"} />
                    </Box>
                    <Box sx={{
                      p: 3,
                      borderTop: active === 4 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                      background: active === 4 ? "#f5f5f5" : "transparent"
                    }}
                      onClick={() => setActive(4)}
                    >
                      <Typography variant="body1" sx={{ mb: 1 }}>Commandes</Typography>
                      <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.commandCount}</Typography>
                      <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.commandPourcentage + "%"} />
                    </Box>
                    <Box sx={{
                      p: 3,
                      borderTop: active === 5 ? "5px solid #FECA00" : "5px solid #FFFFFF",
                      background: active === 5 ? "#f5f5f5" : "transparent"
                    }}
                      onClick={() => setActive(5)}
                    >
                      <Typography variant="body1" sx={{ mb: 1 }}>Reservations</Typography>
                      <Typography variant="h1" component="div" sx={{ mb: 1, fontWeight: "bold" }}>{dataCount?.GetCountPeriod.bookingCount}</Typography>
                      <Chip size="small" color="success" icon={<North sx={{ fontSize: 2 }} />} label={dataCount?.GetCountPeriod.bookingPourcentage + "%"} />
                    </Box>
                  </Box>
                  <Box sx={{
                    m: 3,
                    [theme.breakpoints.down("md")]: {
                      display: "none"
                    }
                  }}>
                    <Select
                      value={periode}
                      onChange={(e: any) => setPeriode(e.target.value)}
                    >
                      <MenuItem value="WEEK"> semaine</MenuItem>
                      <MenuItem value="MONTH">1 mois</MenuItem>
                      <MenuItem value="THREE">3 mois</MenuItem>
                      <MenuItem value="SIX">6 mois</MenuItem>
                    </Select>
                  </Box>
                </Box>
                <CardContent sx={{ width: "100%", minHeight: 400 }}>
                  {
                    loading ? <CircularProgress /> :
                      <ResponsiveContainer height={400} width="100%">
                        <LineChart
                          data={dataChart}
                          width={500}
                          height={300}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line name="Semaine en cours" type="monotone" dataKey="count" stroke="#feca00" activeDot={{ r: 8 }} />
                        </LineChart>
                      </ResponsiveContainer>
                  }
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ mb: 2, fontWeight: "bold" }}>Vos activités en chiffre</Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}
        >
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <GroupAddIcon />
              </Avatar>
              <Typography variant="h2">{data?.users}</Typography>
              <Typography>Nombre d'Utilisateurs</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <SupervisedUserCircleIcon />
              </Avatar>
              <Typography variant="h2">{data?.clients}</Typography>
              <Typography>Nombre de clients incrits</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <QrCodeScannerIcon />
              </Avatar>
              <Typography variant="h2">{data?.qrcode}</Typography>
              <Typography>Nombre de QR Code</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <CropFreeIcon />
              </Avatar>
              <Typography variant="h2">{data?.qrcodeActvie}</Typography>
              <Typography>Nombre de QR Code Actif</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <EmojiEventsIcon />
              </Avatar>
              <Typography variant="h2">{data?.winners}</Typography>
              <Typography>Nombre de Gagnants aux Jeux</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <CasinoIcon />
              </Avatar>
              <Typography variant="h2">{data?.lots}</Typography>
              <Typography>Nombre de lots</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <AddPhotoAlternateIcon />
              </Avatar>
              <Typography variant="h2">{data?.images}</Typography>
              <Typography>Nombre d'images</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <VideoSettingsIcon />
              </Avatar>
              <Typography variant="h2">{data?.videos}</Typography>
              <Typography>Nombre de Vidéos</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ p: 2 }} elevation={3}>
              <Avatar
                sx={{ margin: "auto", bgcolor: theme.palette.primary.main }}
              >
                <ProductionQuantityLimitsIcon />
              </Avatar>
              <Typography variant="h2">{data?.catalogues}</Typography>
              <Typography>Nombre de catalogues produits</Typography>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box >
  )
}
