import { useMutation } from "@apollo/client"
import {
  Alert,
  Box,
  Button,
  Grid,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react"
import { useApplicationContext } from "@/hooks"
import { CREATE_PACK, UPDATE_PACK } from "@/graphql/emailing"
import { CreatePackVariables, CreatePack } from "@/graphql/emailing/types/CreatePack"

type ManageProps = {
  setOpen: (item: boolean) => void
  editiedItem?: any
}

type StateType = {
  name: string
  short: string
  sms: number
  taxe: number
  prixTtc: number
  productStripeId: string
  prixHt: number
}

const initState = {
  name: "",
  short: "",
  prixHt: 0,
  sms: 0,
  taxe: 0,
  prixTtc: 0,
  productStripeId: ""
}

export const PackModal = ({ setOpen, editiedItem }: ManageProps) => {
  const { dispatchSnack } = useApplicationContext()
  const [isLong, setIsLong] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [pack, setPack] = useState<StateType>(initState)
  const [totalPrice, setTotalPrice] = useState(0)
  const [createPack] = useMutation<CreatePack, CreatePackVariables>(CREATE_PACK)
  const [updatePack] = useMutation(UPDATE_PACK)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target
    if (name === "short" && value.length > 150) {
      setIsLong(true)
    } else {
      setIsLong(false)
    }

    if (type === "number") {
      setPack((prev) => ({
        ...prev,
        [name]: parseFloat(value)
      }))
      return
    }

    setPack((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  useEffect(() => {
    const total =
      (Number(pack.prixHt) * Number(pack.taxe)) / 100 +
      Number(pack.prixHt)
    setTotalPrice(Number(total.toFixed(2)))
  }, [pack.prixHt, pack.taxe])

  useEffect(() => {
    if (editiedItem) {
      setDisabled(true)
      setPack({
        name: editiedItem.name || "",
        prixHt: editiedItem.price || "",
        sms: editiedItem.sms || 0,
        prixTtc: editiedItem.prixTtc || "",
        short: editiedItem.description || "",
        taxe: editiedItem.taxe || "",
        productStripeId: editiedItem.productStripeId || ""
      })
    }
  }, [editiedItem])

  const updatedPack = () => {
    const nbrClick = pack.sms
    const nomAbon = pack.name

    if (nbrClick === 0 ||
      nomAbon === ""
    ) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez compléter les champs obligatoires"
      })
      return
    }
    updatePack({
      variables: {
        updatePackId: editiedItem.id,
        data: {
          name: pack.name,
          price: pack.prixHt || 0,
          priceTtc: pack.prixTtc || 0,
          description: pack.short,
          taxe: pack.taxe || 0,
          sms: pack.sms || 0
        }
      }
    })
      .then(() => {
        setOpen(false);
        dispatchSnack({
          open: true,
          severity: "success",
          message: "Modifié avec succès"
        })
      })
      .catch(error => {
        console.error(error);
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Une erreur s'est produite lors de l'edition du pack."
        });
      });
  }

  const handleCreatepack = () => {
    const {
      name,
      short,
      sms,
      prixHt,
      taxe
    } = pack;

    // Vérification des champs obligatoires
    if (
      !name ||
      !short ||
      !taxe ||
      !sms ||
      !prixHt
    ) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez compléter tous les champs obligatoires."
      });
      return;
    }

    createPack({
      variables: {
        data: {
          name: pack.name,
          price: pack.prixHt || 0,
          priceTtc: pack.prixTtc || 0,
          description: pack.short,
          taxe: pack.taxe || 0,
          sms: pack.sms || 0
        }
      }
    })
      .then(() => {
        setOpen(false);
      })
      .catch(error => {
        console.error(error);
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Une erreur s'est produite lors de la création du pack."
        });
      });
  }

  return (
    <Box p={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          my: 2
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          Configuration du pack
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Nom du pack
            </Typography>
            <Box>
              <TextField
                name="name"
                variant="outlined"
                fullWidth
                required
                value={pack?.name}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Nombre de SMS
            </Typography>
            <Box>
              <TextField
                name="sms"
                variant="outlined"
                fullWidth
                required
                type="number"
                value={
                  pack?.sms || pack?.sms === 0
                    ? pack?.sms
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Prix pack (HT)
            </Typography>
            <Box>
              <TextField
                name="prixHt"
                variant="outlined"
                fullWidth
                required
                type="number"
                disabled={disabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  )
                }}
                value={
                  pack?.prixHt || pack?.prixHt === 0
                    ? pack?.prixHt
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Taxe (TVA)
            </Typography>
            <Box>
              <TextField
                name="taxe"
                variant="outlined"
                fullWidth
                type="number"
                disabled={disabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
                value={
                  pack?.taxe || pack?.taxe === 0
                    ? pack?.taxe
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%"
            }}
          >
            <Typography variant="h2" component="div">
              Total TTC :
            </Typography>
            <Typography variant="h2" component="div" marginLeft={4}>
              {totalPrice} €
            </Typography>
          </Box>
        </Grid>
        {disabled ? (
          <Grid item xs={12}>
            <Alert severity="warning">
              Suivant la politique de STRIPE, il est impossible de modifié les
              prix d'un pack!
            </Alert>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <Box>
        <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
          Description courte
        </Typography>
        <Box>
          <Box>
            <TextField
              error={isLong}
              name="short"
              value={pack?.short}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              required
              helperText={
                isLong ? "le nombre de charactère maximal est de 150" : null
              }
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 4
        }}
      >
        {editiedItem ? (
          <Button
            variant="contained"
            onClick={updatedPack}
          >
            Enregistrer
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleCreatepack}
          >
            Enregistrer
          </Button>
        )}
      </Box>
    </Box>
  )

}
