import { Route, Routes } from "react-router-dom"
import { BookingList } from "./BookingList"
import { CommandBoard } from "./CommandBoard"
import { ConfigurationReservation } from "./ConfigurationReservation"
import { EventList } from "./EventList"

export const BookingRoutes = () => {
  return (
    <Routes>
      <Route index element={<CommandBoard />} />
      <Route path="booking-board/*" element={<BookingList />} />
      <Route path="list_event/*" element={ <EventList />} />
      <Route path="booking-param/*" element={ <ConfigurationReservation/>} />
    </Routes>
  )
}
