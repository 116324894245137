import { useParams } from "react-router-dom"
import { Box } from "@mui/material"
import { HorairesInput } from "./HorairesInput"
import { OpeningHourUpdateInputType } from "@/types"
import { useApplicationContext, useCurrentBusiness } from "@/hooks"
import { days } from "@/utils"
import { useCallback, useContext, useEffect } from "react"
import { ApplicationContext } from "@/contexts"

export const Horaires = (): JSX.Element => {
  const contexts = useContext(ApplicationContext)
  const { editHours, setEditHours, setInputHour } = contexts
  const { id } = useParams()
  const { business } = useApplicationContext()
  const { data } = useCurrentBusiness(business?.id || parseFloat(id as string))

  const getEditHour = (val: OpeningHourUpdateInputType) => {
    setInputHour((item) => [...item, val])
  }

  const loadHour = useCallback(() => {
    if (data?.businessHours) {
      const reversed = [...data?.businessHours]?.reverse()
      setEditHours(
        days.map((day) => {
          const hours = reversed?.find((hour: any) => hour.day === day.name)
          return {
            id: hours?.id,
            day: day.name,
            hours: hours,
            open: hours?.start || hours?.end ? true : false
          }
        })
      )
    }
  }, [data?.businessHours, setEditHours])

  useEffect(() => {
    loadHour()
  }, [loadHour])

  return (
    <Box>
      {editHours
        ? editHours.map((day) => {
            return (
              <HorairesInput key={day.day} day={day} onEdit={getEditHour} />
            )
          })
        : ""}
    </Box>
  )
}
