import { useMutation, useQuery } from "@apollo/client"
import { Box, Typography, Pagination, Stack } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import { configPagination } from "@/components/tables/config"
import { BaseTable, ConfirmModal, ResearchBox } from "../../components"
import {
  BusinessList,
  BusinessListVariables,
  BusinessList_businessList as IBusiness,
  BUSINESS_LIST
} from "../../graphql"
import {
  DeleteBusiness,
  DeleteBusinessVariables,
  DELETE_BUSINESS
} from "../../graphql/business"
import { useEtablissementColumns } from "./components/useEtablissementColumns"
import { AddNewBusiness } from "./AddNewBusiness"
import { useApplicationContext } from "../../hooks"
import useSimpleDebounce from "@/hooks/useSimpleDebounce"

export const Etablissement = (): JSX.Element => {
  const perPage = configPagination.rowPerPage
  const navigate = useNavigate()
  const { dispatchSnack, switchToBusiness } = useApplicationContext()
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<number>()
  const [searchValue, setSearchValue] = useState<string>()
  const [page, setPage] = useState<number>(1)
  const [open, setOpen] = useState<boolean>(false)

  const { data, loading, refetch } = useQuery<
    BusinessList,
    BusinessListVariables
  >(BUSINESS_LIST, {
    variables: {
      take: 100,
      skip: 0
    }
  })

  const [deleteBusiness, { loading: deleteLoading }] = useMutation<
    DeleteBusiness,
    DeleteBusinessVariables
  >(DELETE_BUSINESS)
  const handleView = async (b: IBusiness) => {
    // navigate(`${b.id}`)
    switchToBusiness(b.id)
  }
  const handleEmail = (b: IBusiness) => {
    navigate(`${b.id}/email`)
  }
  const handleConfirmDelete = async () => {
    if (!deleteId) {
      setOpenDelete(false)
      return
    }
    const deleteBusinesse = await deleteBusiness({
      variables: {
        businessId: deleteId
      }
    })
    if (deleteBusinesse) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Suppression avec succes"
      })
    }
    refetch()
  }

  const handleDelete = (b: IBusiness) => {
    setDeleteId(b.id)
    setOpenDelete(true)
  }
  const { columns } = useEtablissementColumns({
    onView: handleView,
    onRemove: handleDelete,
    onEmail: handleEmail
  })

  const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }
  const count = configPagination.count(data?.businessList, perPage)

  const handleSearch = (value: string) => {
    setSearchValue(value.toLowerCase())
  }

  const handleClearSearch = () => {
    setSearchValue("")
  }

  const searchDebounced = useSimpleDebounce(searchValue, 500)
  const filterBusiness = (b: IBusiness) => {
    if (searchDebounced) {
      return b.businessName.toLowerCase().includes(searchDebounced)
    }
    return true
  }

  /*****responsive styles */
  const mobileWrap = {
    '@media(max-width:767px)' : {
      flexWrap : "wrap",
      justifyContent : 'center',
    }
  }
  const xsMb2 = {
    '@media(max-width:767px)' : {
      marginBottom : "16px",
    }
  }


  /****end styles */
  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1">Gérer les établissements</Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <BaseTable
          loading={loading}
          columns={columns}
          data={data?.businessList.filter(filterBusiness) || []}
          page={page - 1}
          perPage={perPage}
          topComponent={
            <Box sx={{ p: 2, display: "flex", justifyContent: "end" , ...mobileWrap}}>
              <Box sx={{ mr: 2 , ...xsMb2 }}>
                <ResearchBox
                  placeholder="Rechercher rapide..."
                  onChange={handleSearch}
                  clear={handleClearSearch}
                />
              </Box>
              <Button
                color="primary"
                variant="outlined"
                sx={{ textTransform: "none" , color: "#050005" }}
               onClick={() => setOpen(true)}
              >
                <AddIcon /> Ajouter un établissement
              </Button>
            </Box>
          }
        />
      </Box>
      <Box sx={{ p: 2, display: "flex", justifyContent: "end" }}>
        <Stack>
          {!loading &&
          data !== undefined &&
          data.businessList.length > perPage ? (
            <Pagination
              color="primary"
              count={count}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          ) : null}
        </Stack>
      </Box>
      <AddNewBusiness
        open={open}
        setOpen={setOpen}
        onFinish={() => refetch()}
      />
      <ConfirmModal
        open={openDelete}
        loading={deleteLoading}
        setOpen={setOpenDelete}
        onCancel={() => setOpenDelete(false)}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de commerce"
      >
        <Typography>
          Vous êtes sur le point de supprimer un commerce. Cette action est
          irréversible, êtes-vous sûr de vouloir supprimer?
        </Typography>
      </ConfirmModal>
    </Box>
  )
}
