import DashboardIcon from "@mui/icons-material/Dashboard"
import HomeIcon from "@mui/icons-material/Home"
import ContactsIcon from "@mui/icons-material/Contacts"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import CasinoIcon from "@mui/icons-material/Casino"
import NotificationActive from "@mui/icons-material/NotificationsActive"
import { TemplateRoute } from "@/components/TemplateGames/TemplateRoute"
import { IWebRoutes, IFeatured, AdminType } from "@/types"
import { Dashboard } from "./dashboard"
import { EtablissementRoutes } from "../etablissement"
import { ContactRoute } from "./Contact"
import { Abonnement } from "./Abonnement"
import { MyAcoount } from "./myaccount"
import { CurrencyExchange, Tag } from "@mui/icons-material"
import { User } from "@/components/icons/user"
import { NotificationRouter } from "./notification/NotificationRouter"
import { CampaignRoutes } from "../campaign/CampaignRoutes"
import FormatListBulleted from "@mui/icons-material/FormatListBulleted"
import { TagsComponent } from "@/components/Tags/TagsComponent"

export const admminRoutes: IWebRoutes[] = [
  {
    id: "DASHBOARD",
    index: true,
    element: <Dashboard />,
    role: [AdminType.ADMIN],
    label: "Tableau de bord",
    icon: <HomeIcon />,
    featured: IFeatured.SIDENAV
  },
  {
    id: "ETABLISSEMENT",
    path: "etablissements/*",
    element: <EtablissementRoutes />,
    role: [AdminType.ADMIN],
    label: "Gestion des établissements",
    icon: <DashboardIcon />,
    featured: IFeatured.SIDENAV
  },
  {
    id: "CONTACT",
    path: "contact/*",
    element: <ContactRoute />,
    role: [AdminType.ADMIN],
    label: "Gestion des contacts",
    icon: <ContactsIcon />,
    featured: IFeatured.SIDENAV
  },
  {
    id: "TEMPLATE",
    path: "template/*",
    element: <TemplateRoute />,
    role: [AdminType.ADMIN],
    label: "Gestion des templates",
    icon: <CasinoIcon />,
    featured: IFeatured.SIDENAV
  },
  {
    id: "ABONNEMENT",
    path: "abonnement/*",
    element: <Abonnement />,
    role: [AdminType.ADMIN],
    label: "Abonnement et facturation",
    icon: <CurrencyExchange />,
    featured: IFeatured.SIDENAV
  },
  {
    id: "EMAIL",
    path: "email/*",
    element: <CampaignRoutes />,
    role: [AdminType.ADMIN],
    label: "Marketing",
    icon: <MailOutlineIcon />,
    featured: IFeatured.SIDENAV,
    subMenus: [
      {
        id: "CAMPAGNE",
        label: "Campagne",
        icon: <MailOutlineIcon />,
        path: "email/*"
      },
      {
        id: "SENDER",
        label: "Expéditeur",
        icon: <User />,
        path: "email/sender"
      },
      {
        id: "CONTACT",
        label: "Liste contact",
        icon: <FormatListBulleted />,
        path: "email/contact"
      }
    ]
  },
  {
    id: "NOTIFICATION",
    path: "notification/*",
    element: <NotificationRouter />,
    role: [AdminType.ADMIN],
    label: "Notification et mail",
    icon: <NotificationActive />,
    featured: IFeatured.SIDENAV
  },
  {
    id: "ADMIN_ACCOUNT",
    path: "my_account/*",
    element: <MyAcoount />,
    role: [AdminType.ADMIN],
    label: "Mon compte",
    icon: <User />,
    featured: IFeatured.SIDENAV
  },
  {
    id: "TAGS",
    path: "tags/*",
    element: <TagsComponent />,
    role: [AdminType.ADMIN],
    label: "Gestion des tags",
    icon: <Tag />,
    featured: IFeatured.SIDENAV
  }
]
