import { ButtonLittleIcon, TableColumnProps } from "@/components"
import Delete from "@mui/icons-material/Delete"
import Edit from "@mui/icons-material/Edit"
import { Box } from "@mui/material"
import { ListEmailing_listEmailing as IListEmailing } from "../../../../graphql/emailing"
import { Translate } from "./translate"

interface TableColProps {
  onEdit?: (id: number) => void
  onRemove?: (id: number) => void
}

export const tableEmail = ({
  onEdit,
  onRemove
}: TableColProps): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "id",
      label: "N°",
      component: (data: IListEmailing) => <p>{data.id}</p>
    },
    {
      id: "type",
      label: "Service",
      component: (data: IListEmailing) => <p>{Translate[data.type]}</p>
    },
    {
      id: "templateId",
      label: "Id des templates",
      component: (data: IListEmailing) => <p>{data.templateId}</p>
    },
    {
      id: "action",
      label: "",
      component: (data) => (
        <Box>
          <ButtonLittleIcon
            sx={{ mr: 1, mb: 1 }}
            icon={<Edit />}
            onClick={() => {
              if (onEdit) {
                onEdit(data.id)
              }
            }}
          />
          <ButtonLittleIcon
            sx={{ mr: 1, mb: 1 }}
            icon={<Delete />}
            color="error"
            onClick={() => {
              if (onRemove) {
                onRemove(data.id)
              }
            }}
          />
        </Box>
      )
    }
  ]

  return { columns }
}
