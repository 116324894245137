import { useQuery } from "@apollo/client"

import {
  BusinessListAllProduct,
  BUSINESS_GET_ALL_PRODUCT
} from "../graphql/business"

export const useCurrentLisstProduct = () => {
  const { data, loading, error, refetch } = useQuery<BusinessListAllProduct>(
    BUSINESS_GET_ALL_PRODUCT
  )

  return {
    data: data?.getAllProduct,
    loading,
    error,
    refetch
  }
}
