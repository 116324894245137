import {
  Box, FormControlLabel, RadioGroup, Radio
} from "@mui/material"
import { BaseTable, QueryResult } from "@/components"
import { useQuery } from "@apollo/client"
import { GET_BUSINESS_CONTACT, GET_LIST, GET_LIST_TAG } from "@/graphql/emailing"
import { TableList } from "./TableList"
import { useApplicationContext } from "@/hooks"
import { useState } from "react"

interface DestCampagneType {
  type?: string
}

export const DestinationCampagne = ({ type }: DestCampagneType) => {
  const { setReceiptState, business } = useApplicationContext()
  const [value, setValue] = useState("")
  const { data, loading } = useQuery(GET_BUSINESS_CONTACT, {
    variables: {
      businessId: business?.id
    }
  })
  const { data: dataTag, loading: loadingTag } = useQuery(GET_LIST_TAG)
  const { columns } = TableList()
  const handleChecks = (ids: number[]) => {
    setReceiptState(ids)
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <RadioGroup
          row
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}
        >
          <FormControlLabel value="list" label="Depuis la liste des contacts" control={<Radio />} />
          <FormControlLabel value="tag" label="Depuis la liste des tags" control={<Radio />} />
        </RadioGroup>
      </Box>
      {
        value === "list" && (
          <QueryResult data={data} loading={loading}>
            <BaseTable
              data={data?.getListBusinessContact}
              page={1}
              columns={columns}
              checkbox
              onCheckedChange={handleChecks}
              indexed={type === "sms" ? "id" : "listSendinblueId"}
            />
          </QueryResult>
        )
      }
      {
        value === "tag" && (
          <QueryResult data={dataTag} loading={loadingTag}>
            <BaseTable
              data={dataTag?.getListContactTag}
              page={1}
              columns={columns}
              checkbox
              onCheckedChange={handleChecks}
              indexed={type === "sms" ? "id" : "listSendinblueId"}
            />
          </QueryResult>
        )
      }
    </Box>
  )
}
