/* eslint-disable no-nested-ternary */
import { Box, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useApplicationContext } from "../../../../hooks"
import { FormContainer } from "../components/FormContainer"

export const Confirm = (): JSX.Element => {
  const { signin, signinLoading } = useApplicationContext()
  const { email: emailLocal } = useParams()
  const [password, setPassword] = useState<string>("")
  const navigate = useNavigate()

  const handleCreate = async (): Promise<void> => {
    try {
      const email = JSON.parse(emailLocal as string)
      const login = await signin(email, password)
      if (login.success) {
        navigate(`/discount`)
      }
      return undefined
    } catch (errors) {
      return undefined
    }
  }
  return (
    <FormContainer
      step={8}
      loading={signinLoading}
      title="Confirmer votre inscription"
      onNext={handleCreate}
      disableBtn={!password || signinLoading}
      btnLabel="Confirm et allez au dashboard"
    >
      <Box sx={{ pb: 2 }}>
        <Typography>Entrer votre mot de passe :</Typography>
        <TextField
          fullWidth
          required
          variant="outlined"
          name="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Box>
    </FormContainer>
  )
}
