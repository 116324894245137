/* eslint-disable jsx-a11y/iframe-has-title */
import { Box, Button } from "@mui/material"
import { useState, useRef, useEffect } from "react"
import Editor from "react-email-editor"
import { useNavigate, useParams } from "react-router-dom"
import sample from "./components/example.json"
import welcome from "./components/welcome.json"
import inactive from "./components/inactive.json"
import undelivery from "./components/undelivery.json"
import { EmailType } from "./components/jsonType"
import Swal from "sweetalert2"
import { CustomModal } from "@/components"
import { useApplicationContext } from "@/hooks"

export const TemplateBuilder = () => {
  const { business, setTemplateState } = useApplicationContext()
  const { type } = useParams()
  const navigate = useNavigate()
  const [ready, setReady] = useState(false)
  const [open, setOpen] = useState(false)
  const [htmlTemplate, setHtmlTemplate] = useState("")
  const [design, setDesign] = useState<EmailType>()
  const [header] = useState(`<div>
  <img src="${business?.coverPhoto}"
  height="140" alt="${business?.businessName}-cover" style="border-radius: 0px 0px 40px 40px; width: 100%; object-fit: cover">
  <div>
    <div style="width: 100px; height: 100px; border-radius: 50px; overflow: hidden; position: relative; top: -55px; margin: auto">
      <div>
        <img src="${business?.logo}" alt="${business?.businessName}-logo" style="width: 100%; height: 100%; object-fit: cover;">
      </div>
    </div>
  </div>
</div>`)
  const onReady = () => {
    setReady(true)
    switch (type) {
      case "new-user":
        setDesign(welcome)
        break
      case "inactive-user":
        setDesign(inactive)
        break
      case "undelivery":
        setDesign(undelivery)
        break
      default:
        setDesign(sample)
    }
  }
  const emailEditorRef = useRef(null)
  const loadDesignDefault = (design: any) => {
    if (emailEditorRef.current && emailEditorRef.current["editor"]) {
      const current: any = emailEditorRef.current["editor"]
      current.loadDesign(design)
    }
  }
  /*const saveDesign = () => {
    if (emailEditorRef.current && emailEditorRef.current['editor']) {
      const current: any = emailEditorRef.current['editor']
      current.saveDesign((design: any) => {
        console.log("design => ", design)
      })
    }
  }*/
  const exportHtml = () => {
    if (emailEditorRef.current) {
      const current: any = emailEditorRef.current["editor"]
      current.exportHtml((data: any) => {
        const { html } = data
        localStorage.setItem("templateCampagne", html)
        setHtmlTemplate(html)
        setTemplateState((prev) => ({
          ...prev,
          template: html
        }))
        setOpen(true)
      })
    }
  }
  const handleSave = () => {
    Swal.fire({
      icon: "success",
      title: "Votre modification a été enregistré",
      text: "",
      showConfirmButton: false,
      showCloseButton: true
    })
    navigate(-1)
  }

  useEffect(() => {
    loadDesignDefault({
      counters: design?.counters,
      body: {
        rows: [
          {
            cells: [1],
            columns: [
              {
                contents: [
                  {
                    type: "html",
                    values: {
                      ...design?.body.rows[0].columns[0].contents[0].values,
                      html: header,
                      hideDesktop: false,
                      displayCondition: null,
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      _override: {
                        mobile: {
                          src: {
                            maxWidth: "20%",
                            autoWidth: false
                          }
                        }
                      }
                    }
                  },
                  ...(design?.body.rows[0].columns[0].contents || [])
                ]
              }
            ]
          }
        ],
        values: {
          ...design?.body.values,
          backgroundColor: business?.BusinessPalette?.bgColor
        }
      }
    })
  }, [business?.BusinessPalette?.bgColor, design, header])

  return (
    <Box sx={{ position: "relative" }}>
      {!ready && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            textAlign: "center",
            py: 5,
            fontWeight: "bold",
            fontSize: 25
          }}
        >
          Merci de patienter, nous chargeons l'éditeur pour vous
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
        <Button variant="contained" onClick={exportHtml}>
          Apercu avant sauvegarde
        </Button>
      </Box>
      <Box sx={{ height: "85vh" }}>
        <Editor
          minHeight="100%"
          options={{
            locale: "fr-FR"
          }}
          ref={emailEditorRef}
          onReady={onReady}
        />
      </Box>
      <CustomModal
        title="Apercue avant sauvegarde"
        open={open}
        setOpen={setOpen}
        onConfirm={handleSave}
        fullscreen
      >
        <iframe
          srcDoc={htmlTemplate}
          style={{ minHeight: 450, width: "100%", height: "100%" }}
        />
      </CustomModal>
    </Box>
  )
}
