/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@mui/material"
import { ChangeEvent, FC, useState } from "react"
import TextField from "@mui/material/TextField"
import { Dropzone } from "@/components/dropzone"
import { CustomModal } from "@/components"
import { CategoryInput, EnumFileType, FileBusinessEnum } from "@/types"
import { useCategory } from "@/hooks/category"
import { useApplicationContext, useCurrentBusiness } from "@/hooks"
import { ApolloQueryResult } from "@apollo/client"
import ModalSubCategory from "../../SousCategory/ModalSousCategory/ModalSousCategory"
import { useParams } from "react-router-dom"
import { useFileUpload } from "@/hooks/useFileUpload"

interface ModalCategoryProps {
  open: boolean
  loading?: boolean
  setOpen: (b: boolean) => void
  refresh: () => Promise<ApolloQueryResult<any>>
  businessId: number
}

export const ModalCategory: FC<ModalCategoryProps> = ({
  open,
  loading,
  businessId,
  setOpen,
  refresh
}): JSX.Element => {
  const [values, setValues] = useState<CategoryInput>({
    name: "",
    image: "",
    status: true,
    businessId: businessId
  })
  const { dispatchSnack } = useApplicationContext()
  const [checked, setChecked] = useState<string>("principale")
  const { createCategory } = useCategory()

  const { id } = useParams()
  const { saveFile } = useFileUpload()
  const { refetch } = useCurrentBusiness(
    businessId || parseFloat(id as string)
  )

  // eslint-disable-next-line consistent-return
  const isCheckedSelected = (value: string) => {
    if (checked === value) {
      return true
    }
  }

  const handleChangeControl = (e: any) => {
    setChecked(e.target.value)
  }

  const handleUpload = async (file?: string, name?: string): Promise<void> => {
    if (file) {
      setValues((prev) => ({
        ...prev,
        image: file
      }))
      await saveFile({
        variables: {
          businessId: businessId || parseFloat(id as string),
          files: {
            fileType: EnumFileType.PDF,
            type: FileBusinessEnum.CATALOGUE_PRODUCT,
            url: file,
            name
          }
        }
      })
      refetch()
    }
  }

  const onSubmit = async () => {
    if (!values.businessId) {
      return
    }
    else if (!values.name) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez completer les champs obligatoires"
      })
    }
    else {
      const create = await createCategory(values)
      if (create.success) {
        dispatchSnack({
          open: true,
          severity: "success",
          message: "Catégorie Ajouté avec succès"
        })
        setOpen(false)
        refresh()
        setValues({
          name: "",
          image: "",
          businessId
        })
      } else {
        setOpen(false)
      }
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  /**** responsive styles */
  const smJustifyStart = {
    "@media(max-width:767px)": {
      justifyContent: "flex-start"
    }
  }

  /*******end style */
  return (
    <CustomModal
      noAction
      title={
        checked === "principale"
          ? "Ajouter nouvelle catégorie"
          : "Ajouter nouveau sous-catégorie"
      }
      open={open}
      setOpen={setOpen as any}
    >
      <Grid item xs={12}>
        <FormControl sx={{ display: "flex", justifyContent: "center" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ mb: 3, justifyContent: "center", ...smJustifyStart }}
          >
            <FormControlLabel
              value="principale"
              control={<Radio />}
              checked={isCheckedSelected("principale")}
              onChange={handleChangeControl}
              label="Principale"
            />
            <FormControlLabel
              value="sousCategory"
              control={<Radio />}
              checked={isCheckedSelected("sousCategory")}
              onChange={handleChangeControl}
              label="Sous categorie"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {checked === "principale" ? (
        <Box>
          <Box>
            <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              Catégorie
            </Typography>
            <Box>
              <TextField
                name="name"
                variant="outlined"
                fullWidth
                value={values.name || ""}
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Typography sx={{ fontWeight: "bold", mt: 1, mb: 2 }}>
            Image catégorie
          </Typography>
          <Box sx={{ pt: 2, pb: 4 }}>
            <Box
              sx={{
                width: "100%",
                height: 100,
                mr: 1,
                mb: 1,
                position: "relative"
              }}
            >
              <Dropzone
                onFinished={handleUpload}
                message="Ajouter PDF ou png ou JPEG"
                photo={values.image || ""}
                btnSx={{ height: "100%" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
              gap: "10px"
            }}
          >
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Annuler
            </Button>
            <Button
              disabled={loading}
              variant="contained"
              onClick={onSubmit}
              startIcon={
                loading ? (
                  <CircularProgress style={{ width: 25, height: 25 }} />
                ) : null
              }
            >
              Ajouter
            </Button>
          </Box>
        </Box>
      ) : (
        <ModalSubCategory setOpen={() => setOpen(false)} />
      )}
    </CustomModal>
  )
}
export default ModalCategory
