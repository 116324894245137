import {
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
  Grid
} from "@mui/material"
import { CarteCampagne } from "./component/CarteCampagne"
import { SenderMailForm } from "./component/SenderMailForm"
import { DestinationCampagne } from "./component/DestinationCampagne"
import { useNavigate } from "react-router-dom"
import { useApplicationContext } from "@/hooks"
import { useEffect, useState } from "react"
import { CREATE_CAMPAGNE, CREATE_SMS_CAMPAIGN } from "@/graphql/emailing"
import { useMutation } from "@apollo/client"
import { CreateCampaignVariables } from "@/graphql/emailing/types/CreateCampaign"
import { CreateSmsCampaignVariables } from "@/graphql/emailing/types/CreateSmsCampaign"
import Swal from "sweetalert2"

export const CreateCampaign = () => {
  const navigate = useNavigate()
  const [message, setMessage] = useState("")
  const [createCampagneFn] =
    useMutation<CreateCampaignVariables>(CREATE_CAMPAGNE)
  const [createCampagneSmsFn] =
    useMutation<CreateSmsCampaignVariables>(CREATE_SMS_CAMPAIGN)
  const {
    campagneState,
    receiptState,
    setReceiptState,
    setCampagneState,
    business,
    typeCampagne,
    setTypeCampagne
  } = useApplicationContext()
  const hasValue: boolean = !!campagneState
  const [html, setHtml] = useState("")
  const templateHtml = () => {
    setHtml(localStorage.getItem("templateCampagne") || "")
  }
  const redirectToBuilder = () => {
    navigate("../template")
  }
  const confirmCampaign = () => {
    if (typeCampagne === "email") {
      createCampagneFn({
        variables: {
          data: {
            name: campagneState?.name,
            subject: campagneState?.objectCampaing,
            recipients: receiptState,
            htmlContent: html,
            sender: campagneState?.sender,
            businessId: business?.id || null
          }
        }
      })
        .then(() => {
          setCampagneState({})
          setReceiptState([])
          localStorage.removeItem("templateHtml")
          setHtml("")
          Swal.fire({
            icon: "success",
            title: "Création de la campagne réussie",
            text: "",
            showConfirmButton: false,
            showCancelButton: false
          })
          setTimeout(() => navigate(-1), 1000)
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Création de la campagne échoué",
            text: "Merci de réessayé un peu plus tard",
            showCancelButton: false,
            showConfirmButton: false
          })
        })
    }

    if (typeCampagne === "sms") {
      createCampagneSmsFn({
        variables: {
          data: {
            name: campagneState?.name,
            businessId: business?.id || null,
            message,
            listContact: receiptState
          }
        }
      })
        .then(() => {
          setCampagneState({})
          setReceiptState([])
          setTypeCampagne("")
          setHtml("")
          Swal.fire({
            icon: "success",
            title: "Création de la campagne réussie",
            text: "",
            showConfirmButton: false,
            showCancelButton: false
          })
          setTimeout(() => navigate(-1), 1000)
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Création de la campagne échoué",
            text: "Merci de réessayé un peu plus tard",
            showCancelButton: false,
            showConfirmButton: false
          })
        })
    }
  }

  useEffect(() => {
    templateHtml()
  }, [templateHtml])

  return (
    <Box sx={{ m: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button variant="contained" onClick={confirmCampaign}>
          Créer la campagne
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ my: 2, maxWidth: 750, width: "100%" }}>
              <CarteCampagne
                title="Titre de votre campagne"
                sousTitle="Le nom de votre campagne"
                btnText="Ajouter le nom"
                elevation={0}
                display={hasValue}
              >
                <TextField
                  value={campagneState?.name}
                  onChange={(e) =>
                    setCampagneState((prev) => ({
                      ...prev,
                      name: e.target.value
                    }))
                  }
                  fullWidth
                />
              </CarteCampagne>
              <CarteCampagne
                title="Type de campagne"
                sousTitle="Envoyez votre campagne soit par email soit par SMS"
                btnText="Choisir le type"
                elevation={0}
                display={hasValue}
              >
                <RadioGroup onChange={(e) => setTypeCampagne(e.target.value)}>
                  <FormControlLabel
                    checked={typeCampagne === "sms"}
                    value="sms"
                    control={<Radio />}
                    label="SMS"
                  />
                  <FormControlLabel
                    checked={typeCampagne === "email"}
                    value="email"
                    control={<Radio />}
                    label="Email"
                  />
                </RadioGroup>
              </CarteCampagne>
              {typeCampagne === "email" && (
                <>
                  <CarteCampagne
                    title="De"
                    sousTitle="Qui envoie cette campagne d'emails?"
                    btnText="Ajouter depuis une liste"
                    elevation={0}
                    display={hasValue}
                  >
                    <SenderMailForm />
                  </CarteCampagne>
                  <CarteCampagne
                    title="A"
                    sousTitle="Sélectionnez votre/vos liste(s) de contacts"
                    btnText="Ajouter des destinataires"
                    elevation={0}
                    display={hasValue}
                  >
                    <DestinationCampagne />
                  </CarteCampagne>
                  <CarteCampagne
                    title="Objet de la campagne"
                    sousTitle="Ajoutez une ligne d'objet pour cette campagne"
                    btnText="Ajouter un objet"
                    elevation={0}
                    display={hasValue}
                  >
                    <TextField
                      value={campagneState?.objectCampaing}
                      onChange={(e) =>
                        setCampagneState((prev) => ({
                          ...prev,
                          objectCampaing: e.target.value
                        }))
                      }
                      fullWidth
                    />
                  </CarteCampagne>
                  <CarteCampagne
                    title="Design"
                    sousTitle="Créez votre contenu."
                    btnText="Concevoir l'email"
                    elevation={0}
                    display={hasValue}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <Button variant="contained" onClick={redirectToBuilder}>
                        Creer votre template
                      </Button>
                    </Box>
                  </CarteCampagne>
                </>
              )}
              {typeCampagne === "sms" && (
                <>
                  <CarteCampagne
                    title="A"
                    sousTitle="Sélectionnez votre/vos liste(s) de contacts"
                    btnText="Ajouter des destinataires"
                    elevation={0}
                    display={hasValue}
                  >
                    <DestinationCampagne type="sms" />
                  </CarteCampagne>
                  <CarteCampagne
                    title="Design"
                    sousTitle="Créez votre contenu ."
                    btnText="Corps du message"
                    elevation={0}
                    display={hasValue}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mt: 1 }}
                      >
                        corps du message
                      </Typography>
                      <TextField
                        value={message}
                        fullWidth
                        multiline
                        maxRows={6}
                        variant="outlined"
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Box>
                  </CarteCampagne>
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {typeCampagne === "sms" && (
            <Box
              sx={{
                background:
                  "url('/static/images/phone.png') top center no-repeat",
                minHeight: 450,
                maxWidth: 300,
                p: "50px 20px",
                m: "auto"
              }}
            >
              {message && (
                <Box
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "#0866ff",
                    color: "white",
                    p: 1,
                    maxWidth: "80%"
                  }}
                >
                  {message}
                </Box>
              )}
            </Box>
          )}
          {typeCampagne === "email" && (
            <Box>
              <iframe
                srcDoc={html}
                frameBorder={0}
                style={{ minHeight: 450, width: "100%" }}
              ></iframe>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" onClick={confirmCampaign}>
          Créer la campagne
        </Button>
      </Box>
    </Box>
  )
}
