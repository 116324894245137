import {
  BusinessTheme,
  BusinessThemeVariables
} from "@/graphql/business/types/BusinessTheme"
import {
  ReservationConfiguration,
  ReservationConfigurationVariables
} from "@/graphql/business/types/ReservationConfiguration"
import { useMutation } from "@apollo/client"
import {
  BusinessPalette,
  BusinessPaletteVariables,
  CreateUtilisateur,
  CreateUtilisateurVariables,
  CREATE_USER,
  UpdateUtilisateur,
  UpdateUtilisateurVariables,
  UPDATE_OR_CREATE_CONFIGURATION_RESERVATION,
  UPDATE_OR_CREATE_PALETTE,
  UPDATE_OR_CREATE_THEME,
  UPDATE_USER
} from "../../graphql/business"
import {
  DeleteClient,
  DeleteClientVariables,
  DELETE_CLIENT,
  UpdateClient,
  UpdateClientVariables,
  UPDATE_CLIENT
} from "../../graphql/clients"
import {
  CreateClientInput,
  PaletteInput,
  ReservationConfigurationInput,
  ThemeInput
} from "../../types"

export const useUserAction = () => {
  const [updateUser, { loading }] = useMutation<
    UpdateUtilisateur,
    UpdateUtilisateurVariables
  >(UPDATE_USER)

  const [createUser, { loading: createLoading, error: errorCreateUser }] = useMutation<
    CreateUtilisateur,
    CreateUtilisateurVariables
  >(CREATE_USER)
  return { updateUser, loading, createUser, createLoading, errorCreateUser }
}

export const useUpdateClient = () => {
  const [executeUpdate, { loading: updateLoading }] = useMutation<
    UpdateClient,
    UpdateClientVariables
  >(UPDATE_CLIENT)
  const [executeDelete, { loading: deleteLoading }] = useMutation<
    DeleteClient,
    DeleteClientVariables
  >(DELETE_CLIENT)
  const updateClient = async (clientId: number, data: CreateClientInput) => {
    try {
      const client = await executeUpdate({
        variables: {
          clientId,
          data
        }
      })
      return client.data?.updateClient
    } catch (error) {
      return null
    }
  }
  const deleteClient = async (clientId: number) => {
    try {
      const client = await executeDelete({
        variables: {
          clientId
        }
      })
      return client.data?.deleteClient
    } catch (error) {
      return null
    }
  }
  return {
    deleteLoading,
    updateLoading,
    updateClient,
    deleteClient
  }
}

interface PaletteIdType {
  businessId?: number
  paletteId?: number
}
export const useUpdateOrCreatePalette = () => {
  const [executePaletteAction, { loading }] = useMutation<
    BusinessPalette,
    BusinessPaletteVariables
  >(UPDATE_OR_CREATE_PALETTE)
  const createOrUpdatePalette = async (
    data: PaletteInput,
    { businessId, paletteId }: PaletteIdType
  ) => {
    try {
      const createOrUpdate = await executePaletteAction({
        variables: {
          data,
          businessId,
          paletteId
        }
      })
      return createOrUpdate.data?.businessPalette
    } catch (error) {
      console.log(error)

      return null
    }
  }
  return {
    loading,
    createOrUpdatePalette
  }
}

interface ThemeIdType {
  businessId?: number
  themeId?: number
}
export const useUpdateOrCreateTheme = () => {
  const [executeThemeAction, { loading }] = useMutation<
    BusinessTheme,
    BusinessThemeVariables
  >(UPDATE_OR_CREATE_THEME)
  const createOrUpdateTheme = async (
    data: ThemeInput,
    { businessId, themeId }: ThemeIdType
  ) => {
    try {
      const createOrUpdate = await executeThemeAction({
        variables: { data, businessId, themeId }
      })
      return createOrUpdate
    } catch (error) {
      console.log(error)
      return null
    }
  }

  return {
    createOrUpdateTheme,
    loading
  }
}

interface ConfigurationIdType {
  businessId?: number
  configurationId?: number
}

export const useUpdateOrCreateReservationConfiguration = () => {
  const [
    executeConfigurationAction,
    { loading: configurationLoading, error: configurationError }
  ] = useMutation<ReservationConfiguration, ReservationConfigurationVariables>(
    UPDATE_OR_CREATE_CONFIGURATION_RESERVATION
  )
  const createOrUpdateConfiguration = async (
    data: ReservationConfigurationInput,
    { businessId, configurationId }: ConfigurationIdType
  ) => {
    try {
      const createOrUpdate = await executeConfigurationAction({
        variables: {
          data: data,
          businessId: businessId,
          configurationId: configurationId
        }
      })
      return createOrUpdate
    } catch (error) {
      console.log(error)
      return null
    }
  }

  return {
    createOrUpdateConfiguration,
    configurationLoading,
    configurationError: configurationError?.message
  }
}
