import { TableColumnProps } from "@/components"
import { ClientCommand_clientCommand as IClientCommand } from "@/graphql/business"
import { Chip, Typography } from "@mui/material"
import moment from "moment"

export const TableCommand = (): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "id",
      label: "ID",
      component: (data: IClientCommand) => <Typography>{data.id}</Typography>
    },
    {
      id: "name",
      label: "Nom",
      component: (data: IClientCommand) => <Typography>{data.name}</Typography>
    },
    {
      id: "email",
      label: "email",
      component: (data: IClientCommand) => <Typography>{data.email}</Typography>
    },
    {
      id: "phone",
      label: "Téléphone",
      component: (data: IClientCommand) => <Typography>{data.phone}</Typography>
    },
	{
	id: "createdAt",
	label: "date de création",
	component: (data: IClientCommand) => <Typography>{moment(data.createdAt).format("DD MMMM YYYY")}</Typography>
	},
    {
      id: "status",
      label: "état de la commande",
      component: (data: IClientCommand) => {
        switch (data.status) {
          case "PENDING":
            return <Chip color="warning" label="En attente" />
          case "VALID":
            return <Chip color="success" label="Validé" />
          case "REJECT":
            return <Chip color="error" label="Rejeté" />
          case "DONE":
            return <Chip label="Terminé" />
        }
      }
    }
  ]

  return { columns }
}
