import { gql } from "@apollo/client";

export const ABONNEMENT = gql`
  query OneAbonnement($abonnementId: Float!) {
    oneAbonnement(abonnementId: $abonnementId) {
      id
      name
      short
      description
      createdAt
      prixAbonnement
      numberBooking
      numberBranding
      numberClick
      prixEmail
      numberGame
      prixSms
      numberSubscription
      priceStripeId
      prixTtc
      taxe
      updatedAt
      freeTrial
    }
  }
`

export const LIST_ABONNEMENT = gql`
  query ListAbonnement {
    listAbonnement {
      id
      name
      description
      createdAt
      updatedAt
      prixAbonnement
      numberBooking
      numberBranding
      numberClick
      prixEmail
      numberGame
      percentOff
      prixSms
      numberSubscription
      productStripeId
      priceStripeId
      prixTtc
      short
      taxe
      status
      freeTrial
      defaultSub
    }
  }
`

export const LIST_SUBSCRIPTION = gql`
  query GetSubscriptionList($businessId: Float!) {
    getSubscriptionList(businessId: $businessId) {
      Abonnements {
        abonnementId
        bookingCount
        businessId
        clickCount
        defaultAbonnementId
        itemId
        payementUrl
        status
        stripeSubscriptionId
        subscribeCount
      }
    }
  }
`

export const BUSINESS_SUBSCRIPTION = gql`
  query GetSubscription($businessId: Float!) {
    getSubscriptionBusiness(businessId: $businessId) {
      abonnementId
      bookingCount
      businessId
      clickCount
      defaultAbonnementId
      itemId
      payementUrl
      status
      stripeSubscriptionId
      subscribeCount
    }
  }
`

export const LIST_BILLING = gql`
  query ListBilling($businessId: Float!) {
    listBilling(businessId: $businessId) {
      id
      hostedUrl
      customerId
      currency
      createdAt
      businessId
      billing
      amountPaid
      amountDue
    }
  }
`

export const GET_DEFAULT_ABO = gql`
  query GetDefaultAbonnement {
    getDefaultAbonnement {
      id
      name
      numberBooking
      numberBranding
      percentOff
      description
      freeTrial
      numberClick
      numberGame
      numberSubscription
      priceStripeId
      prixAbonnement
      prixEmail
      prixSms
      prixTtc
      short
      status
    }
  }
`
