import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material"

interface CustomAppBarProps {
  menuComponent?: React.ReactNode
  title?: string
  color?:
    | "inherit"
    | "default"
    | "transparent"
    | "primary"
    | "secondary"
    | undefined
}
const AppToolbar = styled(Toolbar)(() => ({
  minHeight: "unset !important",
  padding: "10px 0"
}))
export const CustomAppBar: React.FC<CustomAppBarProps> = ({
  menuComponent,
  title,
  color
}): JSX.Element => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color={color}>
        <AppToolbar>
          <Typography component="div" color="white" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {menuComponent}
        </AppToolbar>
      </AppBar>
    </Box>
  )
}
