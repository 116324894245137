import { gql } from "@apollo/client"

export const CREATE_LOT = gql`
  mutation CreateLot($data: LotInput!, $businessId: Float!) {
    createLot(data: $data, businessId: $businessId)
  }
`
export const REMOVE_LOT = gql`
  mutation RemoveLot($lotId: Float!) {
    removeLot(lotId: $lotId)
  }
`
export const UPDATE_LOT = gql`
  mutation UpdateLot($data: UpdateLotInput!, $lotId: Float!) {
    updateLot(data: $data, lotId: $lotId)
  }
`
