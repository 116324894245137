import { gql } from "@apollo/client"

export const CLIENT_BY_ID = gql`
  query ClientById($clientId: Float!) {
    clientById(clientId: $clientId) {
      id
      email
      phone
      auth
      firstname
      lastname
      status
      photo
      civilite
      codePostal
      function
      acceptSms
      acceptEmail
      type
      raisonSocial
      city
      address
      source
      createdAt
      updatedAt
      tags {
        tagId
      }
    }
  }
`

export const CLIENT_BOOKING = gql`
  query Clientresevation($clientId: Float!) {
    clientResevation(clientId: $clientId) {
      business {
        id
        logo
        businessName
      }
      businessId
      client {
        id
        lastname
        firstname
        email
        phone
        photo
      }
      eventParty {
        description
        dateStart
        dateEnd
        id
        img
        title
        hourStart
        hourEnd
      }
      clientId
      createdAt
      date
      email
      eventId
      hour
      id
      message
      name
      person
      phone
      status
      updatedAt
    }
  }
`
