/* eslint-disable import/extensions */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { GET_CLIENT_COMMAND } from "@/graphql/business"
import { useState } from "react"
import { BaseTable, QueryResult } from "@/components"
import { TableCommand } from "./components/TableCommand"
import { TableBooking } from "./components/TableBooking"
import { TableGame } from "./components/TableGame"
import { TableSignIn } from "./components/TableSignIn"
import { CLIENT_BOOKING } from "@/graphql/clients"
import { STATUS_GAME_USER } from "@/graphql/qrcode"
import { GET_CLIENT_BUSINESS } from "@/graphql/business"
import { useApplicationContext } from "@/hooks"
import { AdminType } from "@/types"

export const ActiviteInfos = () => {
  const { user } = useApplicationContext()
  const { id } = useParams()
  const [page] = useState<number>(1)
  const { columns } = TableCommand()
  const { columns: columnsBooking } = TableBooking()
  const { columns: columnsGame } = TableGame()
  const { columns: columnsClient } = TableSignIn()
  const { data: dataCommand, loading: loadingCommand } = useQuery(
    GET_CLIENT_COMMAND,
    {
      variables: {
        clientId: Number(id)
      },
      skip: !id
    }
  )
  const { data: dataBooking, loading: loadingBooking } = useQuery(
    CLIENT_BOOKING,
    {
      variables: {
        clientId: Number(id)
      },
      skip: !id
    }
  )
  const { data: dataGame, loading: loadingGame } = useQuery(STATUS_GAME_USER, {
    variables: {
      clientId: Number(id),
      limit: 100
    },
    skip: !id
  })
  const { data: clientData, loading: loadingClient } = useQuery(GET_CLIENT_BUSINESS, {
    variables: {
      clientId: Number(id)
    },
    skip: !id
  })

  return (
    <Box sx={{ m: 2 }}>
      <Box sx={{ my: 1 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography color="primary">Jeux </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <QueryResult data={dataGame} loading={loadingGame}>
              <BaseTable
                indexed="id"
                columns={columnsGame}
                data={dataGame?.statusGamesByUser || []}
                page={page - 1}
              />
            </QueryResult>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ my: 1 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography color="primary">Commandes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <QueryResult data={dataCommand} loading={loadingCommand}>
              <BaseTable
                indexed="id"
                columns={columns}
                data={dataCommand?.clientCommand || []}
                page={page - 1}
              />
            </QueryResult>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography color="primary">Réservations </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <QueryResult data={dataBooking} loading={loadingBooking}>
            <BaseTable
              indexed="id"
              columns={columnsBooking}
              data={dataBooking?.clientresevation || []}
              page={page - 1}
            />
          </QueryResult>
        </AccordionDetails>
      </Accordion>
      {user?.type === AdminType.ADMIN && (
        <Box sx={{ mt: 1 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography color="primary">Information inscription</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <QueryResult data={clientData} loading={loadingClient}>
                <BaseTable
                  indexed="id"
                  columns={columnsClient}
                  data={clientData?.ClientBusiness || []}
                  page={page - 1}
                />
              </QueryResult>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Box>
  )
}
