import { Card, Button, CircularProgress, styled } from "@mui/material"
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentElement
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { FC, useState } from "react"

const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx")

const CardPay = styled(Card)(() => ({
  "& .StripeElement": {
    border: "1px solid #fe724c",
    height: 40,
    borderRadius: 15,
    padding: 10
  }
}))
interface ICheckoutForm {
  amount: number
  loading?: boolean
  onProcess?: (data: any) => void
}
const Form: FC<ICheckoutForm> = ({
  onProcess,
  loading,
  amount
}): JSX.Element => {
  const [load, setLoad] = useState<boolean>(false)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: any): Promise<void> => {
    //   We don't want to let default form submission happen here,
    //   which would refresh the page.
    event.preventDefault()
    setLoad(true)
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoad(false)
      return
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement as any) as any
    })
    setLoad(false)
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error)
    } else {
      onProcess && onProcess(result)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardPay sx={{ p: 1, my: 2, textAlign: "center" }}>
        <CardElement />
        <Button
          variant="contained"
          type="submit"
          sx={{ mt: 3, textTransform: "none" }}
          disabled={!stripe || !elements || loading || load}
          startIcon={
            loading || load ? (
              <CircularProgress style={{ width: 25, height: 25 }} />
            ) : null
          }
        >
          Payer mon abonnement - {amount} €
        </Button>
      </CardPay>
    </form>
  )
}

export const CheckoutForm: FC<ICheckoutForm> = ({
  onProcess,
  loading,
  amount
}): JSX.Element => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "sk_test_51KMRgEFr0p6lB5m8IFtCsR67fTalFxXEfxBezGNcmcMI1mwLqLKY7Ew9mrDjxgDM1ZztlFLej4bIyfd5RPubOWBp00Hxt8rlM2"
  }

  return (
    <Elements stripe={stripePromise}>
      <Form loading={loading} onProcess={onProcess} amount={amount} />
    </Elements>
  )
}
