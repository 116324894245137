import { TableColumnProps } from "@/components"
import { GetListContact_getListContact as ListContatType } from "@/graphql/emailing/types/GetListContact"

export const TableList = (): { columns: TableColumnProps[] } => {
  const columns = [
    {
      id: "2",
      label: "Nom",
      component: (data: ListContatType) => <p>{data.name}</p>
    },
    {
      id: "3",
      label: "Nombre de contacts",
      component: (data: ListContatType) => ( data?.clients ? <p>{data?.clients.length} contact(s)</p> : <p>0 contact</p> )
    }
  ]

  return { columns }
}
