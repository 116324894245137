/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete, Edit, Add } from "@mui/icons-material"
import { Typography, Box } from "@mui/material"
import moment from "moment"
import { ButtonLittleIcon, TableColumnProps } from "@/components"
import {
  BusinessProductById_businessProductById as IProduct,
  BUSINESS_GET_CLICK_BY_ID
} from "@/graphql/business"
import defaultPictures from "@/assets/logoSimple.png"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { useApplicationContext } from "@/hooks"
import { useUpdateProduct } from "@/hooks/category"
import { FormatPrice } from "@/components/FormatPrice"

interface TableProduitsProps {
  onView?: (b: IProduct) => void
  onRemove?: (id: number) => void
  onEdit?: (idEditProduct: number) => void
}

export const TableProduits = ({
  onEdit,
  onRemove
}: TableProduitsProps): { columns: TableColumnProps[] } => {
  const { productUpdate } = useUpdateProduct()
  const { business } = useApplicationContext()
  const navigate = useNavigate()
  const { data: dataCurrency } = useQuery(BUSINESS_GET_CLICK_BY_ID, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })
  const upStatus = async (item: boolean, id: number): Promise<void> => {
    productUpdate(parseFloat(id as unknown as string), {
      status: item
    })
  }
  const columns: TableColumnProps[] = [
    {
      id: "image",
      label: "Image",
      component: (data: IProduct) => (
        <Box
          sx={{
            width: 100,
            height: 100,
            m: 2,
            borderRadius: "5px",
            position: "relative"
          }}
        >
          <img
            src={data.image || defaultPictures}
            alt=""
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
      )
    },
    {
      id: "name",
      label: "Produit ",
      component: (data: IProduct) => <Typography>{data.name}</Typography>
    },
    {
      id: "prix",
      label: "Prix",
      component: (data: IProduct) => (
        <FormatPrice
          position={dataCurrency?.clickCollectById?.position || "left"}
          currency={dataCurrency?.clickCollectById?.currency || "euro"}
          separator={
            dataCurrency?.clickCollectById?.thousandsSeparator || "space"
          }
          decimal={dataCurrency?.clickCollectById?.decimalSeparator || "coma"}
          decNum={dataCurrency?.clickCollectById?.decimalNumber || 2}
          value={data?.prix || 0}
        />
      )
    },
    {
      id: "promo",
      label: "Promotion",
      component: (data: IProduct) => (
        <Typography>
          {!data?.prixPromotionnels ? "" : `${data?.prixPromotionnels} %`}
        </Typography>
      )
    },
    {
      id: "description",
      label: "description",
      component: (data: IProduct) => <Typography>{data.description}</Typography>
    },
    {
      id: "date",
      label: "Date",
      component: (data: IProduct) => (
        <Typography>
          {moment(data.createdAt).format("DD/MM/YYYY à HH:mm")}
        </Typography>
      )
    },
    {
      id: "preparationTime",
      label: "Temps de préparation",
      component: (data: IProduct) => {
        if (!data.preparationTime) {
          return <Typography>Non renseigné</Typography>
        }
        if (Number(data.preparationTime) === 60) {
          return <Typography>1h</Typography>
        }
        if (data.preparationTime >= "70") {
          return <Typography>1h et plus</Typography>
        }
        return <Typography>{data.preparationTime} min</Typography>
      }
    },
    {
      id: "action",
      label: "Action",
      component: (data: IProduct) => (
        <Box>
          <ButtonLittleIcon
            sx={{ mr: 1, mb: 1, backgroundColor: "#2196f3" }}
            icon={<Add />}
            onClick={() => {
              navigate("add-product")
            }}
          />
          <ButtonLittleIcon
            sx={{ mr: 1, mb: 1 }}
            icon={<Edit />}
            color="success"
            onClick={() => {
              if (onEdit) {
                onEdit(data.id)
              }
            }}
          />
          <ButtonLittleIcon
            sx={{ mr: 1, mb: 1 }}
            icon={<Delete />}
            color="error"
            onClick={() => {
              if (onRemove) {
                onRemove(data.id)
              }
            }}
          />
        </Box>
      )
    }
  ]
  return { columns }
}
