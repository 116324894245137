/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  TextField,
  styled,
  TextFieldProps,
  BoxProps,
  IconButton,
  Divider
} from "@mui/material"
import {
  ChangeEvent,
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState
} from "react"
import { Loading } from "../loading"

interface CustomTextInputProps {
  isUrl?: boolean
  label?: string
  editable?: boolean
  containerProps?: BoxProps
  renderComponent?: (text: string) => ReactNode
  loading?: boolean
  nodivider?: boolean
  onSave?: (value: string, name: string) => void
}
const CustomTextField = styled(TextField)(() => ({
  borderRadius: 10,
  "& input": {
    paddingRight: "80px",
    overflow: "scroll"
  }
}))
export const CustomTextInput: FC<CustomTextInputProps & TextFieldProps> = ({
  containerProps,
  editable = true,
  loading,
  nodivider,
  isUrl,
  onSave,
  renderComponent,
  ...props
}): JSX.Element => {
  const { defaultValue, name, placeholder } = props
  const [values, setValues] = useState<string>("")
  // const [edit, setEdit] = useState<boolean>(false)
  const handleSave = (e: SyntheticEvent | any): void => {
    e.preventDefault()
    if (!name) return
    onSave && onSave(values, name)
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setValues(e.target.value)
  }
  useEffect(() => {
    if (defaultValue) {
      setValues(defaultValue as string)
    }
  }, [defaultValue])

  return (
    <Box {...containerProps}>
      <form>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CustomTextField
            fullWidth
            onChange={handleChange}
            onBlur={handleSave}
            variant="outlined"
            value={values}
            {...props}
          />
          {/* <Box sx={{ flexGrow: 1 }}>
            <Typography component="span" variant="subtitle1">
              {label}
            </Typography>
            {renderComponent ? (
              renderComponent(value as string)
            ) : (
              <>
                {isUrl ? (
                  <a
                    href={value as string}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography component="span" variant="subtitle2">
                      {value as string}
                    </Typography>
                  </a>
                ) : (
                  <Typography component="span" variant="subtitle2">
                    {value as string}
                  </Typography>
                )}
              </>
            )}
          </Box> */}
          <Box sx={{ display: "flex", width: 0, justifyContent: "flex-end" }}>
            {loading ? (
              <IconButton>
                <Loading />
              </IconButton>
            ) : null}
          </Box>
        </Box>
        {nodivider ? null : <Divider sx={{ mb: 1 }} />}
      </form>
    </Box>
  )
}
