import moment from "moment"
import {
  ARCHIVE_COMMAND,
  BUSINESS_GET_CLICK_BY_ID,
  CLOSE_DONE_COMMAND,
  GET_CLIENT_COMMAND_ID,
  GET_DONE_COMMAND,
  GET_PENDING_COMMAND,
  GET_REJECT_COMMAND,
  GET_VALID_COMMAND,
  PRODUCT_BY_BUSINESS,
  REJECT_COMMAND,
  VALIDATE_COMMAND
} from "@/graphql/business"
import {
  ColumnType,
  DndInitTypes,
  ProductTaskType,
  TaskType
} from "@/types/DndInitTypes"
import { useApplicationContext } from "@/hooks"
import { useMutation, useQuery, useSubscription } from "@apollo/client"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Grid
} from "@mui/material"
import { useParams } from "react-router-dom"
import { QueryResult } from "@/components"
import { useCallback, useEffect, useMemo, useState } from "react"
import { COMMAND_CREATE_NOTIFICATION } from "@/graphql/business/subscription"
import {
  ValidCommand,
  ValidCommandVariables
} from "@/graphql/business/types/ValidCommand"
import { CardCommand } from "./component/CardCommand"
import {
  ProductByBusiness,
  ProductByBusinessVariables
} from "@/graphql/business/types/ProductByBusiness"
import { ModalDnd } from "@/components/DndBoard/ModalDnd"

export const CommandBoard = () => {
  const [filterTab, setFilterTab] = useState("all")
  const handleClose = () => setOpen(false)
  const [currentCommand, setCurrentCommand] = useState(0)
  const [currentCientId, setCurrentCientId] = useState(0)
  const [currentList, setCurrentList] = useState<ProductTaskType[]>([])
  const [open, setOpen] = useState(false)
  const { business } = useApplicationContext()
  const [validateFn] = useMutation(VALIDATE_COMMAND)
  const [rejectFn] = useMutation(REJECT_COMMAND)
  const [doneFn] = useMutation(CLOSE_DONE_COMMAND)
  const [archiveFn] = useMutation(ARCHIVE_COMMAND)
  const handleView = (
    commandId: number,
    clientId: number,
    products: ProductTaskType[]
  ) => {
    setCurrentCommand(commandId)
    setCurrentCientId(clientId)
    setCurrentList(products)
    refetchCommand({
      clientId,
      commandId
    }).then(() => setOpen(true))
  }
  const { data: dataBusiness } = useQuery<
    ProductByBusiness,
    ProductByBusinessVariables
  >(PRODUCT_BY_BUSINESS, {
    variables: {
      businessId: business?.id as number
    },
    skip: !business?.id
  })
  const { id } = useParams()
  const { data: dataCommand, refetch: refetchCommand } = useQuery(
    GET_CLIENT_COMMAND_ID,
    {
      variables: {
        clientId: currentCientId,
        commandId: currentCommand
      },
      skip: !currentCommand || !currentCientId
    }
  )

  const { data: commandNotif, loading: loadingNotif } = useSubscription(
    COMMAND_CREATE_NOTIFICATION,
    {
      variables: {
        args: {
          businessId: business?.id
        }
      }
    }
  )
  const { data: dataCurrency } = useQuery(BUSINESS_GET_CLICK_BY_ID, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })

  const filterTabFn = (event: SelectChangeEvent) => {
    setFilterTab(event.target.value as string)
  }

  const {
    data: dataPending,
    loading: loadingPending,
    refetch: refetchPending
  } = useQuery(GET_PENDING_COMMAND, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })
  const {
    data: dataValid,
    loading: loadingValid,
    refetch: refetchValid
  } = useQuery<ValidCommand, ValidCommandVariables>(GET_VALID_COMMAND, {
    variables: {
      businessId: business?.id || parseFloat(id as string)
    },
    skip: !business?.id
  })
  const {
    data: dataReject,
    loading: loadingReject,
    refetch: refetchReject
  } = useQuery(GET_REJECT_COMMAND, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })
  const {
    data: dataDone,
    loading: loadingDone,
    refetch: refetchDone
  } = useQuery(GET_DONE_COMMAND, {
    variables: {
      businessId: business?.id
    },
    skip: !business
  })
  const onArchive = (idChange: number) => {
    archiveFn({
      variables: {
        commandId: idChange,
        archive: true
      }
    }).then(() => {
      refetchDone()
      refetchValid()
      refetchReject()
      refetchPending()
    })
  }

  const pending = dataPending?.pendingCommand.map((item: TaskType) => {
    return {
      ...item,
      processedId: `${item.id}_${item.clientId}`
    }
  })
  const done = dataDone?.doneCommand.map((item: TaskType) => {
    return {
      ...item,
      processedId: `${item.id}_${item.clientId}`
    }
  })
  const reject = dataReject?.rejectCommand.map((item: TaskType) => {
    return {
      ...item,
      processedId: `${item.id}_${item.clientId}`
    }
  })
  const valid = dataValid?.validCommand.map((item: any) => {
    return {
      ...item,
      processedId: `${item.id}_${item.clientId}`
    }
  })
  const initData: DndInitTypes = {
    tasks: [],
    columns: [],
    columnOrder: []
  }

  const data = pending && done && reject && valid
  const reload = useCallback(() => {
    refetchDone()
    refetchReject()
    refetchValid()
    refetchPending()
  }, [refetchDone, refetchPending, refetchReject, refetchValid])

  if (data) {
    const tasks: TaskType[] = [
      ...pending.reverse(),
      ...done.reverse(),
      ...reject.reverse(),
      ...valid.reverse()
    ]
    const pendingId = pending.map((item: TaskType) => item.processedId)
    const validId = valid.map((item: TaskType) => item.processedId)
    const rejectId = reject.map((item: TaskType) => item.processedId)
    const doneId = done.map((item: TaskType) => item.processedId)
    const columns: ColumnType[] = [
      {
        id: "pending",
        title: "En attente",
        field: "PENDING",
        taskIds: pendingId
      },
      {
        id: "valid",
        title: "Validé",
        field: "VALID",
        taskIds: validId
      },
      {
        id: "reject",
        title: "Refusé",
        field: "REJECT",
        taskIds: rejectId
      },
      {
        id: "done",
        title: "Terminé",
        field: "DONE",
        taskIds: doneId
      }
    ]
    const columnOrder = columns.map((col) => col.id)

    initData.tasks = tasks
    initData.columns = columns
    initData.columnOrder = columnOrder
  }

  const dateNowFilter = (item: any[]) => {
    const nowDate = moment().format("DD/MM/YYYY")
    const dataDay = item.filter((item: { createdAt: moment.MomentInput }) => {
      const createdDate = moment(item.createdAt).format("DD/MM/YYYY")

      return createdDate === nowDate
    })

    return dataDay
  }

  const dateWeekFilter = (item: any[]) => {
    const currentDate = moment()
    const weekStart = currentDate.clone().startOf("isoWeek")

    let days: string[] = []

    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, "days").format("DD/MM/YYYY"))
    }

    const dataWeek = item.filter((item: { createdAt: moment.MomentInput }) => {
      const createdDate = moment(item.createdAt).format("DD/MM/YYYY")

      return days.includes(createdDate)
    })

    return dataWeek
  }

  const archiveFilter = (item: any[]) => {
    if (item.length > 0) return item.filter((item) => item.archive !== false)
  }

  const allFilter = (item: any[]) => {
    if (item && item.length > 0)
      return item.filter((item) => item.archive !== true)
  }
  const filterTabObj: { [index: string]: any } = {
    now: (data: any[]) => dateNowFilter(data) || [],
    week: (data: any[]) => dateWeekFilter(data) || [],
    archive: (data: any[]) => archiveFilter(data) || [],
    all: (data: any[]) => allFilter(data) || []
  }

  const onChange = (idChange: number, status: string) => {
    switch (status) {
      case "valid":
        validateFn({
          variables: {
            commandId: idChange
          }
        }).then(() => {
          refetchPending()
          refetchValid()
        })
        break

      case "reject":
        rejectFn({
          variables: {
            commandId: idChange
          }
        }).then(() => {
          refetchPending()
          refetchReject()
        })
        break

      case "done":
        doneFn({
          variables: {
            commandId: idChange
          }
        }).then(() => {
          refetchValid()
          refetchDone()
        })
        break

      default:
        break
    }
  }

  useEffect(() => {
    reload()
  }, [reload])

  useEffect(() => {
    setInterval(() => {
      refetchPending()
    }, 30000)
  }, [])

  useMemo(() => {
    if (commandNotif && !loadingNotif) {
      reload()
    }
  }, [commandNotif, loadingNotif])

  return (
    <Box sx={{ p: 2 }}>
      <Box
        marginBottom={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Box>
          <Typography variant="h5">Tableau des commandes :</Typography>
        </Box>
        <Box width={250}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filtre</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterTab}
              label="Filtre"
              onChange={filterTabFn}
            >
              <MenuItem value={"now"}>Aujourd'hui</MenuItem>
              <MenuItem value={"week"}>Cette semaine</MenuItem>
              <MenuItem value={"archive"}>Archivé</MenuItem>
              <MenuItem value={"all"}>Tous</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Box
              sx={{
                borderRadius: "12px",
                backgroundColor: "#EFEFEF",
                padding: 2
              }}
            >
              <Box mb={2}>
                <Typography variant="h5">En attente</Typography>
              </Box>
              <QueryResult data={dataPending} loading={loadingPending}>
                <>
                  {dataPending?.pendingCommand &&
                    dataPending?.pendingCommand.length > 0 &&
                    filterTabObj[filterTab](dataPending?.pendingCommand)
                      .reverse()
                      .map((item: any) => (
                        <CardCommand
                          id={item.id}
                          name={item.name}
                          key={item.id}
                          email={item.email}
                          clientId={item.clientId}
                          adresse={item.address}
                          phone={item.phone}
                          commandeId={0}
                          products={item.products}
                          createdAt={item.createdAt}
                          currencyType={dataCurrency}
                          onEmmit={handleView}
                          businessProducts={dataBusiness}
                          commandType="PENDING"
                          onChange={onChange}
                        />
                      ))}
                </>
              </QueryResult>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box
              sx={{
                borderRadius: "12px",
                backgroundColor: "#EFEFEF",
                padding: 2
              }}
            >
              <Box mb={2}>
                <Typography variant="h5">Validé</Typography>
              </Box>
              <QueryResult data={dataValid} loading={loadingValid}>
                <>
                  {dataValid?.validCommand &&
                    dataValid?.validCommand.length > 0 &&
                    filterTabObj[filterTab](dataValid?.validCommand)
                      .reverse()
                      .map((item: any) => (
                        <CardCommand
                          id={item.id}
                          name={item.name}
                          key={item.id}
                          email={item.email}
                          clientId={item.clientId}
                          adresse={item.address}
                          phone={item.phone}
                          commandeId={0}
                          products={item.products}
                          createdAt={item.createdAt}
                          currencyType={dataCurrency}
                          onEmmit={handleView}
                          businessProducts={dataBusiness}
                          commandType="VALID"
                          onChange={onChange}
                        />
                      ))}
                </>
              </QueryResult>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box
              sx={{
                borderRadius: "12px",
                backgroundColor: "#FFEBE5",
                padding: 2
              }}
            >
              <Box mb={2}>
                <Typography variant="h5">Refusé</Typography>
              </Box>
              <QueryResult data={dataReject} loading={loadingReject}>
                <>
                  {dataReject?.rejectCommand &&
                    dataReject?.rejectCommand.length > 0 &&
                    filterTabObj[filterTab](dataReject?.rejectCommand)
                      .reverse()
                      .map((item: any) => (
                        <CardCommand
                          clientId={item.clientId}
                          id={item.id}
                          name={item.name}
                          key={item.id}
                          email={item.email}
                          adresse={item.address}
                          phone={item.phone}
                          commandeId={0}
                          products={item.products}
                          createdAt={item.createdAt}
                          currencyType={dataCurrency}
                          onEmmit={handleView}
                          businessProducts={dataBusiness}
                          commandType="REJECT"
                          onChange={onChange}
                        />
                      ))}
                </>
              </QueryResult>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box
              sx={{
                borderRadius: "12px",
                backgroundColor: "#CDF0EA",
                padding: 2
              }}
            >
              <Box mb={2}>
                <Typography variant="h5">Terminé</Typography>
              </Box>
              <QueryResult data={dataDone} loading={loadingDone}>
                <>
                  {dataDone?.doneCommand &&
                    dataDone?.doneCommand.length > 0 &&
                    filterTabObj[filterTab](dataDone?.doneCommand)
                      .reverse()
                      .map((item: any) => (
                        <CardCommand
                          clientId={item.clientId}
                          id={item.id}
                          name={item.name}
                          key={item.id}
                          email={item.email}
                          adresse={item.address}
                          phone={item.phone}
                          commandeId={0}
                          products={item.products}
                          createdAt={item.createdAt}
                          currencyType={dataCurrency}
                          onEmmit={handleView}
                          businessProducts={dataBusiness}
                          commandType="DONE"
                          onChange={onChange}
                        />
                      ))}
                </>
              </QueryResult>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ModalDnd
        businessProducts={dataBusiness}
        business={business}
        currentList={currentList}
        currencyType={dataCurrency}
        clientCommand={dataCommand?.clientCommandById}
        open={open}
        handleClose={handleClose}
        archived={onArchive}
      />
    </Box>
  )
}
