import { gql } from "@apollo/client"

export const LOTS = gql`
  query Lots($businessId: Float!) {
    lots(businessId: $businessId) {
      id
      image
      title
      color
      winFrequence
      description
      createdAt
      businessId
    }
  }
`
