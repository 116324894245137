import { TextField, Typography, Button, Box, Grid } from "@mui/material"
import React, { useState, useRef, useMemo } from "react"
import JoditEditor from "jodit-react"

export const EmailEtablissement = () => {
  const editor = useRef(null)
  const [content, setContent] = useState("")
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item xs={8}>
        <Box sx={{ mt: 2 }}>
          <TextField label="Objet" variant="standard" fullWidth />
        </Box>
        <Box sx={{ mt: 2 }}>
          <TextField label="Destinataire" variant="standard" fullWidth />
        </Box>
        <Box sx={{ my: 2 }}>
          <JoditEditor ref={editor} value={content} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <a href="http://google.com" style={{ textDecoration: "none" }}>
            Ajouter une piece jointe
          </a>
          <a href="http://google.com" style={{ textDecoration: "none" }}>
            Programmer l'envoi
          </a>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" sx={{ mx: 2 }}>
            Enregistrer
          </Button>
          <Button color="primary" variant="contained">
            Envoyer
          </Button>
        </Box>
      </Grid>
    </Box>
  )
}
