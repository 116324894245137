import { gql } from "@apollo/client"

export const CREATE_ABONNEMENT = gql`
  mutation CreateAbonnemnt($data: AbonnementInput!) {
    createAbonnementProduct(data: $data) {
      id
    }
  }
`
export const UPDATE_ABONNEMENT = gql`
  mutation UpdateAbonnement($abonnementId: Float!, $data: AbonnementInput!) {
    updateAbonnement(abonnementId: $abonnementId, data: $data) {
      id
    }
  }
`
export const DELETE_ABONNEMENT = gql`
  mutation DeleteAbonnement($abonnementId: Float!) {
    deleteAbonnement(abonnementId: $abonnementId) {
      id
    }
  }
`
export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($data: SubscriptionInput!, $priceId: String!) {
    createSubscription(data: $data, priceId: $priceId)
  }
`

export const BUY_PACK = gql`
  mutation BuyPack($businessId: Float!, $buyPackId: Float!) {
    BuyPack(businessId: $businessId, id: $buyPackId)
  }
`
