import {
  CREATE_ABONNEMENT,
  CreateAbonnemnt,
  CreateAbonnemntVariables,
  ListAbonnement_listAbonnement,
  UPDATE_ABONNEMENT,
  UpdateAbonnement,
  UpdateAbonnementVariables
} from "@/graphql/abonnement"
import { useMutation } from "@apollo/client"
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react"
import { useApplicationContext } from "@/hooks"

type ManageProps = {
  setOpen: (item: boolean) => void
  editiedItem?: ListAbonnement_listAbonnement
}

type StateType = {
  name: string
  short: string
  description: string
  prixAbonnement: string | number
  numberSubscription: string | number
  numberBooking: string | number
  numberClick: string | number
  prixEmail: string | number
  prixSms: string | number
  taxe: string | number
  prixTtc: string | number
  percentOff: string | number
  freeTrial: number
  productStripeId: string
  defaultSub: boolean
}

const initState = {
  name: "",
  short: "",
  description: "",
  prixAbonnement: "",
  numberSubscription: "",
  numberBooking: "",
  numberClick: "",
  prixEmail: 0,
  prixSms: 0,
  taxe: "",
  prixTtc: 0,
  percentOff: 0,
  freeTrial: 0,
  productStripeId: "",
  defaultSub: false
}

export const GererAbonnement = ({ setOpen, editiedItem }: ManageProps) => {
  const { dispatchSnack } = useApplicationContext()
  const [isLong, setIsLong] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isLongLong, setIsLongLong] = useState<boolean>(false)
  const [abonnement, setAbonnement] = useState<StateType>(initState)
  const [totalPrice, setTotalPrice] = useState(0)
  const [createAbonnement, { loading }] = useMutation<
    CreateAbonnemnt,
    CreateAbonnemntVariables
  >(CREATE_ABONNEMENT)
  const [updateAbonnement, { loading: loadingUpdate }] = useMutation<
    UpdateAbonnement,
    UpdateAbonnementVariables
  >(UPDATE_ABONNEMENT)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target
    if (name === "short" && value.length > 150) {
      setIsLong(true)
    } else {
      setIsLong(false)
    }

    if (name === "description" && value.length > 500) {
      setIsLongLong(true)
    } else {
      setIsLongLong(false)
    }

    if (type === "number") {
      setAbonnement((prev) => ({
        ...prev,
        [name]: parseFloat(value)
      }))
      return
    }

    setAbonnement((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setAbonnement((prev) => ({
      ...prev,
      freeTrial: Number(event.target.value)
    }))
  }

  useEffect(() => {
    const total =
      (Number(abonnement.prixAbonnement) * Number(abonnement.taxe)) / 100 +
      Number(abonnement.prixAbonnement)
    setTotalPrice(Number(total.toFixed(2)))
  }, [abonnement.prixAbonnement, abonnement.taxe])

  useEffect(() => {
    if (editiedItem) {
      setDisabled(true)
      setAbonnement({
        name: editiedItem.name || "",
        description: editiedItem.description || "",
        prixAbonnement: editiedItem.prixAbonnement || "",
        numberBooking: editiedItem.numberBooking || "",
        numberClick: editiedItem.numberClick || "",
        prixEmail: editiedItem.prixEmail || "",
        percentOff: editiedItem.percentOff || "",
        prixSms: editiedItem.prixSms || "",
        numberSubscription: editiedItem.numberSubscription || "",
        prixTtc: editiedItem.prixTtc || "",
        short: editiedItem.short || "",
        taxe: editiedItem.taxe || "",
        freeTrial: editiedItem.freeTrial || 0,
        productStripeId: editiedItem.productStripeId || "",
        defaultSub: editiedItem.defaultSub || false
      })
    }
  }, [editiedItem])

  const updatedAbonnement = () => {
    const nbrClick = abonnement.numberClick
    const nbrInsc = abonnement.numberSubscription
    const nbrReserv = abonnement.numberBooking
    const nomAbon = abonnement.name

    if (nbrClick === "" ||
      nbrInsc === "" ||
      nbrReserv === "" ||
      nomAbon === ""
    ) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez compléter les champs obligatoires"
      })
      return
    }
    dispatchSnack({
      open: true,
      severity: "success",
      message: "Modifié avec succès"
    })
  }

  const handleCreateAbonnement = () => {
    const {
      name,
      short,
      description,
      prixAbonnement,
      numberSubscription,
      numberClick,
      numberBooking,
      taxe,
      percentOff,
      freeTrial,
      defaultSub
    } = abonnement;

    // Vérification des champs obligatoires
    if (
      !name ||
      !short ||
      prixAbonnement === null ||
      !numberSubscription ||
      !numberClick ||
      !numberBooking 
    ) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez compléter tous les champs obligatoires."
      });
      return;
    }

    // Création de l'abonnement
    createAbonnement({
      variables: {
        data: {
          name: name,
          short: short,
          description: description,
          prixAbonnement: Number(prixAbonnement),
          numberSubscription: Number(numberSubscription),
          numberClick: Number(numberClick),
          numberBooking: Number(numberBooking),
          taxe: Number(taxe),
          prixTtc: Number(totalPrice),
          percentOff: Number(percentOff),
          freeTrial: Number(freeTrial),
          defaultSub: Number(totalPrice) === 0 ? defaultSub : false
        }
      }
    })
      .then(() => {
        setOpen(false);
      })
      .catch(error => {
        // Gérer les erreurs de création d'abonnement
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Une erreur s'est produite lors de la création de l'abonnement."
        });
      });
  };

  return (
    <Box p={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          my: 2
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold" }}>
          Configuration abonnement
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Nom de l'abonnement
            </Typography>
            <Box>
              <TextField
                name="name"
                variant="outlined"
                fullWidth
                required
                value={abonnement?.name}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Nombre d'inscription
            </Typography>
            <Box>
              <TextField
                name="numberSubscription"
                variant="outlined"
                fullWidth
                required
                type="number"
                value={
                  abonnement?.numberSubscription ||
                    abonnement?.numberSubscription === 0
                    ? abonnement?.numberSubscription
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Nombre de Click n'collect
            </Typography>
            <Box>
              <TextField
                name="numberClick"
                variant="outlined"
                fullWidth
                required
                type="number"
                value={
                  abonnement?.numberClick || abonnement?.numberClick === 0
                    ? abonnement?.numberClick
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Nombre de réservation
            </Typography>
            <Box>
              <TextField
                name="numberBooking"
                variant="outlined"
                fullWidth
                required
                type="number"
                value={
                  abonnement?.numberBooking || abonnement?.numberBooking === 0
                    ? abonnement?.numberBooking
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Prix abonnement mensuel (HT)
            </Typography>
            <Box>
              <TextField
                name="prixAbonnement"
                variant="outlined"
                fullWidth
                required
                type="number"
                disabled={disabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  )
                }}
                value={
                  abonnement?.prixAbonnement || abonnement?.prixAbonnement === 0
                    ? abonnement?.prixAbonnement
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Réduction de prix
            </Typography>
            <Box>
              <TextField
                name="percentOff"
                variant="outlined"
                fullWidth
                required
                type="number"
                disabled={disabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
                value={
                  abonnement?.percentOff || abonnement?.percentOff === 0
                    ? abonnement?.percentOff
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Remise mensuel
            </Typography>
            <Box>
              <FormControl fullWidth>
                <Select
                  id="demo-simple-select"
                  value={(abonnement?.freeTrial as unknown as string) || ""}
                  disabled={disabled}
                  onChange={handleChangeSelect}
                >
                  <MenuItem value={0}>0 mois</MenuItem>
                  <MenuItem value={1}>1 mois</MenuItem>
                  <MenuItem value={2}>2 mois</MenuItem>
                  <MenuItem value={3}>3 mois</MenuItem>
                  <MenuItem value={4}>4 mois</MenuItem>
                  <MenuItem value={5}>5 mois</MenuItem>
                  <MenuItem value={6}>6 mois</MenuItem>
                  <MenuItem value={7}>7 mois</MenuItem>
                  <MenuItem value={8}>8 mois</MenuItem>
                  <MenuItem value={9}>9 mois</MenuItem>
                  <MenuItem value={10}>10 mois</MenuItem>
                  <MenuItem value={11}>11 mois</MenuItem>
                  <MenuItem value={12}>12 mois</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: "bold", my: 2 }}>
              Taxe (TVA)
            </Typography>
            <Box>
              <TextField
                name="taxe"
                variant="outlined"
                fullWidth
                required
                type="number"
                disabled={disabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
                value={
                  abonnement?.taxe || abonnement?.taxe === 0
                    ? abonnement?.taxe
                    : ""
                }
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              height: "100%"
            }}
          >
            <Typography variant="h2" component="div">
              Total TTC :
            </Typography>
            <Typography variant="h2" component="div" marginLeft={4}>
              {totalPrice} €
            </Typography>
          </Box>
        </Grid>
        {disabled && (
          <Grid item xs={12}>
            <Alert severity="warning">
              Suivant la politique de STRIPE, il est impossible de modifié les
              prix d'un abonnement!
            </Alert>
          </Grid>
        )}
      </Grid>
      <Box>
        <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
          Description courte
        </Typography>
        <Box>
          <Box>
            <TextField
              error={isLong}
              name="short"
              value={abonnement?.short}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              required
              helperText={
                isLong ? "le nombre de charactère maximal est de 150" : null
              }
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
            Description longue
          </Typography>
          <Box>
            <TextField
              name="description"
              value={abonnement?.description}
              onChange={handleChange}
              multiline
              fullWidth
              error={isLongLong}
              helperText={
                isLong ? "le nombre de charactère maximal est de 500" : null
              }
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 4
        }}
      >
        {editiedItem ? (
          <Button
            disabled={loadingUpdate}
            variant="contained"
            onClick={() => {
              updateAbonnement({
                variables: {
                  abonnementId: editiedItem.id,
                  data: {
                    name: abonnement.name,
                    short: abonnement.short,
                    description: abonnement.description,
                    prixAbonnement: Number(abonnement.prixAbonnement),
                    numberSubscription: Number(abonnement.numberSubscription),
                    numberClick: Number(abonnement.numberClick),
                    numberBooking: Number(abonnement.numberBooking),
                    taxe: Number(abonnement.taxe),
                    prixTtc: Number(totalPrice),
                    percentOff: Number(abonnement.percentOff),
                    freeTrial: Number(abonnement.freeTrial),
                    productStripeId: abonnement.productStripeId,
                    defaultSub: abonnement.defaultSub
                  }
                }
              })
                .then(() => {
                  setOpen(false);
                });
              updatedAbonnement()
            }}

            startIcon={
              loadingUpdate && (
                <CircularProgress style={{ width: 25, height: 25 }} />
              )
            }
          >
            Enregistrer
          </Button>
        ) : (
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleCreateAbonnement}
            startIcon={
              loading && (
                <CircularProgress style={{ width: 25, height: 25 }} />
              )
            }
          >
            Enregistrer
          </Button>
        )}
      </Box>
    </Box>
  )
}
