import * as React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { useApplicationContext } from "../../hooks"
import { UserMenus } from "./UserMenus"
import { Notifications } from "./Notifications"
import { DashboardNavbar } from "./dashboard-navbar"
import { DashboardSidebar } from "./dashboard-sidebar"
import { Loading } from "../loading"

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280
  }
}))

export const ContainerWithMenu: React.FC = ({ children }): JSX.Element => {
  const { logout, loadingApp } = useApplicationContext()
  const [isSidebarOpen, setSidebarOpen] = React.useState(true)

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            background: "#f8f8f8",
            minHeight: "92vh"
          }}
        >
          {loadingApp ? (
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
              }}
            >
              <Loading />
            </Box>
          ) : (
            children
          )}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar
        notification={<Notifications />}
        onSidebarOpen={() => setSidebarOpen(true)}
        userMenu={<UserMenus logout={logout} />}
      />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
      <Box>
        <CssBaseline />
      </Box>
    </>
  )
}
