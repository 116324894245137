/* eslint-disable no-nested-ternary */
import { useMutation } from "@apollo/client"
import { Box, Typography } from "@mui/material"
import { FC, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { Dropzone } from "../../../../components"

import {
  UpdateBusinessInfo,
  UpdateBusinessInfoVariables,
  UPDATE_BUSINESS_INFO
} from "../../../../graphql/business"
import { storage } from "../../../../utils"
import { FormContainer } from "../components/FormContainer"

interface SignupStepProps {
  admin?: boolean
  afterSave?: () => void
}
export const LogoAndCover: FC<SignupStepProps> = ({
  admin,
  afterSave
}): JSX.Element => {
  const [updateInfo, { loading }] = useMutation<
    UpdateBusinessInfo,
    UpdateBusinessInfoVariables
  >(UPDATE_BUSINESS_INFO)
  const { id } = useParams()
  const [logo, setLogo] = useState<string>()
  const [cover, setCover] = useState<string>()
  const [newId, setNewId] = useState<number>()
  const navigate = useNavigate()

  const handleCreate = async (): Promise<void> => {
    try {
      const create = await updateInfo({
        variables: {
          businessId: parseFloat(id as string) || (newId as number),
          business: {
            logo,
            coverPhoto: cover
          }
        }
      })
      if (create.data?.updateBusinessInfo.id) {
        if (afterSave) {
          Swal.fire({
            icon: "success",
            title: "Etablissement créé aves succés",
            text: "",
            showCancelButton: false,
            showConfirmButton: false
          })
          afterSave()
        } else {
          localStorage.removeItem("new_Id")
          localStorage.removeItem("step_2_tag")
          navigate(`/signup/abonnement/${id}`)
        }
      }
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const handleUploadLogo = (file?: string): void => {
    if (file) {
      setLogo(file)
    }
  }
  const handleUploadCover = (file?: string): void => {
    if (file) {
      setCover(file)
    }
  }

  const loadLocal = useCallback(() => {
    const nid = storage.get("new_Id")
    setNewId(parseFloat(nid))
  }, [])
  useEffect(() => {
    loadLocal()
  }, [loadLocal])
  return (
    <FormContainer
      step={8}
      loading={loading}
      title="Logo de votre établissement et image de couverture"
      onNext={handleCreate}
      btnLabel={admin ? "Terminer" : "Suivant"}
    >
      <Box sx={{ pb: 2 }}>
        <Typography>Ajouter le logo de votre établissment</Typography>
        <Box
          sx={{
            width: 150,
            height: 150,
            py: 1,
            position: "relative"
          }}
        >
          <Dropzone
            onFinished={handleUploadLogo}
            message="Ajouter logo"
            btnSx={{ height: "100%" }}
            photo={logo}
          />
        </Box>
        <Typography>
          Ajouter un photo de couverture de votre établissment
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: 200,
            py: 1,
            position: "relative"
          }}
        >
          <Dropzone
            onFinished={handleUploadCover}
            message="Ajouter couverture"
            btnSx={{ height: "100%" }}
            photo={cover}
          />
        </Box>
      </Box>
    </FormContainer>
  )
}
