import { createTheme } from "@mui/material"
import { COLOR } from "../utils/color"

const theme = createTheme({
  palette: {
    primary: {
      light: "#FECA00",
      main: "#FECA00",
      dark: "#edb900",
      contrastText: "#fff"
    },
    secondary: {
      light: "#8B44FF",
      main: "#8B44FF",
      dark: "#050005",
      contrastText: "#ffff"
    },
  },
  typography: {
    h1: {
      fontSize: 40,
      lineHeight: 0.8
    },
    h2: {
      fontSize: 35,
      fontWeight: "bold"
    },
    h3: {
      fontWeight: 400,
      fontSize: 28
    },
    h4: {
      fontSize: 26
    },
    h5: {
      fontSize: "1rem",
      fontWeight: "bold"
    },
    body1: {},
    body2: {},
    subtitle1: {
      fontWeight: "bold"
    },
    subtitle2: {
      color: COLOR.DARK_GREY
    }
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 50
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Sophia Pro", sans-serif'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          overflow: "visible"
        },
        elevation3: {
          boxShadow: "0 6px 18px 0 #ccc"
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: 8
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&:not(.Mui-focused, [data-shrink='true'])": {
            top: -6
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: 0,
            "& .MuiAutocomplete-input": {
              padding: 10
            }
          }
        },
        input: {
          padding: 10
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem"
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            ".MuiInputBase-input": {
              padding: "0px !important"
            },
            height: 43
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          overflow: "visible"
        }
      }
    }
  }
})

export default theme
