import { Box, Typography } from "@mui/material"
import { FC, useState, useEffect } from "react"

import moment from "moment"
import { OpeningHourUpdateInputType } from "@/types"
import { CustomSwitch } from "@/components/forms/CustomSwitch"
import { BusinessInfo_businessInfo_businessHours as IHours } from "@/graphql/business"
import TimePicker from "rc-time-picker"
import "rc-time-picker/assets/index.css"

interface HoraireType {
  id?: number
  day: string
  hours?: IHours
  open: boolean
}

interface HorairesInputProps {
  day: HoraireType
  onEdit: (item: OpeningHourUpdateInputType) => void
}

interface Test extends OpeningHourUpdateInputType {
  begin?: any
}

export const HorairesInput: FC<HorairesInputProps> = ({
  day,
  onEdit
}): JSX.Element => {
  const [openDay, setOpenDay] = useState<boolean>(false)
  const [values, setValues] = useState<Test>({
    day: "",
    name: "",
    begin: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    end: null,
    start: null
  })
  const handleChangeOpen = (checked: boolean) => {
    setOpenDay(!checked)
  }

  useEffect(() => {
    if (day) {
      setOpenDay(day.open)
      setValues((prev) => ({
        ...prev,
        id: day.id,
        day: day.day,
        start: day.hours?.start ? moment(day?.hours.start) : "",
        end: day.hours?.end ? moment(day?.hours.end) : ""
      }))
    }
  }, [day])

  return (
    <Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <Box sx={{ width: 200, mr: 2 }}>
          <CustomSwitch
            label={day.day}
            checked={openDay}
            onChange={(_v, _n, checked) => {
              if (checked) {
                setValues({
                  day: values.day,
                  start: null,
                  end: null,
                  id: values.id,
                  name: values.name
                })
                onEdit({
                  day: values.day,
                  start: null,
                  end: null,
                  id: values.id,
                  name: values.name
                })
              }
              handleChangeOpen(checked)
            }}
            name={day.day}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: openDay ? "flex" : "none",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              px: 2,
              pb: 2,
              display: "flex",
              alignItems: "center",
              "&:hover .actions": {
                opacity: 1
              }
            }}
          >
            <Box sx={{ px: 2 }}>
              <Typography variant="body2">ouverture</Typography>
              <TimePicker
                showSecond={false}
                value={values.start}
                name="start"
                defaultOpenValue={values.begin}
                allowEmpty={false}
                onChange={(e) => {
                  setValues({
                    ...values,
                    start: e
                  })
                }}
                onClose={() =>
                  onEdit({
                    day: values.day,
                    start: values.start,
                    end: values.end,
                    id: values.id,
                    name: values.name
                  })
                }
              />
            </Box>
            <Box>
              <Typography variant="body2">fermeture</Typography>
              <TimePicker
                showSecond={false}
                value={values.end}
                name="end"
                defaultOpenValue={values.begin}
                allowEmpty={false}
                onChange={(e) => {
                  setValues({
                    ...values,
                    end: e
                  })
                }}
                onClose={() =>
                  onEdit({
                    day: values.day,
                    start: values.start,
                    end: values.end,
                    id: values.id,
                    name: values.name
                  })
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
