import { gql } from "@apollo/client"

export const CREATE_QRCODE = gql`
  mutation CreateQRCode(
    $data: QRCodeInput!
    $reglementInput: ReglementInput!
    $businessId: Float!
  ) {
    createQRCode(
      data: $data
      reglementInput: $reglementInput
      businessId: $businessId
    )
  }
`

export const UPDATE_QRCODE = gql`
  mutation UpdateQRCode($data: QRCodeInput!, $qrcodeId: Float!) {
    updateQRCode(data: $data, qrcodeId: $qrcodeId)
  }
`

export const REMOVE_QRCODE = gql`
  mutation RemoveQRCode($qrcodeId: Float!) {
    removeQRCode(qrcodeId: $qrcodeId)
  }
`

export const UPDATE_WINNER = gql`
  mutation UpdateWinnerLot($updateWinnerLotId: Float!, $status: Boolean!) {
    updateWinnerLot(id: $updateWinnerLotId, status: $status)
  }
`
