import { useParams } from "react-router-dom"
import { CSSProperties, useEffect, useState } from "react"
import { Typography, Box, Grid, Card, Button } from "@mui/material"
import { useMutation } from "@apollo/client"
import {
  BUSINESS_HOURS,
  UpdateBusinessHours,
  UpdateBusinessHoursVariables,
  UpdateBusinessInfo,
  UpdateBusinessInfoVariables,
  UPDATE_BUSINESS_INFO
} from "@/graphql/business"
import { useApplicationContext, useCurrentBusiness } from "@/hooks"
import { CustomModal } from "@/components/modals"
import { TextInputBase } from "@/components/forms"
import { CustomTextInput } from "@/components/forms/CustomTextInput"
import { Horaires } from "@/components/Etablissement/Horaires/Horaires"
import { CustomEditPhoneInput } from "@/components/PhoneInput/CustomEditPhoneInput"
import { AddressInputEdit } from "@/components/AddressInput/AddressInput"
import { CategorieActiviteInput } from "@/components/CategorieActiviteInput"
import { BusinessUpdateInput, OpeningHourUpdateInputType } from "@/types"
import Swal from "sweetalert2"
import { Dropzone } from "@/components/dropzone"

interface ListHour {
  list: OpeningHourUpdateInputType[] | undefined
}


export const EtablissementInformations = (): JSX.Element => {
  const { dispatchSnack } = useApplicationContext()
  const [open, setOpen] = useState(false)
  const { business, loading, inputHour, setInputHour } = useApplicationContext()
  const { id } = useParams()
  const { data, refetch } = useCurrentBusiness(
    business?.id || parseFloat(id as string)
  )

  const [updateInfo, { error: updateError }] = useMutation<
    UpdateBusinessInfo,
    UpdateBusinessInfoVariables
  >(UPDATE_BUSINESS_INFO)

  const [form, setForm] = useState<BusinessUpdateInput>({
    businessName: data?.businessName,
    phone: data?.phone,
    siret: data?.siret,
    activity: data?.activity,
    address: data?.address,
    description: data?.description,
    email: data?.email,
    paypalImg: data?.paypalImg,
    urlPaypalCount: data?.urlPaypalCount,
	countryCode: data?.countryCode
  })

  const [updateHours] = useMutation<
    UpdateBusinessHours,
    UpdateBusinessHoursVariables
  >(BUSINESS_HOURS)

  useEffect(() => {
    if (updateError?.message) {
      Swal.fire({
        icon: "error",
        title: updateError?.message,
        text: "",
        showConfirmButton: false,
        showCloseButton: true
      })
    }
  }, [updateError])

  const handleSaveHorraire = async (value: ListHour): Promise<void> => {
    const reverseArr = value.list?.reverse()
    const filteredArr = reverseArr?.reduce(
      (
        acc: OpeningHourUpdateInputType[],
        current: OpeningHourUpdateInputType
      ) => {
        const x = acc.find(
          (item: OpeningHourUpdateInputType) => item.day === current.day
        )
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      },
      []
    )

    try {
      await updateHours({
        variables: {
          businessId: business?.id || parseFloat(id as string),
          businessHours: {
            list: filteredArr
          }
        }
      })
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve("")
        }, 1000)
      })
      return undefined
    } catch (errors) {
      return undefined
    }
  }

  const handleSaveInfo = async (): Promise<void> => {
    const businessName = form.businessName
    const address = form.address
    const email = form.email
    const description = form.description

    const phone = form.phone
    const siret = form.siret
    const activity = form.activity
    const paypalUrl = form.urlPaypalCount


    if(
      businessName === "" ||
      !phone ||
      siret === "" ||
      activity === "" ||
      address === "" ||
      description === "" ||
      email === ""
      ){
        
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Veuillez compléter tous les champs obligatoires."
        });
        return;
      }
    else if (paypalUrl && typeof paypalUrl === 'string') {
      const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w.-]*)*\/?$/
      const urlTrue = urlRegex.test(paypalUrl);
    
      if (!urlTrue) {
        
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Veuillez vérifier l'URL du Paypal"
        });
      }
      else{
        Swal.showLoading()
        await updateInfo({
          variables: {
            businessId: business?.id || parseFloat(id as string),
            business: form
          }
        })
        handleSaveHorraire({
          list: inputHour || []
        })
        Swal.fire({
          icon: "success",
          title: "Votre modification enregistré",
          text: "",
          showConfirmButton: false,
          showCloseButton: true,
          willClose: () => {
            setInputHour([])
          }
        })
        refetch()
        return undefined
      }
    }
    else if(email && typeof email === "string"){
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      const urlTrue = emailRegex.test(email)

      if (!urlTrue) {
        dispatchSnack({
          open: true,
          severity: "error",
          message: "Veuillez vérifier l'email saisi"
        })
      }
      else{
        Swal.showLoading()
        await updateInfo({
          variables: {
            businessId: business?.id || parseFloat(id as string),
            business: form
          }
        })
        handleSaveHorraire({
          list: inputHour || []
        })
        Swal.fire({
          icon: "success",
          title: "Votre modification enregistré",
          text: "",
          showConfirmButton: false,
          showCloseButton: true,
          willClose: () => {
            setInputHour([])
          }
        })
        refetch()
        return undefined
      }
    } 
    else{
      
      Swal.showLoading()
      await updateInfo({
        variables: {
          businessId: business?.id || parseFloat(id as string),
          business: form
        }
      })
      handleSaveHorraire({
        list: inputHour || []
      })
      Swal.fire({
        icon: "success",
        title: "Votre modification enregistré",
        text: "",
        showConfirmButton: false,
        showCloseButton: true,
        willClose: () => {
          setInputHour([])
        }
      })
      refetch()
      return undefined
    }
  }
  const handleSetState = (value: string, name: string, countryCode?: string) => {
    setForm({
      ...form,
      [name]: value,
	  countryCode
    })
  }

  const containerStyle: CSSProperties = {
    backgroundColor: "white",
    borderRadius: "4px"
  }

  const loadingStyle: CSSProperties = loading
    ? {
        opacity: 0.3,
        pointerEvents: "none"
      }
    : {}

  const handleSetQrCode = (file?: string, name?: string) => {
    setForm((prev) => ({
      ...prev,
      paypalImg: file
    }))
  }

  return (
    <>
      <CustomModal title="update" open={open} setOpen={setOpen}>
        <Box>
          <form>
            <Box>
              <Box>
                <TextInputBase
                  label="Nom du site"
                  name="name"
                  variant="filled"
                />
              </Box>
            </Box>
          </form>
        </Box>
      </CustomModal>
      <Box sx={{ ...loadingStyle, ...containerStyle }}>
        <Grid container sx={{ py: 3 }}>
          <Grid item md={6}>
            <Card sx={{ p: 1, px: 2, height: "100%" }} elevation={0}>
              <Typography variant="subtitle1">
                Nom de l'établissement
              </Typography>
              <CustomTextInput
                editable
                nodivider
                defaultValue={data?.businessName || ""}
                name="businessName"
                onSave={handleSetState}
              />
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card sx={{ p: 1, px: 2, height: "100%" }} elevation={0}>
              <Box>
                <Typography variant="subtitle1">
                  Page Tagdiscount de l'établissement
                </Typography>
                <a
                  href={`https://${data?.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography>{`https://${data?.url}` || ""}</Typography>
                </a>
              </Box>
            </Card>
          </Grid>

          <Grid item md={6}>
            <Card sx={{ p: 1, px: 2, height: "100%" }} elevation={0}>
              <Box>
                <Typography variant="subtitle1">
                  Adresse de l'établissement:
                </Typography>

                <AddressInputEdit
                  nodivider
                  defaultValue={data?.address || ""}
                  name="address"
                  onSave={handleSetState}
                />
              </Box>
            </Card>
          </Grid>

          <Grid item md={6}>
            <Card
              sx={{ p: 1, px: 2, overflow: "visible", height: "100%" }}
              elevation={0}
            >
              <Box>
                <Typography variant="subtitle1">Téléphone</Typography>

                <CustomEditPhoneInput
                  nodivider
                  defaultValue={data?.phone || ""}
                  name="phone"
                  onSave={handleSetState}
                />
              </Box>
            </Card>
          </Grid>

          <Grid item md={6}>
            <Card sx={{ p: 1, px: 2, height: "100%" }} elevation={0}>
              <Box>
                <Typography variant="subtitle1">Siret</Typography>
                <CustomTextInput
                  nodivider
                  defaultValue={data?.siret || ""}
                  name="siret"
                  onSave={handleSetState}
                />
              </Box>
            </Card>
          </Grid>

          <Grid item md={6}>
            <Card sx={{ p: 1, px: 2, height: "100%" }} elevation={0}>
              <Box>
                <Typography variant="subtitle1">
                  Catégorie d'activités
                </Typography>
                <CategorieActiviteInput
                  editable
                  nodivider
                  name="activity"
                  onSave={handleSetState}
                  defaultValue={data?.activity || ""}
                />
              </Box>
            </Card>
          </Grid>

          <Grid item md={6}>
            <Box sx={{ p: 1, px: 2 }}>
              <Typography variant="subtitle1">Email</Typography>
              <CustomTextInput
                nodivider
                defaultValue={data?.email || ""}
                name="email"
                onSave={handleSetState}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ p: 1, px: 2 }} elevation={0}>
              <Box>
                <Typography variant="subtitle1">
                  Description de l'établissement
                </Typography>
                <CustomTextInput
                  nodivider
                  multiline
                  defaultValue={data?.description || ""}
                  name="description"
                  onSave={handleSetState}
                />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ p: 1, px: 2 }} elevation={0}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">
                    Horaire d&apos;ouverture
                    <hr />
                  </Typography>
                  <Box sx={{ py: 2 }}>
                    <Horaires />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">
                    Payement Paypal (QRcode) (taille maximum: 500ko)
                    <hr />
                  </Typography>
                  <Box>
                    <Dropzone
                      message="Ajouter JPEG ou PNG"
                      onFinished={handleSetQrCode}
                      btnSx={{ height: "100%" }}
                    />
                  </Box>
                  {(form?.paypalImg || data?.paypalImg) && (
                    <Box sx={{ my: 1 }}>
                      <img
                        src={form?.paypalImg || data?.paypalImg || ""}
                        alt="qrCode"
                        style={{width: "100%", objectFit: "contain"}}
                      />
                    </Box>
                  )}
                  <Box>
                    <Typography variant="subtitle1">
                      Url de payment par paypal(Paypal.Me)
                    </Typography>
                    <CustomTextInput
                      nodivider
                      defaultValue={data?.urlPaypalCount || ""}
                      name="urlPaypalCount"
                      onSave={handleSetState}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
        <Button variant="contained" onClick={handleSaveInfo} sx={{ color: "#050005" }}>
          Enregistrer les modifications
        </Button>
      </Box>
    </>
  )
}
