import { useQuery } from "@apollo/client"
import { useEffect } from "react"

import {
  CategoryById,
  CategoryByIdVariables,
  BUSINESS_CATEGORY_BY_ID
} from "../graphql/business"

export const useCurrentCategoryById = (id?: number) => {
  const { data, loading, refetch } = useQuery<
    CategoryById,
    CategoryByIdVariables
  >(BUSINESS_CATEGORY_BY_ID, {
    variables: {
      categoryId: id as number
    },
    skip: !id
  })
  useEffect(() => {
    if (id && !data) {
     refetch()
    }
   }, [id])
  return {
    data: data?.categoryById,
    loading,
    refetch
  }
}
