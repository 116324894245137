import { loadStripe } from "@stripe/stripe-js"
import {
  Elements,
} from "@stripe/react-stripe-js"
import { PayementForm } from "./component/PayementForm"

const stripe_key = process.env.REACT_APP_STRIPE_KEY || ""
const stripePromise = loadStripe(stripe_key)

interface PayementProps {
  client_secret?: string
}

export const Payement = ({ client_secret }: PayementProps) => {
  const clientSecret = localStorage.getItem("client_secret") || client_secret || ""
  const options = {
    clientSecret
  }
  return (
    <Elements stripe={stripePromise} options={options}>
      <PayementForm />
    </Elements>
  )
}
