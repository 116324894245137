export const Translate = {
  ADMIN_CHANGE_PASSWORD: "Modification de mot de passe (administrateur)",
  ADMIN_CREATE_TRADING: "Création de commerce (administrateur)",
  ADMIN_CREATE_EVNET: "Création d'évènement (administrateur et commerçant)",
  ADMIN_CHANGE_SUBSCRIPTION: "Changement d'abonnement (administrateur)",
  ADMIN_CREATE_SUBSCRIPTION:
    "Création d'abonnement (administrateur et commerçant)",
  ADMIN_BILLING: "Facturation (administrateur)",
  ADMIN_EDIT_CLICK: "Info click'n collect (administrateur)",
  ADMIN_STATUS_ORDER: "Activation/désactivation de commande",
  TRADER_NEW_USER: "Nouvel utilisateur (commerçant)",
  TRADER_EDIT_USER: "Modification utilisateur (commerçant)",
  TRADER_EDIT_CLICK: "Info click'n collect (commerçant)",
  TRADER_STATUS_ORDER: "Info commande (commerçant)",
  TRADER_NEW_CUSTOMER: "Nouveau client (commerçant)",
  TRADER_NEW_ORDER: "Nouvelle commande (commerçant)",
  TRADRE_VALIDATE_ORDER: "Commande validé (commerçant et client)",
  TRADER_REJECT_ORDER: "Commande rejeté (commerçant et client)",
  TRADER_ARCHIV_ORDER: "Commande archivé (commerçant)",
  TRADER_END_ORDER: "Commande terminé (commerçant)",
  TRADER_NEW_BOOKING: "Nouvelle réservation (commerçant)",
  TRADER_VALIDATE_BOOKING: "Réservation validé (commerçant et client)",
  TRADER_REJECT_BOOKING: "Réservation rejeté (commerçant et client)",
  TRADER_ARCHIV_BOOKING: "Réservation archivé (commerçant)",
  TRADER_STATUS_CUSTOMER: "Info client (commerçant)",
  TRADER_NEW_GAME: "Création de jeu (commerçant)",
  TRADER_NEW_TEMPLATE: "Nouveau template (commerçant)",
  TRADER_END_GAME: "Expiration de jeu (commerçant)",
  TRADER_STATUS_GAIN: "Info gain (commerçant)",
  TRADER_CHANGE_SUBSCRIPTION: "Changement d'abonnement (commerçant)",
  TRADER_QUOTA: "Info quotation (commerçant)",
  TRADER_EDIT_PASSWORD: "Edition de mot de passe (commerçant)",
  TRADER_EDIT_PROFILE: "Edition de profile (commerçant)",
  TRADER_BUSINESS_CREATE: "Création de commerce (commerçant)",
  CUSTOMER_STATUS_ORDER: "Info commande (client)",
  CUSTOMER_STATUS_BOOKING: "Info réservation (client)",
  CUSTOMER_REGISTER: "Inscription (client)",
  CUSTOMER_REGISTER_GAME: "Inscription via jeu (client)",
  CUSTOMER_STATUS_GAME: "Info jeu (client)",
  CUSTOMER_NEW_USER: "Ajout nouvel utilisateur (client)",
  CUSTOMER_SIGNIN: "Nouvel enregistrement (client)",
  CUSTOMER_VALIDATE_BOOKING: "Réservation validé (client)",
  CUSTOM_VALIDATE_ORDER: "Commande validé (client)",
  CUSTOMER_RESET_PASSWORD: "Mot de passe oublié"
}
