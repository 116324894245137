import React, { useState, FC, FormEvent } from "react"
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  CircularProgress
} from "@mui/material"
import { Dropzone } from "../../dropzone"
import { AdminTypeInput, CreateUtilisateurInput } from "../../../types"
import { Password, Photo } from "@mui/icons-material"
import { useApplicationContext } from "@/hooks"

interface UserFormProps {
  loading?: boolean
  onCancel?: () => void
  onCreate?: (values: CreateUtilisateurInput) => void
}

export const UserForm: FC<UserFormProps> = ({
  loading,
  onCreate,
  onCancel
}): JSX.Element => {
  const [values, setValues] = useState<CreateUtilisateurInput>({
    email: "",
    firstname: "",
    password: "",
    lastname: "",
    photo: "",
    type: AdminTypeInput.BUSINESS_ADMIN
  })

  const { dispatchSnack } = useApplicationContext()
  const handleSubmit = (e: FormEvent) => {
      
        e.preventDefault()
        if(!values.email ||
          !values.firstname ||
          !values.lastname ||
          !values.password
         ){
          dispatchSnack({
            open: true,
            severity: "error",
            message:
              "Veuillez compléter les champs obligatoires"
          })
         }
         else{
          onCreate && onCreate(values)
          setValues({
            email: "",
            firstname: "",
            password: "",
            lastname: "",
            photo: "",
            type: AdminTypeInput.BUSINESS_ADMIN
          })
         }
  }
  const handleUploadPicture = (file?: string) => {
    if (file) {
      setValues((prev) => ({
        ...prev,
        photo: file
      }))
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const { name, value } = event.target
    setValues((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12}>
              <Box sx={{ mb: 3, width: 150, margin: "auto" }}>
                <Dropzone
                  onFinished={handleUploadPicture}
                  message="Ajouter profil"
                  btnSx={{ height: 150 }}
                  photo={ values?.photo || ""}
                />
              </Box>
            </Grid>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Nom"
                  type="text"
                  name="firstname"
                  value={values.firstname}
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Prénom"
                  name="lastname"
                  value={values.lastname}
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  name="email"
                  value={values.email}
                  type="email"
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Mot de passe"
                  fullWidth
                  name="password"
                  type="password"
                  value={values.password}
                  sx={{ mb: 3 }}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1">Rôle</Typography>

            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label" />
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={values.type}
                name="type"
                onChange={handleChange}
              >
                <FormControlLabel
                  value="BUSINESS_ADMIN"
                  control={<Radio />}
                  label="Administrateur"
                />
                <FormControlLabel
                  value="BUSINESS_USER"
                  control={<Radio />}
                  label="Utilisateur"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={() => onCancel && onCancel()}
          >
            Anuller
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            startIcon={
              loading ? (
                <CircularProgress style={{ width: 25, height: 25 }} />
              ) : null
            }
          >
            Ajouter
          </Button>
        </Box>
      </Box>
    </form>
  )
}
