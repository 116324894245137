/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Typography,
  Button,
  styled,
  FormGroup,
  CircularProgress,
  FormControl,
  Radio,
  RadioGroup,
  FormLabel,
  Card,
  FormControlLabel,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material"
import { FC, useState } from "react"
import TextField from "@mui/material/TextField"
import PhoneInput from "react-phone-input-2"
import { CustomModal } from "../../../components"
import {
  ClientStatusType,
  CreateClientInput,
  SourceEnumType,
  StatusEnumType
} from "../../../types"
import { Controller, useForm } from "react-hook-form"

export const PhoneContainer = styled(Box)(() => ({
  "& .react-tel-input .form-control": {
    width: "100%",
    paddingBlock: 10
  }
}))

interface AddContactProps {
  open: boolean
  loading?: boolean
  setOpen: (b: boolean) => void
  onAdd: (values: CreateClientInput) => void
}

export const AddContact: FC<AddContactProps> = ({
  open,
  loading,
  setOpen,
  onAdd
}): JSX.Element => {
  const [typeVal, setTypeVal] = useState('PARTICULIER')
  const { register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm<CreateClientInput>({
    defaultValues: {
      address: "",
      email: "",
      password: "",
      city: "",
      civilite: "",
      codePostal: "",
      firstname: "",
      lastname: "",
      phone: "",
      photo: "",
      source: SourceEnumType.MANUAL,
      acceptEmail: true,
      acceptSms: true,
      status: StatusEnumType.CLIENT,
      type: ClientStatusType.PARTICULIER,
      keywords: "",
      raisonSocial: "",
      function: ""
    }
  })

  const onSubmit = (data: CreateClientInput) => {
    onAdd(data)
    reset({
      address: "",
      email: "",
      password: "",
      city: "",
      civilite: "",
      codePostal: "",
      firstname: "",
      lastname: "",
      phone: "",
      photo: "",
      source: SourceEnumType.MANUAL,
      acceptEmail: true,
      acceptSms: true,
      status: StatusEnumType.CLIENT,
      type: ClientStatusType.PARTICULIER,
      keywords: "",
      raisonSocial: "",
      function: ""
    })
  }

  return (
    <CustomModal
      noAction
      title="Ajouter nouveau contact"
      open={open}
      setOpen={setOpen as any}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ fontWeight: "bold", my: 2 }}>
          Information personnelles
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ marginBottom: "15px" }}>
            <TextField
              required
              error={errors.firstname ? true : false}
              label="Prénom"
              variant="outlined"
              {...register("firstname", { required: true })}
              helperText={errors.firstname && "Ce champ est requis"}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Nom"
              required
              error={errors.lastname ? true : false}
              fullWidth
              variant="outlined"
              {...register("lastname", { required: true })}
              helperText={errors.lastname && "Ce champ est requis"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ marginBottom: "15px" }}>
            <FormControl fullWidth>
              <InputLabel id="civilite">Civilité</InputLabel>
              <Select
                labelId="civilite"
                label="Civilité"
                {...register("civilite")}
              >
                <MenuItem value="mr">Mr.</MenuItem>
                <MenuItem value="mme">Mme.</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Fonction"
              variant="outlined"
              {...register("function")}
            />
          </Grid>
        </Grid>

        <Typography sx={{ fontWeight: "bold", mt: 2, mb: 3 }}>
          Coordonnées
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
          <Grid item xs={6} sx={{ width: "100%" }}>
            <TextField
              required
              error={errors.email ? true : false}
              label="E-mail"
              variant="outlined"
              {...register("email", {
                required: { value: true, message: "Champ requis" },
                pattern: {
                  value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Entrer un email valide"
                }
              })}
              helperText={errors.email?.message}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6} spacing={2}>
            <PhoneContainer>
              <Controller
                control={control}
                name="phone"
                rules={{ required: true }}
                render={({ field: { ref, value, ...field } }) => (
                  <PhoneInput
                    {...field}
                    enableSearch
                    inputProps={{
                      ref,
                      required: true,
                      autoFocus: true
                    }}
                    country="fr"
                    value={value}
                    preferredCountries={["fr", "us"]}
                  />
                )}
              />
            </PhoneContainer>
            {errors.phone && (
              <Box sx={{ color: "red" }}>Ce champ est requis</Box>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Addresse"
              variant="outlined"
              {...register("address")}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Code Postal"
              variant="outlined"
              {...register("codePostal")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Ville"
              variant="outlined"
              {...register("city")}
            />
          </Grid>
        </Grid>

        <Typography sx={{ fontWeight: "bold", mt: 2, mb: 3 }}>
          Information de gestion
        </Typography>
        <Box sx={{ my: 2 }}>
          <Card sx={{ p: 2 }} elevation={3}>
            <Typography variant="subtitle1">Type</Typography>
            <FormControl>
              <Controller
                name="type"
                rules={{ required: true }}
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <RadioGroup
                    ref={ref}
                    value={value}
                    {...field}
                    onChange={(e) => setTypeVal(e.target.value)}
                  >
                    <FormControlLabel
                      checked={typeVal === ClientStatusType.PARTICULIER}
                      value={ClientStatusType.PARTICULIER}
                      control={<Radio />}
                      label="Particulier"
                    />
                    <FormControlLabel
                      checked={typeVal === ClientStatusType.PRO}
                      value={ClientStatusType.PRO}
                      control={<Radio />}
                      label="Professionnel"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Card>
        </Box>

        {
          typeVal === "PARTICULIER" && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                label="Raison sociale"
                variant="outlined"
                fullWidth
                {...register("raisonSocial")}
              />
            </Box>
          )
        }


        <Box sx={{ my: 2 }}>
          <Card sx={{ p: 2 }} elevation={3}>
            <Typography variant="subtitle1">Statut</Typography>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label" />
              <Controller
                name="status"
                rules={{ required: true }}
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    {...field}
                    ref={ref}
                    value={value}
                  >
                    <FormControlLabel
                      value={StatusEnumType.CLIENT}
                      control={<Radio />}
                      label="Client"
                    />
                    <FormControlLabel
                      value={StatusEnumType.VIP}
                      control={<Radio />}
                      label="V.I.P"
                    />
                    <FormControlLabel
                      value={StatusEnumType.PROSPECT}
                      control={<Radio />}
                      label="Prospect"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Card>
        </Box>

        <Box>
          <FormGroup sx={{ display: "flex", justifyContent: "space-between" }}>
            <Controller
              name="acceptEmail"
              control={control}
              render={({ field: { ref, value, ...field } }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...field} ref={ref} checked={value || false} />
                  }
                  label="Inscription aux campagnes email"
                />
              )}
            />
            <Controller
              name="acceptSms"
              control={control}
              render={({ field: { ref, value, ...field } }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...field} ref={ref} checked={value || false} />
                  }
                  label="Inscription aux campagnes SMS"
                />
              )}
            />
          </FormGroup>
        </Box>
        <Typography variant="subtitle1">Mots-Clés</Typography>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Mots Clés"
            variant="outlined"
            fullWidth
            {...register("keywords")}
          />
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            startIcon={
              loading ? (
                <CircularProgress style={{ width: 25, height: 25 }} />
              ) : null
            }
          >
            Ajouter
          </Button>
        </Box>
      </form>
    </CustomModal>
  )
}
