import LinearProgress from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { FC, SyntheticEvent } from "react"
import { Button, CircularProgress } from "@mui/material"

interface FormContainerProps {
  disableBtn?: boolean
  loading?: boolean
  title?: string
  step?: number
  onNext?: () => void
  onIgnore?: () => void
  btnLabel?: string
  children?: React.ReactNode
}
export const FormContainer: FC<FormContainerProps> = ({
  loading,
  disableBtn,
  title,
  step,
  btnLabel,
  onNext,
  onIgnore,
  children
}): JSX.Element => {
  const handleNext = (e: SyntheticEvent): void => {
    e.preventDefault()
    onNext && onNext()
  }
  const handleIgnore = (e: SyntheticEvent): void => {
    e.preventDefault()
    onIgnore && onIgnore()
  }
  
  return (
    <Box >
      <Box>
        <Typography sx={{ py: 1 }}>Créer le profil</Typography>
        <LinearProgress
          variant="determinate"
          value={step ? step * 12.5 : 12.5}
        />
      </Box>
      <Box sx={{ py: 1 }}>
        <Typography variant="subtitle1" sx={{ fontSize: 20 }}>
          {title}
        </Typography>
      </Box>
      {children}
      <Box>
        {onIgnore ? (
          <Button variant="outlined" onClick={handleIgnore} sx={{ mr: 1 }}>
            Ignorer
          </Button>
        ) : null}
        <Button
          disabled={loading || disableBtn}
          startIcon={
            loading ? (
              <CircularProgress style={{ width: 25, height: 25 }} />
            ) : null
          }
          variant="contained"
          onClick={handleNext}
          sx={{ color: "#050005" }}
        >
          {btnLabel || "Suivant"}
        </Button>
      </Box>
    </Box>
  )
}
