import {
  TextField,
  Typography,
  Box,
  Grid,
  CardContent,
  Button,
  Card,
  Select,
  MenuItem,
  Switch,
  FormControl,
  Divider,
  IconButton
} from "@mui/material"
import React, { useState, FormEvent, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Dropzone } from "@/components/dropzone"
import {
  BackgroundType,
  BGType,
  BoutonShopType,
  PaletteInput,
  TemplateInput,
  ThemeInput
} from "@/types"
import {
  useCreateTemplateSpinner,
  useTemplateGlobal,
  useTemplateSpinner,
  useTemplateSpinnerById,
  useUpdateTemplateSpinner
} from "@/hooks/templateSpinner"
import { font } from "@/utils"
import Swal from "sweetalert2"
import { ColorPicker } from "@/components/forms/ColorPicker"
import { useApplicationContext } from "@/hooks"
import { ModalTemplateChoice } from "./ModalTemplateChoice"
import { CardSpinner } from "./CardSpinner"
import { Delete } from "@mui/icons-material"
import { ContainerWithFooter } from "@/pages/business/Personnalization/CustomPage/Components/layouts/ContainerWithFooter"
import {
  convertEnumBs,
  Profil
} from "@/pages/business/Personnalization/CustomPage"

export const TemplateGames = () => {
  const { idTemplate } = useParams()
  const navigate = useNavigate()
  const { createTemplateSpinner } = useCreateTemplateSpinner()
  const { data, refetch: refetchData } = useTemplateSpinnerById(
    parseFloat(idTemplate as string)
  )
  const { updateSpinner } = useUpdateTemplateSpinner()
  const { refetch } = useTemplateSpinner()
  const { refetch: refetchGlobal } = useTemplateGlobal()
  const { business } = useApplicationContext()

  const [values, setValues] = useState<TemplateInput>({
    name: "Template de base",
    description: "Utiliser en tant que template de base",
    bgColor: "#0004",
    bgImage: "",
    title: "",
    subtitle: "",
    textColor: "#000",
    spinItemMainColor: "",
    fieldName: true,
    btnBg: "",
    btnTextColor: "",
    fieldEmail: true,
    fieldEmailPlaceholder: "Votre e-mail",
    fieldNamePlaceholder: "Votre nom",
    fieldPhonePlaceholder: "Votre numéro",
    fieldCondition: true,
    fieldPrivacy: true,
    instructionText: "",
    fontFamily: "",
    privacyText: "J'accepte la politique de confidentialité",
    btnText: "Jouer",
    conditionLink: "",
    conditionText: "J'accepte la condition d'utilisation",
    thankText: "Merci !",
    isSavedAsGlobal: true,
    winMessageTitle: "Felicitation vous avez gagner",
    winMessageCode: "Voici votre code pour récupérer votre lot :",
    winPictures: "",
    loseMessageTitle: "",
    loseMessage: "",
    losePictures: "",
    copyCodeText: "Veuillez copier votre code",
    winCloseBtnText: "Fermer"
  })

  const [theme, setTheme] = useState<ThemeInput>({
    colorBtn: "#FE724C",
    colorText: "#FFFFFF",
    colorBtnText: "#FFFFFF",
    mediaColor: "#e63946",
    colorCardProduct: "#FFFFFF",
    colorTextCardProduct: "#000000",
    bsType: BoutonShopType.CIRCULAR,
    fontSize: "14px",
    police: "Poppins"
  })

  const [palette, setPalette] = useState<PaletteInput>({
    bgColor: "#FFFFFF",
    bgType: BackgroundType.COLOR_LINEAR
  })

  const [profil, setProfil] = useState<Profil>()

  const convertEnum = (bgType: BGType) => {
    return BackgroundType[BGType[bgType] as keyof typeof BackgroundType]
  }

  useEffect(() => {
    if (business?.BusinessTheme && business?.BusinessTheme.bsType) {
      setTheme({
        colorBtn: business?.BusinessTheme.colorBtn,
        colorText: business?.BusinessTheme.colorText,
        colorBtnText: business?.BusinessTheme.colorBtnText,
        mediaColor: business?.BusinessTheme.mediaColor,
        colorCardProduct: business?.BusinessTheme.colorCardProduct,
        colorTextCardProduct: business?.BusinessTheme.colorTextCardProduct,
        bsType: convertEnumBs(business?.BusinessTheme.bsType),
        fontSize: business?.BusinessTheme.fontSize,
        police: business?.BusinessTheme.police
      })
    }
    if (business?.BusinessPalette && business?.BusinessPalette.bgType) {
      setPalette({
        bgType: convertEnum(business?.BusinessPalette.bgType),
        bgColor: business?.BusinessPalette.bgColor,
        bgColor1: business?.BusinessPalette.bgColor1,
        bgColor2: business?.BusinessPalette.bgColor2,
        bgImage: business?.BusinessPalette.bgImage,
        bgViedo: business?.BusinessPalette.bgViedo
      })
    }
    if (business?.logo && business?.coverPhoto) {
      setProfil({
        logo: business?.logo,
        coverPhoto: business?.coverPhoto
      })
    }
  }, [business])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === "bgColor") {
      setValues((prev) => ({
        ...prev,
        ...{ bgImage: "" },
        [name]: value
      }))
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: value
      }))
    }
  }
  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      fieldPhone: event.target.checked
    })
  }

  const handleChangeCondition = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({
      ...values,
      fieldCondition: event.target.checked
    })
  }

  const handleChangePrivateText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({
      ...values,
      fieldPrivacy: event.target.checked
    })
  }

  const handleChangeNom = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      fieldName: event.target.checked
    })
  }

  const handleChangeSpinColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({
      ...prev,
      spinItemMainColor: e.target.value
    }))
  }

  const handleChangeBg = (url?: string) => {
    if (url) {
      setValues((prev) => ({
        ...prev,
        bgImage: url
      }))
    }
  }

  const handleWinPictures = (url?: string) => {
    if (url) {
      setValues((prev) => ({
        ...prev,
        winPictures: url
      }))
    }
  }

  const handleLosePictures = (url?: string) => {
    if (url) {
      setValues((prev) => ({
        ...prev,
        losePictures: url
      }))
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const createTemplate = await createTemplateSpinner({
      ...values,
      isSavedByOwner: Boolean(business?.id),
      isSavedAsGlobal: !Boolean(business?.id),
      businessId: business?.id
    })
    if (values.btnText === "" || values.name === "") {
      Swal.fire({
        icon: "error",
        title: "Veuillez compléter les champs obligatoires",
        text: ""
      })
    } else {
      if (createTemplate.success) {
        Swal.fire({
          icon: "success",
          title: "Ajouté avec succès",
          text: ""
        })
        if (business?.id) {
          await refetch({
            businessId: business?.id
          })
        } else {
          refetchGlobal()
        }
        navigate(-1)
      }
    }
  }

  const handleUpdates = async () => {
    if (!idTemplate) return
    const updateTemplate = await updateSpinner(
      parseFloat(idTemplate as string),
      values
    )
    if (updateTemplate) {
      Swal.fire({
        icon: "success",
        title: "Modification réussie",
        text: "",
        showConfirmButton: false,
        showCloseButton: true
      })

      if (business?.id) {
        await refetch({
          businessId: business?.id
        })
        await refetchData({
          templateId: parseFloat(idTemplate)
        })
      } else {
        refetchGlobal()
      }
      navigate(-1)
    }
  }

  const onChoose = (item: TemplateInput) => {
    setValues({
      name: item.name,
      description: item.description,
      bgColor: item.bgColor,
      bgImage: item.bgImage,
      title: item.title,
      subtitle: item.subtitle,
      textColor: item.textColor,
      spinItemMainColor: item.spinItemMainColor,
      fieldName: item.fieldName,
      btnBg: item.btnBg,
      btnTextColor: item.btnTextColor,
      fieldEmail: item.fieldEmail,
      fieldEmailPlaceholder: item.fieldEmailPlaceholder,
      fieldNamePlaceholder: item.fieldNamePlaceholder,
      fieldPhonePlaceholder: item.fieldPhonePlaceholder,
      fieldCondition: item.fieldCondition,
      fieldPrivacy: item.fieldPrivacy,
      instructionText: item.instructionText,
      fontFamily: item.fontFamily,
      privacyText: item.privacyText,
      btnText: item.btnText,
      conditionLink: item.conditionLink,
      conditionText: item.conditionText,
      thankText: item.thankText,
      isSavedAsGlobal: item.isSavedAsGlobal,
      winMessageTitle: item.winMessageTitle,
      winMessageCode: item.winMessageCode,
      copyCodeText: item.copyCodeText,
      winCloseBtnText: item.winCloseBtnText,
      winPictures: item.winPictures,
      loseMessageTitle: item.loseMessageTitle,
      loseMessage: item.loseMessage,
      losePictures: item.losePictures
    })
  }

  const { user } = useApplicationContext()
  const numStyle = {
    fontSize: "3em",
    mx: 1,
    p: 1,
    px: 2,
    background: "white",
    borderRadius: 2
  }

  useEffect(() => {
    if (data) {
      setValues({
        name: data?.name,
        description: data?.description,
        bgColor: data?.bgColor,
        bgImage: data?.bgImage,
        title: data?.title,
        subtitle: data?.subtitle,
        textColor: data?.textColor,
        spinItemMainColor: data?.spinItemMainColor,
        fieldName: data?.fieldName,
        btnBg: data?.btnBg,
        btnTextColor: data?.btnTextColor,
        fieldEmail: data?.fieldEmail,
        instructionText: data?.instructionText,
        fontFamily: data?.fontFamily,
        privacyText: data?.privacyText,
        btnText: data?.btnText,
        conditionLink: data?.conditionLink,
        conditionText: data?.conditionText,
        thankText: data?.thankText,
        winMessageCode: data?.winMessageCode,
        copyCodeText: data?.copyCodeText,
        winMessageTitle: data?.winMessageTitle,
        winCloseBtnText: data?.winCloseBtnText,
        fieldNamePlaceholder: data?.fieldNamePlaceholder,
        fieldPhonePlaceholder: data?.fieldPhonePlaceholder,
        fieldEmailPlaceholder: data?.fieldEmailPlaceholder,
        fieldPrivacy: data?.fieldPrivacy,
        fieldCondition: data?.fieldCondition,
        fieldPhone: data?.fieldPhone,
        winPictures: data?.winPictures,
        loseMessageTitle: data?.loseMessageTitle,
        loseMessage: data?.loseMessage,
        losePictures: data?.losePictures
      })
    }
  }, [data])

  return (
    <Box sx={{ mx: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          mt: 2,
          mb: 2
        }}
      >
        {!idTemplate ? (
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Créer un template
          </Typography>
        ) : (
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Modifier le template
          </Typography>
        )}
      </Box>
      <Grid container justifyContent="flex-end">
        {user?.type === "ADMIN" ? (
          ""
        ) : (
          <Grid item>
            <ModalTemplateChoice
              onChoose={onChoose}
              winPictures={""}
              loseMessageTitle={""}
              loseMessage={""}
              losePictures={""}
            />
          </Grid>
        )}
        <Grid item>
          <Box sx={{ my: 2 }}>
            <Button
              variant="contained"
              onClick={!idTemplate ? handleSubmit : handleUpdates}
              sx={{ color: "#050005" }}
            >
              Enregistrer
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item lg={8}>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "inline-block"
                }}
              >
                Information générales
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Titre
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Sous-titre
                  </Typography>
                  <TextField
                    fullWidth
                    name="subtitle"
                    value={values.subtitle}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="description"
                    multiline
                    value={values.description}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Texte bouton
                  </Typography>
                  <TextField
                    fullWidth
                    name="btnText"
                    value={values.btnText}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Police des caractères
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      name="fontFamily"
                      value={values.fontFamily}
                      onChange={(e) => {
                        setValues((prev) => ({
                          ...prev,
                          fontFamily: e.target.value
                        }))
                      }}
                    >
                      {font.map((fonte) => {
                        return (
                          <MenuItem
                            sx={{ fontFamily: fonte.value }}
                            key={fonte.id}
                            value={fonte.value}
                          >
                            {fonte.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "inline-block"
                }}
              >
                Option des couleurs
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                <Grid container item md={6}>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        mt: 1,
                        mr: 1,
                        display: "inline-block"
                      }}
                    >
                      couleur du texte :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                      type="color"
                      value={values.textColor || ""}
                      name="textColor"
                      onChange={(e) => {
                        setValues((prev) => ({
                          ...prev,
                          textColor: e.target.value
                        }))
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        mt: 1,
                        mr: 1,
                        display: "inline-block"
                      }}
                    >
                      Couleur de fond :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                      type="color"
                      name="bgColor"
                      value={values.bgColor || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        mt: 1,
                        mr: 1,
                        display: "inline-block"
                      }}
                    >
                      Couleur du bouton :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                      type="color"
                      name="btnBg"
                      value={values.btnBg || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        mt: 1,
                        mr: 1,
                        display: "inline-block"
                      }}
                    >
                      couleur du texte (bouton) :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                      type="color"
                      name="btnTextColor"
                      value={values.btnTextColor || ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        mt: 1,
                        mr: 1,
                        display: "inline-block"
                      }}
                    >
                      Couleur de fond (Roulette) :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ColorPicker
                      type="color"
                      name="spinItemMainColor"
                      value={values.spinItemMainColor || ""}
                      onChange={handleChangeSpinColor}
                    />
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Image de fond
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Dropzone
                      message="Image de fond"
                      btnSx={{ height: 150, width: 150, mr: 1 }}
                      onFinished={handleChangeBg}
                    />
                    {values.bgImage && (
                      <Box
                        sx={{
                          width: 150,
                          height: 150,
                          mr: 1,
                          mb: 1,
                          position: "relative"
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setValues((prev) => ({
                              ...prev,
                              bgImage: ""
                            }))
                          }}
                          sx={{ position: "absolute", bottom: 5, right: 5 }}
                        >
                          <Delete color="error" />
                        </IconButton>

                        <img
                          src={values.bgImage}
                          alt=""
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "inline-block"
                }}
              >
                Information client et CGU
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Champ e-mail
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="fieldEmailPlaceholder"
                    value={values.fieldEmailPlaceholder || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Champ nom
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    checked={values.fieldName || false}
                    onChange={handleChangeNom}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="fieldNamePlaceholder"
                    value={values.fieldNamePlaceholder || ""}
                    placeholder="Votre nom"
                    onChange={handleChange}
                    disabled={!values.fieldName}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Champ téléphone
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    checked={values.fieldPhone || false}
                    onChange={handleChangePhone}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="fieldPhonePlaceholder"
                    value={values.fieldPhonePlaceholder || ""}
                    placeholder="Votre Téléphone"
                    onChange={handleChange}
                    disabled={!values.fieldPhone}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Champ politique
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    checked={values.fieldCondition || false}
                    onChange={handleChangeCondition}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="conditionText"
                    value={values.conditionText || ""}
                    placeholder="Condition Text"
                    onChange={handleChange}
                    disabled={!values.fieldCondition}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Champ CGU
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    checked={values.fieldPrivacy || false}
                    onChange={handleChangePrivateText}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="privacyText"
                    value={values.privacyText || ""}
                    placeholder="Private Text"
                    onChange={handleChange}
                    disabled={!values.fieldPrivacy}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4}>
          <CardSpinner
            bgImage={values.bgImage}
            bgColor={values.bgColor}
            textColor={values.textColor}
            fontFamily={values.fontFamily}
            name={values.name}
            description={values.description}
            spinItemMainColor={values.spinItemMainColor}
            subtitle={values.subtitle}
            fieldEmailPlaceholder={values.fieldEmailPlaceholder}
            fieldCondition={values.fieldCondition}
            fieldPrivacy={values.fieldPrivacy}
            conditionText={values.conditionText}
            privacyText={values.privacyText}
            fieldPhonePlaceholder={values.fieldPhonePlaceholder}
            fieldPhone={values.fieldPhone}
            fieldNamePlaceholder={values.fieldNamePlaceholder}
            fieldName={values.fieldName}
            btnBg={values.btnBg}
            btnText={values.btnText}
            btnTextColor={values.btnTextColor}
            thankText={values.thankText}
            logoUrl={business?.logo || ""}
            countryCode={business?.countryCode || "fr"}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item lg={8}>
          <Card>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "inline-block"
                }}
              >
                Coupon gagnant
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Titre
                  </Typography>
                  <TextField
                    fullWidth
                    name="winMessageTitle"
                    value={values.winMessageTitle}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Message
                  </Typography>
                  <TextField
                    fullWidth
                    name="winMessageCode"
                    value={values.winMessageCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Illustration
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Dropzone
                      message="Illustration"
                      btnSx={{ height: 150, width: 150, mr: 1 }}
                      onFinished={handleWinPictures}
                    />
                    {values.winPictures && (
                      <Box
                        sx={{
                          width: 150,
                          height: 150,
                          mr: 1,
                          mb: 1,
                          position: "relative"
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setValues((prev) => ({
                              ...prev,
                              winPictures: ""
                            }))
                          }}
                          sx={{ position: "absolute", bottom: 5, right: 5 }}
                        >
                          <Delete color="error" />
                        </IconButton>

                        <img
                          src={values.winPictures}
                          alt=""
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4}>
          {profil && (
            <ContainerWithFooter
              theme={theme}
              palette={palette}
              profil={profil}
            >
              <Box sx={{ textAlign: "center" }}>
                <Box sx={{ my: 4 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: `${values.textColor}`,
                      fontFamily: `${values.fontFamily}`
                    }}
                  >
                    {values.winMessageTitle}
                  </Typography>
                </Box>
                <Box sx={{ my: 2 }}>
                  {values.winPictures ? (
                    <img
                      src={values.winPictures || ""}
                      alt="Illustration"
                      style={{ maxWidth: "100%", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: 2
                  }}
                >
                  <Typography sx={numStyle}>0</Typography>
                  <Typography sx={numStyle}>0</Typography>
                  <Typography sx={numStyle}>0</Typography>
                  <Typography sx={numStyle}>0</Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: `${values.textColor}`,
                      fontFamily: `${values.fontFamily}`
                    }}
                  >
                    {values.winMessageCode}
                  </Typography>
                </Box>
              </Box>
            </ContainerWithFooter>
          )}
          {/* <Box
            sx={{
              p: 2,
              borderRadius: "10px",
              background: `${values.bgImage}`
                ? `url(${values.bgImage})`
                : `${values.bgColor}`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Box sx={{ my: 4 }}>
                <Typography
                  variant="h4"
                  sx={{
                    color: `${values.textColor}`,
                    fontFamily: `${values.fontFamily}`
                  }}
                >
                  {values.winMessageTitle}
                </Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                {values.losePictures ? (
                  <img
                    src={values.winPictures || ""}
                    alt="Illustration"
                    style={{ maxWidth: "100%" }}
                  />
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: 2
                }}
              >
                <Typography sx={numStyle}>0</Typography>
                <Typography sx={numStyle}>0</Typography>
                <Typography sx={numStyle}>0</Typography>
                <Typography sx={numStyle}>0</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: `${values.textColor}`,
                    fontFamily: `${values.fontFamily}`
                  }}
                >
                  {values.winMessageCode}
                </Typography>
              </Box>
            </Box>
          </Box> */}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={8}>
          <Card>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "inline-block"
                }}
              >
                Visuel perdant
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Titre
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Titre du visuel"
                    name="loseMessageTitle"
                    value={values.loseMessageTitle}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Message
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Message du visuel"
                    name="loseMessage"
                    value={values.loseMessage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 1 }}
                  >
                    Illustration
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Dropzone
                      message="Illustration"
                      btnSx={{ height: 150, width: 150, mr: 1 }}
                      onFinished={handleLosePictures}
                    />
                    {values.losePictures && (
                      <Box
                        sx={{
                          width: 150,
                          height: 150,
                          mr: 1,
                          mb: 1,
                          position: "relative"
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setValues((prev) => ({
                              ...prev,
                              losePictures: ""
                            }))
                          }}
                          sx={{ position: "absolute", bottom: 5, right: 5 }}
                        >
                          <Delete color="error" />
                        </IconButton>

                        <img
                          src={values.losePictures}
                          alt=""
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover" }}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4}>
          {profil && (
            <ContainerWithFooter
              theme={theme}
              palette={palette}
              profil={profil}
            >
              <Box sx={{ textAlign: "center" }}>
                <Box sx={{ my: 4 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: `${values.textColor}`,
                      fontFamily: `${values.fontFamily}`
                    }}
                  >
                    {values.loseMessageTitle || "Titre du visuel"}
                  </Typography>
                </Box>
                <Box sx={{ my: 2 }}>
                  {values.losePictures ? (
                    <img
                      src={values.losePictures || ""}
                      alt="Illustration"
                      style={{ maxWidth: "100%", width: "60%" }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: `${values.textColor}`,
                      fontFamily: `${values.fontFamily}`
                    }}
                  >
                    {values.loseMessage || "Message du visuel"}
                  </Typography>
                </Box>
              </Box>
            </ContainerWithFooter>
          )}
          {/* <Box
            sx={{
              p: 2,
              borderRadius: "10px",
              background: `${values.bgImage}`
                ? `url(${values.bgImage})`
                : `${values.bgColor}`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Box sx={{ my: 4 }}>
                <Typography
                  variant="h4"
                  sx={{
                    color: `${values.textColor}`,
                    fontFamily: `${values.fontFamily}`
                  }}
                >
                  {values.loseMessageTitle || "Titre du visuel"}
                </Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                {values.losePictures ? (
                  <img
                    src={values.losePictures || ""}
                    alt="Illustration"
                    style={{ maxWidth: "100%" }}
                  />
                ) : (
                  ""
                )}
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: `${values.textColor}`,
                    fontFamily: `${values.fontFamily}`
                  }}
                >
                  {values.loseMessage || "Message du visuel"}
                </Typography>
              </Box>
            </Box>
          </Box> */}
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Box sx={{ my: 3 }}>
            <Button
              variant="contained"
              onClick={!idTemplate ? handleSubmit : handleUpdates}
              sx={{ color: "#050005" }}
            >
              Enregistrer
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
