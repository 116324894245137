import { gql } from "@apollo/client"

export const CREATETAG = gql`
  mutation CreateTag($data: TagsInput!) {
    createTag(data: $data)
  }
`

export const DELETETAG = gql`
  mutation DeleteTag($deleteTagId: Float!) {
    DeleteTag(id: $deleteTagId)
  }
`

export const UPDATETAGCLIENT = gql`
  mutation UpdateTagClient($clientId: Float!, $clientTagList: ClientTagListType!) {
    updateTagClient(clientId: $clientId, clientTagList: $clientTagList)
  }
`
