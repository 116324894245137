/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from "react"
import {
  Importer,
  ImporterField,
  ImporterLocale,
  ImporterPreviewColumn
} from "react-csv-importer"

// include the widget CSS file whichever way your bundler supports it
import "react-csv-importer/dist/index.css"

const frFR: ImporterLocale = {
  general: {
    goToPreviousStepTooltip: "Retourner"
  },

  fileStep: {
    initialDragDropPrompt: "Déplacer un fichier CSV ou cliquer pour importer",
    activeDragDropPrompt: "Déplacer CSV...",

    getImportError: (message) => `Import erreur: ${message}`,
    getDataFormatError: (message) => `Veuillez vérifier le format: ${message}`,
    goBackButton: "Retour",
    nextButton: "Continuer",

    rawFileContentsHeading: "Contenus du fichier",
    previewImportHeading: "Prévision de l'importation",
    dataHasHeadersCheckbox: "Contenus avec titre",
    previewLoadingStatus: "Loading preview..."
  },

  fieldsStep: {
    stepSubtitle: "Déplacer les colonnes qui corréspond",
    requiredFieldsError: "Veuillez compléter les champs requis",
    nextButton: "Continuer",

    dragSourceAreaCaption: "Colonne à importer",
    getDragSourcePageIndicator: (currentPage: number, pageCount: number) =>
      `Page ${currentPage} of ${pageCount}`,
    getDragSourceActiveStatus: (columnCode: string) =>
      `Assigning column ${columnCode}`,
    nextColumnsTooltip: "Show next columns",
    previousColumnsTooltip: "Show previous columns",
    clearAssignmentTooltip: "Clear column assignment",
    selectColumnTooltip: "Select column for assignment",
    unselectColumnTooltip: "Unselect column",

    dragTargetAreaCaption: "Target fields",
    getDragTargetOptionalCaption: (field) => `${field} (optional)`,
    getDragTargetRequiredCaption: (field) => `${field} (required)`,
    dragTargetPlaceholder: "Déplacer une colonne içi",
    getDragTargetAssignTooltip: (columnCode: string) =>
      `Assign column ${columnCode}`,
    dragTargetClearTooltip: "Clear column assignment",

    columnCardDummyHeader: "Unassigned field",
    getColumnCardHeader: (code) => `Column ${code}`
  },
  progressStep: {
    stepSubtitle: "Traitement des données",
    uploadMoreButton: "Import à nouveau",
    finishButton: "Enregister",
    statusError: "Could not import",
    statusComplete: "Cliquer sur 'Enregister' pour valider l'import",
    statusPending: "Chargement...",
    processedRowsLabel: "Processed rows:"
  }
}
// in your component code:
interface ImportFilesProps {
  onFinish?: (data: any[]) => void
}

export const ImportFiles: FC<ImportFilesProps> = ({ onFinish }) => {
  const [chunks, setChunks] = useState<any[]>([])
  const handleFinish = () => {
    // const data = fields.map((col, index) => ({
    //   [fields[index]]: col.values[index]
    // }))
    if (onFinish) {
      onFinish(chunks)
    }
  }
  return (
    <Importer
      locale={frFR}
      assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
      restartable={false} // optional, lets user choose to upload another file when import is complete
      onStart={({ file, preview, fields, columnFields }) => {
        // optional, invoked when user has mapped columns and started import
      }}
      processChunk={async (rows, { startIndex }) => {
        // required, may be called several times
        // receives a list of parsed objects based on defined fields and user column mapping;
        // (if this callback returns a promise, the widget will wait for it before parsing more data)
        const rowModifieds = rows.map((row) => {
          const propEmail = (row.acceptEmail as string)?.toLowerCase()
          const emailAccept =
            propEmail === "oui" ? true : propEmail === "non" ? false : null

          const propSms = (row.acceptSms as string)?.toLowerCase()
          const smsAccept =
            propSms === "oui" ? true : propSms === "non" ? false : null
          return {
            ...row,
            acceptSms: smsAccept,
            acceptEmail: emailAccept
          }
        })
        setChunks((prev) => prev.concat(rowModifieds))
      }}
      onComplete={({ file, preview, fields, columnFields }) => {
        // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
      }}
      onClose={({ file, preview, fields, columnFields }) => {
        // optional, if this is specified the user will see a "Finish" button after import is done,
        // which will call this when clicked
        handleFinish()
      }}

      // CSV options passed directly to PapaParse if specified:
      // delimiter={...}
      // newline={...}
      // quoteChar={...}
      // escapeChar={...}
      // comments={...}
      // skipEmptyLines={...}
      // delimitersToGuess={...}
      // chunkSize={...} // defaults to 10000
      // encoding={...} // defaults to utf-8, see FileReader API
    >
      <ImporterField name="lastname" label="Nom" optional />
      <ImporterField name="firstname" label="Prénom" optional />
      <ImporterField name="email" label="E-mail" optional />
      <ImporterField name="phone" label="Téléphone" optional />
      <ImporterField name="address" label="Adresse" optional />
      <ImporterField name="city" label="Ville" optional />
      <ImporterField name="codePostal" label="Code postal" optional />
      <ImporterField name="photo" label="Photo" optional />
      <ImporterField
        name="acceptSms"
        label="Accepter sms marketing (OUI ou NON)"
        optional
      />
      <ImporterField
        name="acceptEmail"
        label="Accepter email marketing (OUI ou NON)"
        optional
      />
    </Importer>
  )
}
