import { TableColumnProps } from "@/components"
import { Box } from "@mui/material"

export const TableResult = () => {
  const column: TableColumnProps[] = [
    {
      id: "email",
      label: "Envoye a",
      component: () => (
        <Box>test@test.mg</Box>
      )
    },
    {
      id: "date",
      label: "date",
      component: () => (
        <Box>12/09/2023</Box>
      )
    }
  ]

  return { column }
}
