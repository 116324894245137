import { useRef } from "react"
import { Box, Button } from "@mui/material"
import Editor from "react-email-editor"

export const EmailEditor = () => {
  const emailEditorRef = useRef(null)
  /*
	  const exportHtml = () => {
		if (emailEditorRef.current) {
		  emailEditorRef.current.editor.exportHtml((data: any) => {
			const { design, html } = data
			console.log("exportHtml :", html)
		  })
		}
	  }
  */
  return (
    <Box sx={{ height: "93vh" }}>
      <Editor
        minHeight="100%"
        options={{
          locale: "fr-FR"
        }}
        ref={emailEditorRef}
      />
    </Box>
  )
}
