import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Typography
} from "@mui/material"
import moment from "moment"
import { useState } from "react"
import { useApplicationContext } from "@/hooks"

type CardProps = {
  status?: boolean
  data: any
  onConfirm: (id: number) => void
  onReject: (id: number) => void
}

export const CardBooking = ({
  status,
  data,
  onConfirm,
  onReject
}: CardProps) => {
  const [loading, setLoading] = useState(false)
  const { dispatchSnack } = useApplicationContext()

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent
        sx={{
          position: "relative",
          pb: "16px !important"
        }}
      >
        <Box>
          {data.eventParty && (
            <Box marginBottom={2}>
              <Chip size="small" color="warning" label="Evénement" />
            </Box>
          )}
          <Box>
            <Typography variant="h5" fontWeight="bold" component="span">
              N° {data.id}
            </Typography>
          </Box>
          {data.eventParty && (
            <Box>
              <Typography variant="h5" fontWeight="bold" component="span">
                Évènement : &nbsp;
              </Typography>
              <Typography variant="body2" component="span">
                {data.eventParty.title}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant="h5" fontWeight="bold" component="span">
              Client : &nbsp;
            </Typography>
            <Typography variant="body2" component="span">
              {data.name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" fontWeight="bold" component="span">
              E-mail : &nbsp;
            </Typography>
            <Typography variant="body2" component="span">
              {data.email}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" fontWeight="bold" component="span">
              Téléphone : &nbsp;
            </Typography>
            <Typography variant="body2" component="span">
              {data.phone}
            </Typography>
          </Box>
          <Box>
            <Typography fontWeight={700}>
              Nombre de personne: {data.person}{" "}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" fontWeight="bold" component="span">
              Date de réservation : &nbsp;
            </Typography>
            <Typography variant="body2" component="span">
              {moment(data.date).format("DD MMMM YYYY")}
            </Typography>
          </Box>
          {!data.eventParty && (
            <Box>
              <Typography variant="h5" fontWeight="bold" component="span">
                Heure de réservation : &nbsp;
              </Typography>
              <Typography variant="body2" component="span">
                {data.eventParty ? moment(data.hour).format("HH:mm") : data.hour}
              </Typography>
            </Box>
          )}
          {!status && (
            <Box
              sx={{
                my: 2,
                display: "flex",
                justifyContent: "space-around"
              }}
            >
              {!loading ? (
                <>
                  <Box>
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      onClick={() => {
                        setLoading(true)
                        dispatchSnack({
                          open: true,
                          severity: "success",
                          message: "Action avec succès"
                        })
                        onConfirm(data.id)
                      }}
                    >
                      Honorable
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setLoading(true)
                        dispatchSnack({
                          open: true,
                          severity: "success",
                          message: "Action avec succès"
                        })
                        onReject(data.id)
                      }}
                    >
                      Non-honorable
                    </Button>
                  </Box>
                </>
              ) : (
                <CircularProgress />
              )}
            </Box>
          )}
        </Box>
        <Box marginTop={1}>
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontSize: "12px"
            }}
          >
            Créé le : {moment(data.createdAt).format("DD MMMM YYYY")}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
