import { Route, Routes } from "react-router-dom"
import { ListContact } from "../admin/Email/components/ListContact"
import { SenderList } from "../admin/Email/SenderList"
import { TemplateBuilder } from "../admin/notification/templateBuilder"
import { CampaignDashboard } from "./CampaignDashboard"
import { CreateCampaign } from "./CreateCampaign"
import { CreateTemplate } from "./createTemplate"
import { CampagneAuto } from "./CampagneAuto"
import { EditCampaign } from "./EditCampaign"
import { AddContact } from "../admin/Email/AddContact"

export const CampaignRoutes = () => {
  return (
    <Routes>
      <Route index element={<CampaignDashboard />} />
      <Route path="create_campaign" element={<CreateCampaign />} />
      <Route path="create_campaign/editor" element={<CreateTemplate />} />
      <Route path="template" element={< TemplateBuilder />} />
      <Route path="sender" element={<SenderList />} />
      <Route path="contact" element={<ListContact />} />
      <Route path="add-contact/:id" element={<AddContact/>} />
      <Route path="auto" element={<CampagneAuto />} />
      <Route path="edit-campaign/:type/:id" element={<EditCampaign />} />
      <Route path="template" element={<TemplateBuilder />} />
      <Route path="template/:type" element={<TemplateBuilder />} />
    </Routes>
  )
}
