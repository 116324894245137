import { gql } from "@apollo/client";

export const BOOKING_PENDING = gql`
  query PendingReservation($businessId: Float!) {
    pendingReservation(businessId: $businessId) {
      id
      name
      businessId
      business {
        id
      }
      client {
        id
        lastname
        phone
        email
      }
      eventParty {
        description
        dateStart
        dateEnd
        id
        img
        title
        hourStart
        hourEnd
      }
      clientId
      createdAt
      date
      hour
      email
      message
      person
      phone
      status
      updatedAt
    }
  }
`

export const BOOKING_VALIDATE = gql`
  query ValidReservation($businessId: Float!) {
    validReservation(businessId: $businessId) {
      id
      name
      businessId
      business {
        id
      }
      client {
        id
        lastname
        phone
        email
      }
      eventParty {
        description
        dateStart
        dateEnd
        id
        img
        title
        hourStart
        hourEnd
      }
      clientId
      createdAt
      date
      hour
      email
      message
      person
      phone
      status
      updatedAt
    }
  }
`

export const BOOKING_REJECTED = gql`
  query RejectedReservation($businessId: Float!) {
    rejectedReservation(businessId: $businessId) {
      business {
        id
      }
      businessId
      client {
        id
        lastname
        phone
        email
        firstname
      }
      eventParty {
        description
        dateStart
        dateEnd
        id
        img
        title
        hourStart
        hourEnd
      }
      clientId
      createdAt
      date
      hour
      email
      id
      message
      name
      person
      phone
      status
      updatedAt
    }
  }
`
