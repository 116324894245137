import { makeStyles } from "@mui/styles"
import mask from "../../../assets/Mask.png"

export const useStyles = makeStyles({
  bg: {
    background: `url(${mask})`
  },
  container: {
    minHeight: "100vh",
    height: "100vh",
    padding: 20
  },
  welcome: {},
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20
  },
  connecter: {
    display: "flex",
    alignItems: "center"
  },
  text: {
    fontFamily: "Phosphate !important",
    lineHeight: "1em"
  }
})
