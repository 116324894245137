import { useQuery } from "@apollo/client"
import {
  BusinessClients,
  BusinessClientsVariables,
  BUSINESS_CLIENT
} from "../graphql/business"

export const useCurrentBusinessClient = (id?: number) => {
  const { data, loading, error, refetch } = useQuery<
    BusinessClients,
    BusinessClientsVariables
  >(BUSINESS_CLIENT, {
    variables: {
      skip: 0,
      take: 100,
      businessId: id as number
    },
    skip: !id
  })
  return {
    data: data?.businessClients,
    loading,
    error,
    refetch
  }
}
