import {
  BaseTable,
  QueryResult,
  CustomModal,
  ConfirmModal,
} from "@/components"
import {
  DELETE_SENDER,
  GET_LIST_SENDER,
  VALIDATE_SENDER,
  CREATE_SENDER
} from "@/graphql/emailing"
import { useForm } from "react-hook-form"
import { useQuery, useMutation } from "@apollo/client"
import { Box, Button, Typography, styled, TextField } from "@mui/material"
import { useState, ChangeEvent } from "react"
import { TableSender } from "./components/TableSender"
import Swal from "sweetalert2"
import Add from "@mui/icons-material/Add"

interface SenderType {
  name: string
  email: string
}

export const SenderList = () => {
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [validateId, setValidateId] = useState<number | null>(null)
  const [deleteId, setDeleteId] = useState<number | null>(null)
  const [sender, setSender] = useState<SenderType>()
  const [code, setCode] = useState<number | "">("")
  const { data, loading, refetch } = useQuery(GET_LIST_SENDER, {
    variables: {
      status: false
    }
  })
  const [validateSender] = useMutation(VALIDATE_SENDER)
  const [deleteSender] = useMutation(DELETE_SENDER)
  const [createSender] = useMutation(CREATE_SENDER)
  const {
    register: registerSender,
    handleSubmit: submitSender,
    formState: { errors: errorsSender }
  } = useForm()
  const getValidation = (item: number) => {
    setOpen(true)
    setValidateId(item)
  }
  const deleteValidation = (item: number) => {
    setOpenDelete(true)
    setDeleteId(item)
  }
  const confirmValidation = () => {
    validateSender({
      variables: {
        otp: Number(code),
        sendinblueId: validateId
      }
    }).then(() => {
      Swal.fire({
        icon: "success",
        title: "Expéditeur validé",
        text: "",
        showConfirmButton: false,
        showCancelButton: false
      })
      setCode("")
      refetch()
    }).catch(() => {
      Swal.fire({
        icon: "error",
        title: "Erreur de validation",
        text: "",
        showConfirmButton: false,
        showCancelButton: false
      })
      setCode("")
      refetch()
    })
  }
  const confirmDeletion = () => {
    deleteSender({
      variables: {
        deleteSenderId: deleteId
      }
    }).then(() => {
      Swal.fire({
        icon: "success",
        title: "Expéditeur supprimé avec succès",
        text: "",
        showConfirmButton: false,
        showCloseButton: true
      })
      refetch()
    }).catch(() => {
      Swal.fire({
        icon: "error",
        title: "Erreur de modification",
        text: "",
        showConfirmButton: false,
        showCloseButton: true
      })
      refetch()
    })
  }
  const handleCancelDelete = () => {
    setOpenDelete(false)
    setDeleteId(null)
  }
  const { columns } = TableSender({
    onValidate: getValidation,
    onDelete: deleteValidation
  })
  const ButtonTop = styled(Button)(() => ({
    textTransform: "none"
  }))
  const handleChangeSender = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setSender((prev: any) => ({
      ...prev,
      [name]: value
    }))
  }
  const handleSubmitSender = async () => {
    await createSender({
      variables: {
        name: sender?.name,
        email: sender?.email
      }
    }).then(() => {
      Swal.fire({
        icon: "success",
        title: "Expéditeur ajouter",
        text: "",
        showConfirmButton: false,
        showCancelButton: false
      })
      refetch()
      setOpenModal(false)
    }).catch(() => {
      Swal.fire({
        icon: "error",
        title: "Erreur de création",
        text: "",
        showCancelButton: false,
        showConfirmButton: false
      })
      setOpenModal(false)
    })
  }

  return (
    <Box sx={{ m: 2 }}>
      <QueryResult data={data} loading={loading}>
        <BaseTable
          data={data?.getListSender}
          page={1}
          columns={columns}
          title="Liste des expéditeurs"
          topComponent={
            <ButtonTop
              variant="outlined"
              startIcon={<Add />}
              onClick={() => setOpenModal(true)}
              sx={{ color: "#050005", marginBlock: 2 }}
            >
              Ajouter un expéditeur
            </ButtonTop>
          }
        />
      </QueryResult>
      <CustomModal
        title="Valider expéditeur"
        open={open}
        setOpen={setOpen}
        onConfirm={confirmValidation}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">Veuillez saisir les 6 chiffres envoyer dans le mail à valider, merci</Typography>
        </Box>
        <Box textAlign="center">
          <input
            type="number"
            value={code}
            style={{
              borderRadius: 5,
              fontSize: "45px",
              border: "2px solid",
              padding: 15,
              maxWidth: 240
            }}
            maxLength={6}
            onChange={(e) => setCode(Number(e.target.value))}
          />
        </Box>
      </CustomModal>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={confirmDeletion}
        confirmLabel="Supprimer"
        isDeleting
        title="Supprimer un expéditeur"
      >
        <Typography>Vous êtes sur le point de supprimé un expéditeur, voulez-vous continué ?</Typography>
      </ConfirmModal>
      <CustomModal
        noAction
        title="Ajouter un éxpéditeur"
        open={openModal}
        setOpen={setOpenModal}
      >
        <Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>Nom de l'éxpéditeur</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={sender?.name}
              {...registerSender("name", {
                required: { value: true, message: "Ce champ est requis" }
              })
              }
              name="name"
              error={errorsSender.name && true}
              helperText={errorsSender.name?.message}
              onChange={handleChangeSender}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>Mail de l'éxpéditeur</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={sender?.email}
              {...registerSender("email", {
                required: { value: true, message: "Ce champ est requis" }
              })
              }
              name="email"
              error={errorsSender.email && true}
              helperText={errorsSender.email?.message}
              onChange={handleChangeSender}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={() => {
              setSender({
                name: "",
                email: ""
              })
              setOpenModal(false)
            }}>Annuler</Button>
            <Button disabled={loading} variant="contained" onClick={submitSender(handleSubmitSender)}>
              {loading ? "Sauvegarde en cours" : "Enregistrer"}
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </Box>
  )
}
