import { CREATETAG, DELETETAG, TAGLIST } from "@/graphql/tag"
import { useApplicationContext } from "@/hooks"
import { useMutation, useQuery } from "@apollo/client"
import Add from "@mui/icons-material/Add"
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { BaseTable, ConfirmModal, QueryResult, CustomModal } from "@/components"
import { TagTable } from "./component/TagTable"

export const TagsComponent = () => {
  const { business, dispatchSnack } = useApplicationContext()
  const { data, loading, refetch } = useQuery(TAGLIST, {
    variables: {
      businessId: business?.id
    },
  })
  const [deleteTag] = useMutation(DELETETAG)
  const [createTag] = useMutation(CREATETAG)
  const [open, setOpen] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [nameVal, setNameVal] = useState("")
  const [expiredVal, setExpiredVal] = useState("")
  const [deleteId, setDeleteId] = useState(0)
  const handleRemove = (id: number) => {
    setOpen(true)
    setDeleteId(id)
  }
  const confirmCreate = async () => {
    await createTag({
      variables: {
        data: {
          name: nameVal,
          expired: expiredVal,
          businessId: business?.id
        }
      }
    }).then(() => {
      setExpiredVal("")
      setNameVal("")
      refetch()
      setOpenCreate(false)
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Creation avec succès"
      })
    }).catch(() => {
      setExpiredVal("")
      setNameVal("")
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Creation echoue"
      })
    })
  }
  const cancelDelete = () => {
    setOpen(false)
    setDeleteId(0)
  }
  const confirmDelete = async () => {
    if (!deleteId) {
      setOpen(false)
      return
    }
    await deleteTag({
      variables: {
        deleteTagId: deleteId
      }
    }).then(() => {
      setDeleteId(0)
      refetch()
      setOpen(false)
      dispatchSnack({
        open: true,
        severity: "success",
        message: "suppression avec succès"
      })
    })
  }
  const { column } = TagTable({
    onRemove: handleRemove
  })

  return (
    <>
      <QueryResult data={data} loading={loading}>
        <BaseTable
          title="Liste des tags disponible"
          columns={column}
          data={data?.tagList || []}
          page={1}
          topComponent={
            <Box sx={{ mb: 3 }}>
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() => setOpenCreate(true)}
              >Ajouter des tags</Button>
            </Box>
          }
        />
      </QueryResult>
      <CustomModal
        open={openCreate}
        setOpen={setOpenCreate}
        title="Creer un tag"
        onConfirm={confirmCreate}
      >
        <TextField
          fullWidth
          variant="outlined"
          label="Nom"
          sx={{ mb: 2 }}
          value={nameVal}
          onChange={(e) => setNameVal(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="expired">Expiration</InputLabel>
          <Select
            labelId="expired"
            label="Expiration"
            value={expiredVal}
            onChange={(e) => setExpiredVal(e.target.value as string)}
          >
            <MenuItem value="DAY">Par jour</MenuItem>
            <MenuItem value="WEEK">Par semaine</MenuItem>
            <MenuItem value="MONTH">Par mois</MenuItem>
            <MenuItem value="UNDEFINED">Indéterminé</MenuItem>
          </Select>
        </FormControl>
      </CustomModal>
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        confirmLabel="Suppression tag"
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      >
        <Typography>Vous êtes sur le point de supprimer un tag</Typography>
      </ConfirmModal>
    </>
  )
}
