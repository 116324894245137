import {
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  TextField,
  Divider,
  Button,
  Select,
  MenuItem
} from "@mui/material"
import { SelectChangeEvent } from "@mui/material/Select"
import { useNavigate, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useApplicationContext } from "@/hooks"
import { ChangeEvent, useEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { CreateTemplate, CreateTemplateVariables } from "@/graphql/emailing/types/CreateTemplate"
import {
  CREATE_TEMPLATE,
  GET_TEMPLATE_ID,
  GET_LIST_SENDER
} from "@/graphql/emailing"
import Swal from "sweetalert2"

export const InfoTemplate = () => {
  const [html, setHtml] = useState("")
  const templateHtml = () => {
    setHtml(localStorage.getItem("templateCampagne") || "")
  }
  const { templateState, setTemplateState } = useApplicationContext()
  const [saveTemplate, { loading }] = useMutation<CreateTemplate, CreateTemplateVariables>(CREATE_TEMPLATE)
  const { id } = useParams()
  const { data } = useQuery(GET_TEMPLATE_ID, {
    variables: {
      getTemplateByIdId: Number(id)
    },
    skip: !id
  })
  const {
    data: listSender
  } = useQuery(GET_LIST_SENDER, {
    variables: {
      status: true
    }
  })
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const redirectToBuilder = () => {
    navigate("../template")
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setTemplateState((prev: any) => ({
      ...prev,
      [name]: value
    }))
  }
  const handleSelect = (e: SelectChangeEvent<string>) => {
    setTemplateState((prev: any) => ({
      ...prev,
      email: e.target.value
    }))
  }

  const submitTemplate = async () => {
    await saveTemplate({
      variables: {
        data: {
          name: templateState?.name,
          email: templateState?.email,
          subject: templateState?.subject,
          templateName: templateState?.nameTemplate,
          htmlContent: html
        }
      }
    }).then(() => {
      setHtml("")
      localStorage.removeItem("templateCampagne")
      Swal.fire({
        icon: "success",
        title: "Template enregistré",
        text: "",
        showCancelButton: false,
        showConfirmButton: false
      })
      setTimeout(() => {
        navigate("../list_template")
      }, 1000)
    }).catch(() => {
      Swal.fire({
        icon: "error",
        title: "Enregistrement échoué",
        text: "",
        showConfirmButton: false,
        showCancelButton: false
      })
    })
  }

  useEffect(() => {
    if (id && data) {
      const info = data?.GetTemplateById
      setTemplateState({
        name: info.name,
        email: info.email,
        subject: info.subject,
        template: info.template,
        nameTemplate: info.nameTemplate
      })
    }
  }, [id, data])

  useEffect(() => {
    templateHtml()
  }, [templateHtml])

  return (
    <Box sx={{ m: 2 }}>
      <Grid alignItems="center" justifyContent="center" container spacing={2} >
        <Grid item xs={12} md={4}>
          <Card elevation={0}>
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "inline-block"
                }}
              >
                Information générales
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>Infos expéditeur</Typography>
                <Select
                  value={templateState?.email}
                  fullWidth
                  name="sender"
                  onChange={handleSelect}
                >
                  {listSender?.getListSender.map((item: any, index: number) => (
                    <MenuItem
                      key={index}
                      value={item.email}
                    >{item.name} ({item.email})</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>Nom de l'éxpéditeur</Typography>
                <TextField
                  fullWidth
                  value={templateState?.name}
                  variant="outlined"
                  {...register("name", {
                    required: { value: true, message: "Ce champ est requis" }
                  })
                  }
                  error={errors.name && true}
                  helperText={errors.name?.message}
                  name="name"
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>Sujet du mail</Typography>
                <TextField
                  fullWidth
                  value={templateState?.subject}
                  variant="outlined"
                  {
                  ...register("subject", {
                    required: { value: true, message: "Ce champ est requis" },
                  })
                  }
                  error={errors.subject && true}
                  helperText={errors.subject?.message}
                  name="subject"
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>Nom du template</Typography>
                <TextField
                  fullWidth
                  value={templateState?.nameTemplate}
                  variant="outlined"
                  {
                  ...register("nameTemplate", {
                    required: { value: true, message: "Ce champ est requis" }
                  })
                  }
                  error={errors.nameTemplate && true}
                  helperText={errors.nameTemplate?.message}
                  name="nameTemplate"
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 1 }}>Template du mail</Typography>
                <TextField
                  value={html}
                  fullWidth
                  multiline
                  maxRows={6}
                  variant="outlined"
                  name="template"
                  onClick={redirectToBuilder}
                  onFocus={redirectToBuilder}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="outlined" sx={{ mr: 2 }}>Annuler</Button>
                <Button disabled={loading} variant="contained" onClick={handleSubmit(submitTemplate)}>
                  {loading ? "Sauvegarde en cours" : "Enregistrer"}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
