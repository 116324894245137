/* eslint-disable @typescript-eslint/no-empty-function */
import "./App.css"
import { ApolloProvider } from "@apollo/client"
import { BrowserRouter } from "react-router-dom"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { StylesProvider } from "@mui/styles"
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from "react"
import moment from "moment"
import "moment/locale/fr"
import { apolloClient } from "./apolloClient"
import { MainRouter } from "./routers"
import theme from "./theme"
import { ContextProvider } from "./contexts"
import { AuthStorage } from "./utils"

export const TokenContext = createContext<{
  token: string | undefined
  setToken: Dispatch<SetStateAction<string | undefined>>
}>({
  token: undefined,
  setToken: () => {}
})

moment.defineLocale("fr", null)
moment.locale("fr")
const App = (): JSX.Element => {
  const [token, setToken] = useState<string>()
  const tokenFromstorage = AuthStorage.isAuth()?.token
  const client = apolloClient(token || undefined)
  useEffect(() => {
    if (tokenFromstorage) {
      setToken(tokenFromstorage)
    }
  }, [tokenFromstorage])

  const memoizedContext = useMemo(() => ({ token, setToken }), [token])
  return (
    <BrowserRouter>
      <TokenContext.Provider value={memoizedContext}>
        <ApolloProvider client={client}>
          <ContextProvider>
            <ThemeProvider theme={theme}>
              <StylesProvider injectFirst>
                <CssBaseline />
                <MainRouter />
              </StylesProvider>
            </ThemeProvider>
          </ContextProvider>
        </ApolloProvider>
      </TokenContext.Provider>
    </BrowserRouter>
  )
}

export default App
