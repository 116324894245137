/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, TextField, styled, BoxProps } from "@mui/material"
import React, { FC } from "react"
import Autocomplete from "@mui/material/Autocomplete"

interface CustomAutocompleteProps {
  label?: string
  options?: any[]
  name: string
  containerProps?: BoxProps
  onChange: (value: any, name: string) => void
  optionLabel?: string
}
const CustomTextField = styled(TextField)(() => ({
  borderRadius: 10,
  "& .MuiOutlinedInput-root": {
    padding: 3
  }
}))
export const CustomAutocomplete: FC<CustomAutocompleteProps> = ({
  containerProps,
  options,
  label,
  name,
  optionLabel,
  onChange
}): JSX.Element => {
  const handleChange = (event: React.SyntheticEvent, value: any): void => {
    event.preventDefault()
    onChange(value, name)
  }
  return (
    <Box {...containerProps}>
      <Autocomplete
        disablePortal
        getOptionLabel={(option) =>
          optionLabel ? option[optionLabel] : option.label || option.name
        }
        id={`combo-box-demo-${name}`}
        options={options || []}
        noOptionsText="Aucune option"
        onChange={handleChange}
        renderInput={(params: any) => (
          <CustomTextField
            fullWidth
            {...params}
            label={label}
            variant="outlined"
            type="new-password"
            name={name}
          />
        )}
      />
    </Box>
  )
}
