import { Delete, Edit } from "@mui/icons-material"
import {
} from "@/graphql/eventParty"
import { Box } from "@mui/material"
import { ButtonLittleIcon, ButtonStatus, TableColumnProps } from "@/components"
import defaultPictures from "@/assets/logoSimple.png"
import { ListEventParty_listEventParty as IList } from "@/graphql/eventParty"
import moment from "moment"

interface TableEventProps {
  onView?: (b: IList) => void
  onRemove?: (id: number) => void
  onEdit?: (idEditProduct: number) => void
  onChangeStatus?: (item: boolean, id: number) => void
}

export const TableEvent = ({
  onEdit,
  onRemove,
  onChangeStatus
}: TableEventProps): { columns: TableColumnProps[] } => {
  const columns: TableColumnProps[] = [
    {
      id: "title",
      label: "Titre",
      component: (data: IList) => <Box>{data.title}</Box>
    },
    {
      id: "img",
      label: "Image",
      component: (data: IList) => (
        <Box
          sx={{
            width: 100,
            height: 100,
            m: 2,
            borderRadius: "5px",
            position: "relative"
          }}
        >
          <img
            src={data.img || defaultPictures}
            alt=""
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
      )
    },
    {
      id: "description",
      label: "Description",
      component: (data: IList) => <Box>{data.description}</Box>
    },
    {
      id: "date",
      label: "Date",
      component: (data: IList) => (
        <Box>
          <Box>Du {moment(data.dateStart).format("DD/MM/YYYY")}</Box>
          <Box>au {moment(data.dateEnd).format("DD/MM/YYYY")}</Box>
        </Box>
      )
    },
    {
      id: "hour",
      label: "Horaire",
      component: (data: IList) => (
        <Box>
          <Box>
            {moment(data.hourStart).format("HH:mm")}
            {" - "}
            {moment(data.hourEnd).format("HH:mm")}
          </Box>
        </Box>
      )
    },
    {
      id: "status",
      label: "Statut",
      component: (data: IList) => {
        if (data)
          return (
            <ButtonStatus item={data.status} id={data.id} onChange={onChangeStatus} />
          )
        return null
      }
    },
    {
      id: "action",
      label: "Action",
      component: (data: IList) => (
        <Box>
          <ButtonLittleIcon
            sx={{ mr: 1, mb: 1 }}
            icon={<Edit />}
            color="success"
            onClick={() => {
              if (onEdit) {
                onEdit(data.id)
              }
            }}
          />
          <ButtonLittleIcon
            sx={{ mr: 1, mb: 1 }}
            icon={<Delete />}
            color="error"
            onClick={() => {
              if (onRemove) {
                onRemove(data.id)
              }
            }}
          />
        </Box>
      )
    }
  ]
  return { columns }
}
