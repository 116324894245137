import {
  BUSINESS_GET_CLICK_BY_ID,
  UpdateBusinessInfo,
  UpdateBusinessInfoVariables,
  UPDATE_BUSINESS_INFO
} from "@/graphql/business"
import {
  useApplicationContext,
  UseClickNCollect,
  useCurrentBusiness
} from "@/hooks"
import { useMutation, useQuery } from "@apollo/client"
import {
  Card,
  Box,
  CardContent,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Grid,
  Button,
  SelectChangeEvent,
  CardMedia,
  CardActions,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react"
import { ButtonOpeningBusiness, QueryResult } from "@/components/common"
import defaultPictures from "@/assets/logoSimple.png"
import Swal from "sweetalert2"

export const ClickNCollect = () => {
  const { business } = useApplicationContext()
  const { data: dataBusiness, refetch: refetchBusiness } = useCurrentBusiness(
    business?.id
  )
  const { updateClick, createClick } = UseClickNCollect()
  const [clickState, setClickState] = useState({
    businessId: 0,
    currency: "euro",
    position: "left",
    thousandsSeparator: "space",
    decimalSeparator: "coma",
    decimalNumber: 2,
    exchangeRate: 0,
    deferredCommand: false
  })
  const [updateInfo] = useMutation<
    UpdateBusinessInfo,
    UpdateBusinessInfoVariables
  >(UPDATE_BUSINESS_INFO)
  const currencyList = [
    {
      name: "euro",
      currency: "€"
    },
    {
      name: "dollars",
      currency: "$"
    },
    {
      name: "franc",
      currency: "CHF"
    },
    {
      name: "roupie",
      currency: "RS"
    }
  ]
  const currencyType = currencyList.find(
    (item) => item.name === clickState.currency
  )
  const position = clickState.position === "left"
  const sepThouList = [
    {
      name: "dot",
      value: "."
    },
    {
      name: "space",
      value: " "
    },
    {
      name: "nospace",
      value: ""
    }
  ]
  const sepThou = sepThouList.find(
    (item) => item.name === clickState.thousandsSeparator
  )
  const sepDec = clickState.decimalSeparator === "coma" ? "," : "."
  const numDec = "0"
  const [hasClickState, setHasClickState] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const { data, refetch, loading } = useQuery(BUSINESS_GET_CLICK_BY_ID, {
    variables: {
      businessId: business?.id
    },
    skip: !business
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === "decimalNumber" || name === "exchangeRate") {
      setClickState({
        ...clickState,
        [name]: parseFloat(value)
      })

      return
    }
    setClickState({
      ...clickState,
      [name]: value
    })
  }

  const handleChangeSelect = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target
    if (name === "thousandsSeparator" && value === "dot") {
      setClickState({
        ...clickState,
        [name]: value,
        decimalSeparator: "coma"
      })

      return
    }

    if (name === "decimalSeparator" && value === "dot") {
      setClickState({
        ...clickState,
        [name]: value,
        thousandsSeparator: "space"
      })

      return
    }

    setClickState({
      ...clickState,
      [name]: value
    })
  }

  const handleSaveStatus = async (): Promise<void> => {
    setLoadingState(true)

    updateInfo({
      variables: {
        businessId: business?.id || 0,
        business: {
          opening: !dataBusiness?.opening
        }
      }
    })
      .then(() => {
        refetchBusiness()
        setLoadingState(false)
        return undefined
      })
      .catch(() => {
        setLoadingState(false)
        return undefined
      })
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (hasClickState) {
      const update = await updateClick(data?.clickCollectById?.id, clickState)
      if (update.success) {
        Swal.fire({
          icon: "success",
          title: "Votre modification enregistré",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
        refetch()
      }
    } else {
      const create = await createClick({
        ...clickState,
        businessId: business?.id || 0
      })
      if (create.success) {
        Swal.fire({
          icon: "success",
          title: "Votre modification enregistré",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
        refetch()
      }
    }
  }

  useEffect(() => {
    if (business) {
      if (data) {
        const dataClick = data?.clickCollectById
        if (!dataClick) {
          setClickState({
            businessId: business?.id || 0,
            currency: "euro",
            position: "right",
            thousandsSeparator: "space",
            decimalSeparator: "coma",
            decimalNumber: 2,
            exchangeRate: 0,
            deferredCommand: false
          })
          setHasClickState(false)

          return
        }
        setClickState({
          businessId: business?.id || 0,
          currency: dataClick?.currency || "euro",
          position: dataClick?.position || "right",
          thousandsSeparator: dataClick?.thousandsSeparator || "space",
          decimalSeparator: dataClick?.decimalSeparator || "coma",
          decimalNumber: parseFloat(dataClick?.decimalNumber),
          exchangeRate: parseFloat(dataClick?.exchangeRate) || 0,
          deferredCommand: dataClick?.deferredCommand
        })
        setHasClickState(true)
      }
    }
  }, [business, data])

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Option commande</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <ButtonOpeningBusiness
          status={dataBusiness?.opening}
          onChange={handleSaveStatus}
          loading={loadingState}
        />
      </Box>
      <Grid container alignItems="center" sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant="subtitle1">Option de devise</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={4}>
          <Card sx={{ mb: 1, width: "100%" }}>
            <CardContent>
              <QueryResult loading={loading} data={data} displayChild>
                <Box
                  sx={{
                    p: 2,
                    border: "0.3px solid grey",
                    borderRadius: "10px"
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        Devise
                      </Typography>
                      <Select
                        name="currency"
                        value={clickState.currency}
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value="euro">Euro €</MenuItem>
                        <MenuItem value="dollars">Dollars $</MenuItem>
                        <MenuItem value="franc">Franc suisse CHF</MenuItem>
                        <MenuItem value="roupie">
                          Roupie Mauricienne RS
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        Position devise
                      </Typography>
                      <Select
                        name="position"
                        value={clickState.position}
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value="left">Gauche</MenuItem>
                        <MenuItem value="right">Droite</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        Séparateur de milliers
                      </Typography>
                      <Select
                        name="thousandsSeparator"
                        value={clickState.thousandsSeparator}
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value="dot">. (point)</MenuItem>
                        <MenuItem value="space">Espace</MenuItem>
                        <MenuItem value="nospace">Sans espace</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        Séparateur des décimaux
                      </Typography>
                      <Select
                        name="decimalSeparator"
                        value={clickState.decimalSeparator}
                        onChange={handleChangeSelect}
                      >
                        <MenuItem value="coma">, (virgule)</MenuItem>
                        <MenuItem value="dot">. (point)</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        Nombre décimal
                      </Typography>
                      <TextField
                        type="number"
                        variant="outlined"
                        fullWidth
                        name="decimalNumber"
                        value={clickState.decimalNumber}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold", mb: 2 }}
                    >
                      Commande différée
                    </Typography>
                    <FormControl fullWidth>
                      <RadioGroup
                        value={clickState.deferredCommand}
                        onChange={(e: any) => {
                          setClickState((prev) => ({
                            ...prev,
                            deferredCommand:
                              e.target.value === "true" ? true : false
                          }))
                        }}
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Non"
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Oui"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      my: 2
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ color: "#050005" }}
                    >
                      Enregistrer
                    </Button>
                  </Box>
                </Box>
              </QueryResult>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
