import { gql } from "@apollo/client"

export const IMPORT_CSV = gql`
  mutation ImportClientFiles($data: ImportType!, $businessId: Float!) {
    importClientFiles(data: $data, businessId: $businessId) {
      id
      email
      phone
    }
  }
`
