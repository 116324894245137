import { useMutation } from "@apollo/client"
import {
  CreateQRCode,
  CreateQRCodeVariables,
  CREATE_QRCODE,
  RemoveQRCode,
  RemoveQRCodeVariables,
  REMOVE_QRCODE,
  UpdateQRCode,
  UpdateQRCodeVariables,
  UPDATE_QRCODE
} from "@/graphql/qrcode"
import { QRCodeInput, ReglementInput } from "@/types"
import { useApplicationContext } from "../application"

export const useQRCodeAction = () => {
  const { business } = useApplicationContext()
  const [createQRCodeExec, { loading }] = useMutation<
    CreateQRCode,
    CreateQRCodeVariables
  >(CREATE_QRCODE)
  const [updateQRCodeExec, { loading: loadingUpdate }] = useMutation<
    UpdateQRCode,
    UpdateQRCodeVariables
  >(UPDATE_QRCODE)
  const [removeQRCodeExec, { loading: loadingRemove }] = useMutation<
    RemoveQRCode,
    RemoveQRCodeVariables
  >(REMOVE_QRCODE)
  const createQRCode = async (
    inputs: QRCodeInput,
    reglement: ReglementInput,
    businessId?: number
  ) => {
    try {
      if (!business?.id && !businessId) {
        return undefined
      }
      const create = await createQRCodeExec({
        variables: {
          businessId: (business?.id as number) || (businessId as number),
          reglementInput: reglement,
          data: {
            ...inputs
          }
        }
      })
      return create.data
    } catch (error) {
      return undefined
    }
  }
  const updateQRCode = async (
    id: number,
    inputs: QRCodeInput,
    cb: () => void
  ) => {
    try {
      console.trace("TRace update")
      const create = await updateQRCodeExec({
        variables: {
          qrcodeId: id,
          data: {
            ...inputs
          }
        }
      })
      if (create.data) {
        cb()
      }
      return create.data
    } catch (error) {
      return undefined
    }
  }
  const removeQRCode = async (id: number, cb: () => void) => {
    try {
      const remove = await removeQRCodeExec({
        variables: {
          qrcodeId: id
        }
      })

      if (remove) {
        cb()
      }

      return remove
    } catch (error) {
      return error
    }
  }
  return {
    createQRCode,
    loading,
    updateQRCode,
    loadingUpdate,
    removeQRCode,
    loadingRemove
  }
}
