import { Typography, Stack, Switch } from "@mui/material"

type switchProps = {
  change: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status: any
  textActive?: boolean
}

export const SwitchStyle = ({ change, status, textActive }: switchProps) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography
        sx={{ fontWeight: "bold" }}
        variant="body2"
        color="textSecondary"
      >
        {
          textActive ? "Desactivé" : "Non-disponible"
        }
      </Typography>
      <Switch
        sx={{ m: 1 }}
        checked={status}
        onChange={change}
        inputProps={{ "aria-label": "controlled" }}
      />
      <Typography
        sx={{ fontWeight: "bold" }}
        variant="body2"
        color="textSecondary"
      >
        {
          textActive ? "Activé" : "Disponible"
        }
      </Typography>
    </Stack>
  )
}
