import { useMutation } from "@apollo/client"
import {
  ImportClientFiles,
  ImportClientFilesVariables,
  IMPORT_CSV
} from "../../graphql/import"
import { ImportClientInput } from "../../types"

export const useImportFile = () => {
  const [executeImport, { loading }] = useMutation<
    ImportClientFiles,
    ImportClientFilesVariables
  >(IMPORT_CSV)
  const importClientCSV = async (
    businessId: number,
    clients: ImportClientInput[]
  ) => {
    try {
      const imports = await executeImport({
        variables: {
          businessId,
          data: {
            clients
          }
        }
      })
      return imports
    } catch (error) {
      return undefined
    }
  }
  return {
    loading,
    importClientCSV
  }
}
