import { BusinessInfo_businessInfo as IBusiness } from "@/graphql/business"


export const businessMenu = (business?: IBusiness): any[] => {
  const menus = [
    {
      menu: "A propos",
      url: "/"
    },
    {
      menu: "Mentions Légales",
      url: business?.mentions
    },
    {
      menu: "Conditions d'utilisations",
      url: business?.conditions
    },
    {
      menu: "Contact",
      url: business?.siteweb
    },
    {
      menu: "Confidentialités",
      url: business?.confidentiality
    }
  ]

  return menus.filter((med) => med.url)
}
