import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react"
import { Lots_lots as Ilot } from "../../../graphql/lots"
import { RANDOM_COLOR } from "../../../utils"
import { Dropzone } from "../../dropzone"
import { TextInputBase } from "../TextInputBase"
import { useApplicationContext } from "@/hooks"

interface Values {
  title: string
  winFrequence: number
  description: string
  image: string
}

interface LotFormProps {
  loading?: boolean
  lot?: Ilot
  onCreate?: (values: Values) => void
  onUpdate?: (values: Values) => void
  onCancel?: () => void
}
export const LotForm: FC<LotFormProps> = ({
  loading,
  lot,
  onCreate,
  onUpdate,
  onCancel
}) => {
  const indexColor = Math.floor(Math.random() * 15)
  const randomColor = RANDOM_COLOR[indexColor]
  const { dispatchSnack } = useApplicationContext()
  const [values, setValues] = useState({
    title: "",
    winFrequence: 100,
    description: "",
    image: "",
    color: randomColor
  })
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: name === "winFrequence" ? parseFloat(value) : value
    }))
  }
  const isActive = () => {
    const requiredField = [
      values.title,
      values.description,
      values.winFrequence
    ]
    if (requiredField.length <= 0) return false
    if (values.title.length > 10) return false
    return requiredField.every((val) => Boolean(val))
  }
  const handleCreate = (e: FormEvent) => {
    e.preventDefault()

    if (lot) {
      onUpdate && onUpdate(values)
    } else {
      onCreate && onCreate(values)
    }
    setValues({
      title: "",
      winFrequence: 100,
      description: "",
      image: "",
      color: ""
    })
  }

  const handleImage = (file?: string) => {
    if (file) {
      setValues((prev) => ({
        ...prev,
        image: file
      }))
    }
  }
  useEffect(() => {
    if (lot) {
      setValues({
        title: lot.title || "",
        winFrequence: lot.winFrequence || 100,
        description: lot.description || "",
        image: lot.image || "",
        color: lot.color || ""
      })
    }
  }, [lot])

  return (
    <form onSubmit={handleCreate}>
      <Box sx={{ width: "100%", height: 250, margin: "auto", p: 2 }}>
        <Dropzone
          message="Image du lot"
          photo={values.image}
          btnSx={{ height: "100%" }}
          onFinished={handleImage}
        />
      </Box>
      <TextInputBase
        error={values.title.length > 10}
        label="Titre ou nom du lot"
        name="title"
        value={values.title}
        onChange={handleChange}
        sx={{ mb: 2 }}
        helperText={
          values.title.length > 10 &&
          "Le nombre de charactère maximal est de 10"
        }
      />
      <Typography variant="caption" sx={{ fontStyle: "italic" }}>
        * Nombre de scan = 1 gain de lot (ex: 200 scanner = 1 gagant )
      </Typography>
      <TextInputBase
        label="Fréquence de gain"
        type="number"
        name="winFrequence"
        value={values.winFrequence}
        onChange={handleChange}
        sx={{ mb: 2, mt: 1 }}
      />
      <TextInputBase
        multiline
        label="Description du lot"
        name="description"
        value={values.description}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <Box sx={{ p: 2, textAlign: "center" }}>
        <Button
          variant="outlined"
          sx={{ my: 1 }}
          onClick={() => {
            onCancel && onCancel()
          }}
        >
          Annuler
        </Button>{" "}
        {lot ? (
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            sx={{ my: 1 }}
            startIcon={
              loading ? (
                <CircularProgress style={{ width: 25, height: 25 }} />
              ) : null
            }
          >
            Mettre à jour
          </Button>
        ) : (
          <Button
            disabled={!isActive()}
            variant="contained"
            type="submit"
            sx={{ color: "#050005" }}
            startIcon={
              loading ? (
                <CircularProgress style={{ width: 25, height: 25 }} />
              ) : null
            }

          >
            Créer
          </Button>
        )}
      </Box>
    </form>
  )
}
