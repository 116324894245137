/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Box, Button, Collapse, ListItem } from "@mui/material"
import List from "@mui/material/List"
import { useState } from "react"
import { useLocation } from "react-router-dom"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NavItem = (props: any) => {
  const { active, icon, title, onClick, onClickSub, subMenus, ...others } =
    props
  const [open, setOpen] = useState(false)
  const location = useLocation()
  const isActive = (path: string): boolean => {
    if (!path && location.pathname.split("/").join(" ").trim() === "discount")
      return true

    const pathList = location.pathname.split("/")
    const lastPath = pathList[pathList.length - 2]
    const currentTrim = path.replace("/*", "").trim()
    const currentList = currentTrim?.split("/")
    const currentPath = currentList[currentList.length - 1]

    return lastPath === currentPath
  }
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <ListItem
      disableGutters
      sx={{
        display: "block",
        mb: 0.5,
        py: 0,
        px: 2
      }}
      {...others}
    >
      {
        subMenus ? (
          <Button
            component="div"
            startIcon={icon}
            disableRipple
            onClick={handleClick}
            sx={
              {
                backgroundColor: active && "rgba(255, 255, 255, 0.37)",
                borderRadius: 1,
                color: active ? "secondary.dark" : "#050005",
                justifyContent: "flex-start",
                px: 3,
                textAlign: "left",
                textTransform: "none",
                width: "100%",
                "& .MuiButton-startIcon": {
                  color: active ? "secondary.dark" : "#050005"
                },
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.37)"
                }
              } as any
            }
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Button>
        ) : (
          <Button
            component="a"
            startIcon={icon}
            disableRipple
            sx={
              {
                backgroundColor: active && "rgba(255, 255, 255, 0.67)",
                borderRadius: 1,
                color: active ? "secondary.dark" : "#050005",
                justifyContent: "flex-start",
                px: 3,
                textAlign: "left",
                textTransform: "none",
                width: "100%",
                "& .MuiButton-startIcon": {
                  color: active ? "secondary.dark" : "#050005"
                },
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.37)"
                }
              } as any
            }
            onClick={onClick}
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
          </Button>
        )
      }
      {subMenus ? (
        <Collapse in={open} unmountOnExit>
          <List>
            {subMenus.map((sub: any) => {
              return (
                <ListItem
                  key={sub.id}
                  disableGutters
                  sx={{
                    display: "flex",
                    mb: 0.5,
                    py: 0,
                    px: 2
                  }}
                  {...others}
                >
                  <Button
                    component="a"
                    startIcon={sub.icon}
                    disableRipple
                    sx={
                      {
                        backgroundColor: isActive(sub.path)
                          ? "primary.main"
                          : "rgba(255, 255, 255, 0.17)",
                        borderRadius: 1,
                        color: "#050005",
                        justifyContent: "flex-start",
                        px: 3,
                        textAlign: "left",
                        textTransform: "none",
                        width: "100%",
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.67)"
                        }
                      } as any
                    }
                    onClick={(): void => {
                      onClickSub(sub.path, sub.routeFunction)
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>{sub.label}</Box>
                  </Button>{" "}
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      ) : null}
    </ListItem>
  )
}
