/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography, Grid, Button, TextField } from "@mui/material"
import { useParams } from "react-router-dom"
import { Dropzone } from "@/components/dropzone"
import { useCurrentSousCategoryById } from "@/hooks/useCurrentSousCategoryById"
import { useUpdateSousCategory } from "@/hooks/category"
import { useEffect, useState } from "react"
import { SousCategoryInput } from "@/types"
import { SwitchStyle } from "@/components/common"
import { ConfirmModal } from "@/components/modals"
import { useApplicationContext } from "@/hooks"
import Swal from "sweetalert2"

export const EditSousCategory = () => {
  const { idEditSousCategory, id } = useParams()
  const { business } = useApplicationContext()
  const { sousCategoryUpdate, sousCategoryStatusUpdate } =
    useUpdateSousCategory()
  const { data, refetch } = useCurrentSousCategoryById(
    parseFloat(idEditSousCategory as string)
  )
  const [subCatState, setSubCatState] = useState<SousCategoryInput>({
    image: "",
    name: "",
    categoryIds: 0,
    status: true,
    businessId: business?.id || parseInt(id || "0")
  })
  const [open, setOpen] = useState(false)

  const updateName = (e: any) => {
    setSubCatState((prev) => ({
      ...prev,
      name: e.target.value
    }))
  }

  const updateFile = async (file?: string): Promise<void> => {
    setSubCatState((prev) => ({
      ...prev,
      image: file || ""
    }))
  }

  const handleUpload = async (file?: string): Promise<void> => {
    try {
      await sousCategoryUpdate(parseFloat(idEditSousCategory as string), {
        image: file
      })
      refetch()
    } catch (errors) {
      throw new Error("ERROR")
    }
  }

  const handleUpdate = async (): Promise<void> => {
    try {
      if (!idEditSousCategory) {
        return
      }
      await sousCategoryUpdate(parseFloat(idEditSousCategory as string), {
        name: subCatState.name
      }).then(() => {
        Swal.fire({
          icon: "success",
          title: "Votre modification enregistré",
          text: "",
          showConfirmButton: false,
          showCloseButton: true
        })
      })
      refetch()
    } catch (errors) {
      throw new Error("ERROR")
    }
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleOpenModal = (): void => {
    setOpen(true)
  }

  const handleStatus = async (): Promise<void> => {
    try {
      if (!idEditSousCategory) {
        return
      }
      await sousCategoryStatusUpdate(
        parseFloat(idEditSousCategory as string),
        !subCatState.status
      )
      refetch()
      setSubCatState({
        ...subCatState,
        status: !subCatState.status
      })
    } catch (errors) {
      throw new Error("ERROR")
    }
  }

  const saveAll = () => {
    handleUpdate()
    handleUpload(subCatState.image || "")
  }

  useEffect(() => {
    setSubCatState({
      image: data?.image || "",
      name: data?.name || "",
      categoryIds: data?.id || 0,
      status: data?.status === null ? true : data?.status,
      businessId: business?.id || parseInt(id || "0")
    })
  }, [data, business?.id, id])

  return (
    <>
      <Box
        sx={{
          p: 2,
          m: 3,
          mb: 1,
          border: "0.3px solid grey",
          borderRadius: "10px"
        }}
      >
        <Grid container justifyContent="center">
          <Grid item md={4}>
            <Typography
              variant="h3"
              sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}
            >
              Modification sous catégorie
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <SwitchStyle
                change={handleOpenModal}
                status={subCatState.status}
              />
            </Box>
            <Typography sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              Sous catégorie
            </Typography>
            <Box>
              <TextField
                name="name"
                variant="outlined"
                fullWidth
                value={subCatState.name || ""}
                onChange={updateName}
              />
            </Box>
            <Typography sx={{ fontWeight: "bold", mt: 1, mb: 2 }}>
              Image sous catégorie
            </Typography>
            <Box sx={{ pt: 2 }}>
              <Box
                sx={{
                  width: "100%",
                  height: 250,
                  mr: 1,
                  mb: 1,
                  position: "relative"
                }}
              >
                <Dropzone
                  onFinished={updateFile}
                  message="Ajouter PDF ou png ou JPEG"
                  photo={subCatState?.image || ""}
                  btnSx={{ height: "100%" }}
                />
              </Box>
            </Box>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Button variant="contained" onClick={saveAll}>
                Enregister
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        onCancel={handleCancel}
        onConfirm={handleStatus}
        confirmLabel="Modifier"
        isDeleting
        title="Changement de status"
      >
        <Typography>
          Cette action va changer tout les statuts des produits de la même
          sous-catégorie. Etes-vous sûr de vouloir changer ?
        </Typography>
      </ConfirmModal>
    </>
  )
}
export default EditSousCategory
