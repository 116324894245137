import { gql } from "@apollo/client"

export const CREATE_CLIENT = gql`
mutation CreateClient($data: CreateClientInput!, $businessId: Float) {
  createClient(data: $data, businessId: $businessId) {
      token
      client {
        id
        email
        phone
      }
    }
  }
`

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($data: CreateClientInput!, $clientId: Float!) {
    updateClient(data: $data, clientId: $clientId) {
      id
      email
      phone
      auth
      firstname
      lastname
      password
      status
      photo
      civilite
      codePostal
      city
      function
      keywords
      acceptSms
      acceptEmail
      type
      raisonSocial
      address
      source
      createdAt
      updatedAt
    }
  }
`

export const DELETE_CLIENT = gql`
  mutation DeleteClient($clientId: Float!) {
    deleteClient(clientId: $clientId) {
      id
      email
      phone
    }
  }
`
