import { TabsType, TabsWithRoutes } from "@/components"
import { Box } from "@mui/material"
import { ListeFacturation } from "./ListeFacturation"
import { ListAbonnement } from "./ListAbonnement"

const tabs: TabsType[] = [
  {
    component: <ListeFacturation />,
    id: "1",
    label: "Liste des facturations",
    index: true
  },
  {
    component: <ListAbonnement />,
    id: "2",
    label: "Liste des abonnements",
    path: "subscription_manager"
  }
]

export const Abonnement = () => {
  return (
    <Box sx={{ p: 2 }}>
      <TabsWithRoutes tabs={tabs} />
    </Box>
  )
}
