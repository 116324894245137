import { useMutation } from "@apollo/client"
import { AddFile, AddFileVariables, ADD_FILE } from "../graphql"
import {
  DeleteFile,
  DeleteFileVariables,
  DELETE_FILE
} from "../graphql/business"

export const useFileUpload = () => {
  const [saveFile, { data, loading, error }] = useMutation<
    AddFile,
    AddFileVariables
  >(ADD_FILE)
  const [
    deleteFile,
    { data: deleteData, loading: deleteLoading, error: deleteError }
  ] = useMutation<DeleteFile, DeleteFileVariables>(DELETE_FILE)
  return {
    data: data?.addFile,
    loading,
    error,
    saveFile,
    deleteData,
    deleteLoading,
    deleteError,
    deleteFile
  }
}
