import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { BaseTable, QueryResult } from "@/components"
import { Box } from "@mui/material"
import { ContactTable } from "./ContactTable"
import { configPagination } from "@/components/tables/config"
import {
  useApplicationContext,
  useCurrentBusinessClient,
} from "@/hooks"
import { Pagination, Button } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { ADD_CONTACT_MULTI } from "@/graphql/business/mutation"
import { useNavigate, useParams } from "react-router-dom"
import { AddMultipleContact, AddMultipleContactVariables } from "@/graphql/business/types/AddMultipleContact"
import { GET_CONTACT_LIST } from "@/graphql/emailing"
import { GetContactList, GetContactListVariables } from "@/graphql/emailing/types/GetContactList"

export const AddContact = () => {
  const { business } = useApplicationContext()
  const [page, setPage] = useState<number>(1)
  const [checked, setChecked] = useState<any[]>([])
  const [listContactId, setListContactId] = useState<number[]>([])
  const [addContactFn] = useMutation<AddMultipleContact, AddMultipleContactVariables>(ADD_CONTACT_MULTI)
  const { data, loading, } = useCurrentBusinessClient(
    business?.id
  )
  const { columns } = ContactTable()
  const { id } = useParams()
  const perPage = configPagination.rowPerPage
  const count = configPagination.count(data, perPage)
  const handleChangePage = (e: any, value: number) => {
    setPage(value)
  }
  const navigate = useNavigate()
  const { data: contactList } = useQuery<GetContactList, GetContactListVariables>(GET_CONTACT_LIST, {
    variables: {
      listId: Number(id)
    }
  })
  const handleSave = () => {
    Swal.showLoading()
    addContactFn({
      variables: {
        data: {
          listId: Number(id) || 0,
          listContact: listContactId
        }
      }
    }).then(() => {
      Swal.hideLoading()
      Swal.fire({
        icon: "success",
        title: "Ajout reussie",
        text: "",
        showConfirmButton: false,
        showCloseButton: false
      })
      setListContactId([])
      navigate(-1)
    }).catch(() => {
      Swal.fire({
        icon: "error",
        title: "Erreur lors de la création",
        text: "",
        showConfirmButton: false,
        showCloseButton: false
      })
    })
  }
  const handleCancel = () => {
    navigate(-1)
    setListContactId([])
  }

  useEffect(() => {
    const listId = contactList?.getContactList.map((item) => item.clientId) || []
    setChecked(listId)
  }, [contactList])

  return (
    <Box sx={{ m: 2 }}>
      <QueryResult data={data} loading={loading}>
        <>
          <BaseTable
            data={data || []}
            columns={columns}
            page={page - 1}
            title="Liste des clients"
            checkbox
            indexed="id"
            onCheckedChange={(ids: any[]) => setListContactId(ids)}
            listChecked={checked}
            topComponent={
              <Box sx={{ my: 3 }}>
                <Button variant="contained" sx={{ mr: 2 }} onClick={handleSave}>
                  Enregistrer
                </Button>
                <Button variant="outlined" onClick={handleCancel}>Annuler</Button>
              </Box>
            }
          />
          <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
            <Pagination
              color="primary"
              page={page}
              count={count}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          </Box>
        </>
      </QueryResult>
    </Box>
  )
}
