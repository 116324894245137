import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  useMediaQuery,
  List
} from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import { useApplicationContext, useRouteValidation } from "@/hooks"
import { AdminType } from "@/types"
import Logo from "@/assets/logo_bg.png"
import { NavItem } from "./NavItem"
import { ArrowBack } from "@mui/icons-material"
import { SyntheticEvent } from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DashboardSidebar = (props: any) => {
  const { open, onClose } = props
  const { user, isAdminModeBusiness, returnToAdmin } =
    useApplicationContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false
  })
  const navigate = useNavigate()
  const location = useLocation()
  const { navbarMenu } = useRouteValidation()

  const handleNavigate = (path?: string, fun?: () => void): void => {
    const pathEdited = path && path.endsWith("*") ? path.replace("*", "") : path
    if (fun) {
      fun()
    }
    navigate(`/discount/${pathEdited ?? ""}`)
  }

  const handleClickLogo = () => {
    navigate("/discount")
  }
  const isActive = (path?: string): boolean => {
    if (!path && location.pathname.split("/").join(" ").trim() === "discount")
      return true

    const pathList = location.pathname.split("/")
    const lastPath = pathList[pathList.length - 2]
    const currentPath = path?.replace("*", "")?.replace("/", "")?.trim()

    return lastPath === currentPath
  }
  const handleSwitchToAdmin = (e: SyntheticEvent) => {
    e.preventDefault()
    returnToAdmin()
    navigate(`/discount/etablissements/`)
  }
  const isNotAdmin = user?.type !== AdminType.ADMIN
  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "inherit",
        backgroundColor: "#FECA00"
      }}
    >
      <div>
        <Box
          sx={{
            p: 3,
            textAlign: "center",
            pt: 2,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Avatar
            src={Logo}
            style={{
              width: 200,
              height: 125,
              margin: "auto",
              padding: 10
            }}
            variant="rounded"
            onClick={handleClickLogo}
          />
        </Box>
        {isAdminModeBusiness ? (
          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="outlined"
              sx={{ textTransform: "none", color: "#050005", borderColor: "#050005", outlineColor: "#050005" }}
              startIcon={<ArrowBack />}
              onClick={handleSwitchToAdmin}
            >
              Retour en tant qu'Admin
            </Button>
          </Box>
        ) : null}
      </div>
      <Divider
        sx={{
          borderColor: "#2D3748",
          my: 3
        }}
      />
      <List sx={{ flexGrow: 1 }}>
        {navbarMenu.map((item) => (
          <NavItem
            key={item.id}
            icon={item.icon}
            title={item.label}
            active={isActive(item.path)}
            subMenus={item.subMenus}
            onClick={() => handleNavigate(item.path, item.routeFunction)}
            onClickSub={(path: string, fun?: () => void) =>
              handleNavigate(path, fun)
            }
          />
        ))}
      </List>
      <Divider sx={{ borderColor: "#2D3748" }} />
    </Box>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "#FECA00",
            color: "#FFFFFF",
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "#FECA00",
          color: "#FFFFFF",
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}
