import { useMutation, useQuery } from "@apollo/client"
import {
  CreateLot,
  CreateLotVariables,
  CREATE_LOT,
  Lots,
  LOTS,
  LotsVariables,
  RemoveLot,
  RemoveLotVariables,
  REMOVE_LOT,
  UpdateLot,
  UpdateLotVariables,
  UPDATE_LOT
} from "../../graphql/lots"
import { LotInput, UpdateLotInput } from "../../types"
import { useApplicationContext } from "../application"

export const useCreateLots = () => {
  const { business } = useApplicationContext()

  const [createNewLot, { loading }] = useMutation<
    CreateLot,
    CreateLotVariables
  >(CREATE_LOT)

  const createLot = async (inputs: LotInput, businessId?: number) => {
    try {
      const lot = createNewLot({
        variables: {
          businessId: (business?.id as number) || (businessId as number),
          data: {
            ...inputs
          }
        }
      })
      return lot
    } catch (error) {
      return undefined
    }
  }

  return { createLot, loading }
}

export const useUpdateLots = () => {
  const [updateLotFn, { loading }] = useMutation<UpdateLot, UpdateLotVariables>(
    UPDATE_LOT
  )

  const updateLot = async (id: number, inputs: UpdateLotInput) => {
    try {
      const lot = updateLotFn({
        variables: {
          lotId: id,
          data: {
            ...inputs
          }
        }
      })
      return lot
    } catch (error) {
      return undefined
    }
  }

  return { updateLot, loading }
}
export const useDeleteLots = () => {
  const [deleteLotFn, { loading }] = useMutation<RemoveLot, RemoveLotVariables>(
    REMOVE_LOT
  )

  const deleteLot = async (id: number) => {
    try {
      const lot = deleteLotFn({
        variables: {
          lotId: id
        }
      })
      return lot
    } catch (error) {
      return undefined
    }
  }

  return { deleteLot, loading }
}

export const useLots = (businessId?: number) => {
  const { business } = useApplicationContext()

  const { data, loading, refetch } = useQuery<Lots, LotsVariables>(LOTS, {
    variables: {
      businessId: (business?.id as number) || (businessId as number)
    },
    skip: !business?.id && !businessId
  })

  return { data, loading, refetch }
}
