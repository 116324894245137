import { CircularProgress } from "@mui/material"

type MyProps = {
  data: object | undefined | null
  loading: boolean
  children: JSX.Element
  displayChild?: boolean
}

export const QueryResult = ({
  loading,
  data,
  children,
  displayChild
}: MyProps) => {
  if (loading) return <CircularProgress />
  if (!data) {
    if (displayChild) {
      return children
    }

    return <p>Rien à afficher ici...</p>
  }
  if (data) {
    return children
  }

  return null
}
