import { useParams } from "react-router-dom"
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react"
import {
  Typography,
  Box,
  Grid,
  Card,
  Chip,
  Avatar,
  Button,
  TextField,
  CircularProgress,
  Autocomplete
} from "@mui/material"
import moment from "moment"
import { useApplicationContext, useOneClient, useUpdateClient } from "@/hooks"
import { Loading } from "@/components"
import {
  ClientStatusType,
  CreateClientInput,
  TagExpired,
} from "@/types"
import { Source } from "@/components/source/Source"
import { Controller, useForm } from "react-hook-form"
import PhoneInput from "react-phone-input-2"
import { PhoneContainer } from "../components/Contact"
import { useQuery } from "@apollo/client"
import { TAGLIST, TagList_tagList as Itag } from "@/graphql/tag"
import { useMutation } from "@apollo/client"
import { UPDATETAGCLIENT } from "@/graphql/tag"

interface TagListType {
  id: number
  name: string
  expired: TagExpired | null
  businessId: number | null
  listBrevoId: number | null
  listContactId: number | null
}

export const ClientInfos = (): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [dataFormat, setDataFormat] = useState<Itag[]>([])
  const [tagInfoState, setTagInfoState] = useState<TagListType[]>([])
  const [updateTagClientFn] = useMutation(UPDATETAGCLIENT)
  const { data, getClient, loading, refetch } = useOneClient()
  const { updateClient, updateLoading } = useUpdateClient()
  const { id } = useParams()
  const { dispatchSnack, business } = useApplicationContext()
  const { data: dataTag, loading: loadingTag } = useQuery(TAGLIST, {
    variables: {
      businessId: business?.id
    },
    skip: !business?.id
  })
  const handleSaveInfo = async (data: CreateClientInput): Promise<void> => {
    if (!data.phone) {
      dispatchSnack({
        open: true,
        severity: "error",
        message: "Veuillez mettre un numero valable"
      })
      return
    }
    try {
      if (!id) {
        return
      }
      await updateClient(parseInt(id), data)
      await refetch()
      dispatchSnack({
        open: true,
        severity: "success",
        message: "Client modifié"
      })
    } catch (errors) {
      return undefined
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm<CreateClientInput>({
    mode: "onBlur"
  })

  useEffect(() => {
    if (data) {
      reset({
        firstname: data.firstname,
        lastname: data.lastname,
        city: data.city,
        address: data.address,
        civilite: data.civilite,
        function: data.function,
        email: data.email,
        phone: data.phone,
        codePostal: data.codePostal,
        raisonSocial: data.raisonSocial
      })

      data.tags.forEach((item) => {
        const selected = dataFormat.find((i) => i.id === item.tagId)
        if (selected) {
          setTagInfoState((prev) => (
            [
              ...prev,
              selected
            ]
          ))
        }
      })

    }
  }, [data, reset])

  useEffect(() => {
    if (dataTag) {
      setDataFormat(dataTag.tagList)
    }
  }, [dataTag])

  const loadingStyle: CSSProperties = loading
    ? {
      opacity: 0.3,
      pointerEvents: "none"
    }
    : {}

  const loadInfo = useCallback(async () => {
    if (id) {
      await getClient(parseInt(id))
    }
    if (containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      })
    }
  }, [id])
  useEffect(() => {
    loadInfo()
  }, [loadInfo])
  if (loading && !data) {
    return <Loading />
  }

  return (
    <Box sx={{ ...loadingStyle, p: 2 }} ref={containerRef}>
      <Box>
        <Box>
          <Card sx={{ p: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Profil client
              </Typography>
              <Box sx={{ py: 2 }}>
                <Avatar src={data?.photo || ""}>
                  {data?.firstname?.charAt(0)}
                  {data?.lastname?.charAt(0)}
                </Avatar>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    border: "0.3px solid grey",
                    borderRadius: "10px"
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Typography>Prénom</Typography>
                    <TextField
                      fullWidth
                      error={errors.firstname && true}
                      {...register("firstname", { required: true })}
                      helperText={errors.firstname && "Ce champ est requis"}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography>Nom </Typography>
                    <TextField
                      fullWidth
                      error={errors.lastname && true}
                      {...register("lastname", { required: true })}
                      helperText={errors.lastname && "Ce champ est requis"}
                    />
                  </Box>
                  <Box>
                    <Typography>Civilité </Typography>
                    <TextField
                      fullWidth
                      {...register("civilite")}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    border: "0.3px solid grey",
                    borderRadius: "10px"
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Typography sx={{ pb: 1 }}>Téléphone </Typography>
                    <PhoneContainer>
                      <Controller
                        control={control}
                        name="phone"
                        rules={{ required: false }}
                        render={({ field: { ref, value, ...field } }) => (
                          <PhoneInput
                            {...field}
                            enableSearch
                            inputProps={{
                              ref,
                              required: false,
                              autoFocus: true
                            }}
                            country="fr"
                            value={value}
                            preferredCountries={["fr", "us"]}
                          />
                        )}
                      />
                    </PhoneContainer>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography>E-mail</Typography>
                    <TextField
                      fullWidth
                      error={errors.email && true}
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Ce champ est requis"
                        },
                        pattern: {
                          value:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          message: "Entrer un email valide"
                        }
                      })}
                      helperText={errors.email?.message}
                    />
                  </Box>
                  <Box>
                    <Typography>Fonction </Typography>
                    <TextField
                      fullWidth
                      {...register("function")}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    border: "0.3px solid grey",
                    borderRadius: "10px"
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Typography>Code Postal</Typography>
                    <TextField
                      fullWidth
                      {...register("codePostal")}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography>Adresse</Typography>
                    <TextField
                      fullWidth
                      {...register("address")}
                    />
                  </Box>
                  <Box>
                    <Typography>Ville</Typography>
                    <TextField
                      fullWidth
                      {...register("city")}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
        <Box>
          <Card sx={{ p: 2 }} elevation={1}>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Informations gestion
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    border: "0.3px solid grey",
                    borderRadius: "10px"
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Typography>Date de création </Typography>
                    <Typography>
                      {moment(data?.createdAt).format("DD/MM/YYYY")}{" "}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <Typography>Mot Clés</Typography>
                    <Autocomplete
                      multiple
                      size="small"
                      options={dataFormat}
                      getOptionLabel={(option) => option.name}
                      value={tagInfoState}
                      onChange={(e, val) => {
                        const arr = val.map((item) => ({
                          tagId: item.id,
                          listId: item.listBrevoId,
                          listContactId: item.listContactId
                        }))
                        setTagInfoState(val)
                        updateTagClientFn({
                          variables: {
                            clientId: data?.id,
                            clientTagList: {
                              clientTag: arr
                            }
                          }
                        })
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 1, color: "#050005" }}>Statut :</Typography>
                    <Chip label={data?.status as any} color="primary" />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    border: "0.3px solid grey",
                    borderRadius: "10px"
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Typography>Date de la dernière modification </Typography>
                    <Typography>
                      {moment(data?.updatedAt).format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography>Raison sociale</Typography>
                    <TextField
                      fullWidth
                      {...register("raisonSocial")}
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mr: 1 }}>Source :</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {Source(data?.source as any).icon}
                      <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {Source(data?.source as any).text}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box
                  sx={{
                    p: 2,
                    border: "0.3px solid gvalrey",
                    borderRadius: "10px"
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Typography>Type: </Typography>
                    <Typography>
                      {data?.type &&
                        data?.type ===
                        ClientStatusType.PARTICULIER
                        ? "Particulier"
                        : "Professionnel"}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography>Campagnes email: </Typography>
                    <Typography>
                      {data?.acceptEmail ? "OUI" : "NON" || ""}{" "}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography>Campagnes SMS: </Typography>
                    <Typography>
                      {data?.acceptSms ? "OUI" : "NON" || ""}{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <Button
                disabled={updateLoading}
                onClick={handleSubmit(handleSaveInfo)}
                variant="contained"
                sx={{ color: "#050005" }}
              >
                {updateLoading && <CircularProgress sx={{ color: "white" }} />}
                Valider
              </Button>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  )
}
