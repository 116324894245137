import ImportExportIcon from "@mui/icons-material/ImportExport"
import KeyboardIcon from "@mui/icons-material/Keyboard"
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline"
import { Facebook } from "../icons/facebook"
import { GoogleIcon } from "../../icons/icons"
import { SourceType } from "../../types"

export const Source = (source: SourceType) => {
  switch (source) {
    case SourceType.FACEBOOK:
      return { text: "Facebook", icon: <Facebook /> }

    case SourceType.GOOGLE:
      return { text: "Google", icon: <GoogleIcon /> }

    case SourceType.MANUAL:
      return { text: "Création manuelle", icon: <DriveFileRenameOutlineIcon /> }

    case SourceType.FORM:
      return { text: "Formulaire d'inscription", icon: <KeyboardIcon /> }

    case SourceType.IMPORT:
      return { text: "Importer", icon: <ImportExportIcon /> }
    default:
      return { text: "", icon: <div /> }
  }
}
