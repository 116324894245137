import {
  ButtonLittleIcon,
  ConfirmModal,
  CustomModal,
  QueryResult
} from "@/components"
import { Add, Beenhere, Delete, Edit } from "@mui/icons-material"
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  styled,
  Divider
} from "@mui/material"
import { useEffect, useState } from "react"
import { GererAbonnement } from "./GererAbonnement"
import { useMutation, useQuery } from "@apollo/client"
import {
  CREATE_SUBSCRIPTION,
  DELETE_ABONNEMENT,
  DeleteAbonnement,
  DeleteAbonnementVariables,
  LIST_ABONNEMENT,
  LIST_SUBSCRIPTION,
  ListAbonnement_listAbonnement,
  BUY_PACK
} from "@/graphql/abonnement"
import { useApplicationContext } from "@/hooks"
import { AdminType } from "@/types"
import {
  CreateSubscription,
  CreateSubscriptionVariables
} from "@/graphql/abonnement/types/CreateSubscription"
import {
  GetSubscriptionList,
  GetSubscriptionListVariables
} from "@/graphql/abonnement/types/GetSubscriptionList"
import { PackModal } from "./PackModal"
import {
  GET_LIST_PACK,
  DELETE_PACK,
  GetListPack_GetListPack
} from "@/graphql/emailing"
import { DISABLE_BUSINESS } from "@/graphql/business"

const ButtonTop = styled(Button)(() => ({
  textTransform: "none",
  marginLeft: 15
}))

export const ListAbonnement = () => {
  const { dispatchSnack, user, business, logout } = useApplicationContext()
  const isAdmin = user?.type === AdminType.ADMIN
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openPack, setOpenPack] = useState(false)
  const [openPackEdit, setOpenPackEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [openUnsubscribe, setOpenUnsubscribe] = useState(false)
  const [openUrl, setOpenUrl] = useState(false)
  const [openUrlPack, setOpenUrlPack] = useState(false)
  const [listAbo, setListAbo] = useState<number[]>([])
  const [toDeleteId, setToDeleteId] = useState<number>()
  const [editItem, setEditItem] = useState<ListAbonnement_listAbonnement>()
  const [editPack, setEditPack] = useState<GetListPack_GetListPack>()
  const { data, loading, refetch } = useQuery(LIST_ABONNEMENT)
  const {
    data: pack,
    loading: packLoading,
    refetch: packRefetch
  } = useQuery(GET_LIST_PACK)
  const [deletePackId, setDeletePackId] = useState<number>()
  const { data: dataSub, loading: loadingSub } = useQuery<
    GetSubscriptionList,
    GetSubscriptionListVariables
  >(LIST_SUBSCRIPTION, {
    variables: {
      businessId: business?.id || 0
    },
    skip: !business?.id
  })
  const [deleteAbonnement] = useMutation<
    DeleteAbonnement,
    DeleteAbonnementVariables
  >(DELETE_ABONNEMENT)
  const [disableBusinessFn] = useMutation(DISABLE_BUSINESS)
  const [deletePack] = useMutation(DELETE_PACK)
  const handleCancelPack = () => {
    setDeletePackId(undefined)
    setConfirmDelete(false)
  }
  const handleCancelDelete = () => {
    setToDeleteId(undefined)
    setOpenDelete(false)
  }
  const handleConfirmDelete = async () => {
    if (!toDeleteId) {
      setOpenDelete(false)
      return
    }
    const deleteProducts = await deleteAbonnement({
      variables: {
        abonnementId: toDeleteId
      }
    })
    if (deleteProducts) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "suppression avec succès"
      })
    }
    refetch()
    setToDeleteId(undefined)
    setOpenDelete(false)
  }
  const handleDeletePack = async () => {
    if (!deletePackId) {
      setConfirmDelete(false)
      return
    }
    const delPack = await deletePack({
      variables: {
        deletePackId
      }
    })
    if (delPack) {
      dispatchSnack({
        open: true,
        severity: "success",
        message: "suppression avec succès"
      })
    }
    packRefetch()
    setDeletePackId(undefined)
    setConfirmDelete(false)
  }

  const handleUnsubscribe = () => {
    disableBusinessFn({
      variables: {
        businessId: business?.id
      }
    }).then(() => {
      logout()
    })
    setOpenUnsubscribe(false)
  }

  const [subscribe, { data: dataPayment, loading: loadingPayement }] =
    useMutation<CreateSubscription, CreateSubscriptionVariables>(
      CREATE_SUBSCRIPTION
    )

  const [buyPack, { data: packData, loading: buyPackLoading }] =
    useMutation(BUY_PACK)

  const checkSub = (a: any, b: number[]) => {
    return b.includes(a)
  }

  useEffect(() => {
    if (dataSub) {
      const aboSub = dataSub.getSubscriptionList.map((item) => {
        if (item.Abonnements) {
          return item.Abonnements[0]?.abonnementId
        }

        return 0
      })
      setListAbo(aboSub)
    }
  }, [business, dataSub])

  useEffect(() => {
    if (!open) {
      refetch()
    }
  }, [refetch, open, openEdit, data])

  useEffect(() => {
    if (!openPack) {
      packRefetch()
    }
  }, [packRefetch, openPack, openPackEdit, pack])

  /****responsive styles */
  const mobileCenter = {
    "@media(max-width:767px)": {
      justifyContent: "center"
    }
  }

  /*****end */

  return (
    <Box p={4}>
      {isAdmin && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            mb: 2,
            ...mobileCenter
          }}
        >
          <ButtonTop
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setOpen(true)}
            sx={{ color: "#050005" }}
          >
            Ajouter un abonnement
          </ButtonTop>
        </Box>
      )}
      <QueryResult data={data} loading={loading}>
        <Grid container spacing={2} justifyContent="center">
          {data?.listAbonnement.map((item: ListAbonnement_listAbonnement) => {
            if (item.status)
              return (
                <Grid key={item.id} item md={3}>
                  <Card sx={{ borderRadius: 8 }}>
                    <CardContent>
                      {isAdmin && (
                        <Box>
                          <ButtonLittleIcon
                            sx={{ mr: 1, mb: 1 }}
                            icon={<Edit />}
                            color="success"
                            onClick={() => {
                              setEditItem(item)
                              setOpenEdit(true)
                            }}
                          />
                          <ButtonLittleIcon
                            sx={{ mr: 1, mb: 1 }}
                            icon={<Delete />}
                            color="error"
                            onClick={() => {
                              setToDeleteId(item.id)
                              setOpenDelete(true)
                            }}
                          />
                        </Box>
                      )}
                      <Box textAlign="center">
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ fontWeight: "bold" }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <p>{item.description}</p>
                      <Box pl={4} mb={3}>
                        {item.numberBooking ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> {item.numberBooking}{" "}
                            réservations
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.numberClick ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> {item.numberClick}{" "}
                            click'n collect
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.numberSubscription ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} />{" "}
                            {item.numberSubscription} inscriptions
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.prixEmail ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> le prix des emails{" "}
                            {item.prixEmail} €
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.prixSms ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> le prix des SMS{" "}
                            {item.prixSms} €
                          </Typography>
                        ) : (
                          ""
                        )}
                        {item.freeTrial ? (
                          <Typography
                            component="div"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1
                            }}
                          >
                            <Beenhere sx={{ mr: 1 }} /> {item.percentOff}% de
                            réduction pendant {item.freeTrial} mois
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Typography
                        textAlign="center"
                        variant="h1"
                        component="div"
                        sx={{ fontWeight: "bold", mb: 4 }}
                      >
                        {item.prixAbonnement} €
                        <Typography component="sup"> HT/mois</Typography>
                      </Typography>
                      {!isAdmin ? (
                        <Box textAlign="center">
                          <Button
                            disabled={
                              loadingSub ? true : checkSub(item.id, listAbo)
                            }
                            variant="outlined"
                            onClick={() => {
                              subscribe({
                                variables: {
                                  priceId: item.priceStripeId || "",
                                  data: {
                                    abonnementId: item.id,
                                    businessId: business?.id
                                  }
                                }
                              })
                              setOpenUrl(true)
                            }}
                            sx={{ color: "#050005" }}
                          >
                            Choisir cet abonnement
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )
            return ""
          })}
        </Grid>
      </QueryResult>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 3
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setOpenUnsubscribe(true)
          }}
        >
          Se desabonner
        </Button>
      </Box>
      <Divider sx={{ my: 5 }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h4"
          component="div"
          sx={{ fontWeight: "bold", mb: 4 }}
        >
          Nos offres SMS
        </Typography>
        {isAdmin && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              mb: 2,
              ...mobileCenter
            }}
          >
            <ButtonTop
              variant="outlined"
              startIcon={<Add />}
              onClick={() => setOpenPack(true)}
              sx={{ color: "#050005" }}
            >
              Ajouter un pack
            </ButtonTop>
          </Box>
        )}
      </Box>
      <QueryResult data={pack} loading={packLoading}>
        <Grid container spacing={2} justifyContent="center">
          {pack?.GetListPack.map((item: any) => {
            if (item.status)
              return (
                <Grid item md={3}>
                  <Card sx={{ borderRadius: 8 }}>
                    <CardContent>
                      {isAdmin ? (
                        <Box>
                          <ButtonLittleIcon
                            sx={{ mr: 1, mb: 1 }}
                            icon={<Edit />}
                            color="success"
                            onClick={() => {
                              setEditPack(item)
                              setOpenPackEdit(true)
                            }}
                          />
                          <ButtonLittleIcon
                            sx={{ mr: 1, mb: 1 }}
                            icon={<Delete />}
                            color="error"
                            onClick={() => {
                              setConfirmDelete(true)
                              setDeletePackId(item.id)
                            }}
                          />
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box textAlign="center">
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ fontWeight: "bold" }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <p>{item.description}</p>
                      <Box pl={4} mb={3}>
                        <Typography
                          component="div"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 1
                          }}
                        >
                          <Beenhere sx={{ mr: 1 }} /> {item.sms}
                          SMS
                        </Typography>
                      </Box>
                      <Typography
                        textAlign="center"
                        variant="h1"
                        component="div"
                        sx={{ fontWeight: "bold", mb: 4 }}
                      >
                        {item.price} €
                        <Typography component="sup"> HT</Typography>
                      </Typography>
                      {!isAdmin ? (
                        <Box textAlign="center">
                          <Button
                            variant="outlined"
                            sx={{ color: "#050005" }}
                            onClick={() => {
                              buyPack({
                                variables: {
                                  buyPackId: item.id,
                                  businessId: business?.id
                                }
                              })
                              setOpenUrlPack(true)
                            }}
                          >
                            Acheter le pack
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )
          })}
        </Grid>
      </QueryResult>
      <CustomModal open={open} setOpen={setOpen} noAction>
        <GererAbonnement setOpen={setOpen} />
      </CustomModal>
      <CustomModal open={openEdit} setOpen={setOpenEdit} noAction>
        <GererAbonnement setOpen={setOpenEdit} editiedItem={editItem} />
      </CustomModal>
      <CustomModal open={openPack} setOpen={setOpenPack} noAction>
        <PackModal setOpen={setOpenPack} />
      </CustomModal>
      <CustomModal open={openPackEdit} setOpen={setOpenPackEdit} noAction>
        <PackModal setOpen={setOpenPackEdit} editiedItem={editPack} />
      </CustomModal>
      <CustomModal open={openUrl} setOpen={setOpenUrl} noAction>
        <QueryResult data={dataPayment} loading={loadingPayement}>
          <Box
            sx={{
              textAlign: "center",
              p: 4
            }}
          >
            <Box mb={4}>
              Pour finaliser votre abonnement, vous allez être redirigé vers la
              page de payement de "STRIPE".
            </Box>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const link = document.createElement("a")
                  link.href = dataPayment?.createSubscription || ""
                  link.setAttribute("target", "_blank")
                  document.body.appendChild(link)
                  link.click()
                  link.remove()
                }}
              >
                Souscrire
              </Button>
            </Box>
          </Box>
        </QueryResult>
      </CustomModal>
      <CustomModal open={openUrlPack} setOpen={setOpenUrlPack} noAction>
        <QueryResult data={packData} loading={buyPackLoading}>
          <Box
            sx={{
              textAlign: "center",
              p: 4
            }}
          >
            <Box mb={4}>
              Pour finaliser votre achat, vous allez être redirigé vers la page
              de payement de "STRIPE".
            </Box>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const link = document.createElement("a")
                  link.href = packData?.BuyPack || ""
                  link.setAttribute("target", "_blank")
                  document.body.appendChild(link)
                  link.click()
                  link.remove()
                }}
              >
                Acheter
              </Button>
            </Box>
          </Box>
        </QueryResult>
      </CustomModal>
      <ConfirmModal
        open={openDelete}
        setOpen={setOpenDelete}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression de l'abonnement"
      >
        <Typography>
          Vous êtes sur le point de supprimer un abonnement
        </Typography>
      </ConfirmModal>
      <ConfirmModal
        open={confirmDelete}
        setOpen={setConfirmDelete}
        onCancel={handleCancelPack}
        onConfirm={handleDeletePack}
        confirmLabel="Supprimer"
        isDeleting
        title="Suppression du pack"
      >
        <Typography>Vous êtes sur le point de supprimer un pack</Typography>
      </ConfirmModal>
      <ConfirmModal
        open={openUnsubscribe}
        setOpen={setOpenUnsubscribe}
        onCancel={handleCancelPack}
        onConfirm={handleUnsubscribe}
        confirmLabel="Confirmer"
        isDeleting
        title="Fermeture du magasin"
      >
        <Typography>
          Vous êtes sur le point de fermer votre magasin ! On vous désabonne,
          vous fermez votre magasin et les informations seront gardées pour vous
          pour une durée d'un mois avant d'être supprimées définitivement de
          notre base de donnée.
        </Typography>
      </ConfirmModal>
    </Box>
  )
}
