import { Add } from "@mui/icons-material"
import ImportExport from "@mui/icons-material/ImportExport"
import { Box, Button, styled, Typography } from "@mui/material"
import { FC } from "react"
import ContactsIcon from "@mui/icons-material/Contacts"

const ButtonTop = styled(Button)(() => ({
  textTransform: "none",
  marginRight: 15,
  /*****responsive styles */
    '@media(max-width:523px)' : {
      margin: "5px"
    }
}))

interface NoClientProps {
  onAdd?: () => void
  onImport?: () => void
}

export const NoClient: FC<NoClientProps> = ({ onAdd, onImport }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ p: 2, textAlign: "center" }}>
        <Box>
          <ContactsIcon
            color="inherit"
            fontSize="large"
            sx={{ width: 100, height: 100, fill: "#999" }}
          />
        </Box>
        <Box>
          <Box sx={{ p: 2 }}>
            <Typography variant="h2">Bienvenue</Typography>
            <Typography variant="subtitle1">
              Tous vos contacts seront affichés sur cette page
            </Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ fontSize: 20 }}>
            Commencez par créer ou importer vos contacts.
          </Typography>
          <Typography>
            Vous pouvez voir ensutie ke détail de chaque contact et ajouter des
            champs pour personnaliser les informations selon vos besoins.
          </Typography>
          <Box sx={{ py: 2 }}>
            <ButtonTop
              variant="contained"
              startIcon={<Add />}
              onClick={() => onAdd && onAdd()}
              sx={{ color: "#050005" }}
            >
              créer un contact
            </ButtonTop>
            <ButtonTop
              color="secondary"
              variant="contained"
              startIcon={<ImportExport />}
              onClick={() => onImport && onImport()}
            >
              Importer des contacts
            </ButtonTop>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
