import { Box, Button, Card, CardProps, Typography } from "@mui/material"
import { ButtonLittleIcon } from "@/components"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Close } from "@mui/icons-material"
import { FC, useState } from "react"

type CarteCampagneProps = {
  title: string
  sousTitle: string
  btnText: string
  display?: boolean
} & CardProps

export const CarteCampagne: FC<CarteCampagneProps> = ({
  title,
  sousTitle,
  btnText,
  sx,
  children,
  display,
  ...props
}) => {
  const [show, setShow] = useState<boolean>(display || false)
  const [hover, setHover] = useState<boolean>(false)
  return (
    <Card sx={{ ...sx, mt: 1, p: 2 }} {...props}>
      <Box sx={{ position: "relative" }}>
        <CheckCircleIcon
          sx={{
            fill: "GrayText",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)"
          }}
        />
        <Box sx={{ ml: 5 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", fontSize: "1.3em", color: "#050005" }}
            >
              {title}
            </Typography>
            {show ? (
              <ButtonLittleIcon
                variant={hover ? "contained" : "outlined"}
                onClick={() => setShow(false)}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                icon={<Close />}
              />
            ) : (
              <Button variant="outlined" onClick={() => setShow(true)} sx={{ color: "#050005" }}>
                {btnText}
              </Button>
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography>{sousTitle}</Typography>
          </Box>
        </Box>
      </Box>
      {show && <Box sx={{ py: 2 }}>{children}</Box>}
    </Card>
  )
}
