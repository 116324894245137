import { ButtonLittleIcon, TableColumnProps } from "@/components"
import Delete from "@mui/icons-material/Delete"
import { TagList_tagList as ITag } from "@/graphql/tag"

interface TagTableProps {
  onRemove?: (id: number) => void
}

export const TagTable = ({onRemove}: TagTableProps) => {
  const column: TableColumnProps[] = [
    {
      id: "name",
      label: "Nom",
      component: (data: ITag) => (
        <div>{data.name}</div>
      )
    },
    {
      id: "expired",
      label: "periode",
      component: (data: ITag) => (
        <div>
          {
            data.expired === "WEEK" && ("Semaine")
          }
          {
            data.expired === "MONTH" && ("Mois")
          }
          {
            data.expired === "UNDEFINED" && ("Indéterminé")
          }
        </div>
      )
    },
    {
      id: "ation",
      label: "action",
      component: (data: ITag) => (
        <div>
          <ButtonLittleIcon
            color="error"
            icon={<Delete />}
            onClick={() => {
              if(onRemove) {
                onRemove(data.id)
              }
            }}
          />
        </div>
      )
    }
  ]

  return { column }
}
