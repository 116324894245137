import Delete from "@mui/icons-material/Delete"
import Edit from "@mui/icons-material/Edit"
import { Typography, Box } from "@mui/material"
import moment from "moment"
import { TableColumnProps, ButtonLittleIcon } from "../../../../components"
import { ClientsList_clientsList as IClient } from "../../../../graphql"

interface useContactColumnsProps {
  onView?: (b: IClient) => void
  onRemove?: (id: number) => void
}

export const useContactColumns = ({
  onView,
  onRemove
}: useContactColumnsProps): { columns: TableColumnProps[] } => {
  // TODO : Change all type any to the correct type
  const columns: TableColumnProps[] = [
    {
      id: "ID",
      label: "ID",

      component: (data: IClient) => (
        // TODO : Change this element to render the ID of the business
        <Typography variant="subtitle2">{data.id}</Typography>
      )
    },
    {
      id: "logo",
      label: "Nom du Contact",

      component: (data: IClient) => {
        // TODO : Change this element to render the ID of the business
        return (
          <Typography variant="subtitle2">
            {data.firstname || data.lastname}
          </Typography>
        )
      }
    },
    {
      id: "nom",
      label: "Email / Téléphone",
      component: (data: IClient) => {
        // TODO : Change this element to render the name of the business
        return (
          <Typography variant="subtitle2">
            {data.email || data.phone}
          </Typography>
        )
      }
    },
    {
      id: "phone",
      label: "Téléphone",
      component: (data: IClient) => {
        // TODO : Change this element to render the address of the business
        return (
          <Box>
            <Typography variant="subtitle2">
              {data.phone || data.phone}
            </Typography>
          </Box>
        )
      }
    },
    {
      id: "date",
      label: "Date d'inscription",
      component: (data: IClient) => {
        // TODO : Change this element to render the address of the business
        return (
          <Box>
            <Typography variant="subtitle2">
              {moment(data.createdAt).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        )
      }
    },
    {
      id: "action",
      label: "Editer",
      component: (data: IClient) => (
        <Box>
          <ButtonLittleIcon
            icon={<Edit />}
            onClick={() => onView && onView(data)}
            color="info"
            sx={{ mr: 1 , backgroundColor: "#001"}}
          />
          <ButtonLittleIcon
            icon={<Delete />}
            color="error"
            onClick={() => {
              if (onRemove) {
                onRemove(data.id)
              }
            }}
          />
        </Box>
      )
    }
  ]
  return { columns }
}
