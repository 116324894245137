import React, { FC } from "react"
import { Box } from "@mui/material"
import { Outlet, Route, Routes } from "react-router-dom"
import { Contact } from "./Contact"
import { GererContact } from "./GererContact"

interface ContactRoutesProps {
  isContact?: boolean
}

export const ContactRoute: FC<ContactRoutesProps> = ({
  isContact
}): JSX.Element => {
  return (
    <Box>
      {isContact ? (
        <GererContact isContact />
      ) : (
        <Routes>
          <Route index element={<Contact />} />
          <Route path=":id/*" element={<GererContact />} />
        </Routes>
      )}

      <Outlet />
    </Box>
  )
}
