import { gql } from "@apollo/client"

export const LIST_EMAILING = gql`
  query ListEmailing {
    listEmailing {
      id
      type
      templateId
      emailParams
    }
  }
`

export const DETAIL_EMAILING = gql`
  query EmailingDetails($emailingDetailsId: Float!) {
    emailingDetails(id: $emailingDetailsId) {
      id
      type
      templateId
      emailParams
    }
  }
`

export const GET_LIST_TEMPLATE = gql`
  query GetListTempateMail($businessId: Float!) {
    GetListTempateMail(businessId: $businessId) {
      id
      businessId
      email
      idSendinblue
      name
      nameTemplate
      subject
      template
      templateId
    }
  }
`

export const GET_LIST_SENDER = gql`
query GetListSender($status: Boolean!) {
  getListSender(status: $status) {
      id
      email
      name
      sendinblueId
      validateStatus
    }
  }
`

export const GET_ALL_LIST_TEMPLATE = gql`
  query GetAllListTemplate {
    GetAllListTemplate {
      id
      name
      email
      nameTemplate
      subject
      idSendinblue
    }
  }
`

export const GET_TEMPLATE_ID = gql`
  query GetTemplateById($getTemplateByIdId: Float!) {
    GetTemplateById(id: $getTemplateByIdId) {
      id
      idSendinblue
      name
      email
      nameTemplate
      subject
      template
    }
  }
`
export const GET_LIST = gql`
  query GetListContact {
    getListContact {
      id
      name
      listSendinblueId
      clients {
        clientId
      }
    }
  }
`

export const GET_BUSINESS_CONTACT = gql`
  query GetListBusinessContact($businessId: Float) {
    getListBusinessContact(businessId: $businessId) {
      id
      name
      listSendinblueId
      clients {
        clientId
      }
    }
  }
`

export const GET_CAMPAGNE = gql`
  query CampagnList($businessId: Float) {
    campagnList(businessId: $businessId) {
      id
      name
      createdAt
      campaignId
      businessId
      status
      type
    }
  }
`

export const GET_LIST_TAG = gql`
  query GetListContactTag {
    getListContactTag {
      clients {
        clientId
      }
      id
      listSendinblueId
      name
    }
  }
`

export const GET_LIST_ENTRY = gql`
  query GetListEntry($businessId: Float!) {
    getListEntry(businessId: $businessId) {
      id
      email
      businessId
      messageId
      type
      webhookId
    }
  }
`

export const GET_LIST_PACK = gql`
  query GetListPack {
    GetListPack {
      id
      description
      name
      price
      priceStripeId
      priceTtc
      productStripeId
      sms
      status
      taxe
    }
  }
`

export const GET_STAT =gql `
  query GetNumTracking($businessId: Float!) {
    getNumTracking(businessId: $businessId) {
      click
      delivered
      pending
      sent
    }
  }
`

export const GET_CONTACT_LIST = gql`
  query GetContactList($listId: Float!) {
    getContactList(listId: $listId) {
      listContactId
      clientId
      client {
        id
        email
        firstname
        lastname
      }
    }
}`
