import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  styled
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { FC, ReactNode } from "react"
import { useNavigate } from "react-router-dom"

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}))

interface DashboardNavbarProps {
  onSidebarOpen: any
  userMenu: ReactNode
  notification: ReactNode
}

export const DashboardNavbar: FC<DashboardNavbarProps> = ({
  onSidebarOpen,
  userMenu, 
  notification
}) => {
  const navigate = useNavigate()

  return (
    <DashboardNavbarRoot
      sx={{
        left: {
          lg: 280
        },
        width: {
          lg: "calc(100% - 280px)"
        }
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2
        }}
      >
        <IconButton
          onClick={onSidebarOpen}
          sx={{
            display: {
              xs: "inline-flex",
              lg: "none"
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Tooltip title="Retour">
          <IconButton sx={{ ml: 1 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Box sx={{ flexGrow: 1 }} />
        { notification }
        {userMenu}
      </Toolbar>
    </DashboardNavbarRoot>
  )
}
