import { gql } from "@apollo/client"

export const SIGNIN = gql`
  mutation Login($password: String!, $email: String!) {
    login(password: $password, email: $email) {
      id
      email
      firstname
      lastname
      password
      type
      photo
      createdAt
      updatedAt
      businessId
      token
    }
  }
`
