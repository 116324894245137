import { BusinessList_businessList } from "@/graphql"
import FiberManualRecord from "@mui/icons-material/FiberManualRecord"
import { Box, Typography, Chip } from "@mui/material"
import moment from "moment"

type ObjType = {
  createdAt: Date
}

function isAfter(date: Date) {
  const day = moment(date).date()
  const dateFormat = moment().set("date", day)
  const isAfter = dateFormat.isAfter(moment())

  if (!isAfter) return dateFormat.add(1, "month").format("DD/MM/YYYY")

  return dateFormat.format("DD/MM/YYYY")
}

function currentSubscriber(
  data: BusinessList_businessList,
  objTested: ObjType[] | null
) {
  if (objTested && objTested?.length > 0) {
    return objTested.length
  }

  return 0
}

export const TableAbonnement = () => {
  const columns = [
    {
      id: "business",
      label: "Nom de l'établissement",
      component: (data: BusinessList_businessList) => {
        return <Typography variant="caption">{data.businessName}</Typography>
      }
    },
    {
      id: "abonnement",
      label: "Abonnement",
      component: (data: BusinessList_businessList) => {
        const abonnement = data?.Abonnements?.[0]?.Abonnement ?? null
        return (
          <Typography variant="caption">{abonnement !== null && abonnement.name}</Typography>
        )
      }
    },
    {
      id: "price",
      label: "Prix",
      component: (data: BusinessList_businessList) => {
        const abonnement = data?.Abonnements?.[0]?.Abonnement ?? null
        return (
          <Box>
            {
              abonnement !== null && (
                <Typography variant="caption">{abonnement.prixTtc} €</Typography>
              )
            }
          </Box>
        )
      }
    },
    {
      id: "status",
      label: "Statut",
      component: (data: BusinessList_businessList) => {
        const abonnement = data?.Abonnements?.[0]?.status ?? null
        return (
          <Box>
            {abonnement !== null ? <Chip
              label="Actif"
              variant="outlined"
              color="success"
              size="small"
              icon={<FiberManualRecord />}
            /> :
              <Chip
                label="En attente"
                variant="outlined"
                color="error"
                size="small"
                icon={<FiberManualRecord />}
              />}
          </Box>
        )
      }
    },
    {
      id: "subscriptions",
      label: "Nb d'inscripts",
      component: (data: BusinessList_businessList) => {
        const abonnement = data?.Abonnements?.[0]?.Abonnement ?? null
        const subCount = data?.Abonnements?.[0]?.subscribeCount ?? null
        return (
          <Box>
            <Typography variant="caption">
              {
                subCount !== null && abonnement !== null && (<>{subCount} / {abonnement.numberSubscription} </>)
              }
            </Typography>
          </Box>
        )
      }
    },
    {
      id: "commands",
      label: "Nb de commande",
      component: (data: BusinessList_businessList) => {
        const abonnement = data?.Abonnements?.[0]?.Abonnement ?? null
        const subCount = data?.Abonnements?.[0]?.clickCount ?? null
        return (
          <Box>
            <Typography variant="caption">
              {
                subCount !== null && abonnement !== null && (<>{subCount} / {abonnement.numberClick} </>)
              }
            </Typography>
          </Box>
        )
      }
    },
    {
      id: "booking",
      label: "Nb de réservation",
      component: (data: BusinessList_businessList) => {
        const abonnement = data?.Abonnements?.[0]?.Abonnement ?? null
        const subCount = data?.Abonnements?.[0]?.bookingCount ?? null
        return (
          <Box>
            <Typography variant="caption">
              {
                subCount !== null && abonnement !== null && (<>{subCount} / {abonnement.numberBooking} </>)
              }
            </Typography>
          </Box>
        )
      }
    },
    {
      id: "register",
      label: "Date d'inscription",
      component: (data: BusinessList_businessList) => {
        return (
          <Box>
            <Typography variant="caption">
              {moment(data.createdAt).format("DD/MM/YYYY")}{" "}
            </Typography>
          </Box>
        )
      }
    },
    {
      id: "next",
      label: "Prochaine facturation",
      component: (data: BusinessList_businessList) => {
        return (
          <Box>
            <Typography variant="caption">{isAfter(data.createdAt)}</Typography>
          </Box>
        )
      }
    }
  ]
  return { columns }
}
