import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react"
import { Login_login as IUser } from "@/graphql"
import { BusinessInfo_businessInfo as IBusiness } from "@/graphql/business"
import { useCurrentBusiness } from "@/hooks"
import { IApplicationContext } from "./Interfaces"
import { BusinessInfo_businessInfo_businessHours as IHours } from "@/graphql/business"
import { AdminType, OpeningHourUpdateInputType } from "@/types"
import { CURRENT_MODE } from "@/constants"

interface HoraireType {
  id?: number
  day: string
  hours?: IHours
  open: boolean
}

interface TemplateMail {
  name?: string
  email?: string
  subject?: string
  nameTemplate?: string
  template?: string
}

interface CampaignType {
  name?: string
  sender?: string
  receipt?: number[]
  objectCampaing?: string
  template?: string
}

interface Props {
  children?: ReactNode
}

export const ApplicationContext = createContext<IApplicationContext>(
  {} as IApplicationContext
)
export const ApplicationContextProvider: FC<Props> = ({ children }) => {
  const [loadingApp, setLoadingApp] = useState<boolean>(false)
  const [newBusiness, setNewBusiness] = useState(false)
  const [business, setBusiness] = useState<IBusiness>()
  const [user, setUser] = useState<IUser>()
  const [editHours, setEditHours] = useState<HoraireType[]>()
  const [inputHour, setInputHour] = useState<OpeningHourUpdateInputType[]>([])
  const [isAdminModeBusiness, setIsAdminModeBusiness] = useState<boolean>(false)
  const [templateState, setTemplateState] = useState<TemplateMail>({
    name: "",
    email: "",
    subject: "",
    nameTemplate: "",
    template: ""
  })

  const [campagneState, setCampagneState] = useState<CampaignType | undefined>()
  const [typeCampagne, setTypeCampagne] = useState("")
  const [receiptState, setReceiptState] = useState<number[]>()
  const [destination, setDestination] = useState("")

  const { data, loading, refetch } = useCurrentBusiness(
    user?.businessId as number
  )

  useEffect(() => {
    if (data) {
      setBusiness(data)
    }
  }, [data])

  const switchToBusiness = async (businessId: number) => {
    setLoadingApp(true)
    const businessData = await refetch({
      businessId: businessId
    })

    if (businessData?.data?.businessInfo) {
      setBusiness(businessData.data.businessInfo)
      setIsAdminModeBusiness(true)
      localStorage.setItem(CURRENT_MODE, `${businessId}`)
      setLoadingApp(false)
    }
  }

  const returnToAdmin = () => {
    localStorage.removeItem(CURRENT_MODE)
    setIsAdminModeBusiness(false)
    setBusiness(undefined)
  }
  const checkMode = useCallback(() => {
    if (user?.type === AdminType.ADMIN) {
      const checklocalStorageId = localStorage.getItem(CURRENT_MODE)
      if (checklocalStorageId && !isNaN(checklocalStorageId as any)) {
        switchToBusiness(parseFloat(checklocalStorageId))
      }
    }
  }, [user])

  useEffect(() => {
    checkMode()
  }, [checkMode])

  const memoizedContext = useMemo(
    () => ({
      user,
      setUser,
      business,
      loading,
      setBusiness,
      editHours,
      setEditHours,
      inputHour,
      setInputHour,
      isAdminModeBusiness,
      setIsAdminModeBusiness,
      switchToBusiness,
      returnToAdmin,
      loadingApp,
      setLoadingApp,
      templateState,
      setTemplateState,
      campagneState,
      setCampagneState,
      receiptState,
      setReceiptState,
      newBusiness,
      setNewBusiness,
      typeCampagne,
      setTypeCampagne,
      destination,
      setDestination
    }),
    [
      user,
      business,
      loading,
      editHours,
      inputHour,
      isAdminModeBusiness,
      loadingApp,
      templateState,
      campagneState,
      receiptState,
      newBusiness,
      typeCampagne
    ]
  )
  return (
    <ApplicationContext.Provider value={memoizedContext}>
      {children}
    </ApplicationContext.Provider>
  )
}
