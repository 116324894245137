import { useMutation } from "@apollo/client"
import {
  CreateProduct,
  CreateProductVariables,
  DeleteProduct,
  DeleteProductVariables,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  UpdateProduct,
  UpdateProductVariables,
  UPDATE_PRODUCT
} from "@/graphql/business"

import { ProductInput, UpdateProductInput } from "@/types"

export const useProduct = () => {
  const [executeCreate, { loading }] = useMutation<
    CreateProduct,
    CreateProductVariables
  >(CREATE_PRODUCT)
  const createProduct = async (businessId: number, data: ProductInput) => {
    try {
      await executeCreate({
        variables: {
          businessId,
          data
        }
      })
      return { success: "OK" }
    } catch (error) {
      return {
        error: "ERROR"
      }
    }
  }
  return {
    loading,
    createProduct
  }
}

export const useUpdateProduct = () => {
  const [updateProduct] = useMutation<UpdateProduct, UpdateProductVariables>(
    UPDATE_PRODUCT
  )
  const productUpdate = async (productId: number, data: UpdateProductInput) => {
    try {
      await updateProduct({
        variables: {
          productId,
          data
        }
      })
      return { success: "OK" }
    } catch (error) {
      return null
    }
  }
  return { productUpdate }
}

export const useDeleteProduct = () => {
  const [removeProduct, { loading }] = useMutation<
    DeleteProduct,
    DeleteProductVariables
  >(DELETE_PRODUCT)
  const deleteProduct = async (id: number) => {
    try {
      const product = await removeProduct({
        variables: {
          productId: id
        }
      })
      return product
    } catch (error) {
      return undefined
    }
  }
  return { deleteProduct, loading }
}
