import { Reglement } from "@/components/qrcode/Reglement"
import { useApplicationContext, useLots } from "@/hooks"
import { useCurrentReglement } from "@/hooks/useCurrentReglement"
import { ReglementInput } from "@/types"
import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"

export const ReglementPage = () => {
  const { idReglement } = useParams()
  const { data, refetch, modifyReglement, loading } = useCurrentReglement(
    parseFloat(idReglement as string)
  )

  const { business } = useApplicationContext()

  const { data: lots } = useLots()

  const navigate = useNavigate()

  const [values, setValues] = useState<ReglementInput>({
    activite: "",
    adresse_compelete: "",
    capital: "",
    date_end: "",
    date_mise_en_ligne: "",
    date_start: "",
    nom_du_jeu: "",
    nombre_de_lot: 0,
    numero_siret: "",
    sites: "",
    societe: "",
    tagdiscount: "",
    ville_rcs: ""
  })

  useEffect(() => {
    if (data) {
      setValues({
        activite: data.activite || "",
        adresse_compelete: data.adresse_compelete || "",
        capital: data.capital || "",
        date_end: data.date_end,
        date_mise_en_ligne: data.date_mise_en_ligne,
        date_start: data.date_start,
        nom_du_jeu: data.nom_du_jeu || "",
        nombre_de_lot: data.nombre_de_lot || 0,
        numero_siret: data.numero_siret || "",
        sites: data.sites || "",
        societe: data.societe || "",
        tagdiscount: data.tagdiscount || "",
        ville_rcs: data.ville_rcs || ""
      })
    }
  }, [data])

  const updateReglement = async (values: ReglementInput) => {
    try {
      const response = await modifyReglement(
        values,
        parseFloat(idReglement as string)
      )
      if (response) {
        await refetch()
        Swal.fire({
          title: "Reglement changé",
          icon: "success"
        }).then(() => navigate("/discount/games/list-qrcode"))
      }
    } catch (error) {
      Swal.fire({
        title: "La modification a échouée",
        icon: "error"
      })
    }
  }

  return (
    <Box sx={{ p: 2, maxWidth: 800, mx: "auto" }}>
      <Reglement
        lots={lots?.lots}
        onCancel={() => {
          navigate("/discount/games/list-qrcode")
        }}
        loading={loading}
        onCreateReglement={updateReglement}
        valueReglement={values}
        business={business}
      />
    </Box>
  )
}
